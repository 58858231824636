import { Administrator, RoleType } from '@/graphql/generated/types';
import { ApolloQueryResult } from '@apollo/client';
import React, {
  ComponentType,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { Loader } from '../ui/loader/loader.component';
import {
  CurrentProfileQuery,
  ProfileDataFragment,
  useCurrentProfileQuery,
} from './profile.generated';

export enum ProfileStatus {
  LOADING,
  AVAILABLE,
  UNAVAILABLE,
}

export type ProfileContext = [
  ProfileStatus,
  ProfileDataFragment | undefined,
  () => Promise<ApolloQueryResult<CurrentProfileQuery>>,
];

const Context = createContext<ProfileContext>(undefined as any);

function Provider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const query = useCurrentProfileQuery({
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });

  const status = useMemo(() => {
    if (query.loading) {
      return ProfileStatus.LOADING;
    }

    if (query.data?.profile) {
      return ProfileStatus.AVAILABLE;
    }

    return ProfileStatus.UNAVAILABLE;
  }, [query]);

  if (
    query.data?.profile &&
    query.data.profile.__typename !== 'Administrator'
  ) {
    throw new Error('User must be of type "Administrator".');
  }

  const value = useMemo<ProfileContext>(
    () => [
      status,
      (query.data?.profile as Administrator) || undefined,
      query.refetch,
    ],
    [status, query.data],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function use(): ProfileDataFragment & { __typename: 'Administrator' } {
  const profile = useContext(Context)[1];
  if (profile && profile?.__typename !== 'Administrator') {
    throw new Error('User must be of type "Administrator".');
  }
  return profile as ProfileDataFragment & { __typename: 'Administrator' };
}

function useStatus() {
  return useContext(Context)[0];
}

function useReload() {
  return useContext(Context)[2];
}

export const Profile = Object.assign(Provider, {
  use,
  useStatus,
  useReload,
});

export const AwaitProfile: ComponentType = (props) => {
  const status = Profile.useStatus();

  return status === ProfileStatus.LOADING ? <Loader /> : <>{props.children}</>;
};

export function isSuperAdmin(profile: ProfileDataFragment) {
  return profile.roles.find((r) => r.name === RoleType.Super) !== undefined;
}
