import React, { ComponentType } from 'react';

interface LogoImageProps {
  color?: string;
  size?: number;
}

export const LogoImage: ComponentType<LogoImageProps> = (props) => {
  const { color = '#000000', size } = props;
  return (
    <svg
      className="logo-image"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
        height: size,
      }}
    >
      <g>
        <circle cx="256" cy="256" r="256" style={{ fill: color }} />
        <g>
          <path
            d="M74.99,280.467c-0.571,3.807 -0.857,7.138 -0.857,9.993c0,17.701 4.759,31.406 14.275,41.113c9.517,9.707 22.841,14.561 39.971,14.561c10.849,-0 20.414,-2.284 28.693,-6.853c8.28,-4.568 15.465,-10.468 21.556,-17.701l3.426,3.997c-5.52,7.043 -12.848,13.133 -21.984,18.273c-9.136,5.139 -19.7,7.708 -31.691,7.708c-9.707,0 -18.32,-1.475 -25.838,-4.425c-7.518,-2.95 -13.847,-7.043 -18.986,-12.277c-5.139,-5.234 -9.041,-11.515 -11.706,-18.843c-2.665,-7.328 -3.997,-15.37 -3.997,-24.125c-0,-10.279 1.808,-20.224 5.425,-29.836c3.616,-9.612 8.755,-18.129 15.417,-25.552c6.662,-7.424 14.561,-13.324 23.697,-17.702c9.136,-4.378 19.319,-6.566 30.549,-6.566c8.946,-0 16.654,1.38 23.126,4.139c6.471,2.76 11.848,6.472 16.131,11.135c4.282,4.663 7.423,10.136 9.422,16.417c1.998,6.281 2.997,12.943 2.997,19.985c0,1.903 -0.142,4.378 -0.428,7.423c-0.285,3.046 -0.809,6.091 -1.57,9.136l-117.628,0Zm112.774,-5.424c0.571,-4.188 0.857,-8.09 0.857,-11.706c-0,-13.704 -4.045,-24.744 -12.134,-33.119c-8.09,-8.374 -19.272,-12.562 -33.547,-12.562c-10.088,0 -19.034,1.856 -26.838,5.568c-7.803,3.711 -14.513,8.374 -20.128,13.989c-5.615,5.615 -10.088,11.801 -13.418,18.558c-3.331,6.757 -5.568,13.181 -6.71,19.272l111.918,-0Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M284.836,351.558c-9.897,0 -18.51,-1.475 -25.838,-4.425c-7.328,-2.95 -13.467,-7.043 -18.415,-12.277c-4.949,-5.234 -8.661,-11.42 -11.135,-18.558c-2.474,-7.137 -3.712,-14.894 -3.712,-23.268c0,-10.659 1.761,-20.842 5.282,-30.549c3.522,-9.708 8.518,-18.273 14.989,-25.696c6.472,-7.423 14.323,-13.371 23.555,-17.844c9.231,-4.473 19.557,-6.709 30.977,-6.709c14.085,-0 25.933,3.759 35.545,11.277c9.612,7.518 15.846,17.273 18.701,29.264l0.856,0l20.842,-121.054l6.281,0l-37.972,215.842l-6.281,0l6.567,-33.69l-0.857,0c-2.665,5.33 -5.948,10.279 -9.85,14.847c-3.902,4.568 -8.375,8.565 -13.419,11.991c-5.043,3.426 -10.611,6.091 -16.702,7.994c-6.09,1.904 -12.562,2.855 -19.414,2.855Zm0.571,-5.424c9.327,-0 18.082,-2.142 26.267,-6.424c8.184,-4.283 15.226,-9.993 21.127,-17.131c5.9,-7.137 10.564,-15.274 13.99,-24.41c3.426,-9.136 5.139,-18.558 5.139,-28.265c-0,-7.233 -1.19,-14.038 -3.569,-20.414c-2.379,-6.376 -5.805,-11.896 -10.278,-16.559c-4.473,-4.664 -9.898,-8.375 -16.274,-11.135c-6.376,-2.76 -13.466,-4.14 -21.27,-4.14c-10.278,0 -19.652,2.094 -28.122,6.281c-8.47,4.188 -15.703,9.755 -21.699,16.702c-5.995,6.948 -10.611,14.942 -13.847,23.983c-3.236,9.041 -4.853,18.415 -4.853,28.122c-0,7.423 1.094,14.371 3.283,20.842c2.189,6.471 5.472,12.134 9.85,16.988c4.378,4.853 9.945,8.66 16.702,11.42c6.757,2.76 14.608,4.14 23.554,4.14Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M403.035,347.561l23.126,-131.332l6.281,-0l-23.126,131.332l-6.281,0Zm33.975,-180.725c-1.713,0 -3.14,-0.523 -4.282,-1.57c-1.142,-1.047 -1.713,-2.712 -1.713,-4.996c-0,-2.094 0.666,-3.95 1.998,-5.568c1.333,-1.618 3.046,-2.427 5.139,-2.427c1.713,0 3.141,0.524 4.283,1.571c1.142,1.047 1.713,2.617 1.713,4.711c0,2.284 -0.714,4.235 -2.141,5.852c-1.428,1.618 -3.093,2.427 -4.997,2.427Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
        </g>
      </g>
    </svg>
  );
};
