import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import {
  PropertySchemaFragment,
  usePropertySchemasQuery,
} from './list.generated';

interface Props {
  enterprise: { id: string; name: string };
}
export const List: ComponentType<RouteComponentProps & Props> = (props) => {
  const { enterprise } = props;
  const _ = Translator.useTranslator();

  const defaultFilter = useMemo(() => {
    return { enterpriseId: enterprise?.id };
  }, [enterprise]);

  const [query] = usePaginatedUrlQuery();
  const { loading, data } = usePropertySchemasQuery({
    variables: {
      ...defaultFilter,
      pagination: getPaginationFromQuery(query),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.Title>Benutzerdefinierte Felder</Frame.Title>
      <Frame.SubTitle>{enterprise.name}</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="../create" label="Neues Schema" />
      </Frame.ActionBar>

      <Frame.Content>
        <ListView<PropertySchemaFragment>
          loading={loading}
          defaultFilter={defaultFilter}
          data={data?.adminPropertySchemas?.data || []}
          totalCount={data?.adminPropertySchemas?.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'Kontext',
              sortable: true,
            },
          ]}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/edit`}>{item.name}</Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>
                  {item.contexts.join(', ')}
                </Link>
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
