import { AddressEditor } from '@/contact/shared/address/address-editor.component';
import { ContactPropertiesEditor } from '@/contact/shared/contact-property/contact-properties-editor.component';
import { Enterprise } from '@/graphql/generated/types';
import { Profile, isSuperAdmin } from '@/profile/profile';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { HelpPopup } from '@/ui/help-popup/help-popup.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { Section } from '@/ui/section/section.component';
import React, { ComponentType } from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { EmploymentsEditor } from '../../employment/employments-editor.component';

interface PersonEditorProps {
  create: boolean;
  form: UseFormReturn<any>;
  contactProperties: UseFieldArrayReturn<any, any>;
  employments: UseFieldArrayReturn<any, any>;
  enterprises?: Pick<Enterprise, 'id' | 'name'>[];
}

export const PersonEditor: ComponentType<PersonEditorProps> = (props) => {
  const _ = Translator.useTranslator();
  const { contactProperties, form, enterprises, employments, create } = props;

  const profile = Profile.use();
  const { availableEnterprises } = useResources();

  return (
    <Grid.Row>
      <Grid.Column>
        <Section title="Person">
          <Form.Input name="person.firstName" label="Vorname" />
          <Form.Input name="person.lastName" label="Nachname" />
          <Form.Gender name="person.gender" label="Geschlecht" />
          <Form.Input name="person.title" label="Titel" />
        </Section>
        <Section title="Arbeitsverhältnisse">
          <EmploymentsEditor employmentsFieldArray={employments} form={form} />
        </Section>
        <Section title="Kontakt">
          <ContactPropertiesEditor
            prefix="person.contactProperties"
            contactPropertiesFieldArray={contactProperties}
            form={form}
          />
        </Section>
      </Grid.Column>
      <Grid.Column>
        <Section title="Anschrift">
          <AddressEditor
            prefix="person.address"
            overrideLabels={{ contactPerson: 'Kontaktperson / z.Hd.' }}
          />
        </Section>
        <Section title="Unternehmen">
          {(isSuperAdmin(profile) || create) && (
            <Form.Select2
              multiple
              disabled={!isSuperAdmin(profile)}
              name="person.enterpriseIds"
              label={
                <>
                  Firmenzugehörigkeit
                  <HelpPopup hover="Halte die Strg- (Windows) oder Befehlstaste (Mac) gedrückt, um mehrere Optionen auszuwählen." />
                </>
              }
              choices={availableEnterprises.map((enterprise) => ({
                value: enterprise.id,
                label: enterprise.name,
              }))}
            />
          )}

          {enterprises && (
            <>
              <ul>
                {enterprises.map((e) => (
                  <li key={e.id}>{e.name}</li>
                ))}
              </ul>
            </>
          )}
          {!isSuperAdmin(profile) && !create && (
            <InfoBox
              content="Für das Ändern der Unternehmenszugehörigkeit wende dich bitte an den Support!"
              header="Hinweis"
            />
          )}
        </Section>
        <Section title="Sonstiges">
          <Form.Html label="Interne Notizen" name="person.internalNotes" />
        </Section>
      </Grid.Column>
    </Grid.Row>
  );
};
