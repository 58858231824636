// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".abkImPGA7QvpgPUg6wUR .Z4HRoy_tmkpOnE0G_VgV{display:flex;justify-content:space-between}.abkImPGA7QvpgPUg6wUR .I_D4j2dwgoKeaomQnaYI{font-weight:bold;font-size:.8em}.abkImPGA7QvpgPUg6wUR .QsTRddYsmheulE29T18t{font-size:.8em}.abkImPGA7QvpgPUg6wUR .Sk839c9J82paWp4gULt_{font-size:.8em}.abkImPGA7QvpgPUg6wUR.YIT61Lw5I51qeQZXklfP label{margin:0}", "",{"version":3,"sources":["webpack://./src/shared/course/course-unit-finder/course-unit-finder.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CACA,6BAAA,CAEF,4CACE,gBAAA,CACA,cAAA,CAEF,4CACE,cAAA,CAEF,4CACE,cAAA,CAIA,iDACE,QAAA","sourcesContent":[".host {\n  .row {\n    display: flex;\n    justify-content: space-between;\n  }\n  .itemRow0 {\n    font-weight: bold;\n    font-size: 0.8em;\n  }\n  .itemRow1 {\n    font-size: 0.8em;\n  }\n  .itemRow2 {\n    font-size: 0.8em;\n  }\n\n  &.small {\n    label {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "abkImPGA7QvpgPUg6wUR",
	"row": "Z4HRoy_tmkpOnE0G_VgV",
	"itemRow0": "I_D4j2dwgoKeaomQnaYI",
	"itemRow1": "QsTRddYsmheulE29T18t",
	"itemRow2": "Sk839c9J82paWp4gULt_",
	"small": "YIT61Lw5I51qeQZXklfP"
};
export default ___CSS_LOADER_EXPORT___;
