import { SANDBOX } from './constants';

export function intersect(a: any[], b: any[]) {
  const setA = new Set(a);
  const setB = new Set(b);

  return Array.from(new Set([...setA].filter((x) => setB.has(x))));
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any);
}

export function isSandbox() {
  return SANDBOX?.toLowerCase() === 'true';
}
