import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadStatsQueryVariables = Types.Exact<{
  date: Types.Scalars['DateTime'];
}>;

export type LeadStatsQuery = {
  __typename?: 'Query';
  adminInquiries: { __typename?: 'PaginatedInquiryResult'; totalCount: number };
  adminConsultingEventsForCalendar: Array<{
    __typename?: 'ConsultingEvent';
    id: any;
  }>;
  adminOrders: {
    __typename?: 'PaginatedOrderResult';
    totalCount: number;
    data: Array<{
      __typename?: 'Order';
      items: Array<
        | { __typename?: 'BookingOrderItem'; bookingId: any }
        | { __typename?: 'DiscountOrderItem' }
        | { __typename?: 'PackageBookingOrderItem' }
        | { __typename?: 'ProductOrderItem' }
      >;
    }>;
  };
  adminBookings: {
    __typename?: 'PaginatedBookingResult';
    totalCount: number;
    data: Array<
      | { __typename?: 'CourseModuleBooking'; id: any }
      | { __typename?: 'CourseUnitBooking'; id: any }
    >;
  };
};

export const LeadStatsDocument = gql`
  query leadStats($date: DateTime!) {
    adminInquiries(
      pagination: { skip: 0, take: 999 }
      filter: { startDate: $date }
    ) {
      totalCount
    }
    adminConsultingEventsForCalendar(input: { startConfirmedAt: $date }) {
      id
    }
    adminOrders(
      pagination: { skip: 0, take: 999 }
      filter: { startDate: $date }
    ) {
      totalCount
      data {
        items {
          ... on BookingOrderItem {
            bookingId
          }
        }
      }
    }
    adminBookings(
      pagination: { skip: 0, take: 999 }
      input: { startDate: $date }
    ) {
      totalCount
      data {
        ... on Booking {
          id
        }
      }
    }
  }
`;
export type LeadStatsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LeadStatsQuery,
    LeadStatsQueryVariables
  >,
  'query'
> &
  ({ variables: LeadStatsQueryVariables; skip?: boolean } | { skip: boolean });

export const LeadStatsComponent = (props: LeadStatsComponentProps) => (
  <ApolloReactComponents.Query<LeadStatsQuery, LeadStatsQueryVariables>
    query={LeadStatsDocument}
    {...props}
  />
);

/**
 * __useLeadStatsQuery__
 *
 * To run a query within a React component, call `useLeadStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadStatsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useLeadStatsQuery(
  baseOptions: Apollo.QueryHookOptions<LeadStatsQuery, LeadStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeadStatsQuery, LeadStatsQueryVariables>(
    LeadStatsDocument,
    options,
  );
}
export function useLeadStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeadStatsQuery,
    LeadStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeadStatsQuery, LeadStatsQueryVariables>(
    LeadStatsDocument,
    options,
  );
}
export type LeadStatsQueryHookResult = ReturnType<typeof useLeadStatsQuery>;
export type LeadStatsLazyQueryHookResult = ReturnType<
  typeof useLeadStatsLazyQuery
>;
export type LeadStatsQueryResult = Apollo.QueryResult<
  LeadStatsQuery,
  LeadStatsQueryVariables
>;
