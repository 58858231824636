import React, { ComponentType } from 'react';
import AsyncSelect from 'react-select/async';
import { Translator } from 'shared/translator/translator.component';
import {
  FindCustomersQuery,
  useFindCustomersLazyQuery,
} from './customer-finder.generated';
import styles from './customer-finder.module.scss';

function getPersonLabel(
  contact: FindCustomersQuery['persons']['data'][number],
  _: any,
) {
  return (
    <>
      <div className={styles.itemRow1}>
        {[
          contact.gender
            ? _(`gender.declarative.${contact.gender}`)
            : undefined,
          contact.title,
          contact.firstName,
          contact.lastName,
        ]
          .filter((t) => !!t)
          .join(' ')}
      </div>
      <div className={styles.itemRow2}>
        {[
          contact.user?.username,
          contact.email ? `(${contact.email})` : undefined,
        ]
          .filter((t) => !!t)
          .join(' ')}
      </div>
    </>
  );
}

export type CustomerItemType = {
  label: string;
  value: string;
  customerId: string;
};
interface CustomerFinderProps {
  value?: CustomerItemType | null;
  onChange: (item?: CustomerItemType | null) => void;
}

export const CustomerFinder: ComponentType<CustomerFinderProps> = (props) => {
  const { value, onChange } = props;
  const _ = Translator.useTranslator();

  const [__, { data, refetch }] = useFindCustomersLazyQuery();
  return (
    <div className={styles.host}>
      <label>Suche</label>
      <AsyncSelect<CustomerItemType>
        onChange={onChange}
        value={value}
        loadOptions={async (inputValue: string, callback) => {
          try {
            const data = await refetch({
              query: inputValue,
            });
            callback(
              data.data?.persons.data.map(
                (person) =>
                  ({
                    label: getPersonLabel(person, _),
                    value: person.id,
                    customerId: person.user?.id,
                  } as any),
              ),
            );
          } catch (e) {
            console.log(e);
          }
        }}
      />
    </div>
  );
};
