import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import { PropertySchemaFragmentDoc } from '../../enterprise/detail/properties/list/list.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PropertySchemaContextQueryVariables = Types.Exact<{
  enterpriseId: Types.Scalars['UUID'];
  context: Types.Scalars['String'];
}>;

export type PropertySchemaContextQuery = {
  __typename?: 'Query';
  adminPropertySchemaContext: Array<{
    __typename?: 'PropertySchema';
    id: any;
    name: string;
    contexts: Array<string>;
    value: string;
  }>;
};

export const PropertySchemaContextDocument = gql`
  query propertySchemaContext($enterpriseId: UUID!, $context: String!) {
    adminPropertySchemaContext(enterpriseId: $enterpriseId, context: $context) {
      ...PropertySchema
    }
  }
  ${PropertySchemaFragmentDoc}
`;
export type PropertySchemaContextComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PropertySchemaContextQuery,
    PropertySchemaContextQueryVariables
  >,
  'query'
> &
  (
    | { variables: PropertySchemaContextQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PropertySchemaContextComponent = (
  props: PropertySchemaContextComponentProps,
) => (
  <ApolloReactComponents.Query<
    PropertySchemaContextQuery,
    PropertySchemaContextQueryVariables
  >
    query={PropertySchemaContextDocument}
    {...props}
  />
);

/**
 * __usePropertySchemaContextQuery__
 *
 * To run a query within a React component, call `usePropertySchemaContextQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertySchemaContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertySchemaContextQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function usePropertySchemaContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertySchemaContextQuery,
    PropertySchemaContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PropertySchemaContextQuery,
    PropertySchemaContextQueryVariables
  >(PropertySchemaContextDocument, options);
}
export function usePropertySchemaContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertySchemaContextQuery,
    PropertySchemaContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PropertySchemaContextQuery,
    PropertySchemaContextQueryVariables
  >(PropertySchemaContextDocument, options);
}
export type PropertySchemaContextQueryHookResult = ReturnType<
  typeof usePropertySchemaContextQuery
>;
export type PropertySchemaContextLazyQueryHookResult = ReturnType<
  typeof usePropertySchemaContextLazyQuery
>;
export type PropertySchemaContextQueryResult = Apollo.QueryResult<
  PropertySchemaContextQuery,
  PropertySchemaContextQueryVariables
>;
