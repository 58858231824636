import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CourseModuleBookingListDataFragment = {
  __typename?: 'CourseModuleBooking';
  courseModule: {
    __typename?: 'CourseModule';
    startDate: any;
    endDate: any;
    dateUncertain: boolean;
    withTime: boolean;
    online: boolean;
    correspondenceCourse: boolean;
    title?: string | null;
    sku: string;
    location?: { __typename?: 'Location'; commonName: string } | null;
    courseUnit: {
      __typename?: 'CourseUnit';
      course: {
        __typename?: 'Course';
        abbreviation: string;
        verboseTitle?: string | null;
      };
    };
  };
};

export type CourseUnitBookingListDataFragment = {
  __typename?: 'CourseUnitBooking';
  courseUnit: {
    __typename?: 'CourseUnit';
    startDate: any;
    endDate: any;
    dateUncertain: boolean;
    withTime: boolean;
    online: boolean;
    correspondenceCourse: boolean;
    title?: string | null;
    sku: string;
    location?: { __typename?: 'Location'; commonName: string } | null;
    course: {
      __typename?: 'Course';
      abbreviation: string;
      verboseTitle?: string | null;
    };
  };
};

export type BookingsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
}>;

export type BookingsQuery = {
  __typename?: 'Query';
  adminBookings: {
    __typename?: 'PaginatedBookingResult';
    totalCount: number;
    data: Array<
      | {
          __typename?: 'CourseModuleBooking';
          id: any;
          createdAt: any;
          confirmedAt?: any | null;
          cancelledAt?: any | null;
          orderItem?:
            | {
                __typename?: 'BookingOrderItem';
                id: any;
                order: { __typename?: 'Order'; id: any };
              }
            | {
                __typename?: 'DiscountOrderItem';
                id: any;
                order: { __typename?: 'Order'; id: any };
              }
            | {
                __typename?: 'PackageBookingOrderItem';
                id: any;
                order: { __typename?: 'Order'; id: any };
              }
            | {
                __typename?: 'ProductOrderItem';
                id: any;
                order: { __typename?: 'Order'; id: any };
              }
            | null;
          customer: {
            __typename?: 'Customer';
            id: any;
            person: {
              __typename?: 'Person';
              id: any;
              title?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            };
          };
          courseModule: {
            __typename?: 'CourseModule';
            startDate: any;
            endDate: any;
            dateUncertain: boolean;
            withTime: boolean;
            online: boolean;
            correspondenceCourse: boolean;
            title?: string | null;
            sku: string;
            location?: { __typename?: 'Location'; commonName: string } | null;
            courseUnit: {
              __typename?: 'CourseUnit';
              course: {
                __typename?: 'Course';
                abbreviation: string;
                verboseTitle?: string | null;
              };
            };
          };
        }
      | {
          __typename?: 'CourseUnitBooking';
          id: any;
          createdAt: any;
          confirmedAt?: any | null;
          cancelledAt?: any | null;
          orderItem?:
            | {
                __typename?: 'BookingOrderItem';
                id: any;
                order: { __typename?: 'Order'; id: any };
              }
            | {
                __typename?: 'DiscountOrderItem';
                id: any;
                order: { __typename?: 'Order'; id: any };
              }
            | {
                __typename?: 'PackageBookingOrderItem';
                id: any;
                order: { __typename?: 'Order'; id: any };
              }
            | {
                __typename?: 'ProductOrderItem';
                id: any;
                order: { __typename?: 'Order'; id: any };
              }
            | null;
          customer: {
            __typename?: 'Customer';
            id: any;
            person: {
              __typename?: 'Person';
              id: any;
              title?: string | null;
              firstName?: string | null;
              lastName?: string | null;
            };
          };
          courseUnit: {
            __typename?: 'CourseUnit';
            startDate: any;
            endDate: any;
            dateUncertain: boolean;
            withTime: boolean;
            online: boolean;
            correspondenceCourse: boolean;
            title?: string | null;
            sku: string;
            location?: { __typename?: 'Location'; commonName: string } | null;
            course: {
              __typename?: 'Course';
              abbreviation: string;
              verboseTitle?: string | null;
            };
          };
        }
    >;
  };
};

export const CourseModuleBookingListDataFragmentDoc = gql`
  fragment CourseModuleBookingListData on CourseModuleBooking {
    courseModule {
      startDate
      endDate
      dateUncertain
      withTime
      online
      correspondenceCourse
      title
      sku
      location {
        commonName
      }
      courseUnit {
        course {
          abbreviation
          verboseTitle
        }
      }
    }
  }
`;
export const CourseUnitBookingListDataFragmentDoc = gql`
  fragment CourseUnitBookingListData on CourseUnitBooking {
    courseUnit {
      startDate
      endDate
      dateUncertain
      withTime
      online
      correspondenceCourse
      title
      sku
      location {
        commonName
      }
      course {
        abbreviation
        verboseTitle
      }
    }
  }
`;
export const BookingsDocument = gql`
  query bookings($pagination: PaginationInput!) {
    adminBookings(pagination: $pagination) {
      totalCount
      data {
        ... on Booking {
          id
          createdAt
          confirmedAt
          cancelledAt
          orderItem {
            id
            order {
              id
            }
          }
          customer {
            id
            person {
              id
              title
              firstName
              lastName
            }
          }
        }
        ... on CourseModuleBooking {
          ...CourseModuleBookingListData
        }
        ... on CourseUnitBooking {
          ...CourseUnitBookingListData
        }
      }
    }
  }
  ${CourseModuleBookingListDataFragmentDoc}
  ${CourseUnitBookingListDataFragmentDoc}
`;
export type BookingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BookingsQuery,
    BookingsQueryVariables
  >,
  'query'
> &
  ({ variables: BookingsQueryVariables; skip?: boolean } | { skip: boolean });

export const BookingsComponent = (props: BookingsComponentProps) => (
  <ApolloReactComponents.Query<BookingsQuery, BookingsQueryVariables>
    query={BookingsDocument}
    {...props}
  />
);

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(
    BookingsDocument,
    options,
  );
}
export function useBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingsQuery,
    BookingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(
    BookingsDocument,
    options,
  );
}
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<
  typeof useBookingsLazyQuery
>;
export type BookingsQueryResult = Apollo.QueryResult<
  BookingsQuery,
  BookingsQueryVariables
>;
