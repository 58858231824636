// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tfTvrFk5byzLIdFmeGsg .UNYoYDbXCZ0sC6rVsAoR{font-size:.8em}", "",{"version":3,"sources":["webpack://./src/contact/person/customer-finder/customer-finder.module.scss"],"names":[],"mappings":"AAGE,4CACE,cAAA","sourcesContent":[".host {\n  .itemRow1 {\n  }\n  .itemRow2 {\n    font-size: 0.8em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "tfTvrFk5byzLIdFmeGsg",
	"itemRow2": "UNYoYDbXCZ0sC6rVsAoR"
};
export default ___CSS_LOADER_EXPORT___;
