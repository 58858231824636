import slugify from 'slugify';
export function boardNameToSlug(name: string) {
  return slugify(name, {
    replacement: '-',
    lower: true,
    locale: 'de',
    trim: true,
  });
}

export const DataFormater = (number) => {
  let outputNum = 0;
  let outputChar = '';
  if (number > 1000000000) {
    outputNum = number / 1000000000;
    outputChar = 'G';
  } else if (number > 1000000) {
    outputNum = number / 1000000;
    outputChar = 'M';
  } else if (number > 1000) {
    outputNum = number / 1000;
    outputChar = 'k';
  } else {
    outputNum = number;
  }

  return `${outputNum.toLocaleString('de')}${outputChar}`;
};
