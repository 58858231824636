import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type RoomEventData_ConsultingEvent_Fragment = { __typename?: 'ConsultingEvent', id: any, startDate: any, endDate: any, roomId?: any | null, room?: { __typename?: 'Room', id: any, name: string } | null };

export type RoomEventData_CourseEvent_Fragment = { __typename?: 'CourseEvent', id: any, startDate: any, endDate: any, roomId?: any | null, room?: { __typename?: 'Room', id: any, name: string } | null };

export type RoomEventData_Event_Fragment = { __typename?: 'Event', id: any, startDate: any, endDate: any, roomId?: any | null, room?: { __typename?: 'Room', id: any, name: string } | null };

export type RoomEventDataFragment = RoomEventData_ConsultingEvent_Fragment | RoomEventData_CourseEvent_Fragment | RoomEventData_Event_Fragment;

export type EventsForTimeQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.FilterEventsInput>;
}>;


export type EventsForTimeQuery = { __typename?: 'Query', events: Array<{ __typename?: 'ConsultingEvent', id: any, startDate: any, endDate: any, roomId?: any | null, room?: { __typename?: 'Room', id: any, name: string } | null } | { __typename?: 'CourseEvent', id: any, startDate: any, endDate: any, roomId?: any | null, room?: { __typename?: 'Room', id: any, name: string } | null } | { __typename?: 'Event', id: any, startDate: any, endDate: any, roomId?: any | null, room?: { __typename?: 'Room', id: any, name: string } | null }> };

export type EventManagerRoomsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EventManagerRoomsQuery = { __typename?: 'Query', adminRooms: { __typename?: 'PaginatedRoomResult', data: Array<{ __typename?: 'Room', id: any, name: string, locationId: any, location: { __typename?: 'Location', id: any, name: string } }> } };

export type UpdateEventMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', adminUpdateEvent: boolean };

export const RoomEventDataFragmentDoc = gql`
    fragment RoomEventData on EventInterface {
  id
  startDate
  endDate
  roomId
  room {
    id
    name
  }
}
    `;
export const EventsForTimeDocument = gql`
    query eventsForTime($input: FilterEventsInput) {
  events(input: $input) {
    ... on EventInterface {
      ...RoomEventData
    }
  }
}
    ${RoomEventDataFragmentDoc}`;
export type EventsForTimeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventsForTimeQuery, EventsForTimeQueryVariables>, 'query'>;

    export const EventsForTimeComponent = (props: EventsForTimeComponentProps) => (
      <ApolloReactComponents.Query<EventsForTimeQuery, EventsForTimeQueryVariables> query={EventsForTimeDocument} {...props} />
    );
    

/**
 * __useEventsForTimeQuery__
 *
 * To run a query within a React component, call `useEventsForTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsForTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsForTimeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsForTimeQuery(baseOptions?: Apollo.QueryHookOptions<EventsForTimeQuery, EventsForTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsForTimeQuery, EventsForTimeQueryVariables>(EventsForTimeDocument, options);
      }
export function useEventsForTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsForTimeQuery, EventsForTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsForTimeQuery, EventsForTimeQueryVariables>(EventsForTimeDocument, options);
        }
export type EventsForTimeQueryHookResult = ReturnType<typeof useEventsForTimeQuery>;
export type EventsForTimeLazyQueryHookResult = ReturnType<typeof useEventsForTimeLazyQuery>;
export type EventsForTimeQueryResult = Apollo.QueryResult<EventsForTimeQuery, EventsForTimeQueryVariables>;
export const EventManagerRoomsDocument = gql`
    query eventManagerRooms {
  adminRooms(pagination: {skip: 0, take: 999}) {
    data {
      id
      name
      locationId
      location {
        id
        name
      }
    }
  }
}
    `;
export type EventManagerRoomsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventManagerRoomsQuery, EventManagerRoomsQueryVariables>, 'query'>;

    export const EventManagerRoomsComponent = (props: EventManagerRoomsComponentProps) => (
      <ApolloReactComponents.Query<EventManagerRoomsQuery, EventManagerRoomsQueryVariables> query={EventManagerRoomsDocument} {...props} />
    );
    

/**
 * __useEventManagerRoomsQuery__
 *
 * To run a query within a React component, call `useEventManagerRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventManagerRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventManagerRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventManagerRoomsQuery(baseOptions?: Apollo.QueryHookOptions<EventManagerRoomsQuery, EventManagerRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventManagerRoomsQuery, EventManagerRoomsQueryVariables>(EventManagerRoomsDocument, options);
      }
export function useEventManagerRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventManagerRoomsQuery, EventManagerRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventManagerRoomsQuery, EventManagerRoomsQueryVariables>(EventManagerRoomsDocument, options);
        }
export type EventManagerRoomsQueryHookResult = ReturnType<typeof useEventManagerRoomsQuery>;
export type EventManagerRoomsLazyQueryHookResult = ReturnType<typeof useEventManagerRoomsLazyQuery>;
export type EventManagerRoomsQueryResult = Apollo.QueryResult<EventManagerRoomsQuery, EventManagerRoomsQueryVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: UUID!, $input: UpdateEventInput!) {
  adminUpdateEvent(id: $id, input: $input)
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;
export type UpdateEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEventMutation, UpdateEventMutationVariables>, 'mutation'>;

    export const UpdateEventComponent = (props: UpdateEventComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEventMutation, UpdateEventMutationVariables> mutation={UpdateEventDocument} {...props} />
    );
    

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;