import kebabIcon from '@/assets/image/icons/kebab-menu.svg';
import React, {
  ComponentType,
  MouseEventHandler,
  useCallback,
  useState,
} from 'react';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
import styles from './dropdown-menu.module.scss';

export interface Action {
  label: string;
  meaning?: 'warning' | 'success' | 'error';
  onClick?: MouseEventHandler;
  href?: string;
}

interface DropdownMenuProps {
  actions: (Action | undefined)[];
}

export const DropdownMenu: ComponentType<DropdownMenuProps> = (props) => {
  const actions = props.actions.filter((a) => a !== undefined) as Action[];

  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className={styles.host}>
      <Icon
        inline
        icon={kebabIcon}
        className={styles.actionsIcon}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div
          className={styles.actionsContainer}
          onClick={() => setIsOpen(false)}
        >
          {actions.map((a) => (
            <Button
              label={a.label}
              warning={a.meaning === 'warning'}
              error={a.meaning === 'error'}
              success={a.meaning === 'success'}
              onClick={a.onClick}
              href={a.href}
            />
          ))}
        </div>
      )}
    </div>
  );
};
