import { Address } from '@/graphql/generated/types';
import { Form } from '@/ui/form/form.component';
import React, { ComponentType } from 'react';
import {
  isoCountryCodes,
  useCountryNames,
} from 'shared/countries/iso-country-codes';

interface AddressEditorProps {
  withCompany?: boolean;
  prefix: string;
  overrideLabels?: Partial<Record<keyof Address, string>>;
}

export const AddressEditor: ComponentType<AddressEditorProps> = (props) => {
  const { prefix, overrideLabels, withCompany = true } = props;
  const getName = useCountryNames();
  return (
    <>
      <Form.Input
        name={`${prefix}.contactPerson`}
        label={overrideLabels?.contactPerson || 'Kontaktperson'}
      />

      {withCompany && (
        <Form.Input
          name={`${prefix}.company`}
          label={overrideLabels?.company || 'Firma'}
        />
      )}
      {!withCompany && <Form.Input type="hidden" name={`${prefix}.company}`} />}

      <Form.Input
        name={`${prefix}.streetAndNumber`}
        label={overrideLabels?.streetAndNumber || 'Straße und Hausnummer'}
      />
      <Form.Pack>
        <Form.Input
          name={`${prefix}.postalCode`}
          label={overrideLabels?.postalCode || 'PLZ'}
        />
        <Form.Input
          name={`${prefix}.city`}
          label={overrideLabels?.city || 'Stadt'}
        />
      </Form.Pack>
      <Form.Select
        choices={isoCountryCodes.map((code: string) => ({
          label: getName(code),
          value: code,
        }))}
        name={`${prefix}.country`}
        label={overrideLabels?.country || 'Land'}
      />
    </>
  );
};
