import React, { ComponentType } from 'react';

interface Props {
  onDoubleClick?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
  className?: string;
}

const DefaultRow: ComponentType<Props> = (props) => {
  return <tr {...props}>{props.children}</tr>;
};

export const Row = Object.assign(DefaultRow, {});
