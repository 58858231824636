import {
  CourseModule,
  CourseModuleStatus,
  CourseUnitStatus,
} from '@/graphql/generated/types';
import styles from '@/shared/tree-edit/tree-edit.module.scss';
import globalStyles from '@/styles/global.scss';
import { TagInspect } from '@/tag/tag-inspect/tag-inspect.component';
import { Button } from '@/ui/button/button.component';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import {
  SelectField,
  SelectFieldProps,
} from '@/ui/select-field/select-field.component';
import { Link } from '@reach/router';
import binIcon from 'assets/image/icons/bin.svg';
import classNames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ComponentType, useCallback, useMemo, useState } from 'react';
import { CourseUnitNodeDataFragment } from 'shared/course/course-unit-list/course-unit-list.generated';
import { CourseUnitNodeTitle } from 'shared/course/course-unit-list/course-unit-node-title.component';

export interface CourseUnitMiniEditorValues {
  startDate: string;
  endDate: string;
  withTime: boolean;
  dateUncertain: boolean;
  title?: Maybe<string>;
  id: string;
  location?: Maybe<{
    id: string;
    city?: Maybe<string>;
    name: string;
    commonName: string;
  }>;
  searchableLocation?: Maybe<{
    id: string;
    city: Maybe<string>;
    name: string;
    commonName: string;
  }>;
  online: boolean;
  correspondenceCourse: boolean;
  modules: CourseModule[];
  status: CourseUnitStatus;
}
interface CourseUnitProps {
  unit?: CourseUnitNodeDataFragment;
  locations: {
    id: string;
    city?: Maybe<string>;
    name: string;
    commonName: string;
  }[];
  searchableLocations: {
    id: string;
    city?: Maybe<string>;
    name: string;
    commonName: string;
  }[];
  onDelete?: (id) => void;
  onSave?: (id) => void;
  onDuplicate?: (id: string) => void;
  defaultValues?: CourseUnitMiniEditorValues;
}

export const CourseUnitNode: ComponentType<CourseUnitProps> = (props) => {
  const {
    unit,
    onDelete,
    onSave,
    onDuplicate,
    locations,
    searchableLocations,
    defaultValues = {
      status: CourseUnitStatus.Active,
      startDate: '',
      endDate: '',
      dateUncertain: false,
      withTime: false,
      title: '',
      id: '',
      location: locations?.length > 0 ? locations[0] : null,
      searchableLocation: null,
      online: false,
      correspondenceCourse: false,
      modules: [],
    },
  } = props;

  const locationOptions: SelectFieldProps['choices'] = useMemo(
    () =>
      locations.map((entry) => ({
        value: entry.id,
        label: entry.name || entry.city || '?',
      })),
    [locations],
  );

  const searchableLocationOptions: SelectFieldProps['choices'] = useMemo(
    () =>
      searchableLocations.map((entry) => ({
        value: entry.id,
        label: entry.name || entry.city || '?',
      })),
    [searchableLocations],
  );

  const [localUnit, setLocalUnit] = useState<CourseUnitNodeDataFragment>(
    unit || defaultValues,
  );

  const onChangeStartDate = useCallback((value) => {
    setLocalUnit((unit) => ({
      ...unit,
      startDate: value,
    }));
  }, []);

  const onChangeEndDate = useCallback((value) => {
    setLocalUnit((unit) => ({
      ...unit,
      endDate: value,
    }));
  }, []);

  const onChangeWithTime = useCallback(
    (value: boolean) =>
      setLocalUnit((unit) => ({
        ...unit,
        withTime: value,
      })),
    [],
  );

  const onChangeDateUncertain = useCallback(
    (value: boolean) =>
      setLocalUnit((unit) => ({
        ...unit,
        dateUncertain: value,
      })),
    [],
  );

  const onChangeLocation = useCallback(
    (value) =>
      setLocalUnit((unit) => ({
        ...unit,
        location: locations.find((entry) => entry.id === value),
      })),
    [locations],
  );

  const onChangeSearchableLocation = useCallback(
    (value) =>
      setLocalUnit((unit) => ({
        ...unit,
        searchableLocation: searchableLocations.find(
          (entry) => entry.id === value,
        ),
      })),
    [searchableLocations],
  );

  const onChangeOnline = useCallback(
    (value: boolean) =>
      setLocalUnit((unit) => ({
        ...unit,
        online: value,
      })),
    [],
  );

  const onChangeCorrespondenceCourse = useCallback(
    (value: boolean) =>
      setLocalUnit((unit) => ({
        ...unit,
        correspondenceCourse: value,
      })),
    [],
  );
  const onSaveCallback = useCallback(
    () => onSave && onSave(localUnit),
    [localUnit, onSave],
  );

  const onDeleteCallback = useCallback(
    () => onDelete && onDelete(localUnit),
    [localUnit, onSave],
  );

  return (
    <div className={classNames(styles.unit, !localUnit.id && styles.new)}>
      <div className={styles.upper}>
        {unit && <CourseUnitNodeTitle courseUnit={unit} />}
        <div className={styles.fields}>
          <div className={styles.section}>
            <DatetimeField
              label="Start"
              withTime={localUnit.withTime}
              value={localUnit.startDate}
              onChange={onChangeStartDate}
            />
            <DatetimeField
              label="Ende"
              withTime={localUnit.withTime}
              value={localUnit.endDate}
              onChange={onChangeEndDate}
            />
          </div>
          <div className={styles.section}>
            <SelectField
              nullable
              label="Standort"
              choices={locationOptions}
              value={localUnit.location?.id || null}
              onChange={onChangeLocation}
            />
            <SelectField
              nullable
              label="Standort relevant für die Suche (falls abweichend)"
              choices={searchableLocationOptions}
              value={localUnit.searchableLocation?.id || null}
              onChange={onChangeSearchableLocation}
            />
          </div>
          <div className={styles.section}>
            <Checkbox
              name="withTime"
              label="Uhrzeit berücksichtigen"
              checked={localUnit.withTime}
              onChange={(e) => {
                onChangeWithTime(e);
              }}
            />
            <Checkbox
              name="dateUncertain"
              label="Datum noch nicht sicher"
              checked={localUnit.dateUncertain}
              onChange={(e) => {
                onChangeDateUncertain(e);
              }}
            />
            <Checkbox
              name="online"
              label="Online"
              checked={localUnit.online}
              onChange={(e) => {
                onChangeOnline(e);
              }}
            />
            <Checkbox
              name="correspondenceCourse"
              label="Fernkurs"
              checked={localUnit.correspondenceCourse}
              onChange={(e) => {
                onChangeCorrespondenceCourse(e);
              }}
            />
          </div>

          <div className={styles.section}>
            {unit && unit.modules.length > 0 && (
              <>
                <label className={globalStyles.label}>Module</label>
                <ul className={styles.modules}>
                  {unit?.modules.map((module) => (
                    <li
                      key={module.id}
                      className={classNames(
                        module.status === CourseModuleStatus.Inactive
                          ? globalStyles.disabled
                          : undefined,
                      )}
                    >
                      <Link to={`./${unit.id}/modules/${module.id}/edit`}>
                        {module.title}
                      </Link>
                      <div className={globalStyles.sub}>
                        <Link
                          style={{ color: '#000000' }}
                          to={`./${unit.id}/modules/${module.id}/edit`}
                        >
                          <Formatted.Date value={module.startDate} /> bis{' '}
                          <Formatted.Date value={module.endDate} />
                        </Link>
                        {module.status === CourseModuleStatus.Inactive && (
                          <>
                            {' '}
                            <span className={globalStyles.error}>
                              [INAKTIV]
                            </span>
                          </>
                        )}
                        {module.tags && <TagInspect tags={module.tags} />}
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div className={styles.section}></div>
        </div>

        <div className={styles.buttons}>
          {onSave && (
            <Button
              small
              className={styles.save}
              primary
              label="Speichern"
              onClick={onSaveCallback}
            />
          )}
          {localUnit.id && (
            <Button
              small
              className={styles.edit}
              label="Bearbeiten"
              linkTo={`/course-management/units/${localUnit.id}/edit`}
            />
          )}
          {onDuplicate && (
            <Button
              className={styles.duplicate}
              small
              label="++"
              onClick={() => onDuplicate(localUnit.id)}
            />
          )}
          {onDelete && localUnit.id && (
            <Button
              className={styles.delete}
              warning
              small
              transparent
              icon={binIcon}
              onClick={onDeleteCallback}
            />
          )}
          {onDelete && !localUnit.id && (
            <Button small label="Abbrechen" onClick={onDeleteCallback} />
          )}
        </div>
      </div>
      <div className={styles.lower}>
        <TagInspect tags={localUnit.tags} />
      </div>
    </div>
  );
};
