import classNames from 'classnames';
import { parseISO } from 'date-fns';
import propertyPath from 'property-path';
import React, { forwardRef, ReactNode, useMemo } from 'react';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import { FormState } from 'react-hook-form';
import { isValidDate } from 'shared/utils';
import globalStyles from 'styles/global.scss';
import styles from './datetime-field.module.scss';

export interface DatetimeFieldProps {
  white?: boolean;
  withTime?: boolean;
  small?: boolean;
  inline?: boolean;
  compact?: boolean;
  value?: string | Date | null;
  name?: string;
  onChange?: (e: any) => void;
  error?: string;
  label?: ReactNode | null;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
  noMargin?: boolean;
}

export const DatetimeField = forwardRef<HTMLInputElement, DatetimeFieldProps>(
  (props, ref) => {
    const {
      noMargin,
      error,
      label,
      white,
      value,
      formState,
      small,
      inline,
      compact,
      onChange,
      withTime = false,
      ...rest
    } = props;

    const errorMessage = useMemo(() => {
      if (props.name && formState?.errors) {
        return propertyPath.get(formState.errors, props.name)?.message;
      }
      return error;
    }, [error, formState, props.name]);

    const currentValue = useMemo(() => {
      if (typeof value === 'object') {
        return value;
      }
      let transformedValue: any = null;
      try {
        transformedValue = value ? parseISO(value) : null;
      } catch (e) {
        console.error(e);
      }
      if (!isValidDate(transformedValue)) {
        return null;
      }
      return transformedValue;
    }, [value]);
    return (
      <div
        className={classNames(
          styles.host,
          noMargin && styles.noMargin,
          small && styles.small,
          inline && styles.inline,
          compact && styles.compact,
          white && styles.white,
          'datetime-field',
        )}
      >
        {label && (
          <label>
            {label}
            {props.required && ' *'}
          </label>
        )}
        {withTime && (
          <DateTimePicker
            showLeadingZeros={true}
            ref={ref}
            {...rest}
            onChange={onChange}
            value={currentValue || null}
          />
        )}
        {!withTime && (
          <DatePicker
            showLeadingZeros={true}
            //ref={ref}
            {...rest}
            onChange={onChange}
            value={currentValue || null}
          />
        )}
        {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
      </div>
    );
  },
);
