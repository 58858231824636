import plusIcon from '@/assets/image/icons/plus.svg';
import { CourseUnit } from '@/graphql/generated/types';
import {
  CourseUnitFilterType,
  CourseUnitListFilter,
} from '@/shared/course/course-unit-list/filter.component';
import { useTreeEdit } from '@/shared/tree-edit/tree-edit.provider';
import { TagInspect } from '@/tag/tag-inspect/tag-inspect.component';
import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { Loader } from '@/ui/loader/loader.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { error, success } from '@/ui/toaster/toast';
import { Link } from '@reach/router';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CourseUnitNodeDataFragment,
  useCourseWithCourseUnitsLazyQuery,
} from 'shared/course/course-unit-list/course-unit-list.generated';
import { useDuplicateCourseUnit } from 'shared/course/duplicator/course-unit-duplicator.hook';
import { useUrlQuery } from 'shared/hooks/url-query';
import { useResources } from 'shared/resources/resources.provider';
import {
  CourseUnitMiniEditorValues,
  CourseUnitNode,
} from '../course-unit-node/course-unit-node.component';
import {
  useCreateCourseUnitMutation,
  useDeleteCourseUnitMutation,
  useUpdateCourseUnitMutation,
} from './course-unit-list.generated';
import styles from './course-unit-list.module.scss';

interface CourseUnitListProps {}

export const CourseUnitList: ComponentType<CourseUnitListProps> = (props) => {
  const { currentCourseAndEnterprise } = useTreeEdit();
  const [currentCourse, enterpriseId] = currentCourseAndEnterprise || [];
  const [create, setCreate] = useState<boolean | CourseUnitMiniEditorValues>(
    false,
  );

  const { open: openDuplicationModal, modal: duplicationModal } =
    useDuplicateCourseUnit(() => {
      refetchUnits();
    });

  const { availableLocations } = useResources();

  const [filter] = useUrlQuery<CourseUnitFilterType>({ omitPastUnits: 'true' });

  const [refetchUnits, { data, loading: loadingUnits }] =
    useCourseWithCourseUnitsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        id: currentCourse,
        options: {
          ...filter,
          includeInactive: filter.includeInactive === 'true' ? true : false,
          isOnline: filter.isOnline === 'true' ? true : false,
          isCorrespondenceCourse:
            filter.isCorrespondenceCourse === 'true' ? true : false,
          sortByStartDate: filter.sortByStartDate === 'DESC' ? 'DESC' : 'ASC',
          omitPastUnits: filter.omitPastUnits === 'true' ? true : false,
        },
      },
    });

  useEffect(() => {
    refetchUnits({
      variables: {
        id: currentCourse,
        options: {
          ...filter,
          includeInactive: filter.includeInactive === 'true' ? true : false,
          isOnline: filter.isOnline === 'true' ? true : false,
          isCorrespondenceCourse:
            filter.isCorrespondenceCourse === 'true' ? true : false,
          sortByStartDate: filter.sortByStartDate === 'DESC' ? 'DESC' : 'ASC',
          omitPastUnits: filter.omitPastUnits === 'true' ? true : false,
        },
      },
    });
  }, [currentCourse, filter]);

  const [createCourseUnit] = useCreateCourseUnitMutation();
  const [updateCourseUnit] = useUpdateCourseUnitMutation();
  const [deleteCourseUnit] = useDeleteCourseUnitMutation();

  const [confirmModal, confirmDelete] = useConfirm(
    async (unit: CourseUnitNodeDataFragment) => {
      try {
        await deleteCourseUnit({ variables: { id: unit.id } });
        refetchUnits();
        success('Die Kurs-Einheit wurde gelöscht.');
      } catch (e) {
        error(
          'Beim Löschen der Kurs-Einheit ist ein Fehler aufgetreten. Bestehen bereits Buchungen für diese Einheit?',
        );
      }
    },
  );

  const locationsForCourse = useMemo(() => {
    return availableLocations.filter((location) =>
      location.enterprises?.map((c) => c.id).includes(enterpriseId),
    );
  }, [availableLocations, enterpriseId]);

  const searchableLocations = useMemo(() => {
    return locationsForCourse.filter((location) => location.parentId === null);
  }, [locationsForCourse]);

  const currentUnits = useMemo(() => {
    return data?.adminCourse.courseUnitsForAdmin || [];
  }, [data?.adminCourse.courseUnitsForAdmin]);

  const onCreateUnit = useCallback(
    (event) => {
      /* if (open) {
        event.stopPropagation();
      } */
      setCreate(true);
    },
    [open],
  );

  useEffect(() => {
    if (currentCourse) {
      refetchUnits();
    }
  }, [currentCourse]);

  const onSave = useCallback(
    async (unit: CourseUnitNodeDataFragment) => {
      if (!unit) {
        return;
      }
      if (unit.id) {
        if (unit.startDate && unit.endDate && currentCourse) {
          await updateCourseUnit({
            variables: {
              id: unit.id,
              input: {
                startDate: unit.startDate,
                endDate: unit.endDate,
                withTime: unit.withTime,
                dateUncertain: unit.dateUncertain,
                courseId: currentCourse,
                locationId: unit.location?.id || null,
                searchableLocationId: unit.searchableLocation?.id || null,
                online: unit.online,
                correspondenceCourse: unit.correspondenceCourse,
                status: unit.status,
              },
            },
          });
          success('Gespeichert');
          refetchUnits();
          setCreate(false);
        } else {
          alert('Bitte Start- und Enddatum angeben!');
        }
      } else {
        if (unit.startDate && unit.endDate && currentCourse) {
          await createCourseUnit({
            variables: {
              input: {
                startDate: unit.startDate,
                endDate: unit.endDate,
                withTime: unit.withTime,
                dateUncertain: unit.dateUncertain,
                courseId: currentCourse,
                locationId: unit.location?.id,
                searchableLocationId: unit.searchableLocation?.id,
                online: unit.online,
                correspondenceCourse: unit.correspondenceCourse,
                status: unit.status,
              },
            },
          });
          setCreate(false);
          await refetchUnits();
        } else {
          alert('Bitte Start- und Enddatum angeben!');
        }
      }
    },
    [currentCourse],
  );

  const onDelete = useCallback((unit: CourseUnitNodeDataFragment) => {
    if (!unit.id) {
      setCreate(false);
    } else {
      confirmDelete(
        <>
          Soll die Kurs-Einheit <em>{unit.title}</em> wirklich gelöscht werden?
        </>,
        unit,
      );
    }
  }, []);

  return (
    <>
      <CourseUnitListFilter />
      <Box
        className={styles.host}
        title={
          <>
            <div className={styles.title}>
              {data?.adminCourse.title || 'Bitte Kurs wählen ...'}
              <TagInspect tags={data?.adminCourse.tags} />
            </div>

            <div className={styles.actions}>
              {currentCourse && (
                <Link to={`/course-management/courses/${currentCourse}/edit`}>
                  Kurs bearbeiten
                </Link>
              )}
            </div>
          </>
        }
      >
        <div className={styles.actions}>
          {currentCourse && (
            <Button
              primary
              round
              small
              icon={plusIcon}
              onClick={onCreateUnit}
            />
          )}
        </div>
        {confirmModal}
        {duplicationModal}
        {create && (
          <CourseUnitNode
            searchableLocations={searchableLocations}
            locations={locationsForCourse}
            onDelete={onDelete}
            onSave={onSave}
            defaultValues={typeof create === 'boolean' ? undefined : create}
          />
        )}
        {loadingUnits && <Loader small />}
        {!loadingUnits && !create && !(currentUnits?.length || 0) && (
          <div className="light">Keine Kurseinheiten</div>
        )}
        {currentUnits?.map((unit) => (
          <CourseUnitNode
            key={unit.id}
            unit={unit}
            locations={locationsForCourse}
            searchableLocations={searchableLocations}
            onDelete={onDelete}
            onSave={onSave}
            onDuplicate={() => openDuplicationModal(unit as CourseUnit)}
          />
        ))}
      </Box>
    </>
  );
};
