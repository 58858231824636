import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PersonListData_Person_Fragment = { __typename?: 'Person', id: any, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, user?: { __typename: 'Administrator', roleIds?: Array<any> | null, isBot: boolean, id: any, username?: string | null, status: Types.UserStatus, roles: Array<{ __typename?: 'Role', id: any, name: Types.RoleType }> } | { __typename: 'Customer', id: any, username?: string | null, status: Types.UserStatus } | { __typename: 'Teacher', id: any, username?: string | null, status: Types.UserStatus } | null, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }>, contactProperties: Array<{ __typename?: 'ContactProperty', key: Types.ContactPropertyKey, value: string }> };

export type PersonListData_PersonWithDuplicates_Fragment = { __typename?: 'PersonWithDuplicates', id: any, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, duplicates?: Array<{ __typename?: 'Duplicate', reasons: Array<string> }> | null, user?: { __typename: 'Administrator', roleIds?: Array<any> | null, isBot: boolean, id: any, username?: string | null, status: Types.UserStatus, roles: Array<{ __typename?: 'Role', id: any, name: Types.RoleType }> } | { __typename: 'Customer', id: any, username?: string | null, status: Types.UserStatus } | { __typename: 'Teacher', id: any, username?: string | null, status: Types.UserStatus } | null, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }>, contactProperties: Array<{ __typename?: 'ContactProperty', key: Types.ContactPropertyKey, value: string }> };

export type PersonListDataFragment = PersonListData_Person_Fragment | PersonListData_PersonWithDuplicates_Fragment;

export type PersonsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.FilterPersonInput>;
  withDuplicates?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type PersonsQuery = { __typename?: 'Query', persons: { __typename?: 'PaginatedPersonWithDuplicatesResult', totalCount: number, data: Array<{ __typename?: 'PersonWithDuplicates', id: any, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, duplicates?: Array<{ __typename?: 'Duplicate', reasons: Array<string> }> | null, user?: { __typename: 'Administrator', roleIds?: Array<any> | null, isBot: boolean, id: any, username?: string | null, status: Types.UserStatus, roles: Array<{ __typename?: 'Role', id: any, name: Types.RoleType }> } | { __typename: 'Customer', id: any, username?: string | null, status: Types.UserStatus } | { __typename: 'Teacher', id: any, username?: string | null, status: Types.UserStatus } | null, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }>, contactProperties: Array<{ __typename?: 'ContactProperty', key: Types.ContactPropertyKey, value: string }> }> } };

export const PersonListDataFragmentDoc = gql`
    fragment PersonListData on PersonInterface {
  id
  firstName
  lastName
  user {
    id
    __typename
    username
    status
    ... on Administrator {
      roles {
        id
        name
      }
      roleIds
      isBot
    }
  }
  enterprises {
    id
    name
  }
  contactProperties {
    key
    value
  }
  ... on PersonWithDuplicates {
    duplicates {
      reasons
    }
  }
  anonymizedAt
}
    `;
export const PersonsDocument = gql`
    query persons($pagination: PaginationInput!, $filter: FilterPersonInput, $withDuplicates: Boolean) {
  persons(
    pagination: $pagination
    filter: $filter
    withDuplicates: $withDuplicates
  ) {
    totalCount
    data {
      ...PersonListData
    }
  }
}
    ${PersonListDataFragmentDoc}`;
export type PersonsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PersonsQuery, PersonsQueryVariables>, 'query'> & ({ variables: PersonsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PersonsComponent = (props: PersonsComponentProps) => (
      <ApolloReactComponents.Query<PersonsQuery, PersonsQueryVariables> query={PersonsDocument} {...props} />
    );
    

/**
 * __usePersonsQuery__
 *
 * To run a query within a React component, call `usePersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *      withDuplicates: // value for 'withDuplicates'
 *   },
 * });
 */
export function usePersonsQuery(baseOptions: Apollo.QueryHookOptions<PersonsQuery, PersonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonsQuery, PersonsQueryVariables>(PersonsDocument, options);
      }
export function usePersonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonsQuery, PersonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonsQuery, PersonsQueryVariables>(PersonsDocument, options);
        }
export type PersonsQueryHookResult = ReturnType<typeof usePersonsQuery>;
export type PersonsLazyQueryHookResult = ReturnType<typeof usePersonsLazyQuery>;
export type PersonsQueryResult = Apollo.QueryResult<PersonsQuery, PersonsQueryVariables>;