import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ProductData_LocationProduct_Fragment = { __typename?: 'LocationProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, enterprise: { __typename?: 'Enterprise', id: any, name: string } };

export type ProductData_SimpleProduct_Fragment = { __typename?: 'SimpleProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, enterprise: { __typename?: 'Enterprise', id: any, name: string } };

export type ProductDataFragment = ProductData_LocationProduct_Fragment | ProductData_SimpleProduct_Fragment;

export type ProductsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.FilterProductsInput>;
}>;


export type ProductsQuery = { __typename?: 'Query', adminProducts: { __typename?: 'PaginatedProductResult', totalCount: number, data: Array<{ __typename?: 'LocationProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, enterprise: { __typename?: 'Enterprise', id: any, name: string } } | { __typename?: 'SimpleProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, enterprise: { __typename?: 'Enterprise', id: any, name: string } }> } };

export const ProductDataFragmentDoc = gql`
    fragment ProductData on Product {
  id
  title
  description
  amount
  status
  enterprise {
    id
    name
  }
}
    `;
export const ProductsDocument = gql`
    query products($pagination: PaginationInput!, $filter: FilterProductsInput) {
  adminProducts(pagination: $pagination, filter: $filter) {
    totalCount
    data {
      ...ProductData
    }
  }
}
    ${ProductDataFragmentDoc}`;
export type ProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductsQuery, ProductsQueryVariables>, 'query'> & ({ variables: ProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductsComponent = (props: ProductsComponentProps) => (
      <ApolloReactComponents.Query<ProductsQuery, ProductsQueryVariables> query={ProductsDocument} {...props} />
    );
    

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;