import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { PersonDetailDataFragmentDoc } from '../../../contact/person/editor/editor.generated';
import { ItemDetailDataFragmentDoc } from '../../shared/item/item.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type OrderQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type OrderQuery = {
  __typename?: 'Query';
  adminOrder: {
    __typename?: 'Order';
    id: any;
    createdAt: any;
    status: Types.OrderStatus;
    useInvoiceAddress: boolean;
    comment?: string | null;
    total: number;
    options?: any | null;
    customer: {
      __typename?: 'Customer';
      id: any;
      username?: string | null;
      person: {
        __typename?: 'Person';
        internalNotes?: string | null;
        enterpriseIds?: Array<any> | null;
        id: any;
        createdAt: any;
        title?: string | null;
        gender?: Types.Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        anonymizedAt?: any | null;
        employments: Array<{
          __typename?: 'Employment';
          id: any;
          description?: string | null;
          companyId: any;
          company: { __typename?: 'Company'; id: any; name: string };
        }>;
        enterprises: Array<{
          __typename?: 'Enterprise';
          id: any;
          name: string;
        }>;
        user?:
          | {
              __typename?: 'Administrator';
              roleIds?: Array<any> | null;
              isBot: boolean;
              id: any;
              username?: string | null;
              fullName: string;
              primaryEmail?: string | null;
              status: Types.UserStatus;
              description?: string | null;
              roles: Array<{
                __typename?: 'Role';
                id: any;
                name: Types.RoleType;
              }>;
              image?: {
                __typename?: 'FilesystemFile';
                id: any;
                url: string;
              } | null;
            }
          | {
              __typename?: 'Customer';
              id: any;
              username?: string | null;
              fullName: string;
              primaryEmail?: string | null;
              status: Types.UserStatus;
              description?: string | null;
              orders: Array<{
                __typename?: 'Order';
                id: any;
                createdAt: any;
                total: number;
              }>;
              bookings: Array<
                | {
                    __typename?: 'CourseModuleBooking';
                    kind: Types.BookingKind;
                    id: any;
                    createdAt: any;
                    courseModule: {
                      __typename?: 'CourseModule';
                      id: any;
                      title?: string | null;
                      sku: string;
                      startDate: any;
                      endDate: any;
                      dateUncertain: boolean;
                      withTime: boolean;
                      online: boolean;
                      correspondenceCourse: boolean;
                      location?: {
                        __typename?: 'Location';
                        id: any;
                        commonName: string;
                      } | null;
                      searchableLocation?: {
                        __typename?: 'Location';
                        id: any;
                        commonName: string;
                      } | null;
                      courseUnit: {
                        __typename?: 'CourseUnit';
                        id: any;
                        course: {
                          __typename?: 'Course';
                          id: any;
                          title: string;
                          verboseTitle?: string | null;
                          abbreviation: string;
                        };
                      };
                    };
                  }
                | {
                    __typename?: 'CourseUnitBooking';
                    kind: Types.BookingKind;
                    id: any;
                    createdAt: any;
                    courseUnit: {
                      __typename?: 'CourseUnit';
                      id: any;
                      title?: string | null;
                      sku: string;
                      startDate: any;
                      endDate: any;
                      dateUncertain: boolean;
                      withTime: boolean;
                      online: boolean;
                      correspondenceCourse: boolean;
                      location?: {
                        __typename?: 'Location';
                        id: any;
                        commonName: string;
                      } | null;
                      searchableLocation?: {
                        __typename?: 'Location';
                        id: any;
                        commonName: string;
                      } | null;
                      course: {
                        __typename?: 'Course';
                        id: any;
                        title: string;
                        verboseTitle?: string | null;
                        abbreviation: string;
                      };
                    };
                  }
              >;
              image?: {
                __typename?: 'FilesystemFile';
                id: any;
                url: string;
              } | null;
            }
          | {
              __typename?: 'Teacher';
              jobTitle?: string | null;
              subjectArea?: string | null;
              slug?: string | null;
              id: any;
              username?: string | null;
              fullName: string;
              primaryEmail?: string | null;
              status: Types.UserStatus;
              description?: string | null;
              image?: {
                __typename?: 'FilesystemFile';
                id: any;
                url: string;
              } | null;
            }
          | null;
        contactProperties: Array<{
          __typename?: 'ContactProperty';
          id: any;
          key: Types.ContactPropertyKey;
          value: string;
        }>;
        inquiries: Array<{
          __typename?: 'Inquiry';
          id: any;
          createdAt: any;
          status: Types.InquiryStatus;
          assignee?: {
            __typename?: 'Administrator';
            id: any;
            fullName: string;
          } | null;
        }>;
        address: {
          __typename?: 'Address';
          company?: string | null;
          contactPerson?: string | null;
          streetAndNumber?: string | null;
          postalCode?: string | null;
          city?: string | null;
          country?: string | null;
          other?: string | null;
        };
        invoiceAddress: {
          __typename?: 'Address';
          company?: string | null;
          contactPerson?: string | null;
          streetAndNumber?: string | null;
          postalCode?: string | null;
          city?: string | null;
          country?: string | null;
          other?: string | null;
        };
      };
    };
    items: Array<
      | {
          __typename?: 'BookingOrderItem';
          id: any;
          quantity: number;
          amount: number;
          booking:
            | {
                __typename?: 'CourseModuleBooking';
                id: any;
                courseModule: {
                  __typename?: 'CourseModule';
                  sku: string;
                  startDate: any;
                  endDate: any;
                  dateUncertain: boolean;
                  withTime: boolean;
                  location?: {
                    __typename?: 'Location';
                    id: any;
                    commonName: string;
                  } | null;
                  courseUnit: {
                    __typename?: 'CourseUnit';
                    id: any;
                    course: {
                      __typename?: 'Course';
                      id: any;
                      abbreviation: string;
                      verboseTitle?: string | null;
                      categoryId: any;
                      category: {
                        __typename?: 'CourseCategory';
                        id: any;
                        enterprise: { __typename?: 'Enterprise'; name: string };
                      };
                    };
                  };
                };
              }
            | {
                __typename?: 'CourseUnitBooking';
                id: any;
                courseUnit: {
                  __typename?: 'CourseUnit';
                  sku: string;
                  startDate: any;
                  endDate: any;
                  dateUncertain: boolean;
                  withTime: boolean;
                  location?: {
                    __typename?: 'Location';
                    id: any;
                    commonName: string;
                  } | null;
                  course: {
                    __typename?: 'Course';
                    id: any;
                    abbreviation: string;
                    verboseTitle?: string | null;
                    categoryId: any;
                    category: {
                      __typename?: 'CourseCategory';
                      id: any;
                      enterprise: { __typename?: 'Enterprise'; name: string };
                    };
                  };
                };
              };
          discount: {
            __typename?: 'DiscountEmbedded';
            type: Types.DiscountType;
            amount?: number | null;
            reason?: string | null;
          };
          discounts: Array<{
            __typename?: 'Discount';
            id: any;
            type: Types.DiscountType;
            amount?: number | null;
            reason?: string | null;
          }>;
        }
      | {
          __typename?: 'DiscountOrderItem';
          id: any;
          quantity: number;
          amount: number;
          discountRule?: {
            __typename?: 'DiscountRule';
            type: Types.DiscountType;
            amount: number;
          } | null;
          discount: {
            __typename?: 'DiscountEmbedded';
            type: Types.DiscountType;
            amount?: number | null;
            reason?: string | null;
          };
          discounts: Array<{
            __typename?: 'Discount';
            id: any;
            type: Types.DiscountType;
            amount?: number | null;
            reason?: string | null;
          }>;
        }
      | {
          __typename?: 'PackageBookingOrderItem';
          id: any;
          quantity: number;
          amount: number;
          package: {
            __typename?: 'Package';
            title: string;
            subTitle?: string | null;
            enterprise: { __typename?: 'Enterprise'; id: any; name: string };
          };
          discount: {
            __typename?: 'DiscountEmbedded';
            type: Types.DiscountType;
            amount?: number | null;
            reason?: string | null;
          };
          discounts: Array<{
            __typename?: 'Discount';
            id: any;
            type: Types.DiscountType;
            amount?: number | null;
            reason?: string | null;
          }>;
        }
      | {
          __typename?: 'ProductOrderItem';
          id: any;
          quantity: number;
          amount: number;
          product:
            | {
                __typename?: 'LocationProduct';
                id: any;
                sku: string;
                title: string;
                enterprise: { __typename?: 'Enterprise'; name: string };
              }
            | {
                __typename?: 'SimpleProduct';
                id: any;
                sku: string;
                title: string;
                enterprise: { __typename?: 'Enterprise'; name: string };
              };
          discount: {
            __typename?: 'DiscountEmbedded';
            type: Types.DiscountType;
            amount?: number | null;
            reason?: string | null;
          };
          discounts: Array<{
            __typename?: 'Discount';
            id: any;
            type: Types.DiscountType;
            amount?: number | null;
            reason?: string | null;
          }>;
        }
    >;
    discounts: Array<{
      __typename?: 'Discount';
      id: any;
      type: Types.DiscountType;
      amount?: number | null;
      reason?: string | null;
    }>;
  };
};

export const OrderDocument = gql`
  query order($id: UUID!) {
    adminOrder(id: $id) {
      id
      createdAt
      status
      useInvoiceAddress
      comment
      total
      options
      customer {
        id
        username
        person {
          ...PersonDetailData
        }
      }
      items {
        ...ItemDetailData
      }
      discounts {
        id
        type
        amount
        reason
      }
    }
  }
  ${PersonDetailDataFragmentDoc}
  ${ItemDetailDataFragmentDoc}
`;
export type OrderComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<OrderQuery, OrderQueryVariables>,
  'query'
> &
  ({ variables: OrderQueryVariables; skip?: boolean } | { skip: boolean });

export const OrderComponent = (props: OrderComponentProps) => (
  <ApolloReactComponents.Query<OrderQuery, OrderQueryVariables>
    query={OrderDocument}
    {...props}
  />
);

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options,
  );
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options,
  );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<
  OrderQuery,
  OrderQueryVariables
>;
