import { addressSchema } from '@/address/address.schema';
import { contactPropertySchema } from '@/contact/shared/contact-property/contact-property.schema';
import { FormError } from '@/ui/form/form.component';
import { array, object, string } from 'yup';
import { employmentSchema } from '../../employment/employment.schema';

export const personSchema = object({
  firstName: string()
    .typeError(FormError.required)
    .required(FormError.required)
    .min(3, FormError.minLength({ min: 3 })),
  lastName: string()
    .typeError(FormError.required)
    .required(FormError.required)
    .min(3, FormError.minLength({ min: 3 })),
  address: addressSchema.nullable(),
  contactProperties: array().of(contactPropertySchema),
  employments: array().of(employmentSchema),
  enterpriseIds: array()
    .of(string())
    .min(1, FormError.minItems({ min: 1 })),
});
