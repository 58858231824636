import { Button } from '@/ui/button/button.component';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import { add, sub } from 'date-fns';
import React, { ComponentType, useCallback } from 'react';
import globalStyles from 'styles/global.scss';
import { useRoomManagement } from '../../room-management-context';
import styles from './date-picker.module.scss';

interface DatePickerProps {}

export const DatePicker: ComponentType<DatePickerProps> = (props) => {
  const { currentDate, setCurrentDate } = useRoomManagement();

  const today = new Date();

  const onChangeStartDate = useCallback((value) => {
    setCurrentDate(value);
  }, []);

  const onChangeOneDay = useCallback(
    (value: 'inc' | 'dec') => {
      if (value === 'inc') {
        setCurrentDate(add(currentDate, { days: 1 }));
      }
      if (value === 'dec') {
        setCurrentDate(sub(currentDate, { days: 1 }));
      }
    },
    [currentDate],
  );

  return (
    <>
      <div className={styles.host}>
        <DatetimeField
          label="Start"
          value={currentDate}
          onChange={onChangeStartDate}
        />

        <div className={globalStyles.actions}>
          <Button
            primary
            small
            label="Zurück"
            onClick={() => onChangeOneDay('dec')}
          />
          <Button
            primary
            small
            label="Heute"
            onClick={() => setCurrentDate(today)}
          />
          <Button
            primary
            small
            label="Vor"
            onClick={() => onChangeOneDay('inc')}
          />
        </div>
      </div>
    </>
  );
};
