import { InfoBox } from '@/ui/info-box/info-box.component';
import React, { ComponentType } from 'react';
import { PersonQuery } from './base-editor.generated';

type CustomerEditorProps = {
  user?: PersonQuery['person']['user'];
};

export const NoOpEditor: ComponentType<CustomerEditorProps> = (props) => {
  return (
    <>
      <InfoBox.Todo
        header="In Arbeit"
        content="Das Erzeugen eines Kundendatensatzes aus einer Person steht derzeit nicht zur Verfügung."
      />
    </>
  );
};
