import { Form } from '@/ui/form/form.component';
import { Section } from '@/ui/section/section.component';
import SchemaForm from '@rjsf/core';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React from 'react';
import { usePropertySchemaContextQuery } from './property-schema.generated';

export type PropertyAware = {
  properties: Record<string, any>;
};

export interface PropertySchemaRendererProps<Values extends PropertyAware> {
  enterpriseId: string;
  context: string;
}

export function PropertySchemaRenderer<Values extends PropertyAware>(
  props: PropertySchemaRendererProps<Values>,
) {
  const { enterpriseId, context } = props;
  const { data } = usePropertySchemaContextQuery({
    variables: {
      enterpriseId,
      context,
    },
  });

  return (
    <>
      {data?.adminPropertySchemaContext.map((schema) => (
        <SchemaForm
          schema={JSON.parse(schema.value) as RJSFSchema}
          validator={validator}
          widgets={{
            SelectWidget: ({ options, label, name }) => (
              <Form.Select2
                label={label}
                choices={options.enumOptions ?? []}
                name={`properties.${schema.name}.${name}`}
              />
            ),
          }}
          templates={{
            ObjectFieldTemplate: (props) => (
              <Section title={props.title}>
                <p>{props.description}</p>
                {props.properties.map(({ content }) => content)}
              </Section>
            ),
            FieldHelpTemplate: () => <></>,
            TitleFieldTemplate: () => <></>,
            DescriptionFieldTemplate: () => <></>,
            FieldTemplate: (props) => <>{props.children}</>,
            ButtonTemplates: {
              SubmitButton: () => <></>,
            },
            BaseInputTemplate: (props) => {
              if (props.schema.contentMediaType === 'text/html') {
                return (
                  <Form.Html
                    label={props.label}
                    name={`properties.${schema.name}.${props.name}`}
                  />
                );
              }

              return (
                <Form.Input
                  label={props.label}
                  name={`properties.${schema.name}.${props.name}`}
                />
              );
            },
          }}
        />
      ))}
    </>
  );
}
