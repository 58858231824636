import { Frame } from '@/frame';
import { FileGroup, FilterFileInput } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Thumbnail } from '@/ui/thumbnail/thumbnail.component';
import { error, success } from '@/ui/toaster/toast';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { ComponentType, useCallback } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { useExplorer } from '../explorer/explorer.context';
import { FileListDataFragment } from '../explorer/explorer.generated';
import { FileGroupSelectField } from '../file-group-select-field.component';
import { UploadButton } from '../upload-button/upload-button.component';
import {
  useDeleteFileMutation,
  usePaginatedFilesQuery,
  useReplaceFileMutation,
} from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const _ = Translator.useTranslator();
  const [query] = usePaginatedUrlQuery<{ group: string; search: string }>();
  const { data, loading } = usePaginatedFilesQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      filter: {
        search: query.search,
        group: query.group as FileGroup,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { open } = useExplorer();
  const [deleteFile] = useDeleteFileMutation();
  const [replaceFile] = useReplaceFileMutation();

  const onDeleteClick = useCallback(async (id: string) => {
    try {
      await deleteFile({ variables: { id } });

      success('Die Datei wurde gelöscht.');
      navigate('.');
    } catch (e) {
      error('Fehler');
    }
  }, []);

  return (
    <>
      {/* <Frame.SubTitle>Dateien</Frame.SubTitle> */}
      <Frame.ActionBar>
        <Button onClick={() => open({ withUpload: true })} label="Hochladen" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<FileListDataFragment, FilterFileInput>
          loading={loading}
          data={data?.adminPaginatedFiles?.data || []}
          totalCount={data?.adminPaginatedFiles?.totalCount}
          columns={[
            {
              name: 'Vorschau',
              sortable: false,
            },
            {
              name: 'Dateiname',
              sortable: true,
            },
            {
              name: 'Link / ID',
              sortable: false,
            },
            {
              name: 'Kategorie',
              sortable: true,
            },
            {
              name: 'Dateityp',
              sortable: true,
            },
            {
              name: 'Unternehmen',
              sortable: true,
            },
            {
              name: 'Aktionen',
              sortable: false,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              <>
                <DebouncedInput
                  label="Suche"
                  debounceRate={400}
                  value={value?.search || ''}
                  onChange={(value) => onChange('search', value)}
                  placeholder={'Dateiname'}
                />
                <FileGroupSelectField
                  value={value.group}
                  onChange={(value) => onChange('group', value as FileGroup)}
                />
              </>
            </>
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <a href={item.url} target="_blank">
                  <Thumbnail file={item} />
                </a>
              </td>
              <td>
                <a href={item.url} target="_blank">
                  {item.name}
                </a>
              </td>
              <td>
                <Copyable
                  copyOnClick
                  valueToCopy={item.humanUrl}
                  label={item.humanUrl}
                >
                  URL kopieren
                </Copyable>
                <br />
                <Copyable
                  copyOnClick
                  valueToCopy={`${item.id}|${item.name}`}
                  label={`${item.id}|${item.name}`}
                >
                  ID kopieren
                </Copyable>
              </td>
              <td>{_(`file-group.${item.group}`)}</td>
              <td>{item.mimetype}</td>
              <td>{item.enterprise?.name}</td>
              <td>
                <Button
                  small
                  error
                  fluid
                  label="Löschen"
                  onClick={() => onDeleteClick(item.id)}
                />
                <UploadButton
                  small
                  fluid
                  label="Datei austauschen"
                  onUpload={async (file) => {
                    await replaceFile({
                      variables: {
                        id: item.id,
                        file,
                        input: {
                          group: item.group,
                          enterpriseId: item.enterpriseId,
                        },
                      },
                    });
                    location.reload();
                  }}
                />
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
