import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DeleteBookingMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteBookingMutation = { __typename?: 'Mutation', adminDeleteBooking: boolean };

export type SetBookingStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  status: Types.SetBookingStatus;
}>;


export type SetBookingStatusMutation = { __typename?: 'Mutation', adminSetBookingStatus: boolean };


export const DeleteBookingDocument = gql`
    mutation deleteBooking($id: UUID!) {
  adminDeleteBooking(id: $id)
}
    `;
export type DeleteBookingMutationFn = Apollo.MutationFunction<DeleteBookingMutation, DeleteBookingMutationVariables>;
export type DeleteBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteBookingMutation, DeleteBookingMutationVariables>, 'mutation'>;

    export const DeleteBookingComponent = (props: DeleteBookingComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteBookingMutation, DeleteBookingMutationVariables> mutation={DeleteBookingDocument} {...props} />
    );
    

/**
 * __useDeleteBookingMutation__
 *
 * To run a mutation, you first call `useDeleteBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingMutation, { data, loading, error }] = useDeleteBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingMutation, DeleteBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingMutation, DeleteBookingMutationVariables>(DeleteBookingDocument, options);
      }
export type DeleteBookingMutationHookResult = ReturnType<typeof useDeleteBookingMutation>;
export type DeleteBookingMutationResult = Apollo.MutationResult<DeleteBookingMutation>;
export type DeleteBookingMutationOptions = Apollo.BaseMutationOptions<DeleteBookingMutation, DeleteBookingMutationVariables>;
export const SetBookingStatusDocument = gql`
    mutation setBookingStatus($id: UUID!, $status: SetBookingStatus!) {
  adminSetBookingStatus(id: $id, status: $status)
}
    `;
export type SetBookingStatusMutationFn = Apollo.MutationFunction<SetBookingStatusMutation, SetBookingStatusMutationVariables>;
export type SetBookingStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetBookingStatusMutation, SetBookingStatusMutationVariables>, 'mutation'>;

    export const SetBookingStatusComponent = (props: SetBookingStatusComponentProps) => (
      <ApolloReactComponents.Mutation<SetBookingStatusMutation, SetBookingStatusMutationVariables> mutation={SetBookingStatusDocument} {...props} />
    );
    

/**
 * __useSetBookingStatusMutation__
 *
 * To run a mutation, you first call `useSetBookingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBookingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBookingStatusMutation, { data, loading, error }] = useSetBookingStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetBookingStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetBookingStatusMutation, SetBookingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBookingStatusMutation, SetBookingStatusMutationVariables>(SetBookingStatusDocument, options);
      }
export type SetBookingStatusMutationHookResult = ReturnType<typeof useSetBookingStatusMutation>;
export type SetBookingStatusMutationResult = Apollo.MutationResult<SetBookingStatusMutation>;
export type SetBookingStatusMutationOptions = Apollo.BaseMutationOptions<SetBookingStatusMutation, SetBookingStatusMutationVariables>;