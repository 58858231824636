import { RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Translator } from 'shared/translator/translator.component';
import { CustomerList } from './customer/list.component';
import { PersonList } from './person/list.component';
import { TeacherList } from './teacher/list.component';

export const ListRoutes: ComponentType<RouteComponentProps> = (props) => {
  const _ = Translator.useTranslator();

  return (
    <>
      <Router>
        <PersonList path="persons/list" />
        <CustomerList path="customers/list" />
        <TeacherList path="teachers/list" />
      </Router>
    </>
  );
};
