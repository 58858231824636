import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DetailLocationDataFragment = {
  __typename?: 'Location';
  id: any;
  name: string;
  city?: string | null;
  administrativeEmail?: string | null;
  geoLocation?: any | null;
  parentId?: any | null;
  status: Types.LocationStatus;
  properties: any;
  geoLocationId?: string | null;
  address?: {
    __typename?: 'Address';
    company?: string | null;
    contactPerson?: string | null;
    streetAndNumber?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: string | null;
  } | null;
  enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
  parent?: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    address?: { __typename?: 'Address'; city?: string | null } | null;
  } | null;
};

export type LocationQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type LocationQuery = {
  __typename?: 'Query';
  adminLocation: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    administrativeEmail?: string | null;
    geoLocation?: any | null;
    parentId?: any | null;
    status: Types.LocationStatus;
    properties: any;
    geoLocationId?: string | null;
    address?: {
      __typename?: 'Address';
      company?: string | null;
      contactPerson?: string | null;
      streetAndNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
    } | null;
    enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
    parent?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
  };
};

export type CreateLocationMutationVariables = Types.Exact<{
  input: Types.CreateLocationInput;
}>;

export type CreateLocationMutation = {
  __typename?: 'Mutation';
  adminCreateLocation: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    administrativeEmail?: string | null;
    geoLocation?: any | null;
    parentId?: any | null;
    status: Types.LocationStatus;
    properties: any;
    geoLocationId?: string | null;
    address?: {
      __typename?: 'Address';
      company?: string | null;
      contactPerson?: string | null;
      streetAndNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
    } | null;
    enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
    parent?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
  };
};

export type EditLocationMutationVariables = Types.Exact<{
  input: Types.EditLocationInput;
  id: Types.Scalars['UUID'];
}>;

export type EditLocationMutation = {
  __typename?: 'Mutation';
  adminEditLocation: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    administrativeEmail?: string | null;
    geoLocation?: any | null;
    parentId?: any | null;
    status: Types.LocationStatus;
    properties: any;
    geoLocationId?: string | null;
    address?: {
      __typename?: 'Address';
      company?: string | null;
      contactPerson?: string | null;
      streetAndNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
    } | null;
    enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
    parent?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
  };
};

export type DeleteLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type DeleteLocationMutation = {
  __typename?: 'Mutation';
  adminDeleteLocation: boolean;
};

export const DetailLocationDataFragmentDoc = gql`
  fragment DetailLocationData on Location {
    id
    name
    city
    administrativeEmail
    address {
      company
      contactPerson
      streetAndNumber
      postalCode
      city
      country
    }
    enterprises {
      id
      name
    }
    parent {
      id
      name
      city
      address {
        city
      }
    }
    geoLocation
    parentId
    status
    properties
    geoLocationId
  }
`;
export const LocationDocument = gql`
  query location($id: UUID!) {
    adminLocation(id: $id) {
      ...DetailLocationData
    }
  }
  ${DetailLocationDataFragmentDoc}
`;
export type LocationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LocationQuery,
    LocationQueryVariables
  >,
  'query'
> &
  ({ variables: LocationQueryVariables; skip?: boolean } | { skip: boolean });

export const LocationComponent = (props: LocationComponentProps) => (
  <ApolloReactComponents.Query<LocationQuery, LocationQueryVariables>
    query={LocationDocument}
    {...props}
  />
);

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationQuery(
  baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationQuery, LocationQueryVariables>(
    LocationDocument,
    options,
  );
}
export function useLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationQuery,
    LocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(
    LocationDocument,
    options,
  );
}
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<
  typeof useLocationLazyQuery
>;
export type LocationQueryResult = Apollo.QueryResult<
  LocationQuery,
  LocationQueryVariables
>;
export const CreateLocationDocument = gql`
  mutation createLocation($input: CreateLocationInput!) {
    adminCreateLocation(input: $input) {
      ...DetailLocationData
    }
  }
  ${DetailLocationDataFragmentDoc}
`;
export type CreateLocationMutationFn = Apollo.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export type CreateLocationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >,
  'mutation'
>;

export const CreateLocationComponent = (
  props: CreateLocationComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >
    mutation={CreateLocationDocument}
    {...props}
  />
);

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >(CreateLocationDocument, options);
}
export type CreateLocationMutationHookResult = ReturnType<
  typeof useCreateLocationMutation
>;
export type CreateLocationMutationResult =
  Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export const EditLocationDocument = gql`
  mutation editLocation($input: EditLocationInput!, $id: UUID!) {
    adminEditLocation(input: $input, id: $id) {
      ...DetailLocationData
    }
  }
  ${DetailLocationDataFragmentDoc}
`;
export type EditLocationMutationFn = Apollo.MutationFunction<
  EditLocationMutation,
  EditLocationMutationVariables
>;
export type EditLocationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    EditLocationMutation,
    EditLocationMutationVariables
  >,
  'mutation'
>;

export const EditLocationComponent = (props: EditLocationComponentProps) => (
  <ApolloReactComponents.Mutation<
    EditLocationMutation,
    EditLocationMutationVariables
  >
    mutation={EditLocationDocument}
    {...props}
  />
);

/**
 * __useEditLocationMutation__
 *
 * To run a mutation, you first call `useEditLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLocationMutation, { data, loading, error }] = useEditLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditLocationMutation,
    EditLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditLocationMutation,
    EditLocationMutationVariables
  >(EditLocationDocument, options);
}
export type EditLocationMutationHookResult = ReturnType<
  typeof useEditLocationMutation
>;
export type EditLocationMutationResult =
  Apollo.MutationResult<EditLocationMutation>;
export type EditLocationMutationOptions = Apollo.BaseMutationOptions<
  EditLocationMutation,
  EditLocationMutationVariables
>;
export const DeleteLocationDocument = gql`
  mutation deleteLocation($id: UUID!) {
    adminDeleteLocation(id: $id)
  }
`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;
export type DeleteLocationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >,
  'mutation'
>;

export const DeleteLocationComponent = (
  props: DeleteLocationComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >
    mutation={DeleteLocationDocument}
    {...props}
  />
);

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >(DeleteLocationDocument, options);
}
export type DeleteLocationMutationHookResult = ReturnType<
  typeof useDeleteLocationMutation
>;
export type DeleteLocationMutationResult =
  Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;
