import { useLocation, useNavigate } from '@reach/router';
import React, { ComponentType, useEffect, useMemo } from 'react';
import { Profile, ProfileStatus } from '../profile/profile';

export const AuthGuard: ComponentType = (props) => {
  const { children } = props;
  const status = Profile.useStatus();
  const profile = Profile.use();
  const navigate = useNavigate();
  const location = useLocation();

  const authorized = useMemo(() => {
    // TODO: register public (or all) routes in another place
    if (
      location.pathname === '/login' ||
      location.pathname === '/request-password' ||
      location.pathname.startsWith('/reset-password')
    ) {
      return true;
    }

    if (status === ProfileStatus.AVAILABLE) {
      return true;
    }

    return false;
  }, [status, location, profile]);

  useEffect(() => {
    if (!authorized) {
      navigate('/login', { replace: true });
    }
  }, [authorized]);

  return authorized ? <>{children}</> : null;
};
