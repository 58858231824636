// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YAgLKOwDRAbqqKY0a43I.tnri88bO3_VuSAsp5d7M{display:flex;justify-content:center;align-items:center}.YAgLKOwDRAbqqKY0a43I.x6tFj5BaDsK5_EK0kIiY{display:flex;justify-content:center;align-items:center;flex-wrap:wrap}.YAgLKOwDRAbqqKY0a43I.x6tFj5BaDsK5_EK0kIiY img{display:block;max-width:80%}", "",{"version":3,"sources":["webpack://./src/ui/logo/logo.module.scss"],"names":[],"mappings":"AAKE,2CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,2CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,+CACE,aAAA,CACA,aAAA","sourcesContent":["@import 'styles/variables';\n\n.host {\n  //background: $color-white;\n\n  &.single {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &.list {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    img {\n      display: block;\n      max-width: 80%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "YAgLKOwDRAbqqKY0a43I",
	"single": "tnri88bO3_VuSAsp5d7M",
	"list": "x6tFj5BaDsK5_EK0kIiY"
};
export default ___CSS_LOADER_EXPORT___;
