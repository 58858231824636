import {
  StatisticsBoard,
  StatisticsBoxContext,
  StatisticsBoxType,
} from '@/graphql/generated/types';
import { InputField } from '@/ui/input-field/input-field.component';
import { useConfirm, useCustomDialogue } from '@/ui/modal/modal.hooks';
import { SelectField2 } from '@/ui/select-field-2/select-field-2.component';
import { SelectField } from '@/ui/select-field/select-field.component';
import React, { useState } from 'react';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { useStatisticsBoards } from '../board/board.provider';

export function useBoardModals(board?: StatisticsBoard | null) {
  const { addBoard, deleteBoard, shareBoard, deleteBox, addBox } =
    useStatisticsBoards();
  const { availableAdministrators } = useResources();
  const [nameForNewBoard, setNameForNewBoard] = useState('');
  const [userIdsToShareBoardWith, setUserIdsToShareBoardWith] = useState<
    string[]
  >([]);
  const [nameForNewBox, setNameForNewBox] = useState('');
  const [typeForNewBox, setTypeForNewBox] = useState<StatisticsBoxType>(
    StatisticsBoxType.Chart,
  );
  const [contextForNewBox, setContextForNewBox] =
    useState<StatisticsBoxContext>(StatisticsBoxContext.Orders);

  const _ = Translator.useTranslator();

  // Create Board
  const [askCreateBoardModal, askCreateBoard] = useCustomDialogue(
    <InputField
      label="Name"
      onChange={(e) => setNameForNewBoard(e.target.value)}
      value={nameForNewBoard}
    />,
    {
      title: 'Neues Board erstellen',
      actions: [
        {
          title: 'Abbrechen',
          handler: () => {
            askCreateBoard.close();
          },
        },
        {
          title: 'Erstellen',
          primary: true,
          handler: () => {
            addBoard(nameForNewBoard);
            askCreateBoard.close();
          },
        },
      ],
    },
  );

  // Share Board
  const [askShareBoardModal, askShareBoard] = useCustomDialogue<
    (board: StatisticsBoard) => void
  >(
    <>
      <p>Bitte wähle die Nutzer, die das Board erhalten sollen.</p>
      <SelectField2
        multiple
        choices={availableAdministrators.map((u) => ({
          label: `${u.firstName} ${u.lastName} (${u.user?.username})`,
          value: u.user?.id,
        }))}
        value={userIdsToShareBoardWith}
        onChange={(value) => setUserIdsToShareBoardWith(value as string[])}
        label="Nutzer"
      />
    </>,
    {
      title: `Board "${board?.name}" mit Nutzern teilen`,
      actions: [
        {
          title: 'Abbrechen',
          handler: () => {
            askShareBoard.close();
          },
        },
        {
          title: 'Teilen',
          primary: true,
          handler: () => {
            if (board) {
              shareBoard(board, userIdsToShareBoardWith);
            }

            askShareBoard.close();
          },
        },
      ],
    },
  );
  addBoard;
  const [askDeleteBoardModal, askDeleteBoard] = useConfirm(
    (id: string) => {
      deleteBoard(id);
      // askDeleteBoard.close();
    },
    {
      meaning: 'error',
      title: 'Board löschen',
    },
  );

  // Create Box
  const [askCreateBoxModal, askCreateBox] = useCustomDialogue(
    <>
      <InputField
        label="Name"
        onChange={(e) => setNameForNewBox(e.target.value)}
        value={nameForNewBox}
      />
      <SelectField
        label="Kontext"
        choices={Object.entries(StatisticsBoxContext).map(([key, value]) => ({
          label: _(`StatisticsBoxContext.${value}`),
          value,
        }))}
        value={contextForNewBox}
        onChange={(value) => setContextForNewBox(value as StatisticsBoxContext)}
      />

      <SelectField
        label="Art"
        choices={Object.entries(StatisticsBoxType).map(([key, value]) => ({
          label: _(`StatisticsBoxType.${value}`),
          value,
        }))}
        value={typeForNewBox}
        onChange={(value) => setTypeForNewBox(value as StatisticsBoxType)}
      />
    </>,
    {
      title: 'Neue Box erstellen',
      actions: [
        {
          title: 'Abbrechen',
          handler: () => {
            askCreateBox.close();
          },
        },
        {
          title: 'Erstellen',
          primary: true,
          handler: () => {
            if (board) {
              addBox(nameForNewBox, typeForNewBox, contextForNewBox, board.id);
            }
            askCreateBox.close();
          },
        },
      ],
    },
  );

  // Delete Box
  const [askDeleteBoxModal, askDeleteBox] = useConfirm(
    (id: string) => {
      if (board) {
        deleteBox(id, board.id);
      }
      // askDeleteBoard.close();
    },
    {
      meaning: 'error',
      title: 'Box löschen',
    },
  );

  return {
    askCreateBoardModal,
    askCreateBoard: askCreateBoard.open,
    askDeleteBoardModal,
    askDeleteBoard,
    askShareBoardModal,
    askShareBoard,
    askDeleteBoxModal,
    askDeleteBox,
    askCreateBoxModal,
    askCreateBox,
  };
}
