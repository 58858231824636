import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { ProductDataFragmentDoc } from '../../list/list.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ProductDetailData_LocationProduct_Fragment = { __typename?: 'LocationProduct', locationIds: Array<any>, id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } };

export type ProductDetailData_SimpleProduct_Fragment = { __typename?: 'SimpleProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } };

export type ProductDetailDataFragment = ProductDetailData_LocationProduct_Fragment | ProductDetailData_SimpleProduct_Fragment;

export type ProductQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type ProductQuery = { __typename?: 'Query', adminProduct: { __typename?: 'LocationProduct', locationIds: Array<any>, id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } } | { __typename?: 'SimpleProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } } };

export type EditProductMutationVariables = Types.Exact<{
  input: Types.UpdateProductInput;
  id: Types.Scalars['UUID'];
}>;


export type EditProductMutation = { __typename?: 'Mutation', adminEditProduct: { __typename?: 'LocationProduct', locationIds: Array<any>, id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } } | { __typename?: 'SimpleProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } } };

export type CreateProductMutationVariables = Types.Exact<{
  input: Types.CreateProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', adminCreateProduct: { __typename?: 'LocationProduct', locationIds: Array<any>, id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } } | { __typename?: 'SimpleProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } } };

export type DeleteProductMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', adminDeleteProduct: { __typename?: 'LocationProduct', locationIds: Array<any>, id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } } | { __typename?: 'SimpleProduct', id: any, title: string, description?: string | null, amount: number, status: Types.ProductStatus, attributes: Array<{ __typename?: 'DecimalAttribute', id?: string | null, kind: string } | { __typename?: 'IntAttribute', name: string, intValue?: number | null, id?: string | null, kind: string } | { __typename?: 'JsonAttribute', id?: string | null, kind: string } | { __typename?: 'StringAttribute', id?: string | null, kind: string } | { __typename?: 'TextAttribute', name: string, textValue?: string | null, id?: string | null, kind: string }>, enterprise: { __typename?: 'Enterprise', id: any, name: string } } };

export const ProductDetailDataFragmentDoc = gql`
    fragment ProductDetailData on Product {
  ...ProductData
  attributes: nativeAttributes {
    id
    kind
    ... on IntAttribute {
      name
      intValue
    }
    ... on TextAttribute {
      name
      textValue
    }
  }
  ... on LocationProduct {
    locationIds
  }
}
    ${ProductDataFragmentDoc}`;
export const ProductDocument = gql`
    query product($id: UUID!) {
  adminProduct(id: $id) {
    ...ProductDetailData
  }
}
    ${ProductDetailDataFragmentDoc}`;
export type ProductComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductQuery, ProductQueryVariables>, 'query'> & ({ variables: ProductQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductComponent = (props: ProductComponentProps) => (
      <ApolloReactComponents.Query<ProductQuery, ProductQueryVariables> query={ProductDocument} {...props} />
    );
    

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const EditProductDocument = gql`
    mutation editProduct($input: UpdateProductInput!, $id: UUID!) {
  adminEditProduct(input: $input, id: $id) {
    ...ProductDetailData
  }
}
    ${ProductDetailDataFragmentDoc}`;
export type EditProductMutationFn = Apollo.MutationFunction<EditProductMutation, EditProductMutationVariables>;
export type EditProductComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditProductMutation, EditProductMutationVariables>, 'mutation'>;

    export const EditProductComponent = (props: EditProductComponentProps) => (
      <ApolloReactComponents.Mutation<EditProductMutation, EditProductMutationVariables> mutation={EditProductDocument} {...props} />
    );
    

/**
 * __useEditProductMutation__
 *
 * To run a mutation, you first call `useEditProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductMutation, { data, loading, error }] = useEditProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditProductMutation(baseOptions?: Apollo.MutationHookOptions<EditProductMutation, EditProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProductMutation, EditProductMutationVariables>(EditProductDocument, options);
      }
export type EditProductMutationHookResult = ReturnType<typeof useEditProductMutation>;
export type EditProductMutationResult = Apollo.MutationResult<EditProductMutation>;
export type EditProductMutationOptions = Apollo.BaseMutationOptions<EditProductMutation, EditProductMutationVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($input: CreateProductInput!) {
  adminCreateProduct(input: $input) {
    ...ProductDetailData
  }
}
    ${ProductDetailDataFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;
export type CreateProductComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProductMutation, CreateProductMutationVariables>, 'mutation'>;

    export const CreateProductComponent = (props: CreateProductComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProductMutation, CreateProductMutationVariables> mutation={CreateProductDocument} {...props} />
    );
    

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: UUID!) {
  adminDeleteProduct(id: $id) {
    ...ProductDetailData
  }
}
    ${ProductDetailDataFragmentDoc}`;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;
export type DeleteProductComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProductMutation, DeleteProductMutationVariables>, 'mutation'>;

    export const DeleteProductComponent = (props: DeleteProductComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProductMutation, DeleteProductMutationVariables> mutation={DeleteProductDocument} {...props} />
    );
    

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;