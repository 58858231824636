import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { SeasonDataFragmentDoc } from '../detail/editor/editor.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SeasonsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
}>;


export type SeasonsQuery = { __typename?: 'Query', adminSeasons: { __typename?: 'PaginatedSeasonResult', totalCount: number, data: Array<{ __typename?: 'Season', id: any, title: string, status: Types.SeasonStatus, enterpriseId: any, enterprise: { __typename?: 'Enterprise', name: string } }> } };


export const SeasonsDocument = gql`
    query seasons($pagination: PaginationInput!) {
  adminSeasons(pagination: $pagination) {
    data {
      ...SeasonData
    }
    totalCount
  }
}
    ${SeasonDataFragmentDoc}`;
export type SeasonsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SeasonsQuery, SeasonsQueryVariables>, 'query'> & ({ variables: SeasonsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SeasonsComponent = (props: SeasonsComponentProps) => (
      <ApolloReactComponents.Query<SeasonsQuery, SeasonsQueryVariables> query={SeasonsDocument} {...props} />
    );
    

/**
 * __useSeasonsQuery__
 *
 * To run a query within a React component, call `useSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSeasonsQuery(baseOptions: Apollo.QueryHookOptions<SeasonsQuery, SeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
      }
export function useSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonsQuery, SeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
        }
export type SeasonsQueryHookResult = ReturnType<typeof useSeasonsQuery>;
export type SeasonsLazyQueryHookResult = ReturnType<typeof useSeasonsLazyQuery>;
export type SeasonsQueryResult = Apollo.QueryResult<SeasonsQuery, SeasonsQueryVariables>;