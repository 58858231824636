import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { Collapsible } from '../collapsible/collapsible.component';
import styles from './section.module.scss';

interface Props {
  className?: string;
  heightened?: boolean;
  title?: string;
  collapsible?: boolean;
  collapsed?: boolean;
}

export const Section: ComponentType<Props> = (props) => {
  const { heightened, children, title, className, collapsible, collapsed } =
    props;
  const element = (
    <div
      className={classNames(
        className,
        styles.host,
        heightened && styles.heightened,
      )}
    >
      {title && <h3 className={styles.title}>{title}</h3>}
      {children}
    </div>
  );

  if (collapsible) {
    return (
      <Collapsible label={title} collapsed={collapsed}>
        {element}
      </Collapsible>
    );
  }
  return element;
};
