import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type EnterpriseDetailBaseQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type EnterpriseDetailBaseQuery = { __typename?: 'Query', adminEnterprise: { __typename?: 'Enterprise', id: any, name: string, slug?: string | null } };


export const EnterpriseDetailBaseDocument = gql`
    query enterpriseDetailBase($id: UUID!) {
  adminEnterprise(id: $id) {
    id
    name
    slug
  }
}
    `;
export type EnterpriseDetailBaseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EnterpriseDetailBaseQuery, EnterpriseDetailBaseQueryVariables>, 'query'> & ({ variables: EnterpriseDetailBaseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EnterpriseDetailBaseComponent = (props: EnterpriseDetailBaseComponentProps) => (
      <ApolloReactComponents.Query<EnterpriseDetailBaseQuery, EnterpriseDetailBaseQueryVariables> query={EnterpriseDetailBaseDocument} {...props} />
    );
    

/**
 * __useEnterpriseDetailBaseQuery__
 *
 * To run a query within a React component, call `useEnterpriseDetailBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseDetailBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseDetailBaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnterpriseDetailBaseQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseDetailBaseQuery, EnterpriseDetailBaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseDetailBaseQuery, EnterpriseDetailBaseQueryVariables>(EnterpriseDetailBaseDocument, options);
      }
export function useEnterpriseDetailBaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseDetailBaseQuery, EnterpriseDetailBaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseDetailBaseQuery, EnterpriseDetailBaseQueryVariables>(EnterpriseDetailBaseDocument, options);
        }
export type EnterpriseDetailBaseQueryHookResult = ReturnType<typeof useEnterpriseDetailBaseQuery>;
export type EnterpriseDetailBaseLazyQueryHookResult = ReturnType<typeof useEnterpriseDetailBaseLazyQuery>;
export type EnterpriseDetailBaseQueryResult = Apollo.QueryResult<EnterpriseDetailBaseQuery, EnterpriseDetailBaseQueryVariables>;