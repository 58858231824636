import { Duration } from '@/graphql/generated/types';
import propertyPath from 'property-path';
import React, { forwardRef, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import globalStyles from 'styles/global.scss';
import { Button } from '../button/button.component';
import styles from './duration-input-field.module.scss';

export interface DurationInputFieldProps {
  value?: Duration;
  name?: string;
  onChange?: (value: { factor?: number | null; value: string } | null) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  error?: string;
  label?: string | null;
  placeholder?: string;
  type?: 'text' | 'password' | 'date' | 'number';
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  defaultFactor?: number;
  units?: Unit[];
  formState?: FormState<any>;
}

interface Unit {
  name: string;
  factor: number;
}

const defaultUnits: Unit[] = [
  {
    name: 'Tage',
    factor: 1,
  },
  {
    name: 'Wochen',
    factor: 7,
  },
  {
    name: 'Monate',
    factor: 28,
  },
];
export const DurationInputField = forwardRef<
  HTMLInputElement,
  DurationInputFieldProps
>((props, ref) => {
  const {
    error,
    label,
    value,
    onChange,
    units = defaultUnits,
    defaultFactor,
    formState,
  } = props;

  const errorMessage = useMemo(() => {
    if (props.name && formState) {
      return propertyPath.get(formState.errors, props.name)?.message;
    }
    return error;
  }, [error, formState, props.name]);

  return (
    <div className={styles.host}>
      {label && (
        <label>
          {label}
          {props.required && ' *'}{' '}
          <i>
            <b>(Bitte als Dezimaltrennzeichen den Punkt "." verwenden!)</b>
          </i>
        </label>
      )}
      <div className={styles.fieldWrap}>
        <input
          ref={ref}
          //onChange={(e) => setLocalValue(e.currentTarget.value)}
          value={value?.value as any}
          onChange={(e) => {
            if (onChange) {
              onChange({
                factor: value?.factor || null,
                value: e.currentTarget.value || (null as any),
              });
            }
          }}
        />
        {units.map((u) => (
          <Button
            key={u.name}
            success={value?.factor === u.factor}
            small
            label={u.name}
            onClick={() => {
              if (onChange) {
                onChange({ factor: u.factor, value: value?.value as any });
              }
            }}
          />
        ))}
      </div>

      {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
    </div>
  );
});
