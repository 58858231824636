import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type TagDataFragment = {
  __typename?: 'Tag';
  id: any;
  name: string;
  slug?: string | null;
  context?: string | null;
  enterpriseId: any;
  enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
};

export type AdminTagsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.FilterTagInput>;
}>;

export type AdminTagsQuery = {
  __typename?: 'Query';
  adminTags: {
    __typename?: 'PaginatedTagResult';
    totalCount: number;
    data: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }>;
  };
};

export const TagDataFragmentDoc = gql`
  fragment TagData on Tag {
    id
    name
    slug
    context
    enterpriseId
    enterprise {
      id
      name
    }
  }
`;
export const AdminTagsDocument = gql`
  query adminTags($pagination: PaginationInput!, $filter: FilterTagInput) {
    adminTags(pagination: $pagination, filter: $filter) {
      totalCount
      data {
        ...TagData
      }
    }
  }
  ${TagDataFragmentDoc}
`;
export type AdminTagsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminTagsQuery,
    AdminTagsQueryVariables
  >,
  'query'
> &
  ({ variables: AdminTagsQueryVariables; skip?: boolean } | { skip: boolean });

export const AdminTagsComponent = (props: AdminTagsComponentProps) => (
  <ApolloReactComponents.Query<AdminTagsQuery, AdminTagsQueryVariables>
    query={AdminTagsDocument}
    {...props}
  />
);

/**
 * __useAdminTagsQuery__
 *
 * To run a query within a React component, call `useAdminTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTagsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminTagsQuery(
  baseOptions: Apollo.QueryHookOptions<AdminTagsQuery, AdminTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminTagsQuery, AdminTagsQueryVariables>(
    AdminTagsDocument,
    options,
  );
}
export function useAdminTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminTagsQuery,
    AdminTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminTagsQuery, AdminTagsQueryVariables>(
    AdminTagsDocument,
    options,
  );
}
export type AdminTagsQueryHookResult = ReturnType<typeof useAdminTagsQuery>;
export type AdminTagsLazyQueryHookResult = ReturnType<
  typeof useAdminTagsLazyQuery
>;
export type AdminTagsQueryResult = Apollo.QueryResult<
  AdminTagsQuery,
  AdminTagsQueryVariables
>;
