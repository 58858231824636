import React, { ComponentType, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
interface Props {
  left?: ReactNode;
}
export const ActionBar: ComponentType<Props> = (props) => {
  const { children, left } = props;
  const domNode = document.getElementById('action-bar-slot');

  useEffect(() => {
    if (domNode) {
      domNode.classList.remove('hide-element');
    }
    return () => {
      if (domNode) {
        domNode.classList.add('hide-element');
      }
    };
  }, []);
  if (!domNode) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      {left && <div className="left">{left}</div>}
      <div className="right">{children}</div>
    </>,
    domNode,
  );
};
