import {
  StatisticsBox,
  StatisticsBoxContext,
  StatisticsBoxType,
  StatisticsSettings,
} from '@/graphql/generated/types';
import { TimespanFormat } from '@/statistics-v3/statistics';
import { boardNameToSlug } from '../../utils';
import { ChartOptions, ChartType } from '../chart/options/options.provider';

export function _createBoard(
  name: string,
  statistics?: StatisticsSettings[] | null,
  selectedEnterpriseId?: string,
) {
  if (!selectedEnterpriseId) {
    return statistics;
  }
  let nextStatisticsSettingsArray: StatisticsSettings[] = statistics
    ? [...statistics]
    : [];

  const newBoard = { name, id: boardNameToSlug(name), boxes: [] };

  if (
    nextStatisticsSettingsArray.find(
      (s) => s.enterpriseId === selectedEnterpriseId,
    )
  ) {
    nextStatisticsSettingsArray = nextStatisticsSettingsArray.map(
      (statsSettings) => {
        if (statsSettings.enterpriseId === selectedEnterpriseId) {
          return {
            ...statsSettings,
            boards: [...statsSettings.boards, newBoard],
          };
        }
        return statsSettings;
      },
    );
  } else {
    nextStatisticsSettingsArray = [
      ...nextStatisticsSettingsArray,
      {
        boards: [newBoard],

        enterpriseId: selectedEnterpriseId,
      },
    ];
  }

  return nextStatisticsSettingsArray;
}

export function _deleteBoard(
  id: string,
  statistics?: StatisticsSettings[] | null,
  selectedEnterpriseId?: string,
) {
  if (!selectedEnterpriseId || !statistics) {
    return statistics;
  }
  const currentStatisticsSettingsArray: StatisticsSettings[] = statistics || [];

  const nextStatisticsSettingsArray: StatisticsSettings[] =
    currentStatisticsSettingsArray.length === 0
      ? []
      : currentStatisticsSettingsArray.map((statsSettings) => {
          if (statsSettings.enterpriseId === selectedEnterpriseId) {
            return {
              ...statsSettings,
              boards: statsSettings.boards.filter((b) => b.id !== id),
            };
          }
          return statsSettings;
        });

  return nextStatisticsSettingsArray;
}

export function _addBox(
  name: string,
  type: StatisticsBoxType,
  context: StatisticsBoxContext,
  boardId: string,
  statistics?: StatisticsSettings[] | null,
  selectedEnterpriseId?: string,
) {
  if (!selectedEnterpriseId) {
    return statistics;
  }
  let nextStatisticsSettingsArray: StatisticsSettings[] = statistics
    ? [...statistics]
    : [];

  const newBox: StatisticsBox & { options: ChartOptions } = {
    name,
    type,
    context,
    id: new Date().getTime().toString(),
    options: {
      timespan: TimespanFormat.Month,
      chartType: 'bar' as ChartType,
      property: '',
      startDate: '',
      endDate: '',
    },
  };

  nextStatisticsSettingsArray = nextStatisticsSettingsArray.map(
    (statsSettings) => {
      if (statsSettings.enterpriseId === selectedEnterpriseId) {
        return {
          ...statsSettings,
          boards: statsSettings.boards.map((b) => {
            if (b.id === boardId) {
              return {
                ...b,
                boxes: [...b.boxes, newBox],
              };
            }
            return b;
          }),
        };
      }
      return statsSettings;
    },
  );

  return nextStatisticsSettingsArray;
}

export function _deleteBox(
  boxId: string,
  boardId: string,
  statistics?: StatisticsSettings[] | null,
  selectedEnterpriseId?: string,
) {
  if (!selectedEnterpriseId) {
    return statistics;
  }
  let nextStatisticsSettingsArray: StatisticsSettings[] = statistics
    ? [...statistics]
    : [];

  nextStatisticsSettingsArray = nextStatisticsSettingsArray.map(
    (statsSettings) => {
      if (statsSettings.enterpriseId === selectedEnterpriseId) {
        return {
          ...statsSettings,
          boards: statsSettings.boards.map((b) => {
            if (b.id === boardId) {
              return {
                ...b,
                boxes: b.boxes.filter((b) => b.id !== boxId),
              };
            }
            return b;
          }),
        };
      }
      return statsSettings;
    },
  );

  return nextStatisticsSettingsArray;
}

export function _updateBox(
  data: StatisticsBox,
  boardId: string,
  statistics?: StatisticsSettings[] | null,
  selectedEnterpriseId?: string,
) {
  if (!selectedEnterpriseId) {
    return statistics;
  }
  let nextStatisticsSettingsArray: StatisticsSettings[] = statistics
    ? [...statistics]
    : [];

  nextStatisticsSettingsArray = nextStatisticsSettingsArray.map(
    (statsSettings) => {
      if (statsSettings.enterpriseId === selectedEnterpriseId) {
        return {
          ...statsSettings,
          boards: statsSettings.boards.map((b) => {
            if (b.id === boardId) {
              return {
                ...b,
                boxes: b.boxes.map((b) => {
                  if (b.id === data.id) {
                    return data;
                  }
                  return b;
                }),
              };
            }
            return b;
          }),
        };
      }
      return statsSettings;
    },
  );

  return nextStatisticsSettingsArray;
}
