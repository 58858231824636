import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SeasonDataFragment = { __typename?: 'Season', id: any, title: string, status: Types.SeasonStatus, enterpriseId: any, enterprise: { __typename?: 'Enterprise', name: string } };

export type SeasonQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type SeasonQuery = { __typename?: 'Query', adminSeason: { __typename?: 'Season', id: any, title: string, status: Types.SeasonStatus, enterpriseId: any, enterprise: { __typename?: 'Enterprise', name: string } } };

export type SaveSeasonMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.SaveSeasonInput>;
}>;


export type SaveSeasonMutation = { __typename?: 'Mutation', adminSaveSeason: { __typename?: 'Season', id: any, title: string, status: Types.SeasonStatus, enterpriseId: any, enterprise: { __typename?: 'Enterprise', name: string } } };

export type DeleteSeasonMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteSeasonMutation = { __typename?: 'Mutation', adminDeleteSeason: boolean };

export const SeasonDataFragmentDoc = gql`
    fragment SeasonData on Season {
  id
  title
  status
  enterpriseId
  enterprise {
    name
  }
}
    `;
export const SeasonDocument = gql`
    query season($id: UUID!) {
  adminSeason(id: $id) {
    ...SeasonData
  }
}
    ${SeasonDataFragmentDoc}`;
export type SeasonComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SeasonQuery, SeasonQueryVariables>, 'query'> & ({ variables: SeasonQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SeasonComponent = (props: SeasonComponentProps) => (
      <ApolloReactComponents.Query<SeasonQuery, SeasonQueryVariables> query={SeasonDocument} {...props} />
    );
    

/**
 * __useSeasonQuery__
 *
 * To run a query within a React component, call `useSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeasonQuery(baseOptions: Apollo.QueryHookOptions<SeasonQuery, SeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, options);
      }
export function useSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonQuery, SeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, options);
        }
export type SeasonQueryHookResult = ReturnType<typeof useSeasonQuery>;
export type SeasonLazyQueryHookResult = ReturnType<typeof useSeasonLazyQuery>;
export type SeasonQueryResult = Apollo.QueryResult<SeasonQuery, SeasonQueryVariables>;
export const SaveSeasonDocument = gql`
    mutation saveSeason($input: SaveSeasonInput) {
  adminSaveSeason(input: $input) {
    ...SeasonData
  }
}
    ${SeasonDataFragmentDoc}`;
export type SaveSeasonMutationFn = Apollo.MutationFunction<SaveSeasonMutation, SaveSeasonMutationVariables>;
export type SaveSeasonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveSeasonMutation, SaveSeasonMutationVariables>, 'mutation'>;

    export const SaveSeasonComponent = (props: SaveSeasonComponentProps) => (
      <ApolloReactComponents.Mutation<SaveSeasonMutation, SaveSeasonMutationVariables> mutation={SaveSeasonDocument} {...props} />
    );
    

/**
 * __useSaveSeasonMutation__
 *
 * To run a mutation, you first call `useSaveSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSeasonMutation, { data, loading, error }] = useSaveSeasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSeasonMutation(baseOptions?: Apollo.MutationHookOptions<SaveSeasonMutation, SaveSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSeasonMutation, SaveSeasonMutationVariables>(SaveSeasonDocument, options);
      }
export type SaveSeasonMutationHookResult = ReturnType<typeof useSaveSeasonMutation>;
export type SaveSeasonMutationResult = Apollo.MutationResult<SaveSeasonMutation>;
export type SaveSeasonMutationOptions = Apollo.BaseMutationOptions<SaveSeasonMutation, SaveSeasonMutationVariables>;
export const DeleteSeasonDocument = gql`
    mutation deleteSeason($id: UUID!) {
  adminDeleteSeason(id: $id)
}
    `;
export type DeleteSeasonMutationFn = Apollo.MutationFunction<DeleteSeasonMutation, DeleteSeasonMutationVariables>;
export type DeleteSeasonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>, 'mutation'>;

    export const DeleteSeasonComponent = (props: DeleteSeasonComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSeasonMutation, DeleteSeasonMutationVariables> mutation={DeleteSeasonDocument} {...props} />
    );
    

/**
 * __useDeleteSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonMutation, { data, loading, error }] = useDeleteSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonMutation, DeleteSeasonMutationVariables>(DeleteSeasonDocument, options);
      }
export type DeleteSeasonMutationHookResult = ReturnType<typeof useDeleteSeasonMutation>;
export type DeleteSeasonMutationResult = Apollo.MutationResult<DeleteSeasonMutation>;
export type DeleteSeasonMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>;