import { error as errorToast, success } from '@/ui/toaster/toast';
import axios from 'axios';
import classNames from 'classnames';
import propertyPath from 'property-path';
import React, { ComponentType, ReactNode, useCallback, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import LocationPicker from 'react-leaflet-location-picker';
import globalStyles from 'styles/global.scss';
import { Button } from '../button/button.component';
import styles from './location-input-field.module.scss';

export interface LocationInputFieldProps {
  white?: boolean;
  small?: boolean;
  compact?: boolean;
  value?: [number, number];
  name?: string;
  onChange?: (location: [number, number] | null) => void;
  error?: string;
  label?: ReactNode | null;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
  guessAddress?: string;
}

/**
 * PostGIS (our db layer) uses longitude/latitude
 * Leaflet uses latitude/longitude
 */

export const LocationInputField: ComponentType<LocationInputFieldProps> = (
  props,
) => {
  const {
    error,
    label,
    value,
    formState,
    small,
    compact,
    white,
    onChange,
    guessAddress,
    ...rest
  } = props;

  const onGeoLocationGuess = useCallback(() => {
    if (guessAddress) {
      axios
        .get(
          `https://nominatim.openstreetmap.org/search?q=${encodeURI(
            guessAddress,
          )}&format=json&polygon=1`,
        )
        .then((r) => {
          const data = r.data;
          if (data.length > 0 && onChange) {
            success('Ermittelte Geo-Location', data[0].display_name);
            onChange([data[0].lon, data[0].lat]);
          } else {
            errorToast('Geo-Location konnte nicht erraten werden.');
          }
        });
    }
  }, [onChange, guessAddress]);

  const errorMessage = useMemo(() => {
    if (props.name && formState?.errors) {
      return propertyPath.get(formState.errors, props.name)?.message;
    }
    return error;
  }, [error, formState, props.name]);
  /* 
  const handleChange = useCallback(
    (latLng: 'lat' | 'lng', v: any) => {
      if (onChange) {
        if (latLng === 'lat') {
          onChange([v, value ? value[1] : 0]);
        } else {
          onChange([value ? value[0] : 0, v]);
        }
      }
    },
    [value],
  ); */

  const pointMode = {
    banner: false,
    control: {
      values: value ? [[value[1], value[0]]] : [],
      onClick: (point) => {
        if (onChange) {
          onChange([point[1], point[0]]);
        }
      },
      onRemove: (point) => {
        if (onChange) {
          onChange(null);
        }
      },
    },
  };

  return (
    <div
      className={classNames(
        styles.host,
        small && styles.small,
        compact && styles.compact,
        white && styles.white,
        'input-field',
      )}
    >
      {label && (
        <label>
          {label}
          {props.required && ' *'}
        </label>
      )}

      <LocationPicker pointMode={pointMode} showInputs={false} />
      <div className={styles.actions}>
        <Button
          error
          label="Geo-Location entfernen"
          onClick={onChange ? () => onChange(null) : undefined}
        />
        <Button
          label="Geo-Location erraten"
          primary
          onClick={onGeoLocationGuess}
        />
      </div>
      {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
    </div>
  );
};
