import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { TagDataFragmentDoc } from '../../../tag/list/list.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CourseDataFragment = {
  __typename?: 'Course';
  id: any;
  title: string;
  subTitle?: string | null;
  slug?: string | null;
  infoCourse: boolean;
  abbreviation: string;
  status: Types.CourseStatus;
  purchasable: boolean;
  eligible: boolean;
  taxRate: number;
  tags?: Array<{
    __typename?: 'Tag';
    id: any;
    name: string;
    slug?: string | null;
    context?: string | null;
    enterpriseId: any;
    enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
  }> | null;
  category: {
    __typename?: 'CourseCategory';
    id: any;
    title: string;
    enterprise: { __typename?: 'Enterprise'; id: any; name: string };
  };
};

export type CoursesQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.FilterCourseInput>;
}>;

export type CoursesQuery = {
  __typename?: 'Query';
  adminCourses: {
    __typename?: 'PaginatedCourseResult';
    totalCount: number;
    data: Array<{
      __typename?: 'Course';
      id: any;
      title: string;
      subTitle?: string | null;
      slug?: string | null;
      infoCourse: boolean;
      abbreviation: string;
      status: Types.CourseStatus;
      purchasable: boolean;
      eligible: boolean;
      taxRate: number;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
      category: {
        __typename?: 'CourseCategory';
        id: any;
        title: string;
        enterprise: { __typename?: 'Enterprise'; id: any; name: string };
      };
    }>;
  };
};

export const CourseDataFragmentDoc = gql`
  fragment CourseData on Course {
    id
    title
    subTitle
    slug
    infoCourse
    abbreviation
    status
    purchasable
    eligible
    taxRate
    tags {
      ...TagData
    }
    category {
      id
      title
      enterprise {
        id
        name
      }
    }
  }
  ${TagDataFragmentDoc}
`;
export const CoursesDocument = gql`
  query courses($pagination: PaginationInput!, $filter: FilterCourseInput) {
    adminCourses(pagination: $pagination, filter: $filter) {
      data {
        ...CourseData
      }
      totalCount
    }
  }
  ${CourseDataFragmentDoc}
`;
export type CoursesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CoursesQuery,
    CoursesQueryVariables
  >,
  'query'
> &
  ({ variables: CoursesQueryVariables; skip?: boolean } | { skip: boolean });

export const CoursesComponent = (props: CoursesComponentProps) => (
  <ApolloReactComponents.Query<CoursesQuery, CoursesQueryVariables>
    query={CoursesDocument}
    {...props}
  />
);

/**
 * __useCoursesQuery__
 *
 * To run a query within a React component, call `useCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCoursesQuery(
  baseOptions: Apollo.QueryHookOptions<CoursesQuery, CoursesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoursesQuery, CoursesQueryVariables>(
    CoursesDocument,
    options,
  );
}
export function useCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesQuery,
    CoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoursesQuery, CoursesQueryVariables>(
    CoursesDocument,
    options,
  );
}
export type CoursesQueryHookResult = ReturnType<typeof useCoursesQuery>;
export type CoursesLazyQueryHookResult = ReturnType<typeof useCoursesLazyQuery>;
export type CoursesQueryResult = Apollo.QueryResult<
  CoursesQuery,
  CoursesQueryVariables
>;
