// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tPC54YEls_q8Ib3v9ghw .KnGmfN0TEPqN5EejqIXS{display:flex;justify-content:space-between;align-items:flex-end}.tPC54YEls_q8Ib3v9ghw .KnGmfN0TEPqN5EejqIXS .QAgHYPRlbomAJqoFrXJQ{display:flex;gap:.5rem}.tPC54YEls_q8Ib3v9ghw .bfJT6_QXFpGYgkBdrLn6{height:2.5rem;width:2.5rem;margin-bottom:.8rem;opacity:.8;transition:all .3s ease-in;cursor:pointer}.tPC54YEls_q8Ib3v9ghw .bfJT6_QXFpGYgkBdrLn6.awcwqN8MWcGesnJB0jEO{transform:rotateX(180deg)}.tPC54YEls_q8Ib3v9ghw .x7PXYUMaZZlllohED4rj{display:flex}.tPC54YEls_q8Ib3v9ghw .x7PXYUMaZZlllohED4rj h4{margin:1rem 0}.tPC54YEls_q8Ib3v9ghw .x7PXYUMaZZlllohED4rj .hbLUCB8OmjI31NQ0AzuO{padding:0 1.5rem;flex:1}", "",{"version":3,"sources":["webpack://./src/statistics-v3/shared/box/statistics-box.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CACA,6BAAA,CACA,oBAAA,CACA,kEACE,YAAA,CACA,SAAA,CAGJ,4CACE,aAAA,CACA,YAAA,CACA,mBAAA,CACA,UAAA,CACA,0BAAA,CACA,cAAA,CAEA,iEACE,yBAAA,CAIJ,4CACE,YAAA,CAEA,+CACE,aAAA,CAEF,kEACE,gBAAA,CACA,MAAA","sourcesContent":[".host {\n  .bottom {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n    .filter {\n      display: flex;\n      gap: 0.5rem;\n    }\n  }\n  .icon {\n    height: 2.5rem;\n    width: 2.5rem;\n    margin-bottom: 0.8rem;\n    opacity: 0.8;\n    transition: all 0.3s ease-in;\n    cursor: pointer;\n\n    &.reverse {\n      transform: rotateX(180deg);\n    }\n  }\n\n  .options {\n    display: flex;\n\n    h4 {\n      margin: 1rem 0;\n    }\n    .section {\n      padding: 0 1.5rem;\n      flex: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "tPC54YEls_q8Ib3v9ghw",
	"bottom": "KnGmfN0TEPqN5EejqIXS",
	"filter": "QAgHYPRlbomAJqoFrXJQ",
	"icon": "bfJT6_QXFpGYgkBdrLn6",
	"reverse": "awcwqN8MWcGesnJB0jEO",
	"options": "x7PXYUMaZZlllohED4rj",
	"section": "hbLUCB8OmjI31NQ0AzuO"
};
export default ___CSS_LOADER_EXPORT___;
