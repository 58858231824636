import { FormError } from '@/ui/form/form.component';
import { object, string } from 'yup';

export const teacherSchema = object({
  username: string()
    .typeError(FormError.required)
    .required(FormError.required)
    .min(3, FormError.minLength({ min: 3 })),
  status: string().typeError(FormError.required).required(FormError.required),
  description: string().nullable(),
  subjectArea: string().nullable(),
  jobTitle: string().nullable(),
  slug: string().typeError(FormError.required).required(FormError.required),
});
