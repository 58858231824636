import { Booking } from '@/graphql/generated/types';
import { Chip } from '@/ui/chip/chip.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import classNames from 'classnames';
import React, { ComponentType } from 'react';
import globalStyles from 'styles/global.scss';

interface BookingStatusViewProps {
  booking: Pick<Booking, 'cancelledAt' | 'confirmedAt'>;
}

export const BookingStatusView: ComponentType<BookingStatusViewProps> = (
  props,
) => {
  const { booking } = props;
  if (booking.cancelledAt) {
    return (
      <>
        <Chip meaning="error">Storniert</Chip>
        <div className={classNames(globalStyles.sub, globalStyles.meta)}>
          am <Formatted.Date withTime value={booking.cancelledAt} />
        </div>
      </>
    );
  }
  if (booking.confirmedAt) {
    return (
      <>
        <Chip meaning="success">Bestätigt</Chip>
        <div className={classNames(globalStyles.sub, globalStyles.meta)}>
          am <Formatted.Date withTime value={booking.confirmedAt} />
        </div>
      </>
    );
  }
  return (
    <>
      <Chip>Unbestätigt</Chip>
    </>
  );
};
