// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CoUuJpX0QQWVyWsWpf61 .w6G1wcLC4qMEVwsp4WiW{flex-wrap:wrap;display:flex}.CoUuJpX0QQWVyWsWpf61 .w6G1wcLC4qMEVwsp4WiW label{margin:0;padding:0}.CoUuJpX0QQWVyWsWpf61 .w6G1wcLC4qMEVwsp4WiW .checkbox{margin:0;margin-right:1rem}.CoUuJpX0QQWVyWsWpf61 .dqJuCMVKgwsTF73JoDeP textarea{min-height:100px}", "",{"version":3,"sources":["webpack://./src/event/calendar/creator/creator.module.scss"],"names":[],"mappings":"AACE,4CACE,cAAA,CACA,YAAA,CAEA,kDACE,QAAA,CACA,SAAA,CAGF,sDACE,QAAA,CACA,iBAAA,CAKF,qDACE,gBAAA","sourcesContent":[".host {\n  .dayCheckboxes {\n    flex-wrap: wrap;\n    display: flex;\n\n    label {\n      margin: 0;\n      padding: 0;\n    }\n\n    :global(.checkbox) {\n      margin: 0;\n      margin-right: 1rem;\n    }\n  }\n\n  .selectedSubjects {\n    textarea {\n      min-height: 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "CoUuJpX0QQWVyWsWpf61",
	"dayCheckboxes": "w6G1wcLC4qMEVwsp4WiW",
	"selectedSubjects": "dqJuCMVKgwsTF73JoDeP"
};
export default ___CSS_LOADER_EXPORT___;
