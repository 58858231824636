import { getPersonDescription } from '@/contact/person/utils';
import { Booking, Person, SetBookingStatus } from '@/graphql/generated/types';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { error, success } from '@/ui/toaster/toast';
import React, { useCallback } from 'react';
import {
  useDeleteBookingMutation,
  useSetBookingStatusMutation,
} from './actions.generated';

export function useBookingActions(
  deleteCallback: () => void,
  changeStateCallback: () => void,
) {
  const [deleteBooking] = useDeleteBookingMutation();
  const [setBookingStatusMutation] = useSetBookingStatusMutation();

  const [modalDelete, askDelete] = useConfirm(
    async (id: string) => {
      try {
        await deleteBooking({
          variables: {
            id,
          },
        });
        success('Die Buchung wurde gelöscht.');
        deleteCallback();
      } catch (e: any) {
        console.error(e);
        error('Beim Löschen ist ein Fehler aufgetreten.', e.message);
      }
    },
    { meaning: 'error', title: 'Buchung löschen' },
  );

  const askForDelete = useCallback(
    (
      booking: Pick<Booking, 'id'> & {
        customer: {
          person: Pick<Person, 'firstName' | 'lastName' | 'gender' | 'title'>;
        };
      },
    ) => {
      askDelete(
        <>
          <p>Diese Aktion lässt sich nicht rückgängig machen.</p>
          <p>
            Bist du sicher, dass du die Buchung von{' '}
            <em>{getPersonDescription(booking.customer.person)}</em> löschen
            möchtest?
          </p>
        </>,
        booking.id,
      );
    },
    [],
  );

  const setBookingStatus = useCallback(
    async (id: string, status: SetBookingStatus) => {
      await setBookingStatusMutation({ variables: { id, status } });
      changeStateCallback();
    },
    [changeStateCallback],
  );

  return {
    modalDelete,
    askForDelete,
    setBookingStatus,
  };
}
