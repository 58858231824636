import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { TagDataFragmentDoc } from '../../../tag/list/list.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CourseUnitDetailDataFragment = {
  __typename?: 'CourseUnit';
  id: any;
  status: Types.CourseUnitStatus;
  title?: string | null;
  subTitle?: string | null;
  description?: string | null;
  startDate: any;
  endDate: any;
  dateUncertain: boolean;
  withTime: boolean;
  modulePriceMatrix?: string | null;
  bookingNotes?: string | null;
  properties: any;
  searchableLocationId?: any | null;
  locationId?: any | null;
  online: boolean;
  correspondenceCourse: boolean;
  amount: number;
  discountedAmount?: number | null;
  earlyBirdDate?: any | null;
  earlyBirdNotice?: string | null;
  courseId: any;
  teacherId?: any | null;
  teacherIds?: Array<any> | null;
  tagIds?: Array<any> | null;
  seasonId?: any | null;
  location?: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    address?: { __typename?: 'Address'; city?: string | null } | null;
  } | null;
  searchableLocation?: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    address?: { __typename?: 'Address'; city?: string | null } | null;
  } | null;
  lateBookingOffset?: {
    __typename?: 'Duration';
    value?: number | null;
    factor?: number | null;
  } | null;
  course: {
    __typename?: 'Course';
    id: any;
    title: string;
    abbreviation: string;
    earlyBirdDate?: any | null;
    earlyBirdNotice?: string | null;
    tagIds?: Array<any> | null;
    category: {
      __typename?: 'CourseCategory';
      enterprise: { __typename?: 'Enterprise'; id: any };
    };
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
  };
  teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
  teachers?: Array<{
    __typename?: 'Teacher';
    id: any;
    fullName: string;
  }> | null;
  tags?: Array<{
    __typename?: 'Tag';
    id: any;
    name: string;
    slug?: string | null;
    context?: string | null;
    enterpriseId: any;
    enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
  }> | null;
  modules: Array<{
    __typename?: 'CourseModule';
    id: any;
    title?: string | null;
    startDate: any;
    endDate: any;
    status: Types.CourseModuleStatus;
    dateUncertain: boolean;
    withTime: boolean;
    tagIds?: Array<any> | null;
    online: boolean;
    correspondenceCourse: boolean;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
  }>;
  season?: {
    __typename?: 'Season';
    title: string;
    enterpriseId: any;
    enterprise: { __typename?: 'Enterprise'; name: string };
  } | null;
};

export type CourseUnitQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type CourseUnitQuery = {
  __typename?: 'Query';
  adminCourseUnit: {
    __typename?: 'CourseUnit';
    id: any;
    status: Types.CourseUnitStatus;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    startDate: any;
    endDate: any;
    dateUncertain: boolean;
    withTime: boolean;
    modulePriceMatrix?: string | null;
    bookingNotes?: string | null;
    properties: any;
    searchableLocationId?: any | null;
    locationId?: any | null;
    online: boolean;
    correspondenceCourse: boolean;
    amount: number;
    discountedAmount?: number | null;
    earlyBirdDate?: any | null;
    earlyBirdNotice?: string | null;
    courseId: any;
    teacherId?: any | null;
    teacherIds?: Array<any> | null;
    tagIds?: Array<any> | null;
    seasonId?: any | null;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    lateBookingOffset?: {
      __typename?: 'Duration';
      value?: number | null;
      factor?: number | null;
    } | null;
    course: {
      __typename?: 'Course';
      id: any;
      title: string;
      abbreviation: string;
      earlyBirdDate?: any | null;
      earlyBirdNotice?: string | null;
      tagIds?: Array<any> | null;
      category: {
        __typename?: 'CourseCategory';
        enterprise: { __typename?: 'Enterprise'; id: any };
      };
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    };
    teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
    teachers?: Array<{
      __typename?: 'Teacher';
      id: any;
      fullName: string;
    }> | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
    modules: Array<{
      __typename?: 'CourseModule';
      id: any;
      title?: string | null;
      startDate: any;
      endDate: any;
      status: Types.CourseModuleStatus;
      dateUncertain: boolean;
      withTime: boolean;
      tagIds?: Array<any> | null;
      online: boolean;
      correspondenceCourse: boolean;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    }>;
    season?: {
      __typename?: 'Season';
      title: string;
      enterpriseId: any;
      enterprise: { __typename?: 'Enterprise'; name: string };
    } | null;
  };
};

export type UpdateDetailedCourseUnitMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.SaveCourseUnitInput;
}>;

export type UpdateDetailedCourseUnitMutation = {
  __typename?: 'Mutation';
  adminUpdateCourseUnit: {
    __typename?: 'CourseUnit';
    id: any;
    status: Types.CourseUnitStatus;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    startDate: any;
    endDate: any;
    dateUncertain: boolean;
    withTime: boolean;
    modulePriceMatrix?: string | null;
    bookingNotes?: string | null;
    properties: any;
    searchableLocationId?: any | null;
    locationId?: any | null;
    online: boolean;
    correspondenceCourse: boolean;
    amount: number;
    discountedAmount?: number | null;
    earlyBirdDate?: any | null;
    earlyBirdNotice?: string | null;
    courseId: any;
    teacherId?: any | null;
    teacherIds?: Array<any> | null;
    tagIds?: Array<any> | null;
    seasonId?: any | null;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    lateBookingOffset?: {
      __typename?: 'Duration';
      value?: number | null;
      factor?: number | null;
    } | null;
    course: {
      __typename?: 'Course';
      id: any;
      title: string;
      abbreviation: string;
      earlyBirdDate?: any | null;
      earlyBirdNotice?: string | null;
      tagIds?: Array<any> | null;
      category: {
        __typename?: 'CourseCategory';
        enterprise: { __typename?: 'Enterprise'; id: any };
      };
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    };
    teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
    teachers?: Array<{
      __typename?: 'Teacher';
      id: any;
      fullName: string;
    }> | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
    modules: Array<{
      __typename?: 'CourseModule';
      id: any;
      title?: string | null;
      startDate: any;
      endDate: any;
      status: Types.CourseModuleStatus;
      dateUncertain: boolean;
      withTime: boolean;
      tagIds?: Array<any> | null;
      online: boolean;
      correspondenceCourse: boolean;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    }>;
    season?: {
      __typename?: 'Season';
      title: string;
      enterpriseId: any;
      enterprise: { __typename?: 'Enterprise'; name: string };
    } | null;
  };
};

export const CourseUnitDetailDataFragmentDoc = gql`
  fragment CourseUnitDetailData on CourseUnit {
    id
    status
    title
    subTitle
    description
    startDate
    endDate
    dateUncertain
    withTime
    modulePriceMatrix
    bookingNotes
    properties
    location {
      id
      name
      city
      address {
        city
      }
    }
    searchableLocation {
      id
      name
      city
      address {
        city
      }
    }
    searchableLocationId
    locationId
    online
    correspondenceCourse
    amount
    discountedAmount
    earlyBirdDate
    earlyBirdNotice
    lateBookingOffset {
      value
      factor
    }
    course {
      id
      title
      abbreviation
      earlyBirdDate
      earlyBirdNotice
      category {
        enterprise {
          id
        }
      }
      tagIds
      tags {
        ...TagData
      }
    }
    courseId
    teacher {
      id
      fullName
    }
    teacherId
    teachers {
      id
      fullName
    }
    teacherIds
    tags {
      ...TagData
    }
    tagIds
    modules {
      id
      title
      startDate
      endDate
      status
      dateUncertain
      withTime
      location {
        id
        name
        city
        address {
          city
        }
      }
      searchableLocation {
        id
        name
        city
        address {
          city
        }
      }
      tags {
        ...TagData
      }
      tagIds
      online
      correspondenceCourse
    }
    seasonId
    season {
      title
      enterpriseId
      enterprise {
        name
      }
    }
  }
  ${TagDataFragmentDoc}
`;
export const CourseUnitDocument = gql`
  query courseUnit($id: UUID!) {
    adminCourseUnit(id: $id) {
      ...CourseUnitDetailData
    }
  }
  ${CourseUnitDetailDataFragmentDoc}
`;
export type CourseUnitComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CourseUnitQuery,
    CourseUnitQueryVariables
  >,
  'query'
> &
  ({ variables: CourseUnitQueryVariables; skip?: boolean } | { skip: boolean });

export const CourseUnitComponent = (props: CourseUnitComponentProps) => (
  <ApolloReactComponents.Query<CourseUnitQuery, CourseUnitQueryVariables>
    query={CourseUnitDocument}
    {...props}
  />
);

/**
 * __useCourseUnitQuery__
 *
 * To run a query within a React component, call `useCourseUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseUnitQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseUnitQuery,
    CourseUnitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseUnitQuery, CourseUnitQueryVariables>(
    CourseUnitDocument,
    options,
  );
}
export function useCourseUnitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseUnitQuery,
    CourseUnitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseUnitQuery, CourseUnitQueryVariables>(
    CourseUnitDocument,
    options,
  );
}
export type CourseUnitQueryHookResult = ReturnType<typeof useCourseUnitQuery>;
export type CourseUnitLazyQueryHookResult = ReturnType<
  typeof useCourseUnitLazyQuery
>;
export type CourseUnitQueryResult = Apollo.QueryResult<
  CourseUnitQuery,
  CourseUnitQueryVariables
>;
export const UpdateDetailedCourseUnitDocument = gql`
  mutation updateDetailedCourseUnit($id: UUID!, $input: SaveCourseUnitInput!) {
    adminUpdateCourseUnit(id: $id, input: $input) {
      ...CourseUnitDetailData
    }
  }
  ${CourseUnitDetailDataFragmentDoc}
`;
export type UpdateDetailedCourseUnitMutationFn = Apollo.MutationFunction<
  UpdateDetailedCourseUnitMutation,
  UpdateDetailedCourseUnitMutationVariables
>;
export type UpdateDetailedCourseUnitComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateDetailedCourseUnitMutation,
    UpdateDetailedCourseUnitMutationVariables
  >,
  'mutation'
>;

export const UpdateDetailedCourseUnitComponent = (
  props: UpdateDetailedCourseUnitComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateDetailedCourseUnitMutation,
    UpdateDetailedCourseUnitMutationVariables
  >
    mutation={UpdateDetailedCourseUnitDocument}
    {...props}
  />
);

/**
 * __useUpdateDetailedCourseUnitMutation__
 *
 * To run a mutation, you first call `useUpdateDetailedCourseUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDetailedCourseUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDetailedCourseUnitMutation, { data, loading, error }] = useUpdateDetailedCourseUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDetailedCourseUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDetailedCourseUnitMutation,
    UpdateDetailedCourseUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDetailedCourseUnitMutation,
    UpdateDetailedCourseUnitMutationVariables
  >(UpdateDetailedCourseUnitDocument, options);
}
export type UpdateDetailedCourseUnitMutationHookResult = ReturnType<
  typeof useUpdateDetailedCourseUnitMutation
>;
export type UpdateDetailedCourseUnitMutationResult =
  Apollo.MutationResult<UpdateDetailedCourseUnitMutation>;
export type UpdateDetailedCourseUnitMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDetailedCourseUnitMutation,
    UpdateDetailedCourseUnitMutationVariables
  >;
