import propertyPath from 'property-path';
import React, { forwardRef, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import globalStyles from 'styles/global.scss';
import styles from './markdown-field.module.scss';
export interface MarkdownFieldProps {
  value?: string;
  name?: string;
  onChange?: (e: any) => void;
  error?: string;
  label?: string | null;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
}

export const MarkdownField = forwardRef<
  HTMLTextAreaElement,
  MarkdownFieldProps
>((props, ref) => {
  const { error, formState, label, ...rest } = props;
  const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
    'write',
  );

  const errorMessage = useMemo(() => {
    if (props.name && formState) {
      return propertyPath.get(formState.errors, props.name)?.message;
    }
    return error;
  }, [error, formState, props.name]);

  return (
    <div className={styles.host}>
      {label && (
        <label>
          {label}
          {props.required && ' *'}
        </label>
      )}
      <ReactMde
        //refs={{ textarea: ref }}
        {...rest}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
        }
      />
      {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
    </div>
  );
});
