import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ContextMenu } from './context-menu.component';

interface ContextMenuApi {
  show: (x: number, y: number, context?: any) => void;
  close: () => void;
}

type ContextMenuHook = [ReactNode, ContextMenuApi];

export function useContextMenu(
  render: (context?: any) => ReactNode,
): ContextMenuHook {
  const [state, setState] = useState<{ x: number; y: number } | false>(false);
  const [context, setContext] = useState<any>();

  const menu = useMemo(() => {
    if (!state) {
      return null;
    }

    return (
      <ContextMenu onClick={() => setState(false)} position={state}>
        {render(context)}
      </ContextMenu>
    );
  }, [state, context, render]);

  const show = useCallback((x: number, y: number, context?: any) => {
    setState({ x, y });
    setContext(context);
  }, []);

  const onWindowClick = useCallback((e) => {
    setState(false);
  }, []);

  const close = useCallback(() => {
    setState(false);
  }, []);

  const api = {
    show,
    close,
  };

  useEffect(() => {
    if (state) {
      window.addEventListener('click', onWindowClick);
    } else {
      window.removeEventListener('click', onWindowClick);
    }
    return () => window.removeEventListener('click', onWindowClick);
  }, [state]);

  return [menu, api];
}
