import React, { ComponentType } from 'react';
import styles from './context-menu.module.scss';
interface ContextMenuProps {
  position: { x: number; y: number };
  onClick: () => void;
}

export const ContextMenu: ComponentType<ContextMenuProps> = (props) => {
  const { position, children, onClick } = props;
  return (
    <div
      onContextMenu={(e) => e.preventDefault()}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      className={styles.host}
      style={{ top: `${position.y}px`, left: `${position.x}px` }}
    >
      {children}
    </div>
  );
};
