import { useMemo } from 'react';
import { AvailableCourseCategoryDataFragment } from 'shared/resources/resources.generated';
import { CategoryNodeProps } from '../category-node/category-node.component';

export function useCategoryTree(
  categories?: AvailableCourseCategoryDataFragment[],
) {
  const categoryTree = useMemo(() => {
    if (categories) {
      const extensibleCategories = categories.map((entry) => ({ ...entry }));

      const categoryMap: {
        [param: string]: CategoryNodeProps;
      } = extensibleCategories.reduce((previous, current) => {
        previous[current.id] = current;
        return previous;
      }, {});

      extensibleCategories.forEach((entry) => {
        if (entry.parentId) {
          if (!categoryMap[entry.parentId].children) {
            categoryMap[entry.parentId].children = [];
          }
          categoryMap[entry.parentId].children?.push(entry as any);
        }
      });

      const tree: CategoryNodeProps[] = Object.values(categoryMap).filter(
        (entry) => !entry.parentId,
      );

      return tree;
    }
  }, [categories]);

  return categoryTree;
}
