import {
  StatisticsBoard,
  StatisticsBoxContext,
  StatisticsBoxType,
} from '@/graphql/generated/types';
import { Box } from '@/ui/box/box.component';
import { Icon } from '@/ui/icon/icon.component';
import { ToolTip } from '@/ui/tool-tip/tool-tip.component';
import chevronDownIcon from 'assets/image/icons/chevron-down.svg';
import classNames from 'classnames';
import React, { ComponentType, useMemo, useState } from 'react';
import { Translator } from 'shared/translator/translator.component';
import globalStyles from 'styles/global.scss';
import { useChartOptions } from '../chart/options/options.provider';
import { DropdownActions } from './dropdown-actions.component';
import {
  getBoxOptionsComponent,
  getDisplayComponent,
  getFilterComponent,
} from './statistics-box-registry';
import styles from './statistics-box.module.scss';

interface StatisticsBoxProps {
  id: string;
  board: StatisticsBoard;
  name: string;
  type: StatisticsBoxType;
  context: StatisticsBoxContext;
}

export const StatisticsBox: ComponentType<StatisticsBoxProps> = (props) => {
  const { name, id, board, type, context } = props;

  const _ = Translator.useTranslator();

  const [showSettings, setShowSettings] = useState(false);

  const { dirty, setDirty } = useChartOptions();

  const DisplayComponent = useMemo(() => {
    return getDisplayComponent(type, context);
  }, [type, context]);

  const FilterComponent = useMemo(() => {
    return getFilterComponent(type, context);
  }, [type, context]);

  const OptionsComponent = useMemo(() => {
    return getBoxOptionsComponent(type, context);
  }, [type, context]);

  return (
    <>
      <Box
        className={styles.host}
        withMargin
        title={
          <>
            <div>
              {name}{' '}
              {dirty && (
                <em className={globalStyles.error}>(nicht gespeichert)</em>
              )}{' '}
            </div>
            <DropdownActions
              id={id}
              name={name}
              type={type}
              context={context}
              board={board}
              onSaved={() => setDirty(false)}
            />
          </>
        }
      >
        <DisplayComponent />

        <div className={styles.bottom}>
          <FilterComponent />
          <ToolTip
            content={
              showSettings
                ? 'Weniger Einstellungen...'
                : 'Mehr Einstellungen...'
            }
          >
            <Icon
              className={classNames(
                styles.icon,
                showSettings && styles.reverse,
              )}
              icon={chevronDownIcon}
              inline
              onClick={() => setShowSettings(!showSettings)}
            />
          </ToolTip>
        </div>
        {showSettings && (
          <div className={styles.options}>
            <OptionsComponent type={type} context={context} />
          </div>
        )}
      </Box>
    </>
  );
};

export const DummyDisplay: ComponentType = () => {
  return <>I could be a chart or anything else.</>;
};

export const DummyFilter: ComponentType = () => {
  return <>I am a filter.</>;
};

export const DummyOptions: ComponentType = () => {
  return <>These are the options.</>;
};
