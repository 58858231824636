import { format, isSameDay, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

interface LocationWithLabel {
  commonName: string;
}
export function getLocationLabel(location?: LocationWithLabel | null) {
  return location?.commonName;
}

interface UnitWithLocation {
  searchableLocation?: LocationWithLabel | null;
  location?: LocationWithLabel | null;
  online: boolean;
  correspondenceCourse: boolean;
}

export function getCourseUnitLocationLabel(unit: UnitWithLocation) {
  const tokens: (string | undefined)[] = [];
  if (unit.location) {
    tokens.push(getLocationLabel(unit.searchableLocation || unit.location));
  }

  if (unit.online) {
    tokens.push('online');
  }
  if (unit.correspondenceCourse) {
    tokens.push('Fernkurs');
  }

  const filteredTokens = tokens.filter((t) => !!t);
  if (filteredTokens.length === 0) {
    return 'kein Standort';
  }

  return filteredTokens.join(' / ');
}

export function getCourseUnitDates(unit: {
  startDate: string;
  endDate: string;
  dateUncertain: boolean;
  withTime: boolean;
}) {
  const formatString = unit.dateUncertain
    ? 'MMMM yyyy'
    : unit.withTime
    ? 'dd.MM.yyyy HH:mm'
    : 'dd.MM.yyyy';

  const formatStringTimeOnly = 'HH:mm';

  const startDate = parseISO(unit.startDate);
  const endDate = parseISO(unit.endDate);

  const start = format(startDate, formatString, {
    locale: de,
  });
  const end = format(
    endDate,
    isSameDay(startDate, endDate) && unit.withTime
      ? formatStringTimeOnly
      : formatString,
    {
      locale: de,
    },
  );
  if (start === end) {
    return start;
  }
  return `${start} – ${end}`;
}
