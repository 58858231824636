import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { Chip } from '@/ui/chip/chip.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import {
  TransactionMailTemplateListDataFragment,
  useTransactionMailTemplatesQuery,
} from './list.generated';

interface Props {
  enterprise: { id: string; name: string };
}
export const List: ComponentType<RouteComponentProps & Props> = (props) => {
  const { enterprise } = props;
  const _ = Translator.useTranslator();

  const defaultFilter = useMemo(() => {
    return { enterpriseId: enterprise?.id };
  }, [enterprise]);

  const [query] = usePaginatedUrlQuery();
  const { loading, data } = useTransactionMailTemplatesQuery({
    variables: {
      ...defaultFilter,
      pagination: getPaginationFromQuery(query),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.Title>TX-Mail-Vorlagen</Frame.Title>
      <Frame.SubTitle>{enterprise.name}</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="../create" label="Neue Vorlage" />
      </Frame.ActionBar>

      <Frame.Content>
        <ListView<TransactionMailTemplateListDataFragment>
          loading={loading}
          defaultFilter={defaultFilter}
          data={data?.adminTransactionMailTemplates?.data || []}
          totalCount={data?.adminTransactionMailTemplates?.totalCount}
          columns={[
            {
              name: 'Aktionen / Trigger',
              sortable: true,
            },
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'Betreff',
              sortable: true,
            },
          ]}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                {item.actions.map((a) => (
                  <Chip big bright>
                    {_(`transaction-mail-action.${a}`)}
                  </Chip>
                ))}
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>{item.name}</Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>{item.subject}</Link>
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
