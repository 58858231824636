import { useErrorLog } from '@/error/error.provider';
import { Icon } from '@/ui/icon/icon.component';
import { Link } from '@reach/router';
import errorIcon from 'assets/image/icons/error.svg';
import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';

export const Footer: ComponentType = () => {
  const domNode = document.getElementById('footer-slot');
  const { errors } = useErrorLog();
  if (!domNode) {
    return null;
  }
  return ReactDOM.createPortal(
    <>
      edi
      {errors.length > 0 && (
        <Link to="/error-log">
          <Icon icon={errorIcon} inline />
        </Link>
      )}
    </>,
    domNode,
  );
};
