import {
  StatisticsBoxContext,
  StatisticsBoxType,
} from '@/graphql/generated/types';
import { TimespanFormat } from '@/statistics-v3/statistics';
import React, {
  ComponentType,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Graph } from '../graph/graph';

export type ChartType = 'line' | 'bar';

export interface ChartOptions {
  startDate: string | undefined;
  endDate: string | undefined;
  timespan: TimespanFormat;
  property: string;
  chartType: ChartType;
  graphs?: Graph<any>[];
}

interface ChartOptionsApi {
  options: ChartOptions;
  setOptions: Dispatch<SetStateAction<ChartOptions>>;
  dirty: boolean;
  setDirty: Dispatch<SetStateAction<boolean>>;
  context: StatisticsBoxContext;
  type: StatisticsBoxType;
}

const ChartOptionsContext = createContext<ChartOptionsApi | undefined>(
  undefined,
);

interface ChartOptionsProviderProps {
  options: ChartOptions;
  context: StatisticsBoxContext;
  type: StatisticsBoxType;
}
export const ChartOptionsProvider: ComponentType<ChartOptionsProviderProps> = ({
  options: optionsFromProps,
  type,
  context,
  children,
}) => {
  const [dirty, setDirty] = useState(false);
  const [options, setOptions] = useState<ChartOptions>(optionsFromProps);
  const initial = useRef(true);

  useEffect(() => {
    if (!initial.current) {
      setDirty(true);
    }
    initial.current = false;
  }, [options]);

  return (
    <ChartOptionsContext.Provider
      value={{
        options,
        setOptions,
        dirty,
        setDirty,
        type,
        context,
      }}
    >
      {children}
    </ChartOptionsContext.Provider>
  );
};

export function useChartOptions() {
  const context = useContext(ChartOptionsContext);
  if (!context) {
    throw new Error('ChartOptionsContext not ready.');
  }
  return context;
}
