import { Frame } from '@/frame';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { RoomEventManager } from './room-event-manager/room-event-manager.component';
import { RoomManagementProvider } from './room-management-context';

export const RoomManagement: ComponentType<RouteComponentProps> = () => {
  return (
    <>
      <Frame.Title>Raum-/Terminplanung</Frame.Title>
      <Frame.ActionBar>
        {/* <Button primary label="Neues Raum buchen" /> */}
      </Frame.ActionBar>
      <Frame.Content>
        <RoomManagementProvider>
          <RoomEventManager />
        </RoomManagementProvider>
      </Frame.Content>
    </>
  );
};
