import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AvailableCoursesQueryVariables = Types.Exact<{
  enterpriseId: Types.Scalars['UUID'];
}>;


export type AvailableCoursesQuery = { __typename?: 'Query', courses: Array<{ __typename?: 'Course', id: any, title: string, verboseTitle?: string | null }> };

export type CreateConsultingEventsMutationVariables = Types.Exact<{
  input: Array<Types.CreateConsultingEventInput> | Types.CreateConsultingEventInput;
}>;


export type CreateConsultingEventsMutation = { __typename?: 'Mutation', adminCreateConsultingEvents: boolean };


export const AvailableCoursesDocument = gql`
    query availableCourses($enterpriseId: UUID!) {
  courses(enterpriseId: $enterpriseId) {
    id
    title
    verboseTitle
  }
}
    `;
export type AvailableCoursesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableCoursesQuery, AvailableCoursesQueryVariables>, 'query'> & ({ variables: AvailableCoursesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableCoursesComponent = (props: AvailableCoursesComponentProps) => (
      <ApolloReactComponents.Query<AvailableCoursesQuery, AvailableCoursesQueryVariables> query={AvailableCoursesDocument} {...props} />
    );
    

/**
 * __useAvailableCoursesQuery__
 *
 * To run a query within a React component, call `useAvailableCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoursesQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useAvailableCoursesQuery(baseOptions: Apollo.QueryHookOptions<AvailableCoursesQuery, AvailableCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableCoursesQuery, AvailableCoursesQueryVariables>(AvailableCoursesDocument, options);
      }
export function useAvailableCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableCoursesQuery, AvailableCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableCoursesQuery, AvailableCoursesQueryVariables>(AvailableCoursesDocument, options);
        }
export type AvailableCoursesQueryHookResult = ReturnType<typeof useAvailableCoursesQuery>;
export type AvailableCoursesLazyQueryHookResult = ReturnType<typeof useAvailableCoursesLazyQuery>;
export type AvailableCoursesQueryResult = Apollo.QueryResult<AvailableCoursesQuery, AvailableCoursesQueryVariables>;
export const CreateConsultingEventsDocument = gql`
    mutation createConsultingEvents($input: [CreateConsultingEventInput!]!) {
  adminCreateConsultingEvents(input: $input)
}
    `;
export type CreateConsultingEventsMutationFn = Apollo.MutationFunction<CreateConsultingEventsMutation, CreateConsultingEventsMutationVariables>;
export type CreateConsultingEventsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateConsultingEventsMutation, CreateConsultingEventsMutationVariables>, 'mutation'>;

    export const CreateConsultingEventsComponent = (props: CreateConsultingEventsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateConsultingEventsMutation, CreateConsultingEventsMutationVariables> mutation={CreateConsultingEventsDocument} {...props} />
    );
    

/**
 * __useCreateConsultingEventsMutation__
 *
 * To run a mutation, you first call `useCreateConsultingEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsultingEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsultingEventsMutation, { data, loading, error }] = useCreateConsultingEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConsultingEventsMutation(baseOptions?: Apollo.MutationHookOptions<CreateConsultingEventsMutation, CreateConsultingEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConsultingEventsMutation, CreateConsultingEventsMutationVariables>(CreateConsultingEventsDocument, options);
      }
export type CreateConsultingEventsMutationHookResult = ReturnType<typeof useCreateConsultingEventsMutation>;
export type CreateConsultingEventsMutationResult = Apollo.MutationResult<CreateConsultingEventsMutation>;
export type CreateConsultingEventsMutationOptions = Apollo.BaseMutationOptions<CreateConsultingEventsMutation, CreateConsultingEventsMutationVariables>;