import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { format } from 'date-fns';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { View } from './detail/view/view.component';
import { List } from './list/list.component';

export const Order: ComponentType<RouteComponentProps> = (props) => {
  const today = format(new Date(), 'y-MM-dd');
  return (
    <>
      <Frame.Title>Bestellungen</Frame.Title>
      <Router className="router">
        <List path="list" />
        <View path=":id/view" />

        <Redirect
          noThrow
          from="/"
          to={`list?startDate=${today}&endDate=${today}`}
        />
      </Router>
    </>
  );
};
