import helpIcon from 'assets/image/icons/help.svg';
import React, { ComponentType, ReactNode, useMemo } from 'react';
import { Box } from '../box/box.component';
import { Icon } from '../icon/icon.component';
import { useModal } from '../modal/modal.hooks';
import { ToolTip } from '../tool-tip/tool-tip.component';
import styles from './help-popup.module.scss';

interface Props {
  hover?: string;
  click?: {
    title?: string;
    content: ReactNode;
  };

  withIcon?: boolean;
}
export const HelpPopup: ComponentType<Props> = (props) => {
  const { click, children, withIcon = true, hover } = props;

  const [modal, { open, close }] = useModal(
    <Box title={click?.title || 'Erklärung'}>{click?.content}</Box>,
  );

  const inner = useMemo(() => {
    return (
      <>
        {withIcon && (
          <Icon
            size={16}
            inline
            icon={helpIcon}
            onClick={click ? open : undefined}
          />
        )}
        <span
          className={styles.clickableText}
          onClick={click ? open : undefined}
        >
          {children}
        </span>
      </>
    );
  }, [withIcon, children, open, click]);

  if (hover) {
    return (
      <>
        <ToolTip content={hover}>{inner}</ToolTip>
        {modal}
      </>
    );
  }
  return (
    <>
      {inner}
      {modal}
    </>
  );
};
