import {
  OrderStatRecord,
  StatisticsBoxContext,
  StatisticsBoxType,
} from '@/graphql/generated/types';
import { registerStatisticsBox } from '../shared/box/statistics-box-registry';
import { GraphOptions, GraphType } from '../shared/chart/graph/graph';
import { CommonChartOptions } from '../shared/chart/options/common-chart-options.component';
import { ChartOptions } from '../shared/chart/options/options.provider';
import { OrderChartFilter } from './chart/filter/filter.component';
import { CourseCategoryGraphItemOptions } from './chart/graph/options/course-category-graph.component';
import { CourseGraphItemOptions } from './chart/graph/options/course-graph.component';
import { OrdersChart } from './chart/orders-chart.component';

registerStatisticsBox<OrderStatRecord>(
  StatisticsBoxType.Chart,
  StatisticsBoxContext.Orders,
  {
    displayComponent: OrdersChart,
    filterComponent: OrderChartFilter,
    optionsComponent: CommonChartOptions,
  },
  [
    {
      label: 'Kurskategorie',
      value: 'CourseCategoryGraph',
      optionsComponent: CourseCategoryGraphItemOptions,
      optionsLabel: 'Kurskategorien wählen',
      filterCallback: filterRecordByCourseCategory,
      incrementCallback: incrementOrderStatRecord,
    },

    {
      label: 'Kurs',
      value: 'CourseGraph',
      optionsLabel: 'Kurse wählen',
      optionsComponent: CourseGraphItemOptions,
      filterCallback: filterRecordByCourse,
      incrementCallback: incrementOrderStatRecord,
    },
  ],
);

function incrementOrderStatRecord(
  oldValue: number,
  record: OrderStatRecord,
  options: ChartOptions,
) {
  if (options.property === 'amount') {
    return oldValue + record.amount * record.quantity;
  }
  return oldValue + record.quantity;
}

function filterRecordByCourseCategory(
  record: OrderStatRecord,
  opts: GraphOptions<GraphType>,
) {
  const options = opts as GraphOptions<'CourseCategoryGraph'>;

  if (options.courseCategoryIds?.length) {
    return options.courseCategoryIds.some(
      (id) => record.courseCategoryIds && record.courseCategoryIds.includes(id),
    );
  }
  return true;
}

function filterRecordByCourse(
  record: OrderStatRecord,
  opts: GraphOptions<GraphType>,
) {
  const options = opts as GraphOptions<'CourseGraph'>;
  if (options.courseIds?.length) {
    return options.courseIds.includes(record.courseId);
  }
  return true;
}
