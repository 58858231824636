import React, { ReactElement, useCallback } from 'react';
import { usePagination } from 'shared/hooks/pagination';
import { useUrlQuery } from 'shared/hooks/url-query';
import { PaginationInput } from '../../graphql/generated/types';
import { Loader } from '../loader/loader.component';
import { Pagination } from '../pagination/pagination.component';
import { Table } from '../table/table.component';
import styles from './list-view.module.scss';

export interface ListFilterPaginationQuery {
  take?: string;
  skip?: string;
}

export type FilterComponentProps<FilterType> = {
  onChange: (key: keyof FilterType, value: any) => any;
  value: FilterType;
  [others: string]: any;
};

type ColumnDefinition = {
  name: string;
  sortable: boolean;
};

export type ListViewProps<DataFragment, FilterType> = {
  renderFilter?: (props: FilterComponentProps<FilterType>) => ReactElement;
  renderRow: (row: DataFragment, index: number) => ReactElement;
  loading?: boolean;
  data: DataFragment[];
  columns: (ColumnDefinition | undefined)[];
  totalCount?: number;
  defaultFilter?: any;
  filterChange?(data: ListFilterPaginationQuery & FilterType): void;
  filter?: ListFilterPaginationQuery & FilterType;
};

export function ListView<DataFragment, FilterType = {}>(
  props: ListViewProps<DataFragment, FilterType>,
) {
  const {
    renderFilter,
    defaultFilter,
    renderRow,
    data,
    columns,
    totalCount,
    loading,
    filterChange,
    filter,
  } = props;
  const [urlQuery, setUrlQuery] = useUrlQuery<
    ListFilterPaginationQuery & FilterType
  >();

  const onFilterChange = useCallback(
    (key: keyof (ListFilterPaginationQuery & FilterType), value: string) => {
      if (filterChange) {
        filterChange({
          ...urlQuery,
          [key]: value,
          skip: 0,
        });
      } else {
        setUrlQuery({
          ...urlQuery,
          [key]: value,
          skip: '0',
        });
      }
    },
    [urlQuery, filterChange],
  );

  const onPaginationChange = useCallback(
    (value: PaginationInput) => {
      setUrlQuery({
        ...urlQuery,
        skip: value.skip?.toString(),
        take: value.take?.toString(),
      });
    },
    [urlQuery],
  );

  const pagination = usePagination(
    totalCount || 0,
    {
      skip: Number(urlQuery.skip),
      take: Number(urlQuery.take),
    },
    onPaginationChange,
  );

  return (
    <div className={styles.host}>
      <div className={styles.head}>
        {renderFilter && (
          <div className={styles.filter}>
            {renderFilter({
              value: filter ?? urlQuery,
              onChange: onFilterChange,
            })}
          </div>
        )}
        {totalCount !== undefined && <Pagination {...pagination} />}
      </div>
      <div className={styles.body}>
        {loading && <Loader />}
        <Table<DataFragment>
          className={styles.table}
          data={data}
          columns={(columns.filter((c) => !!c) as ColumnDefinition[]).map(
            (column) => column.name,
          )}
          render={renderRow}
        />
      </div>
    </div>
  );
}
