import pdfIcon from '@/assets/image/icons/pdf.svg';
import { Maybe } from '@/graphql/generated/types';
import classNames from 'classnames';
import React, { ComponentType, useMemo } from 'react';
import styles from './thumbnail.module.scss';

interface ThumbnailProps {
  size?: number;
  noBackground?: boolean;
  file?: {
    url: string;
    isImage: boolean;
    isRasterizedImage: boolean;
    mimetype?: Maybe<string>;
  };
}

export const Thumbnail: ComponentType<ThumbnailProps> = (props) => {
  const { file, size, noBackground } = props;
  const url = useMemo(() => {
    if (!file) {
      return null;
    }
    if (file.isRasterizedImage) {
      return `${file.url}?size=thumbnail`;
    }
    if (file.isImage) {
      return `${file.url}`;
    }

    if (
      file.mimetype === 'application/pdf' ||
      file.mimetype === 'application/x-pdf'
    ) {
      return pdfIcon;
    }
  }, [file]);

  const style = useMemo(() => {
    if (size) {
      return {
        width: `${size}px`,
        height: `${size}px`,
      };
    }
    return undefined;
  }, [size]);

  if (!url) {
    return (
      <div
        className={classNames(styles.host, noBackground && styles.noBackground)}
        style={style}
      >
        n/a
      </div>
    );
  }
  return (
    <div
      className={classNames(styles.host, noBackground && styles.noBackground)}
      style={style}
    >
      <img src={url} />
    </div>
  );
};
