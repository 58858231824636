import { useRoleGuard } from '@/auth/role/role.hooks';
import { AdministratorList } from '@/contact/person/list/administrator/list.component';
import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { List } from './admin-actions/list/list.component';
import { Service } from './service/service.component';

export const AdministrationRoutes: ComponentType<RouteComponentProps> = (
  props,
) => {
  let menuItems = [];

  const { isSuper } = useRoleGuard();
  return (
    <>
      <Frame.Title>Administration</Frame.Title>
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            {
              label: 'edi Nutzer',
              path: 'users',
            },
            {
              label: 'Wartung',
              path: 'service',
            },
            isSuper
              ? {
                  label: 'Logs',
                  path: 'logs',
                }
              : undefined,
          ]}
        />
      </Frame.SubMenu>
      <Router className="router">
        <AdministratorList path="users" />
        <Service path="service" />
        <List path="logs" />
        <Redirect noThrow from="/" to="users" />
      </Router>
    </>
  );
};
