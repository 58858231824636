import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { PackageFragmentDoc } from '../../list/list.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PackageQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type PackageQuery = {
  __typename?: 'Query';
  adminPackage: {
    __typename?: 'Package';
    id: string;
    title: string;
    subTitle?: string | null;
    abbreviation: string;
    description?: string | null;
    purchasable: boolean;
    status: Types.PackageStatus;
    amount: number;
    taxRate: number;
    tagIds?: Array<any> | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
    }> | null;
    courseUnits: Array<{
      __typename?: 'CourseUnit';
      id: any;
      status: Types.CourseUnitStatus;
      title?: string | null;
      subTitle?: string | null;
      description?: string | null;
      startDate: any;
      endDate: any;
      dateUncertain: boolean;
      withTime: boolean;
      modulePriceMatrix?: string | null;
      bookingNotes?: string | null;
      properties: any;
      searchableLocationId?: any | null;
      locationId?: any | null;
      online: boolean;
      correspondenceCourse: boolean;
      amount: number;
      discountedAmount?: number | null;
      earlyBirdDate?: any | null;
      earlyBirdNotice?: string | null;
      courseId: any;
      teacherId?: any | null;
      teacherIds?: Array<any> | null;
      tagIds?: Array<any> | null;
      seasonId?: any | null;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      lateBookingOffset?: {
        __typename?: 'Duration';
        value?: number | null;
        factor?: number | null;
      } | null;
      course: {
        __typename?: 'Course';
        id: any;
        title: string;
        abbreviation: string;
        earlyBirdDate?: any | null;
        earlyBirdNotice?: string | null;
        tagIds?: Array<any> | null;
        category: {
          __typename?: 'CourseCategory';
          enterprise: { __typename?: 'Enterprise'; id: any };
        };
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      };
      teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
      teachers?: Array<{
        __typename?: 'Teacher';
        id: any;
        fullName: string;
      }> | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
      modules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        status: Types.CourseModuleStatus;
        dateUncertain: boolean;
        withTime: boolean;
        tagIds?: Array<any> | null;
        online: boolean;
        correspondenceCourse: boolean;
        location?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        searchableLocation?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      }>;
      season?: {
        __typename?: 'Season';
        title: string;
        enterpriseId: any;
        enterprise: { __typename?: 'Enterprise'; name: string };
      } | null;
    }>;
  };
};

export type UpdatePackageMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.SavePackageInput;
}>;

export type UpdatePackageMutation = {
  __typename?: 'Mutation';
  adminUpdatePackage: {
    __typename?: 'Package';
    id: string;
    title: string;
    subTitle?: string | null;
    abbreviation: string;
    description?: string | null;
    purchasable: boolean;
    status: Types.PackageStatus;
    amount: number;
    taxRate: number;
    tagIds?: Array<any> | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
    }> | null;
    courseUnits: Array<{
      __typename?: 'CourseUnit';
      id: any;
      status: Types.CourseUnitStatus;
      title?: string | null;
      subTitle?: string | null;
      description?: string | null;
      startDate: any;
      endDate: any;
      dateUncertain: boolean;
      withTime: boolean;
      modulePriceMatrix?: string | null;
      bookingNotes?: string | null;
      properties: any;
      searchableLocationId?: any | null;
      locationId?: any | null;
      online: boolean;
      correspondenceCourse: boolean;
      amount: number;
      discountedAmount?: number | null;
      earlyBirdDate?: any | null;
      earlyBirdNotice?: string | null;
      courseId: any;
      teacherId?: any | null;
      teacherIds?: Array<any> | null;
      tagIds?: Array<any> | null;
      seasonId?: any | null;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      lateBookingOffset?: {
        __typename?: 'Duration';
        value?: number | null;
        factor?: number | null;
      } | null;
      course: {
        __typename?: 'Course';
        id: any;
        title: string;
        abbreviation: string;
        earlyBirdDate?: any | null;
        earlyBirdNotice?: string | null;
        tagIds?: Array<any> | null;
        category: {
          __typename?: 'CourseCategory';
          enterprise: { __typename?: 'Enterprise'; id: any };
        };
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      };
      teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
      teachers?: Array<{
        __typename?: 'Teacher';
        id: any;
        fullName: string;
      }> | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
      modules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        status: Types.CourseModuleStatus;
        dateUncertain: boolean;
        withTime: boolean;
        tagIds?: Array<any> | null;
        online: boolean;
        correspondenceCourse: boolean;
        location?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        searchableLocation?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      }>;
      season?: {
        __typename?: 'Season';
        title: string;
        enterpriseId: any;
        enterprise: { __typename?: 'Enterprise'; name: string };
      } | null;
    }>;
  };
};

export type CreatePackageMutationVariables = Types.Exact<{
  input: Types.SavePackageInput;
}>;

export type CreatePackageMutation = {
  __typename?: 'Mutation';
  adminCreatePackage: {
    __typename?: 'Package';
    id: string;
    title: string;
    subTitle?: string | null;
    abbreviation: string;
    description?: string | null;
    purchasable: boolean;
    status: Types.PackageStatus;
    amount: number;
    taxRate: number;
    tagIds?: Array<any> | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
    }> | null;
    courseUnits: Array<{
      __typename?: 'CourseUnit';
      id: any;
      status: Types.CourseUnitStatus;
      title?: string | null;
      subTitle?: string | null;
      description?: string | null;
      startDate: any;
      endDate: any;
      dateUncertain: boolean;
      withTime: boolean;
      modulePriceMatrix?: string | null;
      bookingNotes?: string | null;
      properties: any;
      searchableLocationId?: any | null;
      locationId?: any | null;
      online: boolean;
      correspondenceCourse: boolean;
      amount: number;
      discountedAmount?: number | null;
      earlyBirdDate?: any | null;
      earlyBirdNotice?: string | null;
      courseId: any;
      teacherId?: any | null;
      teacherIds?: Array<any> | null;
      tagIds?: Array<any> | null;
      seasonId?: any | null;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      lateBookingOffset?: {
        __typename?: 'Duration';
        value?: number | null;
        factor?: number | null;
      } | null;
      course: {
        __typename?: 'Course';
        id: any;
        title: string;
        abbreviation: string;
        earlyBirdDate?: any | null;
        earlyBirdNotice?: string | null;
        tagIds?: Array<any> | null;
        category: {
          __typename?: 'CourseCategory';
          enterprise: { __typename?: 'Enterprise'; id: any };
        };
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      };
      teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
      teachers?: Array<{
        __typename?: 'Teacher';
        id: any;
        fullName: string;
      }> | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
      modules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        status: Types.CourseModuleStatus;
        dateUncertain: boolean;
        withTime: boolean;
        tagIds?: Array<any> | null;
        online: boolean;
        correspondenceCourse: boolean;
        location?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        searchableLocation?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      }>;
      season?: {
        __typename?: 'Season';
        title: string;
        enterpriseId: any;
        enterprise: { __typename?: 'Enterprise'; name: string };
      } | null;
    }>;
  };
};

export type DeletePackageMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type DeletePackageMutation = {
  __typename?: 'Mutation';
  adminDeletePackage: boolean;
};

export const PackageDocument = gql`
  query package($id: UUID!) {
    adminPackage(id: $id) {
      ...Package
    }
  }
  ${PackageFragmentDoc}
`;
export type PackageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PackageQuery,
    PackageQueryVariables
  >,
  'query'
> &
  ({ variables: PackageQueryVariables; skip?: boolean } | { skip: boolean });

export const PackageComponent = (props: PackageComponentProps) => (
  <ApolloReactComponents.Query<PackageQuery, PackageQueryVariables>
    query={PackageDocument}
    {...props}
  />
);

/**
 * __usePackageQuery__
 *
 * To run a query within a React component, call `usePackageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePackageQuery(
  baseOptions: Apollo.QueryHookOptions<PackageQuery, PackageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PackageQuery, PackageQueryVariables>(
    PackageDocument,
    options,
  );
}
export function usePackageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PackageQuery,
    PackageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PackageQuery, PackageQueryVariables>(
    PackageDocument,
    options,
  );
}
export type PackageQueryHookResult = ReturnType<typeof usePackageQuery>;
export type PackageLazyQueryHookResult = ReturnType<typeof usePackageLazyQuery>;
export type PackageQueryResult = Apollo.QueryResult<
  PackageQuery,
  PackageQueryVariables
>;
export const UpdatePackageDocument = gql`
  mutation updatePackage($id: UUID!, $input: SavePackageInput!) {
    adminUpdatePackage(id: $id, input: $input) {
      ...Package
    }
  }
  ${PackageFragmentDoc}
`;
export type UpdatePackageMutationFn = Apollo.MutationFunction<
  UpdatePackageMutation,
  UpdatePackageMutationVariables
>;
export type UpdatePackageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePackageMutation,
    UpdatePackageMutationVariables
  >,
  'mutation'
>;

export const UpdatePackageComponent = (props: UpdatePackageComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdatePackageMutation,
    UpdatePackageMutationVariables
  >
    mutation={UpdatePackageDocument}
    {...props}
  />
);

/**
 * __useUpdatePackageMutation__
 *
 * To run a mutation, you first call `useUpdatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageMutation, { data, loading, error }] = useUpdatePackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePackageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePackageMutation,
    UpdatePackageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePackageMutation,
    UpdatePackageMutationVariables
  >(UpdatePackageDocument, options);
}
export type UpdatePackageMutationHookResult = ReturnType<
  typeof useUpdatePackageMutation
>;
export type UpdatePackageMutationResult =
  Apollo.MutationResult<UpdatePackageMutation>;
export type UpdatePackageMutationOptions = Apollo.BaseMutationOptions<
  UpdatePackageMutation,
  UpdatePackageMutationVariables
>;
export const CreatePackageDocument = gql`
  mutation createPackage($input: SavePackageInput!) {
    adminCreatePackage(input: $input) {
      ...Package
    }
  }
  ${PackageFragmentDoc}
`;
export type CreatePackageMutationFn = Apollo.MutationFunction<
  CreatePackageMutation,
  CreatePackageMutationVariables
>;
export type CreatePackageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePackageMutation,
    CreatePackageMutationVariables
  >,
  'mutation'
>;

export const CreatePackageComponent = (props: CreatePackageComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreatePackageMutation,
    CreatePackageMutationVariables
  >
    mutation={CreatePackageDocument}
    {...props}
  />
);

/**
 * __useCreatePackageMutation__
 *
 * To run a mutation, you first call `useCreatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackageMutation, { data, loading, error }] = useCreatePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePackageMutation,
    CreatePackageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePackageMutation,
    CreatePackageMutationVariables
  >(CreatePackageDocument, options);
}
export type CreatePackageMutationHookResult = ReturnType<
  typeof useCreatePackageMutation
>;
export type CreatePackageMutationResult =
  Apollo.MutationResult<CreatePackageMutation>;
export type CreatePackageMutationOptions = Apollo.BaseMutationOptions<
  CreatePackageMutation,
  CreatePackageMutationVariables
>;
export const DeletePackageDocument = gql`
  mutation deletePackage($id: UUID!) {
    adminDeletePackage(id: $id)
  }
`;
export type DeletePackageMutationFn = Apollo.MutationFunction<
  DeletePackageMutation,
  DeletePackageMutationVariables
>;
export type DeletePackageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeletePackageMutation,
    DeletePackageMutationVariables
  >,
  'mutation'
>;

export const DeletePackageComponent = (props: DeletePackageComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeletePackageMutation,
    DeletePackageMutationVariables
  >
    mutation={DeletePackageDocument}
    {...props}
  />
);

/**
 * __useDeletePackageMutation__
 *
 * To run a mutation, you first call `useDeletePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePackageMutation, { data, loading, error }] = useDeletePackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePackageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePackageMutation,
    DeletePackageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePackageMutation,
    DeletePackageMutationVariables
  >(DeletePackageDocument, options);
}
export type DeletePackageMutationHookResult = ReturnType<
  typeof useDeletePackageMutation
>;
export type DeletePackageMutationResult =
  Apollo.MutationResult<DeletePackageMutation>;
export type DeletePackageMutationOptions = Apollo.BaseMutationOptions<
  DeletePackageMutation,
  DeletePackageMutationVariables
>;
