import { FileGroup } from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { Button } from '@/ui/button/button.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Thumbnail } from '@/ui/thumbnail/thumbnail.component';
import classNames from 'classnames';
import React, { ChangeEvent, ComponentType, useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Translator } from 'shared/translator/translator.component';
import globalStyles from 'styles/global.scss';
import { FileGroupSelectField } from '../file-group-select-field.component';
import { useExplorer } from './explorer.context';
import styles from './explorer.module.scss';
interface ExplorerProps {}

export const Explorer: ComponentType<ExplorerProps> = (props) => {
  const profile = Profile.use();
  const {
    isOpen,
    close,
    toggle,
    onSelect,
    onUpload,
    filter,
    setFilter,
    files,
    withSelection,
    withUpload,
  } = useExplorer();

  const _ = Translator.useTranslator();
  const inputRef = useRef<HTMLInputElement>(null);
  const onClickFile = useCallback((id) => {
    close();
    onSelect(id);
  }, []);

  const onFileChange = useCallback(
    ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (files && files.length > 0) {
        onUpload(files.item(0) as File);
      } else {
        onUpload(undefined);
      }
    },
    [onUpload],
  );

  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (monitor) {
        const files = (monitor.getItem() as any).files;

        if (files.length > 0) {
          onUpload(files.item(0) as File);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  if (!profile) {
    return null;
  }

  return (
    <>
      <div className={classNames(styles.host, isOpen && styles.open)}>
        {withUpload && (
          <div className={globalStyles.flexAlignRight}>
            <Button
              primary
              label="Hochladen"
              onClick={() => inputRef.current?.click()}
            />
          </div>
        )}
        <DebouncedInput
          label="Suche"
          debounceRate={400}
          placeholder="Dateiname"
          value={filter.search || ''}
          onChange={(value) => setFilter({ ...filter, search: value })}
        />
        <FileGroupSelectField
          value={filter.group}
          onChange={(value) =>
            setFilter({ ...filter, group: value as FileGroup })
          }
        />

        <div ref={dropRef}>
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            onChange={onFileChange}
          />
          {isOpen && (
            <ul className={styles.fileList}>
              {files.map((file) => (
                <li
                  className={styles.fileItem}
                  onClick={withSelection ? () => onClickFile(file) : undefined}
                  key={file.id}
                >
                  <Thumbnail noBackground size={40} file={file} />
                  <div className={styles.filename}>
                    <Copyable valueToCopy={file.url}>{file.name}</Copyable>
                    <div className={styles.uploadDate}>
                      <Formatted.Date value={file.createdAt} withTime />,{' '}
                      {file.enterprise?.name}
                    </div>
                  </div>
                  <div className={styles.group}>
                    {_(`file-group.${file.group}`)}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {isOpen && <div className={styles.dimmer} onClick={close} />}
    </>
  );
};
