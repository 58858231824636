import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { HelpPopup } from '@/ui/help-popup/help-popup.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { Section } from '@/ui/section/section.component';
import { Tabs } from '@/ui/tabs/tabs.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { SubNav } from '../sub-nav.component';
import {
  useEditEnterpriseSettingsMutation,
  useEnterpriseSettingsQuery,
} from './settings.generated';

type Values = {
  communicationSettings: {
    data: {
      mailHost: string;
      mailPort: string;
      mailUser: string;
      mailPass: string;
      mailFrom: string;
      mailAddressCourseManagement: string;
      mailAddressSupport: string;
      mailBaseTemplateHtml: string;
      mailBaseTemplateTxt: string;
      useCustomerEmailAsMailFrom: boolean;
    };
  };
  websiteSettings: {
    data: {
      websiteBaseUri: string;
    };
  };
};

const schema = object({
  communicationSettings: object({
    data: object({
      mailHost: string(),
      mailPort: string(),
      mailUser: string(),
      mailPass: string(),
      mailFrom: string(),
      mailAddressCourseManagement: string().email(),
      mailAddressSupport: string().email(),
    }),
  }),
  websiteSettings: object({
    data: object({
      websiteBaseUri: string(),
    }),
  }),
});

type EnterpriseSettingsParams = {
  id?: string;
};

interface Props {
  enterprise: { id: string; name: string };
}

export const Settings: ComponentType<
  RouteComponentProps<EnterpriseSettingsParams> & Props
> = (props) => {
  const enterpriseQuery = useEnterpriseSettingsQuery({
    variables: { id: props.id },
    skip: props.id === undefined,
  });

  const [save] = useEditEnterpriseSettingsMutation();
  const form = useForm<Values>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const onSubmit = useMemo(() => {
    return form.handleSubmit(
      async (input) => {
        try {
          if (props.id) {
            await save({
              variables: {
                id: props.id,
                input,
              },
            });
            success('Die Einstellungen wurden gespeichert.');
          }
        } catch (e) {
          error('Ein Fehler ist beim Speichern aufgetreten.');
        }
      },
      (e) => {
        error('Ein Fehler ist aufgetreten.');
        console.error(e);
      },
    );
  }, [props.id, form.handleSubmit]);

  return (
    <>
      <Frame.Title>Einstellungen</Frame.Title>

      <Frame.SubTitle>
        {enterpriseQuery.data?.adminEnterprise.name}
      </Frame.SubTitle>
      <SubNav current="settings" />
      <Frame.ActionBar>
        <Button label="Zurück" linkTo="/enterprises" />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form
          form={form}
          values={enterpriseQuery.data?.adminEnterprise as Values}
        >
          <Tabs
            tabs={[
              {
                title: 'Website',
                content: (
                  <Grid.Row>
                    <Grid.Column>
                      <Section title="URLs">
                        <Form.Input
                          name="websiteSettings.data.websiteBaseUri"
                          label="URL-Stamm (Passwort zurücksetzen)"
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                  </Grid.Row>
                ),
              },
              {
                title: 'Kommunikation',
                content: (
                  <Grid.Row>
                    <Grid.Column>
                      <Section title="Mailversand">
                        <Form.Input
                          name="communicationSettings.data.mailFrom"
                          label="Absender E-Mail"
                        />
                        <Form.Input
                          name="communicationSettings.data.mailHost"
                          label="Host"
                        />
                        <Form.Input
                          name="communicationSettings.data.mailPort"
                          label="Port"
                        />
                        <Form.Input
                          name="communicationSettings.data.mailUser"
                          label="Username"
                        />
                        <Form.Input
                          name="communicationSettings.data.mailPass"
                          label="Passwort"
                        />

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Form.Checkbox
                            name="communicationSettings.data.useCustomerEmailAsMailFrom"
                            label="Kunden-Email als E-Mail Absender verwenden"
                          />
                          <HelpPopup
                            withIcon
                            click={{
                              content:
                                'Diese Option kann zu Fehlern im Mailversand führen, wenn das E-Mail Konto nicht für diese Aktion konfiguriert ist.',
                            }}
                          />
                        </div>
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Section title="Adressen">
                        <Form.Input
                          name="communicationSettings.data.mailAddressCourseManagement"
                          label={
                            <>
                              E-Mail Kursmanagement{' '}
                              <HelpPopup hover="An diese Adresse werden u.a. Benachrichtigungen für Kurs-Buchungen versendet." />
                            </>
                          }
                        />
                        <Form.Input
                          name="communicationSettings.data.mailAddressSupport"
                          label={
                            <>
                              E-Mail Beratung{' '}
                              <HelpPopup hover="An diese Adresse werden u.a. Benachrichtigungen für eingehende Beratungen gesendet." />
                            </>
                          }
                        />
                      </Section>
                      <Section title="Globale Templates">
                        <Form.Textarea
                          name="communicationSettings.data.mailBaseTemplateHtml"
                          label="Mail-Basis-Template (HTML)"
                        />
                        <Form.Textarea
                          name="communicationSettings.data.mailBaseTemplateTxt"
                          label="Mail-Basis-Template (Reiner Text)"
                        />
                      </Section>
                    </Grid.Column>
                  </Grid.Row>
                ),
              },
              {
                title: 'Features',
                content: <InfoBox.Empty />,
              },
            ]}
          />
        </Form>
      </Frame.Content>
    </>
  );
};
