import { DiscountRuleStatus } from '@/graphql/generated/types';
import { isSuperAdmin, Profile } from '@/profile/profile';
import { Button } from '@/ui/button/button.component';
import { DropdownMenu } from '@/ui/dropdown-menu/dropdown-menu.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { Frame } from '../../../frame';
import { ListView } from '../../../ui/list-view/list-view.component';
import { Row } from '../../../ui/table/row.component';
import { useDiscountRuleActions } from '../actions/actions.hooks';
import { DiscountRuleDependenciesView } from '../dependencies/dependencies.component';
import { useDiscountRulesQuery } from './list.generated';

export const DiscountRuleList: ComponentType<RouteComponentProps> = (props) => {
  const profile = Profile.use();
  const [query] = usePaginatedUrlQuery();
  const { data, loading, refetch } = useDiscountRulesQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
    },
    fetchPolicy: 'network-only',
  });

  const _ = Translator.useTranslator();

  const isSuper = useMemo(() => isSuperAdmin(profile), [profile]);
  const { modalDelete, askForDelete } = useDiscountRuleActions(() => refetch());

  return (
    <>
      <Frame.Title>Rabatte</Frame.Title>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>

      <Frame.ActionBar>
        <Button linkTo="/products/discounts/create" label="Neuer Rabatt" />
      </Frame.ActionBar>

      <Frame.Content>
        {modalDelete}
        <ListView
          loading={loading}
          data={data?.discountRules?.data || []}
          totalCount={data?.discountRules?.totalCount}
          columns={[
            {
              name: 'Titel',
              sortable: true,
            },
            {
              name: 'Abhängigkeiten',
              sortable: false,
            },
            {
              name: 'Rabatt',
              sortable: true,
            },

            isSuper
              ? {
                  name: 'Unternehmen',
                  sortable: true,
                }
              : undefined,
            { name: 'Aktionen', sortable: false },
          ]}
          renderRow={(item) => (
            <Row
              key={item.id}
              className={
                item.status === DiscountRuleStatus.Inactive
                  ? 'inactive'
                  : undefined
              }
            >
              <td>
                <Link to={`../${item.id}/edit`}>{item.title}</Link>
              </td>
              <td>
                {item.useCheckbox && (
                  <>
                    Checkbox
                    <br />
                  </>
                )}
                {item.voucherCode && (
                  <>
                    <em>Code: {item.voucherCode}</em>
                    <br />
                  </>
                )}
                <DiscountRuleDependenciesView
                  dependencies={item.dependencies}
                />
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>
                  <Formatted.Currency value={item.amount} />
                </Link>
              </td>

              {isSuper && <td>{item.enterprise.name}</td>}
              <td>
                <DropdownMenu
                  actions={[
                    {
                      label: 'Bearbeiten',
                      href: `./${item.id}/edit`,
                    },
                    {
                      label: 'Löschen',
                      meaning: 'error',
                      onClick: () => askForDelete(item),
                    },
                  ]}
                />
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
