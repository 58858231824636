import { Gender } from '@/graphql/generated/types';
import React, { ComponentType } from 'react';
import {
  SelectField,
  SelectFieldProps,
} from '../select-field/select-field.component';

export interface GenderInputFieldProps
  extends Omit<SelectFieldProps, 'choices'> {}

export const GenderInputField: ComponentType<GenderInputFieldProps> = (
  props,
) => {
  return (
    <SelectField
      {...props}
      nullable
      choices={[
        {
          label: 'Weiblich',
          value: Gender.Female,
        },
        {
          label: 'Männlich',
          value: Gender.Male,
        },
        {
          label: 'Divers',
          value: Gender.Diverse,
        },
      ]}
    />
  );
};
