import { useMemo } from 'react';
import { buildYup } from 'schema-to-yup';
import { object } from 'yup';
import { usePropertySchemaContextQuery } from './property-schema.generated';

export function useYupPropertySchema(enterpriseId: string, context: string) {
  const { data } = usePropertySchemaContextQuery({
    variables: {
      enterpriseId,
      context,
    },
  });

  return useMemo(
    () =>
      object().shape({
        properties: object().shape(
          (data?.adminPropertySchemaContext ?? []).reduce(
            (shape, jsonSchema) => ({
              ...shape,
              [jsonSchema.name]: buildYup(
                JSON.parse(jsonSchema.value),
                Object.entries(JSON.parse(jsonSchema.value).properties).reduce(
                  (options, [property]) => ({
                    errMessages: {
                      ...options.errMessages,
                      [property]: {
                        required: 'Pflichtfeld',
                        typeError: () => 'Falscher Datentyp',
                        minLength: ({ minLength }) =>
                          `Mindestlänge ${minLength} Zeichen`,
                        maxLength: ({ maxLength }) =>
                          `Maximallänge ${maxLength} Zeichen`,
                      },
                    },
                  }),
                  { errMessages: {} },
                ),
              ),
            }),
            {},
          ),
        ),
      }),
    [data],
  );
}
