import { Season } from '@/graphql/generated/types';
import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { InputField } from '@/ui/input-field/input-field.component';
import { useModal } from '@/ui/modal/modal.hooks';
import { error, success } from '@/ui/toaster/toast';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import globalStyles from 'styles/global.scss';
import { useDuplicateSeasonMutation } from './duplicator.generated';

interface DuplicateSeasonOptions {
  onSubmit(sourceSeasonId: string, title?: string): void;
}

export function useDuplicateSeasonModal(options: DuplicateSeasonOptions): [
  ReactNode,
  {
    open: (season: Season | undefined) => void;
    close: () => void;
  },
] {
  const [sourceSeason, setSourceSeason] = useState<Season | undefined>();
  const [title, setTitle] = useState<string>('');

  const close = useCallback(() => {
    modal[1].close();
  }, []);

  useEffect(() => {
    if (sourceSeason?.title) {
      setTitle(sourceSeason.title);
    }
  }, [sourceSeason]);

  const modal = useModal(
    <Box title="Saison duplizieren">
      <p>Diese Saison wird dupliziert.</p>
      <p>
        ACHTUNG!
        <br />
        Jede Kurs-Einheit und jedes Kurs-Modul in der Quellsaison wird auch
        dupliziert. Die erforderlichen Daten müssen aktualisiert werden.
      </p>

      <InputField
        onChange={(e) => setTitle(e.target.value)}
        value={title}
        label="Titel der neuen Saison"
      />
      <div className={globalStyles.flexAlignRight}>
        <Button label="Abbrechen" onClick={close} />
        <Button
          primary
          label="Duplizieren"
          onClick={() => {
            options.onSubmit((sourceSeason as Season).id, title);
          }}
        />
      </div>
    </Box>,
    { closeOnDimmerClick: false },
  );

  const open = useCallback(
    (sourceSeason: Season | undefined) => {
      setSourceSeason(sourceSeason);
      modal[1].open();
    },
    [modal[1].open],
  );

  return [modal[0], { ...modal[1], open }];
}

export const useDuplicateSeason = (
  callback?: () => void,
): {
  open: (sourceSeason: Season | undefined) => void;
  modal: ReactNode;
} => {
  const [duplicateSeason] = useDuplicateSeasonMutation();

  const onDuplicate = async (sourceSeasonId: string, title: string) => {
    try {
      await duplicateSeason({
        variables: { id: sourceSeasonId, input: { title } },
      });
      success('Die Saison wurde dupliziert.');
      close();
      if (callback) {
        callback();
      }
    } catch (e) {
      error('Beim Kopieren der Saison ist ein Fehler aufgetreten.');
    }
  };

  const [duplicationConfirmModal, { open, close }] = useDuplicateSeasonModal({
    onSubmit: onDuplicate,
  });

  return {
    open,
    modal: duplicationConfirmModal,
  };
};
