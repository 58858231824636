import {
  StatisticsBoxContext,
  StatisticsBoxType,
} from '@/graphql/generated/types';
import { InputField } from '@/ui/input-field/input-field.component';
import { useConfirm, useCustomDialogue } from '@/ui/modal/modal.hooks';
import { SelectField2 } from '@/ui/select-field-2/select-field-2.component';
import React, { useCallback, useMemo, useState } from 'react';
import { getGraphTypes } from '../../box/statistics-box-registry';
import { Graph, GraphType } from './graph';

export function useGraphActions(
  context: StatisticsBoxContext,
  type: StatisticsBoxType,
  graphs: Graph<any>[] | undefined,
  onUpdateGraphs,
) {
  const [newTitle, setNewTitle] = useState('');
  const [newType, setNewType] = useState<GraphType>();
  const [modalDelete, askDelete] = useConfirm(
    async (index: number) => {
      if (graphs) {
        const newGraphs = [...graphs];
        newGraphs.splice(index, 1);
        onUpdateGraphs(newGraphs);
      }
    },
    {
      meaning: 'error',
      title: 'Graph entfernen',
    },
  );

  const addGraph = useCallback(() => {
    if (newType && newTitle) {
      onUpdateGraphs([
        ...(graphs || []),
        {
          name: newTitle,
          enabled: true,
          options: {},
          type: newType,
        },
      ]);
      setNewTitle('');
      setNewType(undefined);
    }
  }, [graphs, newTitle, newType, onUpdateGraphs]);

  const availableGraphTypes = useMemo(() => {
    return getGraphTypes(type, context);
  }, [type, context]);

  const [modalAdd, askAdd] = useCustomDialogue(
    <>
      <InputField
        label="Name"
        value={newTitle}
        onChange={(e) => setNewTitle(e.currentTarget.value)}
      />
      <SelectField2
        value={newType}
        onChange={(v) => setNewType(v as GraphType)}
        label="Typ"
        choices={availableGraphTypes}
      />
    </>,
    {
      actions: [
        { handler: addGraph, title: 'Hinzufügen', primary: true },
        { handler: () => askAdd.close(), title: 'Abbrechen' },
      ],
      title: 'Graph hinzufügen',
    },
  );

  const askForDelete = useCallback((index: number) => {
    askDelete(
      <>
        <p>Soll der Graph wirklich entfernt werden?</p>
      </>,

      index,
    );
  }, []);

  const toggleGraph = useCallback(
    (index: number, value: boolean) => {
      if (graphs) {
        onUpdateGraphs(
          graphs.map((graph, i) =>
            index === i ? { ...graph, enabled: value } : graph,
          ),
        );
      }
    },
    [graphs],
  );

  const updateGraphOptions = useCallback(
    (index: number, value: any) => {
      if (graphs) {
        onUpdateGraphs(
          graphs.map((graph, i) =>
            index === i ? { ...graph, options: value } : graph,
          ),
        );
      }
    },
    [graphs, onUpdateGraphs],
  );

  return {
    toggleGraph,
    modalDelete,
    askForDelete,
    modalAdd,
    askAdd,
    updateGraphOptions,
  };
}
