// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EvaHdgAXNTOgBXK9NgVF td{border-bottom:1px solid #666}", "",{"version":3,"sources":["webpack://./src/order/shared/order-items-overview/order-items-overview.module.scss"],"names":[],"mappings":"AAGE,yBACE,4BAAA","sourcesContent":["@import 'styles/variables';\n\n.withBorderBottom {\n  td {\n    border-bottom: 1px solid $color-darkgrey;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withBorderBottom": "EvaHdgAXNTOgBXK9NgVF"
};
export default ___CSS_LOADER_EXPORT___;
