import { RoleType } from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { Frame } from '../frame';
import { Equipment } from './equipment/equipment.component';
import { Location } from './location/location.component';
import { RoomManagement } from './room-management/room-management.component';
import { Room } from './rooms/room.component';

export const LocationRoutes: ComponentType<RouteComponentProps> = (props) => {
  const profile = Profile.use();
  let menuItems: { label: string; path: string }[] = [];

  const isSuperAdmin = useMemo(() => {
    if (!profile) {
      return false;
    }
    return profile.roles.find((role) => role.name === RoleType.Super);
  }, [profile]);

  const isRoomManager = useMemo(() => {
    if (!profile) {
      return false;
    }
    return profile.roles.find((role) => role.name === RoleType.RoomManager);
  }, [profile]);

  if (isSuperAdmin || isRoomManager) {
    menuItems = [
      {
        label: 'Raum-/Terminplanung',
        path: 'room-management',
      },
      {
        label: 'Standorte',
        path: 'locations',
      },
      {
        label: 'Raumverwaltung',
        path: 'rooms',
      },
      {
        label: 'Ausstattung',
        path: 'equipment',
      },
    ];
  } else {
    menuItems = [
      {
        label: 'Standorte',
        path: 'locations',
      },
    ];
  }

  return (
    <>
      <Frame.SubMenu>
        <NavMenu type="horizontal" items={menuItems} />
      </Frame.SubMenu>
      <Router className="router">
        <Location path="locations/*" />
        <Room path="rooms/*" />
        <Equipment path="equipment/*" />
        <RoomManagement path="room-management/*" />
        <Redirect noThrow from="/" to="locations" />
      </Router>
    </>
  );
};
