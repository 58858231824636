import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type EditEnterpriseDataFragment = { __typename?: 'Enterprise', id: any, name: string, slug?: string | null, isAdmin: boolean, logoId?: any | null, logo?: { __typename?: 'FilesystemFile', id: any, url: string } | null };

export type EnterpriseQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type EnterpriseQuery = { __typename?: 'Query', adminEnterprise: { __typename?: 'Enterprise', id: any, name: string, slug?: string | null, isAdmin: boolean, logoId?: any | null, logo?: { __typename?: 'FilesystemFile', id: any, url: string } | null } };

export type EditEnterpriseMutationVariables = Types.Exact<{
  input: Types.EditEnterpriseInput;
  id: Types.Scalars['UUID'];
}>;


export type EditEnterpriseMutation = { __typename?: 'Mutation', adminEditEnterprise: { __typename?: 'Enterprise', id: any, name: string, slug?: string | null, isAdmin: boolean, logoId?: any | null, logo?: { __typename?: 'FilesystemFile', id: any, url: string } | null } };

export type CreateEnterpriseMutationVariables = Types.Exact<{
  input: Types.EnterpriseInput;
}>;


export type CreateEnterpriseMutation = { __typename?: 'Mutation', adminCreateEnterprise: { __typename?: 'Enterprise', id: any, name: string, slug?: string | null, isAdmin: boolean, logoId?: any | null, logo?: { __typename?: 'FilesystemFile', id: any, url: string } | null } };

export type UpdateEnterpriseFilesMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.UpdateEnterpriseFilesInput;
}>;


export type UpdateEnterpriseFilesMutation = { __typename?: 'Mutation', adminUpdateEnterpriseFiles: boolean };

export const EditEnterpriseDataFragmentDoc = gql`
    fragment EditEnterpriseData on Enterprise {
  id
  name
  slug
  isAdmin
  logoId
  logo {
    id
    url
  }
}
    `;
export const EnterpriseDocument = gql`
    query enterprise($id: UUID!) {
  adminEnterprise(id: $id) {
    ...EditEnterpriseData
  }
}
    ${EditEnterpriseDataFragmentDoc}`;
export type EnterpriseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EnterpriseQuery, EnterpriseQueryVariables>, 'query'> & ({ variables: EnterpriseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EnterpriseComponent = (props: EnterpriseComponentProps) => (
      <ApolloReactComponents.Query<EnterpriseQuery, EnterpriseQueryVariables> query={EnterpriseDocument} {...props} />
    );
    

/**
 * __useEnterpriseQuery__
 *
 * To run a query within a React component, call `useEnterpriseQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnterpriseQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseQuery, EnterpriseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseQuery, EnterpriseQueryVariables>(EnterpriseDocument, options);
      }
export function useEnterpriseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseQuery, EnterpriseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseQuery, EnterpriseQueryVariables>(EnterpriseDocument, options);
        }
export type EnterpriseQueryHookResult = ReturnType<typeof useEnterpriseQuery>;
export type EnterpriseLazyQueryHookResult = ReturnType<typeof useEnterpriseLazyQuery>;
export type EnterpriseQueryResult = Apollo.QueryResult<EnterpriseQuery, EnterpriseQueryVariables>;
export const EditEnterpriseDocument = gql`
    mutation editEnterprise($input: EditEnterpriseInput!, $id: UUID!) {
  adminEditEnterprise(input: $input, id: $id) {
    ...EditEnterpriseData
  }
}
    ${EditEnterpriseDataFragmentDoc}`;
export type EditEnterpriseMutationFn = Apollo.MutationFunction<EditEnterpriseMutation, EditEnterpriseMutationVariables>;
export type EditEnterpriseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditEnterpriseMutation, EditEnterpriseMutationVariables>, 'mutation'>;

    export const EditEnterpriseComponent = (props: EditEnterpriseComponentProps) => (
      <ApolloReactComponents.Mutation<EditEnterpriseMutation, EditEnterpriseMutationVariables> mutation={EditEnterpriseDocument} {...props} />
    );
    

/**
 * __useEditEnterpriseMutation__
 *
 * To run a mutation, you first call `useEditEnterpriseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEnterpriseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEnterpriseMutation, { data, loading, error }] = useEditEnterpriseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditEnterpriseMutation(baseOptions?: Apollo.MutationHookOptions<EditEnterpriseMutation, EditEnterpriseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEnterpriseMutation, EditEnterpriseMutationVariables>(EditEnterpriseDocument, options);
      }
export type EditEnterpriseMutationHookResult = ReturnType<typeof useEditEnterpriseMutation>;
export type EditEnterpriseMutationResult = Apollo.MutationResult<EditEnterpriseMutation>;
export type EditEnterpriseMutationOptions = Apollo.BaseMutationOptions<EditEnterpriseMutation, EditEnterpriseMutationVariables>;
export const CreateEnterpriseDocument = gql`
    mutation createEnterprise($input: EnterpriseInput!) {
  adminCreateEnterprise(input: $input) {
    ...EditEnterpriseData
  }
}
    ${EditEnterpriseDataFragmentDoc}`;
export type CreateEnterpriseMutationFn = Apollo.MutationFunction<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>;
export type CreateEnterpriseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>, 'mutation'>;

    export const CreateEnterpriseComponent = (props: CreateEnterpriseComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEnterpriseMutation, CreateEnterpriseMutationVariables> mutation={CreateEnterpriseDocument} {...props} />
    );
    

/**
 * __useCreateEnterpriseMutation__
 *
 * To run a mutation, you first call `useCreateEnterpriseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnterpriseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnterpriseMutation, { data, loading, error }] = useCreateEnterpriseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnterpriseMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>(CreateEnterpriseDocument, options);
      }
export type CreateEnterpriseMutationHookResult = ReturnType<typeof useCreateEnterpriseMutation>;
export type CreateEnterpriseMutationResult = Apollo.MutationResult<CreateEnterpriseMutation>;
export type CreateEnterpriseMutationOptions = Apollo.BaseMutationOptions<CreateEnterpriseMutation, CreateEnterpriseMutationVariables>;
export const UpdateEnterpriseFilesDocument = gql`
    mutation updateEnterpriseFiles($id: UUID!, $input: UpdateEnterpriseFilesInput!) {
  adminUpdateEnterpriseFiles(id: $id, input: $input)
}
    `;
export type UpdateEnterpriseFilesMutationFn = Apollo.MutationFunction<UpdateEnterpriseFilesMutation, UpdateEnterpriseFilesMutationVariables>;
export type UpdateEnterpriseFilesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEnterpriseFilesMutation, UpdateEnterpriseFilesMutationVariables>, 'mutation'>;

    export const UpdateEnterpriseFilesComponent = (props: UpdateEnterpriseFilesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEnterpriseFilesMutation, UpdateEnterpriseFilesMutationVariables> mutation={UpdateEnterpriseFilesDocument} {...props} />
    );
    

/**
 * __useUpdateEnterpriseFilesMutation__
 *
 * To run a mutation, you first call `useUpdateEnterpriseFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnterpriseFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnterpriseFilesMutation, { data, loading, error }] = useUpdateEnterpriseFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnterpriseFilesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnterpriseFilesMutation, UpdateEnterpriseFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnterpriseFilesMutation, UpdateEnterpriseFilesMutationVariables>(UpdateEnterpriseFilesDocument, options);
      }
export type UpdateEnterpriseFilesMutationHookResult = ReturnType<typeof useUpdateEnterpriseFilesMutation>;
export type UpdateEnterpriseFilesMutationResult = Apollo.MutationResult<UpdateEnterpriseFilesMutation>;
export type UpdateEnterpriseFilesMutationOptions = Apollo.BaseMutationOptions<UpdateEnterpriseFilesMutation, UpdateEnterpriseFilesMutationVariables>;