import classnames from 'classnames';
import React, { memo } from 'react';
import { formatDate, formatMoney, secondsToTime } from 'shared/format/format';
import styles from './formatted.module.scss';
export interface FormattedDurationProps {
  value?: number | null;
}

const FormattedDuration = memo<FormattedDurationProps>((props) => (
  <>{props.value ? secondsToTime(props.value) : 'n. a.'}</>
));

export interface FormattedDateProps {
  value?: Date | string | null;
  withTime?: boolean;
  format?: string;
}

const FormattedDate = memo<FormattedDateProps>((props) =>
  props.value ? (
    <>{formatDate(props.value, props.withTime, props.format)}</>
  ) : null,
);

export interface FormattedCurrencyProps {
  value: number;
  precision?: number;
  isDiscount?: boolean;
}

const FormattedCurrency = memo<FormattedCurrencyProps>((props) => {
  const { value, precision = 2, isDiscount } = props;

  return (
    <span
      className={classnames(styles.currency, isDiscount && styles.isDiscount)}
    >
      {formatMoney(value, precision)}
    </span>
  );
});

export const Formatted = Object.assign(
  {},
  {
    Date: FormattedDate,
    Currency: FormattedCurrency,
    Duration: FormattedDuration,
  },
);
