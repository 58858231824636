import sandboxIcon from '@/assets/image/icons/sandbox.svg';
import { RoleType } from '@/graphql/generated/types';
import { Profile, isSuperAdmin } from '@/profile/profile';
import { Button } from '@/ui/button/button.component';
import { Icon } from '@/ui/icon/icon.component';
import { NavMenu, NavMenuItem } from '@/ui/nav-menu/nav-menu.component';
import { useApolloClient } from '@apollo/client';
import { navigate } from '@reach/router';
import React, { ComponentType, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { intersect, isSandbox } from 'shared/utils';
import { Logo } from '../../ui/logo/logo.component';
import { useLogoutMutation } from './menu.generated';
import styles from './menu.module.scss';

const items: NavMenuItem[] = [
  { label: 'Dashboard', path: 'dashboard' },
  {
    label: 'Leads',
    path: 'leads',
    roles: [RoleType.Super, RoleType.CourseManager],
  },
  {
    label: 'Bestellungen',
    path: 'orders',
    roles: [RoleType.Super, RoleType.Analyst],
  },
  {
    label: 'Teilnehmermanagement',
    path: 'course-handling',
    roles: [RoleType.Super, RoleType.CourseManager],
  },
  {
    label: 'Anfragen',
    path: 'inquiries',
    roles: [RoleType.Super, RoleType.Consultant, RoleType.CourseManager],
  },
  {
    label: 'Kontakte',
    path: 'contacts',
    roles: [RoleType.Super, RoleType.Admin, RoleType.CourseManager],
  },
  {
    label: 'Statistik',
    path: 'statistics',
    roles: [RoleType.Super, RoleType.Analyst],
  },
  {
    label: 'Kursverwaltung',
    path: 'course-management',
    roles: [RoleType.Super, RoleType.CourseManager],
  },
  {
    label: 'Terminverwaltung',
    path: 'event-management',
    roles: [
      RoleType.Super,
      RoleType.Consultant,
      RoleType.RoomManager,
      RoleType.CourseManager,
    ],
  },
  {
    label: 'Produktverwaltung',
    path: 'products',
    roles: [RoleType.Super, RoleType.CourseManager],
  },
  {
    label: 'Standortverwaltung',
    path: 'locations',
    roles: [RoleType.Super, RoleType.RoomManager, RoleType.CourseManager],
  },

  { label: 'Unternehmen', path: 'enterprises', roles: [RoleType.Super] },
  { label: 'Unternehmensprofil', path: 'enterprises', roles: [RoleType.Admin] },
  {
    label: 'Dateien',
    path: 'files',
    roles: [RoleType.Super, RoleType.Admin, RoleType.CourseManager],
  },
  {
    label: 'Tags',
    path: 'tags',
    roles: [RoleType.Super, RoleType.Admin],
  },
  {
    label: 'Datenschutz',
    path: 'privacy',
    roles: [RoleType.Super, RoleType.Admin],
  },
  {
    label: 'Administration',
    path: 'administration',
    roles: [RoleType.Super, RoleType.Admin],
  },
];

interface Props {}

export const Menu: ComponentType<Props> = ({}) => {
  const domNode = document.getElementById('menu-slot');
  const client = useApolloClient();
  const profile = Profile.use();

  const [logout] = useLogoutMutation();

  const onLogoutClick = useCallback(async () => {
    await logout();
    await client.resetStore();

    navigate('.');
  }, []);

  if (!domNode || !profile) {
    return null;
  }
  return ReactDOM.createPortal(
    <>
      <Logo size={60} className={styles.logo} />
      {isSandbox() && (
        <Icon size={36} className={styles.sandboxIcon} icon={sandboxIcon} />
      )}
      <NavMenu
        className={styles.menu}
        items={items.filter(
          (item) =>
            !item.roles ||
            intersect(
              item.roles,
              profile.roles.map((role) => role.name),
            ).length > 0,
        )}
      />

      <div className={styles.lower}>
        <Logo.SelectedEnterprise />
        <div className={styles.profileInfo}>
          <em>{profile.username}</em>
          <br />
          {profile.primaryEmail}

          {isSuperAdmin(profile) && (
            <div className="warning">Du bist Super Admin.</div>
          )}
        </div>
        <Button
          fluid
          label="Abmelden"
          onClick={onLogoutClick}
          className={styles.logoutButton}
        />
      </div>
    </>,
    domNode,
  );
};
