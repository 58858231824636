import {
  StatisticsBoard,
  StatisticsBox,
  StatisticsBoxContext,
  StatisticsBoxType,
  StatisticsSettings,
} from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { error, success } from '@/ui/toaster/toast';
import omitDeep from 'omit-deep-lodash';
import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  useShareStatisticsBoardMutation,
  useUpdateUiSettingsMutation,
} from '../../statistics.generated';
import { ChartOptions } from '../chart/options/options.provider';
import {
  _addBox,
  _createBoard,
  _deleteBoard,
  _deleteBox,
  _updateBox,
} from './actions';

interface StatisticBoardApi {
  settings?: StatisticsSettings | null;
  addBoard: (name: string) => void;
  deleteBoard: (id: string) => void;
  shareBoard: (board: StatisticsBoard, userIds: string[]) => void;
  addBox: (
    name: string,
    type: StatisticsBoxType,
    context: StatisticsBoxContext,
    boardId: string,
  ) => void;
  deleteBox: (boxId: string, boardId: string) => void;
  updateBox: (
    data: StatisticsBox & { options: ChartOptions },
    boardId: string,
  ) => void;
}

const StatisticsBoardsContext = createContext<StatisticBoardApi | undefined>(
  undefined,
);

export const StatisticsBoardProvider: ComponentType = ({ children }) => {
  const profile = Profile.use();
  const reloadProfile = Profile.useReload();

  const settings = useMemo(() => {
    return profile.uiSettings.statistics && profile.selectedEnterprise
      ? profile.uiSettings.statistics.find(
          (s) => s.enterpriseId === profile.selectedEnterprise!.id,
        )
      : undefined;
  }, [profile.selectedEnterprise, profile.uiSettings]);

  const [updateUiSettings] = useUpdateUiSettingsMutation();
  const [share] = useShareStatisticsBoardMutation();

  const addBoard = useCallback(
    async (name: string) => {
      if (!profile.selectedEnterprise) {
        return;
      }
      const nextStatisticsSettingsArray = _createBoard(
        name,
        profile.uiSettings.statistics,
        profile.selectedEnterprise.id,
      );

      await updateUiSettings({
        variables: {
          input: omitDeep(
            {
              ...profile.uiSettings,
              statistics: nextStatisticsSettingsArray,
            },
            ['__typename'],
          ),
        },
      });

      reloadProfile();
    },
    [profile.uiSettings.statistics, profile.selectedEnterprise],
  );

  const deleteBoard = useCallback(
    async (id: string) => {
      if (!profile.selectedEnterprise) {
        return;
      }
      const nextStatisticsSettingsArray = _deleteBoard(
        id,
        profile.uiSettings.statistics,
        profile.selectedEnterprise.id,
      );

      await updateUiSettings({
        variables: {
          input: omitDeep(
            {
              ...profile.uiSettings,
              statistics: nextStatisticsSettingsArray,
            },
            ['__typename'],
          ),
        },
      });

      reloadProfile();
    },
    [profile.uiSettings.statistics, profile.selectedEnterprise],
  );

  const shareBoard = useCallback(
    async (board: StatisticsBoard, userIds: string[]) => {
      if (!profile.selectedEnterprise) {
        return;
      }

      try {
        await share({
          variables: {
            input: {
              board: omitDeep(board, ['__typename']) as StatisticsBoard,
              userIds,
            },
          },
        });
        success('Das Board wurde geteilt.');
      } catch (e) {
        error('Ein Fehler ist aufgetreten.');
      }

      reloadProfile();
    },
    [profile.uiSettings.statistics, profile.selectedEnterprise],
  );

  const addBox = useCallback(
    async (
      name: string,
      type: StatisticsBoxType,
      context: StatisticsBoxContext,
      boardId: string,
    ) => {
      if (!profile.selectedEnterprise) {
        return;
      }
      const nextStatisticsSettingsArray = _addBox(
        name,
        type,
        context,
        boardId,
        profile.uiSettings.statistics,
        profile.selectedEnterprise.id,
      );

      await updateUiSettings({
        variables: {
          input: omitDeep(
            {
              ...profile.uiSettings,
              statistics: nextStatisticsSettingsArray,
            },
            ['__typename'],
          ),
        },
      });

      reloadProfile();
    },
    [profile.uiSettings.statistics, profile.selectedEnterprise],
  );

  const deleteBox = useCallback(
    async (boxId: string, boardId: string) => {
      if (!profile.selectedEnterprise) {
        return;
      }
      const nextStatisticsSettingsArray = _deleteBox(
        boxId,
        boardId,
        profile.uiSettings.statistics,
        profile.selectedEnterprise.id,
      );

      await updateUiSettings({
        variables: {
          input: omitDeep(
            {
              ...profile.uiSettings,
              statistics: nextStatisticsSettingsArray,
            },
            ['__typename'],
          ),
        },
      });

      reloadProfile();
    },
    [profile.uiSettings.statistics, profile.selectedEnterprise],
  );

  const updateBox = useCallback(
    async (data: StatisticsBox, boardId: string) => {
      if (!profile.selectedEnterprise) {
        return;
      }

      const nextStatisticsSettingsArray = _updateBox(
        data,
        boardId,
        profile.uiSettings.statistics,
        profile.selectedEnterprise.id,
      );

      await updateUiSettings({
        variables: {
          input: omitDeep(
            {
              ...profile.uiSettings,
              statistics: nextStatisticsSettingsArray,
            },
            ['__typename'],
          ),
        },
      });
      success('Die Einstellungen wurden gespeichert.');
      reloadProfile();
    },
    [profile.uiSettings.statistics, profile.selectedEnterprise],
  );

  return (
    <StatisticsBoardsContext.Provider
      value={{
        settings,
        addBoard,
        deleteBoard,
        shareBoard,
        addBox,
        deleteBox,
        updateBox,
      }}
    >
      {children}
    </StatisticsBoardsContext.Provider>
  );
};

export function useStatisticsBoards() {
  const context = useContext(StatisticsBoardsContext);
  if (!context) {
    throw new Error('Stats Context not ready');
  }

  return context;
}
