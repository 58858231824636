import {
  AdministratorInput,
  CustomerInput,
  PersonInput,
  TeacherInput,
  UserStatus,
} from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object } from 'yup';
import { administratorSchema } from './administrator/schema';
import { UserType } from './base/base-editor.component';
import { customerSchema } from './customer/schema';
import { personSchema } from './person/person.schema';
import { teacherSchema } from './teacher/schema';

function getSchema(userType?: UserType) {
  if (userType === 'administrators') {
    return object({
      person: personSchema,
      user: administratorSchema,
    });
  }
  if (userType === 'customers') {
    return object({
      person: personSchema,
      user: customerSchema,
    });
  }
  if (userType === 'teachers') {
    return object({
      person: personSchema,
      user: teacherSchema,
    });
  }
  return object({
    person: personSchema,
  });
}

type Values = {
  person: PersonInput;
  user?: CustomerInput | TeacherInput | AdministratorInput | null;
};

export function usePersonUserForm(userType?: UserType) {
  const schema = useMemo(() => getSchema(userType), [userType]);
  const profile = Profile.use();
  const form = useForm<Values>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
    defaultValues: {
      user: {
        status: UserStatus.Unregistered,
      },

      person: {
        gender: null,
        address: {
          country: 'DE',
        },
        contactProperties: [],
        employments: [],
        enterpriseIds: profile?.selectedEnterprise
          ? [profile.selectedEnterprise.id]
          : [],
      },
    },
  });

  return form;
}
