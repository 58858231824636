import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type RewriteCourseAndCategoryNamesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RewriteCourseAndCategoryNamesMutation = { __typename?: 'Mutation', rewriteCourseAndCategoryNames: boolean };

export type RemoveTestInstancesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveTestInstancesMutation = { __typename?: 'Mutation', removeTestInstances: boolean };

export type WriteStatisticsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type WriteStatisticsMutation = { __typename?: 'Mutation', writeStatistics: boolean };


export const RewriteCourseAndCategoryNamesDocument = gql`
    mutation rewriteCourseAndCategoryNames {
  rewriteCourseAndCategoryNames
}
    `;
export type RewriteCourseAndCategoryNamesMutationFn = Apollo.MutationFunction<RewriteCourseAndCategoryNamesMutation, RewriteCourseAndCategoryNamesMutationVariables>;
export type RewriteCourseAndCategoryNamesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RewriteCourseAndCategoryNamesMutation, RewriteCourseAndCategoryNamesMutationVariables>, 'mutation'>;

    export const RewriteCourseAndCategoryNamesComponent = (props: RewriteCourseAndCategoryNamesComponentProps) => (
      <ApolloReactComponents.Mutation<RewriteCourseAndCategoryNamesMutation, RewriteCourseAndCategoryNamesMutationVariables> mutation={RewriteCourseAndCategoryNamesDocument} {...props} />
    );
    

/**
 * __useRewriteCourseAndCategoryNamesMutation__
 *
 * To run a mutation, you first call `useRewriteCourseAndCategoryNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewriteCourseAndCategoryNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewriteCourseAndCategoryNamesMutation, { data, loading, error }] = useRewriteCourseAndCategoryNamesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRewriteCourseAndCategoryNamesMutation(baseOptions?: Apollo.MutationHookOptions<RewriteCourseAndCategoryNamesMutation, RewriteCourseAndCategoryNamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RewriteCourseAndCategoryNamesMutation, RewriteCourseAndCategoryNamesMutationVariables>(RewriteCourseAndCategoryNamesDocument, options);
      }
export type RewriteCourseAndCategoryNamesMutationHookResult = ReturnType<typeof useRewriteCourseAndCategoryNamesMutation>;
export type RewriteCourseAndCategoryNamesMutationResult = Apollo.MutationResult<RewriteCourseAndCategoryNamesMutation>;
export type RewriteCourseAndCategoryNamesMutationOptions = Apollo.BaseMutationOptions<RewriteCourseAndCategoryNamesMutation, RewriteCourseAndCategoryNamesMutationVariables>;
export const RemoveTestInstancesDocument = gql`
    mutation removeTestInstances {
  removeTestInstances
}
    `;
export type RemoveTestInstancesMutationFn = Apollo.MutationFunction<RemoveTestInstancesMutation, RemoveTestInstancesMutationVariables>;
export type RemoveTestInstancesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveTestInstancesMutation, RemoveTestInstancesMutationVariables>, 'mutation'>;

    export const RemoveTestInstancesComponent = (props: RemoveTestInstancesComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveTestInstancesMutation, RemoveTestInstancesMutationVariables> mutation={RemoveTestInstancesDocument} {...props} />
    );
    

/**
 * __useRemoveTestInstancesMutation__
 *
 * To run a mutation, you first call `useRemoveTestInstancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTestInstancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTestInstancesMutation, { data, loading, error }] = useRemoveTestInstancesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveTestInstancesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTestInstancesMutation, RemoveTestInstancesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTestInstancesMutation, RemoveTestInstancesMutationVariables>(RemoveTestInstancesDocument, options);
      }
export type RemoveTestInstancesMutationHookResult = ReturnType<typeof useRemoveTestInstancesMutation>;
export type RemoveTestInstancesMutationResult = Apollo.MutationResult<RemoveTestInstancesMutation>;
export type RemoveTestInstancesMutationOptions = Apollo.BaseMutationOptions<RemoveTestInstancesMutation, RemoveTestInstancesMutationVariables>;
export const WriteStatisticsDocument = gql`
    mutation writeStatistics {
  writeStatistics
}
    `;
export type WriteStatisticsMutationFn = Apollo.MutationFunction<WriteStatisticsMutation, WriteStatisticsMutationVariables>;
export type WriteStatisticsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<WriteStatisticsMutation, WriteStatisticsMutationVariables>, 'mutation'>;

    export const WriteStatisticsComponent = (props: WriteStatisticsComponentProps) => (
      <ApolloReactComponents.Mutation<WriteStatisticsMutation, WriteStatisticsMutationVariables> mutation={WriteStatisticsDocument} {...props} />
    );
    

/**
 * __useWriteStatisticsMutation__
 *
 * To run a mutation, you first call `useWriteStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWriteStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [writeStatisticsMutation, { data, loading, error }] = useWriteStatisticsMutation({
 *   variables: {
 *   },
 * });
 */
export function useWriteStatisticsMutation(baseOptions?: Apollo.MutationHookOptions<WriteStatisticsMutation, WriteStatisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WriteStatisticsMutation, WriteStatisticsMutationVariables>(WriteStatisticsDocument, options);
      }
export type WriteStatisticsMutationHookResult = ReturnType<typeof useWriteStatisticsMutation>;
export type WriteStatisticsMutationResult = Apollo.MutationResult<WriteStatisticsMutation>;
export type WriteStatisticsMutationOptions = Apollo.BaseMutationOptions<WriteStatisticsMutation, WriteStatisticsMutationVariables>;