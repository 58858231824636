import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AdminActionAuditLogsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
}>;


export type AdminActionAuditLogsQuery = { __typename?: 'Query', adminAuditLogs: { __typename?: 'PaginatedAuditLogResult', totalCount: number, data: Array<{ __typename?: 'AdminActionAuditLog', id: any, adminAction: string, adminActionDescription?: string | null, createdAt: any, admin: { __typename?: 'Administrator', id: any, username?: string | null }, person: { __typename?: 'Person', id: any, firstName?: string | null, lastName?: string | null, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }> } } | { __typename?: 'ConsentAuditLog' }> } };


export const AdminActionAuditLogsDocument = gql`
    query adminActionAuditLogs($pagination: PaginationInput!) {
  adminAuditLogs(pagination: $pagination, filter: {kind: "AdminActionAuditLog"}) {
    totalCount
    data {
      ... on AdminActionAuditLog {
        id
        adminAction
        adminActionDescription
        admin {
          id
          username
        }
        createdAt
        person {
          id
          firstName
          lastName
          contactProperties {
            id
            key
            value
          }
        }
      }
    }
  }
}
    `;
export type AdminActionAuditLogsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdminActionAuditLogsQuery, AdminActionAuditLogsQueryVariables>, 'query'> & ({ variables: AdminActionAuditLogsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdminActionAuditLogsComponent = (props: AdminActionAuditLogsComponentProps) => (
      <ApolloReactComponents.Query<AdminActionAuditLogsQuery, AdminActionAuditLogsQueryVariables> query={AdminActionAuditLogsDocument} {...props} />
    );
    

/**
 * __useAdminActionAuditLogsQuery__
 *
 * To run a query within a React component, call `useAdminActionAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminActionAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminActionAuditLogsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminActionAuditLogsQuery(baseOptions: Apollo.QueryHookOptions<AdminActionAuditLogsQuery, AdminActionAuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminActionAuditLogsQuery, AdminActionAuditLogsQueryVariables>(AdminActionAuditLogsDocument, options);
      }
export function useAdminActionAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminActionAuditLogsQuery, AdminActionAuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminActionAuditLogsQuery, AdminActionAuditLogsQueryVariables>(AdminActionAuditLogsDocument, options);
        }
export type AdminActionAuditLogsQueryHookResult = ReturnType<typeof useAdminActionAuditLogsQuery>;
export type AdminActionAuditLogsLazyQueryHookResult = ReturnType<typeof useAdminActionAuditLogsLazyQuery>;
export type AdminActionAuditLogsQueryResult = Apollo.QueryResult<AdminActionAuditLogsQuery, AdminActionAuditLogsQueryVariables>;