import { useEffect } from 'react';

export function useHideFrame() {
  useEffect(() => {
    let classNames = document.body.className.replace(/ ?hideFrame/, '');
    document.body.className = classNames + ' hideFrame';
    return () => {
      document.body.className = document.body.className.replace(
        / ?hideFrame/,
        '',
      );
    };
  }, []);
}
