import { DiscountLabel } from '@/order/discount-label/discount-label.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { ComponentType, default as React, default as React } from 'react';
import { getCourseUnitLocationLabel } from 'shared/course/utils';
import { OrdersQuery } from '../../list/list.generated';
import styles from './mini-order-items-overview.module.scss';

function ProductItem(props: { item: any }) {
  if (!props.item.product) {
    return null;
  }
  return (
    <>
      {props.item.product.title} - <i>{props.item.product.enterprise.name}</i>
    </>
  );
}

function BookingItem(props: { item: any }) {
  const locationLabel = getCourseUnitLocationLabel(
    props.item.booking.courseModule || props.item.booking.courseUnit,
  );
  const enterpriseName =
    props.item.booking.courseModule?.courseUnit.course.category.enterprise
      .name || props.item.booking.courseUnit?.course.category.enterprise.name;
  return (
    <>
      {props.item.booking.courseModule?.courseUnit.course.abbreviation ||
        props.item.booking.courseUnit?.course.abbreviation}
      {locationLabel && <> ({locationLabel})</>} - <i>{enterpriseName}</i>
    </>
  );
}

function PackageBookingItem(props: { item: any }) {
  const enterpriseName = props.item.package.enterprise.name;

  return (
    <>
      {props.item.package.title} - {props.item.package.subTitle}-{' '}
      <i>{enterpriseName}</i>
    </>
  );
}

interface MiniOrderItemsOverviewProps {
  order: OrdersQuery['adminOrders']['data'][number];
}

export const MiniOrderItemsOverview: ComponentType<
  MiniOrderItemsOverviewProps
> = (props) => {
  const { order } = props;
  const { items } = order;
  return (
    <ul className={styles.host}>
      {items
        .filter((i) => i.__typename !== 'DiscountOrderItem')
        .map((i) => (
          <li key={i.id}>
            {i.__typename === 'ProductOrderItem' && (
              <>
                {i.quantity} x <ProductItem item={i} /> à{' '}
                <Formatted.Currency value={i.amount} />
              </>
            )}
            {i.__typename === 'BookingOrderItem' && (
              <>
                {i.quantity} x <BookingItem item={i} /> à{' '}
                <Formatted.Currency value={i.amount} />
              </>
            )}
            {i.__typename === 'PackageBookingOrderItem' && (
              <>
                {i.quantity} x <PackageBookingItem item={i} /> à{' '}
                <Formatted.Currency value={i.amount} />
              </>
            )}
            {i.discounts?.length > 0 && (
              <ul>
                {i.discounts.map((d) => (
                  <li key={d.id}>
                    <>
                      {i.quantity} x {d.reason} à{' '}
                      <DiscountLabel value={d.amount} type={d.type} />
                    </>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}

      {order.discounts.map((d) => (
        <li key={d.id}>
          <>
            {d.reason} <DiscountLabel value={d.amount} type={d.type} />
          </>
        </li>
      ))}
    </ul>
  );
};
