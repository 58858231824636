import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { Grid } from '@/ui/grid/grid.component';
import { Loader } from '@/ui/loader/loader.component';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import toast from 'react-hot-toast';
import { useResources } from 'shared/resources/resources.provider';
import { Frame } from '../../frame';
import {
  useRemoveTestInstancesMutation,
  useRewriteCourseAndCategoryNamesMutation,
  useWriteStatisticsMutation,
} from './service.generated';

export const Service: ComponentType<RouteComponentProps> = (props) => {
  const [rewrite, { loading: rewriteLoading }] =
    useRewriteCourseAndCategoryNamesMutation();
  const [removeTestInstances, { loading: removeLoading }] =
    useRemoveTestInstancesMutation();
  const [writeStatistics, { loading: writeStatsLoading }] =
    useWriteStatisticsMutation();
  const loading = rewriteLoading || removeLoading || writeStatsLoading;

  const { reload } = useResources();

  return (
    <>
      <Frame.SubTitle>Wartung</Frame.SubTitle>
      <Frame.Content>
        {loading && <Loader />}
        <Grid.Row>
          <Grid.Column>
            <Box title="Kurs-Kategorie-Pfade" withMargin>
              <Button
                onClick={() => {
                  rewrite()
                    .then(() => {
                      reload();
                      toast.success('Erledigt.');
                    })
                    .catch(() => toast.error('Da ist etwas schief gelaufen.'));
                }}
                label="Pfade neu schreiben"
              />
            </Box>
            <Box title="Statistiken" withMargin>
              <p>
                Statistiken über Bestellungen u.a. werden aktuell einmal pro Tag
                automatisch abgeglichen. Für eine genauere Momentaufnahme, kann
                dies auch manuell erfolgen.
              </p>
              <Button
                onClick={() => {
                  writeStatistics()
                    .then(() => toast.success('Erledigt.'))
                    .catch(() => toast.error('Da ist etwas schief gelaufen.'));
                }}
                label="Statistiken abgleichen"
              />
            </Box>
          </Grid.Column>
          <Grid.Column>
            <Box title="Testing">
              <Button
                label="Alle Testinstanzen entfernen"
                onClick={() => {
                  removeTestInstances()
                    .then(() =>
                      toast.success('Alle Instanzen werden entfernt.'),
                    )
                    .catch(() => toast.error('Da ist etwas schief gelaufen.'));
                }}
              />
            </Box>
          </Grid.Column>
        </Grid.Row>
      </Frame.Content>
    </>
  );
};
