import { EnhancedEventInterface } from '@/event/context/event.context';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import { format, getDay, parse, startOfWeek } from 'date-fns';
import { de } from 'date-fns/locale';
import React, { ComponentType, ReactElement, useCallback } from 'react';
import {
  Calendar,
  dateFnsLocalizer,
  EventWrapperProps,
  Formats,
  Messages,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useEventCalendar } from './event-calendar.context';
import styles from './event-calendar.module.scss';

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales: { de },
});

const formats: Formats = {
  weekdayFormat: 'EEEE',
};

const messages: Messages = {
  next: 'Vor',
  previous: 'Zurück',
  agenda: 'Übersicht',
  allDay: 'Ganztägig',
  yesterday: 'Gestern',
  work_week: 'Arbeitswoche',
  week: 'Woche',
  tomorrow: 'Morgen',
  today: 'Heute',
  time: 'Uhrzeit',
  showMore: () => 'Mehr',
  noEventsInRange: 'Keine Termine',
  month: 'Monat',
  event: 'Event',
  date: 'Datum',
  day: 'Tag',
};

const EventWrapperComponent: ComponentType<
  EventWrapperProps<any & EnhancedEventInterface>
> = (props) => {
  const { event, children } = props;
  const enhancedChildren = React.cloneElement(children as ReactElement, {
    className: classNames(
      (children as ReactElement).props.className,
      styles.eventWrapper,
      event.isPreview && styles.isPreview,
    ),
  });
  return enhancedChildren;
};

const DateCellWrapperComponent: ComponentType = (props) => {
  const { children } = props;
  const enhancedChildren = React.cloneElement(children as ReactElement, {
    className: classNames(
      (children as ReactElement).props.className,
      styles.dateCell,
    ),
  });
  return enhancedChildren;
};

interface EventCalendarProps<T extends EnhancedEventInterface> {
  events: T[];
  renderTitle: (event: T) => string;
  renderTooltip: (event: T) => string;
  eventWrapper?: any; //ComponentType<EventWrapperProps<T>>;
  dateHeader?: any;
  onSelectSlot?: (e) => void;
}

export function EventCalendar<T extends EnhancedEventInterface>(
  props: EventCalendarProps<T>,
) {
  const {
    events,
    renderTitle,
    renderTooltip,
    eventWrapper,
    onSelectSlot,
    dateHeader,
  } = props;

  const { range, setRange } = useEventCalendar();

  const goToEvent = useCallback((event: T) => {
    navigate(`/event-management/events/${event.id}/detail`);
  }, []);

  return (
    <>
      <Calendar<T>
        selectable={true}
        components={{
          eventWrapper: eventWrapper || EventWrapperComponent,
          month: {
            dateHeader: dateHeader,
          },
        }}
        culture="de"
        localizer={localizer}
        onRangeChange={(range) => {
          if (Array.isArray(range)) {
            if (range.length > 2) {
              setRange({
                startDate: range[0],
                endDate: range[range.length - 1],
              });
            } else {
              setRange({ startDate: range[0], endDate: range[1] });
            }
          } else {
            setRange({
              startDate: range.start as Date,
              endDate: range.end as Date,
            });
          }
        }}
        events={events}
        onDoubleClickEvent={goToEvent}
        tooltipAccessor={renderTooltip}
        titleAccessor={renderTitle}
        startAccessor="startDate"
        endAccessor="endDate"
        style={{ height: 600 }}
        messages={messages}
        formats={formats}
        onSelectSlot={onSelectSlot}
      />
    </>
  );
}
