import { useCallback, useMemo } from 'react';
import { PaginationInput } from '../../graphql/generated/types';

export const PAGINATION_TAKE_DEFAULT = 50;

export function usePagination(
  totalCount: number,
  value: PaginationInput,
  onChange: (value: PaginationInput) => any,
) {
  let skip = Number.isFinite(value.skip) ? value.skip : 0;
  let take = Number.isFinite(value.take) ? value.take : PAGINATION_TAKE_DEFAULT;

  const currentPage = useMemo(() => {
    if (take === 0) {
      return 0;
    }
    return Math.floor(skip / take);
  }, [totalCount, take, skip]);

  const numPages = useMemo(() => {
    if (take === 0) {
      return 0;
    }
    return Math.ceil(totalCount / take);
  }, [totalCount, take]);

  const setCurrentPage = useCallback(
    (page: number) => {
      skip = take * Math.max(0, Math.min(page, numPages - 1));
      onChange({ skip, take });
    },
    [numPages, take],
  );

  const wrappedSetTake = (value: number) => {
    take = value;
    onChange({ skip, take });
  };

  return {
    take,
    setTake: wrappedSetTake,
    skip,
    totalCount,
    numPages,
    currentPage,
    setCurrentPage,
  };
}
