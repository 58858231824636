import { Button } from '@/ui/button/button.component';
import React, { ChangeEvent, ComponentType, useCallback, useRef } from 'react';

interface UploadButtonProps {
  label: string;
  onUpload: (file: File | null | undefined) => void;
  small?: boolean;
  primary?: boolean;
  fluid?: boolean;
}

export const UploadButton: ComponentType<UploadButtonProps> = (props) => {
  const { onUpload, label, small, primary, fluid } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const onFileChange = useCallback(
    ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (files && files.length > 0) {
        onUpload(files.item(0) as File);
      } else {
        onUpload(undefined);
      }
    },
    [onUpload],
  );

  return (
    <>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={onFileChange}
      />
      <Button
        fluid={fluid}
        small={small}
        primary={primary}
        label={label}
        onClick={() => inputRef.current?.click()}
      />
    </>
  );
};
