import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import { PersonListDataFragmentDoc } from '../../contact/person/list/list.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AvailableLocationsDataFragment = {
  __typename?: 'Location';
  id: any;
  name: string;
  commonName: string;
  city?: string | null;
  parentId?: any | null;
  address?: { __typename?: 'Address'; city?: string | null } | null;
  enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
};

export type AvailableEnterpriseDataFragment = {
  __typename?: 'Enterprise';
  id: any;
  name: string;
  courseAttributeSets: Array<string>;
};

export type AvailableRoleDataFragment = {
  __typename?: 'Role';
  id: any;
  name: Types.RoleType;
};

export type AvailableCourseCategoryDataFragment = {
  __typename?: 'CourseCategory';
  id: any;
  title: string;
  verboseTitle?: string | null;
  parentId?: any | null;
  enterpriseId: any;
  enterprise: {
    __typename?: 'Enterprise';
    id: any;
    name: string;
    courseAttributeSets: Array<string>;
  };
  courses?: Array<{
    __typename?: 'Course';
    id: any;
    abbreviation: string;
    title: string;
    infoCourse: boolean;
  }> | null;
};

export type AvailableSeasonDataFragment = {
  __typename?: 'Season';
  id: any;
  title: string;
  enterpriseId: any;
};

export type AvailableCompanyDataFragment = {
  __typename?: 'Company';
  id: any;
  name: string;
};

export type AvailableEnterprisesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AvailableEnterprisesQuery = {
  __typename?: 'Query';
  enterprises: Array<{
    __typename?: 'Enterprise';
    id: any;
    name: string;
    courseAttributeSets: Array<string>;
  }>;
};

export type ResourcesQueryVariables = Types.Exact<{
  enterpriseIds?: Types.InputMaybe<
    Array<Types.Scalars['UUID']> | Types.Scalars['UUID']
  >;
  pagination: Types.PaginationInput;
}>;

export type ResourcesQuery = {
  __typename?: 'Query';
  roles: Array<{ __typename?: 'Role'; id: any; name: Types.RoleType }>;
  locations: Array<{
    __typename?: 'Location';
    id: any;
    name: string;
    commonName: string;
    city?: string | null;
    parentId?: any | null;
    address?: { __typename?: 'Address'; city?: string | null } | null;
    enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
  }>;
  adminCourseCategories: {
    __typename?: 'PaginatedCourseCategoryResult';
    data: Array<{
      __typename?: 'CourseCategory';
      id: any;
      title: string;
      verboseTitle?: string | null;
      parentId?: any | null;
      enterpriseId: any;
      enterprise: {
        __typename?: 'Enterprise';
        id: any;
        name: string;
        courseAttributeSets: Array<string>;
      };
      courses?: Array<{
        __typename?: 'Course';
        id: any;
        abbreviation: string;
        title: string;
        infoCourse: boolean;
      }> | null;
    }>;
  };
  adminTeachers: {
    __typename?: 'PaginatedPersonWithDuplicatesResult';
    totalCount: number;
    data: Array<{
      __typename?: 'PersonWithDuplicates';
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      anonymizedAt?: any | null;
      duplicates?: Array<{
        __typename?: 'Duplicate';
        reasons: Array<string>;
      }> | null;
      user?:
        | {
            __typename: 'Administrator';
            roleIds?: Array<any> | null;
            isBot: boolean;
            id: any;
            username?: string | null;
            status: Types.UserStatus;
            roles: Array<{
              __typename?: 'Role';
              id: any;
              name: Types.RoleType;
            }>;
          }
        | {
            __typename: 'Customer';
            id: any;
            username?: string | null;
            status: Types.UserStatus;
          }
        | {
            __typename: 'Teacher';
            id: any;
            username?: string | null;
            status: Types.UserStatus;
          }
        | null;
      enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
      contactProperties: Array<{
        __typename?: 'ContactProperty';
        key: Types.ContactPropertyKey;
        value: string;
      }>;
    }>;
  };
  adminAvailableAdministrators: {
    __typename?: 'PaginatedPersonWithDuplicatesResult';
    totalCount: number;
    data: Array<{
      __typename?: 'PersonWithDuplicates';
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      anonymizedAt?: any | null;
      duplicates?: Array<{
        __typename?: 'Duplicate';
        reasons: Array<string>;
      }> | null;
      user?:
        | {
            __typename: 'Administrator';
            roleIds?: Array<any> | null;
            isBot: boolean;
            id: any;
            username?: string | null;
            status: Types.UserStatus;
            roles: Array<{
              __typename?: 'Role';
              id: any;
              name: Types.RoleType;
            }>;
          }
        | {
            __typename: 'Customer';
            id: any;
            username?: string | null;
            status: Types.UserStatus;
          }
        | {
            __typename: 'Teacher';
            id: any;
            username?: string | null;
            status: Types.UserStatus;
          }
        | null;
      enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
      contactProperties: Array<{
        __typename?: 'ContactProperty';
        key: Types.ContactPropertyKey;
        value: string;
      }>;
    }>;
  };
  adminAvailableSeasons: {
    __typename?: 'PaginatedSeasonResult';
    totalCount: number;
    data: Array<{
      __typename?: 'Season';
      id: any;
      title: string;
      enterpriseId: any;
    }>;
  };
  adminAvailableCompanies: {
    __typename?: 'PaginatedCompanyResult';
    totalCount: number;
    data: Array<{ __typename?: 'Company'; id: any; name: string }>;
  };
};

export const AvailableLocationsDataFragmentDoc = gql`
  fragment AvailableLocationsData on Location {
    id
    name
    commonName
    city
    address {
      city
    }
    parentId
    enterprises {
      id
      name
    }
  }
`;
export const AvailableEnterpriseDataFragmentDoc = gql`
  fragment AvailableEnterpriseData on Enterprise {
    id
    name
    courseAttributeSets
  }
`;
export const AvailableRoleDataFragmentDoc = gql`
  fragment AvailableRoleData on Role {
    id
    name
  }
`;
export const AvailableCourseCategoryDataFragmentDoc = gql`
  fragment AvailableCourseCategoryData on CourseCategory {
    id
    title
    verboseTitle
    parentId
    enterprise {
      id
      name
      courseAttributeSets
    }
    enterpriseId
    courses {
      id
      abbreviation
      title
      infoCourse
    }
  }
`;
export const AvailableSeasonDataFragmentDoc = gql`
  fragment AvailableSeasonData on Season {
    id
    title
    enterpriseId
  }
`;
export const AvailableCompanyDataFragmentDoc = gql`
  fragment AvailableCompanyData on Company {
    id
    name
  }
`;
export const AvailableEnterprisesDocument = gql`
  query availableEnterprises {
    enterprises {
      ...AvailableEnterpriseData
    }
  }
  ${AvailableEnterpriseDataFragmentDoc}
`;
export type AvailableEnterprisesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AvailableEnterprisesQuery,
    AvailableEnterprisesQueryVariables
  >,
  'query'
>;

export const AvailableEnterprisesComponent = (
  props: AvailableEnterprisesComponentProps,
) => (
  <ApolloReactComponents.Query<
    AvailableEnterprisesQuery,
    AvailableEnterprisesQueryVariables
  >
    query={AvailableEnterprisesDocument}
    {...props}
  />
);

/**
 * __useAvailableEnterprisesQuery__
 *
 * To run a query within a React component, call `useAvailableEnterprisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableEnterprisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableEnterprisesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableEnterprisesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableEnterprisesQuery,
    AvailableEnterprisesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableEnterprisesQuery,
    AvailableEnterprisesQueryVariables
  >(AvailableEnterprisesDocument, options);
}
export function useAvailableEnterprisesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableEnterprisesQuery,
    AvailableEnterprisesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableEnterprisesQuery,
    AvailableEnterprisesQueryVariables
  >(AvailableEnterprisesDocument, options);
}
export type AvailableEnterprisesQueryHookResult = ReturnType<
  typeof useAvailableEnterprisesQuery
>;
export type AvailableEnterprisesLazyQueryHookResult = ReturnType<
  typeof useAvailableEnterprisesLazyQuery
>;
export type AvailableEnterprisesQueryResult = Apollo.QueryResult<
  AvailableEnterprisesQuery,
  AvailableEnterprisesQueryVariables
>;
export const ResourcesDocument = gql`
  query resources($enterpriseIds: [UUID!], $pagination: PaginationInput!) {
    roles {
      ...AvailableRoleData
    }
    locations(enterpriseIds: $enterpriseIds) {
      ...AvailableLocationsData
    }
    adminCourseCategories(
      pagination: $pagination
      enterpriseIds: $enterpriseIds
    ) {
      data {
        ...AvailableCourseCategoryData
      }
    }
    adminTeachers: persons(
      pagination: $pagination
      filter: { userType: "Teacher" }
    ) {
      totalCount
      data {
        ...PersonListData
      }
    }
    adminAvailableAdministrators: persons(
      pagination: $pagination
      filter: { userType: "Administrator" }
    ) {
      totalCount
      data {
        ...PersonListData
      }
    }
    adminAvailableSeasons: adminSeasons(pagination: $pagination) {
      totalCount
      data {
        ...AvailableSeasonData
      }
    }
    adminAvailableCompanies: adminCompanies(pagination: $pagination) {
      totalCount
      data {
        ...AvailableCompanyData
      }
    }
  }
  ${AvailableRoleDataFragmentDoc}
  ${AvailableLocationsDataFragmentDoc}
  ${AvailableCourseCategoryDataFragmentDoc}
  ${PersonListDataFragmentDoc}
  ${AvailableSeasonDataFragmentDoc}
  ${AvailableCompanyDataFragmentDoc}
`;
export type ResourcesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ResourcesQuery,
    ResourcesQueryVariables
  >,
  'query'
> &
  ({ variables: ResourcesQueryVariables; skip?: boolean } | { skip: boolean });

export const ResourcesComponent = (props: ResourcesComponentProps) => (
  <ApolloReactComponents.Query<ResourcesQuery, ResourcesQueryVariables>
    query={ResourcesDocument}
    {...props}
  />
);

/**
 * __useResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesQuery({
 *   variables: {
 *      enterpriseIds: // value for 'enterpriseIds'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<ResourcesQuery, ResourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourcesQuery, ResourcesQueryVariables>(
    ResourcesDocument,
    options,
  );
}
export function useResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourcesQuery,
    ResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourcesQuery, ResourcesQueryVariables>(
    ResourcesDocument,
    options,
  );
}
export type ResourcesQueryHookResult = ReturnType<typeof useResourcesQuery>;
export type ResourcesLazyQueryHookResult = ReturnType<
  typeof useResourcesLazyQuery
>;
export type ResourcesQueryResult = Apollo.QueryResult<
  ResourcesQuery,
  ResourcesQueryVariables
>;
