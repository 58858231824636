import { DiscountRule } from '@/graphql/generated/types';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { error, success } from '@/ui/toaster/toast';
import React, { useCallback } from 'react';
import { useDeleteDiscountRuleMutation } from './actions.generated';

export function useDiscountRuleActions(callback: () => void) {
  const [deleteDiscountRule] = useDeleteDiscountRuleMutation();
  const [modalDelete, askDelete] = useConfirm(
    async (id: string) => {
      try {
        await deleteDiscountRule({
          variables: {
            id,
          },
        });
        success('Der Rabatt wurde gelöscht.');
        callback();
      } catch (e: any) {
        console.error(e);
        error('Beim Löschen ist ein Fehler aufgetreten.', e.message);
      }
    },
    { meaning: 'error', title: 'Rabatt löschen' },
  );

  const askForDelete = useCallback(
    (discountRule: Pick<DiscountRule, 'id' | 'title'>) => {
      askDelete(
        <>
          <p>
            Bist du sicher, dass du den Rabatt <em>{discountRule.title}</em>{' '}
            löschen möchtest?
          </p>
        </>,

        discountRule.id,
      );
    },
    [],
  );
  return { modalDelete, askForDelete };
}
