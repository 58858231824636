import { Attribute, ProductStatus } from '@/graphql/generated/types';
import { CopyId } from '@/ui/copy-id/copy-id.component';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { HtmlField } from '@/ui/html-field/html-field.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { InputField } from '@/ui/input-field/input-field.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import { omit } from 'lodash';
import React, { ComponentType, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getAttributeValueKeyByKind } from 'shared/attributes/attributes.helper';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { array, number, object, string } from 'yup';
import { Frame } from '../../../frame';
import { Button } from '../../../ui/button/button.component';
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useEditProductMutation,
  useProductQuery,
} from './editor.generated';

type Values = {
  title: string;
  kind: 'SimpleProduct' | 'LocationProduct';
  description: string;
  amount: number;
  enterpriseId: string;
  attributes: Attribute[];
  locationIds?: string[] | null;
  status: ProductStatus;
};

const schema = object({
  title: string().required(),
  kind: string().oneOf(['SimpleProduct', 'LocationProduct']),
  description: string().nullable(),
  amount: number().nullable(),
  enterpriseId: string().required(),
  status: string()
    .oneOf([
      ProductStatus.Active,
      ProductStatus.Deleted,
      ProductStatus.Inactive,
    ])
    .required(),
});

const updateSchema = object({
  title: string().required(),
  description: string().nullable(),
  amount: number().nullable(),
  enterpriseId: string(),
  locationIds: array(string()).nullable(),
  status: string()
    .oneOf([
      ProductStatus.Active,
      ProductStatus.Deleted,
      ProductStatus.Inactive,
    ])
    .required(),
});

type ProductEditorParams = {
  id?: string;
};

export const Editor: ComponentType<RouteComponentProps<ProductEditorParams>> = (
  props,
) => {
  const _ = Translator.useTranslator();
  const productQuery = useProductQuery({
    variables: { id: props.id },
    skip: props.id === undefined,
  });

  const [save] = useEditProductMutation();
  const [create] = useCreateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();

  const form = useForm<Values>({
    shouldUnregister: true,
    resolver: yupResolver(props.id ? updateSchema : schema),
    defaultValues: {
      kind: 'SimpleProduct',
      status: ProductStatus.Active,
    },
  });
  const { handleSubmit } = form;

  const attributes = useFieldArray({
    name: 'attributes',
    control: form.control,
  });

  const { availableEnterprises, availableLocations, reload } = useResources();

  const locations = useMemo(() => {
    return availableLocations.filter((location) => location.parentId === null);
  }, [availableLocations]);

  const onSubmit = useMemo(() => {
    return handleSubmit(
      async (input) => {
        try {
          if (props.id) {
            await save({
              variables: {
                id: props.id,
                input,
              },
            });
            reload();
            success('Das Produkt wurde gespeichert.');
          } else {
            const product = await create({
              variables: {
                input: omit(input, ['attributes']),
              },
            });
            reload();
            navigate(`./${product.data?.adminCreateProduct.id}/edit`);
            success('Das Produkt wurde erstellt.');
          }
        } catch (e) {
          error('Bei Speichern des Produktes ist ein Fehler aufgetreten.');
        }
      },
      (e) => {
        error('Ein Fehler ist aufgetreten.');
        //highlightTabsWithFields(Object.keys(e));
        console.error(e);
      },
    );
  }, [props.id, handleSubmit]);

  const [modal, onAskDelete] = useConfirm(
    async () => {
      try {
        if (productQuery.data?.adminProduct.id) {
          await deleteProduct({
            variables: { id: productQuery.data.adminProduct.id },
          });
          success('Das Produkt wurde gelöscht.');
        }
      } catch (e) {
        error('Beim Löschen des Produktes ist ein Fehler aufgetreten.');
      }
    },
    {
      title: `${productQuery.data?.adminProduct.title} löschen`,
      meaning: 'warning',
    },
  );

  const persisted = props.id !== undefined || productQuery.data !== undefined;

  return (
    <>
      {modal}
      <Frame.Title>Produkte</Frame.Title>
      <Frame.SubTitle>
        {persisted ? productQuery.data?.adminProduct.title : 'Neues Produkt'}
        {}
      </Frame.SubTitle>
      <Frame.ActionBar
        left={
          <CopyId
            label="ID"
            id={productQuery.data?.adminProduct.id}
            copyLabel={productQuery.data?.adminProduct.id}
          />
        }
      >
        <Button label="Zurück" linkTo="/products" />
        {persisted && (
          <Button
            error
            label="Löschen"
            onClick={() =>
              onAskDelete('Soll dieses Produkt wirklich gelöscht werden?')
            }
          />
        )}
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={productQuery.data?.adminProduct as any}>
          {!persisted && (
            <Section title="Art des Produktes">
              <p>Bitte bestimme zuerst die Art des Produktes.</p>
              <p>
                Nachdem der Datensatz gespeichert wurde, können jeweilige
                weitere Eigenschaften angegeben werden.
              </p>
              <Form.Select
                name="kind"
                choices={[
                  { label: 'Einfaches Produkt', value: 'SimpleProduct' },
                  { label: 'Standort-Produkt', value: 'LocationProduct' },
                ]}
              />
            </Section>
          )}
          <Grid.Row>
            <Grid.Column>
              <Section title="Produkt-Informationen">
                {productQuery.data?.adminProduct.status ===
                  ProductStatus.Deleted && (
                  <InfoBox error header="Dieses Produkt ist gelöscht." />
                )}
                <Form.Input name="title" label="Titel" />
                <Form.Currency name="amount" label="Preis" />
              </Section>
              {productQuery.data?.adminProduct.__typename ===
                'LocationProduct' && (
                <Section title="Verfügbare für diese Standorte">
                  <Form.Select
                    multiple
                    name="locationIds"
                    choices={locations.map((location) => ({
                      label:
                        location.name ||
                        location.city ||
                        location.address?.city ||
                        'kein Bezeichner',
                      value: location.id,
                    }))}
                  />
                </Section>
              )}
              <Section title="Status und Rechte">
                <Form.Select
                  name="status"
                  label="Status"
                  choices={Object.values(ProductStatus).map((status) => ({
                    label: _(`status.${status}`),
                    value: status,
                  }))}
                />
                {!persisted && (
                  <Form.Select
                    name="enterpriseId"
                    label="Unternehmen"
                    defaultValue={
                      availableEnterprises.length > 0
                        ? availableEnterprises[0].id
                        : undefined
                    }
                    choices={
                      availableEnterprises.map((enterprise) => ({
                        value: enterprise?.id,
                        label: enterprise?.name,
                      })) || []
                    }
                  />
                )}
                {persisted && (
                  <p>
                    Dieses Produkt ist dem Unternehmen{' '}
                    <em>{productQuery.data?.adminProduct.enterprise.name}</em>{' '}
                    zugewiesen.
                  </p>
                )}
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section title="Attribute">
                {attributes.fields.map((field, index) => (
                  <div key={index}>
                    <Controller
                      render={(data) => (
                        <input
                          type="hidden"
                          {...data.field}
                          value={field.name}
                        />
                      )}
                      defaultValue={field.name}
                      name={`attributes.${index}.name`}
                      control={form.control}
                    />
                    <Controller
                      render={(data) => (
                        <>
                          {field.kind === 'TextAttribute' && (
                            <HtmlField
                              {...data.field}
                              value={data.field.value as string}
                              label={_(`attribute.product.${field.name}`)}
                            />
                          )}
                          {field.kind === 'IntAttribute' && (
                            <InputField
                              type="number"
                              {...data.field}
                              value={data.field.value as string}
                              label={_(`attribute.product.${field.name}`)}
                            />
                          )}
                        </>
                      )}
                      defaultValue={
                        field[getAttributeValueKeyByKind(field.kind)]
                      }
                      name={`attributes.${index}.${getAttributeValueKeyByKind(
                        field.kind,
                      )}`}
                    />
                  </div>
                ))}
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
