import { FilterProductsInput, ProductStatus } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Link, RouteComponentProps } from '@reach/router';
import { pick } from 'lodash-es';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { Frame } from '../../frame';
import { ListView } from '../../ui/list-view/list-view.component';
import { Row } from '../../ui/table/row.component';
import { ProductDataFragment, useProductsQuery } from './list.generated';

type FilterType = {
  query?: string;
};

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery<FilterType>();
  const { data, loading } = useProductsQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      filter: pick(query, ['query']),
    },
    fetchPolicy: 'network-only',
  });

  const _ = Translator.useTranslator();

  return (
    <>
      <Frame.Title>Produkte</Frame.Title>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>

      <Frame.ActionBar>
        <Button linkTo="/products/create" label="Neues Produkt" />
      </Frame.ActionBar>

      <Frame.Content>
        <ListView<ProductDataFragment, FilterProductsInput>
          loading={loading}
          data={data?.adminProducts?.data || []}
          totalCount={data?.adminProducts?.totalCount}
          columns={[
            {
              name: 'Titel',
              sortable: true,
            },
            {
              name: 'Preis',
              sortable: true,
            },

            {
              name: 'Unternehmen',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <DebouncedInput
              debounceRate={400}
              value={value?.query || ''}
              onChange={(value) => onChange('query', value)}
              placeholder={'Suche …'}
            />
          )}
          renderRow={(item) => (
            <Row
              key={item.id}
              className={
                item.status === ProductStatus.Inactive ? 'inactive' : undefined
              }
            >
              <td>
                <Link to={`../${item.id}/edit`}>
                  {item.title}
                  {item.status === ProductStatus.Inactive && <> (inaktiv)</>}
                </Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>
                  <Formatted.Currency value={item.amount} />
                </Link>
              </td>

              <td>{item.enterprise.name}</td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
