// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PGENYPce671LXdVcHbuX{font-size:.8em;margin:0;padding:0;list-style:none}.PGENYPce671LXdVcHbuX li{text-align:right;margin:0;padding:0}.PGENYPce671LXdVcHbuX li ul{list-style:none}.PGENYPce671LXdVcHbuX>li{border-bottom:1px solid silver}", "",{"version":3,"sources":["webpack://./src/order/shared/mini-order-items-overview/mini-order-items-overview.module.scss"],"names":[],"mappings":"AACA,sBACE,cAAA,CACA,QAAA,CACA,SAAA,CACA,eAAA,CAEA,yBACE,gBAAA,CACA,QAAA,CACA,SAAA,CACA,4BACE,eAAA,CAGJ,yBACE,8BAAA","sourcesContent":["@import 'styles/variables';\n.host {\n  font-size: 0.8em;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n\n  li {\n    text-align: right;\n    margin: 0;\n    padding: 0;\n    ul {\n      list-style: none;\n    }\n  }\n  > li {\n    border-bottom: 1px solid $color-grey;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "PGENYPce671LXdVcHbuX"
};
export default ___CSS_LOADER_EXPORT___;
