import styles from '@/shared/tree-edit/tree-edit.module.scss';
import { useTreeEdit } from '@/shared/tree-edit/tree-edit.provider';
import { Collapsible } from '@/ui/collapsible/collapsible.component';
import classNames from 'classnames';
import React, { ComponentType, useCallback, useMemo } from 'react';
import {
  CourseNode,
  CourseNodeProps,
} from '../course-node/course-node.component';

export interface CategoryNodeProps {
  id: string;
  title: string;
  parentId?: string;
  depthEven?: boolean;
  enterprise: {
    id: string;
    name: string;
  };
  courses?: CourseNodeProps[] | null;
  children?: CategoryNodeProps[] | null;
}

export const CategoryNode: ComponentType<CategoryNodeProps> = (props) => {
  const { setOpen, isOpen } = useTreeEdit();
  const { title, enterprise, courses, children, depthEven, id } = props;

  const open = useMemo(() => {
    return isOpen(id, 'category');
  }, [isOpen, id]);

  const onOpen = useCallback(
    (isOpen) => {
      setOpen(isOpen, id, 'category');
    },
    [isOpen],
  );

  return (
    <Collapsible
      className={classNames(styles.category, depthEven && styles.even)}
      label={
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>{' '}
          {enterprise?.name && (
            <span className={styles.enterprise}>({enterprise?.name})</span>
          )}
        </div>
      }
      collapsed={!open}
      onChange={onOpen}
    >
      <div className={styles.content}>
        {courses?.map((course) => (
          <CourseNode
            key={course.id}
            {...course}
            enterpriseId={enterprise.id}
          />
        ))}
        {children?.map((category) => (
          <CategoryNode
            depthEven={!depthEven}
            key={category.id}
            {...category}
          />
        ))}
      </div>
    </Collapsible>
  );
};
