import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { CourseUnitDetailDataFragmentDoc } from '../../course-unit/editor/editor.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PackageFragment = {
  __typename?: 'Package';
  id: string;
  title: string;
  subTitle?: string | null;
  abbreviation: string;
  description?: string | null;
  purchasable: boolean;
  status: Types.PackageStatus;
  amount: number;
  taxRate: number;
  tagIds?: Array<any> | null;
  tags?: Array<{
    __typename?: 'Tag';
    id: any;
    name: string;
    slug?: string | null;
    context?: string | null;
  }> | null;
  courseUnits: Array<{
    __typename?: 'CourseUnit';
    id: any;
    status: Types.CourseUnitStatus;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    startDate: any;
    endDate: any;
    dateUncertain: boolean;
    withTime: boolean;
    modulePriceMatrix?: string | null;
    bookingNotes?: string | null;
    properties: any;
    searchableLocationId?: any | null;
    locationId?: any | null;
    online: boolean;
    correspondenceCourse: boolean;
    amount: number;
    discountedAmount?: number | null;
    earlyBirdDate?: any | null;
    earlyBirdNotice?: string | null;
    courseId: any;
    teacherId?: any | null;
    teacherIds?: Array<any> | null;
    tagIds?: Array<any> | null;
    seasonId?: any | null;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    lateBookingOffset?: {
      __typename?: 'Duration';
      value?: number | null;
      factor?: number | null;
    } | null;
    course: {
      __typename?: 'Course';
      id: any;
      title: string;
      abbreviation: string;
      earlyBirdDate?: any | null;
      earlyBirdNotice?: string | null;
      tagIds?: Array<any> | null;
      category: {
        __typename?: 'CourseCategory';
        enterprise: { __typename?: 'Enterprise'; id: any };
      };
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    };
    teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
    teachers?: Array<{
      __typename?: 'Teacher';
      id: any;
      fullName: string;
    }> | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
    modules: Array<{
      __typename?: 'CourseModule';
      id: any;
      title?: string | null;
      startDate: any;
      endDate: any;
      status: Types.CourseModuleStatus;
      dateUncertain: boolean;
      withTime: boolean;
      tagIds?: Array<any> | null;
      online: boolean;
      correspondenceCourse: boolean;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    }>;
    season?: {
      __typename?: 'Season';
      title: string;
      enterpriseId: any;
      enterprise: { __typename?: 'Enterprise'; name: string };
    } | null;
  }>;
};

export type PackagesQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.FilterPackageInput>;
}>;

export type PackagesQuery = {
  __typename?: 'Query';
  adminPackages: {
    __typename?: 'PaginatedPackageResult';
    totalCount: number;
    data: Array<{
      __typename?: 'Package';
      id: string;
      title: string;
      subTitle?: string | null;
      abbreviation: string;
      description?: string | null;
      purchasable: boolean;
      status: Types.PackageStatus;
      amount: number;
      taxRate: number;
      tagIds?: Array<any> | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
      }> | null;
      courseUnits: Array<{
        __typename?: 'CourseUnit';
        id: any;
        status: Types.CourseUnitStatus;
        title?: string | null;
        subTitle?: string | null;
        description?: string | null;
        startDate: any;
        endDate: any;
        dateUncertain: boolean;
        withTime: boolean;
        modulePriceMatrix?: string | null;
        bookingNotes?: string | null;
        properties: any;
        searchableLocationId?: any | null;
        locationId?: any | null;
        online: boolean;
        correspondenceCourse: boolean;
        amount: number;
        discountedAmount?: number | null;
        earlyBirdDate?: any | null;
        earlyBirdNotice?: string | null;
        courseId: any;
        teacherId?: any | null;
        teacherIds?: Array<any> | null;
        tagIds?: Array<any> | null;
        seasonId?: any | null;
        location?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        searchableLocation?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        lateBookingOffset?: {
          __typename?: 'Duration';
          value?: number | null;
          factor?: number | null;
        } | null;
        course: {
          __typename?: 'Course';
          id: any;
          title: string;
          abbreviation: string;
          earlyBirdDate?: any | null;
          earlyBirdNotice?: string | null;
          tagIds?: Array<any> | null;
          category: {
            __typename?: 'CourseCategory';
            enterprise: { __typename?: 'Enterprise'; id: any };
          };
          tags?: Array<{
            __typename?: 'Tag';
            id: any;
            name: string;
            slug?: string | null;
            context?: string | null;
            enterpriseId: any;
            enterprise?: {
              __typename?: 'Enterprise';
              id: any;
              name: string;
            } | null;
          }> | null;
        };
        teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
        teachers?: Array<{
          __typename?: 'Teacher';
          id: any;
          fullName: string;
        }> | null;
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
        modules: Array<{
          __typename?: 'CourseModule';
          id: any;
          title?: string | null;
          startDate: any;
          endDate: any;
          status: Types.CourseModuleStatus;
          dateUncertain: boolean;
          withTime: boolean;
          tagIds?: Array<any> | null;
          online: boolean;
          correspondenceCourse: boolean;
          location?: {
            __typename?: 'Location';
            id: any;
            name: string;
            city?: string | null;
            address?: { __typename?: 'Address'; city?: string | null } | null;
          } | null;
          searchableLocation?: {
            __typename?: 'Location';
            id: any;
            name: string;
            city?: string | null;
            address?: { __typename?: 'Address'; city?: string | null } | null;
          } | null;
          tags?: Array<{
            __typename?: 'Tag';
            id: any;
            name: string;
            slug?: string | null;
            context?: string | null;
            enterpriseId: any;
            enterprise?: {
              __typename?: 'Enterprise';
              id: any;
              name: string;
            } | null;
          }> | null;
        }>;
        season?: {
          __typename?: 'Season';
          title: string;
          enterpriseId: any;
          enterprise: { __typename?: 'Enterprise'; name: string };
        } | null;
      }>;
    }>;
  };
};

export const PackageFragmentDoc = gql`
  fragment Package on Package {
    id
    title
    subTitle
    abbreviation
    description
    purchasable
    status
    amount
    taxRate
    tags {
      id
      name
      slug
      context
    }
    tagIds
    courseUnits {
      ...CourseUnitDetailData
    }
  }
  ${CourseUnitDetailDataFragmentDoc}
`;
export const PackagesDocument = gql`
  query packages($pagination: PaginationInput!, $filter: FilterPackageInput) {
    adminPackages(pagination: $pagination, filter: $filter) {
      data {
        ...Package
      }
      totalCount
    }
  }
  ${PackageFragmentDoc}
`;
export type PackagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PackagesQuery,
    PackagesQueryVariables
  >,
  'query'
> &
  ({ variables: PackagesQueryVariables; skip?: boolean } | { skip: boolean });

export const PackagesComponent = (props: PackagesComponentProps) => (
  <ApolloReactComponents.Query<PackagesQuery, PackagesQueryVariables>
    query={PackagesDocument}
    {...props}
  />
);

/**
 * __usePackagesQuery__
 *
 * To run a query within a React component, call `usePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePackagesQuery(
  baseOptions: Apollo.QueryHookOptions<PackagesQuery, PackagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PackagesQuery, PackagesQueryVariables>(
    PackagesDocument,
    options,
  );
}
export function usePackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PackagesQuery,
    PackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PackagesQuery, PackagesQueryVariables>(
    PackagesDocument,
    options,
  );
}
export type PackagesQueryHookResult = ReturnType<typeof usePackagesQuery>;
export type PackagesLazyQueryHookResult = ReturnType<
  typeof usePackagesLazyQuery
>;
export type PackagesQueryResult = Apollo.QueryResult<
  PackagesQuery,
  PackagesQueryVariables
>;
