// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V6YzbXSE_xx8NOF0CuVg{display:inline;text-align:center;max-width:400px}.V6YzbXSE_xx8NOF0CuVg .rZLtO3fIeEtBduu0g2CE{font-weight:bold}", "",{"version":3,"sources":["webpack://./src/ui/tool-tip/tool-tip.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,4CACE,gBAAA","sourcesContent":[".host {\n  display: inline;\n  text-align: center;\n  max-width: 400px;\n  .title {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "V6YzbXSE_xx8NOF0CuVg",
	"title": "rZLtO3fIeEtBduu0g2CE"
};
export default ___CSS_LOADER_EXPORT___;
