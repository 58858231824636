import { RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Editor as CourseUnitEditor } from './editor/editor.component';
import { Editor as CourseModuleEditor } from './module-editor/editor.component';
import { Root } from './overview/root/root.component';

export const CourseUnit: ComponentType<RouteComponentProps> = (props) => {
  return (
    <>
      <Router className="router">
        <Root default />
        <CourseUnitEditor path=":id/edit" />
        <CourseModuleEditor path=":id/modules/:moduleId/edit" />
      </Router>
    </>
  );
};
