// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WVSrIfKB9OFeeeu65UYQ{border-bottom:2px solid #b3404a;cursor:pointer;transition:opacity .4s}.WVSrIfKB9OFeeeu65UYQ:hover{opacity:.7}", "",{"version":3,"sources":["webpack://./src/ui/help-popup/help-popup.module.scss"],"names":[],"mappings":"AAEA,sBACE,+BAAA,CACA,cAAA,CACA,sBAAA,CAEA,4BACE,UAAA","sourcesContent":["@import 'styles/variables';\n\n.clickableText {\n  border-bottom: 2px solid $color-red;\n  cursor: pointer;\n  transition: opacity 0.4s;\n\n  &:hover {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickableText": "WVSrIfKB9OFeeeu65UYQ"
};
export default ___CSS_LOADER_EXPORT___;
