import classnames from 'classnames';
import React, { ComponentType, useMemo } from 'react';
import { Button } from '../button/button.component';
import { SelectField } from '../select-field/select-field.component';
import styles from './pagination.module.scss';

interface Props {
  take: number;
  skip: number;
  totalCount: number;
  currentPage: number;
  numPages: number;
  setCurrentPage: (page: number) => void;
  setTake: (take: number) => void;
}

export const Pagination: ComponentType<Props> = (props) => {
  const {
    totalCount,
    take,
    skip,
    setTake,
    currentPage,
    numPages,
    setCurrentPage,
  } = props;

  const pages = useMemo(() => {
    let pages: number[] = [];

    const startPage = Math.max(0, currentPage - 2);
    const endPage = Math.min(numPages - 1, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }, [numPages, currentPage]);

  return (
    <div className={styles.host}>
      <div className={styles.navigation}>
        <Button
          small
          transparent
          className={styles.pageButton}
          onClick={() => setCurrentPage(0)}
          label="«"
        />
        <Button
          small
          transparent
          className={styles.pageButton}
          onClick={() => setCurrentPage(currentPage - 1)}
          label="‹"
        />

        {pages.map((p) => (
          <Button
            small
            key={p}
            transparent
            className={classnames(
              styles.pageButton,
              currentPage === p && styles.current,
            )}
            onClick={() => setCurrentPage(p)}
            label={p + 1}
          />
        ))}
        <Button
          small
          transparent
          className={styles.pageButton}
          onClick={() => setCurrentPage(currentPage + 1)}
          label="›"
        />
        <Button
          small
          transparent
          className={styles.pageButton}
          onClick={() => setCurrentPage(numPages - 1)}
          label="»"
        />
      </div>
      <div className={styles.settings}>
        <div className={styles.stats}>{totalCount} Einträge</div>
        <SelectField
          value={take}
          onChange={(v) => setTake(parseInt(v as string))}
          choices={[{ value: '10' }, { value: '50' }, { value: '100' }]}
        />
      </div>
    </div>
  );
};
