import { CourseUnitDetailDataFragment } from '@/course-management/course-unit/editor/editor.generated';
import {
  CourseUnitSearchInput,
  CourseUnitStatus,
} from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Chip } from '@/ui/chip/chip.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { Grid } from '@/ui/grid/grid.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { SelectField2 } from '@/ui/select-field-2/select-field-2.component';
import { Row } from '@/ui/table/row.component';
import { Link } from '@reach/router';
import React, { FC, useMemo, useState } from 'react';
import { getCourseUnitDates } from 'shared/course/utils';
import { useResources } from 'shared/resources/resources.provider';
import globalStyles from 'styles/global.scss';
import { TagsList } from '../tags-list.component';
import { useSelectCourseUnitsQuery } from './course-unit-picker.generated';

export interface CourseUnitPickerProps {
  exclude: CourseUnitDetailDataFragment[];
  onAddClick(item: CourseUnitDetailDataFragment): void;
}

export const CourseUnitPicker: FC<CourseUnitPickerProps> = (props) => {
  const { onAddClick, exclude } = props;
  const [filter, setFilter] = useState<CourseUnitSearchInput>({});
  const { availableSeasons } = useResources();
  const { data, loading } = useSelectCourseUnitsQuery({
    fetchPolicy: 'network-only',
    variables: {
      options: {
        query: filter.query,
        seasonIds: filter.seasonIds,
      },
      pagination: {
        skip: 0,
        take: 10,
      },
    },
  });

  const items = useMemo(
    () =>
      data?.adminCourseUnitsForPackage.data.filter(
        (item) => exclude.some((exclude) => exclude.id === item.id) === false,
      ),
    [data?.adminCourseUnitsForPackage.data, exclude],
  );

  return (
    <Grid.Row>
      <Grid.Column>
        <ListView<CourseUnitDetailDataFragment, CourseUnitSearchInput>
          loading={loading}
          data={items || []}
          filterChange={setFilter}
          filter={filter}
          columns={[
            {
              name: '',
              sortable: false,
            },
            {
              name: 'Zeitraum',
              sortable: true,
            },
            {
              name: 'Titel',
              sortable: true,
            },
            {
              name: 'Tags',
              sortable: true,
            },
            {
              name: 'Standort',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              <DebouncedInput
                label="Suche"
                debounceRate={400}
                value={value?.query || ''}
                onChange={(value) => onChange('query', value)}
                placeholder={'Suche…'}
              />
              <SelectField2
                multiple
                value={value?.seasonIds || []}
                onChange={(e) => onChange('seasonIds', e as string[])}
                choices={availableSeasons.map((l) => ({
                  label: l.title,
                  value: l.id,
                }))}
                label="Saisons"
              />
            </>
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Button small label="+" onClick={() => onAddClick(item)} />
              </td>
              <td>
                <Link to={`../../../units/${item.id}/edit`}>
                  {getCourseUnitDates(item)}
                </Link>
                {item.status === CourseUnitStatus.Inactive && (
                  <>
                    {' '}
                    <span className={globalStyles.error}>[INAKTIV]</span>
                  </>
                )}
              </td>
              <td>
                <Link to={`../../units/${item.id}/edit`}>
                  <Copyable
                    valueToCopy={item.course.id}
                    label="ID kopieren"
                    inline
                  >
                    {item.course.abbreviation} {item.course.title}
                    <br />
                    <span className={globalStyles.light}>
                      {item.season?.title}
                    </span>
                  </Copyable>
                </Link>
              </td>
              <td>
                <TagsList unit={item} />
              </td>
              <td>
                {item.location?.name ||
                  item.location?.city ||
                  item.location?.address?.city}
                {item.online && <Chip>Online</Chip>}
                {item.correspondenceCourse && <Chip>Fernkurs</Chip>}
              </td>
            </Row>
          )}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
