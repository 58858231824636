import { Frame } from '@/frame';
import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { CompanyRoutes } from './company/company.component';
import { DuplicateResolver } from './person/duplicate-resolver/duplicate-resolver.component';
import { BaseEditor } from './person/editor/base/base-editor.component';
import { ListRoutes } from './person/list/routes.component';

export const Contact: ComponentType<RouteComponentProps> = (props) => {
  return (
    <>
      <Frame.Title>Kontakte</Frame.Title>
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            {
              label: 'Personen',
              path: 'persons/list',
            },

            {
              label: 'Kunden',
              path: 'customers/list',
            },
            {
              label: 'Dozenten',
              path: 'teachers/list',
            },
            {
              label: 'Firmen',
              path: 'companies/list',
            },
            {
              label: 'Listen',
              path: 'lists',
              disabled: true,
            },
          ]}
        />
      </Frame.SubMenu>
      <Router>
        <ListRoutes path="*" />
        <CompanyRoutes path="companies/*" />
        <BaseEditor path=":userType/:id/edit" />
        <BaseEditor path=":userType/create" />
        <DuplicateResolver path=":id/duplicates" />
        <Redirect noThrow from="/" to="persons/list" />
        <Redirect noThrow from="persons" to="list" />
        <Redirect noThrow from="customers" to="list" />
        <Redirect noThrow from="teachers" to="list" />
      </Router>
    </>
  );
};
