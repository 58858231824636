import { Frame } from '@/frame';
import { SeasonStatus } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { useSeasonsQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery();
  const { data, loading, error } = useSeasonsQuery({
    fetchPolicy: 'network-only',
    variables: {
      pagination: getPaginationFromQuery(query),
    },
  });

  return (
    <>
      <Frame.SubTitle>Saisons</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="../create" label="Neue Saison" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView
          data={data?.adminSeasons.data || []}
          totalCount={data?.adminSeasons.totalCount}
          columns={[
            { name: 'Titel', sortable: true },
            { name: 'Unternehmen', sortable: true },
          ]}
          renderRow={(item) => (
            <Row
              key={item.id}
              className={classNames(
                item.status === SeasonStatus.Inactive && 'inactive',
              )}
            >
              <td>
                <Link to={`../${item.id}/edit`}>{item.title}</Link>
              </td>
              <td>{item.enterprise.name}</td>
            </Row>
          )}
        ></ListView>
      </Frame.Content>
    </>
  );
};
