import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FindCourseUnitsQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
  startAfter?: Types.InputMaybe<Types.Scalars['String']>;
  endAfter?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FindCourseUnitsQuery = { __typename?: 'Query', adminCourseUnits: Array<{ __typename?: 'CourseUnit', id: any, title?: string | null, amount: number, status: Types.CourseUnitStatus, startDate: any, endDate: any, withTime: boolean, dateUncertain: boolean, online: boolean, correspondenceCourse: boolean, location?: { __typename?: 'Location', id: any, name: string, city?: string | null, commonName: string } | null, course: { __typename?: 'Course', id: any, verboseTitle?: string | null } }>, adminCourseModules: Array<{ __typename?: 'CourseModule', id: any, title?: string | null, status: Types.CourseModuleStatus, amount: number, startDate: any, endDate: any, withTime: boolean, dateUncertain: boolean, online: boolean, correspondenceCourse: boolean, location?: { __typename?: 'Location', id: any, name: string, city?: string | null, commonName: string } | null, courseUnit: { __typename?: 'CourseUnit', id: any, title?: string | null, course: { __typename?: 'Course', id: any, verboseTitle?: string | null } } }> };


export const FindCourseUnitsDocument = gql`
    query findCourseUnits($query: String!, $startAfter: String, $endAfter: String) {
  adminCourseUnits(
    options: {query: $query, startAfter: $startAfter, endAfter: $endAfter}
  ) {
    id
    title
    amount
    status
    startDate
    endDate
    withTime
    dateUncertain
    location {
      id
      name
      city
      commonName
    }
    online
    correspondenceCourse
    course {
      id
      verboseTitle
    }
  }
  adminCourseModules(
    options: {query: $query, startAfter: $startAfter, endAfter: $endAfter}
  ) {
    id
    title
    status
    amount
    startDate
    endDate
    withTime
    dateUncertain
    location {
      id
      name
      city
      commonName
    }
    online
    correspondenceCourse
    courseUnit {
      id
      title
      course {
        id
        verboseTitle
      }
    }
  }
}
    `;
export type FindCourseUnitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FindCourseUnitsQuery, FindCourseUnitsQueryVariables>, 'query'> & ({ variables: FindCourseUnitsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FindCourseUnitsComponent = (props: FindCourseUnitsComponentProps) => (
      <ApolloReactComponents.Query<FindCourseUnitsQuery, FindCourseUnitsQueryVariables> query={FindCourseUnitsDocument} {...props} />
    );
    

/**
 * __useFindCourseUnitsQuery__
 *
 * To run a query within a React component, call `useFindCourseUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCourseUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCourseUnitsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      startAfter: // value for 'startAfter'
 *      endAfter: // value for 'endAfter'
 *   },
 * });
 */
export function useFindCourseUnitsQuery(baseOptions: Apollo.QueryHookOptions<FindCourseUnitsQuery, FindCourseUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCourseUnitsQuery, FindCourseUnitsQueryVariables>(FindCourseUnitsDocument, options);
      }
export function useFindCourseUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCourseUnitsQuery, FindCourseUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCourseUnitsQuery, FindCourseUnitsQueryVariables>(FindCourseUnitsDocument, options);
        }
export type FindCourseUnitsQueryHookResult = ReturnType<typeof useFindCourseUnitsQuery>;
export type FindCourseUnitsLazyQueryHookResult = ReturnType<typeof useFindCourseUnitsLazyQuery>;
export type FindCourseUnitsQueryResult = Apollo.QueryResult<FindCourseUnitsQuery, FindCourseUnitsQueryVariables>;