import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { BookingRoutes } from './booking/booking-routes.component';
import { CoursesRoutes } from './courses/courses-routes.component';

export const CourseHandlingRoutes: ComponentType<RouteComponentProps> = (
  props,
) => {
  return (
    <>
      <Frame.Title>Teilnehmermanagement</Frame.Title>
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            {
              label: 'Buchungen',
              path: 'bookings',
            },

            {
              label: 'Kurse',
              path: 'courses',
            },
          ]}
        />
      </Frame.SubMenu>
      <Router className="router">
        {/* <CourseHandlingStart path="start" /> */}
        <BookingRoutes path="bookings/*" />
        <CoursesRoutes path="courses/*" />
        <Redirect from="/" to="bookings" />
      </Router>
    </>
  );
};
