import { Chart } from '@/statistics-v3/shared/chart/chart.component';
import { useChartOptions } from '@/statistics-v3/shared/chart/options/options.provider';
import { transformRecordsToGraphData } from '@/statistics-v3/statistics';
import React, { ComponentType, useMemo } from 'react';
import { useOrderStatisticsQuery } from '../orders.generated';

interface OrdersChartProps {}

export const OrdersChart: ComponentType<OrdersChartProps> = (props) => {
  const { options, type, context } = useChartOptions();

  const statisticsQuery = useOrderStatisticsQuery({
    fetchPolicy: 'network-only',
    variables: { startDate: options.startDate, endDate: options.endDate },
  });

  const data = useMemo(() => {
    return transformRecordsToGraphData(
      statisticsQuery.data?.statRecords || [],
      type,
      context,
      options,
    );
  }, [statisticsQuery.data, type, context, options]);

  return (
    <>
      <Chart
        chartType={options.chartType}
        data={data}
        loading={statisticsQuery.loading}
        graphs={options.graphs}
        unit={options.property === 'amount' ? '€' : ''}
      />
    </>
  );
};
