import React, { ComponentType } from 'react';
import { TagDataFragment } from '../list/list.generated';
import { TagChip } from './tag-chip.component';

interface TagInspectProps {
  tags?: TagDataFragment[] | null;
}

export const TagInspect: ComponentType<TagInspectProps> = (props) => {
  const { tags } = props;

  if (!tags || tags.length === 0) {
    return null;
  }
  return (
    <div>
      {tags.map((tag) => (
        <TagChip key={tag.id} tag={tag} />
      ))}
    </div>
  );
};
