import { CourseUnit } from '@/graphql/generated/types';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import classNames from 'classnames';
import { formatISO } from 'date-fns';
import React, { ComponentType, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getCourseUnitDates, getCourseUnitLocationLabel } from '../utils';
import {
  FindCourseUnitsQuery,
  useFindCourseUnitsQuery,
} from './course-unit-finder.generated';
import styles from './course-unit-finder.module.scss';

function getCourseUnitLabel(
  unit:
    | FindCourseUnitsQuery['adminCourseUnits'][number]
    | FindCourseUnitsQuery['adminCourseModules'][number],
) {
  return (
    <>
      <div className={styles.itemRow0}>
        {unit.__typename === 'CourseUnit' && <>Kurs-Einheit</>}
        {unit.__typename === 'CourseModule' && <>Kurs-Modul</>}
      </div>
      <div className={styles.itemRow1}>
        {[
          unit.__typename === 'CourseModule'
            ? unit.courseUnit.course.verboseTitle
            : (unit as CourseUnit).course.verboseTitle,
          unit.title ? `(${unit.title})` : undefined,
        ]
          .filter((i) => !!i)
          .join(' ')
          .trim()}
      </div>
      <div className={styles.itemRow2}>
        {[`${getCourseUnitDates(unit)},`, getCourseUnitLocationLabel(unit)]
          .filter((i) => !!i)
          .join(' ')
          .trim()}
      </div>
      <div className={styles.itemRow3}>
        <Formatted.Currency value={unit.amount} />
      </div>
    </>
  );
}

export type CourseUnitOrModuleItemType = {
  value: string;
  label: string;
  type: 'CourseUnit' | 'CourseModule';
  amount: number;
};
interface CourseUnitFinderProps {
  small?: boolean;
  value?: CourseUnitOrModuleItemType | null;
  onChange: (item?: CourseUnitOrModuleItemType | null) => void;
}

export const CourseUnitFinder: ComponentType<CourseUnitFinderProps> = (
  props,
) => {
  const { value, onChange, small } = props;
  const [startAfter, setStartAfter] = useState<Date | null>(null);
  const [endAfter, setEndAfter] = useState<Date | null>(null);
  const { refetch, data } = useFindCourseUnitsQuery({
    variables: { query: '' },
  });

  return (
    <div className={classNames(styles.host, small && styles.small)}>
      <div className={styles.row}>
        <DatetimeField
          noMargin
          small
          label="Start nach"
          value={startAfter}
          onChange={setStartAfter}
        />
        <DatetimeField
          noMargin
          small
          label="Ende nach"
          value={endAfter}
          onChange={setEndAfter}
        />
      </div>
      <label>Suche</label>
      <AsyncSelect<CourseUnitOrModuleItemType>
        defaultOptions={
          data?.adminCourseUnits.map((m) => ({
            label: getCourseUnitLabel(m) as any,
            value: m.id,
            type: m.__typename === 'CourseUnit' ? 'CourseUnit' : 'CourseModule',
            amount: m.amount,
          })) || []
        }
        onChange={onChange}
        value={value}
        loadOptions={async (inputValue: string, callback) => {
          try {
            const data = await refetch({
              query: inputValue,
              startAfter: startAfter ? formatISO(startAfter) : null,
              endAfter: endAfter ? formatISO(endAfter) : null,
            });
            callback(
              [
                ...data.data?.adminCourseModules,
                ...data.data?.adminCourseUnits,
              ].map(
                (unit) =>
                  ({
                    label: getCourseUnitLabel(unit),
                    value: unit.id,
                    type:
                      unit.__typename === 'CourseUnit'
                        ? 'CourseUnit'
                        : 'CourseModule',
                    amount: unit.amount,
                  } as any),
              ),
            );
          } catch (e) {
            console.log(e);
          }
        }}
      />
    </div>
  );
};
