import classNames from 'classnames';
import propertyPath from 'property-path';
import React, { forwardRef, ReactNode, ReactText, Ref, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import globalStyles from 'styles/global.scss';
import styles from './select-field.module.scss';

type ValueType = number | number[] | string | string[] | null;
export interface SelectFieldProps {
  nullLabel?: string;
  small?: boolean;
  value?: ValueType;
  name?: string;
  onChange?: (value: ValueType) => void;
  choices: { value: string | null; label?: ReactText }[];
  error?: string;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  nullable?: boolean;
  readOnly?: boolean;
  multiple?: boolean;
  formState?: FormState<any>;
}

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (props, ref: Ref<HTMLSelectElement>) => {
    const {
      nullLabel = 'Nichts zuweisen',
      small,
      value,
      name,
      nullable,
      onChange,
      choices,
      error,
      label,
      multiple,
      children,
      formState,
      ...rest
    } = props;

    const errorMessage = useMemo(() => {
      if (props.name && formState) {
        return propertyPath.get(formState.errors, props.name)?.message;
      }
      return error;
    }, [error, formState, props.name]);

    return (
      <div
        className={classNames(
          styles.host,
          small && styles.small,
          'select-field',
        )}
      >
        {label && (
          <label>
            {label}
            {props.required && ' *'}
          </label>
        )}
        <select
          ref={ref}
          name={name}
          {...rest}
          value={
            (value === null || value === '' || value === undefined
              ? ''
              : Array.isArray(value)
              ? value
              : [value]) as string
          }
          onChange={
            onChange
              ? (e) => {
                  if (multiple) {
                    onChange(
                      Array.from(e.currentTarget.options)
                        .filter((option) => option.selected === true)
                        .map((option) => option.value),
                    );
                  } else {
                    onChange(
                      e.currentTarget.value === 'NULL'
                        ? null
                        : e.currentTarget.value || null,
                    );
                  }
                }
              : undefined
          }
          multiple={!!multiple}
        >
          {nullable && <option value="NULL">{nullLabel}</option>}
          {choices.map((choice) => (
            <option key={choice.value} value={choice.value || 'NULL'}>
              {choice.label !== undefined ? choice.label : choice.value}
            </option>
          ))}
        </select>
        {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
      </div>
    );
  },
);
