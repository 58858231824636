import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from '@reach/router';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import {
  useCreateEquipmentMutation,
  useEditEquipmentMutation,
  useEquipmentQuery,
} from './editor.generated';

type Values = {
  name: string;
  description: string;
};

const schema = object({
  name: string().required(),
  description: string().required(),
});

export const Editor = (props) => {
  const [save] = useEditEquipmentMutation();
  const [create] = useCreateEquipmentMutation();
  const eqQuery = useEquipmentQuery({ variables: { id: props.id } });

  const form = useForm<Values>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (input) => {
      if (props.id) {
        try {
          await save({ variables: { id: props.id, input } });
          eqQuery.refetch();
          success('Doe Ausstattung wurde gespeichert.');
        } catch (e) {
          error('Beim Speichern der Ausstattung ist ein Fehler aufgetreten.');
        }
      } else {
        try {
          const equipment = await create({ variables: { input } });
          navigate(`./${equipment.data?.adminCreateEquipment.id}/edit`);
          success('Die Ausstattung wurde erstellt.');
        } catch (e) {
          error('Beim Speichern der Ausstattung ist ein Fehler aufgetreten.');
        }
      }
    },
    [props.id],
  );

  const onSubmitError = useCallback((errors) => {
    error('Bitte überprüfe deine Eingabe.');
  }, []);

  const persisted = props.id !== undefined || eqQuery.data !== undefined;

  return (
    <>
      <Frame.SubTitle>
        {persisted ? eqQuery.data?.adminEquipment.name : 'Neue Ausstattung'}
      </Frame.SubTitle>
      <Frame.ActionBar>
        <Button label="Zurück" linkTo="/locations/equipment" />
        <Button
          primary
          label="Speichern"
          onClick={form.handleSubmit(onSubmit, onSubmitError)}
        />
      </Frame.ActionBar>
      <Frame.Content>
        <Grid.Row>
          <Grid.Column>
            <Section>
              <Form form={form} values={eqQuery.data?.adminEquipment}>
                <Form.Input name="name" label="Name" />
                <Form.Input name="description" label="Beschreibung" />
              </Form>
            </Section>
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Frame.Content>
    </>
  );
};
