import { isSameDay, parseISO } from 'date-fns';
import React, { ComponentType, useEffect, useMemo, useRef } from 'react';
import {
  useRoomManagement,
  useRoomManagementUi,
} from '../../room-management-context';
import {
  EventManagerRoomsQuery,
  RoomEventDataFragment,
} from '../../room-management.generated';
import { EventBlock } from '../event-block/event-block.component';
import styles from '../room-event-manager.module.scss';
import { Ruler } from '../ruler/ruler.component';

interface EventsProps {
  room: EventManagerRoomsQuery['adminRooms']['data'][number];
  id: string;
  onDoubleClick: (event: RoomEventDataFragment) => void;
}

export const Events: ComponentType<EventsProps> = (props) => {
  const { room, id, onDoubleClick } = props;
  const { rowWidth } = useRoomManagementUi();
  const { localEvents, registerRoomDiv, currentDate } = useRoomManagement();
  const ref = useRef<HTMLDivElement>(null);

  const thisEvents = useMemo(() => {
    return localEvents.filter(
      (e) =>
        e.roomId === room.id && isSameDay(parseISO(e.startDate), currentDate),
    );
  }, [room, localEvents, currentDate]);

  useEffect(() => {
    registerRoomDiv(room.id, ref);
  }, []);

  return (
    <div
      className={styles.events}
      style={{ width: `${rowWidth}px` }}
      ref={ref}
      id={id}
    >
      <Ruler />
      {thisEvents.map((event, idx) => (
        <EventBlock event={event} onDoubleClick={() => onDoubleClick(event)} />
      ))}
    </div>
  );
};
