import * as Types from '../../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { CourseUnitDetailDataFragmentDoc } from '../../../../course-unit/editor/editor.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SelectCourseUnitsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  options?: Types.InputMaybe<Types.CourseUnitSearchInput>;
}>;

export type SelectCourseUnitsQuery = {
  __typename?: 'Query';
  adminCourseUnitsForPackage: {
    __typename?: 'PaginatedCourseUnitResult';
    totalCount: number;
    data: Array<{
      __typename?: 'CourseUnit';
      id: any;
      status: Types.CourseUnitStatus;
      title?: string | null;
      subTitle?: string | null;
      description?: string | null;
      startDate: any;
      endDate: any;
      dateUncertain: boolean;
      withTime: boolean;
      modulePriceMatrix?: string | null;
      bookingNotes?: string | null;
      properties: any;
      searchableLocationId?: any | null;
      locationId?: any | null;
      online: boolean;
      correspondenceCourse: boolean;
      amount: number;
      discountedAmount?: number | null;
      earlyBirdDate?: any | null;
      earlyBirdNotice?: string | null;
      courseId: any;
      teacherId?: any | null;
      teacherIds?: Array<any> | null;
      tagIds?: Array<any> | null;
      seasonId?: any | null;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
      lateBookingOffset?: {
        __typename?: 'Duration';
        value?: number | null;
        factor?: number | null;
      } | null;
      course: {
        __typename?: 'Course';
        id: any;
        title: string;
        abbreviation: string;
        earlyBirdDate?: any | null;
        earlyBirdNotice?: string | null;
        tagIds?: Array<any> | null;
        category: {
          __typename?: 'CourseCategory';
          enterprise: { __typename?: 'Enterprise'; id: any };
        };
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      };
      teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
      teachers?: Array<{
        __typename?: 'Teacher';
        id: any;
        fullName: string;
      }> | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
      modules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        status: Types.CourseModuleStatus;
        dateUncertain: boolean;
        withTime: boolean;
        tagIds?: Array<any> | null;
        online: boolean;
        correspondenceCourse: boolean;
        location?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        searchableLocation?: {
          __typename?: 'Location';
          id: any;
          name: string;
          city?: string | null;
          address?: { __typename?: 'Address'; city?: string | null } | null;
        } | null;
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      }>;
      season?: {
        __typename?: 'Season';
        title: string;
        enterpriseId: any;
        enterprise: { __typename?: 'Enterprise'; name: string };
      } | null;
    }>;
  };
};

export const SelectCourseUnitsDocument = gql`
  query selectCourseUnits(
    $pagination: PaginationInput!
    $options: CourseUnitSearchInput
  ) {
    adminCourseUnitsForPackage(pagination: $pagination, options: $options) {
      data {
        ...CourseUnitDetailData
      }
      totalCount
    }
  }
  ${CourseUnitDetailDataFragmentDoc}
`;
export type SelectCourseUnitsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SelectCourseUnitsQuery,
    SelectCourseUnitsQueryVariables
  >,
  'query'
> &
  (
    | { variables: SelectCourseUnitsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SelectCourseUnitsComponent = (
  props: SelectCourseUnitsComponentProps,
) => (
  <ApolloReactComponents.Query<
    SelectCourseUnitsQuery,
    SelectCourseUnitsQueryVariables
  >
    query={SelectCourseUnitsDocument}
    {...props}
  />
);

/**
 * __useSelectCourseUnitsQuery__
 *
 * To run a query within a React component, call `useSelectCourseUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectCourseUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectCourseUnitsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSelectCourseUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SelectCourseUnitsQuery,
    SelectCourseUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SelectCourseUnitsQuery,
    SelectCourseUnitsQueryVariables
  >(SelectCourseUnitsDocument, options);
}
export function useSelectCourseUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelectCourseUnitsQuery,
    SelectCourseUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SelectCourseUnitsQuery,
    SelectCourseUnitsQueryVariables
  >(SelectCourseUnitsDocument, options);
}
export type SelectCourseUnitsQueryHookResult = ReturnType<
  typeof useSelectCourseUnitsQuery
>;
export type SelectCourseUnitsLazyQueryHookResult = ReturnType<
  typeof useSelectCourseUnitsLazyQuery
>;
export type SelectCourseUnitsQueryResult = Apollo.QueryResult<
  SelectCourseUnitsQuery,
  SelectCourseUnitsQueryVariables
>;
