import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { PersonSimpleDetailDataFragmentDoc } from '../../../contact/person/editor/editor.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ConsultingEventEditorDataFragment = { __typename?: 'ConsultingEvent', id: any, startDate: any, endDate: any, description?: string | null, availableSubjects: Array<string>, selectedSubjects: Array<string>, availableCourseIds: Array<any>, selectedCourseIds: Array<any>, availableLocationIds: Array<any>, selectedLocationIds: Array<any>, enterpriseId: any, consultantId?: any | null, attendeeComment?: string | null, enterprise: { __typename?: 'Enterprise', id: any, name: string }, consultant?: { __typename?: 'Administrator', id: any, person: { __typename?: 'Person', firstName?: string | null, lastName?: string | null } } | null, attendee?: { __typename?: 'Person', id: any, createdAt: any, title?: string | null, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, inquiries: Array<{ __typename?: 'Inquiry', id: any, createdAt: any, status: Types.InquiryStatus, assignee?: { __typename?: 'Administrator', id: any, fullName: string } | null }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null } } | null };

export type ConsultingEventQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type ConsultingEventQuery = { __typename?: 'Query', adminConsultingEvent: { __typename?: 'ConsultingEvent', id: any, startDate: any, endDate: any, description?: string | null, availableSubjects: Array<string>, selectedSubjects: Array<string>, availableCourseIds: Array<any>, selectedCourseIds: Array<any>, availableLocationIds: Array<any>, selectedLocationIds: Array<any>, enterpriseId: any, consultantId?: any | null, attendeeComment?: string | null, enterprise: { __typename?: 'Enterprise', id: any, name: string }, consultant?: { __typename?: 'Administrator', id: any, person: { __typename?: 'Person', firstName?: string | null, lastName?: string | null } } | null, attendee?: { __typename?: 'Person', id: any, createdAt: any, title?: string | null, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, inquiries: Array<{ __typename?: 'Inquiry', id: any, createdAt: any, status: Types.InquiryStatus, assignee?: { __typename?: 'Administrator', id: any, fullName: string } | null }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null } } | null } };

export type UpdateConsultingEventMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.UpdateConsultingEventInput;
}>;


export type UpdateConsultingEventMutation = { __typename?: 'Mutation', adminUpdateConsultingEvent: { __typename?: 'ConsultingEvent', id: any, startDate: any, endDate: any, description?: string | null, availableSubjects: Array<string>, selectedSubjects: Array<string>, availableCourseIds: Array<any>, selectedCourseIds: Array<any>, availableLocationIds: Array<any>, selectedLocationIds: Array<any>, enterpriseId: any, consultantId?: any | null, attendeeComment?: string | null, enterprise: { __typename?: 'Enterprise', id: any, name: string }, consultant?: { __typename?: 'Administrator', id: any, person: { __typename?: 'Person', firstName?: string | null, lastName?: string | null } } | null, attendee?: { __typename?: 'Person', id: any, createdAt: any, title?: string | null, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, inquiries: Array<{ __typename?: 'Inquiry', id: any, createdAt: any, status: Types.InquiryStatus, assignee?: { __typename?: 'Administrator', id: any, fullName: string } | null }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null } } | null } };

export const ConsultingEventEditorDataFragmentDoc = gql`
    fragment ConsultingEventEditorData on ConsultingEvent {
  id
  startDate
  endDate
  description
  availableSubjects
  selectedSubjects
  availableCourseIds
  selectedCourseIds
  availableLocationIds
  selectedLocationIds
  enterprise {
    id
    name
  }
  enterpriseId
  consultant {
    id
    person {
      firstName
      lastName
    }
  }
  consultantId
  attendee {
    ...PersonSimpleDetailData
  }
  attendeeComment
}
    ${PersonSimpleDetailDataFragmentDoc}`;
export const ConsultingEventDocument = gql`
    query consultingEvent($id: UUID!) {
  adminConsultingEvent(id: $id) {
    ...ConsultingEventEditorData
  }
}
    ${ConsultingEventEditorDataFragmentDoc}`;
export type ConsultingEventComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConsultingEventQuery, ConsultingEventQueryVariables>, 'query'> & ({ variables: ConsultingEventQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConsultingEventComponent = (props: ConsultingEventComponentProps) => (
      <ApolloReactComponents.Query<ConsultingEventQuery, ConsultingEventQueryVariables> query={ConsultingEventDocument} {...props} />
    );
    

/**
 * __useConsultingEventQuery__
 *
 * To run a query within a React component, call `useConsultingEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultingEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultingEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsultingEventQuery(baseOptions: Apollo.QueryHookOptions<ConsultingEventQuery, ConsultingEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultingEventQuery, ConsultingEventQueryVariables>(ConsultingEventDocument, options);
      }
export function useConsultingEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultingEventQuery, ConsultingEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultingEventQuery, ConsultingEventQueryVariables>(ConsultingEventDocument, options);
        }
export type ConsultingEventQueryHookResult = ReturnType<typeof useConsultingEventQuery>;
export type ConsultingEventLazyQueryHookResult = ReturnType<typeof useConsultingEventLazyQuery>;
export type ConsultingEventQueryResult = Apollo.QueryResult<ConsultingEventQuery, ConsultingEventQueryVariables>;
export const UpdateConsultingEventDocument = gql`
    mutation updateConsultingEvent($id: UUID!, $input: UpdateConsultingEventInput!) {
  adminUpdateConsultingEvent(id: $id, input: $input) {
    ...ConsultingEventEditorData
  }
}
    ${ConsultingEventEditorDataFragmentDoc}`;
export type UpdateConsultingEventMutationFn = Apollo.MutationFunction<UpdateConsultingEventMutation, UpdateConsultingEventMutationVariables>;
export type UpdateConsultingEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateConsultingEventMutation, UpdateConsultingEventMutationVariables>, 'mutation'>;

    export const UpdateConsultingEventComponent = (props: UpdateConsultingEventComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateConsultingEventMutation, UpdateConsultingEventMutationVariables> mutation={UpdateConsultingEventDocument} {...props} />
    );
    

/**
 * __useUpdateConsultingEventMutation__
 *
 * To run a mutation, you first call `useUpdateConsultingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultingEventMutation, { data, loading, error }] = useUpdateConsultingEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsultingEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsultingEventMutation, UpdateConsultingEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsultingEventMutation, UpdateConsultingEventMutationVariables>(UpdateConsultingEventDocument, options);
      }
export type UpdateConsultingEventMutationHookResult = ReturnType<typeof useUpdateConsultingEventMutation>;
export type UpdateConsultingEventMutationResult = Apollo.MutationResult<UpdateConsultingEventMutation>;
export type UpdateConsultingEventMutationOptions = Apollo.BaseMutationOptions<UpdateConsultingEventMutation, UpdateConsultingEventMutationVariables>;