import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';

export const Title: ComponentType = (props) => {
  const domNode = document.getElementById('title-slot');
  if (!domNode) {
    return null;
  }
  return ReactDOM.createPortal(
    <>
      <h2>{props.children || <>&nbsp;</>}</h2>
    </>,
    domNode,
  );
};

export const SubTitle: ComponentType = (props) => {
  const domNode = document.getElementById('sub-title-slot');
  if (!domNode) {
    return null;
  }
  return ReactDOM.createPortal(
    <>
      <h3>{props.children || <>&nbsp;</>}</h3>
    </>,
    domNode,
  );
};
