// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z_imyAty3ruognrS_b_7{position:fixed;z-index:99;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,.6);display:flex;align-items:center;justify-content:center}.Z_imyAty3ruognrS_b_7 .gro2F3N7tEZVCWFVIUpn{width:40vw}.Z_imyAty3ruognrS_b_7 .gro2F3N7tEZVCWFVIUpn .TnP6D31i6g4GsroXe34E{margin:16px;display:flex;justify-content:flex-end}.Z_imyAty3ruognrS_b_7 .gro2F3N7tEZVCWFVIUpn .TnP6D31i6g4GsroXe34E .button{margin:0 8px}.Z_imyAty3ruognrS_b_7 .gro2F3N7tEZVCWFVIUpn .TnP6D31i6g4GsroXe34E .button:first-child{margin-left:0}.Z_imyAty3ruognrS_b_7 .gro2F3N7tEZVCWFVIUpn .TnP6D31i6g4GsroXe34E .button:last-child{margin-right:0}", "",{"version":3,"sources":["webpack://./src/ui/modal/modal.module.scss"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,UAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,yBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,4CACE,UAAA,CACA,kEACE,WAAA,CACA,YAAA,CACA,wBAAA,CAEA,0EACE,YAAA,CAEA,sFACE,aAAA,CAGF,qFACE,cAAA","sourcesContent":["@import 'styles/variables';\n\n.dimmer {\n  position: fixed;\n  z-index: 99;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.6);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .host {\n    width: 40vw;\n    .actions {\n      margin: $default-margin * 2;\n      display: flex;\n      justify-content: flex-end;\n\n      :global(.button) {\n        margin: 0 $default-margin;\n\n        &:first-child {\n          margin-left: 0;\n        }\n\n        &:last-child {\n          margin-right: 0;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dimmer": "Z_imyAty3ruognrS_b_7",
	"host": "gro2F3N7tEZVCWFVIUpn",
	"actions": "TnP6D31i6g4GsroXe34E"
};
export default ___CSS_LOADER_EXPORT___;
