import { CourseUnitDetailDataFragment } from '@/course-management/course-unit/editor/editor.generated';
import { Chip } from '@/ui/chip/chip.component';
import React, { ComponentType } from 'react';

interface TagsListProps {
  unit: CourseUnitDetailDataFragment;
}

export const TagsList: ComponentType<TagsListProps> = (props) => {
  const { unit } = props;
  return (
    <>
      {unit.tags && unit.tags.length > 0 && (
        <>
          <h5>Kurseinheit</h5>
          {unit.tags.map((tag) => (
            <Chip key={tag.id}>{tag.name}</Chip>
          ))}
        </>
      )}
      {unit.course?.tags && unit.course.tags.length > 0 && (
        <>
          <h5>Kurs</h5>
          {unit.course.tags.map((tag) => (
            <Chip key={tag.id}>{tag.name}</Chip>
          ))}
        </>
      )}
    </>
  );
};
