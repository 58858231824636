import chevronDownIcon from 'assets/image/icons/chevron-down.svg';
import classnames from 'classnames';
import React, { ComponentType, ReactNode, useCallback, useState } from 'react';
import { Icon } from '../icon/icon.component';
import styles from './collapsible.module.scss';

interface Props {
  className?: string;
  label: string | ReactNode;
  collapsed?: boolean;
  onChange?: (collapsed: boolean) => void;
  children?: ReactNode;
}

export const Collapsible: ComponentType<Props> = (props) => {
  const { className, label, collapsed, onChange, children } = props;

  const [open, setOpen] = useState(!collapsed);

  const onClick = useCallback(() => {
    setOpen(!open);
    if (onChange) {
      onChange(!open);
    }
  }, [setOpen, open, onChange]);

  return (
    <div
      className={classnames(
        className,
        styles.host,
        open ? styles.open : styles.collapsed,
      )}
    >
      <div className={styles.indicator}>
        <Icon className={styles.icon} icon={chevronDownIcon} inline />
      </div>
      <div className={styles.label} onClick={onClick}>
        {label}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
