import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { DiscountRuleDataFragmentDoc } from '../discount.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DiscountRulesQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
}>;

export type DiscountRulesQuery = {
  __typename?: 'Query';
  discountRules: {
    __typename?: 'PaginatedDiscountRuleResult';
    totalCount: number;
    data: Array<{
      __typename?: 'DiscountRule';
      id: any;
      title?: string | null;
      description?: string | null;
      status: Types.DiscountRuleStatus;
      amount: number;
      type: Types.DiscountType;
      voucherCode?: string | null;
      useCheckbox: boolean;
      applyToWholeCart: boolean;
      dependencyRelation: Types.DiscountRuleDependencyRelation;
      enterpriseId: any;
      dependencies: {
        __typename?: 'DiscountRuleDependencies';
        courseIds?: Array<string> | null;
        courseUnitIds?: Array<string> | null;
        courseModuleIds?: Array<string> | null;
        productIds?: Array<string> | null;
        categoryIds?: Array<string> | null;
        products: Array<
          | { __typename?: 'LocationProduct'; id: any; title: string }
          | { __typename?: 'SimpleProduct'; id: any; title: string }
        >;
        courses: Array<{
          __typename?: 'Course';
          id: any;
          title: string;
          verboseTitle?: string | null;
        }>;
        courseUnits: Array<{
          __typename?: 'CourseUnit';
          id: any;
          title?: string | null;
          startDate: any;
          endDate: any;
          withTime: boolean;
          dateUncertain: boolean;
          correspondenceCourse: boolean;
          online: boolean;
          location?: { __typename?: 'Location'; commonName: string } | null;
          searchableLocation?: {
            __typename?: 'Location';
            commonName: string;
          } | null;
          course: { __typename?: 'Course'; id: any; title: string };
        }>;
        courseModules: Array<{
          __typename?: 'CourseModule';
          id: any;
          title?: string | null;
          startDate: any;
          endDate: any;
          withTime: boolean;
          dateUncertain: boolean;
          correspondenceCourse: boolean;
          online: boolean;
          location?: { __typename?: 'Location'; commonName: string } | null;
          searchableLocation?: {
            __typename?: 'Location';
            commonName: string;
          } | null;
          courseUnit: {
            __typename?: 'CourseUnit';
            id: any;
            course: { __typename?: 'Course'; id: any; title: string };
          };
        }>;
      };
      excludedItems: {
        __typename?: 'DiscountRuleDependencies';
        courseIds?: Array<string> | null;
        courseUnitIds?: Array<string> | null;
        courseModuleIds?: Array<string> | null;
        productIds?: Array<string> | null;
        categoryIds?: Array<string> | null;
        products: Array<
          | { __typename?: 'LocationProduct'; id: any; title: string }
          | { __typename?: 'SimpleProduct'; id: any; title: string }
        >;
        courses: Array<{
          __typename?: 'Course';
          id: any;
          title: string;
          verboseTitle?: string | null;
        }>;
        courseUnits: Array<{
          __typename?: 'CourseUnit';
          id: any;
          title?: string | null;
          startDate: any;
          endDate: any;
          withTime: boolean;
          dateUncertain: boolean;
          correspondenceCourse: boolean;
          online: boolean;
          location?: { __typename?: 'Location'; commonName: string } | null;
          searchableLocation?: {
            __typename?: 'Location';
            commonName: string;
          } | null;
          course: { __typename?: 'Course'; id: any; title: string };
        }>;
        courseModules: Array<{
          __typename?: 'CourseModule';
          id: any;
          title?: string | null;
          startDate: any;
          endDate: any;
          withTime: boolean;
          dateUncertain: boolean;
          correspondenceCourse: boolean;
          online: boolean;
          location?: { __typename?: 'Location'; commonName: string } | null;
          searchableLocation?: {
            __typename?: 'Location';
            commonName: string;
          } | null;
          courseUnit: {
            __typename?: 'CourseUnit';
            id: any;
            course: { __typename?: 'Course'; id: any; title: string };
          };
        }>;
      };
      enterprise: { __typename?: 'Enterprise'; id: any; name: string };
    }>;
  };
};

export const DiscountRulesDocument = gql`
  query discountRules($pagination: PaginationInput!) {
    discountRules(pagination: $pagination) {
      totalCount
      data {
        ...DiscountRuleData
      }
    }
  }
  ${DiscountRuleDataFragmentDoc}
`;
export type DiscountRulesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DiscountRulesQuery,
    DiscountRulesQueryVariables
  >,
  'query'
> &
  (
    | { variables: DiscountRulesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const DiscountRulesComponent = (props: DiscountRulesComponentProps) => (
  <ApolloReactComponents.Query<DiscountRulesQuery, DiscountRulesQueryVariables>
    query={DiscountRulesDocument}
    {...props}
  />
);

/**
 * __useDiscountRulesQuery__
 *
 * To run a query within a React component, call `useDiscountRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountRulesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDiscountRulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscountRulesQuery,
    DiscountRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscountRulesQuery, DiscountRulesQueryVariables>(
    DiscountRulesDocument,
    options,
  );
}
export function useDiscountRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscountRulesQuery,
    DiscountRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscountRulesQuery, DiscountRulesQueryVariables>(
    DiscountRulesDocument,
    options,
  );
}
export type DiscountRulesQueryHookResult = ReturnType<
  typeof useDiscountRulesQuery
>;
export type DiscountRulesLazyQueryHookResult = ReturnType<
  typeof useDiscountRulesLazyQuery
>;
export type DiscountRulesQueryResult = Apollo.QueryResult<
  DiscountRulesQuery,
  DiscountRulesQueryVariables
>;
