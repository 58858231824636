import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ListRoomDataFragment = { __typename?: 'Room', id: any, name: string, maxPeople?: number | null, pricePerHour?: number | null, location: { __typename?: 'Location', name: string, address?: { __typename?: 'Address', streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null } | null, enterprises: Array<{ __typename?: 'Enterprise', name: string }> } };

export type RoomsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RoomsQuery = { __typename?: 'Query', adminRooms: { __typename?: 'PaginatedRoomResult', totalCount: number, data: Array<{ __typename?: 'Room', id: any, name: string, maxPeople?: number | null, pricePerHour?: number | null, location: { __typename?: 'Location', name: string, address?: { __typename?: 'Address', streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null } | null, enterprises: Array<{ __typename?: 'Enterprise', name: string }> } }> } };

export const ListRoomDataFragmentDoc = gql`
    fragment ListRoomData on Room {
  id
  name
  location {
    name
    address {
      streetAndNumber
      postalCode
      city
      country
    }
    enterprises {
      name
    }
  }
  maxPeople
  pricePerHour
}
    `;
export const RoomsDocument = gql`
    query rooms($pagination: PaginationInput!, $query: String) {
  adminRooms(pagination: $pagination, query: $query) {
    totalCount
    data {
      ...ListRoomData
    }
  }
}
    ${ListRoomDataFragmentDoc}`;
export type RoomsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RoomsQuery, RoomsQueryVariables>, 'query'> & ({ variables: RoomsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RoomsComponent = (props: RoomsComponentProps) => (
      <ApolloReactComponents.Query<RoomsQuery, RoomsQueryVariables> query={RoomsDocument} {...props} />
    );
    

/**
 * __useRoomsQuery__
 *
 * To run a query within a React component, call `useRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRoomsQuery(baseOptions: Apollo.QueryHookOptions<RoomsQuery, RoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoomsQuery, RoomsQueryVariables>(RoomsDocument, options);
      }
export function useRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomsQuery, RoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoomsQuery, RoomsQueryVariables>(RoomsDocument, options);
        }
export type RoomsQueryHookResult = ReturnType<typeof useRoomsQuery>;
export type RoomsLazyQueryHookResult = ReturnType<typeof useRoomsLazyQuery>;
export type RoomsQueryResult = Apollo.QueryResult<RoomsQuery, RoomsQueryVariables>;