import { getPersonDescription } from '@/contact/person/utils';
import { useEventList } from '@/event/context/event.context';
import { Profile } from '@/profile/profile';
import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import { Grid } from '@/ui/grid/grid.component';
import { InputField } from '@/ui/input-field/input-field.component';
import { SelectField2 } from '@/ui/select-field-2/select-field-2.component';
import { SelectField } from '@/ui/select-field/select-field.component';
import { Textarea } from '@/ui/textarea/textarea.component';
import { TimeField } from '@/ui/time-field/time-field.component';
import { error, success } from '@/ui/toaster/toast';
import { startOfWeek } from 'date-fns';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useResources } from 'shared/resources/resources.provider';
import globalStyles from 'styles/global.scss';
import {
  useAvailableCoursesQuery,
  useCreateConsultingEventsMutation,
} from './creator.generated';
import styles from './creator.module.scss';
import { generateEvents } from './generator';

interface CreatorProps {
  onCreate: () => void;
}

export const Creator: ComponentType<CreatorProps> = (props) => {
  const { onCreate } = props;

  const [numWeeks, setNumWeeks] = useState(1);
  const [startTime, setStartTime] = useState('08:00');
  const [endTime, setEndTime] = useState('08:45');
  const [startDay, setStartDay] = useState<Date>(
    startOfWeek(new Date(), { weekStartsOn: 1 }),
  );
  const [weekDays, setWeekDays] = useState<(0 | 1 | 2 | 3 | 4 | 5 | 6)[]>([]);
  const [selectedConsultantId, setSelectedConsultantId] = useState<string>();
  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState<string>();

  const { setEvents, events } = useEventList();

  const profile = Profile.use();

  const { availableConsultants, availableLocations, availableEnterprises } =
    useResources();

  const availableMotherLocations = useMemo(() => {
    return availableLocations.filter((l) => l.parentId === null);
  }, [availableLocations]);

  const availableCoursesQuery = useAvailableCoursesQuery({
    skip: !profile?.selectedEnterprise,
    variables: { enterpriseId: profile?.selectedEnterprise?.id },
  });

  const [create] = useCreateConsultingEventsMutation();

  const onChangeStartDay = useCallback((value) => {
    setStartDay(startOfWeek(value ? value : new Date(), { weekStartsOn: 1 }));
  }, []);

  const applyPreview = useCallback(() => {
    const newEvents = generateEvents({
      numWeeks,
      startTime,
      endTime,
      startDay,
      weekDays,
      isPreview: true,
    });
    if (newEvents.length) {
      setEvents([...events.filter((e) => !e.isPreview), ...newEvents]);
    }
  }, [numWeeks, startTime, endTime, startDay, weekDays, events]);

  const createEvents = useCallback(async () => {
    try {
      const eventsToCreate = generateEvents({
        numWeeks,
        startTime,
        endTime,
        startDay,
        weekDays,
        isPreview: false,
        availableCourseIds: selectedCourseIds,
        availableLocationIds: selectedLocationIds,
        availableSubjects: selectedSubjects,
        consultantId: selectedConsultantId,
      }).map((e) => ({ ...e, enterpriseId: selectedEnterpriseId }));

      if (eventsToCreate.length > 0) {
        await create({
          variables: {
            input: eventsToCreate,
          },
        });
        success(`Es wurde(n) ${eventsToCreate.length} Termin(e) erstellt.`);
        onCreate();
      }
    } catch (e) {
      error('Beim Erstellen der Termine ist ein Fehler aufgetreten.');
    }
  }, [
    profile,
    numWeeks,
    startTime,
    endTime,
    startDay,
    weekDays,
    events,
    selectedSubjects,
    selectedConsultantId,
    selectedLocationIds,
    selectedCourseIds,
    selectedEnterpriseId,
    onCreate,
  ]);

  useEffect(() => {
    if (profile && profile.selectedEnterprise) {
      setSelectedEnterpriseId(profile.selectedEnterprise.id);
    }
  }, [profile]);
  return (
    <Box dark title="Zeitfenster anlegen" className={styles.host}>
      <Grid.Row>
        <Grid.Column>
          <TimeField
            white
            label="Start"
            value={startTime}
            onChange={setStartTime}
          />
          <TimeField white label="Ende" value={endTime} onChange={setEndTime} />
        </Grid.Column>
        <Grid.Column>
          <DatetimeField
            white
            value={startDay}
            onChange={onChangeStartDay}
            label="Start-Datum (Anfang d. Woche)"
          />
          <InputField
            white
            label="Anzahl Wochen"
            type="number"
            value={numWeeks?.toString()}
            onChange={(value) => setNumWeeks(value && value > 0 ? value : 1)}
          />
        </Grid.Column>
      </Grid.Row>
      <label>Wochentage</label>
      <div className={styles.dayCheckboxes}>
        <Checkbox
          checked={weekDays.includes(0)}
          onChange={(c: boolean) =>
            setWeekDays((d) =>
              c ? [...d.filter((d) => d !== 0), 0] : d.filter((d) => d !== 0),
            )
          }
          // label={`Mo, ${format(startDay, 'dd.MM.')}`}
          label="Montag"
        />
        <Checkbox
          checked={weekDays.includes(1)}
          onChange={(c: boolean) =>
            setWeekDays((d) =>
              c ? [...d.filter((d) => d !== 1), 1] : d.filter((d) => d !== 1),
            )
          }
          // label={`Di, ${format(addDays(startDay, 1), 'dd.MM.')}`}
          label="Dienstag"
        />
        <Checkbox
          checked={weekDays.includes(2)}
          onChange={(c: boolean) =>
            setWeekDays((d) =>
              c ? [...d.filter((d) => d !== 2), 2] : d.filter((d) => d !== 2),
            )
          }
          // label={`Mi, ${format(addDays(startDay, 2), 'dd.MM.')}`}
          label="Mittwoch"
        />
        <Checkbox
          checked={weekDays.includes(3)}
          onChange={(c: boolean) =>
            setWeekDays((d) =>
              c ? [...d.filter((d) => d !== 3), 3] : d.filter((d) => d !== 3),
            )
          }
          // label={`Do, ${format(addDays(startDay, 3), 'dd.MM.')}`}
          label="Donnerstag"
        />
        <Checkbox
          checked={weekDays.includes(4)}
          onChange={(c: boolean) =>
            setWeekDays((d) =>
              c ? [...d.filter((d) => d !== 4), 4] : d.filter((d) => d !== 4),
            )
          }
          // label={`Fr, ${format(addDays(startDay, 4), 'dd.MM.')}`}
          label="Freitag"
        />
        <Checkbox
          checked={weekDays.includes(5)}
          onChange={(c: boolean) =>
            setWeekDays((d) =>
              c ? [...d.filter((d) => d !== 5), 5] : d.filter((d) => d !== 5),
            )
          }
          // label={`Sa, ${format(addDays(startDay, 5), 'dd.MM.')}`}
          label="Samstag"
        />
        <Checkbox
          checked={weekDays.includes(6)}
          onChange={(c: boolean) =>
            setWeekDays((d) =>
              c ? [...d.filter((d) => d !== 6), 6] : d.filter((d) => d !== 6),
            )
          }
          // label={`So, ${format(addDays(startDay, 6), 'dd.MM.')}`}
          label="Sonntag"
        />
      </div>

      <SelectField
        label="Berater/in wählen"
        nullable
        value={selectedConsultantId}
        onChange={(id) => setSelectedConsultantId(id as string)}
        choices={availableConsultants.map((t) => ({
          value: t.user?.id,
          label: getPersonDescription(t),
        }))}
      />

      <SelectField2
        multiple
        label="Wählbare Kurse wählen"
        value={selectedCourseIds}
        onChange={(ids) => setSelectedCourseIds(ids as string[])}
        choices={
          availableCoursesQuery.data?.courses.map((c) => ({
            value: c.id,
            label: c.verboseTitle || c.title,
          })) || []
        }
      />

      <SelectField2
        multiple
        label="Wählbare Standorte wählen"
        value={selectedLocationIds}
        onChange={(ids) => setSelectedLocationIds(ids as string[])}
        choices={
          availableMotherLocations.map((l) => ({
            value: l.id,
            label: l.commonName,
          })) || []
        }
      />

      <Textarea
        label="Wählbare Themen (pro Zeile)"
        white
        className={styles.selectedSubjects}
        value={selectedSubjects.join('\n')}
        onChange={(e) => {
          setSelectedSubjects(
            e.currentTarget.value ? e.currentTarget.value.split('\n') : [],
          );
        }}
      />

      {profile && !profile.selectedEnterprise && (
        <SelectField
          value={selectedEnterpriseId}
          onChange={(value) => setSelectedEnterpriseId(value as string)}
          label="Unternehmen"
          choices={availableEnterprises.map((e) => ({
            label: e.name,
            value: e.id,
          }))}
        />
      )}

      <div className={globalStyles.flexAlignRight}>
        <Button label="Vorschau" onClick={applyPreview} />
        <Button primary label="Erstellen" onClick={createEvents} />
      </div>
    </Box>
  );
};
