import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FindCustomersQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
}>;


export type FindCustomersQuery = { __typename?: 'Query', persons: { __typename?: 'PaginatedPersonWithDuplicatesResult', data: Array<{ __typename?: 'PersonWithDuplicates', id: any, title?: string | null, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null, email?: string | null, user?: { __typename?: 'Administrator', id: any, username?: string | null, status: Types.UserStatus } | { __typename?: 'Customer', id: any, username?: string | null, status: Types.UserStatus } | { __typename?: 'Teacher', id: any, username?: string | null, status: Types.UserStatus } | null }> } };


export const FindCustomersDocument = gql`
    query findCustomers($query: String!) {
  persons(
    pagination: {skip: 0, take: 999}
    filter: {query: $query, userType: "Customer"}
  ) {
    data {
      id
      title
      gender
      firstName
      lastName
      email
      user {
        id
        username
        status
      }
    }
  }
}
    `;
export type FindCustomersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FindCustomersQuery, FindCustomersQueryVariables>, 'query'> & ({ variables: FindCustomersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FindCustomersComponent = (props: FindCustomersComponentProps) => (
      <ApolloReactComponents.Query<FindCustomersQuery, FindCustomersQueryVariables> query={FindCustomersDocument} {...props} />
    );
    

/**
 * __useFindCustomersQuery__
 *
 * To run a query within a React component, call `useFindCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCustomersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFindCustomersQuery(baseOptions: Apollo.QueryHookOptions<FindCustomersQuery, FindCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCustomersQuery, FindCustomersQueryVariables>(FindCustomersDocument, options);
      }
export function useFindCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCustomersQuery, FindCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCustomersQuery, FindCustomersQueryVariables>(FindCustomersDocument, options);
        }
export type FindCustomersQueryHookResult = ReturnType<typeof useFindCustomersQuery>;
export type FindCustomersLazyQueryHookResult = ReturnType<typeof useFindCustomersLazyQuery>;
export type FindCustomersQueryResult = Apollo.QueryResult<FindCustomersQuery, FindCustomersQueryVariables>;