import classNames from 'classnames';
import propertyPath from 'property-path';
import React, {
  ComponentProps,
  ComponentType,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import {
  Controller,
  ControllerProps,
  DeepPartial,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import {
  UnpackNestedValue,
  UseFormReturn,
} from 'react-hook-form/dist/types/form';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { NumberFormatProps } from 'react-number-format';
import { Checkbox, CheckboxProps } from '../checkbox/checkbox.component';
import { CurrencyInputField } from '../currency-input-field/currency-input-field.component';
import { DatetimeField } from '../datetime-field/datetime-field.component';
import {
  DurationInputField,
  DurationInputFieldProps,
} from '../duration-input-field/duration-input-field.component';
import {
  GenderInputField,
  GenderInputFieldProps,
} from '../gender-input-field/gender-input-field.component';
import { HtmlField, HtmlFieldProps } from '../html-field/html-field.component';
import {
  InputField,
  InputFieldProps,
} from '../input-field/input-field.component';
import {
  LocationInputField,
  LocationInputFieldProps,
} from '../location-input-field/location-input-field.component';
import {
  MarkdownField,
  MarkdownFieldProps,
} from '../markdown-field/markdown-field.component';
import {
  SelectField2,
  SelectField2Props,
} from '../select-field-2/select-field-2.component';
import {
  SelectField,
  SelectFieldProps,
} from '../select-field/select-field.component';
import { Textarea, TextareaProps } from '../textarea/textarea.component';
import styles from './form.module.scss';

export type StrictFormFieldProps = {
  name: string;
  defaultValue?: string | boolean | number;
  help?: ReactNode;
  helpTitle?: string;
};

export type HookedFormFieldProps<Props> = Props & StrictFormFieldProps;

export type Render = (data: {
  field: {
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    value: any;
  };
}) => ReactElement;

export type ControlledFormProps<FieldValues> = {
  children: any;
  style?: any;
  form: UseFormReturn<FieldValues>;
  values?: UnpackNestedValue<DeepPartial<FieldValues>> | null;
};

function ControlledForm<FieldValues>(props: ControlledFormProps<FieldValues>) {
  const { children, form, values } = props;

  useEffect(() => {
    if (values) {
      form.reset(values);
    }
  }, [values]);

  return (
    <FormProvider {...(form as UseFormReturn<FieldValues>)}>
      <>{children}</>
    </FormProvider>
  );
}

function ControlledInput(props: HookedFormFieldProps<InputFieldProps>) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <InputField
          {...props}
          {...field}
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledDate(props: HookedFormFieldProps<any>) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <DatetimeField
          {...props}
          {...field}
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledTextarea(props: HookedFormFieldProps<TextareaProps>) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <Textarea
          {...props}
          {...field}
          onChange={
            props.type !== 'number'
              ? field.onChange
              : (e) => {
                  const value = e.currentTarget.value;
                  if (value === '') {
                    setValue(props.name, null);
                  } else {
                    setValue(props.name, value);
                  }
                }
          }
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledCheckbox(props: HookedFormFieldProps<CheckboxProps>) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <Checkbox
          {...props}
          {...field}
          checked={field.value}
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props],
  );
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue === false ? false : defaultValue || ''}
      render={render}
    />
  );
}

function ControlledDuration(
  props: HookedFormFieldProps<DurationInputFieldProps>,
) {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <DurationInputField
          {...props}
          {...field}
          onChange={(value) => field.onChange(value)}
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledSelect(props: HookedFormFieldProps<SelectFieldProps>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <SelectField
          {...props}
          {...field}
          onChange={(value) => {
            field.onChange(value);
          }}
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledSelect2(props: HookedFormFieldProps<SelectField2Props>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <SelectField2
          {...props}
          {...field}
          onChange={(value) => {
            field.onChange(value);
          }}
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledLocation(
  props: HookedFormFieldProps<LocationInputFieldProps>,
) {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <LocationInputField
          {...props}
          {...field}
          onChange={(value) => {
            field.onChange(value);
          }}
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props, props.guessAddress],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledGender(props: HookedFormFieldProps<GenderInputFieldProps>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <GenderInputField
          {...props}
          {...field}
          onChange={(value) => {
            field.onChange(value);
          }}
          error={propertyPath.get(errors, name)?.message}
        />
      );
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledMarkdown(props: HookedFormFieldProps<MarkdownFieldProps>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return <MarkdownField {...field} {...props} />;
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledHtml(props: HookedFormFieldProps<HtmlFieldProps>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  const { name, defaultValue } = props;

  const render = useCallback<ControllerProps['render']>(
    ({ field, fieldState }) => {
      return (
        <HtmlField {...field} error={fieldState.error?.message} {...props} />
      );
    },
    [errors, props],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={render}
    />
  );
}

function ControlledCurrency(
  props: HookedFormFieldProps<
    Omit<NumberFormatProps, 'value' | 'onChange' | 'type'> & {
      label?: string | null;
    }
  >,
) {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  const { name, defaultValue, ...inputProps } = props;

  const render = useCallback<Render>(
    ({ field }) => {
      return (
        <>
          <CurrencyInputField
            {...props}
            error={errors[name]?.message}
            {...field}
          />
        </>
      );
    },
    [errors, props.label, inputProps],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={render}
    />
  );
}

type PropsComparator<C extends ComponentType> = (
  prevProps: Readonly<ComponentProps<C>>,
  nextProps: Readonly<ComponentProps<C>>,
) => boolean;

function typedMemo<C extends ComponentType<any>>(
  Component: C,
  propsComparator?: PropsComparator<C>,
) {
  return memo(Component, propsComparator) as any as C;
}
interface PackProps {
  alignBottom?: boolean;
}
const PackFields: ComponentType<PackProps> = (props) => {
  const { children, alignBottom } = props;
  return (
    <div className={classNames(styles.pack, alignBottom && styles.alignBottom)}>
      {children}
    </div>
  );
};

export const Form = Object.assign(ControlledForm, {
  Input: typedMemo(ControlledInput),
  Date: typedMemo(ControlledDate),
  Textarea: typedMemo(ControlledTextarea),
  Duration: typedMemo(ControlledDuration),
  Location: ControlledLocation,
  Select: typedMemo(ControlledSelect),
  Select2: typedMemo(ControlledSelect2),
  Gender: typedMemo(ControlledGender),
  Markdown: typedMemo(ControlledMarkdown),
  Html: typedMemo(ControlledHtml),
  Currency: typedMemo(ControlledCurrency),
  Checkbox: typedMemo(ControlledCheckbox),
  Pack: PackFields,
});

export const FormError = {
  required: 'Dies ist ein Pflichtfeld.',
  minLength: (params: { min: number }) =>
    `Mindestens ${params.min} Zeichen ist/sind notwendig.`,
  minItems: (params: { min: number }) =>
    `Mindestens ${params.min} Element ist/sind notwendig.`,
  email: 'Dies ist keine gültige E-Mail Adresse.',
  wrongUserOrPassword: 'Benutzername oder Passwort sind nicht richtig.',
};
