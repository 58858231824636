import { DataFormater } from '@/statistics-v3/utils';
import React, { PropsWithChildren, useMemo } from 'react';
import {
  Bar,
  BarChart,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Translator } from 'shared/translator/translator.component';
import { ChartType } from './options/options.provider';

import { Loader } from '@/ui/loader/loader.component';
import { Graph } from './graph/graph';

export type ChartPoint<T = {}> = {
  time: string | number;
  [key: string]: string | number; // not optimal: should be `[key: string]: number`, but TS complains
};

interface ChartProps<T> {
  data: ChartPoint<T>[];
  chartType: ChartType;
  graphs?: Graph<any>[];
  unit?: string;
  loading?: boolean;
}

export const Chart = <T extends {}>(
  props: PropsWithChildren<ChartProps<T>>,
) => {
  const { data, chartType, graphs, unit, loading } = props;

  const _ = Translator.useTranslator();

  const ChartComponent = useMemo(() => {
    if (chartType === 'line') {
      return LineChart;
    }
    return BarChart;
  }, [chartType]);

  const DataComponent: any = useMemo(() => {
    if (chartType === 'line') {
      return Line;
    }
    return Bar;
  }, [chartType]);

  const enabledGraphs = useMemo(() => {
    return graphs?.filter((g) => g.enabled) || [];
  }, [graphs]);

  return (
    <>
      {loading && <Loader />}
      <ChartComponent width={600} height={400} data={data}>
        <XAxis
          dataKey="time"
          tick={{ fontSize: '1.3rem' }}
          axisLine={false}
          tickLine={false}
        />
        {
          <YAxis
            axisLine={false}
            tickLine={false}
            unit={unit ? ` ${unit}` : undefined}
            tickFormatter={DataFormater}
            tick={{ fontSize: '1.3rem' }}
          />
        }
        <Tooltip
          formatter={(value) => `${new Intl.NumberFormat('de').format(value)}`}
        />
        <Legend />

        {enabledGraphs.map((graph, index) => (
          <DataComponent
            key={`graph-${graph.name}-${index}`}
            dataKey={`value${index}`}
            stackId="a"
            fill={graph.options.color}
            strokeWidth={3}
            stroke={graph.options.color}
            dot={{ strokeWidth: 1 }}
            type="monotone"
            name={graph.name}
          />
        ))}
      </ChartComponent>
    </>
  );
};
