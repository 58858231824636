import * as Types from '../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type StatisticsSettingsDataFragment = { __typename?: 'StatisticsSettings', enterpriseId: any, boards: Array<{ __typename?: 'StatisticsBoard', id: string, name: string, boxes: Array<{ __typename?: 'StatisticsBox', id: string, name: string, type: Types.StatisticsBoxType, context: Types.StatisticsBoxContext, options: any }> }> };

export type UpdateUiSettingsMutationVariables = Types.Exact<{
  input: Types.UiSettingsInput;
}>;


export type UpdateUiSettingsMutation = { __typename?: 'Mutation', adminEditUiSettings: { __typename?: 'Person', id: any } };

export type ShareStatisticsBoardMutationVariables = Types.Exact<{
  input: Types.ShareStatisticsBoardInput;
}>;


export type ShareStatisticsBoardMutation = { __typename?: 'Mutation', adminShareStatisticsBoard: boolean };

export const StatisticsSettingsDataFragmentDoc = gql`
    fragment StatisticsSettingsData on StatisticsSettings {
  enterpriseId
  boards {
    id
    name
    boxes {
      id
      name
      type
      context
      options
    }
  }
}
    `;
export const UpdateUiSettingsDocument = gql`
    mutation updateUiSettings($input: UiSettingsInput!) {
  adminEditUiSettings(input: $input) {
    id
  }
}
    `;
export type UpdateUiSettingsMutationFn = Apollo.MutationFunction<UpdateUiSettingsMutation, UpdateUiSettingsMutationVariables>;
export type UpdateUiSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUiSettingsMutation, UpdateUiSettingsMutationVariables>, 'mutation'>;

    export const UpdateUiSettingsComponent = (props: UpdateUiSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUiSettingsMutation, UpdateUiSettingsMutationVariables> mutation={UpdateUiSettingsDocument} {...props} />
    );
    

/**
 * __useUpdateUiSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUiSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUiSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUiSettingsMutation, { data, loading, error }] = useUpdateUiSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUiSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUiSettingsMutation, UpdateUiSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUiSettingsMutation, UpdateUiSettingsMutationVariables>(UpdateUiSettingsDocument, options);
      }
export type UpdateUiSettingsMutationHookResult = ReturnType<typeof useUpdateUiSettingsMutation>;
export type UpdateUiSettingsMutationResult = Apollo.MutationResult<UpdateUiSettingsMutation>;
export type UpdateUiSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUiSettingsMutation, UpdateUiSettingsMutationVariables>;
export const ShareStatisticsBoardDocument = gql`
    mutation shareStatisticsBoard($input: ShareStatisticsBoardInput!) {
  adminShareStatisticsBoard(input: $input)
}
    `;
export type ShareStatisticsBoardMutationFn = Apollo.MutationFunction<ShareStatisticsBoardMutation, ShareStatisticsBoardMutationVariables>;
export type ShareStatisticsBoardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ShareStatisticsBoardMutation, ShareStatisticsBoardMutationVariables>, 'mutation'>;

    export const ShareStatisticsBoardComponent = (props: ShareStatisticsBoardComponentProps) => (
      <ApolloReactComponents.Mutation<ShareStatisticsBoardMutation, ShareStatisticsBoardMutationVariables> mutation={ShareStatisticsBoardDocument} {...props} />
    );
    

/**
 * __useShareStatisticsBoardMutation__
 *
 * To run a mutation, you first call `useShareStatisticsBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareStatisticsBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareStatisticsBoardMutation, { data, loading, error }] = useShareStatisticsBoardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareStatisticsBoardMutation(baseOptions?: Apollo.MutationHookOptions<ShareStatisticsBoardMutation, ShareStatisticsBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareStatisticsBoardMutation, ShareStatisticsBoardMutationVariables>(ShareStatisticsBoardDocument, options);
      }
export type ShareStatisticsBoardMutationHookResult = ReturnType<typeof useShareStatisticsBoardMutation>;
export type ShareStatisticsBoardMutationResult = Apollo.MutationResult<ShareStatisticsBoardMutation>;
export type ShareStatisticsBoardMutationOptions = Apollo.BaseMutationOptions<ShareStatisticsBoardMutation, ShareStatisticsBoardMutationVariables>;