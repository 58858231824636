import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
export type DependenciesFragmentFragment = {
  __typename?: 'DiscountRuleDependencies';
  courseIds?: Array<string> | null;
  courseUnitIds?: Array<string> | null;
  courseModuleIds?: Array<string> | null;
  productIds?: Array<string> | null;
  categoryIds?: Array<string> | null;
  products: Array<
    | { __typename?: 'LocationProduct'; id: any; title: string }
    | { __typename?: 'SimpleProduct'; id: any; title: string }
  >;
  courses: Array<{
    __typename?: 'Course';
    id: any;
    title: string;
    verboseTitle?: string | null;
  }>;
  courseUnits: Array<{
    __typename?: 'CourseUnit';
    id: any;
    title?: string | null;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    correspondenceCourse: boolean;
    online: boolean;
    location?: { __typename?: 'Location'; commonName: string } | null;
    searchableLocation?: { __typename?: 'Location'; commonName: string } | null;
    course: { __typename?: 'Course'; id: any; title: string };
  }>;
  courseModules: Array<{
    __typename?: 'CourseModule';
    id: any;
    title?: string | null;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    correspondenceCourse: boolean;
    online: boolean;
    location?: { __typename?: 'Location'; commonName: string } | null;
    searchableLocation?: { __typename?: 'Location'; commonName: string } | null;
    courseUnit: {
      __typename?: 'CourseUnit';
      id: any;
      course: { __typename?: 'Course'; id: any; title: string };
    };
  }>;
};

export type DiscountRuleDataFragment = {
  __typename?: 'DiscountRule';
  id: any;
  title?: string | null;
  description?: string | null;
  status: Types.DiscountRuleStatus;
  amount: number;
  type: Types.DiscountType;
  voucherCode?: string | null;
  useCheckbox: boolean;
  applyToWholeCart: boolean;
  dependencyRelation: Types.DiscountRuleDependencyRelation;
  enterpriseId: any;
  dependencies: {
    __typename?: 'DiscountRuleDependencies';
    courseIds?: Array<string> | null;
    courseUnitIds?: Array<string> | null;
    courseModuleIds?: Array<string> | null;
    productIds?: Array<string> | null;
    categoryIds?: Array<string> | null;
    products: Array<
      | { __typename?: 'LocationProduct'; id: any; title: string }
      | { __typename?: 'SimpleProduct'; id: any; title: string }
    >;
    courses: Array<{
      __typename?: 'Course';
      id: any;
      title: string;
      verboseTitle?: string | null;
    }>;
    courseUnits: Array<{
      __typename?: 'CourseUnit';
      id: any;
      title?: string | null;
      startDate: any;
      endDate: any;
      withTime: boolean;
      dateUncertain: boolean;
      correspondenceCourse: boolean;
      online: boolean;
      location?: { __typename?: 'Location'; commonName: string } | null;
      searchableLocation?: {
        __typename?: 'Location';
        commonName: string;
      } | null;
      course: { __typename?: 'Course'; id: any; title: string };
    }>;
    courseModules: Array<{
      __typename?: 'CourseModule';
      id: any;
      title?: string | null;
      startDate: any;
      endDate: any;
      withTime: boolean;
      dateUncertain: boolean;
      correspondenceCourse: boolean;
      online: boolean;
      location?: { __typename?: 'Location'; commonName: string } | null;
      searchableLocation?: {
        __typename?: 'Location';
        commonName: string;
      } | null;
      courseUnit: {
        __typename?: 'CourseUnit';
        id: any;
        course: { __typename?: 'Course'; id: any; title: string };
      };
    }>;
  };
  excludedItems: {
    __typename?: 'DiscountRuleDependencies';
    courseIds?: Array<string> | null;
    courseUnitIds?: Array<string> | null;
    courseModuleIds?: Array<string> | null;
    productIds?: Array<string> | null;
    categoryIds?: Array<string> | null;
    products: Array<
      | { __typename?: 'LocationProduct'; id: any; title: string }
      | { __typename?: 'SimpleProduct'; id: any; title: string }
    >;
    courses: Array<{
      __typename?: 'Course';
      id: any;
      title: string;
      verboseTitle?: string | null;
    }>;
    courseUnits: Array<{
      __typename?: 'CourseUnit';
      id: any;
      title?: string | null;
      startDate: any;
      endDate: any;
      withTime: boolean;
      dateUncertain: boolean;
      correspondenceCourse: boolean;
      online: boolean;
      location?: { __typename?: 'Location'; commonName: string } | null;
      searchableLocation?: {
        __typename?: 'Location';
        commonName: string;
      } | null;
      course: { __typename?: 'Course'; id: any; title: string };
    }>;
    courseModules: Array<{
      __typename?: 'CourseModule';
      id: any;
      title?: string | null;
      startDate: any;
      endDate: any;
      withTime: boolean;
      dateUncertain: boolean;
      correspondenceCourse: boolean;
      online: boolean;
      location?: { __typename?: 'Location'; commonName: string } | null;
      searchableLocation?: {
        __typename?: 'Location';
        commonName: string;
      } | null;
      courseUnit: {
        __typename?: 'CourseUnit';
        id: any;
        course: { __typename?: 'Course'; id: any; title: string };
      };
    }>;
  };
  enterprise: { __typename?: 'Enterprise'; id: any; name: string };
};

export const DependenciesFragmentFragmentDoc = gql`
  fragment DependenciesFragment on DiscountRuleDependencies {
    courseIds
    courseUnitIds
    courseModuleIds
    productIds
    categoryIds
    products {
      ... on Product {
        id
        title
      }
    }
    courses {
      id
      title
      verboseTitle
    }
    courseUnits {
      id
      title
      startDate
      endDate
      withTime
      dateUncertain
      correspondenceCourse
      online
      location {
        commonName
      }
      searchableLocation {
        commonName
      }
      course {
        id
        title
      }
    }
    courseModules {
      id
      title
      startDate
      endDate
      withTime
      dateUncertain
      correspondenceCourse
      online
      location {
        commonName
      }
      searchableLocation {
        commonName
      }
      courseUnit {
        id
        course {
          id
          title
        }
      }
    }
  }
`;
export const DiscountRuleDataFragmentDoc = gql`
  fragment DiscountRuleData on DiscountRule {
    id
    title
    description
    status
    amount
    type
    voucherCode
    useCheckbox
    applyToWholeCart
    dependencyRelation
    dependencies {
      ...DependenciesFragment
    }
    excludedItems {
      ...DependenciesFragment
    }
    enterprise {
      id
      name
    }
    enterpriseId
  }
  ${DependenciesFragmentFragmentDoc}
`;
