import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { Profile } from '../../profile/profile';
import { LogoImage } from './logo-image.component';
import styles from './logo.module.scss';

interface Props {
  size?: number;
  className?: string;
}

const Default: ComponentType<Props> = (props) => {
  const { size = 200, className } = props;
  return (
    <div className={classNames(styles.host, styles.single, className)}>
      <LogoImage color="#44c082" size={size} />
    </div>
  );
};

const AssignedEnterprises: ComponentType = (prps) => {
  const profile = Profile.use();

  return (
    <div className={classNames(styles.host, styles.list)}>
      {profile?.person.enterprises.map((enterprise, index) =>
        enterprise.logo ? (
          <img key={index} className="brand-logo" src={enterprise.logo.url} />
        ) : (
          enterprise.name
        ),
      )}
    </div>
  );
};

const SelectedEnterprise: ComponentType = (prps) => {
  const profile = Profile.use();

  if (profile) {
    return (
      <div className={classNames(styles.host, styles.list)}>
        <img
          className="brand-logo"
          src={profile?.selectedEnterprise?.logo?.url}
        />
      </div>
    );
  }
  return null;
};
export const Logo = Object.assign(Default, {
  AssignedEnterprises,
  SelectedEnterprise,
});
