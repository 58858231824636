import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type NodeDomain = 'category' | 'course';

interface TreeEditApi {
  setOpen(open: boolean, id: string, domain: NodeDomain): void;
  isOpen(id: string, domain: NodeDomain): boolean;
  currentCourseAndEnterprise: [string, string] | undefined;
  setCurrentCourseAndEnterprise(
    courseAndEnterprise: [string, string] | undefined,
  ): void;
}

type TreeNodeState = {
  domain: NodeDomain;
  state: boolean;
  id: string;
};

const TreeEditContext = createContext<TreeEditApi | undefined>(undefined);

export const TreeEditProvider: ComponentType = ({ children }) => {
  const persistedState = localStorage.getItem('course-tree.state');
  const persistedCurrentCourseState = localStorage.getItem(
    'course-tree.current-curse-and-enterprise',
  );
  const [currentCourseAndEnterprise, setCurrentCourseAndEnterprise] = useState<
    [string, string]
  >(
    persistedCurrentCourseState && persistedCurrentCourseState !== 'undefined'
      ? JSON.parse(persistedCurrentCourseState)
      : undefined,
  );
  const [state, setState] = useState<TreeNodeState[]>(
    persistedState ? JSON.parse(persistedState) : [],
  );

  useEffect(() => {
    localStorage.setItem('course-tree.state', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    localStorage.setItem(
      'course-tree.current-curse-and-enterprise',
      JSON.stringify(currentCourseAndEnterprise),
    );
  }, [currentCourseAndEnterprise]);

  const setOpen = useCallback(
    (open: boolean, id: string, domain: NodeDomain) => {
      if (state.find((node) => node.id === id && node.domain === domain)) {
        setState(
          state.map((node) =>
            node.id === id && node.domain === domain
              ? { ...node, state: open }
              : node,
          ),
        );
      } else {
        setState([...state, { id, domain, state: true }]);
      }
    },
    [state],
  );

  const isOpen = useCallback(
    (id: string, domain: NodeDomain) => {
      const nodeState = state.find(
        (node) => node.id === id && node.domain === domain,
      );
      if (nodeState) {
        return nodeState.state;
      }
      return false;
    },
    [state],
  );

  return (
    <TreeEditContext.Provider
      value={{
        setOpen,
        isOpen,
        currentCourseAndEnterprise,
        setCurrentCourseAndEnterprise,
      }}
    >
      {children}
    </TreeEditContext.Provider>
  );
};

export function useTreeEdit() {
  const api = useContext(TreeEditContext);

  if (!api) {
    throw new Error('Tree Edit Context not ready');
  }

  return api;
}
