import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import { ItemDetailDataFragmentDoc } from '../shared/item/item.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type OrderDataFragment = {
  __typename?: 'Order';
  id: any;
  total: number;
  createdAt: any;
  status: Types.OrderStatus;
  customer: {
    __typename?: 'Customer';
    id: any;
    person: {
      __typename?: 'Person';
      id: any;
      title?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    };
  };
  items: Array<
    | {
        __typename?: 'BookingOrderItem';
        id: any;
        quantity: number;
        amount: number;
        booking:
          | {
              __typename?: 'CourseModuleBooking';
              id: any;
              courseModule: {
                __typename?: 'CourseModule';
                sku: string;
                startDate: any;
                endDate: any;
                dateUncertain: boolean;
                withTime: boolean;
                location?: {
                  __typename?: 'Location';
                  id: any;
                  commonName: string;
                } | null;
                courseUnit: {
                  __typename?: 'CourseUnit';
                  id: any;
                  course: {
                    __typename?: 'Course';
                    id: any;
                    abbreviation: string;
                    verboseTitle?: string | null;
                    categoryId: any;
                    category: {
                      __typename?: 'CourseCategory';
                      id: any;
                      enterprise: { __typename?: 'Enterprise'; name: string };
                    };
                  };
                };
              };
            }
          | {
              __typename?: 'CourseUnitBooking';
              id: any;
              courseUnit: {
                __typename?: 'CourseUnit';
                sku: string;
                startDate: any;
                endDate: any;
                dateUncertain: boolean;
                withTime: boolean;
                location?: {
                  __typename?: 'Location';
                  id: any;
                  commonName: string;
                } | null;
                course: {
                  __typename?: 'Course';
                  id: any;
                  abbreviation: string;
                  verboseTitle?: string | null;
                  categoryId: any;
                  category: {
                    __typename?: 'CourseCategory';
                    id: any;
                    enterprise: { __typename?: 'Enterprise'; name: string };
                  };
                };
              };
            };
        discount: {
          __typename?: 'DiscountEmbedded';
          type: Types.DiscountType;
          amount?: number | null;
          reason?: string | null;
        };
        discounts: Array<{
          __typename?: 'Discount';
          id: any;
          type: Types.DiscountType;
          amount?: number | null;
          reason?: string | null;
        }>;
      }
    | {
        __typename?: 'DiscountOrderItem';
        id: any;
        quantity: number;
        amount: number;
        discountRule?: {
          __typename?: 'DiscountRule';
          type: Types.DiscountType;
          amount: number;
        } | null;
        discount: {
          __typename?: 'DiscountEmbedded';
          type: Types.DiscountType;
          amount?: number | null;
          reason?: string | null;
        };
        discounts: Array<{
          __typename?: 'Discount';
          id: any;
          type: Types.DiscountType;
          amount?: number | null;
          reason?: string | null;
        }>;
      }
    | {
        __typename?: 'PackageBookingOrderItem';
        id: any;
        quantity: number;
        amount: number;
        package: {
          __typename?: 'Package';
          title: string;
          subTitle?: string | null;
          enterprise: { __typename?: 'Enterprise'; id: any; name: string };
        };
        discount: {
          __typename?: 'DiscountEmbedded';
          type: Types.DiscountType;
          amount?: number | null;
          reason?: string | null;
        };
        discounts: Array<{
          __typename?: 'Discount';
          id: any;
          type: Types.DiscountType;
          amount?: number | null;
          reason?: string | null;
        }>;
      }
    | {
        __typename?: 'ProductOrderItem';
        id: any;
        quantity: number;
        amount: number;
        product:
          | {
              __typename?: 'LocationProduct';
              id: any;
              sku: string;
              title: string;
              enterprise: { __typename?: 'Enterprise'; name: string };
            }
          | {
              __typename?: 'SimpleProduct';
              id: any;
              sku: string;
              title: string;
              enterprise: { __typename?: 'Enterprise'; name: string };
            };
        discount: {
          __typename?: 'DiscountEmbedded';
          type: Types.DiscountType;
          amount?: number | null;
          reason?: string | null;
        };
        discounts: Array<{
          __typename?: 'Discount';
          id: any;
          type: Types.DiscountType;
          amount?: number | null;
          reason?: string | null;
        }>;
      }
  >;
  discounts: Array<{
    __typename?: 'Discount';
    id: any;
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  }>;
};

export type OrdersQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.FilterOrdersInput>;
}>;

export type OrdersQuery = {
  __typename?: 'Query';
  adminOrders: {
    __typename?: 'PaginatedOrderResult';
    totalCount: number;
    data: Array<{
      __typename?: 'Order';
      id: any;
      total: number;
      createdAt: any;
      status: Types.OrderStatus;
      customer: {
        __typename?: 'Customer';
        id: any;
        person: {
          __typename?: 'Person';
          id: any;
          title?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        };
      };
      items: Array<
        | {
            __typename?: 'BookingOrderItem';
            id: any;
            quantity: number;
            amount: number;
            booking:
              | {
                  __typename?: 'CourseModuleBooking';
                  id: any;
                  courseModule: {
                    __typename?: 'CourseModule';
                    sku: string;
                    startDate: any;
                    endDate: any;
                    dateUncertain: boolean;
                    withTime: boolean;
                    location?: {
                      __typename?: 'Location';
                      id: any;
                      commonName: string;
                    } | null;
                    courseUnit: {
                      __typename?: 'CourseUnit';
                      id: any;
                      course: {
                        __typename?: 'Course';
                        id: any;
                        abbreviation: string;
                        verboseTitle?: string | null;
                        categoryId: any;
                        category: {
                          __typename?: 'CourseCategory';
                          id: any;
                          enterprise: {
                            __typename?: 'Enterprise';
                            name: string;
                          };
                        };
                      };
                    };
                  };
                }
              | {
                  __typename?: 'CourseUnitBooking';
                  id: any;
                  courseUnit: {
                    __typename?: 'CourseUnit';
                    sku: string;
                    startDate: any;
                    endDate: any;
                    dateUncertain: boolean;
                    withTime: boolean;
                    location?: {
                      __typename?: 'Location';
                      id: any;
                      commonName: string;
                    } | null;
                    course: {
                      __typename?: 'Course';
                      id: any;
                      abbreviation: string;
                      verboseTitle?: string | null;
                      categoryId: any;
                      category: {
                        __typename?: 'CourseCategory';
                        id: any;
                        enterprise: { __typename?: 'Enterprise'; name: string };
                      };
                    };
                  };
                };
            discount: {
              __typename?: 'DiscountEmbedded';
              type: Types.DiscountType;
              amount?: number | null;
              reason?: string | null;
            };
            discounts: Array<{
              __typename?: 'Discount';
              id: any;
              type: Types.DiscountType;
              amount?: number | null;
              reason?: string | null;
            }>;
          }
        | {
            __typename?: 'DiscountOrderItem';
            id: any;
            quantity: number;
            amount: number;
            discountRule?: {
              __typename?: 'DiscountRule';
              type: Types.DiscountType;
              amount: number;
            } | null;
            discount: {
              __typename?: 'DiscountEmbedded';
              type: Types.DiscountType;
              amount?: number | null;
              reason?: string | null;
            };
            discounts: Array<{
              __typename?: 'Discount';
              id: any;
              type: Types.DiscountType;
              amount?: number | null;
              reason?: string | null;
            }>;
          }
        | {
            __typename?: 'PackageBookingOrderItem';
            id: any;
            quantity: number;
            amount: number;
            package: {
              __typename?: 'Package';
              title: string;
              subTitle?: string | null;
              enterprise: { __typename?: 'Enterprise'; id: any; name: string };
            };
            discount: {
              __typename?: 'DiscountEmbedded';
              type: Types.DiscountType;
              amount?: number | null;
              reason?: string | null;
            };
            discounts: Array<{
              __typename?: 'Discount';
              id: any;
              type: Types.DiscountType;
              amount?: number | null;
              reason?: string | null;
            }>;
          }
        | {
            __typename?: 'ProductOrderItem';
            id: any;
            quantity: number;
            amount: number;
            product:
              | {
                  __typename?: 'LocationProduct';
                  id: any;
                  sku: string;
                  title: string;
                  enterprise: { __typename?: 'Enterprise'; name: string };
                }
              | {
                  __typename?: 'SimpleProduct';
                  id: any;
                  sku: string;
                  title: string;
                  enterprise: { __typename?: 'Enterprise'; name: string };
                };
            discount: {
              __typename?: 'DiscountEmbedded';
              type: Types.DiscountType;
              amount?: number | null;
              reason?: string | null;
            };
            discounts: Array<{
              __typename?: 'Discount';
              id: any;
              type: Types.DiscountType;
              amount?: number | null;
              reason?: string | null;
            }>;
          }
      >;
      discounts: Array<{
        __typename?: 'Discount';
        id: any;
        type: Types.DiscountType;
        amount?: number | null;
        reason?: string | null;
      }>;
    }>;
  };
};

export const OrderDataFragmentDoc = gql`
  fragment OrderData on Order {
    id
    total
    createdAt
    status
    customer {
      id
      person {
        id
        title
        firstName
        lastName
      }
    }
    items {
      ...ItemDetailData
    }
    discounts {
      id
      type
      amount
      reason
    }
  }
  ${ItemDetailDataFragmentDoc}
`;
export const OrdersDocument = gql`
  query orders($pagination: PaginationInput!, $filter: FilterOrdersInput) {
    adminOrders(pagination: $pagination, filter: $filter) {
      totalCount
      data {
        ...OrderData
      }
    }
  }
  ${OrderDataFragmentDoc}
`;
export type OrdersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OrdersQuery,
    OrdersQueryVariables
  >,
  'query'
> &
  ({ variables: OrdersQueryVariables; skip?: boolean } | { skip: boolean });

export const OrdersComponent = (props: OrdersComponentProps) => (
  <ApolloReactComponents.Query<OrdersQuery, OrdersQueryVariables>
    query={OrdersDocument}
    {...props}
  />
);

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    options,
  );
}
export function useOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    options,
  );
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<
  OrdersQuery,
  OrdersQueryVariables
>;
