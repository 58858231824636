import { Frame } from '@/frame';
import { LocationStatus } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import globalStyles from 'styles/global.scss';
import {
  ListLocationDataFragment,
  LocationsQueryVariables,
  useLocationsQuery,
} from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery();
  const { data, loading } = useLocationsQuery({
    variables: { pagination: getPaginationFromQuery(query) },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="/locations/locations/create" label="Neuer Standort" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<ListLocationDataFragment, LocationsQueryVariables['query']>
          loading={loading}
          data={data?.adminLocations?.data || []}
          totalCount={data?.adminLocations?.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'Stadt',
              sortable: true,
            },
            {
              name: 'Mutter',
              sortable: true,
            },
            {
              name: 'Firmen',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              {/* <Filter
              value={value || ''}
              onChange={onChange}
              placeholder={'Suche…'}
              loading={loading}
            /> */}
            </>
          )}
          renderRow={(item) => (
            <Row
              key={item.id}
              className={classNames(
                !item.parent && globalStyles.emphasizedRow,
                item.status === LocationStatus.Inactive && 'inactive',
              )}
            >
              <td>
                <Link to={`../${item.id}/edit`}>
                  <Copyable
                    valueToCopy={`${item.id}|${item.name || item.city}`}
                    label={`Kopieren: ${item.id}|${item.name || item.city}`}
                    inline
                  >
                    {item.name}
                  </Copyable>
                </Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>
                  {item.address?.city}{' '}
                  <span className={globalStyles.meta}>{item.city}</span>
                </Link>
              </td>
              <td>
                {item.parent && (
                  <Link to={`../${item.parent.id}/edit`}>
                    {item.parent.city || item.parent.address?.city}{' '}
                    <span className={globalStyles.meta}>
                      {item.parent.name}
                    </span>
                  </Link>
                )}
              </td>
              <td>
                {item.enterprises
                  .map((enterprise) => enterprise.name)
                  .join(', ')}
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
