import {
  getPersonDescription,
  getPersonDetailLink,
} from '@/contact/person/utils';
import { DuplicatesCell } from '@/contact/person/view/duplicates-cell.component';
import { PersonEmail } from '@/contact/person/view/person-email.component';
import { UserStatusChip } from '@/contact/person/view/user-status-chip.component';
import { Frame } from '@/frame';
import { FilterPersonInput } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { PersonListDataFragment, usePersonsQuery } from '../list.generated';

export const TeacherList: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery<{ query: string }>();
  const { data, loading } = usePersonsQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      withDuplicates: true,
      filter: { query: query.query, userType: 'Teacher' },
    },
    fetchPolicy: 'network-only',
  });
  const _ = Translator.useTranslator();

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="/contacts/teachers/create" label="Neuer Dozent" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<PersonListDataFragment, FilterPersonInput>
          loading={loading}
          data={data?.persons?.data || []}
          totalCount={data?.persons?.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'E-Mail',
              sortable: true,
            },
            {
              name: 'Benutzer',
              sortable: true,
            },
            {
              name: 'Unternehmen',
              sortable: true,
            },
            {
              name: 'Status',
              sortable: true,
            },
            {
              name: 'Hinweise',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <DebouncedInput
              debounceRate={400}
              value={value?.query || ''}
              onChange={(value) => onChange('query', value)}
              placeholder={'Suche…'}
            />
          )}
          renderRow={(item) => {
            const detailLink = getPersonDetailLink(item);
            return (
              <Row key={item.id}>
                <td>
                  <Link to={detailLink}>{getPersonDescription(item)}</Link>
                </td>
                <td>
                  <Link to={detailLink}>
                    <PersonEmail person={item} />
                  </Link>
                </td>
                <td>
                  <Link to={detailLink}>{item.user?.username}</Link>
                </td>
                <td>
                  {item.enterprises.length > 0 &&
                    item.enterprises.map((i) => i.name).join(', ')}
                </td>
                <td>
                  <UserStatusChip user={item.user} />
                </td>
                <td>
                  {item.__typename === 'PersonWithDuplicates' &&
                    item.duplicates && (
                      <DuplicatesCell
                        duplicates={item.duplicates}
                        linkTo={`../../${item.id}/duplicates`}
                      />
                    )}
                </td>
              </Row>
            );
          }}
        />
      </Frame.Content>
    </>
  );
};
