import { addressSchema } from '@/address/address.schema';
import { Address, LocationStatus } from '@/graphql/generated/types';
import { Profile, isSuperAdmin } from '@/profile/profile';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { HelpPopup } from '@/ui/help-popup/help-popup.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  isoCountryCodes,
  useCountryNames,
} from 'shared/countries/iso-country-codes';
import { PropertySchemaRenderer } from 'shared/property-schema/property-schema.component';
import { useYupPropertySchema } from 'shared/property-schema/property-schema.hooks';
import { useResources } from 'shared/resources/resources.provider';
import { array, number, object, string } from 'yup';
import { Frame } from '../../../../frame';
import { Button } from '../../../../ui/button/button.component';
import {
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useEditLocationMutation,
  useLocationQuery,
} from './editor.generated';

type Values = {
  name: string;
  city: string | null;
  address: Partial<Address> | null;
  administrativeEmail: string | null;
  enterpriseIds: string[];
  parentId: string | null;
  geoLocation: [number, number] | null;
  status: LocationStatus;
};

const schema = object({
  name: string().min(3).required(),
  city: string(),
  address: addressSchema,
  administrativeEmail: string().email(),
  parentId: string().nullable(),
  geoLocation: array(number()).min(2).max(2).nullable(),
  status: string().required(),
  geoLocationId: string().nullable().default(''),
});

const superAdminSchema = schema.shape({
  enterpriseIds: array(string()).min(1),
});

export const Editor: ComponentType<RouteComponentProps<{ id: string }>> = (
  props,
) => {
  const locationQuery = useLocationQuery({ variables: { id: props.id } });
  const getName = useCountryNames();
  const [save] = useEditLocationMutation();
  const [create] = useCreateLocationMutation();
  const profile = Profile.use();
  const properties = useYupPropertySchema(
    profile.selectedEnterprise?.id!,
    'location',
  );

  const form = useForm<Values>({
    resolver: yupResolver(
      (isSuperAdmin(profile) ? superAdminSchema : schema).concat(properties),
    ),
    shouldUnregister: true,
    defaultValues: {
      parentId: null,
      status: LocationStatus.Active,
      enterpriseIds: isSuperAdmin(profile)
        ? undefined
        : [profile.selectedEnterprise?.id],
    },
  });
  const { availableEnterprises, availableLocations, reload } = useResources();

  const availableParentLocations = useMemo(() => {
    return availableLocations.filter((location) =>
      props.id
        ? location.id !== props.id && location.parentId == null
        : location.parentId === null,
    );
  }, [availableLocations, props.id]);

  const onSubmit = useCallback(
    async (input) => {
      if (props.id) {
        try {
          await save({ variables: { id: props.id, input } });
          await reload();
          success('Der Standort wurde aktualisiert.');
        } catch (e) {
          error('Beim Erstellen des Standortes ist ein Fehler aufgetreten.');
        }
      } else {
        try {
          const location = await create({ variables: { input } });
          navigate(`./${location.data?.adminCreateLocation.id}/edit`);
          await reload();
          success('Der Standort wurde erstellt.');
        } catch (e) {
          error('Beim Erstellen des Standortes ist ein Fehler aufgetreten.');
        }
      }
    },
    [props.id],
  );

  const onSubmitError = useCallback((errors) => {
    error('Bitte überprüfe deine Eingabe.');
  }, []);

  const [remove] = useDeleteLocationMutation();

  const [confirmModal, confirmDelete] = useConfirm(async (id: string) => {
    try {
      await remove({ variables: { id } });
      await reload();
      navigate('../list');
      success('Der Standort wurde gelöscht.');
    } catch (e) {
      error('Fehler');
    }
  });

  const onDelete = useCallback(async () => {
    if (props.id) {
      confirmDelete('Soll dieser Standort wirklich gelöscht werden?', props.id);
    }
  }, [props.id]);

  const address =
    locationQuery.data?.adminLocation.address || form.watch('address');

  const persisted = props.id !== undefined || locationQuery.data !== undefined;

  return (
    <>
      {confirmModal}
      <Frame.SubTitle>
        {persisted ? locationQuery.data?.adminLocation.name : 'Neuer Standort'}
      </Frame.SubTitle>
      <Frame.ActionBar>
        <Button warning transparent label="Löschen" onClick={onDelete} />
        <Button label="Zurück" linkTo="/locations/locations/list" />
        <Button
          primary
          label="Speichern"
          onClick={form.handleSubmit(onSubmit, onSubmitError)}
        />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={locationQuery.data?.adminLocation}>
          <Grid.Row>
            <Grid.Column>
              <Section title="Standort">
                <Form.Input name="name" label="Name" />
                <Form.Location
                  name="geoLocation"
                  label="Geo-Location"
                  guessAddress={`${address?.streetAndNumber} ${address?.postalCode} ${address?.city}`}
                />
                <Form.Input
                  name="city"
                  label={
                    <>
                      angezeigte Stadt{' '}
                      <HelpPopup hover="überschreibt die Stadt der eigentlichen Anschrift" />
                    </>
                  }
                />
                <Form.Input
                  name="administrativeEmail"
                  label="Verwaltungs-E-Mail"
                />
                <Form.Select
                  label="Status"
                  name="status"
                  choices={[
                    { label: 'Aktiv', value: LocationStatus.Active },
                    { label: 'Inaktiv', value: LocationStatus.Inactive },
                  ]}
                />
              </Section>

              <Form.Select
                name="parentId"
                nullable
                label={
                  <>
                    Mutter{' '}
                    <HelpPopup
                      hover="Der übergeordnete Standort dient der besseren Zuordnung eines
                      Standortes, z.B. der wahrheitsgemäß in einer anderen Stadt
                liegt, aber dennoch zum Suchergebnis eines übergeordneten
                Standortes gehören soll."
                    />
                  </>
                }
                choices={
                  availableParentLocations.map((location) => ({
                    value: location.id,
                    label: `${location.city || location.address?.city} (${
                      location.name
                    })`,
                  })) || []
                }
              />

              {!persisted && (
                <>
                  <Form.Select
                    disabled
                    multiple
                    name="enterpriseIds"
                    label={
                      <>
                        Firmenzugehörigkeit
                        <HelpPopup hover="Halte die Strg- (Windows) oder Befehlstaste (Mac) gedrückt, um mehrere Optionen auszuwählen." />
                      </>
                    }
                    choices={availableEnterprises.map((enterprise) => ({
                      value: enterprise.id,
                      label: enterprise.name,
                    }))}
                  />
                </>
              )}
              {persisted && (
                <>
                  <label>Firmenzugehörigkeit</label>
                  <ul>
                    {locationQuery.data?.adminLocation.enterprises.map((c) => (
                      <li>{c.name}</li>
                    ))}
                  </ul>
                </>
              )}
            </Grid.Column>
            <Grid.Column>
              <Section title="Anschrift">
                <Form.Input
                  name="address.contactPerson"
                  label="Vor- und Nachname"
                />

                <Form.Input name="address.company" label="Firma" />

                <Form.Input
                  name="address.streetAndNumber"
                  label="Straße und Hausnummer"
                />
                <Form.Input name="address.postalCode" label="PLZ" />
                <Form.Input name="address.city" label="Stadt" />
                <Form.Select
                  choices={isoCountryCodes.map((code: string) => ({
                    label: getName(code),
                    value: code,
                  }))}
                  name="address.country"
                  label="Land"
                />
              </Section>
              <Form.Input
                name="geoLocationId"
                label="Geo Location ID (Google)"
              />
              {profile.selectedEnterprise?.id && (
                <PropertySchemaRenderer
                  enterpriseId={profile.selectedEnterprise?.id}
                  context="location"
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
