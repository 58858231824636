export function getAttributeValueKeyByKind(kind) {
  if (kind === 'TextAttribute') {
    return 'textValue';
  }
  if (kind === 'StringAttribute') {
    return 'stringValue';
  }
  if (kind === 'IntAttribute') {
    return 'intValue';
  }
  if (kind === 'JsonAttribute') {
    return 'jsonValue';
  }

  return 'decimalValue';
}
