import closeImage from '@/assets/image/icons/close.svg';
import chevronDownIcon from 'assets/image/icons/chevron-down.svg';
import classNames from 'classnames';
import React, { ComponentType, ReactNode, useCallback, useState } from 'react';
import { Rnd } from 'react-rnd';
import { Icon } from '../icon/icon.component';
import { Loader } from '../loader/loader.component';
import styles from './box.module.scss';
interface Props {
  stretch?: boolean;
  title?: ReactNode;
  loading?: boolean;
  dark?: boolean;
  className?: string;
  fullHeight?: boolean;
  collapsible?: boolean;
  withMargin?: boolean;
  flex?: boolean;
}

export const Default: ComponentType<Props> = (props) => {
  const {
    children,
    loading,
    dark,
    title,
    className,
    fullHeight,
    collapsible,
    withMargin,
    flex,
    stretch,
  } = props;

  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <div
      className={classNames(
        styles.host,
        dark && styles.dark,
        (stretch || fullHeight) && styles.fullHeight,
        className,
        collapsible && styles.collapsible,
        open ? styles.open : styles.collapsed,
        withMargin && styles.withMargin,
      )}
    >
      {loading && <Loader withLogo />}
      {(collapsible || title) && (
        <div className={styles.header} onClick={onClick}>
          {title}
          {collapsible && (
            <div className={styles.indicator}>
              <Icon className={styles.icon} icon={chevronDownIcon} inline />
            </div>
          )}
        </div>
      )}
      <div className={classNames(styles.content, flex && styles.flex)}>
        {children}
      </div>
    </div>
  );
};

interface FloatingProps {
  bounds?: string;
  position: { x: number; y: number };
  onClose?: () => void;
}
export const Floating: ComponentType<Props & FloatingProps> = (props) => {
  const { position, bounds, onClose } = props;
  return (
    <Rnd
      bounds={bounds}
      default={{ x: position.x, y: position.y } as any}
      className={styles.floating}
      dragGrid={[1, 1]}
      resizeGrid={[1, 1]}
      dragHandleClassName={styles.header}
      // bounds={bounds}
      // onDrag={onDrag as any}
      // onDragStop={onDragStop}
      // onResize={onResize}
      // onResizeStop={onResizeStopAction}
    >
      <Box
        {...props}
        title={
          <>
            {props.title}
            <Icon inline onClick={onClose} icon={closeImage} />
          </>
        }
      >
        {props.children}
      </Box>
    </Rnd>
  );
};
export const Box = Object.assign(Default, { Floating });
