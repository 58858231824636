import { usePersonsQuery } from '@/contact/person/list/list.generated';
import { getPersonDescription } from '@/contact/person/utils';
import { durationSchema } from '@/duration/duration.schema';
import { Frame } from '@/frame';
import {
  CourseModule,
  CourseModuleStatus,
  SaveCourseModuleInput,
} from '@/graphql/generated/types';
import globalStyles from '@/styles/global.scss';
import { useAdminTagsQuery } from '@/tag/list/list.generated';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Grid } from '@/ui/grid/grid.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import omitDeep from 'omit-deep-lodash';
import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { array, bool, number, object, string } from 'yup';
import {
  useCourseModuleQuery,
  useDeleteCourseModuleMutation,
  useUpdateDetailedCourseModuleMutation,
} from './editor.generated';

const schema = object({
  title: string().nullable(),
  subTitle: string().nullable(),
  status: string().required(),
  lateBookingOffset: durationSchema,
  description: string().nullable(),
  startDate: string().required(),
  endDate: string().required(),
  withTime: bool(),
  dateUncertain: bool(),
  amount: number().nullable(),
  discountedAmount: number().nullable(),
  earlyBirdDate: string().nullable(),
  earlyBirdNotice: string().nullable(),
  online: bool(),
  correspondenceCourse: bool(),
  tagIds: array().of(string()),
});

export const Editor: ComponentType<
  RouteComponentProps<{ moduleId: string; id: string }>
> = (props) => {
  const { moduleId, id } = props;

  const { availableLocations } = useResources();
  const tagsQuery = useAdminTagsQuery({
    variables: { pagination: { take: 999, skip: 0 } },
  });

  const courseModuleQuery = useCourseModuleQuery({
    variables: { id: moduleId },
    skip: moduleId === undefined,
    fetchPolicy: 'network-only',
  });

  const allTeachers = usePersonsQuery({
    variables: {
      filter: { userType: 'Teacher' },
      pagination: { take: 1000, skip: 0 },
    },
  }).data?.persons.data;

  const [update] = useUpdateDetailedCourseModuleMutation();
  const [remove] = useDeleteCourseModuleMutation();

  const form = useForm<SaveCourseModuleInput, CourseModule>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
    defaultValues: {
      status: CourseModuleStatus.Active,
      online: false,
      dateUncertain: false,
      withTime: false,
      correspondenceCourse: false,
      tagIds: [],
    },
  });

  const courseLocations = useMemo(() => {
    if (courseModuleQuery.data) {
      return availableLocations.filter((location) =>
        location.enterprises
          .map((l) => l.id)
          .includes(
            courseModuleQuery.data?.adminCourseModule.courseUnit.course.category
              .enterpriseId,
          ),
      );
    }
    return [];
  }, [courseModuleQuery.data, availableLocations]);

  const searchableLocations = useMemo(() => {
    return courseLocations.filter((location) => location.parentId === null);
  }, [courseLocations]);

  const courseTeachers = useMemo(() => {
    if (courseModuleQuery.data && allTeachers) {
      return allTeachers.filter((person) =>
        person.enterprises
          .map((l) => l.id)
          .includes(
            courseModuleQuery.data?.adminCourseModule.courseUnit.course.category
              .enterpriseId,
          ),
      );
    }
    return [];
  }, [courseModuleQuery.data, allTeachers]);

  const [confirmModal, confirmDelete] = useConfirm(async () => {
    try {
      await remove({ variables: { id: moduleId } });
      navigate(`/course-management/units/${id}/edit`);
      success('Kurs-Modul gelöscht.');
    } catch (e) {
      error(
        'Fehler',
        'Die Kurs-Einheit kann nicht gelöscht werden, da sie Module oder Events enthält.',
      );
    }
  });

  const onSubmit = useMemo(() => {
    return form.handleSubmit(async (input) => {
      try {
        if (moduleId) {
          await update({
            variables: {
              id: moduleId,
              input: {
                ...(omitDeep(input, '__typename') as any),
                courseUnitId: id,
              },
            },
          });

          success('Das Kurs-Modul wurde gespeichert.');
        }
      } catch (e: any) {
        error('Fehler', e.message);
      }
    });
  }, [moduleId, form.handleSubmit, courseModuleQuery.data, id]);

  const onDelete = useCallback(async () => {
    if (moduleId) {
      confirmDelete('Soll dieses Kurs-Modul wirklich gelöscht werden?');
    }
  }, [moduleId]);

  useEffect(() => {
    if (courseModuleQuery.data) {
      form.setValue(
        'locationId',
        courseModuleQuery.data.adminCourseModule.location?.id ||
          courseLocations[0]?.id,
      );
      form.setValue(
        'searchableLocationId',
        courseModuleQuery.data.adminCourseModule.searchableLocation?.id ||
          searchableLocations[0]?.id,
      );
    }
  }, [courseModuleQuery.data, courseLocations, searchableLocations]);

  return (
    <>
      {confirmModal}
      <Frame.SubTitle>
        Kurs-Modul:{' '}
        {courseModuleQuery.data?.adminCourseModule.title ||
          courseModuleQuery.data?.adminCourseModule.courseUnit.title ||
          courseModuleQuery.data?.adminCourseModule.courseUnit.course.title}
        {courseModuleQuery.data &&
          courseModuleQuery.data.adminCourseModule.status ===
            CourseModuleStatus.Inactive && (
            <>
              {' '}
              <span className={globalStyles.error}>[INAKTIV]</span>
            </>
          )}
      </Frame.SubTitle>

      <Frame.ActionBar>
        {moduleId && (
          <Button
            warning
            transparent
            label="Modul löschen"
            onClick={onDelete}
          />
        )}
        <Button label="Zurück" linkTo={`/course-management/units/${id}/edit`} />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Grid.Row>
          <Grid.Column>
            <Section title="Kurs-Modul-Eigenschaften">
              <Form
                form={form}
                values={courseModuleQuery.data?.adminCourseModule}
              >
                <Form.Input
                  name="title"
                  label="Titel (optional, überschreibt den Namen des Kurses)"
                />

                <Form.Input
                  name="subTitle"
                  label="Untertitel (optional, überschreibt den Namen des Kurses)"
                />

                <Form.Select
                  label="Status"
                  name="status"
                  choices={[
                    { label: 'Aktiv', value: CourseModuleStatus.Active },
                    { label: 'Inaktiv', value: CourseModuleStatus.Inactive },
                  ]}
                />

                <Form.Select2
                  name="tagIds"
                  multiple
                  label="Tags"
                  choices={
                    tagsQuery.data?.adminTags.data.map((t) => ({
                      value: t.id,
                      label: t.name,
                    })) || []
                  }
                />

                <Form.Date name="startDate" label="Start" />
                <Form.Date name="endDate" label="Ende" />

                <Form.Checkbox
                  name="withTime"
                  label="Uhrzeiten berücksichtigen"
                />
                <Form.Checkbox
                  name="dateUncertain"
                  label="Datum noch nicht sicher"
                />
                <Form.Duration
                  name="lateBookingOffset"
                  label="Buchen möglich bis (nach Start)"
                />
                <Form.Select
                  nullable
                  label="Standort"
                  name="locationId"
                  choices={courseLocations.map((location) => ({
                    label: location.name || location.city || '',
                    value: location.id,
                  }))}
                />
                <Form.Select
                  nullable
                  label="Standort relevant für die Suche (falls abweichend)"
                  name="searchableLocationId"
                  choices={searchableLocations.map((location) => ({
                    label: location.name || location.city || '',
                    value: location.id,
                  }))}
                />
                <Form.Select
                  nullable
                  label="Dozent"
                  name="teacherId"
                  choices={courseTeachers?.map((person) => ({
                    label:
                      getPersonDescription(person) +
                      ' (' +
                      person.enterprises
                        .map((enterprise) => enterprise.name)
                        .join(', ') +
                      ')',
                    value: person.user?.id,
                  }))}
                />
                <Form.Currency name="amount" label="Preis" />
                <Form.Currency
                  name="discountedAmount"
                  label="Preis (Frühbucher-Rabatt)"
                />
                {courseModuleQuery.data?.adminCourseModule.courseUnit.course
                  .earlyBirdDate && (
                  <p>
                    Für den gesamten Kurs gilt die Datumsgrenze{' '}
                    <em>
                      <Formatted.Date
                        value={
                          courseModuleQuery.data?.adminCourseModule.courseUnit
                            .course.earlyBirdDate
                        }
                      />
                    </em>
                  </p>
                )}
                <Form.Date
                  name="earlyBirdDate"
                  label="Frühbucher Rabatt gültig bis (falls abweichend)"
                />
                <Form.Input
                  name="earlyBirdNotice"
                  label={
                    courseModuleQuery.data?.adminCourseModule.courseUnit.course
                      .earlyBirdNotice
                      ? `Frühbucher Hinweis (falls abweichend von "${courseModuleQuery.data.adminCourseModule.courseUnit.course.earlyBirdNotice}")`
                      : 'Frühbucher Hinweis'
                  }
                />
                <Form.Checkbox name="online" label="Online-Angebot" />
                <Form.Checkbox name="correspondenceCourse" label="Fernkurs" />
                <Form.Html
                  name="description"
                  label="Beschreibung (wird z.B. im Detail-Popup/Overlay angezeigt)"
                />
              </Form>
            </Section>
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Frame.Content>
    </>
  );
};
