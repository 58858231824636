import {
  useCreateAdministratorMutation,
  useUpdateAdministratorMutation,
} from '../administrator/editor.generated';
import {
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
} from '../customer/editor.generated';
import {
  useCreatePersonMutation,
  useUpdatePersonMutation,
} from '../person/person.generated';
import {
  useCreateTeacherMutation,
  useUpdateTeacherMutation,
} from '../teacher/editor.generated';
import { UserType } from './base-editor.component';

export function useCreateMutation(userType?: UserType) {
  const createPersonMutation = useCreatePersonMutation();
  const createCustomerMutation = useCreateCustomerMutation();
  const createAdministratorMutation = useCreateAdministratorMutation();
  const createTeacherMutation = useCreateTeacherMutation();
  if (userType === 'administrators') {
    return createAdministratorMutation;
  }
  if (userType === 'customers') {
    return createCustomerMutation;
  }
  if (userType === 'teachers') {
    return createTeacherMutation;
  }
  return createPersonMutation;
}

export function useUpdateMutation(userType?: UserType) {
  const updatePersonMutation = useUpdatePersonMutation();
  const updateCustomerMutation = useUpdateCustomerMutation();
  const updateAdministratorMutation = useUpdateAdministratorMutation();
  const updateTeacherMutation = useUpdateTeacherMutation();
  if (userType === 'administrators') {
    return updateAdministratorMutation;
  }
  if (userType === 'customers') {
    return updateCustomerMutation;
  }
  if (userType === 'teachers') {
    return updateTeacherMutation;
  }
  return updatePersonMutation;
}
