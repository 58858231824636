import { RoleType } from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  AvailableCourseCategoryDataFragment,
  AvailableEnterpriseDataFragment,
  AvailableLocationsDataFragment,
  AvailableRoleDataFragment,
  ResourcesQuery,
  useAvailableEnterprisesQuery,
  useResourcesQuery,
} from './resources.generated';

interface ResourcesAPI {
  availableLocations: AvailableLocationsDataFragment[];
  availableEnterprises: AvailableEnterpriseDataFragment[];
  availableCourseCategories: (AvailableCourseCategoryDataFragment & {
    isLeaf: boolean;
  })[];
  availableRoles: AvailableRoleDataFragment[];
  availableTeachers: ResourcesQuery['adminTeachers']['data'];
  availableConsultants: ResourcesQuery['adminAvailableAdministrators']['data'];
  availableAdministrators: ResourcesQuery['adminAvailableAdministrators']['data'];
  reload(): void;
  availableSeasons: ResourcesQuery['adminAvailableSeasons']['data'];
  availableCompanies: ResourcesQuery['adminAvailableCompanies']['data'];
  taxRates: number[];
}

const ResourcesContext = createContext<ResourcesAPI | null>(null);

export const ResourcesProvider: ComponentType = ({ children }) => {
  const profile = Profile.use();

  const availableEnterprisesQuery = useAvailableEnterprisesQuery({
    skip: profile === undefined,
    fetchPolicy: 'network-only',
  });

  const availableEnterprises = useMemo(() => {
    if (profile?.roles.find((role) => role.name === RoleType.Super)) {
      return availableEnterprisesQuery.data?.enterprises || [];
    }

    return profile?.person.enterprises || [];
  }, [
    profile?.person.enterprises,
    availableEnterprisesQuery.data?.enterprises,
  ]);

  const resourcesQuery = useResourcesQuery({
    skip: profile === undefined || availableEnterprises.length === 0,
    fetchPolicy: 'network-only',
    variables: {
      pagination: { take: 999, skip: 0 },
      enterpriseIds: availableEnterprises.map((enterprise) => enterprise.id),
    },
  });

  const availableCourseCategories = useMemo(() => {
    const categories = resourcesQuery.data?.adminCourseCategories.data || [];

    return categories.map((category) => ({
      ...category,
      isLeaf: categories.find((c) => c.parentId === category.id) === undefined,
    }));
  }, [resourcesQuery.data]);

  const reload = useCallback(() => {
    availableEnterprisesQuery.refetch();
    resourcesQuery.refetch();
  }, []);

  const availableLocations = useMemo(() => {
    if (!resourcesQuery.data?.locations) {
      return [];
    }
    if (profile.roles.find((role) => role.name === RoleType.Super)) {
      return resourcesQuery.data.locations;
    }
    return resourcesQuery.data.locations.filter((location) => {
      const enterprises = profile.person.enterprises.map((c) => c.id);
      // if (location.enterprises.find((c) => enterprises.includes(c.id))) {
      //   return true;
      // }
      if (
        location.enterprises.find(
          (c) => c.id === profile.selectedEnterprise?.id,
        )
      ) {
        return true;
      }
      return false;
    });
  }, [
    resourcesQuery.data?.locations,
    profile?.person.enterprises,
    profile?.selectedEnterprise,
  ]);

  const availableConsultants = useMemo(() => {
    return (
      resourcesQuery.data?.adminAvailableAdministrators.data.filter((a) =>
        a.user?.__typename === 'Administrator'
          ? a.user.roles.find((r) => r.name === RoleType.Consultant)
          : false,
      ) || []
    );
  }, [resourcesQuery.data?.adminAvailableAdministrators]);

  const availableSeasons = useMemo(() => {
    return resourcesQuery?.data?.adminAvailableSeasons.data || [];
  }, [resourcesQuery.data?.adminAvailableSeasons]);

  const availableCompanies = useMemo(() => {
    return resourcesQuery?.data?.adminAvailableCompanies.data || [];
  }, [resourcesQuery.data?.adminAvailableCompanies]);

  // useEffect(() => {
  //   if (profile) {
  //     availableEnterprisesQuery.refetch();
  //     availableCourseCategoriesQuery.refetch();
  //   }
  // }, [profile]);

  return (
    <ResourcesContext.Provider
      value={{
        availableEnterprises,
        availableCourseCategories,
        availableLocations,
        availableRoles: resourcesQuery.data?.roles || [],
        availableTeachers: resourcesQuery.data?.adminTeachers.data || [],
        availableAdministrators:
          resourcesQuery.data?.adminAvailableAdministrators.data || [],
        availableConsultants,
        reload,
        availableSeasons,
        availableCompanies,
        taxRates: [0, 0.07, 0.19],
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};

export function useResources() {
  const context = useContext(ResourcesContext);
  if (!context) {
    throw new Error('ResourcesContext not ready.');
  }
  return context;
}
