import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type EditEnterpriseSettingsDataFragment = { __typename?: 'Enterprise', id: any, name: string, communicationSettings?: { __typename?: 'CommunicationSettings', id: any, updatedAt: any, data?: { __typename?: 'CommunicationSettingsData', mailHost?: string | null, mailPort?: string | null, mailUser?: string | null, mailPass?: string | null, mailFrom?: string | null, mailAddressCourseManagement?: string | null, mailAddressSupport?: string | null, mailBaseTemplateHtml?: string | null, mailBaseTemplateTxt?: string | null, useCustomerEmailAsMailFrom?: boolean | null } | null } | null, websiteSettings?: { __typename?: 'WebsiteSettings', id: any, updatedAt: any, data?: { __typename?: 'WebsiteSettingsData', websiteBaseUri?: string | null } | null } | null };

export type EnterpriseSettingsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type EnterpriseSettingsQuery = { __typename?: 'Query', adminEnterprise: { __typename?: 'Enterprise', id: any, name: string, communicationSettings?: { __typename?: 'CommunicationSettings', id: any, updatedAt: any, data?: { __typename?: 'CommunicationSettingsData', mailHost?: string | null, mailPort?: string | null, mailUser?: string | null, mailPass?: string | null, mailFrom?: string | null, mailAddressCourseManagement?: string | null, mailAddressSupport?: string | null, mailBaseTemplateHtml?: string | null, mailBaseTemplateTxt?: string | null, useCustomerEmailAsMailFrom?: boolean | null } | null } | null, websiteSettings?: { __typename?: 'WebsiteSettings', id: any, updatedAt: any, data?: { __typename?: 'WebsiteSettingsData', websiteBaseUri?: string | null } | null } | null } };

export type EditEnterpriseSettingsMutationVariables = Types.Exact<{
  input: Types.EditEnterpriseInput;
  id: Types.Scalars['UUID'];
}>;


export type EditEnterpriseSettingsMutation = { __typename?: 'Mutation', adminEditEnterprise: { __typename?: 'Enterprise', id: any, name: string, communicationSettings?: { __typename?: 'CommunicationSettings', id: any, updatedAt: any, data?: { __typename?: 'CommunicationSettingsData', mailHost?: string | null, mailPort?: string | null, mailUser?: string | null, mailPass?: string | null, mailFrom?: string | null, mailAddressCourseManagement?: string | null, mailAddressSupport?: string | null, mailBaseTemplateHtml?: string | null, mailBaseTemplateTxt?: string | null, useCustomerEmailAsMailFrom?: boolean | null } | null } | null, websiteSettings?: { __typename?: 'WebsiteSettings', id: any, updatedAt: any, data?: { __typename?: 'WebsiteSettingsData', websiteBaseUri?: string | null } | null } | null } };

export const EditEnterpriseSettingsDataFragmentDoc = gql`
    fragment EditEnterpriseSettingsData on Enterprise {
  id
  name
  communicationSettings {
    id
    updatedAt
    data {
      mailHost
      mailPort
      mailUser
      mailPass
      mailFrom
      mailAddressCourseManagement
      mailAddressSupport
      mailBaseTemplateHtml
      mailBaseTemplateTxt
      useCustomerEmailAsMailFrom
    }
  }
  websiteSettings {
    id
    updatedAt
    data {
      websiteBaseUri
    }
  }
}
    `;
export const EnterpriseSettingsDocument = gql`
    query enterpriseSettings($id: UUID!) {
  adminEnterprise(id: $id) {
    ...EditEnterpriseSettingsData
  }
}
    ${EditEnterpriseSettingsDataFragmentDoc}`;
export type EnterpriseSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EnterpriseSettingsQuery, EnterpriseSettingsQueryVariables>, 'query'> & ({ variables: EnterpriseSettingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EnterpriseSettingsComponent = (props: EnterpriseSettingsComponentProps) => (
      <ApolloReactComponents.Query<EnterpriseSettingsQuery, EnterpriseSettingsQueryVariables> query={EnterpriseSettingsDocument} {...props} />
    );
    

/**
 * __useEnterpriseSettingsQuery__
 *
 * To run a query within a React component, call `useEnterpriseSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnterpriseSettingsQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseSettingsQuery, EnterpriseSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseSettingsQuery, EnterpriseSettingsQueryVariables>(EnterpriseSettingsDocument, options);
      }
export function useEnterpriseSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseSettingsQuery, EnterpriseSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseSettingsQuery, EnterpriseSettingsQueryVariables>(EnterpriseSettingsDocument, options);
        }
export type EnterpriseSettingsQueryHookResult = ReturnType<typeof useEnterpriseSettingsQuery>;
export type EnterpriseSettingsLazyQueryHookResult = ReturnType<typeof useEnterpriseSettingsLazyQuery>;
export type EnterpriseSettingsQueryResult = Apollo.QueryResult<EnterpriseSettingsQuery, EnterpriseSettingsQueryVariables>;
export const EditEnterpriseSettingsDocument = gql`
    mutation editEnterpriseSettings($input: EditEnterpriseInput!, $id: UUID!) {
  adminEditEnterprise(input: $input, id: $id) {
    ...EditEnterpriseSettingsData
  }
}
    ${EditEnterpriseSettingsDataFragmentDoc}`;
export type EditEnterpriseSettingsMutationFn = Apollo.MutationFunction<EditEnterpriseSettingsMutation, EditEnterpriseSettingsMutationVariables>;
export type EditEnterpriseSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditEnterpriseSettingsMutation, EditEnterpriseSettingsMutationVariables>, 'mutation'>;

    export const EditEnterpriseSettingsComponent = (props: EditEnterpriseSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<EditEnterpriseSettingsMutation, EditEnterpriseSettingsMutationVariables> mutation={EditEnterpriseSettingsDocument} {...props} />
    );
    

/**
 * __useEditEnterpriseSettingsMutation__
 *
 * To run a mutation, you first call `useEditEnterpriseSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEnterpriseSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEnterpriseSettingsMutation, { data, loading, error }] = useEditEnterpriseSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditEnterpriseSettingsMutation(baseOptions?: Apollo.MutationHookOptions<EditEnterpriseSettingsMutation, EditEnterpriseSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEnterpriseSettingsMutation, EditEnterpriseSettingsMutationVariables>(EditEnterpriseSettingsDocument, options);
      }
export type EditEnterpriseSettingsMutationHookResult = ReturnType<typeof useEditEnterpriseSettingsMutation>;
export type EditEnterpriseSettingsMutationResult = Apollo.MutationResult<EditEnterpriseSettingsMutation>;
export type EditEnterpriseSettingsMutationOptions = Apollo.BaseMutationOptions<EditEnterpriseSettingsMutation, EditEnterpriseSettingsMutationVariables>;