import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import globalStyles from 'styles/global.scss';
import {
  CourseCategoryDataFragment,
  useCourseCategoriesQuery,
} from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery();
  const { data, loading } = useCourseCategoriesQuery({
    variables: { pagination: getPaginationFromQuery(query) },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>Kategorien</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="../create" label="Neue Kategorie" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<CourseCategoryDataFragment, string>
          loading={loading}
          data={data?.adminCourseCategories?.data || []}
          totalCount={data?.adminCourseCategories?.totalCount}
          columns={[
            {
              name: 'Titel / Slug',
              sortable: true,
            },
            {
              name: 'Mutter',
              sortable: true,
            },
            {
              name: 'Unternehmen',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              {/* <Filter
              value={value || ''}
              onChange={onChange}
              placeholder={'Suche …'}
              loading={loading}
            /> */}
            </>
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/edit`}>
                  <Copyable valueToCopy={item.id} label="ID kopieren" inline>
                    {item.title}
                    <br />
                    <span className={globalStyles.light}>{item.slug}</span>
                  </Copyable>
                </Link>
              </td>
              <td>
                {item.parent && (
                  <Link to={`../${item.parent.id}/edit`}>
                    {item.parent.title}
                  </Link>
                )}
              </td>
              <td>{item.enterprise.name}</td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
