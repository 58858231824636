import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { Grid } from '@/ui/grid/grid.component';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { useBoardModals } from '../box/modals.hook';
import { StatisticsBox } from '../box/statistics-box.component';
import { ChartOptionsProvider } from '../chart/options/options.provider';
import { useStatisticsBoards } from './board.provider';
import styles from './stats-board.module.scss';

export const StatsBoard: ComponentType<RouteComponentProps> = (props) => {
  const { settings } = useStatisticsBoards();

  const board = useMemo(() => {
    if (settings) {
      return settings.boards.find(
        (b) => b.id === props.path?.replace('board-', ''),
      );
    }
    return undefined;
  }, [settings, props.path]);

  const {
    askDeleteBoardModal,
    askDeleteBoard,
    askShareBoardModal,
    askShareBoard,
    askCreateBox,
    askCreateBoxModal,
  } = useBoardModals(board);

  return (
    <>
      {askDeleteBoardModal}
      {askShareBoardModal}
      {askCreateBoxModal}
      <Frame.Title>Statistik</Frame.Title>
      <Frame.ActionBar>
        {board && (
          <>
            <Button label="Board teilen" onClick={() => askShareBoard.open()} />
            <Button
              label="Board löschen"
              onClick={() =>
                askDeleteBoard(
                  <>
                    Bist du sicher, dass du das Board <em>{board.name}</em>{' '}
                    löschen möchtest?
                  </>,
                  board.id,
                )
              }
              error
            />
          </>
        )}
      </Frame.ActionBar>
      <Frame.Content>
        <Grid.Masonry
          cells={
            board?.boxes.map((box) => (
              <ChartOptionsProvider
                key={box.id}
                context={box.context}
                type={box.type}
                options={box.options}
              >
                <StatisticsBox {...box} board={board} />
              </ChartOptionsProvider>
            )) || []
          }
          columns={2}
        />
        <div className={styles.actions}>
          <Button
            round
            primary
            label="+"
            onClick={() => {
              if (board) {
                askCreateBox.open();
              }
            }}
          />
        </div>
      </Frame.Content>
    </>
  );
};
