import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ListEquipmentDataFragment = { __typename?: 'Equipment', id: any, name: string, description: string };

export type EquipmentsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EquipmentsQuery = { __typename?: 'Query', adminEquipments: { __typename?: 'PaginatedEquipmentResult', totalCount: number, data: Array<{ __typename?: 'Equipment', id: any, name: string, description: string }> } };

export const ListEquipmentDataFragmentDoc = gql`
    fragment ListEquipmentData on Equipment {
  id
  name
  description
}
    `;
export const EquipmentsDocument = gql`
    query equipments($pagination: PaginationInput!, $query: String) {
  adminEquipments(pagination: $pagination, query: $query) {
    totalCount
    data {
      ...ListEquipmentData
    }
  }
}
    ${ListEquipmentDataFragmentDoc}`;
export type EquipmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EquipmentsQuery, EquipmentsQueryVariables>, 'query'> & ({ variables: EquipmentsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EquipmentsComponent = (props: EquipmentsComponentProps) => (
      <ApolloReactComponents.Query<EquipmentsQuery, EquipmentsQueryVariables> query={EquipmentsDocument} {...props} />
    );
    

/**
 * __useEquipmentsQuery__
 *
 * To run a query within a React component, call `useEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useEquipmentsQuery(baseOptions: Apollo.QueryHookOptions<EquipmentsQuery, EquipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EquipmentsQuery, EquipmentsQueryVariables>(EquipmentsDocument, options);
      }
export function useEquipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquipmentsQuery, EquipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EquipmentsQuery, EquipmentsQueryVariables>(EquipmentsDocument, options);
        }
export type EquipmentsQueryHookResult = ReturnType<typeof useEquipmentsQuery>;
export type EquipmentsLazyQueryHookResult = ReturnType<typeof useEquipmentsLazyQuery>;
export type EquipmentsQueryResult = Apollo.QueryResult<EquipmentsQuery, EquipmentsQueryVariables>;