import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { CourseUnitNodeDataFragmentDoc } from '../../../../shared/course/course-unit-list/course-unit-list.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DeleteCourseUnitMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type DeleteCourseUnitMutation = {
  __typename?: 'Mutation';
  adminDeleteCourseUnit: boolean;
};

export type CreateCourseUnitMutationVariables = Types.Exact<{
  input: Types.SaveCourseUnitInput;
}>;

export type CreateCourseUnitMutation = {
  __typename?: 'Mutation';
  adminCreateCourseUnit: {
    __typename?: 'CourseUnit';
    id: any;
    title?: string | null;
    subTitle?: string | null;
    status: Types.CourseUnitStatus;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    online: boolean;
    correspondenceCourse: boolean;
    tagIds?: Array<any> | null;
    seasonId?: any | null;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      commonName: string;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      commonName: string;
    } | null;
    modules: Array<{
      __typename?: 'CourseModule';
      id: any;
      title?: string | null;
      subTitle?: string | null;
      startDate: any;
      endDate: any;
      status: Types.CourseModuleStatus;
      tagIds?: Array<any> | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    }>;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
    season?: {
      __typename?: 'Season';
      id: any;
      status: Types.SeasonStatus;
      title: string;
    } | null;
  };
};

export type UpdateCourseUnitMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.SaveCourseUnitInput;
}>;

export type UpdateCourseUnitMutation = {
  __typename?: 'Mutation';
  adminUpdateCourseUnit: {
    __typename?: 'CourseUnit';
    id: any;
    title?: string | null;
    subTitle?: string | null;
    status: Types.CourseUnitStatus;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    online: boolean;
    correspondenceCourse: boolean;
    tagIds?: Array<any> | null;
    seasonId?: any | null;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      commonName: string;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      commonName: string;
    } | null;
    modules: Array<{
      __typename?: 'CourseModule';
      id: any;
      title?: string | null;
      subTitle?: string | null;
      startDate: any;
      endDate: any;
      status: Types.CourseModuleStatus;
      tagIds?: Array<any> | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    }>;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
    season?: {
      __typename?: 'Season';
      id: any;
      status: Types.SeasonStatus;
      title: string;
    } | null;
  };
};

export const DeleteCourseUnitDocument = gql`
  mutation deleteCourseUnit($id: UUID!) {
    adminDeleteCourseUnit(id: $id)
  }
`;
export type DeleteCourseUnitMutationFn = Apollo.MutationFunction<
  DeleteCourseUnitMutation,
  DeleteCourseUnitMutationVariables
>;
export type DeleteCourseUnitComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCourseUnitMutation,
    DeleteCourseUnitMutationVariables
  >,
  'mutation'
>;

export const DeleteCourseUnitComponent = (
  props: DeleteCourseUnitComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    DeleteCourseUnitMutation,
    DeleteCourseUnitMutationVariables
  >
    mutation={DeleteCourseUnitDocument}
    {...props}
  />
);

/**
 * __useDeleteCourseUnitMutation__
 *
 * To run a mutation, you first call `useDeleteCourseUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseUnitMutation, { data, loading, error }] = useDeleteCourseUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCourseUnitMutation,
    DeleteCourseUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCourseUnitMutation,
    DeleteCourseUnitMutationVariables
  >(DeleteCourseUnitDocument, options);
}
export type DeleteCourseUnitMutationHookResult = ReturnType<
  typeof useDeleteCourseUnitMutation
>;
export type DeleteCourseUnitMutationResult =
  Apollo.MutationResult<DeleteCourseUnitMutation>;
export type DeleteCourseUnitMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseUnitMutation,
  DeleteCourseUnitMutationVariables
>;
export const CreateCourseUnitDocument = gql`
  mutation createCourseUnit($input: SaveCourseUnitInput!) {
    adminCreateCourseUnit(input: $input) {
      ...CourseUnitNodeData
    }
  }
  ${CourseUnitNodeDataFragmentDoc}
`;
export type CreateCourseUnitMutationFn = Apollo.MutationFunction<
  CreateCourseUnitMutation,
  CreateCourseUnitMutationVariables
>;
export type CreateCourseUnitComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCourseUnitMutation,
    CreateCourseUnitMutationVariables
  >,
  'mutation'
>;

export const CreateCourseUnitComponent = (
  props: CreateCourseUnitComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateCourseUnitMutation,
    CreateCourseUnitMutationVariables
  >
    mutation={CreateCourseUnitDocument}
    {...props}
  />
);

/**
 * __useCreateCourseUnitMutation__
 *
 * To run a mutation, you first call `useCreateCourseUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseUnitMutation, { data, loading, error }] = useCreateCourseUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseUnitMutation,
    CreateCourseUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCourseUnitMutation,
    CreateCourseUnitMutationVariables
  >(CreateCourseUnitDocument, options);
}
export type CreateCourseUnitMutationHookResult = ReturnType<
  typeof useCreateCourseUnitMutation
>;
export type CreateCourseUnitMutationResult =
  Apollo.MutationResult<CreateCourseUnitMutation>;
export type CreateCourseUnitMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseUnitMutation,
  CreateCourseUnitMutationVariables
>;
export const UpdateCourseUnitDocument = gql`
  mutation updateCourseUnit($id: UUID!, $input: SaveCourseUnitInput!) {
    adminUpdateCourseUnit(id: $id, input: $input) {
      ...CourseUnitNodeData
    }
  }
  ${CourseUnitNodeDataFragmentDoc}
`;
export type UpdateCourseUnitMutationFn = Apollo.MutationFunction<
  UpdateCourseUnitMutation,
  UpdateCourseUnitMutationVariables
>;
export type UpdateCourseUnitComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCourseUnitMutation,
    UpdateCourseUnitMutationVariables
  >,
  'mutation'
>;

export const UpdateCourseUnitComponent = (
  props: UpdateCourseUnitComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateCourseUnitMutation,
    UpdateCourseUnitMutationVariables
  >
    mutation={UpdateCourseUnitDocument}
    {...props}
  />
);

/**
 * __useUpdateCourseUnitMutation__
 *
 * To run a mutation, you first call `useUpdateCourseUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseUnitMutation, { data, loading, error }] = useUpdateCourseUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourseUnitMutation,
    UpdateCourseUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCourseUnitMutation,
    UpdateCourseUnitMutationVariables
  >(UpdateCourseUnitDocument, options);
}
export type UpdateCourseUnitMutationHookResult = ReturnType<
  typeof useUpdateCourseUnitMutation
>;
export type UpdateCourseUnitMutationResult =
  Apollo.MutationResult<UpdateCourseUnitMutation>;
export type UpdateCourseUnitMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseUnitMutation,
  UpdateCourseUnitMutationVariables
>;
