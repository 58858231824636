import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CompanyDetailDataFragment = { __typename?: 'Company', id: any, name: string, createdAt: any, internalNotes?: string | null, enterpriseIds?: Array<any> | null, employments: Array<{ __typename?: 'Employment', id: any, description?: string | null, person: { __typename?: 'Person', id: any, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null } }>, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }> };

export type CompanyQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type CompanyQuery = { __typename?: 'Query', adminCompany: { __typename?: 'Company', id: any, name: string, createdAt: any, internalNotes?: string | null, enterpriseIds?: Array<any> | null, employments: Array<{ __typename?: 'Employment', id: any, description?: string | null, person: { __typename?: 'Person', id: any, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null } }>, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }> } };

export type CreateCompanyMutationVariables = Types.Exact<{
  input: Types.CompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', adminCreateCompany: { __typename?: 'Company', id: any, name: string, createdAt: any, internalNotes?: string | null, enterpriseIds?: Array<any> | null, employments: Array<{ __typename?: 'Employment', id: any, description?: string | null, person: { __typename?: 'Person', id: any, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null } }>, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }> } };

export type EditCompanyMutationVariables = Types.Exact<{
  input: Types.CompanyInput;
  id: Types.Scalars['UUID'];
}>;


export type EditCompanyMutation = { __typename?: 'Mutation', adminEditCompany: { __typename?: 'Company', id: any, name: string, createdAt: any, internalNotes?: string | null, enterpriseIds?: Array<any> | null, employments: Array<{ __typename?: 'Employment', id: any, description?: string | null, person: { __typename?: 'Person', id: any, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null } }>, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }> } };

export type DeleteCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', adminDeleteCompany: boolean };

export const CompanyDetailDataFragmentDoc = gql`
    fragment CompanyDetailData on Company {
  id
  name
  createdAt
  employments {
    id
    description
    person {
      id
      gender
      firstName
      lastName
    }
  }
  contactProperties {
    id
    key
    value
  }
  address {
    company
    contactPerson
    streetAndNumber
    postalCode
    city
    country
    other
  }
  invoiceAddress {
    company
    contactPerson
    streetAndNumber
    postalCode
    city
    country
    other
  }
  internalNotes
  enterprises {
    id
    name
  }
  enterpriseIds
}
    `;
export const CompanyDocument = gql`
    query company($id: UUID!) {
  adminCompany(id: $id) {
    ...CompanyDetailData
  }
}
    ${CompanyDetailDataFragmentDoc}`;
export type CompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompanyQuery, CompanyQueryVariables>, 'query'> & ({ variables: CompanyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompanyComponent = (props: CompanyComponentProps) => (
      <ApolloReactComponents.Query<CompanyQuery, CompanyQueryVariables> query={CompanyDocument} {...props} />
    );
    

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CompanyInput!) {
  adminCreateCompany(input: $input) {
    ...CompanyDetailData
  }
}
    ${CompanyDetailDataFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export type CreateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCompanyMutation, CreateCompanyMutationVariables>, 'mutation'>;

    export const CreateCompanyComponent = (props: CreateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> mutation={CreateCompanyDocument} {...props} />
    );
    

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const EditCompanyDocument = gql`
    mutation editCompany($input: CompanyInput!, $id: UUID!) {
  adminEditCompany(input: $input, id: $id) {
    ...CompanyDetailData
  }
}
    ${CompanyDetailDataFragmentDoc}`;
export type EditCompanyMutationFn = Apollo.MutationFunction<EditCompanyMutation, EditCompanyMutationVariables>;
export type EditCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditCompanyMutation, EditCompanyMutationVariables>, 'mutation'>;

    export const EditCompanyComponent = (props: EditCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<EditCompanyMutation, EditCompanyMutationVariables> mutation={EditCompanyDocument} {...props} />
    );
    

/**
 * __useEditCompanyMutation__
 *
 * To run a mutation, you first call `useEditCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyMutation, { data, loading, error }] = useEditCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditCompanyMutation(baseOptions?: Apollo.MutationHookOptions<EditCompanyMutation, EditCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCompanyMutation, EditCompanyMutationVariables>(EditCompanyDocument, options);
      }
export type EditCompanyMutationHookResult = ReturnType<typeof useEditCompanyMutation>;
export type EditCompanyMutationResult = Apollo.MutationResult<EditCompanyMutation>;
export type EditCompanyMutationOptions = Apollo.BaseMutationOptions<EditCompanyMutation, EditCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: UUID!) {
  adminDeleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export type DeleteCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>, 'mutation'>;

    export const DeleteCompanyComponent = (props: DeleteCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCompanyMutation, DeleteCompanyMutationVariables> mutation={DeleteCompanyDocument} {...props} />
    );
    

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;