import classNames from 'classnames';
import propertyPath from 'property-path';
import React, { forwardRef, ReactNode, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import TimePicker from 'react-time-picker';
import globalStyles from 'styles/global.scss';
import styles from './time-field.module.scss';

export interface TimeFieldProps {
  white?: boolean;
  small?: boolean;
  compact?: boolean;
  value?: string | Date | null;
  name?: string;
  onChange?: (e: any) => void;
  error?: string;
  label?: ReactNode | null;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
}

export const TimeField = forwardRef<HTMLInputElement, TimeFieldProps>(
  (props, ref) => {
    const {
      error,
      label,
      white,
      value,
      formState,
      small,
      compact,
      onChange,
      ...rest
    } = props;

    const errorMessage = useMemo(() => {
      if (props.name && formState?.errors) {
        return propertyPath.get(formState.errors, props.name)?.message;
      }
      return error;
    }, [error, formState, props.name]);

    return (
      <div
        className={classNames(
          styles.host,
          small && styles.small,
          compact && styles.compact,
          white && styles.white,
          'time-field',
        )}
      >
        {label && (
          <label>
            {label}
            {props.required && ' *'}
          </label>
        )}

        <TimePicker {...rest} onChange={onChange} value={value} />
        {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
      </div>
    );
  },
);
