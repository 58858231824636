import { ContactProperty, ContactPropertyKey } from '@/graphql/generated/types';
import { Formatted } from '@/ui/formatted/formatted.component';
import React, { ReactNode } from 'react';
import { MergeCandidate } from '../types';
import { RelatedUserType } from './utils';

export interface Attribute {
  name: keyof MergeCandidate | ContactPropertyKey;
  label: string;
  render?: (item: Partial<MergeCandidate>) => ReactNode;
  extractMultipleValues?: (item: Partial<MergeCandidate>) => {
    label: string;
    value: string;
  }[];
}

export const personAttributes: Attribute[] = [
  {
    name: 'firstName',
    label: 'Vorname',
    render: (item) => item?.firstName,
  },
  { name: 'lastName', label: 'Nachname', render: (item) => item?.lastName },
  {
    name: 'createdAt',
    label: 'CreatedAt',
    render: (item) => <Formatted.Date value={item?.createdAt} />,
  },
  {
    name: ContactPropertyKey.Email,
    label: 'E-Mail',
    extractMultipleValues: (item) =>
      item.contactProperties
        ?.filter((p) => p.key === ContactPropertyKey.Email)
        .map((p) => ({ label: p.value, value: p.value })) || [],
  },
  {
    name: ContactPropertyKey.Phone,
    label: 'Telefon',
    render: (item) => (
      <>
        {(item?.contactProperties as ContactProperty[])
          ?.filter(
            (c: ContactProperty) =>
              c.key === ContactPropertyKey.Mobile ||
              c.key === ContactPropertyKey.Phone,
          )
          .map((i, idx) => {
            const phoneCount =
              (item?.contactProperties as ContactProperty[])?.filter(
                (c: ContactProperty) =>
                  c.key === ContactPropertyKey.Mobile ||
                  c.key === ContactPropertyKey.Phone,
              )?.length || 1;

            return (
              <div key={idx}>
                <>{i.value}</>
                {idx < phoneCount - 2 && <>, </>}
              </div>
            );
          })}
      </>
    ),
  },

  { name: 'gender', label: 'Gender', render: (item) => item?.gender },
  {
    name: 'internalNotes',
    label: 'Interne Notizen',
    render: (item) => item?.internalNotes,
  },

  {
    name: 'address',
    label: 'Adresse',
    render: (item) => (
      <>
        {item?.address?.company && <div>{item?.address?.company} /</div>}
        <> {item?.address?.contactPerson}</>
        <div>{item?.address?.streetAndNumber}</div>
        <div>
          {item?.address?.postalCode} {item?.address?.city}
        </div>
        <div>{item?.address?.country}</div>
      </>
    ),
  },
  {
    name: 'invoiceAddress',
    label: 'Rechnungsadresse',
    render: (item) => (
      <>
        {item?.invoiceAddress?.company && (
          <div>{item?.invoiceAddress?.company} /</div>
        )}
        <> {item?.invoiceAddress?.contactPerson}</>
        <div>{item?.invoiceAddress?.streetAndNumber}</div>
        <div>
          {item?.invoiceAddress?.postalCode} {item?.invoiceAddress?.city}
        </div>
        <div>{item?.invoiceAddress?.country}</div>
      </>
    ),
  },
  {
    name: 'companies',
    label: 'Firmen',
    extractMultipleValues: (item) =>
      item.companies?.map((c) => ({ label: c.name, value: c.id })) || [],
  },
];

export const userAttributes: Attribute[] = [
  /* {
    name: 'username',
    label: 'Benutzername',
    render: (item) => item?.user?.username,
  },
  {
    name: 'description',
    label: 'Beschreibung',
    render: (item) => (
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: item?.user?.description || '' }}
      />
    ),
  }, */
];

export const adminAttributes: Attribute[] = [
  /* {
    name: 'roles',
    label: 'Rollen',
    render: (item) =>
      (item?.user as Administrator)?.roles.map((i) => i.name).join(', '),
    canBeMultiple: true,
  }, */
];

export const teacherAttributes: Attribute[] = [
  /* {
    name: 'jobTitle',
    label: 'Berufsbezeichnung',
    render: (item) => (
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: (item?.user as Teacher)?.jobTitle || '',
        }}
      />
    ),
  },
  {
    name: 'subjectArea',
    label: 'Fachbereich',
    render: (item) => (
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: (item?.user as Teacher)?.subjectArea || '',
        }}
      />
    ),
  }, */
];

export function getAttributesByType(type?: RelatedUserType) {
  const attributes = [...personAttributes];
  if (type === 'Administrator') {
    attributes.push(...adminAttributes);
  } else if (type === 'Teacher') {
    attributes.push(...teacherAttributes);
  }
  if (type !== undefined) {
    attributes.push(...userAttributes);
  }
  return attributes;
}
