import {
  FileGroup,
  FilterFileInput,
  RoleType,
} from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { success } from '@/ui/toaster/toast';
import React, {
  ComponentType,
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AvailableEnterpriseDataFragment } from 'shared/resources/resources.generated';
import { useFileUploadModal } from '../file-upload-modal/file-upload-modal.hook';
import {
  FileListDataFragment,
  useFilesLazyQuery,
  useUploadFileMutation,
} from './explorer.generated';

type OpenExplorerOptions = {
  filter?: FilterFileInput;
  withSelection?: boolean;
  withUpload?: boolean;
  onUpload?: UploadHandler;
  onSelect?: SelectHandler;
};
interface ExplorerApi {
  isOpen: boolean;
  open(options?: OpenExplorerOptions): void;
  close(): void;
  toggle(): void;
  onSelect: SelectHandler;
  onUpload: UploadHandler;
  files: FileListDataFragment[];
  filter: FilterFileInput;
  setFilter(filter: FilterFileInput): void;
  _selectHandler: MutableRefObject<SelectHandler | undefined>;
  _uploadHandler: MutableRefObject<UploadHandler | undefined>;
  withSelection: boolean;
  withUpload: boolean;
}

type SelectHandler = (selected: FileListDataFragment) => void;
type UploadHandler = (files: File | File[] | undefined) => void;

const ExplorerContext = createContext<ExplorerApi | undefined>(undefined);

export const ExplorerProvider: ComponentType = ({ children }) => {
  const [state, setState] = useState(false);

  const profile = Profile.use();

  const [filter, setFilter] = useState<FilterFileInput>({});
  const [localFiles, setLocalFiles] = useState<FileListDataFragment[]>([]);
  const [withSelection, setWithSelection] = useState(true);
  const [withUpload, setWithUpload] = useState(false);

  const [loadFiles, { data }] = useFilesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [upload] = useUploadFileMutation();

  const onUploadFile = useCallback(
    async (
      file: File | File[] | undefined,
      enterprise: AvailableEnterpriseDataFragment | undefined,
      group: FileGroup,
      private_: boolean,
    ) => {
      if (file && !Array.isArray(file)) {
        const isSuperAdmin = profile?.roles.find(
          (r) => r.name === RoleType.Super,
        );
        const selectedEnterprise = isSuperAdmin ? enterprise : undefined;

        const result = await upload({
          variables: {
            file,
            input: {
              enterpriseId: selectedEnterprise?.id,
              group,
              private: private_,
            },
          },
        });

        if (result) {
          closeModal();
          success('Die Datei wurde erfolgreich hochgeladen.');
          loadFiles({ variables: { filter } });
        }
      }
    },
    [profile, filter],
  );

  const [modal, { open: openModal, close: closeModal }] = useFileUploadModal({
    onSubmit: onUploadFile,
  });

  const onShowUploadFileModal = useCallback(
    (file: File | File[] | undefined) => {
      openModal(file);
    },
    [],
  );

  const selectHandler = useRef<SelectHandler>();
  const uploadHandler = useRef<UploadHandler>();

  const open = useCallback(
    (options?: OpenExplorerOptions) => {
      if (options?.onUpload !== undefined) {
        uploadHandler.current = options.onUpload;
      } else {
        uploadHandler.current = onShowUploadFileModal;
      }
      if (options?.onSelect !== undefined) {
        selectHandler.current = options.onSelect;
      } else {
        selectHandler.current = undefined;
      }
      setState(true);
      if (options?.filter) {
        setFilter((f) => ({ ...options.filter }));
      }
      if (options?.withSelection !== undefined) {
        setWithSelection(options.withSelection);
      }
      if (options?.withUpload !== undefined) {
        setWithUpload(options?.withUpload);
      } else {
        setWithUpload(true);
      }
    },
    [onShowUploadFileModal],
  );

  const close = useCallback(() => {
    setState(false);
  }, []);

  const toggle = useCallback(() => {
    setState((s) => !s);
  }, []);

  const onSelect = useCallback(
    (selected: any) => {
      if (selectHandler.current) {
        selectHandler.current(selected);
      }
    },
    [selectHandler.current],
  );

  const onUpload = useCallback(
    (files: any | any[]) => {
      if (uploadHandler.current) {
        uploadHandler.current(files);
      }
    },
    [uploadHandler.current],
  );

  useEffect(() => {
    if (data) {
      setLocalFiles(data.adminFiles);
    }
  }, [data?.adminFiles]);

  useEffect(() => {
    if (state) {
      loadFiles({ variables: { filter } });
    }
  }, [state]);

  useEffect(() => {
    loadFiles({ variables: { filter } });
  }, [filter.search, filter.group]);

  const api = useMemo(
    () => ({
      isOpen: state,
      open,
      close,
      toggle,
      onSelect,
      onUpload,
      _selectHandler: selectHandler,
      _uploadHandler: uploadHandler,
      files: localFiles,
      filter,
      setFilter,
      withSelection,
      withUpload,
    }),
    [
      state,
      onSelect,
      selectHandler,
      uploadHandler,
      localFiles,
      filter,
      setFilter,
      withSelection,
      withUpload,
    ],
  );

  useEffect(() => {
    (window as any).openEdiExplorer = open;
    (window as any).closeEdiExplorer = close;
    (window as any).toggleEdiExplorer = toggle;
  }, [open, close, toggle]);

  return (
    <ExplorerContext.Provider value={api}>
      {children}
      {modal}
    </ExplorerContext.Provider>
  );
};

export function useExplorer() {
  const context = useContext(ExplorerContext);

  if (!context) {
    throw new Error('Explorer context not ready');
  }

  return context;
}
