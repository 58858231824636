import classNames from 'classnames';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DraggableData, Rnd, RndResizeCallback } from 'react-rnd';
import styles from './resizable.module.scss';
interface Props {
  className?: string;
  onDrag?: (e: MouseEvent, d: DraggableData) => void;
  onDrop?: (e: MouseEvent, d: DraggableData) => void;
  onResize?: RndResizeCallback;
  onResizeStop?: RndResizeCallback;
  x?: number;
  width?: number;
  snapUnit: number;
  bounds?: string;
}

export const Resizable: ComponentType<Props> = (props) => {
  const {
    bounds,
    className,
    children,
    onDrag,
    onDrop,
    onResize,
    onResizeStop,
    x: xFromProps,
    width,
    snapUnit,
  } = props;
  const [x, setX] = useState(xFromProps || 0);
  const [y, setY] = useState(0);

  const isControlledComponent = useMemo(() => {
    return xFromProps !== undefined;
  }, [xFromProps]);

  const onDragStop = useCallback(
    (e, d) => {
      if (!isControlledComponent) {
        setX(d.x);
      }
      if (onDrop) {
        onDrop(e, d);
      }
    },
    [onDrop, isControlledComponent],
  );

  const onResizeStopAction = useCallback(
    (e, direction, ref, delta, position) => {
      if (!isControlledComponent) {
        setX(position.x);
      }
      if (onResizeStop) {
        onResizeStop(e, direction, ref, delta, position);
      }
    },
    [onResizeStop, isControlledComponent],
  );

  useEffect(() => {
    if (isControlledComponent && xFromProps !== undefined) {
      setX(xFromProps);
    }
  }, [xFromProps, isControlledComponent]);

  return (
    <Rnd
      className={classNames(styles.host, className)}
      position={{ x, y }}
      size={{ width: width ? width : 100, height: '100%' }}
      dragGrid={[snapUnit, 1]}
      resizeGrid={[snapUnit, 1]}
      bounds={bounds}
      onDrag={onDrag as any}
      onDragStop={onDragStop}
      onResize={onResize}
      onResizeStop={onResizeStopAction}
      enableResizing={{
        right: true,
        left: true,
      }}
    >
      {children}
    </Rnd>
  );
};
