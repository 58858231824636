import React, { ComponentType, ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';
import shortid from 'shortid';
import styles from './tool-tip.module.scss';

interface Props {
  title?: string;
  content: ReactNode;
  multiline?: boolean;
}

export const ToolTip: ComponentType<Props> = (props) => {
  const { title, content, multiline } = props;
  const id = shortid();
  return (
    <div className={styles.host} data-tip data-for={id}>
      {props.children}
      <ReactTooltip id={id} place="bottom" multiline={multiline}>
        {title && <div className={styles.title}>{title}</div>}
        {content}
      </ReactTooltip>
    </div>
  );
};
