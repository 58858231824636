import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type TransactionMailTemplateListDataFragment = { __typename?: 'TransactionMailTemplate', id: any, name: string, subject: string, actions: Array<string> };

export type TransactionMailTemplatesQueryVariables = Types.Exact<{
  enterpriseId: Types.Scalars['UUID'];
  pagination: Types.PaginationInput;
}>;


export type TransactionMailTemplatesQuery = { __typename?: 'Query', adminTransactionMailTemplates: { __typename?: 'PaginatedTransactionMailTemplateResult', totalCount: number, data: Array<{ __typename?: 'TransactionMailTemplate', id: any, name: string, subject: string, actions: Array<string> }> } };

export const TransactionMailTemplateListDataFragmentDoc = gql`
    fragment TransactionMailTemplateListData on TransactionMailTemplate {
  id
  name
  subject
  actions
}
    `;
export const TransactionMailTemplatesDocument = gql`
    query transactionMailTemplates($enterpriseId: UUID!, $pagination: PaginationInput!) {
  adminTransactionMailTemplates(
    enterpriseId: $enterpriseId
    pagination: $pagination
  ) {
    data {
      ...TransactionMailTemplateListData
    }
    totalCount
  }
}
    ${TransactionMailTemplateListDataFragmentDoc}`;
export type TransactionMailTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TransactionMailTemplatesQuery, TransactionMailTemplatesQueryVariables>, 'query'> & ({ variables: TransactionMailTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TransactionMailTemplatesComponent = (props: TransactionMailTemplatesComponentProps) => (
      <ApolloReactComponents.Query<TransactionMailTemplatesQuery, TransactionMailTemplatesQueryVariables> query={TransactionMailTemplatesDocument} {...props} />
    );
    

/**
 * __useTransactionMailTemplatesQuery__
 *
 * To run a query within a React component, call `useTransactionMailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionMailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionMailTemplatesQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTransactionMailTemplatesQuery(baseOptions: Apollo.QueryHookOptions<TransactionMailTemplatesQuery, TransactionMailTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionMailTemplatesQuery, TransactionMailTemplatesQueryVariables>(TransactionMailTemplatesDocument, options);
      }
export function useTransactionMailTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionMailTemplatesQuery, TransactionMailTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionMailTemplatesQuery, TransactionMailTemplatesQueryVariables>(TransactionMailTemplatesDocument, options);
        }
export type TransactionMailTemplatesQueryHookResult = ReturnType<typeof useTransactionMailTemplatesQuery>;
export type TransactionMailTemplatesLazyQueryHookResult = ReturnType<typeof useTransactionMailTemplatesLazyQuery>;
export type TransactionMailTemplatesQueryResult = Apollo.QueryResult<TransactionMailTemplatesQuery, TransactionMailTemplatesQueryVariables>;