// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ed6bs5TZaWboNx0FgaXB .LTyrRgUgFKTCJcs3d5gZ{display:flex;justify-content:flex-end;margin-bottom:1rem}.ed6bs5TZaWboNx0FgaXB .JOBGyt_MEmZ_VBvAJVAA .ngSbTrb8NgmrPxZDNJJw{position:absolute;top:4px;right:12px}", "",{"version":3,"sources":["webpack://./src/course-management/course-unit/overview/course-unit-list/course-unit-list.module.scss"],"names":[],"mappings":"AAEE,4CACE,YAAA,CACA,wBAAA,CACA,kBAAA,CAGA,kEACE,iBAAA,CACA,OAAA,CACA,UAAA","sourcesContent":["@import 'styles/variables';\n.host {\n  .actions {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 1rem;\n  }\n  .unit {\n    .create {\n      position: absolute;\n      top: $space-unit * 0.5;\n      right: $space-unit * 1.5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "ed6bs5TZaWboNx0FgaXB",
	"actions": "LTyrRgUgFKTCJcs3d5gZ",
	"unit": "JOBGyt_MEmZ_VBvAJVAA",
	"create": "ngSbTrb8NgmrPxZDNJJw"
};
export default ___CSS_LOADER_EXPORT___;
