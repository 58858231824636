import {
  getPersonDescription,
  getPersonDetailLink,
} from '@/contact/person/utils';
import { Frame } from '@/frame';
import { DropdownMenu } from '@/ui/dropdown-menu/dropdown-menu.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { SelectField } from '@/ui/select-field/select-field.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { useResources } from 'shared/resources/resources.provider';
import {
  ConsultingEventsForListQuery,
  useConsultingEventsForListQuery,
} from './list.generated';

type FilterType = {
  mode: 'past' | 'upcoming';
  consultantId?: string | null;
};

export const ConsultingEventList: ComponentType<RouteComponentProps> = (
  props,
) => {
  const [query] = usePaginatedUrlQuery<FilterType>();

  const { availableConsultants } = useResources();

  const { loading, data } = useConsultingEventsForListQuery({
    fetchPolicy: 'network-only',
    variables: {
      pagination: getPaginationFromQuery(query),
      input: {
        mode: query.mode || 'upcoming',
        consultantId: query.consultantId,
      },
    },
  });

  return (
    <>
      <Frame.SubTitle>Beratungstermine</Frame.SubTitle>
      {/* <Frame.ActionBar>
      <Button linkTo="/customers/create" label="Neuer Kunde" />
    </Frame.ActionBar> */}
      <Frame.Content>
        <ListView<
          ConsultingEventsForListQuery['adminConsultingEvents']['data'][number],
          FilterType
        >
          loading={loading}
          data={data?.adminConsultingEvents.data || []}
          renderFilter={({ onChange, value }) => (
            <>
              <SelectField
                choices={[
                  { label: 'Geplant', value: 'upcoming' },
                  { label: 'Vergangen', value: 'past' },
                ]}
                label="Auswahl"
                value={value.mode}
                onChange={(value) => {
                  onChange('mode', value);
                }}
              />
              <SelectField
                nullable
                nullLabel="Alle"
                choices={availableConsultants.map((t) => ({
                  value: t.user?.id,
                  label: getPersonDescription(t),
                }))}
                label="Berater/in"
                value={value.consultantId}
                onChange={(value) => {
                  onChange('consultantId', value);
                }}
              />
            </>
          )}
          totalCount={data?.adminConsultingEvents.totalCount || 0}
          columns={[
            {
              name: 'Datum',
              sortable: true,
            },

            {
              name: 'Kunde',
              sortable: true,
            },
            {
              name: 'Berater',
              sortable: false,
            },

            { name: 'Aktionen', sortable: false },
          ]}
          /* renderFilter={({ value, onChange }) => (
            <DebouncedInput
              debounceRate={400}
              value={value?.query || ''}
              onChange={(value) => onChange('query', value)}
              placeholder={'Suche…'}
            />
          )} */
          renderRow={(event) => {
            const detailLink = `/event-management/events/${event.id}/detail`;
            const personLink = event.attendee
              ? getPersonDetailLink(event.attendee)
              : '';
            const consultantLink = event.consultant
              ? `/contacts/administrators/${event.consultant.personId}/edit`
              : '';
            return (
              <Row key={event.id}>
                <td>
                  <Link to={detailLink}>
                    <Formatted.Date value={event.startDate} withTime />
                  </Link>
                </td>
                <td>
                  <Link to={personLink}>
                    {event.attendee && getPersonDescription(event.attendee)}
                  </Link>
                </td>
                <td>
                  <Link to={consultantLink}>
                    {event.consultant && event.consultant.fullName}
                  </Link>
                </td>
                <td>
                  <DropdownMenu
                    actions={[
                      {
                        label: 'Zum Termin',
                        href: detailLink,
                      },
                    ]}
                  />
                </td>
              </Row>
            );
          }}
        />
      </Frame.Content>
    </>
  );
};
