import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { useContextMenu } from '@/ui/context-menu/context-menu.hook';
import { Grid } from '@/ui/grid/grid.component';
import { Loader } from '@/ui/loader/loader.component';
import { notice, success } from '@/ui/toaster/toast';
import { useApolloClient } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import classNames from 'classnames';
import { format, formatISO } from 'date-fns';
import React, { ComponentType, ReactElement, useEffect, useState } from 'react';
import { DateHeaderProps, EventWrapperProps } from 'react-big-calendar';
import { EventCalendar } from 'shared/event-calendar/event-calendar.component';
import {
  EventCalendarProvider,
  useEventCalendar,
} from 'shared/event-calendar/event-calendar.context';
import {
  EnhancedEventInterface,
  EventListProvider,
  useEventList,
} from '../context/event.context';
import {
  ConsultingEventsForCalendarQuery,
  useConsultingEventsForCalendarQuery,
  useCopyConsultingEventMutation,
  useDeleteConsultingEventMutation,
} from './calendar.generated';
import styles from './calendar.module.scss';
import { ClipboardProvider, useClipboard } from './clipboard/clipboard.context';
import { Creator } from './creator/creator.component';

const renderTitle = (
  event: ConsultingEventsForCalendarQuery['adminConsultingEventsForCalendar'][number] &
    EnhancedEventInterface,
) => {
  const label =
    event.description ||
    (event.__typename === 'ConsultingEvent' ? 'Beratung' : 'Termin');
  if (event.__typename === 'ConsultingEvent') {
    return `${label} (${event.consultant?.fullName || 'Alle'})`;
  }
  return label;
};

const renderTooltip = (
  event: ConsultingEventsForCalendarQuery['adminConsultingEventsForCalendar'][number] &
    EnhancedEventInterface,
) => {
  const time = format(event.startDate, 'HH:mm');
  const moreInfo =
    event.__typename === 'ConsultingEvent' && event.attendee
      ? `Gebucht von: ${event.attendee.firstName} ${event.attendee.lastName}`
      : '';
  return `${time} ${moreInfo}`;
};

const EventWrapperComponent: ComponentType<
  EventWrapperProps<EnhancedEventInterface & { attendee: any }>
> = (props) => {
  const { copy } = useClipboard();
  const [deleteEvent] = useDeleteConsultingEventMutation();
  const client = useApolloClient();
  const [menu, { show, close }] = useContextMenu((context) => (
    <>
      <Button
        onClick={(e) => {
          copy(context);
          e.stopPropagation();
          e.preventDefault();
          close();
          notice('Termin in Zwischenablage');
        }}
        label="Kopieren"
      />
      {!context.attendee && (
        <Button
          error
          onClick={async (e) => {
            await deleteEvent({ variables: { id: context.id } });
            client.refetchQueries({ include: 'active' });
            e.stopPropagation();
            e.preventDefault();
            close();
            success('Der Termin wurde gelöscht');
          }}
          label="Löschen"
        />
      )}
    </>
  ));
  const { event, children } = props;
  const enhancedChildren = React.cloneElement(children as ReactElement, {
    onContextMenu: (e) => {
      e.preventDefault();
      show(e.clientX, e.clientY, event);
    },
    className: classNames(
      (children as ReactElement).props.className,
      styles.eventWrapper,
      event.isPreview && styles.isPreview,
      event.attendee && styles.hasAttendee,
    ),
  });
  return (
    <>
      {menu}
      {enhancedChildren}
    </>
  );
};

const DateHeaderComponent: ComponentType<DateHeaderProps> = ({
  label,
  onDrillDown,
  date,
}) => {
  const { paste, state } = useClipboard();
  const client = useApolloClient();
  const [duplicateEvent] = useCopyConsultingEventMutation();
  const [menu, { show, close }] = useContextMenu((context) =>
    state ? (
      <Button
        onClick={async (e) => {
          await duplicateEvent({
            variables: {
              input: {
                id: paste(),
                day: formatISO(context),
              },
            },
          });
          e.stopPropagation();
          e.preventDefault();
          close();

          client.refetchQueries({ include: 'active' });
          success('Der Termin wurde kopiert.');
        }}
        label="Einfügen"
      />
    ) : null,
  );
  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        show(e.clientX, e.clientY, date);
      }}
    >
      {menu}
      <a href="#" onClick={onDrillDown}>
        {label}
      </a>
    </div>
  );
};

interface InnerComponentProps {
  creatorVisible: boolean;
  setCreatorVisible: (toggle: boolean) => void;
}

const InnerComponent: ComponentType<InnerComponentProps> = (props) => {
  const { creatorVisible, setCreatorVisible } = props;
  const { paste, state } = useClipboard();

  const [slotMenu, { show: showSlotMenu }] = useContextMenu((context) =>
    state ? <Button onClick={() => paste()} label="Einfügen" /> : <></>,
  );

  const { events, setEvents } =
    useEventList<
      ConsultingEventsForCalendarQuery['adminConsultingEventsForCalendar'][number]
    >();

  const { range } = useEventCalendar();

  const startDate = range.startDate
    ? formatISO(range.startDate)
    : range.endDate
    ? formatISO(range.endDate)
    : undefined;
  const endDate = range.endDate ? formatISO(range.endDate) : undefined;

  const eventsQuery = useConsultingEventsForCalendarQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        startDate: startDate,
        endDate: endDate,
      },
    },
  });

  useEffect(() => {
    if (eventsQuery.data) {
      setEvents(
        eventsQuery.data.adminConsultingEventsForCalendar.map((e: any) => ({
          ...e,
          startDate: new Date(e.startDate),
          endDate: new Date(e.endDate),
        })),
      );
    }
  }, [eventsQuery.data]);

  if (creatorVisible) {
    return (
      <Grid.Row>
        <Grid.Column smaller>
          <Creator
            onCreate={() => {
              setCreatorVisible(false);
              eventsQuery.refetch();
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <EventCalendar
            events={events}
            renderTitle={renderTitle}
            renderTooltip={renderTooltip}
            dateHeader={DateHeaderComponent}
            eventWrapper={EventWrapperComponent}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }

  return (
    <>
      {slotMenu}
      {eventsQuery.loading && <Loader />}
      <EventCalendar
        eventWrapper={EventWrapperComponent}
        dateHeader={DateHeaderComponent}
        events={events}
        renderTitle={renderTitle}
        renderTooltip={renderTooltip}
      />
    </>
  );
};

export const Calendar: ComponentType<RouteComponentProps> = (props) => {
  const [creatorVisible, setCreatorVisible] = useState(false);
  return (
    <>
      <Frame.SubTitle>Beratungstermine</Frame.SubTitle>
      <Frame.ActionBar>
        {!creatorVisible && (
          <Button
            onClick={() => setCreatorVisible(true)}
            primary
            label="Zeitfenster anlegen"
          />
        )}
        {creatorVisible && (
          <Button onClick={() => setCreatorVisible(false)} label="Abbrechen" />
        )}
      </Frame.ActionBar>
      <Frame.Content>
        <EventCalendarProvider>
          <EventListProvider>
            <ClipboardProvider>
              <InnerComponent
                creatorVisible={creatorVisible}
                setCreatorVisible={setCreatorVisible}
              />
            </ClipboardProvider>
          </EventListProvider>
        </EventCalendarProvider>
      </Frame.Content>
    </>
  );
};
