import {
  CustomerFinder,
  CustomerItemType,
} from '@/contact/person/customer-finder/customer-finder.component';
import { useCustomDialogue } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import React, { useState } from 'react';
import {
  CourseUnitFinder,
  CourseUnitOrModuleItemType,
} from 'shared/course/course-unit-finder/course-unit-finder.component';
import { useCreateBookingMutation } from './create-booking.generated';

export function useCreateBookingModal(onCreate: () => void) {
  const [selectedCourseUnitOrModule, setSelectedCourseUnitOrModule] =
    useState<CourseUnitOrModuleItemType | null>();
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerItemType | null>();

  const [createBooking] = useCreateBookingMutation();
  const [modal, { open, close }] = useCustomDialogue(
    <>
      <Section title="1. Kurs-Einheit wählen">
        <CourseUnitFinder
          value={selectedCourseUnitOrModule}
          onChange={setSelectedCourseUnitOrModule}
        />
      </Section>
      <Section title="2. Kunde:in wählen">
        <CustomerFinder
          value={selectedCustomer}
          onChange={setSelectedCustomer}
        />
      </Section>
    </>,
    {
      title: 'Neue Buchung',
      closeOnAction: false,
      actions: [
        {
          title: 'Abbrechen',
          handler: () => {
            close();
          },
        },
        {
          handler: async () => {
            try {
              if (selectedCustomer && selectedCourseUnitOrModule) {
                await createBooking({
                  variables: {
                    id: selectedCourseUnitOrModule?.value,
                    customerId: selectedCustomer?.customerId,
                    bookingType:
                      selectedCourseUnitOrModule?.type === 'CourseModule'
                        ? 'CourseModuleBooking'
                        : 'CourseUnitBooking',
                  },
                });
                success('Die Buchung wurde erfolgreich durchgeführt.');
                close();
                onCreate();
              } else {
                error('Bitte wähle Kund:in und Kurs-Einheit/-modul!');
              }
            } catch (e) {
              error('Bei der Buchung ist ein Fehler aufgetreten.');
            }
          },
          title: 'Buchung erstellen',
          primary: true,
        },
      ],
    },
  );
  return { modal, open };
}
