import { Person } from '@/graphql/generated/types';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { error, success } from '@/ui/toaster/toast';
import React, { useCallback } from 'react';
import { getPersonDescription } from '../utils';
import {
  useAnonymizePersonMutation,
  useDeletePersonMutation,
} from './actions.generated';

export function usePersonActions(
  callback: (action: 'anonymize' | 'delete') => void,
) {
  const [deletePerson] = useDeletePersonMutation();
  const [anonymizePerson] = useAnonymizePersonMutation();
  const [modalDelete, askDelete] = useConfirm(
    async (id: string) => {
      try {
        await deletePerson({
          variables: {
            id,
          },
        });
        success('Die Person und alle verknüpften Elemente wurden gelöscht.');
        callback('delete');
      } catch (e: any) {
        console.error(e);
        error('Beim Löschen ist ein Fehler aufgetreten.', e.message);
      }
    },
    { meaning: 'error', title: 'Person löschen' },
  );
  const [modalAnonymize, askAnonymize] = useConfirm(
    async (id: string) => {
      try {
        await anonymizePerson({
          variables: {
            id,
          },
        });
        success(
          'Die Person wurde anonymisiert',
          'Die Person und verknüpfte Objekte wurden vollständig anonymisiert. Diese Person wird in der Übersicht nicht mehr gelistet.',
        );
        callback('anonymize');
      } catch (e: any) {
        console.error(e);
        error('Beim Anonymisieren ist ein Fehler aufgetreten.', e.message);
      }
    },
    {
      meaning: 'error',
      title: 'Person löschen (anonymisieren)',
    },
  );

  const askForAnonymize = useCallback(
    (
      person: Pick<
        Person,
        'id' | 'firstName' | 'lastName' | 'gender' | 'title'
      >,
    ) => {
      askAnonymize(
        <>
          <p>
            Mit dieser Aktion wird die Person datenschutzkonform aus der
            Datenbank entfernt bzw. alle personenbezogenen Daten gelöscht.
            Buchungen, Bestellungen u.a. bleiben erhalten. Statistiken werden
            nicht verändert.
          </p>
          <p>
            Möchtest du die Person <em>{getPersonDescription(person)}</em>{' '}
            löschen (anonymisieren)?
          </p>
        </>,
        person.id,
      );
    },
    [],
  );

  const askForDelete = useCallback(
    (
      person: Pick<
        Person,
        'id' | 'firstName' | 'lastName' | 'gender' | 'title'
      >,
    ) => {
      askDelete(
        <>
          <p>
            Alle verknüpften Bestellungen, Buchungen und Anfragen werden
            ebenfalls gelöscht.
          </p>
          <p>Diese Aktion lässt sich nicht rückgängig machen!</p>
          <p>
            Statistiken werden durch diese Aktion beeinflusst. Wenn du lediglich
            die Person / Kund:in löschen, ausblenden und datenschutzkonform
            anonymisieren möchtest, verwende die andere Methode.
          </p>
          <p>
            Bist du sicher, dass du die Person{' '}
            <em>{getPersonDescription(person)}</em> löschen möchtest?
          </p>
        </>,

        person.id,
      );
    },
    [],
  );
  return { modalAnonymize, modalDelete, askForAnonymize, askForDelete };
}
