import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { TagDataFragmentDoc } from '../../../tag/list/list.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CourseUnitNodeDataFragment = {
  __typename?: 'CourseUnit';
  id: any;
  title?: string | null;
  subTitle?: string | null;
  status: Types.CourseUnitStatus;
  startDate: any;
  endDate: any;
  withTime: boolean;
  dateUncertain: boolean;
  online: boolean;
  correspondenceCourse: boolean;
  tagIds?: Array<any> | null;
  seasonId?: any | null;
  location?: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    commonName: string;
  } | null;
  searchableLocation?: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    commonName: string;
  } | null;
  modules: Array<{
    __typename?: 'CourseModule';
    id: any;
    title?: string | null;
    subTitle?: string | null;
    startDate: any;
    endDate: any;
    status: Types.CourseModuleStatus;
    tagIds?: Array<any> | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
  }>;
  tags?: Array<{
    __typename?: 'Tag';
    id: any;
    name: string;
    slug?: string | null;
    context?: string | null;
    enterpriseId: any;
    enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
  }> | null;
  season?: {
    __typename?: 'Season';
    id: any;
    status: Types.SeasonStatus;
    title: string;
  } | null;
};

export type CourseWithCourseUnitsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  options?: Types.InputMaybe<Types.CourseUnitSearchInput>;
}>;

export type CourseWithCourseUnitsQuery = {
  __typename?: 'Query';
  adminCourse: {
    __typename?: 'Course';
    title: string;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
    courseUnitsForAdmin: Array<{
      __typename?: 'CourseUnit';
      id: any;
      title?: string | null;
      subTitle?: string | null;
      status: Types.CourseUnitStatus;
      startDate: any;
      endDate: any;
      withTime: boolean;
      dateUncertain: boolean;
      online: boolean;
      correspondenceCourse: boolean;
      tagIds?: Array<any> | null;
      seasonId?: any | null;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        commonName: string;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        commonName: string;
      } | null;
      modules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        subTitle?: string | null;
        startDate: any;
        endDate: any;
        status: Types.CourseModuleStatus;
        tagIds?: Array<any> | null;
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      }>;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
      season?: {
        __typename?: 'Season';
        id: any;
        status: Types.SeasonStatus;
        title: string;
      } | null;
    }>;
  };
};

export const CourseUnitNodeDataFragmentDoc = gql`
  fragment CourseUnitNodeData on CourseUnit {
    id
    title
    subTitle
    status
    startDate
    endDate
    withTime
    dateUncertain
    location {
      id
      name
      city
      commonName
    }
    searchableLocation {
      id
      name
      city
      commonName
    }
    online
    correspondenceCourse
    modules {
      id
      title
      subTitle
      startDate
      endDate
      status
      tags {
        ...TagData
      }
      tagIds
    }
    tags {
      ...TagData
    }
    tagIds
    seasonId
    season {
      id
      status
      title
    }
  }
  ${TagDataFragmentDoc}
`;
export const CourseWithCourseUnitsDocument = gql`
  query courseWithCourseUnits($id: UUID!, $options: CourseUnitSearchInput) {
    adminCourse(id: $id) {
      title
      tags {
        ...TagData
      }
      courseUnitsForAdmin(options: $options) {
        ...CourseUnitNodeData
      }
    }
  }
  ${TagDataFragmentDoc}
  ${CourseUnitNodeDataFragmentDoc}
`;
export type CourseWithCourseUnitsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CourseWithCourseUnitsQuery,
    CourseWithCourseUnitsQueryVariables
  >,
  'query'
> &
  (
    | { variables: CourseWithCourseUnitsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const CourseWithCourseUnitsComponent = (
  props: CourseWithCourseUnitsComponentProps,
) => (
  <ApolloReactComponents.Query<
    CourseWithCourseUnitsQuery,
    CourseWithCourseUnitsQueryVariables
  >
    query={CourseWithCourseUnitsDocument}
    {...props}
  />
);

/**
 * __useCourseWithCourseUnitsQuery__
 *
 * To run a query within a React component, call `useCourseWithCourseUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseWithCourseUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseWithCourseUnitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCourseWithCourseUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseWithCourseUnitsQuery,
    CourseWithCourseUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseWithCourseUnitsQuery,
    CourseWithCourseUnitsQueryVariables
  >(CourseWithCourseUnitsDocument, options);
}
export function useCourseWithCourseUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseWithCourseUnitsQuery,
    CourseWithCourseUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseWithCourseUnitsQuery,
    CourseWithCourseUnitsQueryVariables
  >(CourseWithCourseUnitsDocument, options);
}
export type CourseWithCourseUnitsQueryHookResult = ReturnType<
  typeof useCourseWithCourseUnitsQuery
>;
export type CourseWithCourseUnitsLazyQueryHookResult = ReturnType<
  typeof useCourseWithCourseUnitsLazyQuery
>;
export type CourseWithCourseUnitsQueryResult = Apollo.QueryResult<
  CourseWithCourseUnitsQuery,
  CourseWithCourseUnitsQueryVariables
>;
