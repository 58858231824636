import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type ErrorLog = {
  date: Date;
  message: string;
};

interface ErrorContextApi {
  errors: ErrorLog[];
  log: (message: string) => void;
}

const ErrorContext = createContext<ErrorContextApi | undefined>(undefined);

export const ErrorProvider: ComponentType = (props) => {
  const [errors, setErrors] = useState<ErrorLog[]>([]);

  const log = useCallback((message: string) => {
    setErrors((e) => [...e, { date: new Date(), message }]);
  }, []);

  return (
    <ErrorContext.Provider value={{ errors, log }}>
      {props.children}
    </ErrorContext.Provider>
  );
};

export function useErrorLog() {
  const context = useContext(ErrorContext);

  if (!context) {
    throw new Error('ErrorContext not ready.');
  }

  return context as ErrorContextApi;
}
