import { Frame } from '@/frame';
import { ConsentAuditLog, ContactPropertyKey } from '@/graphql/generated/types';
import { Chip } from '@/ui/chip/chip.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { useConsentAuditLogsQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const _ = Translator.useTranslator();
  const [query] = usePaginatedUrlQuery();
  const { data, loading } = useConsentAuditLogsQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.Content>
        <ListView<ConsentAuditLog>
          loading={loading}
          data={(data?.adminAuditLogs.data as any) || []}
          totalCount={data?.adminAuditLogs.totalCount || 0}
          columns={[
            {
              name: 'Datum',
              sortable: true,
            },
            {
              name: 'Status',
              sortable: true,
            },
            {
              name: 'Person',
              sortable: true,
            },
            {
              name: 'Kategorie',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              {/* <Filter
              value={value || ''}
              onChange={onChange}
              placeholder={'Suche…'}
              loading={loading}
            /> */}
            </>
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Formatted.Date value={item.createdAt} withTime />
              </td>
              <td>
                {item.consent && item.verifiedAt && !item.verificationToken && (
                  <Chip meaning="success">Eingewilligt</Chip>
                )}
                {item.consent && !item.verifiedAt && item.verificationToken && (
                  <Chip meaning="warning">
                    Double Opt-In noch nicht abgeschlossen
                  </Chip>
                )}
                {!item.consent && <Chip meaning="error">Widerrufen</Chip>}
              </td>
              <td>
                {item.person.firstName} {item.person.lastName}
                <br />
                {
                  item.person.contactProperties.find(
                    (c) => c.key === ContactPropertyKey.Email,
                  )?.value
                }
              </td>
              <td>{_(`audit-log-handle.${item.consentHandle}`)}</td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
