import { getPersonDescription } from '@/contact/person/utils';
import { FilterInquiriesInput, InquiryStatus } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Chip } from '@/ui/chip/chip.component';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { DropdownMenu } from '@/ui/dropdown-menu/dropdown-menu.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { HelpPopup } from '@/ui/help-popup/help-popup.component';
import { SelectField } from '@/ui/select-field/select-field.component';
import { Link, RouteComponentProps } from '@reach/router';
import { format } from 'date-fns';
import { pick } from 'lodash-es';
import { stringify } from 'query-string';
import React, { ComponentType } from 'react';
import { API_URI } from 'shared/constants';
import { getLocationLabel } from 'shared/course/utils';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import globalStyles from 'styles/global.scss';
import { Frame } from '../../frame';
import { ListView } from '../../ui/list-view/list-view.component';
import { Row } from '../../ui/table/row.component';
import { useInquiryActions } from '../shared/actions.hooks';
import { useAssigneeActions } from '../shared/assignee-actions.component';
import { InquiryStatusView } from '../shared/status.component';
import { InquiryDataFragment, useInquiriesQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery<FilterInquiriesInput>();

  const { data, loading, refetch } = useInquiriesQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      filter: {
        ...pick(query, ['startDate', 'endDate', 'kind', 'status', 'query']),
      },
    },
    fetchPolicy: 'network-only',
  });

  const { actionsForInquiry } = useAssigneeActions(refetch);

  const _ = Translator.useTranslator();
  const { modalDelete, askForDelete } = useInquiryActions(refetch);
  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>

      <Frame.ActionBar>
        <Button
          label="CSV Export (genesis)"
          href={`${API_URI}/export/v1/bannas.genesis-inquiries?${stringify(
            query,
          )}`}
        />
        <HelpPopup hover="Der CSV Export ist an die Anforderungen der Bannas Steuerlehrgänge angepasst. Alle Leads inklusive Anfrage können im Leads-Modul allgemein per CSV exportiert werden." />
      </Frame.ActionBar>
      <Frame.Content>
        {modalDelete}
        <ListView<InquiryDataFragment, FilterInquiriesInput>
          loading={loading}
          data={data?.adminInquiries?.data || []}
          totalCount={data?.adminInquiries?.totalCount}
          columns={[
            {
              name: 'Eingegangen am',
              sortable: true,
            },
            {
              name: 'Person',
              sortable: true,
            },
            {
              name: 'Thema',
              sortable: true,
            },
            {
              name: 'Herkunft',
              sortable: true,
            },
            {
              name: 'Art',
              sortable: true,
            },

            { name: 'Status', sortable: true },
            { name: 'Aktionen', sortable: true },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              <DebouncedInput
                debounceRate={400}
                label="Suche"
                placeholder="Thema oder Person"
                value={value.query || ''}
                onChange={(v) => onChange('query', v)}
              />
              <SelectField
                label="Nach Art filtern"
                value={value.kind}
                choices={[
                  {
                    value: null,
                    label: 'Alle',
                  },
                  {
                    value: 'Inquiry',
                    label: _(`inquiry-kind.Inquiry`),
                  },
                  {
                    value: 'DownloadInquiry',
                    label: _(`inquiry-kind.DownloadInquiry`),
                  },
                  {
                    value: 'LocationInquiry',
                    label: _(`inquiry-kind.LocationInquiry`),
                  },
                  {
                    value: 'CourseInquiry',
                    label: _(`inquiry-kind.CourseInquiry`),
                  },
                  {
                    value: 'CourseUnitInquiry',
                    label: _(`inquiry-kind.CourseUnitInquiry`),
                  },
                  {
                    value: 'CourseModuleInquiry',
                    label: _(`inquiry-kind.CourseModuleInquiry`),
                  },
                ]}
                onChange={(value) => onChange('kind', value)}
              />
              <DatetimeField
                white
                label="Anfangs-Datum"
                value={value.startDate}
                withTime={false}
                onChange={(value) => {
                  onChange(
                    'startDate',
                    value ? format(value, 'Y-MM-dd') : null,
                  );
                }}
              />
              <DatetimeField
                white
                label="End-Datum"
                value={value.endDate}
                withTime={false}
                onChange={(value) =>
                  onChange('endDate', value ? format(value, 'Y-MM-dd') : null)
                }
              />
              <SelectField
                label="Nach Status filtern"
                value={value.status}
                choices={[
                  {
                    value: null,
                    label: 'Alle',
                  },
                  {
                    value: InquiryStatus.Open,
                    label: _(`inquiry-status.${InquiryStatus.Open}`),
                  },
                  {
                    value: InquiryStatus.InProgress,
                    label: _(`inquiry-status.${InquiryStatus.InProgress}`),
                  },
                  {
                    value: InquiryStatus.Closed,
                    label: _(`inquiry-status.${InquiryStatus.Closed}`),
                  },
                ]}
                onChange={(value) => onChange('status', value)}
              />
            </>
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/edit`}>
                  <Formatted.Date value={item.createdAt} withTime />
                </Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>
                  {getPersonDescription(item.person)}
                </Link>
                {item.person?.user && (
                  <div>
                    Kunde:
                    <Link to={`/customers/${item.person.user.id}/edit`}>
                      {item.person?.firstName} {item.person?.lastName}
                    </Link>{' '}
                  </div>
                )}
              </td>

              <td>
                <Link to={`../${item.id}/edit`}>{item.subject}</Link>
                {item.__typename === 'LocationInquiry' && (
                  <>
                    <br />
                    {getLocationLabel(item.location)}
                  </>
                )}
              </td>
              <td className={globalStyles.small}>{item.origin}</td>
              <td>
                <Chip>{_(`inquiry-kind.${item.__typename}`)}</Chip>
              </td>

              <td>
                <InquiryStatusView inquiry={item} />
              </td>
              <td>
                <DropdownMenu
                  actions={[
                    ...actionsForInquiry(item),
                    {
                      label: 'Löschen',
                      meaning: 'error',
                      onClick: () => askForDelete(item),
                    },
                  ]}
                />
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
