import { getPersonDescription } from '@/contact/person/utils';
import { Collapsible } from '@/ui/collapsible/collapsible.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Section } from '@/ui/section/section.component';
import { Row } from '@/ui/table/row.component';
import { Table } from '@/ui/table/table.component';
import { Link } from '@reach/router';
import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { CourseUnitNodeTitle } from 'shared/course/course-unit-list/course-unit-node-title.component';
import {
  getCourseUnitDates,
  getCourseUnitLocationLabel,
} from 'shared/course/utils';
import { CourseWithCourseUnitsAndBookingsQuery } from '../course-unit-list/course-unit-list.generated';
import styles from './course-unit-participants.module.scss';

interface CourseUnitParticipantsProps {
  courseTitle: string;
  courseUnit: CourseWithCourseUnitsAndBookingsQuery['adminCourse']['courseUnitsForAdmin'][number];
}

export const CourseUnitParticipants: ComponentType<
  CourseUnitParticipantsProps
> = (props) => {
  const { courseUnit, courseTitle } = props;
  return (
    <Section
      className={styles.host}
      title={`${getCourseUnitDates(courseUnit)} (${getCourseUnitLocationLabel(
        courseUnit,
      )})`}
    >
      <CourseUnitNodeTitle courseUnit={courseUnit} />
      <Collapsible label={`${courseUnit.bookings.length} Teilnehmer`}>
        <Table
          compact
          columns={['Name', 'Gebucht am']}
          render={(booking) => (
            <Row className={classNames(booking.confirmedAt && 'bg-success')}>
              <td>
                <Link to={`../../bookings/${booking.id}/view`}>
                  {getPersonDescription(booking.customer.person)}
                </Link>
              </td>
              <td>
                <Link to={`../../bookings/${booking.id}/view`}>
                  <Formatted.Date value={booking.createdAt} withTime />
                </Link>
              </td>
            </Row>
          )}
          data={courseUnit.bookings}
        />
      </Collapsible>
    </Section>
  );
};
