import { usePersonsQuery } from '@/contact/person/list/list.generated';
import { durationSchema } from '@/duration/duration.schema';
import { useUploadFileMutation } from '@/file/explorer/explorer.generated';
import { Frame } from '@/frame';
import {
  Course,
  CourseCategory,
  CourseStatus,
  CreateCourseInput,
  FileGroup,
  UpdateCourseInput,
} from '@/graphql/generated/types';
import { useAdminTagsQuery } from '@/tag/list/list.generated';
import { Button } from '@/ui/button/button.component';
import { CopyId } from '@/ui/copy-id/copy-id.component';
import { Form, FormError } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { HtmlField } from '@/ui/html-field/html-field.component';
import { ImageChooser } from '@/ui/image-chooser/image-chooser.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { InputField } from '@/ui/input-field/input-field.component';
import { JsonField } from '@/ui/json-field/json-field.component';
import { Loader } from '@/ui/loader/loader.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { SelectField2 } from '@/ui/select-field-2/select-field-2.component';
import { Tabs } from '@/ui/tabs/tabs.component';
import { error, success } from '@/ui/toaster/toast';
import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from '@reach/router';
import filter from 'object-key-filter';
import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getAttributeValueKeyByKind } from 'shared/attributes/attributes.helper';
import {
  PropertyAware,
  PropertySchemaRenderer,
} from 'shared/property-schema/property-schema.component';
import { useYupPropertySchema } from 'shared/property-schema/property-schema.hooks';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { array, boolean, date, number, object, string } from 'yup';
import {
  generateCourseQuery,
  generateCreateCourseMutation,
  generateUpdateCourseMutation,
} from './edit-course-data.query';
import {
  InitialCourseDataFragment,
  useDeleteCourseMutation,
  useUpdateCourseFilesMutation,
} from './editor.generated';

interface EditProps {
  mode: 'create' | 'update';
  course?: InitialCourseDataFragment;
  attributeSets: string[];
}

function getParentCategoryPathRecursively(
  category: Pick<CourseCategory, 'id' | 'parentId' | 'title'>,
  categories: Pick<CourseCategory, 'id' | 'parentId' | 'title'>[],
) {
  const parentCategory = category.parentId
    ? categories.find((c) => c.id === category.parentId)
    : undefined;
  if (!parentCategory) {
    return category.title;
  }

  return `${getParentCategoryPathRecursively(parentCategory, categories)} > ${
    category.title
  }`;
}

const schema = object({
  status: string().required(),
  purchasable: boolean(),
  eligible: boolean(),
  abbreviation: string().required(),
  title: string().required().min(1),
  subTitle: string().nullable(),
  slug: string(),
  approximateDuration: durationSchema,
  approximateClassHours: number().min(0).nullable(),
  earlyBirdDate: date().nullable(),
  earlyBirdNotice: string().nullable(),
  infoCourse: boolean(),
  modulePriceMatrix: string().nullable(),
  tagIds: array().of(string()),
  position: number().min(0).required(),
  taxRate: number().required(),
  teacherIds: array().of(string().uuid().required()),
});

const createSchema = object({
  status: string(),
  purchasable: boolean(),
  eligible: boolean(),
  abbreviation: string().required(FormError.required),
  title: string().required(FormError.required).min(1),
  subTitle: string().nullable(),
  categoryId: string().required(FormError.required),
  slug: string(),
  approximateDuration: durationSchema,
  approximateClassHours: number().min(0).nullable(),
  earlyBirdDate: date().nullable(),
  earlyBirdNotice: string().nullable(),
  infoCourse: boolean(),
  modulePriceMatrix: string().nullable(),
  tagIds: array().of(string()),
  position: number().integer().required(),
  taxRate: number().required(),
  teacherIds: array().of(string().uuid().required()),
});

export const Editor: ComponentType<EditProps> = (props) => {
  const { attributeSets, course, mode } = props;
  const _ = Translator.useTranslator();
  const properties = useYupPropertySchema(
    course?.category.enterprise.id,
    'course',
  );

  const {
    availableCourseCategories,
    reload,
    availableAdministrators,
    taxRates,
  } = useResources();

  const tagsQuery = useAdminTagsQuery({
    variables: { pagination: { take: 999, skip: 0 } },
  });

  const { data: teachersQuery } = usePersonsQuery({
    variables: {
      pagination: { skip: 0, take: 10000 },
      withDuplicates: true,
      filter: { userType: 'Teacher' },
    },
    fetchPolicy: 'network-only',
  });

  const availableCourseCategoryLeaves = useMemo(() => {
    return availableCourseCategories
      .filter((c) => c.isLeaf)
      .map((c) => ({
        ...c,
        categoryPath: getParentCategoryPathRecursively(
          c,
          availableCourseCategories,
        ),
      }));
  }, [availableCourseCategories]);

  const [loadCourse, courseQuery] = useLazyQuery(
    generateCourseQuery(attributeSets),
    {
      fetchPolicy: 'network-only',
      variables: { id: course?.id },
    },
  );

  const [update] = useMutation(generateUpdateCourseMutation(attributeSets));
  const [updateCourseFiles] = useUpdateCourseFilesMutation();
  const [uploadFile] = useUploadFileMutation();
  const [create] = useMutation(generateCreateCourseMutation(attributeSets));
  const [remove] = useDeleteCourseMutation();

  const form = useForm<UpdateCourseInput & CreateCourseInput & PropertyAware>({
    resolver: yupResolver(
      course?.id ? schema.concat(properties) : createSchema.concat(properties),
    ),
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      status: CourseStatus.Active,
      purchasable: true,
      eligible: false,
      taxRate: 0,
      approximateClassHours: null,
      earlyBirdDate: null,
      earlyBirdNotice: null,
      infoCourse: false,
      attributes: [],
      tagIds: [],
      position: 0,
      categoryId:
        availableCourseCategoryLeaves.length > 0
          ? availableCourseCategoryLeaves[0].id
          : undefined,
    },
  });

  const [confirmModal, confirmDelete] = useConfirm(async (course: Course) => {
    await remove({ variables: { id: course.id } });
    navigate('../list');
    success('Der Kurs wurde gelöscht.');
  });

  const attributeSetFieldArrays = {};

  const onSubmit = useMemo(() => {
    return form.handleSubmit(
      async (input) => {
        try {
          if (course) {
            await update({
              variables: {
                id: course.id,
                input: {
                  ...input,
                  attributes: input.attributes,
                },
              },
            });
            await reload();
            success('Kurs wurde gespeichert.');
          } else {
            const newOne = await create({
              variables: {
                input: { ...input, attributes: [] },
              },
            });
            await reload();
            navigate(`${newOne.data.adminCreateCourse.id}/edit`);
            success('Kurs wurde erstellt.');
          }
        } catch (e) {
          error('Fehler beim Speichern');
        }
      },
      (e) => {
        error('Ein Fehler ist aufgetreten.');
      },
    );
  }, [course, form.handleSubmit]);

  const onChangeImage = useCallback(
    async (selected: { id: string; name: string; url: string } | null) => {
      if (course?.id) {
        await updateCourseFiles({
          variables: {
            id: course.id,
            input: { imageId: selected?.id || null },
          },
        });
        if (!selected) {
          success('Das Kurs-Bild wurde vom Kurs entfernt.');
        } else {
          success('Der Kurs wurde mit einem neuen Bild verknüpft.');
        }
      }
    },
    [course?.id],
  );

  const persisted = !!course?.id;

  const onDelete = useCallback(async () => {
    try {
      if (courseQuery.data?.adminCourse) {
        confirmDelete(
          'Soll dieser Kurs wirklich gelöscht werden?',
          courseQuery.data?.adminCourse,
        );
      }
    } catch (e) {
      error(
        'Fehler',
        'Der Kurs kann nicht gelöscht werden, da Kurstermine für diesen Kurs existieren.',
      );
    }
  }, [courseQuery.data?.adminCourse]);

  for (const set of attributeSets) {
    attributeSetFieldArrays[set] = useFieldArray<any>({
      control: form.control,
      name: set,
    });
  }

  const cleanCourseData = useMemo(() => {
    if (courseQuery.data?.adminCourse) {
      return filter(courseQuery.data.adminCourse, ['__typename'], true);
    }
    return undefined;
  }, [courseQuery.data]);

  const onUploadImage = useCallback(
    async (file) => {
      if (course?.category) {
        const result = await uploadFile({
          variables: {
            file,
            input: {
              enterpriseId: course.category.enterprise.id,
              group: FileGroup.Course,
            },
          },
        });

        if (result.data) {
          await updateCourseFiles({
            variables: {
              id: course.id,
              input: { imageId: result.data?.adminUploadFile.id },
            },
          });
          success('Das Kurs-Bild wurde erfolgreich gespeichert..');
        } else {
          error('Beim Hochladen ist ein Fehler aufgetreten.');
        }
      }
    },
    [course?.category, course?.id],
  );

  const tagIds = form.watch('tagIds');
  const teacherIds = form.watch('teacherIds');

  useEffect(() => {
    if (cleanCourseData?.tagIds !== undefined) {
      form.setValue('tagIds', cleanCourseData.tagIds);
    }

    if (cleanCourseData?.teacherIds !== undefined) {
      form.setValue('teacherIds', cleanCourseData.teacherIds);
    }
  }, [cleanCourseData?.tagIds]);

  useEffect(() => {
    if (course) {
      loadCourse();
    }
  }, [course]);

  if (mode === 'update' && !courseQuery.data?.adminCourse) {
    return <Loader />;
  }

  return (
    <>
      {confirmModal}
      <Frame.SubTitle>
        {mode === 'create' && <>Neuer Kurs</>}
        {mode === 'update' && <>Kurs: {courseQuery.data?.adminCourse.title}</>}
      </Frame.SubTitle>

      <Frame.ActionBar left={<CopyId id={courseQuery.data?.adminCourse.id} />}>
        {course && (
          <Button warning transparent label="Kurs löschen" onClick={onDelete} />
        )}
        <Button label="Zurück" linkTo="/course-management/courses/list" />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={cleanCourseData}>
          <Grid.Row>
            <Grid.Column>
              <Section title="Kerndaten">
                <Form.Input name="abbreviation" label="Kürzel" />

                <Form.Input name="title" label="Titel" />
                <Form.Input name="subTitle" label="Untertitel (kurz)" />

                <Form.Select
                  label="Status"
                  name="status"
                  choices={[
                    { label: 'Aktiv', value: CourseStatus.Active },
                    { label: 'Inaktiv', value: CourseStatus.Inactive },
                  ]}
                />

                <SelectField2
                  multiple
                  label="Tags"
                  value={tagIds}
                  onChange={(value) => {
                    form.setValue('tagIds', value ? (value as string[]) : []);
                  }}
                  choices={
                    tagsQuery.data?.adminTags.data.map((t) => ({
                      value: t.id,
                      label: t.name,
                    })) || []
                  }
                />

                <Form.Input type="number" name="position" label="Position" />

                <Form.Input
                  name="slug"
                  label="Slug"
                  placeholder="Freilassen um automatisch generieren zu lassen"
                />

                <Form.Select
                  name="categoryId"
                  nullable
                  label="Kategorie"
                  defaultValue={
                    availableCourseCategoryLeaves.length > 0
                      ? availableCourseCategoryLeaves[0].id
                      : undefined
                  }
                  choices={
                    availableCourseCategoryLeaves.map((courseCategory) => ({
                      value: courseCategory.id,
                      label: `${courseCategory.categoryPath} (${courseCategory.enterprise?.name})`,
                    })) || []
                  }
                />
                <Form.Html name="description" label="(Kurz-)beschreibung" />

                <SelectField2
                  multiple
                  label="Dozenten"
                  value={teacherIds}
                  onChange={(value) => {
                    form.setValue(
                      'teacherIds',
                      value ? (value as string[]) : [],
                    );
                  }}
                  choices={
                    teachersQuery?.persons.data.map((t) => ({
                      value: t.user?.id,
                      label: `${t.firstName} ${t.lastName}`,
                    })) || []
                  }
                />
              </Section>
              <Section title="Bilder">
                {!persisted && (
                  <InfoBox header="Der Datensatz muss zuerst gespeichert werden." />
                )}
                {persisted && (
                  <ImageChooser
                    explorerFilter={{ group: FileGroup.Course }}
                    label="Kurs-Bild"
                    disabled={false}
                    loading={false}
                    currentImageUrl={courseQuery.data?.adminCourse.image?.url}
                    onChange={onUploadImage}
                    onSelect={onChangeImage}
                  />
                )}
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section title="Zeiten">
                <Form.Duration
                  name="approximateDuration"
                  label="Zeitraum"
                  defaultFactor={7}
                />

                <Form.Input
                  type="number"
                  name="approximateClassHours"
                  label="Dauer (Unterrichtsstunden)"
                />
              </Section>
              <Section title="Kauf und Preisgestaltung">
                <Form.Checkbox
                  label="direkt buchbar über Shop/Website"
                  name="purchasable"
                />
                <Form.Checkbox
                  label="Es besteht Fördermöglichkeit"
                  name="eligible"
                />
                <Form.Select
                  label="Steuersatz"
                  name="taxRate"
                  choices={taxRates.map((rate) => ({
                    label: `${Math.round(rate * 100)} %`,
                    value: rate.toString(),
                  }))}
                />
                <Form.Date
                  name="earlyBirdDate"
                  label="Frühbucher Rabatt gültig bis"
                />
                <Form.Input
                  name="earlyBirdNotice"
                  label="Frühbucher Rabatt Hinweise"
                  placeholder="Frühbucherpreis bis {{date}}"
                />
                <Form.Textarea
                  name="modulePriceMatrix"
                  label="Preismatrix für evtl. in Kurseinheiten enthaltene Module"
                />
              </Section>

              <Section title="Info-/Sonderveranstaltungen">
                <Form.Checkbox
                  name="infoCourse"
                  label="Dieser Kurs ist eine Info-VA"
                />
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Section title="Attribute (Benutzerdefinierte Felder v1)" collapsible>
            <Tabs
              tabs={Object.keys(attributeSetFieldArrays).map((key) => ({
                title: _(`attribute.set.${key}`),
                content: attributeSetFieldArrays[key].fields.map(
                  (field, index) => (
                    <div key={field.id}>
                      <Controller
                        render={(data) => (
                          <input
                            type="hidden"
                            {...data.field}
                            value={field.name}
                          />
                        )}
                        defaultValue={field.name}
                        name={`attributes.${index}.name`}
                        control={form.control}
                      />
                      <Controller
                        render={(data) => (
                          <>
                            {field.kind === 'TextAttribute' && (
                              <HtmlField
                                {...data.field}
                                value={data.field.value as string}
                                label={_(`attribute.course.${field.name}`)}
                              />
                            )}
                            {field.kind === 'JsonAttribute' && (
                              <>
                                {field.view === 'CategoryPicker' && (
                                  <SelectField2
                                    {...data.field}
                                    value={data.field.value as string}
                                    multiple={true}
                                    label={_(`attribute.course.${field.name}`)}
                                    choices={
                                      availableCourseCategoryLeaves.map(
                                        (courseCategory) => ({
                                          value: courseCategory.id,
                                          label: `${courseCategory.categoryPath} (${courseCategory.enterprise?.name})`,
                                        }),
                                      ) || []
                                    }
                                  />
                                )}
                                {field.view !== 'CategoryPicker' && (
                                  <JsonField
                                    {...data.field}
                                    value={data.field.value as string}
                                    label={_(`attribute.course.${field.name}`)}
                                  />
                                )}
                              </>
                            )}
                            {field.kind === 'IntAttribute' && (
                              <InputField
                                type="number"
                                {...data.field}
                                value={data.field.value as string}
                                label={_(`attribute.course.${field.name}`)}
                              />
                            )}
                            {field.kind === 'StringAttribute' && (
                              <>
                                {field.view === 'AdministratorPicker' && (
                                  <SelectField2
                                    {...data.field}
                                    value={data.field.value as string}
                                    multiple={false}
                                    label={_(`attribute.course.${field.name}`)}
                                    choices={availableAdministrators.map(
                                      (a) => ({
                                        label: `${a.firstName} ${a.lastName} (${a.user?.username})`,
                                        value: a[field.valueProperty],
                                      }),
                                    )}
                                  />
                                )}

                                {field.view === 'SimplePicker' && (
                                  <SelectField2
                                    {...data.field}
                                    value={data.field.value as string}
                                    multiple={false}
                                    label={_(`attribute.course.${field.name}`)}
                                    choices={field.values.map((value) => ({
                                      label: _(
                                        `attribute.course.${field.name}.option.${value}`,
                                      ),
                                      value,
                                    }))}
                                  />
                                )}

                                {!field.view && (
                                  <InputField
                                    {...data.field}
                                    value={data.field.value as string}
                                    label={_(`attribute.course.${field.name}`)}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                        name={`attributes.${index}.${getAttributeValueKeyByKind(
                          field.kind,
                        )}`}
                        control={form.control}
                        defaultValue={
                          field[getAttributeValueKeyByKind(field.kind)]
                        }
                      />
                    </div>
                  ),
                ),
              }))}
            />
          </Section>
          {course?.category.enterprise.id && (
            <Section
              title="Benutzerdefinierte Felder (v2)"
              collapsible
              collapsed
            >
              <PropertySchemaRenderer
                enterpriseId={course?.category.enterprise.id}
                context="course"
              />
            </Section>
          )}
        </Form>
      </Frame.Content>
    </>
  );
};
