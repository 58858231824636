import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { Section } from '@/ui/section/section.component';
import { useTabs } from '@/ui/tabs/tabs.provider';
import { useUserStatusChoices } from '@/user/status/user-status-choices';
import React, { ComponentType } from 'react';
import { useResources } from 'shared/resources/resources.provider';
import { PersonQuery } from '../base/base-editor.generated';

type CustomerEditorProps = {
  user?: PersonQuery['person']['user'];
};

export const CustomerEditor: ComponentType<CustomerEditorProps> = (props) => {
  const { user } = props;
  const { highlightTabsWithFields, setHighlightedIndizes } = useTabs();

  const { availableEnterprises, reload } = useResources();

  return (
    <Grid.Row>
      <Grid.Column>
        <Section title="User">
          <Form.Input name="user.username" label="Benutzername" />
        </Section>
        <Section title="Rechte">
          <Form.Select
            name="user.status"
            label="Status"
            choices={useUserStatusChoices()}
          />
        </Section>
      </Grid.Column>
    </Grid.Row>
  );
};
