import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import MonacoEditor from '@monaco-editor/react';
import { RouteComponentProps, navigate } from '@reach/router';
import Ajv from 'ajv';
import React, { ComponentType, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import globalStyles from 'styles/global.scss';
import { addMethod, array, object, string } from 'yup';
import { usePropertySchemaQuery } from '../list/list.generated';
import { usePersistPropertySchemaMutation } from './editor.generated';

const ajv = new Ajv();

declare module 'yup' {
  interface StringSchema {
    jsonschema(): StringSchema;
  }
}

addMethod(string, 'jsonschema', function (errorMessage) {
  return this.test('test-json-schema', errorMessage, function (value) {
    const { path, createError } = this;

    try {
      ajv.compile(JSON.parse(value ?? ' '));

      return true;
    } catch (error: any) {
      return createError({ path, message: errorMessage || error.message });
    }
  });
});

const schema = object({
  name: string().required('Der Name is ein Pflichtfeld.').min(1),
  contexts: array(string()).required(),
  value: string()
    .required('Das Schema ist ein Pflichtfeld')
    .jsonschema()
    .min(1),
});

type TransactionMailTemplateEditorParams = {
  id?: string;
  propertySchemaId?: string;
};

interface Props {
  enterprise?: { id: string; name: string };
}

export const Editor: ComponentType<
  RouteComponentProps<TransactionMailTemplateEditorParams> & Props
> = (props) => {
  const schemaQuery = usePropertySchemaQuery({
    variables: {
      propertySchemaId: props.propertySchemaId,
      enterpriseId: props.id,
    },
    skip: props.propertySchemaId === undefined || props.id === undefined,
  });

  const _ = Translator.useTranslator();

  const [save] = usePersistPropertySchemaMutation();

  const form = useForm<any>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
    defaultValues: {
      value: `{
  "title": "Benutzerdefinierte Felder",
  "description": "",
  "type": "object",
  "properties": {
    "feldName": {
      "description": "Feldbeschreibung",
      "type": "integer"
    }
  },
  "required": ["feldName"]
}`,
    },
  });

  const { reload } = useResources();

  const onSubmit = useMemo(() => {
    return form.handleSubmit(
      async (input) => {
        try {
          const saved = await save({
            variables: {
              id: props.propertySchemaId,
              enterpriseId: props.id,
              ...input,
            },
          });
          reload();
          success(
            props.propertySchemaId
              ? 'Die Vorlage wurde gespeichert.'
              : 'Die Vorlage wurde erstellt.',
          );
          if (!props.propertySchemaId) {
            navigate(`./${saved.data?.adminPersistPropertySchema.id}/edit`);
          }
        } catch (e) {
          error('Beim Speichern der Vorlage ist ein Fehler aufgetreten.');
        }
      },
      (e) => {
        error('Ein Fehler ist aufgetreten.');
        console.error(e);
      },
    );
  }, [props.id, form.handleSubmit]);

  const persisted =
    props.propertySchemaId !== undefined || schemaQuery.data !== undefined;

  return (
    <>
      <Frame.Title>Benutzerdefinierte Felder</Frame.Title>

      <Frame.SubTitle>
        {persisted
          ? schemaQuery.data?.adminPropertySchema.name
          : 'Neue Vorlage'}
      </Frame.SubTitle>
      <Frame.ActionBar>
        <Button
          label="Zurück"
          linkTo={`/enterprises/${props.id}/property-schemas/`}
        />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={schemaQuery.data?.adminPropertySchema}>
          <Grid.Row>
            <Grid.Column>
              <Form.Input name="name" label="Name" />
              <Form.Select
                label="Bereiche"
                multiple
                name="contexts"
                choices={[
                  { label: 'Kurse', value: 'course' },
                  { label: 'Kurs-Einheiten', value: 'course-unit' },
                  { label: 'Kurs-Kategorien', value: 'course-category' },
                  { label: 'Standorte', value: 'location' },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label>Schema</label>
              <Controller
                control={form.control}
                name="value"
                render={({ field, fieldState }) => (
                  <>
                    <MonacoEditor
                      height={400}
                      language="json"
                      theme="vs-light"
                      {...field}
                      beforeMount={(monaco) => {
                        monaco.languages.json.jsonDefaults.setDiagnosticsOptions(
                          {
                            enableSchemaRequest: true,
                            validate: true,
                            schemas: [
                              {
                                uri: 'https://json-schema.org/draft-07/schema',
                                fileMatch: ['*'],
                              },
                            ],
                          },
                        );
                      }}
                      options={{
                        inlineSuggest: { enabled: true },
                        fontSize: 12,
                        formatOnType: true,
                        formatOnPaste: true,
                        autoClosingBrackets: 'always',
                        minimap: { enabled: false },
                        automaticLayout: true,
                        fixedOverflowWidgets: true,
                      }}
                    />
                    {fieldState.error && (
                      <p className={globalStyles.error}>
                        {fieldState.error.message}
                      </p>
                    )}
                  </>
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
