import { useUploadFileMutation } from '@/file/explorer/explorer.generated';
import { Frame } from '@/frame';
import { FileGroup } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { ImageChooser } from '@/ui/image-chooser/image-chooser.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { boolean, object, string } from 'yup';
import { SubNav } from '../sub-nav.component';
import {
  useCreateEnterpriseMutation,
  useEditEnterpriseMutation,
  useEnterpriseQuery,
  useUpdateEnterpriseFilesMutation,
} from './editor.generated';

type Values = {
  name: string;
  slug: string | null;
  isAdmin: boolean;
};

const schema = object({
  name: string().required('Der Name is ein Pflichtfeld.').min(1),
  slug: string().required('Der Slug is ein Pflichtfeld.').min(1),
  isAdmin: boolean(),
});

type EnterpriseEditorParams = {
  id?: string;
};

interface Props {
  enterprise?: { id: string; name: string };
}

export const Editor: ComponentType<
  RouteComponentProps<EnterpriseEditorParams> & Props
> = (props) => {
  const enterpriseQuery = useEnterpriseQuery({
    variables: { id: props.id },
    skip: props.id === undefined,
  });

  const [save] = useEditEnterpriseMutation();
  const [updateEnterpriseFiles] = useUpdateEnterpriseFilesMutation();
  const [uploadFile] = useUploadFileMutation();
  const [create] = useCreateEnterpriseMutation();
  const form = useForm<Values>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const { reload } = useResources();

  const onSubmit = useMemo(() => {
    return form.handleSubmit(
      async (input) => {
        try {
          if (props.id) {
            await save({
              variables: {
                id: props.id,
                input,
              },
            });
            reload();
            success('Das Unternehmen wurde gespeichert.');
          } else {
            const enterprise = await create({
              variables: {
                input,
              },
            });
            reload();
            navigate(`./${enterprise.data?.adminCreateEnterprise.id}/edit`);
            success('Das Unternehmen wurde erstellt.');
          }
        } catch (e) {
          error('Fehler beim Speichern');
        }
      },
      (e) => {
        error('Ein Fehler ist aufgetreten.');
        console.error(e);
      },
    );
  }, [props.id, form.handleSubmit]);

  const onUploadImage = useCallback(
    async (file) => {
      if (enterpriseQuery.data?.adminEnterprise.id) {
        const result = await uploadFile({
          variables: {
            file,
            input: {
              enterpriseId: enterpriseQuery.data?.adminEnterprise.id,
              group: FileGroup.Enterprise,
            },
          },
        });

        if (result.data) {
          await updateEnterpriseFiles({
            variables: {
              id: props.id,
              input: { logoId: result.data?.adminUploadFile.id },
            },
          });
          success('Das Unternehmens-Logo wurde erfolgreich gespeichert..');
          enterpriseQuery.refetch();
        } else {
          error('Beim Hochladen ist ein Fehler aufgetreten.');
        }
      }
    },
    [props.id, enterpriseQuery.data],
  );

  const onChangeImage = useCallback(
    async (selected: { id: string; name: string; url: string } | null) => {
      if (props.id) {
        await updateEnterpriseFiles({
          variables: {
            id: props.id,
            input: { logoId: selected?.id || null },
          },
        });
        if (!selected) {
          success('Das Logo wurde vom Datensatz entfernt.');
        } else {
          success('Das Unternehmen wurde mit einem neuen Logo verknüpft.');
        }
      }
    },
    [props.id],
  );

  const persisted =
    props.id !== undefined || enterpriseQuery.data !== undefined;

  return (
    <>
      <Frame.Title>Unternehmen</Frame.Title>

      <Frame.SubTitle>
        {persisted
          ? enterpriseQuery.data?.adminEnterprise.name
          : 'Neues Unternehmen'}
      </Frame.SubTitle>
      {props.id && <SubNav current="edit" />}
      <Frame.ActionBar>
        <Button label="Zurück" linkTo="/enterprises" />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={enterpriseQuery.data?.adminEnterprise}>
          <Grid.Row>
            <Grid.Column>
              <Section title="Unternehmen">
                <Form.Input name="name" label="Name" />
                <Form.Input name="slug" label="Slug / Name in Urls" />
                {persisted && (
                  <ImageChooser
                    label="Logo"
                    explorerFilter={{ group: FileGroup.Enterprise }}
                    disabled={false}
                    loading={false}
                    currentImageUrl={
                      enterpriseQuery.data?.adminEnterprise.logo?.url
                    }
                    onChange={onUploadImage}
                    onSelect={onChangeImage}
                  />
                )}
                {!persisted && (
                  <InfoBox header="Der Datensatz muss zuerst gespeichert werden." />
                )}
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section title="Administration">
                <p>
                  (Nur) ein Unternehmen sollte "administrative" Zwecke erfüllen,
                  zum Beispiel das Versenden von Transaktionsmails zwischen
                  edi-Usern.
                </p>
                <Form.Checkbox name="isAdmin" label="Administrator" />
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
