import { Frame } from '@/frame';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { RouteComponentProps } from '@reach/router';
import { format } from 'date-fns';
import React, { ComponentType } from 'react';
import { useErrorLog } from './error.provider';

export const ErrorList: ComponentType<RouteComponentProps> = (props) => {
  const { errors } = useErrorLog();
  return (
    <Frame.Content>
      <ListView
        data={errors}
        renderRow={(item) => (
          <Row key={item.date.toString()}>
            <td>{format(item.date, 'dd.MM.yy HH:mm:ss')}</td>
            <td>{item.message}</td>
          </Row>
        )}
        columns={[
          { sortable: true, name: 'Datum' },
          { sortable: false, name: 'Fehler' },
        ]}
      />
    </Frame.Content>
  );
};
