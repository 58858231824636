import { PersonView } from '@/contact/person/view/person-view.component';
import { Frame } from '@/frame';
import { Profile } from '@/profile/profile';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { LabeledItem } from '@/ui/labeled-item/labeled-item.component';
import { Loader } from '@/ui/loader/loader.component';
import { Section } from '@/ui/section/section.component';
import { Textarea } from '@/ui/textarea/textarea.component';
import { error, success } from '@/ui/toaster/toast';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { useAvailableCoursesQuery } from '../creator/creator.generated';
import {
  useConsultingEventQuery,
  useUpdateConsultingEventMutation,
} from './detail.generated';

interface Values {
  selectedSubjects: string[];
  availableSubjects: string[];
}
interface DetailRouteParams {
  id: string;
}

export const Detail: ComponentType<RouteComponentProps<DetailRouteParams>> = (
  props,
) => {
  const profile = Profile.use();

  const { availableLocations, availableConsultants } = useResources();

  const availableCoursesQuery = useAvailableCoursesQuery({
    skip: !profile?.selectedEnterprise,
    variables: { enterpriseId: profile?.selectedEnterprise?.id },
  });

  const consultingEventQuery = useConsultingEventQuery({
    fetchPolicy: 'network-only',
    variables: { id: props.id },
    skip: props.id === undefined,
  });

  const form = useForm<Values>({
    shouldUnregister: true,
    defaultValues: {
      selectedSubjects: [],
      availableSubjects: [],
    },
  });

  const [update] = useUpdateConsultingEventMutation();

  const availableSubjects = form.watch('availableSubjects') || [];

  const onSubmit = useMemo(
    () =>
      form.handleSubmit(
        async (data) => {
          try {
            await update({ variables: { id: props.id, input: data as any } });
            success('Der Termin wurde aktualisiert.');
          } catch (e) {
            error('Beim Speichern ist ein Fehler aufgetreten.');
          }
        },
        (e) => {
          error('Beim Speichern ist ein Fehler aufgetreten.');
        },
      ),
    [props.id],
  );

  useEffect(() => {
    if (consultingEventQuery.data?.adminConsultingEvent) {
      form.setValue(
        'availableSubjects',
        consultingEventQuery.data.adminConsultingEvent.availableSubjects,
      );
    }
  }, [consultingEventQuery.data?.adminConsultingEvent]);
  return (
    <>
      <Frame.Content>
        {!consultingEventQuery.loading && !consultingEventQuery.data && (
          <Loader header="Dieser Termin ist für dich nicht zugänglich." />
        )}
        <Form
          form={form}
          values={consultingEventQuery.data?.adminConsultingEvent}
        >
          <Grid.Row>
            <Grid.Column>
              <Section title="Terminangebot" heightened>
                <Form.Date withTime name="startDate" label="Start" />
                <Form.Date withTime name="endDate" label="Ende" />
                <Form.Select
                  name="consultantId"
                  label="Berater/in"
                  nullable
                  choices={availableConsultants.map((person) => ({
                    label: `${person.firstName} ${person.lastName}`,
                    value: person.user?.id,
                  }))}
                />
                <Textarea
                  slim
                  white
                  label="Wählbare Themen (pro Zeile)"
                  value={availableSubjects.join('\n')}
                  onChange={(e) => {
                    form.setValue(
                      'availableSubjects',
                      e.currentTarget.value
                        ? e.currentTarget.value.split('\n')
                        : [],
                    );
                  }}
                />
                <Form.Select2
                  name="availableLocationIds"
                  label="Wählbare Standorte"
                  multiple
                  nullable
                  choices={availableLocations.map((l) => ({
                    label: l.commonName,
                    value: l.id,
                  }))}
                />
                <Form.Select2
                  name="availableCourseIds"
                  label="Wählbare Kurse"
                  multiple
                  nullable
                  choices={
                    availableCoursesQuery.data?.courses.map((l) => ({
                      label: l.verboseTitle || l.title,
                      value: l.id,
                    })) || []
                  }
                />
                <Form.Html name="description" label="Beschreibung" />
              </Section>
              <Button primary label="Speichern" onClick={onSubmit} />
            </Grid.Column>
            <Grid.Column>
              <Section title="Kunde/Kundin">
                <PersonView
                  person={
                    consultingEventQuery.data?.adminConsultingEvent.attendee
                  }
                />
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section title="Angaben bei Buchung">
                <LabeledItem
                  label="Gewählte Themen"
                  value={
                    <ul>
                      {consultingEventQuery.data?.adminConsultingEvent.selectedSubjects.map(
                        (s, i) => (
                          <li key={i}>{s}</li>
                        ),
                      )}
                    </ul>
                  }
                />
                <LabeledItem
                  label="Gewählte Kurse"
                  value={
                    <ul>
                      {consultingEventQuery.data?.adminConsultingEvent.selectedCourseIds.map(
                        (id) => (
                          <li key={id}>
                            {availableCoursesQuery.data?.courses.find(
                              (c) => c.id === id,
                            )?.title || id}
                          </li>
                        ),
                      )}
                    </ul>
                  }
                />
                <LabeledItem
                  label="Gewählte Standorte"
                  value={
                    <ul>
                      {consultingEventQuery.data?.adminConsultingEvent.selectedLocationIds.map(
                        (id) => (
                          <li key={id}>
                            {availableLocations.find((l) => l.id === id)
                              ?.commonName || id}
                          </li>
                        ),
                      )}
                    </ul>
                  }
                />

                <LabeledItem
                  label="Kommentar"
                  value={
                    consultingEventQuery.data?.adminConsultingEvent
                      .attendeeComment
                  }
                />
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
