import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type OrderStatisticsQueryVariables = Types.Exact<{
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrderStatisticsQuery = { __typename?: 'Query', statRecords: Array<{ __typename?: 'InquiryStatRecord', id: any, kind: string, createdAt: any } | { __typename?: 'OrderStatRecord', id: any, kind: string, createdAt: any, orderItemId?: any | null, orderItemType?: Types.StatRecordOrderItemType | null, quantity: number, amount: number, productId?: any | null, productTitle?: string | null, productAttributes?: Array<string> | null, bookingOptions?: any | null, courseId?: any | null, courseTitle?: string | null, courseUnitId?: any | null, courseUnitTitle?: string | null, courseModuleId?: any | null, courseModuleTitle?: string | null, courseCategoryIds?: Array<any> | null, courseCategoryTitle?: string | null, locationId?: any | null, locationName?: string | null, discountRuleId?: any | null, discountRule?: { __typename?: 'DiscountRule', id: any, title?: string | null, amount: number, type: Types.DiscountType, voucherCode?: string | null } | null }> };


export const OrderStatisticsDocument = gql`
    query orderStatistics($startDate: String, $endDate: String) {
  statRecords(
    filter: {kind: "OrderStatRecord", startDate: $startDate, endDate: $endDate}
  ) {
    ... on StatRecord {
      id
      kind
      createdAt
    }
    ... on OrderStatRecord {
      orderItemId
      orderItemType
      quantity
      amount
      productId
      productTitle
      productAttributes
      bookingOptions
      courseId
      courseTitle
      courseUnitId
      courseUnitTitle
      courseModuleId
      courseModuleTitle
      courseCategoryIds
      courseCategoryTitle
      locationId
      locationName
      discountRule {
        id
        title
        amount
        type
        voucherCode
      }
      discountRuleId
    }
  }
}
    `;
export type OrderStatisticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrderStatisticsQuery, OrderStatisticsQueryVariables>, 'query'>;

    export const OrderStatisticsComponent = (props: OrderStatisticsComponentProps) => (
      <ApolloReactComponents.Query<OrderStatisticsQuery, OrderStatisticsQueryVariables> query={OrderStatisticsDocument} {...props} />
    );
    

/**
 * __useOrderStatisticsQuery__
 *
 * To run a query within a React component, call `useOrderStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatisticsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useOrderStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<OrderStatisticsQuery, OrderStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderStatisticsQuery, OrderStatisticsQueryVariables>(OrderStatisticsDocument, options);
      }
export function useOrderStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderStatisticsQuery, OrderStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderStatisticsQuery, OrderStatisticsQueryVariables>(OrderStatisticsDocument, options);
        }
export type OrderStatisticsQueryHookResult = ReturnType<typeof useOrderStatisticsQuery>;
export type OrderStatisticsLazyQueryHookResult = ReturnType<typeof useOrderStatisticsLazyQuery>;
export type OrderStatisticsQueryResult = Apollo.QueryResult<OrderStatisticsQuery, OrderStatisticsQueryVariables>;