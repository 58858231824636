import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { TagDataFragmentDoc } from '../../list/list.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AdminTagQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type AdminTagQuery = {
  __typename?: 'Query';
  adminTag: {
    __typename?: 'Tag';
    id: any;
    name: string;
    slug?: string | null;
    context?: string | null;
    enterpriseId: any;
    enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
  };
};

export type AdminEditTagMutationVariables = Types.Exact<{
  input: Types.TagInput;
  id: Types.Scalars['UUID'];
}>;

export type AdminEditTagMutation = {
  __typename?: 'Mutation';
  editTag: {
    __typename?: 'Tag';
    id: any;
    name: string;
    slug?: string | null;
    context?: string | null;
    enterpriseId: any;
    enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
  };
};

export type AdminCreateTagMutationVariables = Types.Exact<{
  input: Types.TagInput;
}>;

export type AdminCreateTagMutation = {
  __typename?: 'Mutation';
  createTag: {
    __typename?: 'Tag';
    id: any;
    name: string;
    slug?: string | null;
    context?: string | null;
    enterpriseId: any;
    enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
  };
};

export const AdminTagDocument = gql`
  query adminTag($id: UUID!) {
    adminTag(id: $id) {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;
export type AdminTagComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminTagQuery,
    AdminTagQueryVariables
  >,
  'query'
> &
  ({ variables: AdminTagQueryVariables; skip?: boolean } | { skip: boolean });

export const AdminTagComponent = (props: AdminTagComponentProps) => (
  <ApolloReactComponents.Query<AdminTagQuery, AdminTagQueryVariables>
    query={AdminTagDocument}
    {...props}
  />
);

/**
 * __useAdminTagQuery__
 *
 * To run a query within a React component, call `useAdminTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminTagQuery(
  baseOptions: Apollo.QueryHookOptions<AdminTagQuery, AdminTagQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminTagQuery, AdminTagQueryVariables>(
    AdminTagDocument,
    options,
  );
}
export function useAdminTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminTagQuery,
    AdminTagQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminTagQuery, AdminTagQueryVariables>(
    AdminTagDocument,
    options,
  );
}
export type AdminTagQueryHookResult = ReturnType<typeof useAdminTagQuery>;
export type AdminTagLazyQueryHookResult = ReturnType<
  typeof useAdminTagLazyQuery
>;
export type AdminTagQueryResult = Apollo.QueryResult<
  AdminTagQuery,
  AdminTagQueryVariables
>;
export const AdminEditTagDocument = gql`
  mutation adminEditTag($input: TagInput!, $id: UUID!) {
    editTag(input: $input, id: $id) {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;
export type AdminEditTagMutationFn = Apollo.MutationFunction<
  AdminEditTagMutation,
  AdminEditTagMutationVariables
>;
export type AdminEditTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AdminEditTagMutation,
    AdminEditTagMutationVariables
  >,
  'mutation'
>;

export const AdminEditTagComponent = (props: AdminEditTagComponentProps) => (
  <ApolloReactComponents.Mutation<
    AdminEditTagMutation,
    AdminEditTagMutationVariables
  >
    mutation={AdminEditTagDocument}
    {...props}
  />
);

/**
 * __useAdminEditTagMutation__
 *
 * To run a mutation, you first call `useAdminEditTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditTagMutation, { data, loading, error }] = useAdminEditTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminEditTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditTagMutation,
    AdminEditTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminEditTagMutation,
    AdminEditTagMutationVariables
  >(AdminEditTagDocument, options);
}
export type AdminEditTagMutationHookResult = ReturnType<
  typeof useAdminEditTagMutation
>;
export type AdminEditTagMutationResult =
  Apollo.MutationResult<AdminEditTagMutation>;
export type AdminEditTagMutationOptions = Apollo.BaseMutationOptions<
  AdminEditTagMutation,
  AdminEditTagMutationVariables
>;
export const AdminCreateTagDocument = gql`
  mutation adminCreateTag($input: TagInput!) {
    createTag(input: $input) {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;
export type AdminCreateTagMutationFn = Apollo.MutationFunction<
  AdminCreateTagMutation,
  AdminCreateTagMutationVariables
>;
export type AdminCreateTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AdminCreateTagMutation,
    AdminCreateTagMutationVariables
  >,
  'mutation'
>;

export const AdminCreateTagComponent = (
  props: AdminCreateTagComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    AdminCreateTagMutation,
    AdminCreateTagMutationVariables
  >
    mutation={AdminCreateTagDocument}
    {...props}
  />
);

/**
 * __useAdminCreateTagMutation__
 *
 * To run a mutation, you first call `useAdminCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateTagMutation, { data, loading, error }] = useAdminCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateTagMutation,
    AdminCreateTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateTagMutation,
    AdminCreateTagMutationVariables
  >(AdminCreateTagDocument, options);
}
export type AdminCreateTagMutationHookResult = ReturnType<
  typeof useAdminCreateTagMutation
>;
export type AdminCreateTagMutationResult =
  Apollo.MutationResult<AdminCreateTagMutation>;
export type AdminCreateTagMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateTagMutation,
  AdminCreateTagMutationVariables
>;
