import { FileGroup } from '@/graphql/generated/types';
import { SelectField } from '@/ui/select-field/select-field.component';
import React, { ComponentType } from 'react';
import { Translator } from 'shared/translator/translator.component';

interface FileGroupSelectFieldProps {
  value?: FileGroup | null;
  onChange(group: FileGroup | null): void;
  label?: string;
  nullable?: boolean;
}

export const FileGroupSelectField: ComponentType<FileGroupSelectFieldProps> = (
  props,
) => {
  const { value, onChange, label = 'Kategorie', nullable = true } = props;
  const _ = Translator.useTranslator();
  return (
    <SelectField
      label={label}
      value={value}
      onChange={(value) => onChange(value as FileGroup)}
      nullable={nullable}
      nullLabel={nullable ? 'Alle anzeigen' : undefined}
      choices={[
        {
          value: FileGroup.Common,
          label: _(`file-group.${FileGroup.Common}`),
        },
        {
          value: FileGroup.Course,
          label: _(`file-group.${FileGroup.Course}`),
        },
        {
          value: FileGroup.Teacher,
          label: _(`file-group.${FileGroup.Teacher}`),
        },
        {
          value: FileGroup.Administrator,
          label: _(`file-group.${FileGroup.Administrator}`),
        },
        {
          value: FileGroup.Enterprise,
          label: _(`file-group.${FileGroup.Enterprise}`),
        },
      ]}
    />
  );
};
