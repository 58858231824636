import { Frame } from '@/frame';
import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { Grid } from '@/ui/grid/grid.component';
import { Row } from '@/ui/table/row.component';
import { Table } from '@/ui/table/table.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { useBoardModals } from '../box/modals.hook';
import { useStatisticsBoards } from './board.provider';
import styles from './stats-board.module.scss';

export const IntroStatsBoard: ComponentType<RouteComponentProps> = (props) => {
  const { settings } = useStatisticsBoards();
  const { askCreateBoard, askCreateBoardModal } = useBoardModals();

  return (
    <>
      {askCreateBoardModal}
      <Frame.Title>Statistik</Frame.Title>

      <Frame.Content>
        <Grid.Row>
          <Grid.Column smaller>
            <Box stretch title="Einleitung">
              <p>
                Übersichten (der Umsätze, Anzahl Buchungen, etc.) können in Form
                von <em>Boards</em> organisiert werden.
              </p>
              <p>
                Ein Board kann über die <em>Teilen</em> Funktion an andere
                Administrator/innen weitergegeben werden.
              </p>
              <p>
                Verwende als <em>Name</em> für das Board einen eindeutigen
                Bezeichner, der die darin enthaltenen Graphen und Infoboxen
                treffend zusammenfasst!
              </p>
              <p>
                Ein Board enthält <em>Boxen</em>, welche sowohl Graphen als auch
                Kennzahlen enthalten können. Bennene jede Box entsprechend ihrer
                Aufgabe!
              </p>
              <p>
                Eine Box mit Graphen muss mindestens einen <em>Graphen</em>{' '}
                enthalten. Ein Graph ist eine Kurven- oder Balkendiagramm,
                welches einen Namen, eine Farbe und eine Gruppe von messbaren
                Werten enthält.
              </p>
            </Box>
          </Grid.Column>
          <Grid.Column bigger>
            <Box stretch title="Boards">
              <p>
                Deine Boards erreichst du über die horizontale Subnavigation am
                oberen Rand.
              </p>
              <p>
                Durch Klicken auf das <em>+</em> auf dem Board oder auf diesen
                Button kannst du ein neues Board erstellen.
              </p>
              <Button
                primary
                onClick={askCreateBoard}
                label="Neues Board erstellen"
              />
              <Table
                className={styles.boardsTable}
                columns={['Name']}
                data={settings?.boards || []}
                render={(item) => (
                  <Row>
                    <td>
                      <Link to={`../board-${item.id}`}>{item.name}</Link>
                    </td>
                  </Row>
                )}
              />
            </Box>
          </Grid.Column>
        </Grid.Row>
      </Frame.Content>
    </>
  );
};
