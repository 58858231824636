import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { List } from './list/list.component';

export const FileRoutes: ComponentType<RouteComponentProps> = (props) => {
  return (
    <>
      <Frame.Title>Dateien</Frame.Title>
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            {
              label: 'Dateiliste',
              path: 'list',
            },
          ]}
        />
      </Frame.SubMenu>
      <Router className="router">
        <List path="list" />
        <Redirect from="/" to="list" />
      </Router>
    </>
  );
};
