import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { Editor } from './detail/editor/editor.component';
import { List } from './list/list.component';

export const Inquiry: ComponentType<RouteComponentProps> = (props) => {
  return (
    <>
      <Frame.Title>Anfragen</Frame.Title>
      <Router className="router">
        <List path="list" />
        <Editor path=":id/edit" />
        <Editor path="create" />
        <Redirect noThrow from="/" to="list" />
      </Router>
    </>
  );
};
