import { useCallback } from 'react';
import { Translator } from 'shared/translator/translator.component';

export const isoCountryCodes = ['DE', 'AT', 'CH', 'NL', 'BE'];

export function useCountryNames() {
  const t = Translator.useTranslator();
  const getName = useCallback((code: string) => {
    if (isoCountryCodes.includes(code)) {
      return t(`country.${code}`);
    } else {
      return code;
    }
  }, []);
  return getName;
}
