import { DiscountType } from '@/graphql/generated/types';
import { Formatted } from '@/ui/formatted/formatted.component';
import React, { ComponentType } from 'react';
import styles from './discount-label.module.scss';

interface DiscountLabelProps {
  type: DiscountType;
  value?: number | null;
}

export const DiscountLabel: ComponentType<DiscountLabelProps> = (props) => {
  const { type, value } = props;
  return (
    <span className={styles.host}>
      {type === DiscountType.Absolute && (
        <Formatted.Currency value={-(value || 0)} isDiscount />
      )}
      {type === DiscountType.Relative && (
        <>
          <span>{-(value || 0)} %</span>
        </>
      )}
    </span>
  );
};
