import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';

interface Props {}
export const SubMenu: ComponentType<Props> = (props) => {
  const domNode = document.getElementById('sub-menu-slot');
  if (!domNode) {
    return null;
  }
  return ReactDOM.createPortal(<>{props.children}</>, domNode);
};
