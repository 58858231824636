import { RoleType } from '@/graphql/generated/types';
import { Link } from '@reach/router';
import classNames from 'classnames';
import React, { ComponentType, useMemo } from 'react';
import styles from './nav-menu.module.scss';

export interface NavMenuItem {
  label: string;
  path?: string;
  onClick?: () => void;
  roles?: RoleType[];
  disabled?: boolean;
}

interface Props {
  items: (NavMenuItem | undefined)[];
  type?: 'horizontal' | 'vertical';
  className?: string;
}

export const NavMenu: ComponentType<Props> = (props) => {
  const { items, type = 'vertical', className } = props;

  const getProps = useMemo(() => {
    return ({ isPartiallyCurrent }) => {
      return {
        className: isPartiallyCurrent
          ? classNames(styles.current, 'current')
          : undefined,
      };
    };
  }, []);

  return (
    <ul className={classNames(styles.host, styles[type], className)}>
      {(items.filter((i) => !!i) as NavMenuItem[]).map((item) => (
        <li key={item.path || item.label} onClick={item.onClick}>
          {!item.disabled && item.path && (
            <Link getProps={getProps} to={item.path}>
              {item.label}
            </Link>
          )}
          {(!item.path || item.disabled) && (
            <a className={classNames(item.disabled && styles.disabled)}>
              {item.label}
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};
