import {
  CourseGraphOptions,
  Graph,
} from '@/statistics/shared/chart/graph/graph';
import { Button } from '@/ui/button/button.component';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import React, {
  ComponentType,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useResources } from 'shared/resources/resources.provider';
import { GraphItemOptionsProps } from '../../../../shared/chart/graph/graph-item/options/graph-options.hooks';
import styles from './graph-options.module.scss';

interface CourseGraphItemOptionsProps extends GraphItemOptionsProps {
  stateDispatch: [
    CourseGraphOptions,
    Dispatch<SetStateAction<CourseGraphOptions>>,
  ];
  graph: Graph<any>;
}

export const CourseGraphItemOptions: ComponentType<
  CourseGraphItemOptionsProps
> = (props) => {
  const { graph, stateDispatch } = props;
  const { availableCourseCategories } = useResources();

  const availableCourses = useMemo(() => {
    return availableCourseCategories.reduce<{ id: string; title: string }[]>(
      (prev, current) => {
        return [...prev, ...(current.courses || [])];
      },
      [],
    );
  }, [availableCourseCategories]);

  const [state, setState] = stateDispatch;

  const toggleCourse = useCallback(
    (value: boolean, id: string) => {
      const currentCourseIds = state.courseIds || [];
      setState({
        ...state,
        courseIds: value
          ? [...currentCourseIds, id]
          : currentCourseIds.filter((cId) => cId !== id),
      });
    },
    [state],
  );

  const handleSelectAll = useCallback(() => {
    setState({
      ...state,
      courseIds: availableCourses.map((course) => course.id),
    });
  }, [state]);

  const handleDeselectAll = useCallback(() => {
    setState({
      ...state,
      courseIds: [],
    });
  }, []);

  useEffect(() => {
    setState(
      (graph.options || {
        courseIds: [],
      }) as CourseGraphOptions,
    );
  }, []);

  return (
    <div className={styles.host}>
      <div className={styles.buttonContainer}>
        <Button label="Alle auswählen" onClick={handleSelectAll} />
        <Button label="Alle abwählen" onClick={handleDeselectAll} />
      </div>
      {availableCourses.map((c) => (
        <Checkbox
          label={c.title}
          checked={state.courseIds?.includes(c.id)}
          onChange={(value) => toggleCourse(value, c.id)}
        />
      ))}
    </div>
  );
};
