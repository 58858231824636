import { Resizable } from '@/ui/resizable/resizable.component';
import classNames from 'classnames';
import {
  add,
  differenceInMinutes,
  format,
  formatISO,
  parseISO,
  startOfDay,
} from 'date-fns';
import React, {
  ComponentType,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Translator } from 'shared/translator/translator.component';
import {
  useRoomManagement,
  useRoomManagementUi,
} from '../../room-management-context';
import { RoomEventDataFragment } from '../../room-management.generated';
import styles from './event-block.module.scss';
interface EventBlockProps {
  event: RoomEventDataFragment;
  onDoubleClick: () => void;
}

export const EventBlock: ComponentType<EventBlockProps> = (props) => {
  const { event, onDoubleClick } = props;

  const _ = Translator.useTranslator();
  const [hovered, setHovered] = useState(false);

  const hoverTimerRef = useRef<any>(null);

  const {
    getX,
    getMinutesFromPixels,
    getRoomIdByPosition,
    pixelsPerMinute,
    translateEventDates,
  } = useRoomManagementUi();
  const { updateLocalEvent } = useRoomManagement();

  const [displayedStartTime, setDisplayedStartTime] = useState<string>(
    format(parseISO(event.startDate), 'HH:mm'),
  );

  const [displayedEndTime, setDisplayedEndTime] = useState<string>(
    format(parseISO(event.endDate), 'HH:mm'),
  );

  const x = useMemo(() => {
    return getX(event);
  }, [event, getX]);

  useEffect(() => {
    setDisplayedStartTime(format(parseISO(event.startDate), 'HH:mm'));
    setDisplayedEndTime(format(parseISO(event.endDate), 'HH:mm'));
  }, [event]);

  return (
    <Resizable
      bounds=".room-event-manager-action-area"
      className={classNames(styles.host, hovered && styles.hovered)}
      x={x}
      width={differenceInMinutes(
        parseISO(event.endDate),
        parseISO(event.startDate),
      )}
      onDrag={(e, d) => {
        const { startDate, endDate } = translateEventDates(event, d.x);
        setDisplayedStartTime(format(startDate, 'HH:mm'));
        setDisplayedEndTime(format(endDate, 'HH:mm'));
      }}
      onDrop={(e, d) => {
        const { startDate, endDate } = translateEventDates(event, d.lastX);
        updateLocalEvent(event, {
          roomId: getRoomIdByPosition(e.clientY) || event.roomId,
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
        });
      }}
      onResize={(e, direction, ref, delta, position) => {
        const startTimeMinutes = getMinutesFromPixels(position.x);
        const duration = differenceInMinutes(
          parseISO(event.endDate),
          parseISO(event.startDate),
        );
        const endTimeMinutes = startTimeMinutes + duration + delta.width;
        if (direction === 'left') {
          setDisplayedStartTime(
            format(
              add(startOfDay(new Date()), {
                minutes: startTimeMinutes,
              }),
              'HH:mm',
            ),
          );
        }
        if (direction === 'right') {
          setDisplayedEndTime(
            format(
              add(startOfDay(new Date()), {
                minutes: endTimeMinutes,
              }),
              'HH:mm',
            ),
          );
        }
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        const startTime = getMinutesFromPixels(position.x);
        const newStartDate = formatISO(
          add(startOfDay(parseISO(event.startDate)), {
            minutes: startTime,
          }),
        );
        const duration =
          differenceInMinutes(
            parseISO(event.endDate),
            parseISO(event.startDate),
          ) + delta.width;
        const newEndDate = formatISO(
          add(parseISO(newStartDate), {
            minutes: duration,
          }),
        );
        updateLocalEvent(event, {
          startDate: newStartDate,
          endDate: newEndDate,
        });
      }}
      snapUnit={pixelsPerMinute * 5}
    >
      <div
        className={styles.hoverTarget}
        onMouseEnter={() => {
          clearTimeout(hoverTimerRef.current);
          hoverTimerRef.current = setTimeout(() => {
            setHovered(true);
          }, 1000);
        }}
        onMouseLeave={() => {
          clearTimeout(hoverTimerRef.current);
          setHovered(false);
        }}
        onDoubleClick={onDoubleClick}
      >
        <div className={styles.timeLabel}>
          {displayedStartTime} - {displayedEndTime}
        </div>
        <div className={styles.infoPopup}>
          {_(`event-kind.${event.__typename}`)}
        </div>
      </div>
    </Resizable>
  );
};
