import { Loader } from '@/ui/loader/loader.component';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { object, string } from 'yup';
import { Editor } from './editor.component';
import { useInitialCourseQuery } from './editor.generated';

interface EditProps {
  mode: 'create' | 'update';
}

const schema = object({
  title: string().required().min(1),
});

export const LoadedEditor: ComponentType<
  RouteComponentProps<{ id: string }> & EditProps
> = (props) => {
  const { id, mode } = props;
  const _ = Translator.useTranslator();

  const { availableCourseCategories } = useResources();

  const initialCourseQuery = useInitialCourseQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });

  const attributeSets = useMemo(() => {
    if (initialCourseQuery.data?.adminCourse) {
      return initialCourseQuery.data.adminCourse.category.enterprise
        .courseAttributeSets;
    }
    return [];
  }, [initialCourseQuery.data?.adminCourse, availableCourseCategories]);

  if (initialCourseQuery.loading) {
    return <Loader />;
  }

  return (
    <Editor
      mode={mode}
      course={initialCourseQuery.data?.adminCourse}
      attributeSets={attributeSets}
    />
  );
};
