import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LoginStep1QueryVariables = Types.Exact<{
  username: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;

export type LoginStep1Query = {
  __typename?: 'Query';
  myEnterprises: {
    __typename?: 'MyEnterprisesResult';
    isSuperAdmin: boolean;
    enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
  };
};

export type LoginStep2MutationVariables = Types.Exact<{
  username: Types.Scalars['String'];
  password: Types.Scalars['String'];
  enterpriseId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type LoginStep2Mutation = {
  __typename?: 'Mutation';
  authenticate: string;
};

export const LoginStep1Document = gql`
  query loginStep1($username: String!, $password: String!) {
    myEnterprises(username: $username, password: $password) {
      isSuperAdmin
      enterprises {
        id
        name
      }
    }
  }
`;
export type LoginStep1ComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LoginStep1Query,
    LoginStep1QueryVariables
  >,
  'query'
> &
  ({ variables: LoginStep1QueryVariables; skip?: boolean } | { skip: boolean });

export const LoginStep1Component = (props: LoginStep1ComponentProps) => (
  <ApolloReactComponents.Query<LoginStep1Query, LoginStep1QueryVariables>
    query={LoginStep1Document}
    {...props}
  />
);

/**
 * __useLoginStep1Query__
 *
 * To run a query within a React component, call `useLoginStep1Query` and pass it any options that fit your needs.
 * When your component renders, `useLoginStep1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginStep1Query({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginStep1Query(
  baseOptions: Apollo.QueryHookOptions<
    LoginStep1Query,
    LoginStep1QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoginStep1Query, LoginStep1QueryVariables>(
    LoginStep1Document,
    options,
  );
}
export function useLoginStep1LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoginStep1Query,
    LoginStep1QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoginStep1Query, LoginStep1QueryVariables>(
    LoginStep1Document,
    options,
  );
}
export type LoginStep1QueryHookResult = ReturnType<typeof useLoginStep1Query>;
export type LoginStep1LazyQueryHookResult = ReturnType<
  typeof useLoginStep1LazyQuery
>;
export type LoginStep1QueryResult = Apollo.QueryResult<
  LoginStep1Query,
  LoginStep1QueryVariables
>;
export const LoginStep2Document = gql`
  mutation loginStep2(
    $username: String!
    $password: String!
    $enterpriseId: UUID
  ) {
    authenticate(
      username: $username
      password: $password
      enterpriseId: $enterpriseId
    )
  }
`;
export type LoginStep2MutationFn = Apollo.MutationFunction<
  LoginStep2Mutation,
  LoginStep2MutationVariables
>;
export type LoginStep2ComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LoginStep2Mutation,
    LoginStep2MutationVariables
  >,
  'mutation'
>;

export const LoginStep2Component = (props: LoginStep2ComponentProps) => (
  <ApolloReactComponents.Mutation<
    LoginStep2Mutation,
    LoginStep2MutationVariables
  >
    mutation={LoginStep2Document}
    {...props}
  />
);

/**
 * __useLoginStep2Mutation__
 *
 * To run a mutation, you first call `useLoginStep2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginStep2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginStep2Mutation, { data, loading, error }] = useLoginStep2Mutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useLoginStep2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginStep2Mutation,
    LoginStep2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginStep2Mutation, LoginStep2MutationVariables>(
    LoginStep2Document,
    options,
  );
}
export type LoginStep2MutationHookResult = ReturnType<
  typeof useLoginStep2Mutation
>;
export type LoginStep2MutationResult =
  Apollo.MutationResult<LoginStep2Mutation>;
export type LoginStep2MutationOptions = Apollo.BaseMutationOptions<
  LoginStep2Mutation,
  LoginStep2MutationVariables
>;
