import infoIcon from '@/assets/image/icons/info.svg';
import { Frame } from '@/frame';
import { CourseStatus, FilterCourseInput } from '@/graphql/generated/types';
import { TagInspect } from '@/tag/tag-inspect/tag-inspect.component';
import { Button } from '@/ui/button/button.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { Icon } from '@/ui/icon/icon.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { SelectField } from '@/ui/select-field/select-field.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import classNames from 'classnames';
import { pick } from 'lodash-es';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { useResources } from 'shared/resources/resources.provider';
import globalStyles from 'styles/global.scss';
import { CourseDataFragment, useCoursesQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery<FilterCourseInput>();
  const { availableCourseCategories } = useResources();
  const { data, loading } = useCoursesQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      filter: pick(query, ['query', 'categoryId']),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>Kurse</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="../create" label="Neuer Kurs" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<CourseDataFragment, FilterCourseInput>
          loading={loading}
          data={data?.adminCourses.data || []}
          totalCount={data?.adminCourses?.totalCount}
          columns={[
            {
              name: 'Kürzel',
              sortable: true,
            },
            {
              name: 'Titel / Untertitel / Slug',
              sortable: true,
            },
            {
              name: 'Kategorie',
              sortable: true,
            },
            {
              name: 'Tags',
              sortable: true,
            },
            {
              name: 'direkt buchbar',
              sortable: true,
            },
            {
              name: 'förderbar',
              sortable: true,
            },
            {
              name: 'Steuersatz',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              <DebouncedInput
                label="Suche"
                debounceRate={400}
                value={value?.query || ''}
                onChange={(value) => onChange('query', value)}
                placeholder={'Suche…'}
              />
              <SelectField
                label="Kategorie"
                choices={[
                  { value: null, label: 'Alle' },
                  ...(availableCourseCategories?.map((c) => ({
                    value: c.id,
                    label: c.title,
                  })) || []),
                ]}
                onChange={(value) => onChange('categoryId', value)}
                value={value.categoryId}
              />
            </>
          )}
          renderRow={(item) => (
            <Row
              key={item.id}
              className={classNames(
                item.status === CourseStatus.Inactive && 'inactive',
              )}
            >
              <td>{item.abbreviation}</td>
              <td>
                <Link to={`../${item.id}/edit`}>
                  {item.infoCourse && <Icon inline icon={infoIcon} />}
                  <Copyable valueToCopy={item.id} label="ID kopieren" inline>
                    {item.title}
                    <br />
                    {item.subTitle && (
                      <>
                        {item.subTitle}
                        <br />
                      </>
                    )}

                    <span className={globalStyles.light}>{item.slug}</span>
                  </Copyable>
                </Link>
              </td>
              <td>
                <Link
                  to={`/course-management/categories/${item.category.id}/edit`}
                >
                  {item.category.title}
                </Link>
              </td>
              <td>
                <TagInspect tags={item.tags} />
              </td>
              <td>{item.purchasable ? <>Ja</> : <>Nein</>}</td>
              <td>{item.eligible ? <>Ja</> : <>Nein</>}</td>
              <td>{Math.round(item.taxRate * 100)} %</td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
