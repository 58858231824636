import classNames from 'classnames';
import propertyPath from 'property-path';
import React, { forwardRef, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import globalStyles from 'styles/global.scss';
import styles from './currency-input-field.module.scss';

export interface CurrencyInputFieldProps {
  value?: string;
  name?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  error?: string;
  label?: string | null;
  placeholder?: string;
  type?: 'text' | 'password' | 'date' | 'number';
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
  noMargin?: boolean;
}

/*
function currencyFormatter(value) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(value / 100);

  return `${amount}`;
}
*/

export const CurrencyInputField = forwardRef<
  HTMLInputElement,
  CurrencyInputFieldProps
>((props, ref) => {
  const { formState, error, label, value, readOnly, disabled, noMargin } =
    props;

  const errorMessage = useMemo(() => {
    if (props.name && formState) {
      return propertyPath.get(formState.errors, props.name)?.message;
    }
    return error;
  }, [error, formState, props.name]);

  return (
    <div className={classNames(styles.host, noMargin && styles.noMargin)}>
      {label && (
        <label>
          {label}
          {props.required && ' *'}
        </label>
      )}
      <NumberFormat
        readOnly={readOnly}
        disabled={disabled}
        className={styles.input}
        onValueChange={(value) =>
          props.onChange ? props.onChange(value.value || null) : undefined
        }
        isNumericString
        suffix=" €"
        onBlur={props.onBlur}
        decimalSeparator=","
        thousandSeparator="."
        value={value}
        decimalScale={2}
        /*format={currencyFormatter}*/
      />
      {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
    </div>
  );
});
