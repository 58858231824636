import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { List } from './audit-logs/list/list.component';

export const PrivacyRoutes: ComponentType<RouteComponentProps> = (props) => {
  let menuItems = [];

  return (
    <>
      {/* <Frame.Title>Standorte</Frame.Title> */}
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            {
              label: 'Einwilligungen / Widerrufe',
              path: 'audit-logs',
            },
          ]}
        />
      </Frame.SubMenu>
      <Router className="router">
        <List path="audit-logs" />
        <Redirect noThrow from="/" to="audit-logs" />
      </Router>
    </>
  );
};
