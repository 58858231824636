import { default as classNames, default as classnames } from 'classnames';
import React, { ComponentType, ReactNode } from 'react';
import { Copyable } from '../copyable/copyable.component';
import styles from './labeled-item.module.scss';
interface Props {
  label?: string;
  value?: ReactNode | null;
  warning?: boolean | string;
  isBool?: boolean;
  booleanValue?: boolean;
  big?: boolean;
  multiline?: boolean;
}

export const LabeledItem: ComponentType<Props> = (props) => {
  const { label, value, multiline, warning, isBool, booleanValue, big } = props;
  return (
    <div
      className={classnames(
        styles.host,
        {
          [styles.empty]: value === null || value === undefined || value === '',
          [styles.warning]: warning,
        },
        multiline && styles.multiline,
        big && styles.big,
      )}
    >
      {label && <div className={styles.label}>{label}</div>}
      <Copyable copyOnClick>
        <div className={styles.value}>{value ?? 'keine Angaben'}</div>
      </Copyable>
      {booleanValue !== undefined && (
        <div
          className={classNames(
            styles.value,
            booleanValue ? styles.yes : styles.no,
          )}
        >
          {booleanValue ? 'Ja' : 'Nein'}
        </div>
      )}
      {typeof warning === 'string' && (
        <div className={styles.warning}>{warning}</div>
      )}
    </div>
  );
};
