import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { ConsultingEventDataFragmentDoc } from '../../calendar/calendar.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ConsultingEventsForListQueryVariables = Types.Exact<{
  input: Types.FilterConsultingEvents;
  pagination: Types.PaginationInput;
}>;


export type ConsultingEventsForListQuery = { __typename?: 'Query', adminConsultingEvents: { __typename?: 'PaginatedConsultingEventResult', totalCount: number, data: Array<{ __typename?: 'ConsultingEvent', availableSubjects: Array<string>, selectedSubjects: Array<string>, id: any, description?: string | null, kind: string, startDate: any, endDate: any, attendee?: { __typename?: 'Person', id: any, createdAt: any, title?: string | null, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, inquiries: Array<{ __typename?: 'Inquiry', id: any, createdAt: any, status: Types.InquiryStatus, assignee?: { __typename?: 'Administrator', id: any, fullName: string } | null }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null } } | null, enterprise: { __typename?: 'Enterprise', id: any, name: string }, consultant?: { __typename?: 'Administrator', id: any, fullName: string, personId: any } | null, availableCourses: Array<{ __typename?: 'Course', id: any, title: string }>, selectedCourses: Array<{ __typename?: 'Course', id: any, title: string }>, availableLocations: Array<{ __typename?: 'Location', id: any, commonName: string }>, selectedLocations: Array<{ __typename?: 'Location', id: any, commonName: string }>, location?: { __typename?: 'Location', id: any, commonName: string } | null }> } };


export const ConsultingEventsForListDocument = gql`
    query consultingEventsForList($input: FilterConsultingEvents!, $pagination: PaginationInput!) {
  adminConsultingEvents(input: $input, pagination: $pagination) {
    totalCount
    data {
      ...ConsultingEventData
    }
  }
}
    ${ConsultingEventDataFragmentDoc}`;
export type ConsultingEventsForListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConsultingEventsForListQuery, ConsultingEventsForListQueryVariables>, 'query'> & ({ variables: ConsultingEventsForListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConsultingEventsForListComponent = (props: ConsultingEventsForListComponentProps) => (
      <ApolloReactComponents.Query<ConsultingEventsForListQuery, ConsultingEventsForListQueryVariables> query={ConsultingEventsForListDocument} {...props} />
    );
    

/**
 * __useConsultingEventsForListQuery__
 *
 * To run a query within a React component, call `useConsultingEventsForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultingEventsForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultingEventsForListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useConsultingEventsForListQuery(baseOptions: Apollo.QueryHookOptions<ConsultingEventsForListQuery, ConsultingEventsForListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultingEventsForListQuery, ConsultingEventsForListQueryVariables>(ConsultingEventsForListDocument, options);
      }
export function useConsultingEventsForListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultingEventsForListQuery, ConsultingEventsForListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultingEventsForListQuery, ConsultingEventsForListQueryVariables>(ConsultingEventsForListDocument, options);
        }
export type ConsultingEventsForListQueryHookResult = ReturnType<typeof useConsultingEventsForListQuery>;
export type ConsultingEventsForListLazyQueryHookResult = ReturnType<typeof useConsultingEventsForListLazyQuery>;
export type ConsultingEventsForListQueryResult = Apollo.QueryResult<ConsultingEventsForListQuery, ConsultingEventsForListQueryVariables>;