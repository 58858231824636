import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { CoursesOverview } from './overview/overview.component';

export const CoursesRoutes: ComponentType<RouteComponentProps> = (props) => {
  return (
    <Router className="router">
      <CoursesOverview path="overview" />

      <Redirect noThrow from="/" to="overview" />
    </Router>
  );
};
