import { Maybe } from '@/graphql/generated/types';
import { useEquipmentsQuery } from '@/location/equipment/list/list.generated';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { HelpPopup } from '@/ui/help-popup/help-popup.component';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { array, number, object, string } from 'yup';
import { Frame } from '../../../../frame';
import { Button } from '../../../../ui/button/button.component';
import {
  useCreateRoomMutation,
  useEditRoomMutation,
  useRoomQuery,
} from './editor.generated';

type Values = {
  name: string;
  maxPeople: Maybe<number | undefined>;
  locationId: string;
  pricePerHour: Maybe<number | undefined>;
  equipmentIds: string[];
};

const schema = object({
  locationId: string().required(),
  name: string().min(3).required(),
  maxPeople: number().integer().nullable(),
  pricePerHour: number().nullable(),
  equipmentIds: array(string()).nullable(),
});

export const Editor: ComponentType<RouteComponentProps<{ id: string }>> = (
  props,
) => {
  const roomQuery = useRoomQuery({
    variables: { id: props.id },
    fetchPolicy: 'network-only',
  });

  const [save] = useEditRoomMutation();
  const [create] = useCreateRoomMutation();

  const eqQuery = useEquipmentsQuery({
    variables: { pagination: { take: 50, skip: 0 } },
  });
  const availableEquipments = eqQuery.data?.adminEquipments.data;

  const { availableLocations } = useResources();

  const form = useForm<Values>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
    defaultValues: {
      equipmentIds: [],
    },
  });

  const onSubmit = useCallback(
    async (input) => {
      if (props.id) {
        try {
          await save({ variables: { id: props.id, input } });
          roomQuery.refetch();
          success('Der Raum wurde gespeichert.');
        } catch (e) {
          error('Beim Speichern des Raumes ist ein Fehler aufgetreten.');
        }
      } else {
        try {
          const location = await create({ variables: { input } });
          navigate(`./${location.data?.adminCreateRoom.id}/edit`);
          success('Der Raum wurde erstellt.');
        } catch (e) {
          error('Beim Speichern des Raumes ist ein Fehler aufgetreten.');
        }
      }
    },
    [props.id],
  );

  const onSubmitError = useCallback((errors) => {
    error('Bitte überprüfe deine Eingabe!');
  }, []);

  const persisted = props.id !== undefined || roomQuery.data !== undefined;

  const isForeignLocation = useMemo(() => {
    if (roomQuery.data?.adminRoom.locationId) {
      if (
        availableLocations.find(
          (l) => l.id === roomQuery.data?.adminRoom.locationId,
        ) === undefined
      ) {
        return true;
      }
    }
    return false;
  }, [availableLocations, roomQuery.data?.adminRoom.locationId]);

  useEffect(() => {
    if (!props.id && availableLocations.length) {
      form.setValue('locationId', availableLocations[0]?.id);
    }
  }, [props.id, availableLocations]);

  return (
    <>
      <Frame.SubTitle>
        {persisted ? roomQuery.data?.adminRoom.name : 'Neuen Raum Hinzufügen'}
      </Frame.SubTitle>

      <Frame.ActionBar>
        <Button label="Zurück" linkTo="/locations/rooms" />
        <Button
          primary
          label="Speichern"
          onClick={form.handleSubmit(onSubmit, onSubmitError)}
        />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={roomQuery.data?.adminRoom}>
          <Grid.Row>
            <Grid.Column>
              <Section>
                {isForeignLocation && roomQuery.data?.adminRoom.locationId && (
                  <>
                    <p>
                      Der Raum befindet sich in dem Standort{' '}
                      <em>{roomQuery.data.adminRoom.location.name}</em>
                    </p>
                    <p>Du kannst den Standort deshalb nicht ändern.</p>
                  </>
                )}
                {!isForeignLocation && (
                  <Form.Select
                    name="locationId"
                    label="Standort"
                    defaultValue={availableLocations[0]?.id}
                    choices={availableLocations.map((location) => ({
                      value: location.id,
                      label:
                        location.name +
                        ' (' +
                        location?.enterprises
                          .map((enterprise) => enterprise.name)
                          .join(', ') +
                        ')',
                    }))}
                  />
                )}

                <Form.Input name="name" label="Name" />
                <Form.Input
                  type="number"
                  name="maxPeople"
                  label="Kapazität (Anzahl Personen)"
                />
                <Form.Currency name="pricePerHour" label="Preis pro Stunde" />
                {availableEquipments && !persisted && (
                  <Form.Select
                    multiple
                    name="equipmentIds"
                    label={
                      <>
                        Ausstattung
                        <HelpPopup hover="Halte die Strg- (Windows) oder Befehlstaste (Mac) gedrückt, um mehrere Optionen auszuwählen." />
                      </>
                    }
                    choices={availableEquipments.map((enterprise) => ({
                      value: enterprise.id,
                      label: enterprise.name,
                    }))}
                  />
                )}
                {availableEquipments && persisted && (
                  <>
                    <label>Ausstattung</label>
                    <ul>
                      {roomQuery.data?.adminRoom.equipments.map((c) => (
                        <li>{c.name}</li>
                      ))}
                    </ul>
                  </>
                )}
              </Section>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
