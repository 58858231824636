import classnames from 'classnames';
import React, { forwardRef, ReactNode } from 'react';
import styles from './radio-button.module.scss';

export interface RadioProps {
  id?: string;
  name?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  error?: string;
  disabled?: boolean;
  value: string;
  label?: string | ReactNode;
  small?: boolean;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { id, name, checked, onChange, error, disabled, value, label, small } =
    props;
  return (
    <div
      className={classnames(
        styles.host,
        disabled && styles.disabled,
        small && styles.small,
      )}
    >
      <label htmlFor={id}>
        <input
          type="radio"
          name={name}
          checked={checked}
          value={value}
          id={id}
          onChange={onChange}
          disabled={disabled}
        />
        {label}
      </label>
    </div>
  );
});
