import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type InitialCourseDataFragment = { __typename?: 'Course', id: any, category: { __typename?: 'CourseCategory', enterprise: { __typename?: 'Enterprise', id: any, courseAttributeSets: Array<string> } } };

export type InitialCourseQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type InitialCourseQuery = { __typename?: 'Query', adminCourse: { __typename?: 'Course', id: any, category: { __typename?: 'CourseCategory', enterprise: { __typename?: 'Enterprise', id: any, courseAttributeSets: Array<string> } } } };

export type DeleteCourseMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteCourseMutation = { __typename?: 'Mutation', adminDeleteCourse: boolean };

export type UpdateCourseFilesMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.UpdateCourseFilesInput;
}>;


export type UpdateCourseFilesMutation = { __typename?: 'Mutation', adminUpdateCourseFiles: boolean };

export const InitialCourseDataFragmentDoc = gql`
    fragment InitialCourseData on Course {
  id
  category {
    enterprise {
      id
      courseAttributeSets
    }
  }
}
    `;
export const InitialCourseDocument = gql`
    query initialCourse($id: UUID!) {
  adminCourse(id: $id) {
    ...InitialCourseData
  }
}
    ${InitialCourseDataFragmentDoc}`;
export type InitialCourseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InitialCourseQuery, InitialCourseQueryVariables>, 'query'> & ({ variables: InitialCourseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InitialCourseComponent = (props: InitialCourseComponentProps) => (
      <ApolloReactComponents.Query<InitialCourseQuery, InitialCourseQueryVariables> query={InitialCourseDocument} {...props} />
    );
    

/**
 * __useInitialCourseQuery__
 *
 * To run a query within a React component, call `useInitialCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInitialCourseQuery(baseOptions: Apollo.QueryHookOptions<InitialCourseQuery, InitialCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitialCourseQuery, InitialCourseQueryVariables>(InitialCourseDocument, options);
      }
export function useInitialCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitialCourseQuery, InitialCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitialCourseQuery, InitialCourseQueryVariables>(InitialCourseDocument, options);
        }
export type InitialCourseQueryHookResult = ReturnType<typeof useInitialCourseQuery>;
export type InitialCourseLazyQueryHookResult = ReturnType<typeof useInitialCourseLazyQuery>;
export type InitialCourseQueryResult = Apollo.QueryResult<InitialCourseQuery, InitialCourseQueryVariables>;
export const DeleteCourseDocument = gql`
    mutation deleteCourse($id: UUID!) {
  adminDeleteCourse(id: $id)
}
    `;
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>;
export type DeleteCourseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCourseMutation, DeleteCourseMutationVariables>, 'mutation'>;

    export const DeleteCourseComponent = (props: DeleteCourseComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCourseMutation, DeleteCourseMutationVariables> mutation={DeleteCourseDocument} {...props} />
    );
    

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, options);
      }
export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>;
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>;
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<DeleteCourseMutation, DeleteCourseMutationVariables>;
export const UpdateCourseFilesDocument = gql`
    mutation updateCourseFiles($id: UUID!, $input: UpdateCourseFilesInput!) {
  adminUpdateCourseFiles(id: $id, input: $input)
}
    `;
export type UpdateCourseFilesMutationFn = Apollo.MutationFunction<UpdateCourseFilesMutation, UpdateCourseFilesMutationVariables>;
export type UpdateCourseFilesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCourseFilesMutation, UpdateCourseFilesMutationVariables>, 'mutation'>;

    export const UpdateCourseFilesComponent = (props: UpdateCourseFilesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCourseFilesMutation, UpdateCourseFilesMutationVariables> mutation={UpdateCourseFilesDocument} {...props} />
    );
    

/**
 * __useUpdateCourseFilesMutation__
 *
 * To run a mutation, you first call `useUpdateCourseFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseFilesMutation, { data, loading, error }] = useUpdateCourseFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseFilesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseFilesMutation, UpdateCourseFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourseFilesMutation, UpdateCourseFilesMutationVariables>(UpdateCourseFilesDocument, options);
      }
export type UpdateCourseFilesMutationHookResult = ReturnType<typeof useUpdateCourseFilesMutation>;
export type UpdateCourseFilesMutationResult = Apollo.MutationResult<UpdateCourseFilesMutation>;
export type UpdateCourseFilesMutationOptions = Apollo.BaseMutationOptions<UpdateCourseFilesMutation, UpdateCourseFilesMutationVariables>;