import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ConsentAuditLogsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
}>;


export type ConsentAuditLogsQuery = { __typename?: 'Query', adminAuditLogs: { __typename?: 'PaginatedAuditLogResult', totalCount: number, data: Array<{ __typename?: 'AdminActionAuditLog' } | { __typename?: 'ConsentAuditLog', id: any, createdAt: any, verifiedAt?: any | null, verificationToken?: string | null, consent: boolean, consentHandle: Types.ConsentHandle, person: { __typename?: 'Person', id: any, firstName?: string | null, lastName?: string | null, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }> } }> } };


export const ConsentAuditLogsDocument = gql`
    query consentAuditLogs($pagination: PaginationInput!) {
  adminAuditLogs(
    pagination: $pagination
    filter: {kind: "ConsentAuditLog", includeAdminLogins: false}
  ) {
    totalCount
    data {
      ... on ConsentAuditLog {
        id
        createdAt
        person {
          id
          firstName
          lastName
          contactProperties {
            id
            key
            value
          }
        }
        verifiedAt
        verificationToken
        consent
        consentHandle
      }
    }
  }
}
    `;
export type ConsentAuditLogsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConsentAuditLogsQuery, ConsentAuditLogsQueryVariables>, 'query'> & ({ variables: ConsentAuditLogsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConsentAuditLogsComponent = (props: ConsentAuditLogsComponentProps) => (
      <ApolloReactComponents.Query<ConsentAuditLogsQuery, ConsentAuditLogsQueryVariables> query={ConsentAuditLogsDocument} {...props} />
    );
    

/**
 * __useConsentAuditLogsQuery__
 *
 * To run a query within a React component, call `useConsentAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentAuditLogsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useConsentAuditLogsQuery(baseOptions: Apollo.QueryHookOptions<ConsentAuditLogsQuery, ConsentAuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsentAuditLogsQuery, ConsentAuditLogsQueryVariables>(ConsentAuditLogsDocument, options);
      }
export function useConsentAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsentAuditLogsQuery, ConsentAuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsentAuditLogsQuery, ConsentAuditLogsQueryVariables>(ConsentAuditLogsDocument, options);
        }
export type ConsentAuditLogsQueryHookResult = ReturnType<typeof useConsentAuditLogsQuery>;
export type ConsentAuditLogsLazyQueryHookResult = ReturnType<typeof useConsentAuditLogsLazyQuery>;
export type ConsentAuditLogsQueryResult = Apollo.QueryResult<ConsentAuditLogsQuery, ConsentAuditLogsQueryVariables>;