import { FilterTagInput } from '@/graphql/generated/types';
import { Profile, isSuperAdmin } from '@/profile/profile';
import { Button } from '@/ui/button/button.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { Frame } from '../../frame';
import { TagDataFragment, useAdminTagsQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const profile = Profile.use();
  const [query] = usePaginatedUrlQuery();
  const { data, loading } = useAdminTagsQuery({
    variables: { pagination: getPaginationFromQuery(query) },
    fetchPolicy: 'network-only',
  });
  const _ = Translator.useTranslator();

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="/tags/create" label="Neuer Tag" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<TagDataFragment, FilterTagInput>
          loading={loading}
          data={data?.adminTags?.data || []}
          totalCount={data?.adminTags?.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'Slug',
              sortable: true,
            },
            {
              name: 'Kontext',
              sortable: true,
            },
            isSuperAdmin(profile)
              ? { name: 'Unternehmen', sortable: true }
              : undefined,
          ]}
          renderFilter={({ value, onChange }) => (
            <DebouncedInput
              debounceRate={400}
              value={value?.query || ''}
              onChange={(value) => onChange('query', value)}
              placeholder={'Suche…'}
            />
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/edit`}>{item.name}</Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>{item.slug}</Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>{item.context}</Link>
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
