import { useRoleGuard } from '@/auth/role/role.hooks';
import { RoleType } from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Detail } from './detail/detail.component';
import { Editor } from './detail/editor/editor.component';
import { List } from './list/list.component';

export const Enterprise: ComponentType<RouteComponentProps> = (props) => {
  const { isSuper, hasRole } = useRoleGuard();
  const profile = Profile.use();

  return (
    <>
      <Router className="router">
        {isSuper && <List path="list" />}
        <Detail path=":id/*" />
        {isSuper && <Editor path="create" />}
        {isSuper && <Redirect noThrow from="/" to="list" />}
        {hasRole(RoleType.Admin) && profile?.selectedEnterprise && (
          <Redirect
            noThrow
            from="/"
            to={`${profile.selectedEnterprise.id}/edit`}
          />
        )}
      </Router>
    </>
  );
};
