import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { useEnterpriseDetailBaseQuery } from './detail.generated';
import { Editor } from './editor/editor.component';
import { PropertySchema } from './properties/properties.component';
import { Settings } from './settings/settings.component';
import { TransactionMailTemplate } from './transaction-mail-template/transaction-mail-template.component';

type EnterpriseDetailParams = {
  id?: string;
};

export const Detail: ComponentType<
  RouteComponentProps<EnterpriseDetailParams>
> = (props) => {
  const { id } = props;

  const enterpriseQuery = useEnterpriseDetailBaseQuery({
    variables: { id },
    skip: id === undefined,
  });

  if (!enterpriseQuery.data?.adminEnterprise) {
    return null;
  }

  return (
    <>
      <Router className="router">
        <Editor path="edit" enterprise={enterpriseQuery.data.adminEnterprise} />

        <Settings
          path="settings"
          enterprise={enterpriseQuery.data.adminEnterprise}
        />
        <TransactionMailTemplate
          path="transaction-mail-templates/*"
          enterprise={enterpriseQuery.data.adminEnterprise}
        />

        <PropertySchema
          path="property-schemas/*"
          enterprise={enterpriseQuery.data.adminEnterprise}
        />
        <Redirect from="/" to="edit" />
      </Router>
    </>
  );
};
