import { usePersonActions } from '@/contact/person/actions/actions.hooks';
import {
  getCreateMutationName,
  getGenderedUserLabel,
  getPersonDescription,
} from '@/contact/person/utils';
import { UserStatusChip } from '@/contact/person/view/user-status-chip.component';
import { BookingCourseUnitOrModule } from '@/course-handling/booking/shared/booking-course-unit-or-module.component';
import { BookingCourseView } from '@/course-handling/booking/shared/course-view.component';
import { Frame } from '@/frame';
import { Customer } from '@/graphql/generated/types';
import { InquiryStatusView } from '@/inquiry/shared/status.component';
import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { Chip } from '@/ui/chip/chip.component';
import { CopyId } from '@/ui/copy-id/copy-id.component';
import { Form } from '@/ui/form/form.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Grid } from '@/ui/grid/grid.component';
import { Row } from '@/ui/table/row.component';
import { Table } from '@/ui/table/table.component';
import { Tabs } from '@/ui/tabs/tabs.component';
import { useTabs } from '@/ui/tabs/tabs.provider';
import { error, success } from '@/ui/toaster/toast';
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { omit } from 'lodash-es';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { getCourseUnitLocationLabel } from 'shared/course/utils';
import { Translator } from 'shared/translator/translator.component';
import { AdministratorEditor } from '../administrator/editor.component';
import { administratorFields } from '../administrator/tab-field-map';
import { CustomerEditor } from '../customer/editor.component';
import { customerFields } from '../customer/tab-field-map';
import { PersonEditor } from '../person/person.component';
import { personFields } from '../person/tab-field-map';
import { TeacherEditor } from '../teacher/editor.component';
import { teacherFields } from '../teacher/tab-field-map';
import { usePersonUserForm } from '../user-form.hook';
import { usePersonQuery } from './base-editor.generated';
import { useCreateMutation, useUpdateMutation } from './mutations';
import { NoOpEditor } from './no-op.component';

function getUserEditor(userType?: UserType) {
  if (userType === 'customers') {
    return CustomerEditor;
  }
  if (userType === 'teachers') {
    return TeacherEditor;
  }
  if (userType === 'administrators') {
    return AdministratorEditor;
  }
  return NoOpEditor;
}

function getUserFields(userType?: UserType) {
  if (userType === 'customers') {
    return customerFields;
  }
  if (userType === 'administrators') {
    return administratorFields;
  }
  if (userType === 'teachers') {
    return teacherFields;
  }

  return [];
}
export type UserType = 'persons' | 'customers' | 'administrators' | 'teachers';
interface RouteParams {
  userType: UserType;
  id: string;
}

interface BaseEditorProps {}

export const BaseEditor: ComponentType<
  RouteComponentProps<RouteParams> & BaseEditorProps
> = (props) => {
  const { userType, id } = props;

  const _ = Translator.useTranslator();
  const { highlightTabsWithFields, setHighlightedIndizes } = useTabs();
  const personQuery = usePersonQuery({
    variables: { id: props.id },
    skip: props.id === undefined,
    fetchPolicy: 'network-only',
  });

  const [create] = useCreateMutation(userType);
  const [update] = useUpdateMutation(userType);
  const { modalDelete, askForDelete, modalAnonymize, askForAnonymize } =
    usePersonActions((action) => {
      if (action === 'delete') {
        navigate(-1);
      } else {
        personQuery.refetch();
      }
    });

  const persisted = props.id !== undefined || personQuery.data !== undefined;

  const person = personQuery.data?.person;

  const form = usePersonUserForm(userType);

  const contactProperties = useFieldArray<any>({
    control: form.control,
    name: 'person.contactProperties',
  });

  const employments = useFieldArray<any>({
    control: form.control,
    name: 'person.employments',
  });

  const onBack = useCallback(() => {
    if (userType === 'administrators') {
      navigate('/administration/users');
    } else {
      navigate('..');
    }
  }, [userType]);

  const onSubmit = useMemo(
    () =>
      form.handleSubmit(
        async (data) => {
          setHighlightedIndizes([]);

          if (!persisted) {
            try {
              const newPerson = await create({
                variables: {
                  personInput: data.person,
                  userInput: data.user,
                } as any,
              });
              success('Die Person wurde gespeichert.');
              const id = newPerson.data
                ? newPerson.data[getCreateMutationName(userType)].id
                : undefined;
              if (id) {
                navigate(`/contacts/${userType}/${id}/edit`);
              }
            } catch (e) {
              error('Beim Speichern ist ein Fehler aufgetreten.');
            }
          } else {
            if (personQuery.data?.person.id) {
              try {
                await update({
                  variables: {
                    id: personQuery.data.person.id,
                    personInput: data.person,
                    userInput: data.user,
                  } as any,
                });
                success('Die Person wurde gespeichert.');
              } catch (e) {
                error('Beim Speichern ist ein Fehler aufgetreten.');
              }
            }
          }
        },
        (errors) => {
          console.error(errors);
          highlightTabsWithFields(Object.keys(errors));
          error('Bitte überprüfe die Angaben.');
        },
      ),
    [personQuery.data, persisted, userType],
  );

  const formValues = useMemo(() => {
    if (personQuery.data) {
      return {
        person: omit(personQuery.data.person, 'user'),
        user: personQuery.data.person.user,
      };
    }
  }, [personQuery.data]);

  const UserEditor = useMemo(() => getUserEditor(userType), [userType]);
  const userFields = useMemo(() => getUserFields(userType), [userType]);

  return (
    <>
      <Frame.SubTitle>
        {person ? (
          <>
            <em>{getGenderedUserLabel(person, userType, _)}</em>{' '}
            {getPersonDescription(person)}
          </>
        ) : (
          _(`label.new.${userType}`)
        )}
        {}
      </Frame.SubTitle>
      <Frame.ActionBar
        left={
          <>
            <CopyId
              label="ID (Person)"
              id={personQuery.data?.person.id}
              copyLabel={personQuery.data?.person.id}
            />
            {person?.user && (
              <CopyId
                label={`ID (${_(`user-type.${person.user.__typename}`)})`}
                id={person.user.id}
                copyLabel={person.user.id}
              />
            )}
            {person?.anonymizedAt && <Chip>anonymisiert</Chip>}
          </>
        }
      >
        {person && (
          <>
            {!person.anonymizedAt && (
              <Button
                error
                label="Löschen (anonym)"
                onClick={() => askForAnonymize(person)}
              />
            )}
            <Button
              error
              label="Löschen (destruktiv)"
              onClick={() => askForDelete(person)}
            />
          </>
        )}
        <Button label="Zurück" onClick={onBack} />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        {modalDelete}
        {modalAnonymize}

        <Form form={form} values={formValues}>
          <Tabs
            tabs={[
              {
                title: 'Person',
                fields: personFields,
                content: (
                  <PersonEditor
                    create={!persisted}
                    form={form}
                    contactProperties={contactProperties}
                    employments={employments}
                    enterprises={personQuery.data?.person.enterprises}
                  />
                ),
              },
              {
                title: (
                  <>
                    {_(`tab-title.${userType}`)}
                    {persisted && personQuery.data?.person.user && (
                      <UserStatusChip user={personQuery.data.person.user} />
                    )}
                  </>
                ),
                fields: userFields,
                content: (
                  <UserEditor
                    user={formValues?.user}
                    person={formValues?.person}
                  />
                ),
              },
              {
                title: `Anfragen${
                  person && person.inquiries.length > 0
                    ? ` (${person?.inquiries.length})`
                    : ''
                }`,
                content: (
                  <Table
                    columns={['Eingegangen', 'Status']}
                    data={person?.inquiries || []}
                    render={(item) => (
                      <Row>
                        <td>
                          <Link to={`/inquiries/${item.id}/edit`}>
                            <Formatted.Date value={item.createdAt} />
                          </Link>
                        </td>
                        <td>
                          <InquiryStatusView inquiry={item} />
                        </td>
                      </Row>
                    )}
                  />
                ),
              },
              userType === 'customers'
                ? {
                    title: `Bestellungen${
                      person && (person.user as Customer).orders.length > 0
                        ? ` (${(person.user as Customer).orders.length})`
                        : ''
                    } & Buchungen${
                      person && (person.user as Customer).bookings.length > 0
                        ? ` (${(person.user as Customer).bookings.length})`
                        : ''
                    }`,
                    content: (
                      <Grid.Row>
                        <Grid.Column>
                          <Box title="Bestellungen">
                            <Table
                              columns={['Eingegangen', 'Bestellwert (gesamt)']}
                              data={(person?.user as Customer)?.orders || []}
                              render={(item) => (
                                <Row>
                                  <td>
                                    <Link to={`/orders/${item.id}/view`}>
                                      <Formatted.Date value={item.createdAt} />
                                    </Link>
                                  </td>
                                  <td>
                                    <Formatted.Currency value={item.total} />
                                  </td>
                                </Row>
                              )}
                            />
                          </Box>
                        </Grid.Column>
                        <Grid.Column>
                          <Box title="Buchungen">
                            <Table
                              columns={[
                                'Eingegangen',
                                'Kurs',
                                'Einheit / Modul',
                                'Standort',
                              ]}
                              data={(person?.user as Customer)?.bookings || []}
                              render={(item) => (
                                <Row>
                                  <td>
                                    <Link
                                      to={`/course-handling/${item.id}/view`}
                                    >
                                      <Formatted.Date value={item.createdAt} />
                                    </Link>
                                  </td>
                                  <td>
                                    <BookingCourseView booking={item as any} />
                                  </td>
                                  <td>
                                    <BookingCourseUnitOrModule
                                      booking={item as any}
                                    />
                                  </td>
                                  <td>
                                    {getCourseUnitLocationLabel(
                                      item.kind === 'CourseModuleBooking'
                                        ? (item as any).courseModule
                                        : (item as any).courseUnit,
                                    )}
                                  </td>
                                </Row>
                              )}
                            />
                          </Box>
                        </Grid.Column>
                      </Grid.Row>
                    ),
                  }
                : undefined,
            ]}
          />
        </Form>
      </Frame.Content>
    </>
  );
};
