import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CourseCategoryDataFragment = { __typename?: 'CourseCategory', id: any, title: string, slug?: string | null, enterpriseId: any, enterprise: { __typename?: 'Enterprise', name: string, id: any }, parent?: { __typename?: 'CourseCategory', id: any, title: string } | null };

export type CourseCategoriesQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  enterpriseIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type CourseCategoriesQuery = { __typename?: 'Query', adminCourseCategories: { __typename?: 'PaginatedCourseCategoryResult', totalCount: number, data: Array<{ __typename?: 'CourseCategory', id: any, title: string, slug?: string | null, enterpriseId: any, enterprise: { __typename?: 'Enterprise', name: string, id: any }, parent?: { __typename?: 'CourseCategory', id: any, title: string } | null }> } };

export const CourseCategoryDataFragmentDoc = gql`
    fragment CourseCategoryData on CourseCategory {
  id
  title
  slug
  enterprise {
    name
    id
  }
  enterpriseId
  parent {
    id
    title
  }
}
    `;
export const CourseCategoriesDocument = gql`
    query courseCategories($pagination: PaginationInput!, $enterpriseIds: [UUID!]) {
  adminCourseCategories(pagination: $pagination, enterpriseIds: $enterpriseIds) {
    data {
      ...CourseCategoryData
    }
    totalCount
  }
}
    ${CourseCategoryDataFragmentDoc}`;
export type CourseCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CourseCategoriesQuery, CourseCategoriesQueryVariables>, 'query'> & ({ variables: CourseCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CourseCategoriesComponent = (props: CourseCategoriesComponentProps) => (
      <ApolloReactComponents.Query<CourseCategoriesQuery, CourseCategoriesQueryVariables> query={CourseCategoriesDocument} {...props} />
    );
    

/**
 * __useCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCategoriesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      enterpriseIds: // value for 'enterpriseIds'
 *   },
 * });
 */
export function useCourseCategoriesQuery(baseOptions: Apollo.QueryHookOptions<CourseCategoriesQuery, CourseCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseCategoriesQuery, CourseCategoriesQueryVariables>(CourseCategoriesDocument, options);
      }
export function useCourseCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseCategoriesQuery, CourseCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseCategoriesQuery, CourseCategoriesQueryVariables>(CourseCategoriesDocument, options);
        }
export type CourseCategoriesQueryHookResult = ReturnType<typeof useCourseCategoriesQuery>;
export type CourseCategoriesLazyQueryHookResult = ReturnType<typeof useCourseCategoriesLazyQuery>;
export type CourseCategoriesQueryResult = Apollo.QueryResult<CourseCategoriesQuery, CourseCategoriesQueryVariables>;