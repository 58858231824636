import infoIcon from '@/assets/image/icons/info.svg';
import { Frame } from '@/frame';
import {
  FilterCourseInput,
  FilterPackageInput,
  PackageStatus,
} from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Chip } from '@/ui/chip/chip.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { Icon } from '@/ui/icon/icon.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import classNames from 'classnames';
import { pick } from 'lodash-es';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { useResources } from 'shared/resources/resources.provider';
import { PackageFragment, usePackagesQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery<FilterCourseInput>();
  const { availableCourseCategories } = useResources();
  const { data, loading } = usePackagesQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      filter: pick(query, ['query']),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>Kurse</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="../create" label="Neues Package" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<PackageFragment, FilterPackageInput>
          loading={loading}
          data={data?.adminPackages.data || []}
          totalCount={data?.adminPackages?.totalCount}
          columns={[
            {
              name: 'Titel / Kürzel',
              sortable: true,
            },
            {
              name: 'direkt buchbar',
              sortable: true,
            },
            { name: 'Tags', sortable: false },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              <DebouncedInput
                label="Suche"
                debounceRate={400}
                value={value?.query || ''}
                onChange={(value) => onChange('query', value)}
                placeholder={'Suche…'}
              />
            </>
          )}
          renderRow={(item) => (
            <Row
              key={item.id}
              className={classNames(
                item.status === PackageStatus.Inactive && 'inactive',
              )}
            >
              <td>
                <Link to={`../${item.id}/edit`}>
                  {item.title && <Icon inline icon={infoIcon} />}
                  <Copyable valueToCopy={item.id} label="ID kopieren" inline>
                    {item.title}
                    <br />
                    {item.abbreviation}
                    <br />
                    {item.subTitle && (
                      <>
                        {item.subTitle}
                        <br />
                      </>
                    )}
                  </Copyable>
                </Link>
              </td>
              <td>{item.purchasable ? <>Ja</> : <>Nein</>}</td>
              <td>
                {item.tags?.map((tag) => (
                  <Chip key={tag.id}>{tag.name}</Chip>
                ))}
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
