import copyImg from '@/assets/image/icons/copy.svg';
import React, { ComponentType } from 'react';
import { Copyable } from '../copyable/copyable.component';
import { Icon } from '../icon/icon.component';

interface CopyIdProps {
  id?: string;
  label?: string;
  copyLabel?: string;
}

export const CopyId: ComponentType<CopyIdProps> = (props) => {
  const { id, label, copyLabel } = props;
  return (
    <Copyable
      copyOnClick
      disabled={id === undefined}
      valueToCopy={id}
      label={copyLabel || 'id kopieren'}
      inline
    >
      {label || 'ID'}
      <Icon inline icon={copyImg} />
    </Copyable>
  );
};
