// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SNCFaGwkFSackXiRxYC7{padding-top:.5rem;padding-bottom:.5rem}.SNCFaGwkFSackXiRxYC7 .HHc3jtVBI0XjqGT5mmIq{display:flex;gap:1rem;padding:0 8px}.SNCFaGwkFSackXiRxYC7 label{padding-top:0;padding-bottom:0}", "",{"version":3,"sources":["webpack://./src/statistics-v3/orders/chart/graph/options/graph-options.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,oBAAA,CACA,4CACE,YAAA,CACA,QAAA,CACA,aAAA,CAEF,4BACE,aAAA,CACA,gBAAA","sourcesContent":["@import 'styles/variables';\n\n.host {\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  .buttonContainer {\n    display: flex;\n    gap: 1rem;\n    padding: 0 8px;\n  }\n  label {\n    padding-top: 0;\n    padding-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "SNCFaGwkFSackXiRxYC7",
	"buttonContainer": "HHc3jtVBI0XjqGT5mmIq"
};
export default ___CSS_LOADER_EXPORT___;
