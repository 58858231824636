import classNames from 'classnames';
import { formatISO, parseISO } from 'date-fns';
import propertyPath from 'property-path';
import React, {
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useMemo,
} from 'react';
import { FormState } from 'react-hook-form';
import globalStyles from 'styles/global.scss';
import styles from './textarea.module.scss';

export interface TextareaProps {
  small?: boolean;
  white?: boolean;
  value?: string;
  name?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  error?: string;
  label?: ReactNode | null;
  placeholder?: string;
  type?: 'text' | 'password' | 'date' | 'number';
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
  onKeyPress?: KeyboardEventHandler;
  outOfOrder?: string;
  className?: string;
  slim?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      error,
      label,
      value,
      formState,
      small,
      white,
      outOfOrder,
      className,
      slim,
      ...rest
    } = props;

    const errorMessage = useMemo(() => {
      if (props.name && formState?.errors) {
        return propertyPath.get(formState.errors, props.name)?.message;
      }
      return error;
    }, [error, formState, props.name]);

    return (
      <div
        className={classNames(
          styles.host,
          small && styles.small,
          outOfOrder && styles.isOutOfOrder,
          white && styles.white,
          slim && styles.slim,
          className,
        )}
      >
        {outOfOrder && (
          <div className={styles.outOfOrder}>
            <div className={styles.inner}>{outOfOrder}</div>
          </div>
        )}
        {label && (
          <label>
            {label}
            {props.required && ' *'}
          </label>
        )}
        <textarea
          ref={ref}
          {...rest}
          value={
            props.type === 'date'
              ? value
                ? formatISO(parseISO(value)).substr(0, 10)
                : ''
              : value || ''
          }
        />
        {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
      </div>
    );
  },
);
