import { endOfMonth, startOfMonth } from 'date-fns';
import React, {
  ComponentType,
  createContext,
  useContext,
  useState,
} from 'react';

type Range = { startDate: Date; endDate: Date };
interface EventCalendarApi {
  range: Range;
  setRange: (range: Range) => void;
}

const EventCalendarContext = createContext<EventCalendarApi | undefined>(
  undefined,
);

export const EventCalendarProvider: ComponentType = ({ children }) => {
  const today = new Date();
  const [state, setState] = useState<{ startDate: Date; endDate: Date }>({
    startDate: startOfMonth(today),
    endDate: endOfMonth(today),
  });

  return (
    <EventCalendarContext.Provider value={{ range: state, setRange: setState }}>
      {children}
    </EventCalendarContext.Provider>
  );
};

export function useEventCalendar() {
  const context = useContext(EventCalendarContext);
  if (!context) {
    throw new Error('EventCalendarContext is not ready.');
  }
  return context;
}
