import {
  CheckedStatesType,
  MultipleValuesType,
  PersonDuplicateTypeBase,
  RecordType,
} from './duplicate-resolver.component';
import { MergeCandidate, MergeState } from './types';

export function extractCandidates(person: PersonDuplicateTypeBase | undefined) {
  if (person) {
    const candidates: MergeCandidate[] = [];
    candidates.push(person);

    person.duplicates.forEach((item) => {
      if (person.id !== item.person1.id) {
        candidates.push(item.person1);
      } else {
        candidates.push(item.person2);
      }
    });

    return candidates;
  }
  return [];
}

export function calculateMaster(
  candidates: MergeCandidate[],
  state: MergeState | undefined,
) {
  return candidates[0] || {};
}

export function mergeRecords(
  masterRecord: RecordType | undefined,
  checkedStates: CheckedStatesType,
  dataToDisplay: RecordType[],
  multipleValues?: MultipleValuesType,
) {
  const mergedMasterRecord = { ...masterRecord };

  for (const recordId in checkedStates) {
    for (const attribute in masterRecord) {
      if (checkedStates[recordId][attribute]) {
        const sourceRecord = dataToDisplay.find((d) => d.id === recordId);
        if (sourceRecord) {
          mergedMasterRecord[attribute] = sourceRecord[attribute];
        }
      }
    }
  }

  for (const recordId in multipleValues) {
    for (const attribute in masterRecord) {
      if (multipleValues[recordId][attribute]) {
        const sourceRecord = dataToDisplay.find((d) => d.id === recordId);
        if (sourceRecord) {
          if (!mergedMasterRecord[attribute]) {
            mergedMasterRecord[attribute] = [];
          }
          mergedMasterRecord[attribute].push(
            ...multipleValues[recordId][attribute],
          );
        }
      }
    }
  }

  // filter duplicate values

  return mergedMasterRecord;
}
