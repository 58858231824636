import {
  DiscountRuleDependencies,
  DiscountRuleDependencyRelation,
  DiscountRuleStatus,
  DiscountType,
  SaveDiscountRuleInput,
} from '@/graphql/generated/types';
import { useProductsQuery } from '@/product/list/list.generated';
import { Profile, isSuperAdmin } from '@/profile/profile';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { Loader } from '@/ui/loader/loader.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { Tabs } from '@/ui/tabs/tabs.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  getCourseUnitDates,
  getCourseUnitLocationLabel,
} from 'shared/course/utils';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { InferType, array, boolean, number, object, string } from 'yup';
import { Frame } from '../../../../frame';
import { Button } from '../../../../ui/button/button.component';
import { useDiscountRuleActions } from '../../actions/actions.hooks';
import {
  useAvailableCourseModulesQuery,
  useAvailableCourseUnitsQuery,
  useAvailableCoursesForDiscountsQuery,
  useDiscountRuleQuery,
  useSaveDiscountRuleMutation,
} from './editor.generated';

type Values = {
  title: string;
  description: string;
  type: DiscountType;
  amount: number;
  enterpriseId: string;
  dependencies: DiscountRuleDependencies;
  excludedItems: DiscountRuleDependencies;
  status: DiscountRuleStatus;
  useCheckbox: boolean;
  voucherCode?: string | null;
  applyToWholeCart: boolean;
  dependencyRelation: DiscountRuleDependencyRelation;
};

const dependenciesSchema = object({
  productIds: array().of(string().required()).nullable(),
  courseIds: array().of(string().required()).nullable(),
  courseUnitIds: array().of(string().required()).nullable(),
  courseModuleIds: array().of(string().required()).nullable(),
  categoryIds: array().of(string().required()).nullable(),
});

const schema = object({
  title: string().required(),
  description: string().required(),
  dependencyRelation: string()
    .oneOf([
      DiscountRuleDependencyRelation.And,
      DiscountRuleDependencyRelation.Or,
    ])
    .required(), // .required(), // Does not work, IDKW.
  type: string()
    .oneOf([DiscountType.Absolute, DiscountType.Relative])
    .required(),
  status: string()
    .oneOf([DiscountRuleStatus.Active, DiscountRuleStatus.Inactive])
    .required(),
  amount: number().min(0).required(),
  enterpriseId: string().required(),
  useCheckbox: boolean().default(false),
  voucherCode: string().nullable(),
  applyToWholeCart: boolean().default(false),
  dependencies: dependenciesSchema,
  excludedItems: dependenciesSchema,
});

export type Schema = InferType<typeof schema>;

type DiscountRuleEditorParams = {
  id?: string;
};

export const DiscountRuleEditor: ComponentType<
  RouteComponentProps<DiscountRuleEditorParams>
> = (props) => {
  const profile = Profile.use();
  const _ = Translator.useTranslator();
  const discountRuleQuery = useDiscountRuleQuery({
    variables: { id: props.id },
    skip: props.id === undefined,
  });

  const productsQuery = useProductsQuery({
    variables: { pagination: { skip: 0, take: 999 } },
  });

  const coursesQuery = useAvailableCoursesForDiscountsQuery();
  const courseUnitsQuery = useAvailableCourseUnitsQuery();
  const courseModulesQuery = useAvailableCourseModulesQuery();

  const [save] = useSaveDiscountRuleMutation();

  const { modalDelete, askForDelete } = useDiscountRuleActions(() =>
    navigate('/products/discounts'),
  );

  const form = useForm<Schema>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
    defaultValues: {
      enterpriseId: profile.selectedEnterprise?.id,
      type: DiscountType.Absolute,
      status: DiscountRuleStatus.Active,
      useCheckbox: false,
      dependencyRelation: DiscountRuleDependencyRelation.And,
      applyToWholeCart: true,
      dependencies: {
        categoryIds: [],
        courseIds: [],
        courseUnitIds: [],
        courseModuleIds: [],
        productIds: [],
      },
      excludedItems: {
        categoryIds: [],
        courseIds: [],
        courseUnitIds: [],
        courseModuleIds: [],
        productIds: [],
      },
    },
  });
  const { handleSubmit } = form;

  const { availableEnterprises, availableCourseCategories, reload } =
    useResources();

  const saveRule = useCallback(
    async (
      id: string | undefined,
      input: SaveDiscountRuleInput,
      force?: boolean,
    ) => {
      const newRule = await save({
        variables: {
          id,
          input,
          force,
        },
      });
      reload();
      success('Die Rabatt-Regel wurde gespeichert.');
      if (!id) {
        navigate(`./${newRule.data?.saveDiscountRule.id}/edit`);
      }
    },
    [],
  );

  const [modal, askForForce] = useConfirm(
    async (id: string | undefined, input: SaveDiscountRuleInput) => {
      try {
        await saveRule(id, input, true);
      } catch (e) {
        error('Bei Speichern der Rabatt-Regel ist ein Fehler aufgetreten.');
      }
    },
  );

  const onSubmit = useMemo(() => {
    return handleSubmit(
      async (input) => {
        try {
          await saveRule(props.id, input);
        } catch (e: any) {
          if (e.message === 'duplicate-rule') {
            askForForce(
              'Es besteht bereits eine Regel mit gleichen Abhängigkeiten. Gegebenenfalls werden beim Kauf mehrere Rabatte angewendet. Trotzdem speichern?',
              props.id,
              input,
            );
          } else {
            error('Bei Speichern der Rabatt-Regel ist ein Fehler aufgetreten.');
          }
        }
      },
      (e) => {
        error('Ein Fehler ist aufgetreten.');
        //highlightTabsWithFields(Object.keys(e));
        console.error(e, form.getValues());
      },
    );
  }, [props.id, handleSubmit]);

  const persisted =
    props.id !== undefined || discountRuleQuery.data !== undefined;

  const dependencyRalation = form.watch('dependencyRelation');
  const type = form.watch('type');
  const amount = form.watch('amount');
  const isSuper = useMemo(() => isSuperAdmin(profile), [profile]);

  const transformedAmount = useMemo(() => {
    if (isNaN(amount)) {
      return undefined;
    }
    if (type === DiscountType.Relative) {
      return amount * 100;
    }
    return amount;
  }, [amount, type]);
  useEffect(() => {
    if (discountRuleQuery.data?.discountRule) {
      form.setValue('amount', discountRuleQuery.data.discountRule.amount);
    }
  }, [discountRuleQuery.data]);

  return (
    <>
      <Frame.Title>Rabatte</Frame.Title>
      <Frame.SubTitle>
        {persisted
          ? discountRuleQuery.data?.discountRule.title
          : 'Neue Rabatt-Regel'}
        {}
      </Frame.SubTitle>
      <Frame.ActionBar>
        {discountRuleQuery.data && (
          <Button
            error
            label="Löschen"
            onClick={() => askForDelete(discountRuleQuery.data?.discountRule!)}
          />
        )}
        <Button label="Zurück" linkTo="/products/discounts" />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        {discountRuleQuery.loading && <Loader />}
        {modalDelete}
        {modal}
        <Form form={form} values={discountRuleQuery.data?.discountRule as any}>
          <Tabs
            tabs={[
              {
                title: 'Allgemein',
                content: (
                  <Grid.Row>
                    <Grid.Column>
                      <Section title="Rabatt">
                        <Form.Input
                          name="title"
                          label="Titel (zur internen Erkennbarkeit)"
                        />
                        <Form.Textarea
                          name="description"
                          label="Beschreibung (wird im Warenkorb kommuniziert)"
                        />
                        <Form.Select
                          label="Status"
                          name="status"
                          choices={Object.values(DiscountRuleStatus).map(
                            (s) => ({
                              label: _(`status.${s}`),
                              value: s,
                            }),
                          )}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Section title="Anwendung">
                        <Form.Select
                          label="Kontext"
                          name="applyToWholeCart"
                          choices={[
                            {
                              label: 'Global - auf ganzen Warenkorb',
                              value: 'true',
                            },
                            {
                              label: 'Lokal - auf Einzelposition(en)',
                              value: 'false',
                            },
                          ]}
                        />
                        <Form.Select
                          label="Art"
                          name="type"
                          choices={Object.values(DiscountType).map((d) => ({
                            label: _(`discount-type.${d}`),
                            value: d,
                          }))}
                        />
                        {type === DiscountType.Absolute && (
                          <Form.Currency name="amount" label="Wert" />
                        )}
                        {type === DiscountType.Relative && (
                          <Form.Input
                            type="number"
                            name="amount"
                            label="Wert in %"
                          />
                        )}
                      </Section>
                      <Section title="Weitere Trigger">
                        <Form.Input name="voucherCode" label="Voucher-Code" />
                        <Form.Checkbox
                          name="useCheckbox"
                          label="Auslösen durch Checkbox"
                        />
                      </Section>
                      {isSuper && (
                        <Section title="Zugehörigkeit">
                          <Form.Select
                            choices={availableEnterprises.map((e) => ({
                              label: e.name,
                              value: e.id,
                            }))}
                            name="enterpriseId"
                            label="Unternehmen"
                          />
                        </Section>
                      )}
                      {!isSuper && (
                        <Form.Input type="hidden" name="enterpriseId" />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ),
              },
              {
                title: 'Abhängigkeiten',
                content: (
                  <Grid.Row>
                    <Grid.Column>
                      <Section title="Abhängigkeiten">
                        <InfoBox
                          header={
                            dependencyRalation ===
                            DiscountRuleDependencyRelation.And
                              ? 'Und-Beziehung'
                              : 'Oder-Beziehung'
                          }
                          content={
                            dependencyRalation ===
                            DiscountRuleDependencyRelation.And ? (
                              <>
                                Der Rabatt wird ausgelöst, wenn <em>alle</em>{' '}
                                dieser Einträge im Warenkorb enthalten sind{' '}
                                <em>oder</em> ein Voucher-Code hinterlegt oder
                                der Checkbox-Trigger aktiviert ist.
                              </>
                            ) : (
                              <>
                                Der Rabatt wird ausgelöst, wenn{' '}
                                <em>mindestens einer</em> dieser Einträge im
                                Warenkorb enthalten ist <em>oder</em> ein
                                Voucher-Code hinterlegt oder der
                                Checkbox-Trigger aktiviert ist.
                              </>
                            )
                          }
                        />

                        <Form.Select2
                          name="dependencyRelation"
                          label="Operator für Abhängigkeiten"
                          choices={Object.keys(
                            DiscountRuleDependencyRelation,
                          ).map((r) => ({
                            label: _(`discount_rule_dependency_relation.${r}`),
                            value: r,
                          }))}
                        />

                        <Form.Select2
                          multiple
                          label="Kategorien"
                          choices={
                            availableCourseCategories
                              .filter((c) => c.isLeaf)
                              .map((p) => ({
                                label: p.verboseTitle || p.title,
                                value: p.id,
                              })) || []
                          }
                          name="dependencies.categoryIds"
                        />
                        <Form.Select2
                          multiple
                          label="Kurse"
                          choices={
                            coursesQuery.data?.adminCourses.data.map((p) => ({
                              label: p.verboseTitle || p.title,
                              value: p.id,
                            })) || []
                          }
                          name="dependencies.courseIds"
                        />
                        <Form.Select2
                          multiple
                          label="Kurseinheiten"
                          choices={
                            courseUnitsQuery.data?.adminCourseUnits.map(
                              (u) => ({
                                label: `${
                                  u.title || u.course.title
                                } (${getCourseUnitDates(
                                  u,
                                )}, ${getCourseUnitLocationLabel(u)})`,
                                value: u.id,
                              }),
                            ) || []
                          }
                          name="dependencies.courseUnitIds"
                        />
                        <Form.Select2
                          multiple
                          label="Kursmodule"
                          choices={
                            courseModulesQuery.data?.adminCourseModules.map(
                              (m) => ({
                                label: `${
                                  m.title || m.courseUnit.course.title
                                } (${getCourseUnitDates(
                                  m,
                                )}, ${getCourseUnitLocationLabel(m)})`,
                                value: m.id,
                              }),
                            ) || []
                          }
                          name="dependencies.courseModuleIds"
                        />
                        <Form.Select2
                          multiple
                          label="Produkte"
                          choices={
                            productsQuery.data?.adminProducts.data.map((p) => ({
                              label: p.title,
                              value: p.id,
                            })) || []
                          }
                          name="dependencies.productIds"
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Section title="Ausschlüsse">
                        <InfoBox
                          header="Erklärung"
                          content={
                            <>
                              Die folgenden Kurse werden von der globalen oder
                              lokalen Rabattierung ausgeschlossen und nicht
                              rabattiert.
                              <br />
                              Bei einem <em>globalem Rabatt</em> mit{' '}
                              <em>absoluter Rabattierung</em> ergeben
                              Ausschlüsse keinen Sinn und werden ignoriert.
                            </>
                          }
                        />
                        <Form.Select2
                          multiple
                          label="Kategorien"
                          choices={
                            availableCourseCategories
                              .filter((c) => c.isLeaf)
                              .map((p) => ({
                                label: p.verboseTitle || p.title,
                                value: p.id,
                              })) || []
                          }
                          name="excludedItems.categoryIds"
                        />
                        <Form.Select2
                          multiple
                          label="Kurse"
                          choices={
                            coursesQuery.data?.adminCourses.data.map((p) => ({
                              label: p.verboseTitle || p.title,
                              value: p.id,
                            })) || []
                          }
                          name="excludedItems.courseIds"
                        />
                        <Form.Select2
                          multiple
                          label="Kurseinheiten"
                          choices={
                            courseUnitsQuery.data?.adminCourseUnits.map(
                              (u) => ({
                                label: `${
                                  u.title || u.course.title
                                } (${getCourseUnitDates(
                                  u,
                                )}, ${getCourseUnitLocationLabel(u)})`,
                                value: u.id,
                              }),
                            ) || []
                          }
                          name="excludedItems.courseUnitIds"
                        />
                        <Form.Select2
                          multiple
                          label="Kursmodule"
                          choices={
                            courseModulesQuery.data?.adminCourseModules.map(
                              (m) => ({
                                label: `${
                                  m.title || m.courseUnit.course.title
                                } (${getCourseUnitDates(
                                  m,
                                )}, ${getCourseUnitLocationLabel(m)})`,
                                value: m.id,
                              }),
                            ) || []
                          }
                          name="excludedItems.courseModuleIds"
                        />
                        <Form.Select2
                          multiple
                          label="Produkte"
                          choices={
                            productsQuery.data?.adminProducts.data.map((p) => ({
                              label: p.title,
                              value: p.id,
                            })) || []
                          }
                          name="excludedItems.productIds"
                        />
                      </Section>
                    </Grid.Column>
                  </Grid.Row>
                ),
              },
            ]}
          />
        </Form>
      </Frame.Content>
    </>
  );
};
