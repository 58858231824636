import { CKEditor } from '@ckeditor/ckeditor5-react';
import VoltaClassicEditor from '@lib/ckeditor5-build';
import classNames from 'classnames';
import propertyPath from 'property-path';
import React, { forwardRef, useMemo, useState } from 'react';
import { FormState } from 'react-hook-form';
import globalStyles from 'styles/global.scss';
import { Button } from '../button/button.component';
import config from './ckeditor-config';
import styles from './html-field.module.scss';

export interface HtmlFieldProps {
  value?: string | null;
  name?: string;
  onChange?: (e: any) => void;
  error?: string;
  label?: string | null;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
}

export const HtmlField = forwardRef<HTMLTextAreaElement, HtmlFieldProps>(
  (props, ref) => {
    const { error, formState, label, value, onChange, ...rest } = props;
    const [mode, setMode] = useState<'text' | 'wysiwyg'>('wysiwyg');

    const errorMessage = useMemo(() => {
      if (props.name && formState) {
        return propertyPath.get(formState.errors, props.name)?.message;
      }
      return error;
    }, [error, formState, props.name]);

    return (
      <div className={styles.host}>
        <div className={styles.top}>
          {label && (
            <label>
              {label}
              {props.required && ' *'}
            </label>
          )}
          <div className={styles.actions}>
            <Button
              primary={mode === 'wysiwyg'}
              small
              label="WYSIWYG"
              onClick={() => setMode('wysiwyg')}
            />
            <Button
              primary={mode === 'text'}
              small
              label="Quelltext"
              onClick={() => setMode('text')}
            />
          </div>
        </div>

        <div
          className={classNames(
            styles.conditional,
            mode === 'wysiwyg' && styles.visible,
          )}
        >
          <CKEditor
            onReady={(editor) => {
              editor.setData(value || '');
            }}
            config={config}
            editor={VoltaClassicEditor}
            data={value || ''}
            /* onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
          }} */
            onChange={(event, editor) => {
              const data = editor.getData();
              if (onChange) {
                onChange(data);
              }
            }}
          />
        </div>
        <div
          className={classNames(
            styles.conditional,
            mode === 'text' && styles.visible,
          )}
        >
          <textarea
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value);
              }
            }}
            value={value || ''}
          />
        </div>

        {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
      </div>
    );
  },
);
