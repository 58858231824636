import { getPersonDetailLink } from '@/contact/person/utils';
import { PersonView } from '@/contact/person/view/person-view.component';
import { Person } from '@/graphql/generated/types';
import { useInquiryActions } from '@/inquiry/shared/actions.hooks';
import { useAssigneeActions } from '@/inquiry/shared/assignee-actions.component';
import { Box } from '@/ui/box/box.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Grid } from '@/ui/grid/grid.component';
import { HorizontalTable } from '@/ui/horizontal-table/horizontal-table.component';
import { LabeledItem } from '@/ui/labeled-item/labeled-item.component';
import { Loader } from '@/ui/loader/loader.component';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { Translator } from 'shared/translator/translator.component';
import { Frame } from '../../../frame';
import { Button } from '../../../ui/button/button.component';
import { useInquiryQuery } from './editor.generated';

type InquiryEditorParams = {
  id?: string;
};

export const Editor: ComponentType<RouteComponentProps<InquiryEditorParams>> = (
  props,
) => {
  const _ = Translator.useTranslator();
  const inquiryQuery = useInquiryQuery({
    variables: { id: props.id },
    skip: props.id === undefined,
  });

  const inquiry = inquiryQuery.data?.adminInquiry;
  const { actionsForInquiry } = useAssigneeActions(() => {
    inquiryQuery.refetch();
  });

  const { modalDelete, askForDelete } = useInquiryActions(() => {
    navigate('/inquiries');
  });

  if (!inquiry) {
    return <Loader fullscreen />;
  }

  const content = inquiry.content ? JSON.parse(inquiry.content) : null;

  return (
    <>
      <Frame.SubTitle>
        Anfrage vom <Formatted.Date value={inquiry.createdAt} withTime />
      </Frame.SubTitle>
      <Frame.ActionBar>
        <Button error label="Löschen" onClick={() => askForDelete(inquiry)} />
        {actionsForInquiry(inquiry).map((a) => (
          <Button key={a.label} {...a} />
        ))}
        <Button label="Zurück" linkTo="/inquiries" />
      </Frame.ActionBar>
      <Frame.Content>
        {modalDelete}

        <Grid.Row>
          <Grid.Column bigger>
            <Box title="Anfrage" withMargin>
              <LabeledItem label="Thema" value={inquiry.subject} />

              {content && (
                <LabeledItem
                  label="Weitere Angaben"
                  value={<HorizontalTable data={content} />}
                />
              )}
              {(inquiry as any).location && (
                <LabeledItem
                  label="Standort"
                  value={(inquiry as any).location?.name}
                />
              )}
              <LabeledItem label="Herkunft" value={inquiry.origin} />
              <LabeledItem
                label="Eingegangen am"
                value={<Formatted.Date value={inquiry.createdAt} withTime />}
              />
            </Box>
            <Box title="Status">
              <LabeledItem
                label="Eingegangen am"
                value={<Formatted.Date value={inquiry.createdAt} withTime />}
              />
              <LabeledItem
                label="Zugewiesen am"
                value={<Formatted.Date value={inquiry.assignedAt} withTime />}
              />
              <LabeledItem
                label="Bearbeitet von"
                value={inquiry.assignee?.fullName}
              />
              <LabeledItem
                label="Geschlossen am"
                value={<Formatted.Date value={inquiry.closedAt} withTime />}
              />
            </Box>
          </Grid.Column>
          <Grid.Column smaller>
            <Box
              title={
                <>
                  Person{' '}
                  <Button
                    small
                    primary
                    href={getPersonDetailLink(inquiry.person)}
                    label="Details"
                  />
                </>
              }
            >
              <PersonView person={inquiry.person as Person} />
            </Box>
          </Grid.Column>
        </Grid.Row>
      </Frame.Content>
    </>
  );
};
