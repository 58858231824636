import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DetailEquipmentDataFragment = { __typename?: 'Equipment', id: any, name: string, description: string };

export type EquipmentQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type EquipmentQuery = { __typename?: 'Query', adminEquipment: { __typename?: 'Equipment', id: any, name: string, description: string } };

export type CreateEquipmentMutationVariables = Types.Exact<{
  input: Types.CreateEquipmentInput;
}>;


export type CreateEquipmentMutation = { __typename?: 'Mutation', adminCreateEquipment: { __typename?: 'Equipment', id: any, name: string, description: string } };

export type EditEquipmentMutationVariables = Types.Exact<{
  input: Types.EditEquipmentInput;
  id: Types.Scalars['UUID'];
}>;


export type EditEquipmentMutation = { __typename?: 'Mutation', adminEditEquipment: { __typename?: 'Equipment', id: any, name: string, description: string } };

export const DetailEquipmentDataFragmentDoc = gql`
    fragment DetailEquipmentData on Equipment {
  id
  name
  description
}
    `;
export const EquipmentDocument = gql`
    query equipment($id: UUID!) {
  adminEquipment(id: $id) {
    ...DetailEquipmentData
  }
}
    ${DetailEquipmentDataFragmentDoc}`;
export type EquipmentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EquipmentQuery, EquipmentQueryVariables>, 'query'> & ({ variables: EquipmentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EquipmentComponent = (props: EquipmentComponentProps) => (
      <ApolloReactComponents.Query<EquipmentQuery, EquipmentQueryVariables> query={EquipmentDocument} {...props} />
    );
    

/**
 * __useEquipmentQuery__
 *
 * To run a query within a React component, call `useEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEquipmentQuery(baseOptions: Apollo.QueryHookOptions<EquipmentQuery, EquipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EquipmentQuery, EquipmentQueryVariables>(EquipmentDocument, options);
      }
export function useEquipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquipmentQuery, EquipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EquipmentQuery, EquipmentQueryVariables>(EquipmentDocument, options);
        }
export type EquipmentQueryHookResult = ReturnType<typeof useEquipmentQuery>;
export type EquipmentLazyQueryHookResult = ReturnType<typeof useEquipmentLazyQuery>;
export type EquipmentQueryResult = Apollo.QueryResult<EquipmentQuery, EquipmentQueryVariables>;
export const CreateEquipmentDocument = gql`
    mutation createEquipment($input: CreateEquipmentInput!) {
  adminCreateEquipment(input: $input) {
    ...DetailEquipmentData
  }
}
    ${DetailEquipmentDataFragmentDoc}`;
export type CreateEquipmentMutationFn = Apollo.MutationFunction<CreateEquipmentMutation, CreateEquipmentMutationVariables>;
export type CreateEquipmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEquipmentMutation, CreateEquipmentMutationVariables>, 'mutation'>;

    export const CreateEquipmentComponent = (props: CreateEquipmentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEquipmentMutation, CreateEquipmentMutationVariables> mutation={CreateEquipmentDocument} {...props} />
    );
    

/**
 * __useCreateEquipmentMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentMutation, { data, loading, error }] = useCreateEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEquipmentMutation, CreateEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEquipmentMutation, CreateEquipmentMutationVariables>(CreateEquipmentDocument, options);
      }
export type CreateEquipmentMutationHookResult = ReturnType<typeof useCreateEquipmentMutation>;
export type CreateEquipmentMutationResult = Apollo.MutationResult<CreateEquipmentMutation>;
export type CreateEquipmentMutationOptions = Apollo.BaseMutationOptions<CreateEquipmentMutation, CreateEquipmentMutationVariables>;
export const EditEquipmentDocument = gql`
    mutation editEquipment($input: EditEquipmentInput!, $id: UUID!) {
  adminEditEquipment(input: $input, id: $id) {
    ...DetailEquipmentData
  }
}
    ${DetailEquipmentDataFragmentDoc}`;
export type EditEquipmentMutationFn = Apollo.MutationFunction<EditEquipmentMutation, EditEquipmentMutationVariables>;
export type EditEquipmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditEquipmentMutation, EditEquipmentMutationVariables>, 'mutation'>;

    export const EditEquipmentComponent = (props: EditEquipmentComponentProps) => (
      <ApolloReactComponents.Mutation<EditEquipmentMutation, EditEquipmentMutationVariables> mutation={EditEquipmentDocument} {...props} />
    );
    

/**
 * __useEditEquipmentMutation__
 *
 * To run a mutation, you first call `useEditEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEquipmentMutation, { data, loading, error }] = useEditEquipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<EditEquipmentMutation, EditEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEquipmentMutation, EditEquipmentMutationVariables>(EditEquipmentDocument, options);
      }
export type EditEquipmentMutationHookResult = ReturnType<typeof useEditEquipmentMutation>;
export type EditEquipmentMutationResult = Apollo.MutationResult<EditEquipmentMutation>;
export type EditEquipmentMutationOptions = Apollo.BaseMutationOptions<EditEquipmentMutation, EditEquipmentMutationVariables>;