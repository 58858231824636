export default {
  ////// CONFIGURATION TEMPLATE: uncomment desired lines to override default config.
  ////// While all lines are commented, config will not change anything.
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'unemphasize',
      'link',
      'alignment',
      '|',
      'bulletedList',
      'numberedList',
      'insertTable',
      // 'strapiMediaLib',
      'insertImage',
      'openExplorer',
      'accordion',
      'horizontalLine',
      'htmlEmbed',
      '|',
      'undo',
      'redo',
      'fullscreen',
    ],
    // items: [
    //     "heading",
    //     "|",
    //     "fontFamily",
    //     "fontSize",
    //     "fontColor",
    //     "|",
    //     "bold",
    //     "italic",
    //     "underline",
    //     "strikethrough",
    //     "subscript",
    //     "superscript",
    //     "removeFormat",
    //     "code",
    //     "link",
    //     "bulletedList",
    //     "numberedList",
    //     "todoList",
    //     "insertImage",
    //     "strapiMediaLib",
    //     "|",
    //     "alignment",
    //     "indent",
    //     "outdent",
    //     "|",
    //     "specialCharacters",
    //     "blockQuote",
    //     "insertTable",
    //     "mediaEmbed",
    //     "htmlEmbed",
    //     "codeBlock",
    //     "horizontalLine",
    //     "|",
    //     "fullScreen",
    //     "undo",
    //     "redo",
    // ],
    // shouldNotGroupWhenFull: true
  },
  image: {
    // styles: [
    //     "alignLeft",
    //     "alignCenter",
    //     "alignRight",
    // ],
    // resizeOptions: [
    //     {
    //         name: "resizeImage:original",
    //         value: null,
    //         icon: "original"
    //     },
    //     {
    //         name: "resizeImage:50",
    //         value: "50",
    //         icon: "medium"
    //     },
    //     {
    //         name: "resizeImage:75",
    //         value: "75",
    //         icon: "large"
    //     }
    // ],
    // toolbar: [
    //     "imageStyle:alignLeft",
    //     "imageStyle:alignCenter",
    //     "imageStyle:alignRight",
    //     "|",
    //     "imageTextAlternative",
    //     "|",
    //     "resizeImage:50",
    //     "resizeImage:75",
    //     "resizeImage:original",
    //     "|",
    //     "linkImage",
    // ]
  },
  table: {
    // contentToolbar: [
    //     "tableColumn",
    //     "tableRow",
    //     "mergeTableCells",
    //     "tableProperties",
    //     "tableCellProperties",
    // ]
  },
  heading: {
    // options: [
    //     { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
    //     { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
    //     { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
    //     { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
    //     { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
    // ]
  },
  htmlEmbed: {
    // showPreviews: true,
  },
  fontFamily: {
    // options: [
    //   "default",
    //   "Arial, Helvetica, sans-serif",
    //   "Courier New, Courier, monospace",
    //   "Georgia, serif",
    //   "Lucida Sans Unicode, Lucida Grande, sans-serif",
    //   "Tahoma, Geneva, sans-serif",
    //   "Times New Roman, Times, serif",
    //   "Trebuchet MS, Helvetica, sans-serif",
    //   "Verdana, Geneva, sans-serif",
    //   "JetBrains Mono, monospace",
    //   "Lato, Inter, sans-serif",
    // ],
  },
  link: {
    defaultProtocol: 'https://',
    decorators: [
      {
        mode: 'manual',
        label: 'Open in a new tab',
        defaultValue: true,
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
      {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'download',
        },
      },
      {
        mode: 'manual',
        label: 'Link URL is a navigation node ID',
        attributes: {
          class: 'navigation-id-link',
        },
      },
      {
        mode: 'manual',
        label: 'Link URL is an EDI course ID',
        attributes: {
          class: 'course-id-link',
        },
      },
    ],
  },
};
