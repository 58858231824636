import { EventInterface } from '@/graphql/generated/types';
import React, {
  ComponentType,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DeepPartial } from 'react-hook-form';

const EventListContext = createContext<
  | [
      EnhancedEventInterface[],
      Dispatch<SetStateAction<EnhancedEventInterface[]>>,
    ]
  | undefined
>(undefined);

export interface EnhancedEventInterface extends DeepPartial<EventInterface> {
  startDate: Date;
  endDate: Date;
  isPreview?: true;
}

export const EventListProvider: ComponentType = ({ children }) => {
  const state = useState<EnhancedEventInterface[]>([]);
  return (
    <EventListContext.Provider value={state}>
      {children}
    </EventListContext.Provider>
  );
};

interface EventListApi<T> {
  events: (T & EnhancedEventInterface)[];
  setEvents: Dispatch<SetStateAction<(T & EnhancedEventInterface)[]>>;
}

export function useEventList<T>(): EventListApi<T> {
  const context = useContext(EventListContext);

  if (!context) {
    throw new Error('EventListContext not ready');
  }

  const [events, setEvents] = context;

  const api = useMemo(
    () => ({
      events,
      setEvents,
    }),
    [events, setEvents],
  );

  return api as any;
}
