import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import { FileListDataFragmentDoc } from '../explorer/explorer.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PaginatedFilesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.FilterFileInput>;
  pagination: Types.PaginationInput;
}>;


export type PaginatedFilesQuery = { __typename?: 'Query', adminPaginatedFiles: { __typename?: 'PaginatedFilesystemFileResult', totalCount: number, data: Array<{ __typename?: 'FilesystemFile', id: any, name: string, url: string, humanUrl: string, isImage: boolean, isRasterizedImage: boolean, group: Types.FileGroup, createdAt: any, mimetype?: string | null, enterpriseId?: any | null, enterprise?: { __typename?: 'Enterprise', id: any, name: string } | null }> } };

export type DeleteFileMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', adminDeleteFile: boolean };

export type ReplaceFileMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['UUID']>;
  input: Types.FileUploadInput;
  file: Types.Scalars['Upload'];
}>;


export type ReplaceFileMutation = { __typename?: 'Mutation', adminUploadFile: { __typename?: 'FilesystemFile', id: any, name: string, url: string, group: Types.FileGroup, enterpriseId?: any | null } };


export const PaginatedFilesDocument = gql`
    query paginatedFiles($filter: FilterFileInput, $pagination: PaginationInput!) {
  adminPaginatedFiles(filter: $filter, pagination: $pagination) {
    totalCount
    data {
      ...FileListData
    }
  }
}
    ${FileListDataFragmentDoc}`;
export type PaginatedFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PaginatedFilesQuery, PaginatedFilesQueryVariables>, 'query'> & ({ variables: PaginatedFilesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PaginatedFilesComponent = (props: PaginatedFilesComponentProps) => (
      <ApolloReactComponents.Query<PaginatedFilesQuery, PaginatedFilesQueryVariables> query={PaginatedFilesDocument} {...props} />
    );
    

/**
 * __usePaginatedFilesQuery__
 *
 * To run a query within a React component, call `usePaginatedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedFilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginatedFilesQuery(baseOptions: Apollo.QueryHookOptions<PaginatedFilesQuery, PaginatedFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedFilesQuery, PaginatedFilesQueryVariables>(PaginatedFilesDocument, options);
      }
export function usePaginatedFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedFilesQuery, PaginatedFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedFilesQuery, PaginatedFilesQueryVariables>(PaginatedFilesDocument, options);
        }
export type PaginatedFilesQueryHookResult = ReturnType<typeof usePaginatedFilesQuery>;
export type PaginatedFilesLazyQueryHookResult = ReturnType<typeof usePaginatedFilesLazyQuery>;
export type PaginatedFilesQueryResult = Apollo.QueryResult<PaginatedFilesQuery, PaginatedFilesQueryVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($id: UUID!) {
  adminDeleteFile(id: $id)
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;
export type DeleteFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFileMutation, DeleteFileMutationVariables>, 'mutation'>;

    export const DeleteFileComponent = (props: DeleteFileComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFileMutation, DeleteFileMutationVariables> mutation={DeleteFileDocument} {...props} />
    );
    

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const ReplaceFileDocument = gql`
    mutation replaceFile($id: UUID, $input: FileUploadInput!, $file: Upload!) {
  adminUploadFile(id: $id, file: $file, input: $input) {
    id
    name
    url
    group
    enterpriseId
  }
}
    `;
export type ReplaceFileMutationFn = Apollo.MutationFunction<ReplaceFileMutation, ReplaceFileMutationVariables>;
export type ReplaceFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplaceFileMutation, ReplaceFileMutationVariables>, 'mutation'>;

    export const ReplaceFileComponent = (props: ReplaceFileComponentProps) => (
      <ApolloReactComponents.Mutation<ReplaceFileMutation, ReplaceFileMutationVariables> mutation={ReplaceFileDocument} {...props} />
    );
    

/**
 * __useReplaceFileMutation__
 *
 * To run a mutation, you first call `useReplaceFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceFileMutation, { data, loading, error }] = useReplaceFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useReplaceFileMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceFileMutation, ReplaceFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceFileMutation, ReplaceFileMutationVariables>(ReplaceFileDocument, options);
      }
export type ReplaceFileMutationHookResult = ReturnType<typeof useReplaceFileMutation>;
export type ReplaceFileMutationResult = Apollo.MutationResult<ReplaceFileMutation>;
export type ReplaceFileMutationOptions = Apollo.BaseMutationOptions<ReplaceFileMutation, ReplaceFileMutationVariables>;