import { Loader } from '@/ui/loader/loader.component';
import React, { ComponentType, useEffect } from 'react';
import {
  CourseUnitFilterType,
  CourseUnitListFilter,
} from 'shared/course/course-unit-list/filter.component';
import { useUrlQuery } from 'shared/hooks/url-query';
import { useTreeEdit } from 'shared/tree-edit/tree-edit.provider';
import { CourseUnitParticipants } from '../course-unit-participants/course-unit-participants.component';
import { useCourseWithCourseUnitsAndBookingsLazyQuery } from './course-unit-list.generated';

interface CourseUnitListProps {}

export const CourseUnitList: ComponentType<CourseUnitListProps> = (props) => {
  const { currentCourseAndEnterprise } = useTreeEdit();
  const [currentCourse, enterpriseId] = currentCourseAndEnterprise || [];
  const [filter] = useUrlQuery<CourseUnitFilterType>();

  const [refetchUnits, { data, loading: loadingUnits }] =
    useCourseWithCourseUnitsAndBookingsLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    refetchUnits({
      variables: {
        id: currentCourse,
        options: {
          ...filter,
          includeInactive: filter.includeInactive === 'true' ? true : false,
          isOnline: filter.isOnline === 'true' ? true : false,
          isCorrespondenceCourse:
            filter.isCorrespondenceCourse === 'true' ? true : false,
          sortByStartDate: filter.sortByStartDate === 'DESC' ? 'DESC' : 'ASC',
        },
      },
    });
  }, [currentCourse, filter]);

  return (
    <>
      <CourseUnitListFilter />
      {loadingUnits && <Loader />}
      {data?.adminCourse.courseUnitsForAdmin.map((courseUnit) => (
        <CourseUnitParticipants
          key={courseUnit.id}
          courseTitle={data.adminCourse.title}
          courseUnit={courseUnit}
        />
      ))}
    </>
  );
};
