import { User, UserStatus } from '@/graphql/generated/types';
import { Chip } from '@/ui/chip/chip.component';
import React, { ComponentType } from 'react';
import { Translator } from 'shared/translator/translator.component';

interface UserStatusChipProps {
  user?: Pick<User, 'status'> | null;
}

export const UserStatusChip: ComponentType<UserStatusChipProps> = (props) => {
  const { user } = props;
  const _ = Translator.useTranslator();
  return (
    <Chip
      meaning={user?.status === UserStatus.Registered ? 'success' : undefined}
    >
      {_(`user-status.${user?.status}`)}
    </Chip>
  );
};
