import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CompanyListDataFragment = { __typename?: 'Company', id: any, name: string, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }>, address: { __typename?: 'Address', city?: string | null } };

export type CompaniesQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  filter?: Types.InputMaybe<Types.FilterCompaniesInput>;
}>;


export type CompaniesQuery = { __typename?: 'Query', adminCompanies: { __typename?: 'PaginatedCompanyResult', totalCount: number, data: Array<{ __typename?: 'Company', id: any, name: string, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }>, address: { __typename?: 'Address', city?: string | null } }> } };

export const CompanyListDataFragmentDoc = gql`
    fragment CompanyListData on Company {
  id
  name
  enterprises {
    id
    name
  }
  address {
    city
  }
}
    `;
export const CompaniesDocument = gql`
    query companies($pagination: PaginationInput!, $filter: FilterCompaniesInput) {
  adminCompanies(pagination: $pagination, filter: $filter) {
    totalCount
    data {
      ...CompanyListData
    }
  }
}
    ${CompanyListDataFragmentDoc}`;
export type CompaniesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompaniesQuery, CompaniesQueryVariables>, 'query'> & ({ variables: CompaniesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompaniesComponent = (props: CompaniesComponentProps) => (
      <ApolloReactComponents.Query<CompaniesQuery, CompaniesQueryVariables> query={CompaniesDocument} {...props} />
    );
    

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;