import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputField } from '../input-field/input-field.component';

interface DebouncedInputProps {
  placeholder?: string;
  label?: string;
  value?: string;
  onChange(value: string): void;
  debounceRate;
}

export const DebouncedInput = (props: DebouncedInputProps) => {
  const { value, onChange, placeholder = '', debounceRate, label } = props;
  const timeoutRef = useRef<any>();
  const [searchValue, setSearchValue] = useState(value);
  const isInitial = useRef(true);

  const onValueChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      event.persist();
      const { value } = event.currentTarget;
      setSearchValue(value);
    },
    [],
  );

  useEffect(() => {
    if (!isInitial.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onChange(searchValue || '');
      }, debounceRate);
    }
    isInitial.current = false;
  }, [searchValue, debounceRate]);

  return (
    <InputField
      label={label}
      placeholder={placeholder}
      value={searchValue}
      onChange={onValueChange}
    />
  );
};
