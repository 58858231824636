import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { View } from './detail/view/view.component';
import { List } from './list/list.component';

export const BookingRoutes: ComponentType<RouteComponentProps> = (props) => {
  return (
    <Router className="router">
      <List path="list" />
      <View path=":id/view" />

      <Redirect noThrow from="/" to="list" />
    </Router>
  );
};
