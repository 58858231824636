import { useUploadFileMutation } from '@/file/explorer/explorer.generated';
import { FileGroup } from '@/graphql/generated/types';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { ImageChooser } from '@/ui/image-chooser/image-chooser.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { useUserStatusChoices } from '@/user/status/user-status-choices';
import React, { ComponentType, useCallback } from 'react';
import { PersonQuery } from '../base/base-editor.generated';
import { useUpdateTeacherFilesMutation } from './editor.generated';

type TeacherEditorProps = {
  user?: PersonQuery['person']['user'];
  person?: PersonQuery['person'];
};

export const TeacherEditor: ComponentType<TeacherEditorProps> = (props) => {
  const { user, person } = props;

  const [updateTeacherFiles] = useUpdateTeacherFilesMutation();
  const [uploadFile] = useUploadFileMutation();

  const onUploadImage = useCallback(
    async (file) => {
      if (
        user &&
        person &&
        person.enterpriseIds &&
        person.enterpriseIds.length > 0
      ) {
        const result = await uploadFile({
          variables: {
            file,
            input: {
              enterpriseId: person.enterpriseIds[0],
              group: FileGroup.Teacher,
            },
          },
        });

        if (result.data) {
          await updateTeacherFiles({
            variables: {
              id: user.id,
              input: { imageId: result.data?.adminUploadFile.id },
            },
          });
          success('Das Dozenten-Bild wurde erfolgreich gespeichert..');
        } else {
          error('Beim Hochladen ist ein Fehler aufgetreten.');
        }
      }
    },
    [user, person],
  );

  const onChangeImage = useCallback(
    async (selected: { id: string; name: string; url: string } | null) => {
      if (user?.id) {
        await updateTeacherFiles({
          variables: {
            id: user.id,
            input: { imageId: selected?.id || null },
          },
        });
        if (!selected) {
          success('Das Dozenten-Bild wurde entfernt.');
        } else {
          success('Der Dozent wurde mit einem neuen Bild verknüpft.');
        }
      }
    },
    [user],
  );

  /* 
  const [confirmModal, confirmDelete] = useConfirm(async (id: string) => {
    try {
      await remove({ variables: { id } });
      navigate('../list');
      success('Der/die Dozent/in wurde gelöscht.');
    } catch (e) {
      error('Fehler');
    }
  }); */

  /* const onDelete = useCallback(async () => {
    if (props.id) {
      confirmDelete(
        'Soll diese/r Dozent/in wirklich gelöscht werden?',
        props.id,
      );
    }
  }, [props.id]); */

  return (
    <Grid.Row>
      <Grid.Column>
        <Section title="User">
          <Form.Input name="user.username" label="Benutzername" />
        </Section>
        <Section title="Rechte">
          <Form.Select
            name="user.status"
            label="Status"
            choices={useUserStatusChoices()}
          />
        </Section>
        <Section title="Inhaltliches">
          <Form.Input name="user.jobTitle" label="Berufsbezeichnung" />
          <Form.Input name="user.subjectArea" label="Fachbereich(e)" />
          <Form.Html name="user.description" label="Beschreibung / Vita" />
        </Section>
      </Grid.Column>
      <Grid.Column>
        <Section title="Website">
          <Form.Input name="user.slug" label="Slug" />
        </Section>
        <Section title="Bilder">
          {user && (
            <ImageChooser
              label="Dozenten-Bild"
              explorerFilter={{ group: FileGroup.Teacher }}
              disabled={false}
              loading={false}
              currentImageUrl={user.image?.url}
              onChange={onUploadImage}
              onSelect={onChangeImage}
            />
          )}
          {!person && (
            <InfoBox header="Der Datensatz muss zuerst gespeichert werden." />
          )}
        </Section>
      </Grid.Column>
    </Grid.Row>
  );
};
