import { Person } from '@/graphql/generated/types';
import { LabeledItem } from '@/ui/labeled-item/labeled-item.component';
import { Section } from '@/ui/section/section.component';
import React, { ComponentType } from 'react';
import { useCountryNames } from 'shared/countries/iso-country-codes';
import { Translator } from 'shared/translator/translator.component';
import { AddressView } from './address-view.component';

interface PersonViewProps {
  person?: Pick<
    Person,
    | 'gender'
    | 'title'
    | 'firstName'
    | 'lastName'
    | 'contactProperties'
    | 'address'
    | 'invoiceAddress'
    | 'internalNotes'
  > | null;
  hideAddress?: boolean;
}

export const PersonView: ComponentType<PersonViewProps> = (props) => {
  const { person, hideAddress } = props;
  const _ = Translator.useTranslator();
  const getName = useCountryNames();

  if (!person) {
    return null;
  }
  return (
    <>
      <Section title="Hauptdaten">
        <LabeledItem value={person.gender} label="Geschlecht" />
        <LabeledItem value={person.title} label="Titel" />
        <LabeledItem value={person.firstName} label="Vorname" />
        <LabeledItem value={person.lastName} label="Nachname" />
        <LabeledItem value={person.internalNotes} label="Interne Notizen" />
      </Section>
      {person.contactProperties && (
        <Section title="Kontakt">
          {person.contactProperties.map((property) => (
            <LabeledItem
              key={property.id}
              label={_(`contact-property.${property.key}`) as string}
              value={property.value}
            />
          ))}
        </Section>
      )}
      {!hideAddress && (
        <>
          {person.address && (
            <>
              <Section title="Anschrift">
                <AddressView address={person.address} />
              </Section>
            </>
          )}
          {person.invoiceAddress && (
            <>
              <Section title="Rechnungs-Anschrift">
                <AddressView address={person.invoiceAddress} />
              </Section>
            </>
          )}
        </>
      )}
    </>
  );
};
