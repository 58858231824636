import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { Editor } from './detail/editor/editor.component';
import { DiscountRuleEditor } from './discount/detail/editor/editor.component';
import { DiscountRuleList } from './discount/list/list.component';
import { List } from './list/list.component';

export const Product: ComponentType<RouteComponentProps> = (props) => {
  return (
    <>
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            {
              label: 'Produkte',
              path: 'list',
            },

            {
              label: 'Rabatte',
              path: 'discounts/list',
            },
          ]}
        />
      </Frame.SubMenu>
      <Router className="router">
        <List path="list" />
        <Editor path=":id/edit" />
        <Editor path="create" />
        <DiscountRuleList path="discounts/list" />
        <DiscountRuleEditor path="discounts/create" />
        <DiscountRuleEditor path="discounts/:id/edit" />
        <Redirect noThrow from="/" to="list" />
        <Redirect noThrow from="/discounts" to="list" />
      </Router>
    </>
  );
};
