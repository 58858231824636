import classNames from 'classnames';
import propertyPath from 'property-path';
import React, { forwardRef, ReactNode, ReactText, Ref, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import Select from 'react-select';
import globalStyles from 'styles/global.scss';
import styles from './select-field-2.module.scss';

type ValueType = string | string[] | null;

export interface SelectField2Props {
  nullLabel?: string;
  small?: boolean;
  value?: ValueType;
  name?: string;
  onChange?: (value: ValueType) => void;
  choices: { value: string | null; label?: ReactText }[];
  error?: string;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  nullable?: boolean;
  readOnly?: boolean;
  multiple?: boolean;
  formState?: FormState<any>;

  placeholder?: string;
  isClearable?: boolean;
}

export const SelectField2 = forwardRef<HTMLSelectElement, SelectField2Props>(
  (props, ref: Ref<any>) => {
    const {
      nullLabel = 'Nichts zuweisen',
      small,
      value,
      name,
      nullable,
      onChange,
      choices,
      error,
      label,
      multiple,
      children,
      formState,
      isClearable,
      placeholder,
      disabled,
      ...rest
    } = props;

    const errorMessage = useMemo(() => {
      if (props.name && formState) {
        return propertyPath.get(formState.errors, props.name)?.message;
      }
      return error;
    }, [error, formState, props.name]);

    const currentValue = useMemo(() => {
      if (multiple) {
        if (Array.isArray(value) && (value as any[]).length === 0) {
          return [];
        }
        return choices.filter((c) =>
          (value as string[])?.includes(c.value as string),
        );
      }
      return choices.find((c) => c.value === value);
    }, [value, multiple, choices]);

    return (
      <div className={classNames(styles.host, small && styles.small)}>
        {label && (
          <label>
            {label}
            {props.required && ' *'}
          </label>
        )}
        <Select
          menuPosition="fixed"
          defaultValue={currentValue}
          isClearable={isClearable}
          name={name}
          ref={ref}
          value={currentValue}
          onChange={(value) => {
            if (onChange) {
              onChange(
                Array.isArray(value)
                  ? value.map((v) => v.value)
                  : (value as any).value,
              );
            }
          }}
          isMulti={multiple}
          placeholder={placeholder}
          /*
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#d09b3e',
              primary: '#1084b0',
            },
          })}
          style={{
            input: (provided) => ({
              ...provided,
              background: 'red',
              height: '10px',
            }),
          }} */
          isDisabled={disabled}
          classNamePrefix="react-select"
          className={styles.host}
          // components={{ DropdownIndicator, IndicatorSeparator: null }}
          options={choices}
        />
        {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
      </div>
    );
  },
);

/*
export const Dropdown: ComponentType<DropdownProps> = (props) => {
  const { choices, isClearable, placeholder, onChange, value, multiple } =
    props;
  return (
    <Select
      isClearable={isClearable}
      value={choices.find((c) => c.value === value)}
      onChange={(value) => {
        if (onChange) {
          onChange(
            Array.isArray(value)
              ? value.map((v) => v.value)
              : (value as any).value,
          );
        }
      }}
      isMulti={multiple}
      placeholder={placeholder}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#d09b3e',
          primary: '#1084b0',
        },
      })}
      style={{
        input: (provided) => ({
          ...provided,
          background: 'red',
          height: '10px',
        }),
      }}
      classNamePrefix="react-select"
      className={styles.host}
      components={{ DropdownIndicator, IndicatorSeparator: null }}
      options={choices}
    />
  );
};
*/
