import classNames from 'classnames';
import propertyPath from 'property-path';
import React, {
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { FormState } from 'react-hook-form';
import globalStyles from 'styles/global.scss';
import { Copyable } from '../copyable/copyable.component';
import styles from './input-field.module.scss';

export interface InputFieldProps {
  white?: boolean;
  small?: boolean;
  compact?: boolean;
  value?: string;
  name?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  error?: string;
  label?: ReactNode | null;
  placeholder?: string;
  type?: 'text' | 'password' | 'number' | 'hidden';
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
  onKeyPress?: KeyboardEventHandler;
  horizontal?: boolean;
  noMargin?: boolean;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      noMargin,
      error,
      label,
      value,
      formState,
      small,
      horizontal,
      compact,
      white,
      onChange,
      ...rest
    } = props;

    const errorMessage = useMemo(() => {
      if (props.name && formState?.errors) {
        return propertyPath.get(formState.errors, props.name)?.message;
      }
      return error;
    }, [error, formState, props.name]);

    if (props.type === 'hidden') {
      return <input type="hidden" {...props} ref={ref} />;
    }

    const handleChange = useCallback(
      (e) => {
        if (onChange) {
          if (props.type === 'number') {
            onChange(parseInt(e.currentTarget.value));
          } else {
            onChange(e);
          }
        }
      },
      [props.type, onChange],
    );

    const currentValue = useMemo(() => {
      if (props.type === 'number') {
        return value !== null && value !== undefined ? parseInt(value) : 0;
      }
      return value || '';
    }, [value, props.type]);

    return (
      <div
        className={classNames(
          styles.host,
          small && styles.small,
          compact && styles.compact,
          horizontal && styles.horizontal,
          noMargin && styles.noMargin,
          white && styles.white,
          'input-field',
        )}
      >
        {label && (
          <Copyable copyOnClick valueToCopy={currentValue?.toString() || ''}>
            <label>
              {label}
              {props.required && ' *'}
            </label>
          </Copyable>
        )}
        <input
          ref={ref}
          {...rest}
          onChange={handleChange}
          value={currentValue}
        />
        {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
      </div>
    );
  },
);
