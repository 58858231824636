import {
  StatisticsBoard,
  StatisticsBoxContext,
  StatisticsBoxType,
} from '@/graphql/generated/types';
import { DropdownMenu } from '@/ui/dropdown-menu/dropdown-menu.component';
import React, { ComponentType } from 'react';
import { useStatisticsBoards } from '../board/board.provider';
import { useChartOptions } from '../chart/options/options.provider';
import { useBoardModals } from './modals.hook';

interface DropdownActionsProps {
  id: string;
  name: string;
  type: StatisticsBoxType;
  context: StatisticsBoxContext;
  board: StatisticsBoard;

  onSaved: () => void;
}

export const DropdownActions: ComponentType<DropdownActionsProps> = (props) => {
  const { board, id, name, onSaved, type, context } = props;

  const { options } = useChartOptions();

  const { updateBox } = useStatisticsBoards();
  const { askDeleteBox, askDeleteBoxModal } = useBoardModals(board);
  return (
    <>
      {askDeleteBoxModal}

      <DropdownMenu
        actions={[
          {
            label: 'Box speichern',
            meaning: 'success',
            onClick: () => {
              if (board) {
                updateBox(
                  {
                    id,
                    name,
                    options,
                    type,
                    context,
                  },
                  board.id,
                );
                onSaved();
              }
            },
          },
          {
            label: 'Box löschen',
            meaning: 'error',
            onClick: () => {
              askDeleteBox(
                <>
                  Möchtest du die Box <em>{name}</em> löschen?
                </>,
                id,
              );
            },
          },
        ]}
      />
    </>
  );
};
