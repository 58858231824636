import React, { ComponentType } from 'react';
import styles from './horizontal-table.module.scss';
interface HorizontalTableProps {
  data: Record<string, string | number>;
}

export const HorizontalTable: ComponentType<HorizontalTableProps> = (props) => {
  const { data } = props;
  return (
    <table className={styles.host}>
      <tbody>
        {Object.keys(data).map((key) => (
          <tr key={key}>
            <th>{key}</th>
            <td>
              {typeof data[key] === 'string'
                ? data[key]
                : JSON.stringify(data[key])}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
