import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PropertySchemaFragment = {
  __typename?: 'PropertySchema';
  id: any;
  name: string;
  contexts: Array<string>;
  value: string;
};

export type PropertySchemasQueryVariables = Types.Exact<{
  enterpriseId: Types.Scalars['UUID'];
  pagination: Types.PaginationInput;
}>;

export type PropertySchemasQuery = {
  __typename?: 'Query';
  adminPropertySchemas: {
    __typename?: 'PaginatedPropertySchemaResult';
    totalCount: number;
    data: Array<{
      __typename?: 'PropertySchema';
      id: any;
      name: string;
      contexts: Array<string>;
      value: string;
    }>;
  };
};

export type PropertySchemaQueryVariables = Types.Exact<{
  enterpriseId: Types.Scalars['UUID'];
  propertySchemaId: Types.Scalars['UUID'];
}>;

export type PropertySchemaQuery = {
  __typename?: 'Query';
  adminPropertySchema: {
    __typename?: 'PropertySchema';
    id: any;
    name: string;
    contexts: Array<string>;
    value: string;
  };
};

export const PropertySchemaFragmentDoc = gql`
  fragment PropertySchema on PropertySchema {
    id
    name
    contexts
    value
  }
`;
export const PropertySchemasDocument = gql`
  query propertySchemas($enterpriseId: UUID!, $pagination: PaginationInput!) {
    adminPropertySchemas(enterpriseId: $enterpriseId, pagination: $pagination) {
      data {
        ...PropertySchema
      }
      totalCount
    }
  }
  ${PropertySchemaFragmentDoc}
`;
export type PropertySchemasComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PropertySchemasQuery,
    PropertySchemasQueryVariables
  >,
  'query'
> &
  (
    | { variables: PropertySchemasQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PropertySchemasComponent = (
  props: PropertySchemasComponentProps,
) => (
  <ApolloReactComponents.Query<
    PropertySchemasQuery,
    PropertySchemasQueryVariables
  >
    query={PropertySchemasDocument}
    {...props}
  />
);

/**
 * __usePropertySchemasQuery__
 *
 * To run a query within a React component, call `usePropertySchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertySchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertySchemasQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePropertySchemasQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertySchemasQuery,
    PropertySchemasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertySchemasQuery, PropertySchemasQueryVariables>(
    PropertySchemasDocument,
    options,
  );
}
export function usePropertySchemasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertySchemasQuery,
    PropertySchemasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PropertySchemasQuery,
    PropertySchemasQueryVariables
  >(PropertySchemasDocument, options);
}
export type PropertySchemasQueryHookResult = ReturnType<
  typeof usePropertySchemasQuery
>;
export type PropertySchemasLazyQueryHookResult = ReturnType<
  typeof usePropertySchemasLazyQuery
>;
export type PropertySchemasQueryResult = Apollo.QueryResult<
  PropertySchemasQuery,
  PropertySchemasQueryVariables
>;
export const PropertySchemaDocument = gql`
  query propertySchema($enterpriseId: UUID!, $propertySchemaId: UUID!) {
    adminPropertySchema(
      enterpriseId: $enterpriseId
      propertySchemaId: $propertySchemaId
    ) {
      ...PropertySchema
    }
  }
  ${PropertySchemaFragmentDoc}
`;
export type PropertySchemaComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PropertySchemaQuery,
    PropertySchemaQueryVariables
  >,
  'query'
> &
  (
    | { variables: PropertySchemaQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PropertySchemaComponent = (
  props: PropertySchemaComponentProps,
) => (
  <ApolloReactComponents.Query<
    PropertySchemaQuery,
    PropertySchemaQueryVariables
  >
    query={PropertySchemaDocument}
    {...props}
  />
);

/**
 * __usePropertySchemaQuery__
 *
 * To run a query within a React component, call `usePropertySchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertySchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertySchemaQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *      propertySchemaId: // value for 'propertySchemaId'
 *   },
 * });
 */
export function usePropertySchemaQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertySchemaQuery,
    PropertySchemaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertySchemaQuery, PropertySchemaQueryVariables>(
    PropertySchemaDocument,
    options,
  );
}
export function usePropertySchemaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertySchemaQuery,
    PropertySchemaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertySchemaQuery, PropertySchemaQueryVariables>(
    PropertySchemaDocument,
    options,
  );
}
export type PropertySchemaQueryHookResult = ReturnType<
  typeof usePropertySchemaQuery
>;
export type PropertySchemaLazyQueryHookResult = ReturnType<
  typeof usePropertySchemaLazyQuery
>;
export type PropertySchemaQueryResult = Apollo.QueryResult<
  PropertySchemaQuery,
  PropertySchemaQueryVariables
>;
