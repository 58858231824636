import { IfSuper } from '@/auth/role/if-role.component';
import { TagInput } from '@/graphql/generated/types';
import { Profile, isSuperAdmin } from '@/profile/profile';
import { Box } from '@/ui/box/box.component';
import { Form, FormError } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { object, string } from 'yup';
import { Frame } from '../../../frame';
import { Button } from '../../../ui/button/button.component';
import {
  useAdminCreateTagMutation,
  useAdminEditTagMutation,
  useAdminTagQuery,
} from './editor.generated';

const schema = object({
  name: string().required(FormError.required).min(3, FormError.minLength),
  slug: string().required(FormError.required).min(3, FormError.minLength),
});

type TagEditorParams = {
  id?: string;
};

export const Editor: ComponentType<RouteComponentProps<TagEditorParams>> = (
  props,
) => {
  const { id } = props;
  const tagQuery = useAdminTagQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });

  const persisted = id !== undefined;

  const { reload, availableEnterprises } = useResources();

  const [save] = useAdminEditTagMutation();
  const [create] = useAdminCreateTagMutation();
  const profile = Profile.use();

  const form = useForm<TagInput>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
    defaultValues: {
      enterpriseId: isSuperAdmin(profile)
        ? undefined
        : profile.selectedEnterprise?.id,
    },
  });

  const onSubmit = useMemo(() => {
    return form.handleSubmit(
      async (input) => {
        try {
          if (props.id) {
            await save({
              variables: {
                id: props.id,
                input,
              },
            });
            reload();
            success('Der Tag wurde gespeichert.');
          } else {
            const tag = await create({
              variables: {
                input,
              },
            });
            reload();
            navigate(`./${tag.data?.createTag.id}/edit`);
            success('Der Tag wurde erstellt.');
          }
        } catch (e) {
          error('Beim Speichern des Tags ist ein Fehler aufgetreten.');
        }
      },
      (e) => {
        error('Ein Fehler ist aufgetreten.');
        console.error(e);
      },
    );
  }, [props.id]);

  return (
    <>
      <Frame.SubTitle>
        {persisted ? tagQuery.data?.adminTag.name : 'Neuer Tag'}
        {}
      </Frame.SubTitle>
      <Frame.ActionBar>
        <Button label="Zurück" linkTo="/tags" />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={tagQuery.data?.adminTag as any}>
          <Grid.Row>
            <Grid.Column>
              <Box>
                <Form.Input name="name" label="Name" />
                <Form.Input name="slug" label="Slug" />
                <Form.Input name="context" label="Kontext" />
                <IfSuper
                  otherwise={<Form.Input type="hidden" name="enterpriseId" />}
                >
                  <Form.Select
                    name="enterpriseId"
                    label="Unternehmen"
                    choices={availableEnterprises.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                  />
                </IfSuper>
              </Box>
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
