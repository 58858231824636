import { gql } from '@apollo/client';

export function generateCourseQueryFragment(sets: string[]) {
  return gql`
    fragment EditCourseData on Course {
      id
      status
      purchasable
      eligible
      taxRate
      title
      subTitle
      slug
      abbreviation
      position
      tags {
        id
        name
        slug
      }
      tagIds
      teacherIds
      approximateDuration {
        value
        factor
      }
      approximateClassHours
      categoryId
      description
      earlyBirdDate
      earlyBirdNotice
      infoCourse
      modulePriceMatrix
      properties
      image {
        id
        url
      }

      ${sets
        .map(
          (set) => `
      ${set}: attributes(set: "${set}") {
        ... on Attribute {
          kind
          name
          view
          valueProperty
          values
        }
        ... on TextAttribute {
          textValue
        }
        ... on StringAttribute {
          stringValue
        }
        ... on IntAttribute {
          intValue
        }
        ... on DecimalAttribute {
          decimalValue
        }
        ... on JsonAttribute {
          jsonValue
        }
    }
        `,
        )
        .join('\n')}
      
    }
  `;
}

export function generateCourseQuery(sets: string[]) {
  const fragment = generateCourseQueryFragment(sets);

  return gql`${fragment}
  query course($id: UUID!) {
        
        adminCourse(id: $id) {
          ${fragment.definitions
            .map(
              (definition) =>
                definition.kind === 'FragmentDefinition' &&
                `...${definition.name.value}`,
            )
            .join('\n')}
        }
      }`;
}

export function generateCreateCourseMutation(sets: string[]) {
  const fragment = generateCourseQueryFragment(sets);
  return gql`${fragment}
  mutation createCourse($input: CreateCourseInput!) {
        adminCreateCourse(input: $input) {
            ${fragment.definitions
              .map(
                (definition) =>
                  definition.kind === 'FragmentDefinition' &&
                  `...${definition.name.value}`,
              )
              .join('\n')}
        }
      }`;
}

export function generateUpdateCourseMutation(sets: string[]) {
  const fragment = generateCourseQueryFragment(sets);
  return gql`${fragment}
  mutation updateCourse(
        $input: UpdateCourseInput!
        $id: UUID!
      ) {
        adminUpdateCourse(input: $input, id: $id) {
            ${fragment.definitions
              .map(
                (definition) =>
                  definition.kind === 'FragmentDefinition' &&
                  `...${definition.name.value}`,
              )
              .join('\n')}
        }
      }`;
}
