import React from 'react';
import { Message } from 'react-hook-form';
import toast from 'react-hot-toast';

export function success(message: Message, longMessage?: string) {
  return toast.success(
    <div>
      <h3>{message}</h3>
      {longMessage && <p>{longMessage}</p>}
    </div>,
    { duration: longMessage ? 10000 : undefined },
  );
}

export function error(message: Message, longMessage?: string) {
  return toast.error(
    <div>
      <h3>{message}</h3>
      {longMessage && <p>{longMessage}</p>}
    </div>,
  );
}

export function warning(message: Message, longMessage?: string) {
  return toast(
    <>
      <h3>{message}</h3>
      {longMessage && <p>{longMessage}</p>}
    </>,
    {
      style: {
        background: '#d87920',
        color: '#ffffff',
      },
    },
  );
}

export function notice(message: Message, longMessage?: string) {
  return toast(
    <>
      <h3>{message}</h3>
      {longMessage && <p>{longMessage}</p>}
    </>,
    {
      style: {
        background: '#909090',
        color: '#ffffff',
      },
    },
  );
}
