import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
export type ItemDetailData_BookingOrderItem_Fragment = {
  __typename?: 'BookingOrderItem';
  id: any;
  quantity: number;
  amount: number;
  booking:
    | {
        __typename?: 'CourseModuleBooking';
        id: any;
        courseModule: {
          __typename?: 'CourseModule';
          sku: string;
          startDate: any;
          endDate: any;
          dateUncertain: boolean;
          withTime: boolean;
          location?: {
            __typename?: 'Location';
            id: any;
            commonName: string;
          } | null;
          courseUnit: {
            __typename?: 'CourseUnit';
            id: any;
            course: {
              __typename?: 'Course';
              id: any;
              abbreviation: string;
              verboseTitle?: string | null;
              categoryId: any;
              category: {
                __typename?: 'CourseCategory';
                id: any;
                enterprise: { __typename?: 'Enterprise'; name: string };
              };
            };
          };
        };
      }
    | {
        __typename?: 'CourseUnitBooking';
        id: any;
        courseUnit: {
          __typename?: 'CourseUnit';
          sku: string;
          startDate: any;
          endDate: any;
          dateUncertain: boolean;
          withTime: boolean;
          location?: {
            __typename?: 'Location';
            id: any;
            commonName: string;
          } | null;
          course: {
            __typename?: 'Course';
            id: any;
            abbreviation: string;
            verboseTitle?: string | null;
            categoryId: any;
            category: {
              __typename?: 'CourseCategory';
              id: any;
              enterprise: { __typename?: 'Enterprise'; name: string };
            };
          };
        };
      };
  discount: {
    __typename?: 'DiscountEmbedded';
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  };
  discounts: Array<{
    __typename?: 'Discount';
    id: any;
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  }>;
};

export type ItemDetailData_DiscountOrderItem_Fragment = {
  __typename?: 'DiscountOrderItem';
  id: any;
  quantity: number;
  amount: number;
  discountRule?: {
    __typename?: 'DiscountRule';
    type: Types.DiscountType;
    amount: number;
  } | null;
  discount: {
    __typename?: 'DiscountEmbedded';
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  };
  discounts: Array<{
    __typename?: 'Discount';
    id: any;
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  }>;
};

export type ItemDetailData_PackageBookingOrderItem_Fragment = {
  __typename?: 'PackageBookingOrderItem';
  id: any;
  quantity: number;
  amount: number;
  package: {
    __typename?: 'Package';
    title: string;
    subTitle?: string | null;
    enterprise: { __typename?: 'Enterprise'; id: any; name: string };
  };
  discount: {
    __typename?: 'DiscountEmbedded';
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  };
  discounts: Array<{
    __typename?: 'Discount';
    id: any;
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  }>;
};

export type ItemDetailData_ProductOrderItem_Fragment = {
  __typename?: 'ProductOrderItem';
  id: any;
  quantity: number;
  amount: number;
  product:
    | {
        __typename?: 'LocationProduct';
        id: any;
        sku: string;
        title: string;
        enterprise: { __typename?: 'Enterprise'; name: string };
      }
    | {
        __typename?: 'SimpleProduct';
        id: any;
        sku: string;
        title: string;
        enterprise: { __typename?: 'Enterprise'; name: string };
      };
  discount: {
    __typename?: 'DiscountEmbedded';
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  };
  discounts: Array<{
    __typename?: 'Discount';
    id: any;
    type: Types.DiscountType;
    amount?: number | null;
    reason?: string | null;
  }>;
};

export type ItemDetailDataFragment =
  | ItemDetailData_BookingOrderItem_Fragment
  | ItemDetailData_DiscountOrderItem_Fragment
  | ItemDetailData_PackageBookingOrderItem_Fragment
  | ItemDetailData_ProductOrderItem_Fragment;

export const ItemDetailDataFragmentDoc = gql`
  fragment ItemDetailData on OrderItem {
    id
    quantity
    amount
    discount {
      type
      amount
      reason
    }
    discounts {
      id
      type
      amount
      reason
    }
    ... on PackageBookingOrderItem {
      id
      package {
        title
        subTitle
        enterprise {
          id
          name
        }
      }
    }
    ... on BookingOrderItem {
      booking {
        id
        ... on CourseUnitBooking {
          courseUnit {
            sku
            startDate
            endDate
            dateUncertain
            withTime
            location {
              id
              commonName
            }
            course {
              id
              abbreviation
              verboseTitle
              categoryId
              category {
                id
                enterprise {
                  name
                }
              }
            }
          }
        }
        ... on CourseModuleBooking {
          courseModule {
            sku
            startDate
            endDate
            dateUncertain
            withTime
            location {
              id
              commonName
            }
            courseUnit {
              id
              course {
                id
                abbreviation
                verboseTitle
                categoryId
                category {
                  id
                  enterprise {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on ProductOrderItem {
      product {
        ... on Product {
          id
          sku
          title
          enterprise {
            name
          }
        }
      }
    }
    ... on DiscountOrderItem {
      discountRule {
        type
        amount
      }
    }
  }
`;
