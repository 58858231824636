import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateBookingMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  customerId: Types.Scalars['UUID'];
  bookingType: Types.Scalars['String'];
}>;


export type CreateBookingMutation = { __typename?: 'Mutation', createBooking: boolean };


export const CreateBookingDocument = gql`
    mutation createBooking($id: UUID!, $customerId: UUID!, $bookingType: String!) {
  createBooking(id: $id, customerId: $customerId, bookingType: $bookingType)
}
    `;
export type CreateBookingMutationFn = Apollo.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;
export type CreateBookingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBookingMutation, CreateBookingMutationVariables>, 'mutation'>;

    export const CreateBookingComponent = (props: CreateBookingComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBookingMutation, CreateBookingMutationVariables> mutation={CreateBookingDocument} {...props} />
    );
    

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *      bookingType: // value for 'bookingType'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, options);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<CreateBookingMutation, CreateBookingMutationVariables>;