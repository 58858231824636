import { FormError } from '@/ui/form/form.component';
import { useApolloClient } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@reach/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translator } from 'shared/translator/translator.component';
import { object, string } from 'yup';
import {
  useLoginStep1LazyQuery,
  useLoginStep2Mutation,
} from './login.generated';

type Step1Values = {
  username: string;
  password: string;
};

export const validationSchema = object({
  username: string().required(FormError.required),
  password: string().required(FormError.required),
});

export function useLoginForm(
  onStep1Success: (enterprises: { id: string; name: string }[]) => void,
) {
  const _ = Translator.useTranslator();
  const [serverError, setServerError] = useState<string | false>(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const navigate = useNavigate();
  const client = useApolloClient();

  const { handleSubmit: handleSubmitStep1, ...form } = useForm<Step1Values>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      username: process.env.DEFAULT_LOGIN_USER || '',
      password: process.env.DEFAULT_LOGIN_PASS || '',
    },
  });

  const [getMyEnterprisesQuery, { data, error: errorStep1 }] =
    useLoginStep1LazyQuery({ fetchPolicy: 'network-only' });

  const [login, { loading }] = useLoginStep2Mutation();

  const submitStep1 = useMemo(
    () =>
      handleSubmitStep1(async (variables) => {
        try {
          await getMyEnterprisesQuery({
            variables: {
              username: variables.username,
              password: variables.password,
            },
          });
        } catch (e: any) {
          setServerError(`error.${e.message}`);
        }
      }),
    [handleSubmitStep1],
  );

  const submitStep2 = useCallback(
    async (enterpriseId: string | null) => {
      try {
        const { data } = await login({
          variables: { ...credentials, enterpriseId },
        });

        if (data) {
          if (data.authenticate) {
            await client.resetStore();
            navigate('/', { replace: true });
          } else {
            setServerError(FormError.wrongUserOrPassword);
          }
        }
      } catch (e: any) {}
    },
    [credentials],
  );

  useEffect(() => {
    if (data?.myEnterprises.enterprises) {
      setCredentials(form.getValues());

      if (data.myEnterprises.isSuperAdmin === false) {
        onStep1Success(data.myEnterprises.enterprises);
      } else {
        login({
          variables: { ...form.getValues() },
        })
          .then((result) => {
            if (result.data?.authenticate) {
              client.resetStore().then(() => navigate('/', { replace: true }));
            } else {
              setServerError('Benutzername oder Passwort falsch');
            }
          })
          .catch((e) => {
            setServerError(`error.${e.message}`);
          });
      }

      setServerError(false);
    }
  }, [data?.myEnterprises, onStep1Success]);

  useEffect(() => {
    if (errorStep1) {
      setServerError(_(`error.${errorStep1.message}`) as string);
    }
  }, [errorStep1]);

  return {
    ...form,
    loading,
    submitStep1,
    submitStep2,
    serverError,
    setServerError,
  };
}
