import { RoleType } from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { useCallback, useMemo } from 'react';

export function useRoleGuard() {
  const profile = Profile.use();

  const isSuper = useMemo(() => {
    return (
      profile &&
      profile.roles.find((role) => role.name === RoleType.Super) !== undefined
    );
  }, [profile]);

  const hasRole = useCallback(
    (role: RoleType) => {
      return profile?.roles.find((r) => r.name === role);
    },
    [profile],
  );

  return {
    isSuper,
    hasRole,
  };
}
