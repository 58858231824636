import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.FilterLeadsInput>;
}>;


export type LeadsQuery = { __typename?: 'Query', leads: Array<{ __typename?: 'Lead', createdAt: any, actionId: any, action: Types.LeadAction, person: { __typename?: 'Person', id: any, title?: string | null, firstName?: string | null, lastName?: string | null } }> };


export const LeadsDocument = gql`
    query leads($filter: FilterLeadsInput) {
  leads(filter: $filter) {
    createdAt
    person {
      id
      title
      firstName
      lastName
    }
    actionId
    action
  }
}
    `;
export type LeadsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LeadsQuery, LeadsQueryVariables>, 'query'>;

    export const LeadsComponent = (props: LeadsComponentProps) => (
      <ApolloReactComponents.Query<LeadsQuery, LeadsQueryVariables> query={LeadsDocument} {...props} />
    );
    

/**
 * __useLeadsQuery__
 *
 * To run a query within a React component, call `useLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLeadsQuery(baseOptions?: Apollo.QueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
      }
export function useLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
        }
export type LeadsQueryHookResult = ReturnType<typeof useLeadsQuery>;
export type LeadsLazyQueryHookResult = ReturnType<typeof useLeadsLazyQuery>;
export type LeadsQueryResult = Apollo.QueryResult<LeadsQuery, LeadsQueryVariables>;