import * as Types from '../graphql/generated/types';

import { gql } from '@apollo/client';
export type EventData_ConsultingEvent_Fragment = { __typename?: 'ConsultingEvent', id: any, description?: string | null, kind: string, startDate: any, endDate: any, enterprise: { __typename?: 'Enterprise', id: any, name: string }, location?: { __typename?: 'Location', id: any, commonName: string } | null };

export type EventData_CourseEvent_Fragment = { __typename?: 'CourseEvent', id: any, description?: string | null, kind: string, startDate: any, endDate: any, enterprise: { __typename?: 'Enterprise', id: any, name: string }, location?: { __typename?: 'Location', id: any, commonName: string } | null };

export type EventData_Event_Fragment = { __typename?: 'Event', id: any, description?: string | null, kind: string, startDate: any, endDate: any, enterprise: { __typename?: 'Enterprise', id: any, name: string }, location?: { __typename?: 'Location', id: any, commonName: string } | null };

export type EventDataFragment = EventData_ConsultingEvent_Fragment | EventData_CourseEvent_Fragment | EventData_Event_Fragment;

export const EventDataFragmentDoc = gql`
    fragment EventData on EventInterface {
  id
  description
  kind
  startDate
  endDate
  enterprise {
    id
    name
  }
  location {
    id
    commonName
  }
}
    `;