import classNames from 'classnames';
import propertyPath from 'property-path';
import React, { forwardRef, ReactNode, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import globalStyles from 'styles/global.scss';
import styles from './checkbox.module.scss';

export interface CheckboxProps {
  small?: boolean;
  checked?: boolean;
  name?: string;
  onChange?: (e: any) => void;
  error?: string;
  label?: ReactNode | null;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  formState?: FormState<any>;
  noSpacing?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const { error, label, checked, formState, small, noSpacing, ...rest } =
      props;

    const errorMessage = useMemo(() => {
      if (props.name && formState?.errors) {
        return propertyPath.get(formState.errors, props.name)?.message;
      }
      return error;
    }, [error, formState, props.name]);

    return (
      <div
        className={classNames(
          styles.host,
          small && styles.small,
          noSpacing && styles.noSpacing,
          'checkbox',
        )}
      >
        <label>
          <input
            ref={ref}
            type="checkbox"
            {...rest}
            checked={checked}
            onChange={
              props.onChange
                ? (e) => props.onChange!(e.currentTarget.checked)
                : undefined
            }
          />
          {label}
          {props.required && ' *'}
        </label>
        {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
      </div>
    );
  },
);
