export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Attribute: [
      'DecimalAttribute',
      'IntAttribute',
      'JsonAttribute',
      'StringAttribute',
      'TextAttribute',
    ],
    AuditLog: ['AdminActionAuditLog', 'ConsentAuditLog'],
    AuditLogUnion: ['AdminActionAuditLog', 'ConsentAuditLog'],
    Booking: ['CourseModuleBooking', 'CourseUnitBooking'],
    BookingUnion: ['CourseModuleBooking', 'CourseUnitBooking'],
    CalculatedOrderItem: [
      'CalculatedBookingOrderItem',
      'CalculatedCustomOrderItem',
      'CalculatedDiscountOrderItem',
      'CalculatedPackageBookingOrderItem',
      'CalculatedProductOrderItem',
    ],
    CalculatedOrderItemUnion: [
      'CalculatedBookingOrderItem',
      'CalculatedCustomOrderItem',
      'CalculatedDiscountOrderItem',
      'CalculatedPackageBookingOrderItem',
      'CalculatedProductOrderItem',
    ],
    EventInterface: ['ConsultingEvent', 'CourseEvent', 'Event'],
    EventUnion: ['ConsultingEvent', 'CourseEvent', 'Event'],
    InquiryInterface: [
      'CourseInquiry',
      'CourseModuleInquiry',
      'CourseUnitInquiry',
      'DownloadInquiry',
      'Inquiry',
      'LocationInquiry',
    ],
    InquiryUnion: [
      'CourseInquiry',
      'CourseModuleInquiry',
      'CourseUnitInquiry',
      'DownloadInquiry',
      'Inquiry',
      'LocationInquiry',
    ],
    OrderItem: [
      'BookingOrderItem',
      'DiscountOrderItem',
      'PackageBookingOrderItem',
      'ProductOrderItem',
    ],
    PersonInterface: ['Person', 'PersonWithDuplicates'],
    PersonUnion: ['Person', 'PersonWithDuplicates'],
    Product: ['LocationProduct', 'SimpleProduct'],
    ProductUnion: ['LocationProduct', 'SimpleProduct'],
    Settings: ['CommunicationSettings', 'WebsiteSettings'],
    StatRecord: ['InquiryStatRecord', 'OrderStatRecord'],
    StatRecordUnion: ['InquiryStatRecord', 'OrderStatRecord'],
    TransactionTemplate: ['TransactionMailTemplate'],
    User: ['Administrator', 'Customer', 'Teacher'],
    UserUnion: ['Administrator', 'Customer', 'Teacher'],
  },
};
export default result;
