import infoIcon from '@/assets/image/icons/info.svg';
import styles from '@/shared/tree-edit/tree-edit.module.scss';
import { useTreeEdit } from '@/shared/tree-edit/tree-edit.provider';
import { Icon } from '@/ui/icon/icon.component';
import classNames from 'classnames';
import React, { ComponentType, useCallback } from 'react';

export interface CourseNodeProps {
  id: string;
  title: string;
  infoCourse: boolean;
  abbreviation: string;
  enterpriseId: string;
}

export const CourseNode: ComponentType<CourseNodeProps> = (props) => {
  const { id, title, infoCourse, abbreviation, enterpriseId } = props;
  const { setCurrentCourseAndEnterprise, currentCourseAndEnterprise } =
    useTreeEdit();

  const onOpen = useCallback(
    (open) => {
      setCurrentCourseAndEnterprise([id, enterpriseId]);
    },
    [enterpriseId],
  );

  return (
    <div
      className={classNames(
        styles.course,
        currentCourseAndEnterprise &&
          currentCourseAndEnterprise[0] === id &&
          styles.active,
      )}
      onClick={onOpen}
    >
      {`${abbreviation} – ${title}`}
      {infoCourse && <Icon inline icon={infoIcon} />}
    </div>
  );
};
