import {
  StatisticsBoxContext,
  StatisticsBoxType,
  StatRecord,
} from '@/graphql/generated/types';
import { ComponentType } from 'react';
import { GraphOptions, GraphType } from '../chart/graph/graph';
import { GraphItemOptionsProps } from '../chart/graph/graph-item/options/graph-options.hooks';
import { CommonChartOptionsProps } from '../chart/options/common-chart-options.component';
import { ChartOptions } from '../chart/options/options.provider';
import {
  DummyDisplay,
  DummyFilter,
  DummyOptions,
} from './statistics-box.component';

type GraphTypeDefinition<StatRecordType extends StatRecord> = {
  optionsComponent: ComponentType<GraphItemOptionsProps>;
  optionsLabel?: string;
  value: GraphType;
  label: string;
  filterCallback: (
    record: StatRecordType,
    options: GraphOptions<GraphType>,
  ) => boolean;
  incrementCallback?: (
    oldValue: number,
    record: StatRecordType,
    options: ChartOptions,
  ) => number;
};

let StatisticsBoxRegistry: {
  displayComponent: ComponentType;
  filterComponent: ComponentType;
  optionsComponent: ComponentType<CommonChartOptionsProps>;
  context: StatisticsBoxContext;
  type: StatisticsBoxType;
  graphTypes?: GraphTypeDefinition<any>[];
}[] = [];

export function registerStatisticsBox<SRT extends StatRecord>(
  type: StatisticsBoxType,
  context: StatisticsBoxContext,
  components: {
    displayComponent: ComponentType;
    filterComponent: ComponentType;
    optionsComponent: ComponentType<CommonChartOptionsProps>;
  },
  graphTypes?: GraphTypeDefinition<SRT>[],
) {
  StatisticsBoxRegistry = [
    ...StatisticsBoxRegistry.filter(
      (item) => item.context === context && item.type === type,
    ),
    {
      type,
      context,
      ...components,
      graphTypes,
    },
  ];
}

export function getDisplayComponent(
  type: StatisticsBoxType,
  context: StatisticsBoxContext,
) {
  return (
    StatisticsBoxRegistry.find(
      (item) => item.context === context && item.type === type,
    )?.displayComponent || DummyDisplay
  );
}

export function getFilterComponent(
  type: StatisticsBoxType,
  context: StatisticsBoxContext,
) {
  return (
    StatisticsBoxRegistry.find(
      (item) => item.context === context && item.type === type,
    )?.filterComponent || DummyFilter
  );
}

export function getBoxOptionsComponent(
  type: StatisticsBoxType,
  context: StatisticsBoxContext,
) {
  return (
    StatisticsBoxRegistry.find(
      (item) => item.context === context && item.type === type,
    )?.optionsComponent || DummyOptions
  );
}

export function getGraphTypes(
  type: StatisticsBoxType,
  context: StatisticsBoxContext,
) {
  return (
    StatisticsBoxRegistry.find(
      (item) => item.context === context && item.type === type,
    )?.graphTypes ||
    [] ||
    []
  );
}
