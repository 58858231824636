import { AdministrationRoutes } from '@/administration/administration-routes.component';
import { RequestPasswordComponent } from '@/auth/request-password/request-password.component';
import { ResetPasswordComponent } from '@/auth/reset-password/reset-password.component';
import { Contact } from '@/contact/contact.component';
import { CourseHandlingRoutes } from '@/course-handling/course-handling-routes.component';
import { CourseManagementRoutes } from '@/course-management/course-management-routes.component';
import { Enterprise } from '@/enterprise/enterprise.component';
import { ErrorList } from '@/error/error-list.component';
import { ErrorProvider } from '@/error/error.provider';
import { EventRoutes } from '@/event/event-routes.component';
import { Explorer } from '@/file/explorer/explorer.component';
import { ExplorerProvider } from '@/file/explorer/explorer.context';
import { FileRoutes } from '@/file/file-routes.component';
import { Inquiry } from '@/inquiry/inquiry.component';
import { Lead } from '@/lead/lead.component';
import { LocationRoutes } from '@/location/location-routes.component';
import { Order } from '@/order/order.component';
import { PrivacyRoutes } from '@/privacy/privacy-routes.component';
import { Product } from '@/product/product.component';
import { StatisticsRoutes } from '@/statistics-v3/statistics-routes.component';
import { Tag } from '@/tag/tag.component';
import { TabsProvider } from '@/ui/tabs/tabs.provider';
import { toastOptions } from '@/ui/toaster/toast-options';
import { ApolloProvider } from '@apollo/client';
import { LocationProvider, Redirect, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { CookiesProvider } from 'react-cookie';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Toaster } from 'react-hot-toast';
import { ResourcesProvider } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { AuthGuard } from '../auth/auth-guard';
import { Login } from '../auth/login/login.component';
import { Dashboard } from '../dashboard/dashboard.component';
import { Frame } from '../frame';
import { Footer } from '../frame/footer/footer.component';
import { client } from '../graphql/client';
import { AwaitProfile, Profile } from '../profile/profile';
import { NotFound } from './not-found';

export const App: ComponentType = () => {
  return (
    <ErrorProvider>
      <ApolloProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <CookiesProvider>
            <Profile>
              <AwaitProfile>
                <ResourcesProvider>
                  <Translator>
                    <LocationProvider>
                      <AuthGuard>
                        <TabsProvider>
                          <ExplorerProvider>
                            <Frame.Menu />
                            <Router className="router">
                              <Login path="login" />
                              <RequestPasswordComponent path="request-password" />
                              <ResetPasswordComponent path="reset-password/:code" />
                              <Dashboard path="dashboard" />
                              <Lead path="leads/*" />
                              <StatisticsRoutes path="statistics/*" />
                              <Order path="orders/*" />
                              <CourseHandlingRoutes path="course-handling/*" />
                              <Contact path="contacts/*" />
                              <CourseManagementRoutes path="course-management/*" />
                              <EventRoutes path="event-management/*" />
                              <LocationRoutes path="locations/*" />
                              <FileRoutes path="files/*" />
                              <Enterprise path="enterprises/*" />
                              <Inquiry path="inquiries/*" />
                              <Product path="products/*" />
                              <PrivacyRoutes path="privacy/*" />
                              <AdministrationRoutes path="administration/*" />
                              <Tag path="tags/*" />
                              <ErrorList path="error-log" />
                              <Redirect to="dashboard" from="*" />
                              <NotFound default />
                            </Router>
                            <Explorer />
                            <Toaster toastOptions={toastOptions} />
                            <Footer />
                          </ExplorerProvider>
                        </TabsProvider>
                      </AuthGuard>
                    </LocationProvider>
                  </Translator>
                </ResourcesProvider>
              </AwaitProfile>
            </Profile>
          </CookiesProvider>
        </DndProvider>
      </ApolloProvider>
    </ErrorProvider>
  );
};
