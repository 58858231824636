import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { Logo } from '../logo/logo.component';
import styles from './loader.module.scss';

interface Props {
  fullscreen?: boolean;
  withLogo?: boolean;
  small?: boolean;
  text?: string;
  progress?: number;
}
export const Loader: ComponentType<Props> = (props) => {
  const {
    fullscreen,
    small,
    withLogo = true,
    text = 'Bitte warten',

    children,
    progress,
  } = props;
  return (
    <div className={classNames(styles.host, { fullscreen, small })}>
      {progress !== undefined && (
        <div className="progress">{Math.round(progress * 100)} %</div>
      )}
      {text && <p>{text}</p>}
      {withLogo && <Logo size={40} />}
      {children}
    </div>
  );
};
