import { FormError } from '@/ui/form/form.component';
import { array, object, string } from 'yup';

export const administratorSchema = object({
  username: string().typeError(FormError.required).required(FormError.required),
  status: string().typeError(FormError.required).required(FormError.required),
  roleIds: array()
    .typeError(FormError.required)
    .of(string().typeError(FormError.required))
    .min(1, FormError.minItems({ min: 1 })),
});
