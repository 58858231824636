import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import globalStyles from 'styles/global.scss';
import {
  ListRoomDataFragment,
  RoomsQueryVariables,
  useRoomsQuery,
} from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery();
  const { data, loading } = useRoomsQuery({
    variables: { pagination: getPaginationFromQuery(query) },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="/locations/rooms/create" label="Neuer Raum" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<ListRoomDataFragment, RoomsQueryVariables['query']>
          loading={loading}
          data={data?.adminRooms?.data || []}
          totalCount={data?.adminRooms?.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'Standort',
              sortable: true,
            },
            {
              name: 'Kapazität (Personen)',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              {/* <DebouncedInput
            debounceRate={500}
              value={value || ''}
              onChange={(value) => onChange('')}
              placeholder={'Suche…'}
              loading={loading}
            /> */}
            </>
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/edit`}>{item.name}</Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>
                  {item.location?.address?.city}{' '}
                  <span className={globalStyles.meta}>
                    {item.location?.name} (
                    {item.location?.enterprises
                      .map((enterprise) => enterprise.name)
                      .join(', ')}
                    )
                  </span>
                </Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>{item.maxPeople}</Link>
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
