import React, {
  cloneElement,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { Box } from '../box/box.component';
import { Button } from '../button/button.component';
import styles from './modal.module.scss';

interface ModalOptions {
  closeOnDimmerClick?: boolean;
  width?: string;
}
export function useModal<Props>(
  node: ReactElement,
  options: ModalOptions = {
    width: '40vw',
    closeOnDimmerClick: true,
  },
): [ReactElement<Props>, { open: () => void; close: () => void }] {
  const [open, updateOpen] = useState(false);
  const onClose = useCallback(() => updateOpen(false), []);
  const onOpen = useCallback(() => updateOpen(true), []);

  const element = document.getElementById('modals');

  const modal =
    open && element ? (
      ReactDOM.createPortal(
        <div
          className={styles.dimmer}
          onClick={options.closeOnDimmerClick ? onClose : undefined}
        >
          <div className={styles.host} style={{ width: options.width }}>
            {cloneElement(node, {
              open: true,
              onClose,
              centered: false,
            })}
          </div>
        </div>,
        element,
      )
    ) : (
      <></>
    );
  return [
    modal,
    {
      open: onOpen,
      close: onClose,
    },
  ];
}

type AlertHook = [ReactElement, (message: string, title?: string) => void];

interface AlertState {
  message: string;
  title: string;
}

export function useAlert(onOkCallback?: () => any): AlertHook {
  const [state, setState] = useState<AlertState>({
    title: 'Hinweis',
    message: '',
  });

  const show = useCallback((message: string, title?: string) => {
    setState((s) => ({
      message,
      title: title ? title : s.title,
    }));

    open();
  }, []);

  const onOkClick = useCallback(() => {
    close();
    if (onOkCallback) {
      onOkCallback();
    }
  }, []);

  const [modal, { open, close }] = useModal(
    <Box title={state.title}>
      {state.message}
      <div className={styles.actions}>
        <Button onClick={onOkClick} label="OK" />
      </div>
    </Box>,
  );

  return [modal, show];
}

type ConfirmHook<Handler extends (...args: any[]) => void> = [
  ReactElement,
  (question: ReactNode, ...args: Parameters<Handler>) => void,
];

interface ConfirmState {
  question: ReactNode;
  args: any[];
}

interface ConfirmOptions {
  accept?: string;
  cancel?: string;
  title?: ReactNode;
  meaning?: 'success' | 'warning' | 'error';
}

export function useConfirm<Handler extends (...args: any[]) => void>(
  handler: Handler,
  options: ConfirmOptions = {
    accept: 'Ja',
    cancel: 'Nein',
    title: 'Bestätigen',
    meaning: 'success',
  },
): ConfirmHook<Handler> {
  const [state, setState] = useState<ConfirmState>({
    question: '',
    args: [],
  });

  const ask = useCallback((question: ReactNode, ...args: any[]) => {
    setState({
      question,
      args,
    });

    open();
  }, []);

  const onConfirm = useCallback(() => {
    close();
    handler(...state.args);
  }, [state.args]);

  const onCancel = useCallback(() => {
    close();
  }, []);

  const [modal, { open, close }] = useModal(
    <Box title={options.title}>
      {state.question}
      <div className={styles.actions}>
        <Button onClick={onCancel} label={options.cancel || 'Nein'} />
        <Button
          warning={options.meaning === 'warning'}
          success={options.meaning === 'success'}
          error={options.meaning === 'error'}
          primary
          onClick={onConfirm}
          label={options.accept || 'Ja'}
        />
      </div>
    </Box>,
  );

  return [modal, ask];
}

interface DialogueOptions {
  actions: { handler: (...args: any[]) => void; title: string }[];
  title?: string;
}

export function useDialogue<Handler extends (...args: any[]) => void>(
  options: DialogueOptions,
): ConfirmHook<Handler> {
  const [state, setState] = useState<ConfirmState>({
    question: '',
    args: [],
  });

  const ask = useCallback((question: ReactNode, ...args: any[]) => {
    setState({
      question,
      args,
    });

    open();
  }, []);

  const [modal, { open, close }] = useModal(
    <Box title={options.title}>
      <p>{state.question}</p>
      <div className={styles.actions}>
        {options.actions.map((action, index) => (
          <Button
            key={`action-${index}`}
            onClick={() => {
              action.handler(...state.args);
              close();
            }}
            label={action.title}
          />
        ))}
      </div>
    </Box>,
  );

  return [modal, ask];
}

interface CustomDialogueOptions<Handler> {
  actions: {
    handler: Handler;
    title: string;
    primary?: boolean;
  }[];
  closeOnAction?: boolean;
  closeOnDimmerClick?: boolean;
  title?: string;
  width?: string;
}

export function useCustomDialogue<Handler extends (...args: any[]) => void>(
  node: ReactElement,
  options: CustomDialogueOptions<Handler>,
  ...args: any[]
): [ReactElement, { open: () => void; close: () => void }] {
  const [modal, { open, close }] = useModal(
    <Box title={options.title}>
      {node}
      <div className={styles.actions}>
        {options.actions.map((action, index) => (
          <Button
            primary={action.primary}
            key={`action-${index}`}
            onClick={() => {
              action.handler(args);
              if (options.closeOnAction !== false) {
                close();
              }
            }}
            label={action.title}
          />
        ))}
      </div>
    </Box>,
    { closeOnDimmerClick: options.closeOnDimmerClick, width: options.width },
  );

  return [modal, { open, close }];
}
