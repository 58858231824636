import closeIcon from '@/assets/image/icons/close.svg';
import { ProductFinder } from '@/product/product-finder/product-finder.component';
import { Button } from '@/ui/button/button.component';
import { CurrencyInputField } from '@/ui/currency-input-field/currency-input-field.component';
import { InputField } from '@/ui/input-field/input-field.component';
import classNames from 'classnames';
import React, { ComponentType, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CourseUnitFinder } from 'shared/course/course-unit-finder/course-unit-finder.component';
import styles from './order-item.module.scss';
import { OrderItemType } from './order-items.hooks';
interface OrderItemProps {
  index: number;
  item: OrderItemType;
  form: UseFormReturn<any>;
  onRemove: () => void;
}

export const OrderItem: ComponentType<OrderItemProps> = (props) => {
  const { onRemove, form, item, index } = props;

  const chosenItem = form.watch(`items.${index}.item`);

  useEffect(() => {
    if (chosenItem) {
      form.setValue(`items.${index}.amount`, chosenItem.amount);
    }
  }, [index, chosenItem]);

  return (
    <div className={styles.host}>
      <div className={classNames(styles.segment, styles.big)}>
        <Controller
          name={`items.${index}.item`}
          control={form.control}
          render={({ field }) => (
            <>
              {item.type === 'Course' && (
                <CourseUnitFinder
                  small
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
              {item.type === 'Product' && (
                <ProductFinder onChange={field.onChange} value={field.value} />
              )}
            </>
          )}
        />
      </div>
      <div className={styles.segment}>
        <Controller
          name={`items.${index}.quantity`}
          control={form.control}
          render={({ field }) => (
            <InputField {...field} noMargin label="Anzahl" type="number" />
          )}
        />
        <Controller
          name={`items.${index}.amount`}
          control={form.control}
          render={({ field }) => (
            <CurrencyInputField
              {...field}
              noMargin
              label="Preis"
              type="number"
            />
          )}
        />
      </div>
      <div className={classNames(styles.segment, styles.actions)}>
        <Button icon={closeIcon} small onClick={onRemove} />
      </div>
    </div>
  );
};
