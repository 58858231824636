import { useLocation, useNavigate } from '@reach/router';
import { parse, stringify } from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';

export function usePaginatedUrlQuery<T extends object>() {
  return useUrlQuery<{ skip: string; take: string } & T>();
}

export function useUrlQuery<T extends object>(
  initialQuery?: Partial<T>,
): [T, (value: T) => void] {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => parse(location.search), [location.search]) as T;

  const setQuery = useCallback(
    (value: T) => {
      const qs = stringify(value, { encode: true });
      navigate(`${location.pathname}?${qs}${location.hash}`, {
        state: { qs },
        replace: true,
      });
    },
    [navigate],
  );

  useEffect(() => {
    if (initialQuery) {
      setQuery({ ...query, ...initialQuery });
    }
  }, []);

  return [query, setQuery];
}

export const useUrlQueryKey = (
  key: string,
  defaultValue: string | null = null,
): [string | null, (value: any) => void] => {
  const [query, setQuery] = useUrlQuery();

  let value = defaultValue;
  if (query && query[key]) {
    value = query[key];
  }

  const setValue = useCallback(
    (value) => {
      const q = {
        ...(query || {}),
        [key]: value,
      };
      setQuery(q);
    },
    [key, setQuery],
  );

  return [value, setValue];
};
