import { CourseUnit } from '@/graphql/generated/types';
import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { InputField } from '@/ui/input-field/input-field.component';
import { useModal } from '@/ui/modal/modal.hooks';
import { SelectField } from '@/ui/select-field/select-field.component';
import { error, success } from '@/ui/toaster/toast';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useResources } from 'shared/resources/resources.provider';
import globalStyles from 'styles/global.scss';
import { useDuplicateCourseUnitMutation } from './duplicator.generated';

interface DuplicateCourseUnitOptions {
  onSubmit(
    sourceUnitId: string,
    title?: string,
    seasonId?: string | null,
  ): void;
}

export function useDuplicateCourseUnitModal(
  options: DuplicateCourseUnitOptions,
): [
  ReactNode,
  {
    open: (unit: CourseUnit | undefined) => void;
    close: () => void;
  },
] {
  const [unit, setUnit] = useState<CourseUnit | undefined>();
  const [title, setTitle] = useState<string>('');
  const [seasonId, setSeasonId] = useState<string | undefined | null>();

  const { availableSeasons } = useResources();

  const close = useCallback(() => {
    modal[1].close();
  }, []);

  useEffect(() => {
    if (unit?.title) {
      setTitle(unit.title);
    }
  }, [unit]);

  const modal = useModal(
    <Box title="Kurs-Einheit duplizieren">
      <p>Diese Kurs-Einheit wird dupliziert.</p>
      <InputField
        onChange={(e) => setTitle(e.target.value)}
        value={title}
        label="Titel der neuen Kurs-Einheit"
      />
      <SelectField
        choices={availableSeasons?.map((season) => ({
          label: season.title,
          value: season.id,
        }))}
        nullable
        label="Gehört zur Saison"
        value={seasonId}
        onChange={(value) => setSeasonId(value as any)}
      />
      <div className={globalStyles.flexAlignRight}>
        <Button label="Abbrechen" onClick={close} />
        <Button
          primary
          label="Duplizieren"
          onClick={() => {
            options.onSubmit((unit as CourseUnit).id, title, seasonId);
          }}
        />
      </div>
    </Box>,
    { closeOnDimmerClick: false },
  );

  const open = useCallback(
    (unit: CourseUnit | undefined) => {
      setUnit(unit);
      modal[1].open();
    },
    [modal[1].open],
  );

  return [modal[0], { ...modal[1], open }];
}

export const useDuplicateCourseUnit = (
  callback?: () => void,
): {
  open: (unit: CourseUnit | undefined) => void;
  modal: ReactNode;
} => {
  const [duplicateCourseUnit] = useDuplicateCourseUnitMutation();

  const onDuplicate = async (
    sourceUnitId: string,
    title: string,
    seasonId: string | undefined | null,
  ) => {
    try {
      await duplicateCourseUnit({
        variables: { id: sourceUnitId, input: { title, seasonId } },
      });
      success('Die Kurseinheit wurde dupliziert.');
      close();
      if (callback) {
        callback();
      }
    } catch (e) {
      error('Beim Kopieren der Kurseinheit ist ein Fehler aufgetreten.');
    }
  };

  const [duplicationConfirmModal, { open, close }] =
    useDuplicateCourseUnitModal({ onSubmit: onDuplicate });

  return {
    open,
    modal: duplicationConfirmModal,
  };
};
