// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bBri1NT7zULFgjt2RMQu{background-color:rgba(0,0,0,.2)}", "",{"version":3,"sources":["webpack://./src/ui/resizable/resizable.module.scss"],"names":[],"mappings":"AAAA,sBACE,+BAAA","sourcesContent":[".host {\n  background-color: rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": "bBri1NT7zULFgjt2RMQu"
};
export default ___CSS_LOADER_EXPORT___;
