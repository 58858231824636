import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { PersonDetailDataFragmentDoc } from '../../../../contact/person/editor/editor.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CourseUnitBookingDetailDataFragment = {
  __typename?: 'CourseUnitBooking';
  options?: any | null;
  comment?: string | null;
  courseUnit: {
    __typename?: 'CourseUnit';
    id: any;
    title?: string | null;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    online: boolean;
    correspondenceCourse: boolean;
    location?: { __typename?: 'Location'; id: any; commonName: string } | null;
    course: { __typename?: 'Course'; id: any; title: string };
  };
};

export type CourseModuleBookingDetailDataFragment = {
  __typename?: 'CourseModuleBooking';
  options?: any | null;
  comment?: string | null;
  courseModule: {
    __typename?: 'CourseModule';
    id: any;
    title?: string | null;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    online: boolean;
    correspondenceCourse: boolean;
    location?: { __typename?: 'Location'; id: any; commonName: string } | null;
    courseUnit: {
      __typename?: 'CourseUnit';
      id: any;
      title?: string | null;
      course: { __typename?: 'Course'; id: any; title: string };
    };
  };
};

export type BookingQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type BookingQuery = {
  __typename?: 'Query';
  adminBooking:
    | {
        __typename?: 'CourseModuleBooking';
        id: any;
        createdAt: any;
        confirmedAt?: any | null;
        cancelledAt?: any | null;
        options?: any | null;
        comment?: string | null;
        customer: {
          __typename?: 'Customer';
          id: any;
          username?: string | null;
          person: {
            __typename?: 'Person';
            internalNotes?: string | null;
            enterpriseIds?: Array<any> | null;
            id: any;
            createdAt: any;
            title?: string | null;
            gender?: Types.Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            anonymizedAt?: any | null;
            employments: Array<{
              __typename?: 'Employment';
              id: any;
              description?: string | null;
              companyId: any;
              company: { __typename?: 'Company'; id: any; name: string };
            }>;
            enterprises: Array<{
              __typename?: 'Enterprise';
              id: any;
              name: string;
            }>;
            user?:
              | {
                  __typename?: 'Administrator';
                  roleIds?: Array<any> | null;
                  isBot: boolean;
                  id: any;
                  username?: string | null;
                  fullName: string;
                  primaryEmail?: string | null;
                  status: Types.UserStatus;
                  description?: string | null;
                  roles: Array<{
                    __typename?: 'Role';
                    id: any;
                    name: Types.RoleType;
                  }>;
                  image?: {
                    __typename?: 'FilesystemFile';
                    id: any;
                    url: string;
                  } | null;
                }
              | {
                  __typename?: 'Customer';
                  id: any;
                  username?: string | null;
                  fullName: string;
                  primaryEmail?: string | null;
                  status: Types.UserStatus;
                  description?: string | null;
                  orders: Array<{
                    __typename?: 'Order';
                    id: any;
                    createdAt: any;
                    total: number;
                  }>;
                  bookings: Array<
                    | {
                        __typename?: 'CourseModuleBooking';
                        kind: Types.BookingKind;
                        id: any;
                        createdAt: any;
                        courseModule: {
                          __typename?: 'CourseModule';
                          id: any;
                          title?: string | null;
                          sku: string;
                          startDate: any;
                          endDate: any;
                          dateUncertain: boolean;
                          withTime: boolean;
                          online: boolean;
                          correspondenceCourse: boolean;
                          location?: {
                            __typename?: 'Location';
                            id: any;
                            commonName: string;
                          } | null;
                          searchableLocation?: {
                            __typename?: 'Location';
                            id: any;
                            commonName: string;
                          } | null;
                          courseUnit: {
                            __typename?: 'CourseUnit';
                            id: any;
                            course: {
                              __typename?: 'Course';
                              id: any;
                              title: string;
                              verboseTitle?: string | null;
                              abbreviation: string;
                            };
                          };
                        };
                      }
                    | {
                        __typename?: 'CourseUnitBooking';
                        kind: Types.BookingKind;
                        id: any;
                        createdAt: any;
                        courseUnit: {
                          __typename?: 'CourseUnit';
                          id: any;
                          title?: string | null;
                          sku: string;
                          startDate: any;
                          endDate: any;
                          dateUncertain: boolean;
                          withTime: boolean;
                          online: boolean;
                          correspondenceCourse: boolean;
                          location?: {
                            __typename?: 'Location';
                            id: any;
                            commonName: string;
                          } | null;
                          searchableLocation?: {
                            __typename?: 'Location';
                            id: any;
                            commonName: string;
                          } | null;
                          course: {
                            __typename?: 'Course';
                            id: any;
                            title: string;
                            verboseTitle?: string | null;
                            abbreviation: string;
                          };
                        };
                      }
                  >;
                  image?: {
                    __typename?: 'FilesystemFile';
                    id: any;
                    url: string;
                  } | null;
                }
              | {
                  __typename?: 'Teacher';
                  jobTitle?: string | null;
                  subjectArea?: string | null;
                  slug?: string | null;
                  id: any;
                  username?: string | null;
                  fullName: string;
                  primaryEmail?: string | null;
                  status: Types.UserStatus;
                  description?: string | null;
                  image?: {
                    __typename?: 'FilesystemFile';
                    id: any;
                    url: string;
                  } | null;
                }
              | null;
            contactProperties: Array<{
              __typename?: 'ContactProperty';
              id: any;
              key: Types.ContactPropertyKey;
              value: string;
            }>;
            inquiries: Array<{
              __typename?: 'Inquiry';
              id: any;
              createdAt: any;
              status: Types.InquiryStatus;
              assignee?: {
                __typename?: 'Administrator';
                id: any;
                fullName: string;
              } | null;
            }>;
            address: {
              __typename?: 'Address';
              company?: string | null;
              contactPerson?: string | null;
              streetAndNumber?: string | null;
              postalCode?: string | null;
              city?: string | null;
              country?: string | null;
              other?: string | null;
            };
            invoiceAddress: {
              __typename?: 'Address';
              company?: string | null;
              contactPerson?: string | null;
              streetAndNumber?: string | null;
              postalCode?: string | null;
              city?: string | null;
              country?: string | null;
              other?: string | null;
            };
          };
        };
        orderItem?:
          | {
              __typename?: 'BookingOrderItem';
              id: any;
              order: { __typename?: 'Order'; id: any };
            }
          | {
              __typename?: 'DiscountOrderItem';
              id: any;
              order: { __typename?: 'Order'; id: any };
            }
          | {
              __typename?: 'PackageBookingOrderItem';
              id: any;
              order: { __typename?: 'Order'; id: any };
            }
          | {
              __typename?: 'ProductOrderItem';
              id: any;
              order: { __typename?: 'Order'; id: any };
            }
          | null;
        courseModule: {
          __typename?: 'CourseModule';
          id: any;
          title?: string | null;
          startDate: any;
          endDate: any;
          withTime: boolean;
          dateUncertain: boolean;
          online: boolean;
          correspondenceCourse: boolean;
          location?: {
            __typename?: 'Location';
            id: any;
            commonName: string;
          } | null;
          courseUnit: {
            __typename?: 'CourseUnit';
            id: any;
            title?: string | null;
            course: { __typename?: 'Course'; id: any; title: string };
          };
        };
      }
    | {
        __typename?: 'CourseUnitBooking';
        id: any;
        createdAt: any;
        confirmedAt?: any | null;
        cancelledAt?: any | null;
        options?: any | null;
        comment?: string | null;
        customer: {
          __typename?: 'Customer';
          id: any;
          username?: string | null;
          person: {
            __typename?: 'Person';
            internalNotes?: string | null;
            enterpriseIds?: Array<any> | null;
            id: any;
            createdAt: any;
            title?: string | null;
            gender?: Types.Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            anonymizedAt?: any | null;
            employments: Array<{
              __typename?: 'Employment';
              id: any;
              description?: string | null;
              companyId: any;
              company: { __typename?: 'Company'; id: any; name: string };
            }>;
            enterprises: Array<{
              __typename?: 'Enterprise';
              id: any;
              name: string;
            }>;
            user?:
              | {
                  __typename?: 'Administrator';
                  roleIds?: Array<any> | null;
                  isBot: boolean;
                  id: any;
                  username?: string | null;
                  fullName: string;
                  primaryEmail?: string | null;
                  status: Types.UserStatus;
                  description?: string | null;
                  roles: Array<{
                    __typename?: 'Role';
                    id: any;
                    name: Types.RoleType;
                  }>;
                  image?: {
                    __typename?: 'FilesystemFile';
                    id: any;
                    url: string;
                  } | null;
                }
              | {
                  __typename?: 'Customer';
                  id: any;
                  username?: string | null;
                  fullName: string;
                  primaryEmail?: string | null;
                  status: Types.UserStatus;
                  description?: string | null;
                  orders: Array<{
                    __typename?: 'Order';
                    id: any;
                    createdAt: any;
                    total: number;
                  }>;
                  bookings: Array<
                    | {
                        __typename?: 'CourseModuleBooking';
                        kind: Types.BookingKind;
                        id: any;
                        createdAt: any;
                        courseModule: {
                          __typename?: 'CourseModule';
                          id: any;
                          title?: string | null;
                          sku: string;
                          startDate: any;
                          endDate: any;
                          dateUncertain: boolean;
                          withTime: boolean;
                          online: boolean;
                          correspondenceCourse: boolean;
                          location?: {
                            __typename?: 'Location';
                            id: any;
                            commonName: string;
                          } | null;
                          searchableLocation?: {
                            __typename?: 'Location';
                            id: any;
                            commonName: string;
                          } | null;
                          courseUnit: {
                            __typename?: 'CourseUnit';
                            id: any;
                            course: {
                              __typename?: 'Course';
                              id: any;
                              title: string;
                              verboseTitle?: string | null;
                              abbreviation: string;
                            };
                          };
                        };
                      }
                    | {
                        __typename?: 'CourseUnitBooking';
                        kind: Types.BookingKind;
                        id: any;
                        createdAt: any;
                        courseUnit: {
                          __typename?: 'CourseUnit';
                          id: any;
                          title?: string | null;
                          sku: string;
                          startDate: any;
                          endDate: any;
                          dateUncertain: boolean;
                          withTime: boolean;
                          online: boolean;
                          correspondenceCourse: boolean;
                          location?: {
                            __typename?: 'Location';
                            id: any;
                            commonName: string;
                          } | null;
                          searchableLocation?: {
                            __typename?: 'Location';
                            id: any;
                            commonName: string;
                          } | null;
                          course: {
                            __typename?: 'Course';
                            id: any;
                            title: string;
                            verboseTitle?: string | null;
                            abbreviation: string;
                          };
                        };
                      }
                  >;
                  image?: {
                    __typename?: 'FilesystemFile';
                    id: any;
                    url: string;
                  } | null;
                }
              | {
                  __typename?: 'Teacher';
                  jobTitle?: string | null;
                  subjectArea?: string | null;
                  slug?: string | null;
                  id: any;
                  username?: string | null;
                  fullName: string;
                  primaryEmail?: string | null;
                  status: Types.UserStatus;
                  description?: string | null;
                  image?: {
                    __typename?: 'FilesystemFile';
                    id: any;
                    url: string;
                  } | null;
                }
              | null;
            contactProperties: Array<{
              __typename?: 'ContactProperty';
              id: any;
              key: Types.ContactPropertyKey;
              value: string;
            }>;
            inquiries: Array<{
              __typename?: 'Inquiry';
              id: any;
              createdAt: any;
              status: Types.InquiryStatus;
              assignee?: {
                __typename?: 'Administrator';
                id: any;
                fullName: string;
              } | null;
            }>;
            address: {
              __typename?: 'Address';
              company?: string | null;
              contactPerson?: string | null;
              streetAndNumber?: string | null;
              postalCode?: string | null;
              city?: string | null;
              country?: string | null;
              other?: string | null;
            };
            invoiceAddress: {
              __typename?: 'Address';
              company?: string | null;
              contactPerson?: string | null;
              streetAndNumber?: string | null;
              postalCode?: string | null;
              city?: string | null;
              country?: string | null;
              other?: string | null;
            };
          };
        };
        orderItem?:
          | {
              __typename?: 'BookingOrderItem';
              id: any;
              order: { __typename?: 'Order'; id: any };
            }
          | {
              __typename?: 'DiscountOrderItem';
              id: any;
              order: { __typename?: 'Order'; id: any };
            }
          | {
              __typename?: 'PackageBookingOrderItem';
              id: any;
              order: { __typename?: 'Order'; id: any };
            }
          | {
              __typename?: 'ProductOrderItem';
              id: any;
              order: { __typename?: 'Order'; id: any };
            }
          | null;
        courseUnit: {
          __typename?: 'CourseUnit';
          id: any;
          title?: string | null;
          startDate: any;
          endDate: any;
          withTime: boolean;
          dateUncertain: boolean;
          online: boolean;
          correspondenceCourse: boolean;
          location?: {
            __typename?: 'Location';
            id: any;
            commonName: string;
          } | null;
          course: { __typename?: 'Course'; id: any; title: string };
        };
      };
};

export const CourseUnitBookingDetailDataFragmentDoc = gql`
  fragment CourseUnitBookingDetailData on CourseUnitBooking {
    options
    comment
    courseUnit {
      id
      title
      startDate
      endDate
      withTime
      dateUncertain
      online
      correspondenceCourse
      location {
        id
        commonName
      }
      course {
        id
        title
      }
    }
  }
`;
export const CourseModuleBookingDetailDataFragmentDoc = gql`
  fragment CourseModuleBookingDetailData on CourseModuleBooking {
    options
    comment
    courseModule {
      id
      title
      startDate
      endDate
      withTime
      dateUncertain
      online
      correspondenceCourse
      location {
        id
        commonName
      }
      courseUnit {
        id
        title
        course {
          id
          title
        }
      }
    }
  }
`;
export const BookingDocument = gql`
  query booking($id: UUID!) {
    adminBooking(id: $id) {
      ... on Booking {
        id
        createdAt
        confirmedAt
        cancelledAt
        customer {
          id
          username
          person {
            ...PersonDetailData
          }
        }
        orderItem {
          id
          order {
            id
          }
        }
      }
      ... on CourseModuleBooking {
        ...CourseModuleBookingDetailData
      }
      ... on CourseUnitBooking {
        ...CourseUnitBookingDetailData
      }
    }
  }
  ${PersonDetailDataFragmentDoc}
  ${CourseModuleBookingDetailDataFragmentDoc}
  ${CourseUnitBookingDetailDataFragmentDoc}
`;
export type BookingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BookingQuery,
    BookingQueryVariables
  >,
  'query'
> &
  ({ variables: BookingQueryVariables; skip?: boolean } | { skip: boolean });

export const BookingComponent = (props: BookingComponentProps) => (
  <ApolloReactComponents.Query<BookingQuery, BookingQueryVariables>
    query={BookingDocument}
    {...props}
  />
);

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(
  baseOptions: Apollo.QueryHookOptions<BookingQuery, BookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingQuery, BookingQueryVariables>(
    BookingDocument,
    options,
  );
}
export function useBookingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingQuery,
    BookingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingQuery, BookingQueryVariables>(
    BookingDocument,
    options,
  );
}
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingQueryResult = Apollo.QueryResult<
  BookingQuery,
  BookingQueryVariables
>;
