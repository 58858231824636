import addIcon from '@/assets/image/icons/plus-white.svg';
import {
  CustomerFinder,
  CustomerItemType,
} from '@/contact/person/customer-finder/customer-finder.component';
import { useErrorLog } from '@/error/error.provider';
import { Profile } from '@/profile/profile';
import { Button } from '@/ui/button/button.component';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import { useCustomDialogue } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { Textarea } from '@/ui/textarea/textarea.component';
import { error, success } from '@/ui/toaster/toast';
import { uniqueId } from 'lodash-es';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { CourseUnitOrModuleItemType } from 'shared/course/course-unit-finder/course-unit-finder.component';
import { useCreateOrderMutation } from './create-order.generated';
import styles from './create-order.module.scss';
import { OrderItem } from './order-item/order-item.component';
import { OrderItemType } from './order-item/order-items.hooks';
type Values = {
  customer: CustomerItemType;
  items: OrderItemType[];
  sendNotificationMails: boolean;
  consentAds: boolean;
  comment?: string;
};
export function useCreateOrderModal(onCreate: () => void) {
  const profile = Profile.use();
  const { log } = useErrorLog();
  const form = useForm<Values>({
    defaultValues: {
      items: [],
      sendNotificationMails: false,
      consentAds: false,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'items',
  });

  const [createOrder] = useCreateOrderMutation();
  const [modal, { open, close }] = useCustomDialogue(
    <>
      <Section title="1. Kurs-Einheiten, -Module und Produkte wählen">
        {fields.map((item, index) => (
          <OrderItem
            index={index}
            form={form}
            key={item.id}
            item={item}
            onRemove={() => remove(index)}
          />
        ))}
        <div className={styles.addButton}>
          <Button
            icon={addIcon}
            small
            primary
            label="Kurs"
            onClick={() => {
              append({
                id: uniqueId(),
                quantity: 1,
                item: null,
                amount: 0,
                type: 'Course',
              });
            }}
          />
          <Button
            icon={addIcon}
            small
            primary
            label="Produkt"
            onClick={() => {
              append({
                id: uniqueId(),
                quantity: 1,
                item: null,
                amount: 0,
                type: 'Product',
              });
            }}
          />
        </div>
      </Section>
      <Section title="2. Kunde:in wählen">
        <Controller
          name="customer"
          control={form.control}
          render={({ field }) => <CustomerFinder {...field} />}
        />
      </Section>
      <Section title="3. Kommentar, Datenschutz, Benachrichtigungen">
        <Controller
          name="comment"
          control={form.control}
          render={({ field }) => (
            <Textarea
              slim
              label="Kommentar zur Bestellung (einsehbar durch Kund:in)"
              {...field}
            />
          )}
        />
        <Controller
          name="consentAds"
          control={form.control}
          render={({ field }) => (
            <Checkbox
              label="Einwilligung für Werbung (Achtung! Kund:in wird im Anschluss eine Double-Opt-In Mail erhalten.)"
              {...field}
            />
          )}
        />
        <Controller
          name="sendNotificationMails"
          control={form.control}
          render={({ field }) => (
            <Checkbox
              label="Benachrichtigungsmails an Kund:in und Kursverwaltung senden"
              {...field}
            />
          )}
        />
      </Section>
    </>,
    {
      width: '70vw',
      closeOnAction: false,
      title: 'Neue Bestellung',
      actions: [
        {
          title: 'Abbrechen',
          handler: () => {
            close();
          },
        },
        {
          handler: async () => {
            const values = form.getValues();
            const items = values.items.filter((item) => !!item.item);
            if (
              values.customer &&
              items.length > 0 &&
              profile.selectedEnterprise
            ) {
              try {
                await createOrder({
                  variables: {
                    enterpriseId: profile.selectedEnterprise.id,
                    customerId: values.customer.customerId,
                    sendNotificationMails: values.sendNotificationMails,
                    order: {
                      consentGtc:
                        'Bestellung wurde durch Administrator erzeugt.',
                      consentAds: values.consentAds
                        ? 'Bestellung wurde durch Administrator erzeugt, Zustimmung zum Versenden von Info-Material wurde eingeholt.'
                        : undefined,
                      comment: values.comment,
                      items: items.map((item) => ({
                        quantity: item.quantity,
                        amount: parseFloat(item.amount as any),
                        courseModuleId:
                          item.type === 'Course' &&
                          item.item &&
                          (item.item as CourseUnitOrModuleItemType).type ===
                            'CourseModule'
                            ? item.item.value
                            : undefined,
                        courseUnitId:
                          item.type === 'Course' &&
                          item.item &&
                          (item.item as CourseUnitOrModuleItemType).type ===
                            'CourseUnit'
                            ? item.item.value
                            : undefined,
                        productId:
                          item.type === 'Product' && item.item
                            ? item.item.value
                            : undefined,
                      })),
                    },
                  },
                });
                success('Die Buchung wurde erfolgreich durchgeführt.');
                close();
                onCreate();
              } catch (e) {
                error('Bei der Bestellung ist ein Fehler aufgetreten.');
                log(JSON.stringify(e));
              }
            } else {
              error('Bitte überprüfe deine Angaben!');
            }
          },
          title: 'Bestellung erstellen',
          primary: true,
        },
      ],
    },
  );
  return { modal, open };
}
