import * as Types from '../graphql/generated/types';

import { gql } from '@apollo/client';
export type UserDetailData_Administrator_Fragment = { __typename?: 'Administrator', roleIds?: Array<any> | null, isBot: boolean, id: any, username?: string | null, fullName: string, primaryEmail?: string | null, status: Types.UserStatus, description?: string | null, roles: Array<{ __typename?: 'Role', id: any, name: Types.RoleType }>, image?: { __typename?: 'FilesystemFile', id: any, url: string } | null };

export type UserDetailData_Customer_Fragment = { __typename?: 'Customer', id: any, username?: string | null, fullName: string, primaryEmail?: string | null, status: Types.UserStatus, description?: string | null, orders: Array<{ __typename?: 'Order', id: any, createdAt: any, total: number }>, bookings: Array<{ __typename?: 'CourseModuleBooking', kind: Types.BookingKind, id: any, createdAt: any, courseModule: { __typename?: 'CourseModule', id: any, title?: string | null, sku: string, startDate: any, endDate: any, dateUncertain: boolean, withTime: boolean, online: boolean, correspondenceCourse: boolean, location?: { __typename?: 'Location', id: any, commonName: string } | null, searchableLocation?: { __typename?: 'Location', id: any, commonName: string } | null, courseUnit: { __typename?: 'CourseUnit', id: any, course: { __typename?: 'Course', id: any, title: string, verboseTitle?: string | null, abbreviation: string } } } } | { __typename?: 'CourseUnitBooking', kind: Types.BookingKind, id: any, createdAt: any, courseUnit: { __typename?: 'CourseUnit', id: any, title?: string | null, sku: string, startDate: any, endDate: any, dateUncertain: boolean, withTime: boolean, online: boolean, correspondenceCourse: boolean, location?: { __typename?: 'Location', id: any, commonName: string } | null, searchableLocation?: { __typename?: 'Location', id: any, commonName: string } | null, course: { __typename?: 'Course', id: any, title: string, verboseTitle?: string | null, abbreviation: string } } }>, image?: { __typename?: 'FilesystemFile', id: any, url: string } | null };

export type UserDetailData_Teacher_Fragment = { __typename?: 'Teacher', jobTitle?: string | null, subjectArea?: string | null, slug?: string | null, id: any, username?: string | null, fullName: string, primaryEmail?: string | null, status: Types.UserStatus, description?: string | null, image?: { __typename?: 'FilesystemFile', id: any, url: string } | null };

export type UserDetailDataFragment = UserDetailData_Administrator_Fragment | UserDetailData_Customer_Fragment | UserDetailData_Teacher_Fragment;

export const UserDetailDataFragmentDoc = gql`
    fragment UserDetailData on User {
  id
  username
  fullName
  primaryEmail
  status
  description
  image {
    id
    url
  }
  ... on Customer {
    orders {
      id
      createdAt
      total
    }
    bookings {
      kind
      id
      createdAt
      ... on CourseUnitBooking {
        courseUnit {
          id
          title
          sku
          startDate
          endDate
          dateUncertain
          withTime
          online
          correspondenceCourse
          location {
            id
            commonName
          }
          searchableLocation {
            id
            commonName
          }
          course {
            id
            title
            verboseTitle
            abbreviation
          }
        }
      }
      ... on CourseModuleBooking {
        courseModule {
          id
          title
          sku
          startDate
          endDate
          dateUncertain
          withTime
          online
          correspondenceCourse
          location {
            id
            commonName
          }
          searchableLocation {
            id
            commonName
          }
          courseUnit {
            id
            course {
              id
              title
              verboseTitle
              abbreviation
            }
          }
        }
      }
    }
  }
  ... on Administrator {
    roles {
      id
      name
    }
    roleIds
    isBot
  }
  ... on Teacher {
    jobTitle
    subjectArea
    slug
  }
}
    `;