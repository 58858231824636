import { Frame } from '@/frame';
import { TreeEditProvider } from '@/shared/tree-edit/tree-edit.provider';
import { Box } from '@/ui/box/box.component';
import { Grid } from '@/ui/grid/grid.component';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { useResources } from 'shared/resources/resources.provider';
import { CategoryNode } from '../category-node/category-node.component';
import { CourseUnitList } from '../course-unit-list/course-unit-list.component';
import { useCategoryTree } from './root.utils';

export const Root: ComponentType<RouteComponentProps> = (props) => {
  const { availableCourseCategories } = useResources();

  const categoryTree = useCategoryTree(availableCourseCategories);

  return (
    <>
      <Frame.SubTitle>Kursplan</Frame.SubTitle>
      <Frame.Content>
        <TreeEditProvider>
          <Grid.Row fullHeight>
            <Grid.Column oneFourth>
              <Box title="Kategorien / Kurse" fullHeight>
                {categoryTree?.map((category) => (
                  <CategoryNode key={category.id} {...category} />
                ))}
              </Box>
            </Grid.Column>
            <Grid.Column threeFourths>
              <CourseUnitList />
            </Grid.Column>
          </Grid.Row>
        </TreeEditProvider>
      </Frame.Content>
    </>
  );
};
