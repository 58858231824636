import classNames from 'classnames';
import React, { ComponentType, useState } from 'react';
import styles from './chip.module.scss';

interface Props {
  hoverHelp?: string;
  meaning?: 'warning' | 'success' | 'error';
  transparent?: boolean;
  className?: string;
  noVerticalSpacing?: boolean;
  big?: boolean;
  bright?: boolean;
}
export const Chip: ComponentType<Props> = (props) => {
  const {
    hoverHelp,
    transparent,
    meaning,
    className,
    noVerticalSpacing,
    big,
    bright,
  } = props;
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className={classNames(
        styles.host,
        !transparent && styles.withBackground,
        meaning && styles[meaning],
        className,
        big && styles.big,
        bright && styles.bright,
        noVerticalSpacing && styles.noVerticalSpacing,
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.children}
      {hovered && hoverHelp && <div className={styles.popup}>{hoverHelp}</div>}
    </div>
  );
};
