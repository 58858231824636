import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type EnterpriseDataFragment = { __typename?: 'Enterprise', id: any, name: string, slug?: string | null, isAdmin: boolean };

export type EnterprisesQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
}>;


export type EnterprisesQuery = { __typename?: 'Query', adminEnterprises: { __typename?: 'PaginatedEnterpriseResult', totalCount: number, data: Array<{ __typename?: 'Enterprise', id: any, name: string, slug?: string | null, isAdmin: boolean }> } };

export const EnterpriseDataFragmentDoc = gql`
    fragment EnterpriseData on Enterprise {
  id
  name
  slug
  isAdmin
}
    `;
export const EnterprisesDocument = gql`
    query enterprises($pagination: PaginationInput!) {
  adminEnterprises(pagination: $pagination) {
    totalCount
    data {
      ...EnterpriseData
    }
  }
}
    ${EnterpriseDataFragmentDoc}`;
export type EnterprisesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EnterprisesQuery, EnterprisesQueryVariables>, 'query'> & ({ variables: EnterprisesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EnterprisesComponent = (props: EnterprisesComponentProps) => (
      <ApolloReactComponents.Query<EnterprisesQuery, EnterprisesQueryVariables> query={EnterprisesDocument} {...props} />
    );
    

/**
 * __useEnterprisesQuery__
 *
 * To run a query within a React component, call `useEnterprisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterprisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterprisesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useEnterprisesQuery(baseOptions: Apollo.QueryHookOptions<EnterprisesQuery, EnterprisesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterprisesQuery, EnterprisesQueryVariables>(EnterprisesDocument, options);
      }
export function useEnterprisesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterprisesQuery, EnterprisesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterprisesQuery, EnterprisesQueryVariables>(EnterprisesDocument, options);
        }
export type EnterprisesQueryHookResult = ReturnType<typeof useEnterprisesQuery>;
export type EnterprisesLazyQueryHookResult = ReturnType<typeof useEnterprisesLazyQuery>;
export type EnterprisesQueryResult = Apollo.QueryResult<EnterprisesQuery, EnterprisesQueryVariables>;