import { useUploadFileMutation } from '@/file/explorer/explorer.generated';
import { FileGroup, RoleType } from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { HelpPopup } from '@/ui/help-popup/help-popup.component';
import { ImageChooser } from '@/ui/image-chooser/image-chooser.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { useUserStatusChoices } from '@/user/status/user-status-choices';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { PersonQuery } from '../base/base-editor.generated';
import { useUpdateAdministratorFilesMutation } from './editor.generated';

type Props = {
  user?: PersonQuery['person']['user'];
  person?: PersonQuery['person'];
};

export const AdministratorEditor: ComponentType<Props> = (props) => {
  const { user, person } = props;

  const _ = Translator.useTranslator();

  const profile = Profile.use();

  const [updateAdministratorFiles] = useUpdateAdministratorFilesMutation();
  const [uploadFile] = useUploadFileMutation();

  const { availableRoles } = useResources();

  const onUploadImage = useCallback(
    async (file) => {
      if (person && person.enterpriseIds && person.enterpriseIds.length > 0) {
        const result = await uploadFile({
          variables: {
            file,
            input: {
              enterpriseId: person.enterpriseIds[0],
              group: FileGroup.Administrator,
            },
          },
        });

        if (user && result.data) {
          await updateAdministratorFiles({
            variables: {
              id: user.id,
              input: { imageId: result.data?.adminUploadFile.id },
            },
          });
          success('Das Dozenten-Bild wurde erfolgreich gespeichert..');
        } else {
          error('Beim Hochladen ist ein Fehler aufgetreten.');
        }
      }
    },
    [user, person],
  );

  const onChangeImage = useCallback(
    async (selected: { id: string; name: string; url: string } | null) => {
      if (user) {
        await updateAdministratorFiles({
          variables: {
            id: user.id,
            input: { imageId: selected?.id || null },
          },
        });
        if (!selected) {
          success('Das User-Bild wurde entfernt.');
        } else {
          success('Der User wurde mit einem neuen Bild verknüpft.');
        }
      }
    },
    [user],
  );

  const isSuperAdmin = useMemo(() => {
    return profile.roles.find((r) => r.name === RoleType.Super) !== undefined;
  }, [profile]);

  return (
    <Grid.Row>
      <Grid.Column>
        <Section title="User">
          <Form.Input name="user.username" label="Benutzername" />
          <Form.Checkbox
            label="Dieser Benutzer ist ein Bot"
            name="user.isBot"
            defaultValue={false}
          />
        </Section>
        <Section title="Rechte">
          <Form.Select2
            multiple
            name="user.roleIds"
            label={
              <>
                Rollen/Berechtigungen
                <HelpPopup hover="Halte die Strg- (Windows) oder Befehlstaste (Mac) gedrückt, um mehrere Optionen auszuwählen." />
              </>
            }
            choices={availableRoles.map((role) => ({
              value: role.id,
              label: role.name,
            }))}
          />

          <Form.Select
            name="user.status"
            label="Status"
            choices={useUserStatusChoices()}
          />
        </Section>
      </Grid.Column>
      <Grid.Column>
        <Section title="Inhaltliches">
          <Form.Html name="user.description" label="Beschreibung / Vita" />
        </Section>
        <Section title="Bilder">
          {user && (
            <ImageChooser
              label="User-Bild"
              explorerFilter={{ group: FileGroup.Administrator }}
              disabled={false}
              loading={false}
              currentImageUrl={user.image?.url}
              onChange={onUploadImage}
              onSelect={onChangeImage}
            />
          )}
          {!user && (
            <InfoBox header="Der Datensatz muss zuerst gespeichert werden." />
          )}
        </Section>
      </Grid.Column>
    </Grid.Row>
  );
};
