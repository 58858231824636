import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { CourseUnitNodeDataFragmentDoc } from '../course-unit-list/course-unit-list.generated';
import { SeasonDataFragmentDoc } from '../../../course-management/season/detail/editor/editor.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DuplicateCourseUnitMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.DuplicateCourseUnitInput;
}>;

export type DuplicateCourseUnitMutation = {
  __typename?: 'Mutation';
  adminDuplicateCourseUnit: {
    __typename?: 'CourseUnit';
    id: any;
    title?: string | null;
    subTitle?: string | null;
    status: Types.CourseUnitStatus;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    online: boolean;
    correspondenceCourse: boolean;
    tagIds?: Array<any> | null;
    seasonId?: any | null;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      commonName: string;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      commonName: string;
    } | null;
    modules: Array<{
      __typename?: 'CourseModule';
      id: any;
      title?: string | null;
      subTitle?: string | null;
      startDate: any;
      endDate: any;
      status: Types.CourseModuleStatus;
      tagIds?: Array<any> | null;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
    }>;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
      enterpriseId: any;
      enterprise?: { __typename?: 'Enterprise'; id: any; name: string } | null;
    }> | null;
    season?: {
      __typename?: 'Season';
      id: any;
      status: Types.SeasonStatus;
      title: string;
    } | null;
  };
};

export type DuplicateSeasonMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.DuplicateSeasonInput;
}>;

export type DuplicateSeasonMutation = {
  __typename?: 'Mutation';
  adminDuplicateSeason: {
    __typename?: 'Season';
    id: any;
    title: string;
    status: Types.SeasonStatus;
    enterpriseId: any;
    enterprise: { __typename?: 'Enterprise'; name: string };
  };
};

export const DuplicateCourseUnitDocument = gql`
  mutation duplicateCourseUnit($id: UUID!, $input: DuplicateCourseUnitInput!) {
    adminDuplicateCourseUnit(unitIdToDuplicate: $id, input: $input) {
      ...CourseUnitNodeData
    }
  }
  ${CourseUnitNodeDataFragmentDoc}
`;
export type DuplicateCourseUnitMutationFn = Apollo.MutationFunction<
  DuplicateCourseUnitMutation,
  DuplicateCourseUnitMutationVariables
>;
export type DuplicateCourseUnitComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DuplicateCourseUnitMutation,
    DuplicateCourseUnitMutationVariables
  >,
  'mutation'
>;

export const DuplicateCourseUnitComponent = (
  props: DuplicateCourseUnitComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    DuplicateCourseUnitMutation,
    DuplicateCourseUnitMutationVariables
  >
    mutation={DuplicateCourseUnitDocument}
    {...props}
  />
);

/**
 * __useDuplicateCourseUnitMutation__
 *
 * To run a mutation, you first call `useDuplicateCourseUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCourseUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCourseUnitMutation, { data, loading, error }] = useDuplicateCourseUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateCourseUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateCourseUnitMutation,
    DuplicateCourseUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateCourseUnitMutation,
    DuplicateCourseUnitMutationVariables
  >(DuplicateCourseUnitDocument, options);
}
export type DuplicateCourseUnitMutationHookResult = ReturnType<
  typeof useDuplicateCourseUnitMutation
>;
export type DuplicateCourseUnitMutationResult =
  Apollo.MutationResult<DuplicateCourseUnitMutation>;
export type DuplicateCourseUnitMutationOptions = Apollo.BaseMutationOptions<
  DuplicateCourseUnitMutation,
  DuplicateCourseUnitMutationVariables
>;
export const DuplicateSeasonDocument = gql`
  mutation duplicateSeason($id: UUID!, $input: DuplicateSeasonInput!) {
    adminDuplicateSeason(seasonIdToDuplicate: $id, input: $input) {
      ...SeasonData
    }
  }
  ${SeasonDataFragmentDoc}
`;
export type DuplicateSeasonMutationFn = Apollo.MutationFunction<
  DuplicateSeasonMutation,
  DuplicateSeasonMutationVariables
>;
export type DuplicateSeasonComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DuplicateSeasonMutation,
    DuplicateSeasonMutationVariables
  >,
  'mutation'
>;

export const DuplicateSeasonComponent = (
  props: DuplicateSeasonComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    DuplicateSeasonMutation,
    DuplicateSeasonMutationVariables
  >
    mutation={DuplicateSeasonDocument}
    {...props}
  />
);

/**
 * __useDuplicateSeasonMutation__
 *
 * To run a mutation, you first call `useDuplicateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateSeasonMutation, { data, loading, error }] = useDuplicateSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateSeasonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateSeasonMutation,
    DuplicateSeasonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateSeasonMutation,
    DuplicateSeasonMutationVariables
  >(DuplicateSeasonDocument, options);
}
export type DuplicateSeasonMutationHookResult = ReturnType<
  typeof useDuplicateSeasonMutation
>;
export type DuplicateSeasonMutationResult =
  Apollo.MutationResult<DuplicateSeasonMutation>;
export type DuplicateSeasonMutationOptions = Apollo.BaseMutationOptions<
  DuplicateSeasonMutation,
  DuplicateSeasonMutationVariables
>;
