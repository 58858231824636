import classNames from 'classnames';
import React, { ComponentType, memo } from 'react';
import styles from './icon.module.scss';
interface Props {
  icon?: string;
  size?: number;
  inline?: boolean;
  color?: string;
  className?: string;
  onClick?: () => void;
}
export const Icon: ComponentType<Props> = memo((props) => {
  const { icon, color, onClick, inline, size, className } = props;

  return (
    <>
      <img
        onClick={onClick}
        className={classNames(styles.host, className, 'icon', color, {
          [styles.inline]: inline,
          [styles.clickable]: !!onClick,
        })}
        style={size ? { width: `${size}px`, height: `${size}px` } : undefined}
        src={icon}
      />
    </>
  );
});
