import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { CourseUnitNodeDataFragmentDoc } from '../../../../shared/course/course-unit-list/course-unit-list.generated';
import { PersonSimpleDetailDataFragmentDoc } from '../../../../contact/person/editor/editor.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CourseWithCourseUnitsAndBookingsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  options?: Types.InputMaybe<Types.CourseUnitSearchInput>;
}>;

export type CourseWithCourseUnitsAndBookingsQuery = {
  __typename?: 'Query';
  adminCourse: {
    __typename?: 'Course';
    title: string;
    courseUnitsForAdmin: Array<{
      __typename?: 'CourseUnit';
      id: any;
      title?: string | null;
      subTitle?: string | null;
      status: Types.CourseUnitStatus;
      startDate: any;
      endDate: any;
      withTime: boolean;
      dateUncertain: boolean;
      online: boolean;
      correspondenceCourse: boolean;
      tagIds?: Array<any> | null;
      seasonId?: any | null;
      bookings: Array<
        | {
            __typename?: 'CourseModuleBooking';
            id: any;
            createdAt: any;
            confirmedAt?: any | null;
            customer: {
              __typename?: 'Customer';
              id: any;
              person: {
                __typename?: 'Person';
                id: any;
                createdAt: any;
                title?: string | null;
                gender?: Types.Gender | null;
                firstName?: string | null;
                lastName?: string | null;
                anonymizedAt?: any | null;
                contactProperties: Array<{
                  __typename?: 'ContactProperty';
                  id: any;
                  key: Types.ContactPropertyKey;
                  value: string;
                }>;
                inquiries: Array<{
                  __typename?: 'Inquiry';
                  id: any;
                  createdAt: any;
                  status: Types.InquiryStatus;
                  assignee?: {
                    __typename?: 'Administrator';
                    id: any;
                    fullName: string;
                  } | null;
                }>;
                address: {
                  __typename?: 'Address';
                  company?: string | null;
                  contactPerson?: string | null;
                  streetAndNumber?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  country?: string | null;
                  other?: string | null;
                };
                invoiceAddress: {
                  __typename?: 'Address';
                  company?: string | null;
                  contactPerson?: string | null;
                  streetAndNumber?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  country?: string | null;
                  other?: string | null;
                };
              };
            };
          }
        | {
            __typename?: 'CourseUnitBooking';
            id: any;
            createdAt: any;
            confirmedAt?: any | null;
            customer: {
              __typename?: 'Customer';
              id: any;
              person: {
                __typename?: 'Person';
                id: any;
                createdAt: any;
                title?: string | null;
                gender?: Types.Gender | null;
                firstName?: string | null;
                lastName?: string | null;
                anonymizedAt?: any | null;
                contactProperties: Array<{
                  __typename?: 'ContactProperty';
                  id: any;
                  key: Types.ContactPropertyKey;
                  value: string;
                }>;
                inquiries: Array<{
                  __typename?: 'Inquiry';
                  id: any;
                  createdAt: any;
                  status: Types.InquiryStatus;
                  assignee?: {
                    __typename?: 'Administrator';
                    id: any;
                    fullName: string;
                  } | null;
                }>;
                address: {
                  __typename?: 'Address';
                  company?: string | null;
                  contactPerson?: string | null;
                  streetAndNumber?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  country?: string | null;
                  other?: string | null;
                };
                invoiceAddress: {
                  __typename?: 'Address';
                  company?: string | null;
                  contactPerson?: string | null;
                  streetAndNumber?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  country?: string | null;
                  other?: string | null;
                };
              };
            };
          }
      >;
      location?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        commonName: string;
      } | null;
      searchableLocation?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        commonName: string;
      } | null;
      modules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        subTitle?: string | null;
        startDate: any;
        endDate: any;
        status: Types.CourseModuleStatus;
        tagIds?: Array<any> | null;
        tags?: Array<{
          __typename?: 'Tag';
          id: any;
          name: string;
          slug?: string | null;
          context?: string | null;
          enterpriseId: any;
          enterprise?: {
            __typename?: 'Enterprise';
            id: any;
            name: string;
          } | null;
        }> | null;
      }>;
      tags?: Array<{
        __typename?: 'Tag';
        id: any;
        name: string;
        slug?: string | null;
        context?: string | null;
        enterpriseId: any;
        enterprise?: {
          __typename?: 'Enterprise';
          id: any;
          name: string;
        } | null;
      }> | null;
      season?: {
        __typename?: 'Season';
        id: any;
        status: Types.SeasonStatus;
        title: string;
      } | null;
    }>;
  };
};

export const CourseWithCourseUnitsAndBookingsDocument = gql`
  query courseWithCourseUnitsAndBookings(
    $id: UUID!
    $options: CourseUnitSearchInput
  ) {
    adminCourse(id: $id) {
      title
      courseUnitsForAdmin(options: $options) {
        ...CourseUnitNodeData
        bookings {
          id
          createdAt
          confirmedAt
          customer {
            id
            person {
              ...PersonSimpleDetailData
            }
          }
        }
      }
    }
  }
  ${CourseUnitNodeDataFragmentDoc}
  ${PersonSimpleDetailDataFragmentDoc}
`;
export type CourseWithCourseUnitsAndBookingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CourseWithCourseUnitsAndBookingsQuery,
    CourseWithCourseUnitsAndBookingsQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: CourseWithCourseUnitsAndBookingsQueryVariables;
        skip?: boolean;
      }
    | { skip: boolean }
  );

export const CourseWithCourseUnitsAndBookingsComponent = (
  props: CourseWithCourseUnitsAndBookingsComponentProps,
) => (
  <ApolloReactComponents.Query<
    CourseWithCourseUnitsAndBookingsQuery,
    CourseWithCourseUnitsAndBookingsQueryVariables
  >
    query={CourseWithCourseUnitsAndBookingsDocument}
    {...props}
  />
);

/**
 * __useCourseWithCourseUnitsAndBookingsQuery__
 *
 * To run a query within a React component, call `useCourseWithCourseUnitsAndBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseWithCourseUnitsAndBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseWithCourseUnitsAndBookingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCourseWithCourseUnitsAndBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseWithCourseUnitsAndBookingsQuery,
    CourseWithCourseUnitsAndBookingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseWithCourseUnitsAndBookingsQuery,
    CourseWithCourseUnitsAndBookingsQueryVariables
  >(CourseWithCourseUnitsAndBookingsDocument, options);
}
export function useCourseWithCourseUnitsAndBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseWithCourseUnitsAndBookingsQuery,
    CourseWithCourseUnitsAndBookingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseWithCourseUnitsAndBookingsQuery,
    CourseWithCourseUnitsAndBookingsQueryVariables
  >(CourseWithCourseUnitsAndBookingsDocument, options);
}
export type CourseWithCourseUnitsAndBookingsQueryHookResult = ReturnType<
  typeof useCourseWithCourseUnitsAndBookingsQuery
>;
export type CourseWithCourseUnitsAndBookingsLazyQueryHookResult = ReturnType<
  typeof useCourseWithCourseUnitsAndBookingsLazyQuery
>;
export type CourseWithCourseUnitsAndBookingsQueryResult = Apollo.QueryResult<
  CourseWithCourseUnitsAndBookingsQuery,
  CourseWithCourseUnitsAndBookingsQueryVariables
>;
