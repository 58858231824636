import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ListLocationDataFragment = {
  __typename?: 'Location';
  id: any;
  status: Types.LocationStatus;
  name: string;
  city?: string | null;
  address?: { __typename?: 'Address'; city?: string | null } | null;
  enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
  parent?: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    address?: { __typename?: 'Address'; city?: string | null } | null;
  } | null;
};

export type LocationsQueryVariables = Types.Exact<{
  pagination: Types.PaginationInput;
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type LocationsQuery = {
  __typename?: 'Query';
  adminLocations: {
    __typename?: 'PaginatedLocationResult';
    totalCount: number;
    data: Array<{
      __typename?: 'Location';
      id: any;
      status: Types.LocationStatus;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
      enterprises: Array<{ __typename?: 'Enterprise'; id: any; name: string }>;
      parent?: {
        __typename?: 'Location';
        id: any;
        name: string;
        city?: string | null;
        address?: { __typename?: 'Address'; city?: string | null } | null;
      } | null;
    }>;
  };
};

export const ListLocationDataFragmentDoc = gql`
  fragment ListLocationData on Location {
    id
    status
    name
    address {
      city
    }
    city
    enterprises {
      id
      name
    }
    parent {
      id
      name
      city
      address {
        city
      }
    }
  }
`;
export const LocationsDocument = gql`
  query locations($pagination: PaginationInput!, $query: String) {
    adminLocations(pagination: $pagination, query: $query) {
      totalCount
      data {
        ...ListLocationData
      }
    }
  }
  ${ListLocationDataFragmentDoc}
`;
export type LocationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LocationsQuery,
    LocationsQueryVariables
  >,
  'query'
> &
  ({ variables: LocationsQueryVariables; skip?: boolean } | { skip: boolean });

export const LocationsComponent = (props: LocationsComponentProps) => (
  <ApolloReactComponents.Query<LocationsQuery, LocationsQueryVariables>
    query={LocationsDocument}
    {...props}
  />
);

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options,
  );
}
export function useLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options,
  );
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<
  typeof useLocationsLazyQuery
>;
export type LocationsQueryResult = Apollo.QueryResult<
  LocationsQuery,
  LocationsQueryVariables
>;
