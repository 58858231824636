import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import introspection from './generated/introspection';

const GRAPHQL_HTTP_URI = process.env.GRAPHQL_HTTP_URI;

export const client = new ApolloClient({
  //@ts-ignore
  link: createUploadLink({
    uri: GRAPHQL_HTTP_URI,
    /*  headers: {
      'content-type': 'application/json',
    }, */
    fetchOptions: {
      cors: 'no-cors',
      credentials: 'include',
    },
  }),
  //uri: GRAPHQL_HTTP_URI,
  cache: new InMemoryCache({
    addTypename: true,
    resultCaching: true,
    possibleTypes: introspection.possibleTypes,
  }),
  credentials: 'include',
});
