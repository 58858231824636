import { yupResolver } from '@hookform/resolvers/yup';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHideFrame } from 'shared/hooks/hide-frame';
import globalStyles from 'styles/global.scss';
import { object, ref, string } from 'yup';
import { Button } from '../../ui/button/button.component';
import { InputField } from '../../ui/input-field/input-field.component';
import { Loader } from '../../ui/loader/loader.component';
import { useResetPasswordMutation } from './reset-password.generated';

const schema = object({
  password: string()
    .required('Bitte gib ein Passwort ein!')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_@#\$%\^&\*])(?=.{8,})/,
      'Dein Passwort muss mindestens 8 Stellen haben und aus Klein- und Großbuchstaben sowie mindestens einer Zahl und einem Sonderzeichen bestehen.',
    ),
  passwordConfirmation: string()
    .required('Bitte bestätige dein Passwort!')
    .oneOf([ref('password')], 'Die Passwörter müssen übereinstimmen.'),
});

interface Params {
  code?: string;
}

export const ResetPasswordComponent: ComponentType<
  RouteComponentProps<Params>
> = (props) => {
  const { code } = props;
  const [serverError, setServerError] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [reset, { loading }] = useResetPasswordMutation();

  const { formState, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit(
        async (values) => {
          if (code) {
            setServerError(undefined);
            try {
              const result = await reset({
                variables: { input: { password: values.password, code } },
              });
              if (result.data?.redeemInvitation) {
                setSuccess(true);
              } else {
                setServerError(
                  'Ein Fehler beim Zurücksetzen des Passwortes ist aufgetreten.',
                );
              }
            } catch (e) {
              setServerError(
                'Ein Fehler beim Zurücksetzen des Passwortes ist aufgetreten.',
              );
            }
          }
        },
        () => {},
      ),
    [code],
  );
  useHideFrame();
  const domNode = document.getElementById('frame');
  if (!domNode) {
    return null;
  }

  if (!code) {
    return null;
  }

  return (
    <div className="centered" style={{ width: 300, margin: '150px auto' }}>
      {loading && <Loader small />}
      {success && (
        <>
          <h1>OK.</h1>
          <p>
            Du kannst dich jetzt mit deinem neuen Passwort und deiner
            E-Mail-Adresse anmelden.
          </p>
          <Link to="/login">Zur Anmeldung</Link>
        </>
      )}
      {!success && (
        <>
          <h1 className={globalStyles.h2}>Erstelle dein neues Passwort</h1>
          <Controller
            name="password"
            render={({ field }) => (
              <InputField
                {...field}
                type="password"
                placeholder="Passwort"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
                error={formState.errors.password?.message}
              />
            )}
            control={control}
            defaultValue=""
          />

          <Controller
            name="passwordConfirmation"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                type="password"
                placeholder="Passwort (wiederholen)"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
                error={formState.errors.passwordConfirmation?.message}
              />
            )}
            defaultValue=""
          />

          {serverError && <p className={globalStyles.error}>{serverError}</p>}
          <div>
            <Button onClick={onSubmit} label="Passwort setzen" />
          </div>
        </>
      )}
    </div>
  );
};
