import {
  CourseCategoryGraphOptions,
  Graph,
} from '@/statistics/shared/chart/graph/graph';
import { Button } from '@/ui/button/button.component';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import React, {
  ComponentType,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { useResources } from 'shared/resources/resources.provider';
import { GraphItemOptionsProps } from '../../../../shared/chart/graph/graph-item/options/graph-options.hooks';
import styles from './graph-options.module.scss';

interface CourseCategoryGraphItemOptionsProps extends GraphItemOptionsProps {
  stateDispatch: [
    CourseCategoryGraphOptions,
    Dispatch<SetStateAction<CourseCategoryGraphOptions>>,
  ];
  graph: Graph<any>;
}

export const CourseCategoryGraphItemOptions: ComponentType<
  CourseCategoryGraphItemOptionsProps
> = (props) => {
  const { graph, stateDispatch } = props;
  const { availableCourseCategories } = useResources();
  const [state, setState] = stateDispatch;

  const toggleCourseCategory = useCallback(
    (value: boolean, id: string) => {
      const currentCategoryIds = state.courseCategoryIds || [];
      setState({
        ...state,
        courseCategoryIds: value
          ? [...currentCategoryIds, id]
          : currentCategoryIds.filter((cId) => cId !== id),
      });
    },
    [state],
  );

  const handleSelectAll = useCallback(() => {
    setState({
      ...state,
      courseCategoryIds: availableCourseCategories.map((cat) => cat.id),
    });
  }, [state]);

  const handleDeselectAll = useCallback(() => {
    setState({
      ...state,
      courseCategoryIds: [],
    });
  }, []);

  useEffect(() => {
    setState(
      (graph.options || {
        courseCategoryIds: [],
      }) as CourseCategoryGraphOptions,
    );
  }, []);

  return (
    <div className={styles.host}>
      <div className={styles.buttonContainer}>
        <Button label="Alle auswählen" onClick={handleSelectAll} />
        <Button label="Alle abwählen" onClick={handleDeselectAll} />
      </div>
      {availableCourseCategories.map((c) => (
        <Checkbox
          label={c.title}
          checked={state.courseCategoryIds?.includes(c.id)}
          onChange={(value) => toggleCourseCategory(value, c.id)}
        />
      ))}
    </div>
  );
};
