import { Link } from '@reach/router';
import classnames from 'classnames';
import React, { ComponentType, MouseEventHandler, ReactText } from 'react';
import { Icon } from '../icon/icon.component';
import styles from './button.module.scss';

interface Props {
  small?: boolean;
  fluid?: boolean;
  primary?: boolean;
  warning?: boolean;
  error?: boolean;
  success?: boolean;
  label?: ReactText;
  icon?: string;
  onClick?: MouseEventHandler;
  linkTo?: string;
  href?: string;
  className?: string;
  transparent?: boolean;
  inline?: boolean;
  disabled?: boolean;
  round?: boolean;
  meaning?: 'error' | 'success' | 'warning';
}

export const ButtonComponent: ComponentType<Props> = (props) => {
  const {
    meaning,
    inline,
    label,
    icon,
    onClick,
    className,
    transparent,
    linkTo,
    href,
    primary,
    warning,
    error,
    success,
    fluid,
    small,
    disabled,
    round,
  } = props;

  const classNames = classnames(
    'button',
    styles.host,
    className,
    transparent && styles.transparent,
    primary && styles.primary,
    inline && styles.inline,
    (warning || meaning === 'warning') && styles.warning,
    (error || meaning === 'error') && styles.error,
    (success || meaning === 'success') && styles.success,
    small && styles.small,
    fluid && styles.fluid,
    disabled && styles.disabled,
    round && styles.round,
  );

  if (href) {
    return (
      <a className={classNames} href={href}>
        {icon && <Icon icon={icon} inline />}
        {label}
      </a>
    );
  }

  if (linkTo) {
    return (
      <Link className={classNames} to={linkTo}>
        {icon && <Icon icon={icon} inline />}
        {label}
      </Link>
    );
  }
  return (
    <button className={classNames} disabled={disabled} onClick={onClick}>
      {icon && <Icon icon={icon} inline />}
      {label}
    </button>
  );
};

const Row: ComponentType = (props) => {
  const { children } = props;
  return <div className={styles.row}>{children}</div>;
};

export const Button = Object.assign(ButtonComponent, { Row });
