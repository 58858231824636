import { RoleType } from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import React, { ComponentType, ReactNode } from 'react';

interface Props {
  role: RoleType;
  otherwise?: ReactNode;
}

export const IfRole: ComponentType<Props> = (props) => {
  const { children, role, otherwise } = props;
  const profile = Profile.use();
  if (!profile) {
    return null;
  }

  if (profile.__typename !== 'Administrator') {
    return null;
  }

  if (!profile.roles.find((profileRole) => profileRole.name === role)) {
    return <>{otherwise}</>;
  }

  return <>{children}</>;
};

export const IfSuper: ComponentType<Pick<Props, 'otherwise'>> = ({
  children,
  otherwise,
}) => (
  <IfRole role={RoleType.Super} otherwise={otherwise}>
    {children}
  </IfRole>
);
