import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import {
  CompaniesQueryVariables,
  CompanyListDataFragment,
  useCompaniesQuery,
} from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery();
  const { data, loading } = useCompaniesQuery({
    variables: { pagination: getPaginationFromQuery(query) },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>Firmen</Frame.SubTitle>
      <Frame.ActionBar>
        <Button
          linkTo="/contacts/companies/create-company"
          label="Neuer Firmenkontakt"
        />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<CompanyListDataFragment, CompaniesQueryVariables['filter']>
          loading={loading}
          data={data?.adminCompanies?.data || []}
          totalCount={data?.adminCompanies?.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'Stadt',
              sortable: true,
            },
            {
              name: 'Firmen',
              sortable: true,
            },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              {/* <Filter
              value={value || ''}
              onChange={onChange}
              placeholder={'Suche…'}
              loading={loading}
            /> */}
            </>
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/edit-company`}>{item.name}</Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit-company`}>
                  {item.address?.city}
                </Link>
              </td>

              <td>
                {item.enterprises
                  .map((enterprise) => enterprise.name)
                  .join(', ')}
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
