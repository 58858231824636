import { CourseUnit } from '@/graphql/generated/types';
import { DiscountLabel } from '@/order/discount-label/discount-label.component';
import globalStyles from '@/styles/global.scss';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Row } from '@/ui/table/row.component';
import { Table } from '@/ui/table/table.component';
import { Link } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import {
  getCourseUnitDates,
  getCourseUnitLocationLabel,
} from 'shared/course/utils';
import { OrdersQuery } from '../../list/list.generated';
import styles from './order-items-overview.module.scss';

interface OrderItemsOverviewProps {
  order: OrdersQuery['adminOrders']['data'][number];
}

function getProductName(
  item: OrdersQuery['adminOrders']['data'][number]['items'][number],
) {
  if (item.__typename === 'BookingOrderItem') {
    const booking = item.booking as any;
    const locationLabel = getCourseUnitLocationLabel(
      booking.courseModule || booking.courseUnit,
    );

    return (
      <>
        {booking.courseModule?.courseUnit.course.abbreviation ||
          booking.courseUnit?.course.abbreviation}
        {locationLabel && <> ({locationLabel})</>}
      </>
    );
  }

  if (item.__typename === 'PackageBookingOrderItem') {
    return (
      <>
        {item.package.title} - {item.package.subTitle}
      </>
    );
  }

  if (item.__typename === 'ProductOrderItem') {
    return item.product.title;
  }

  return 'Rabatt';
}

export const OrderItemsOverview: ComponentType<OrderItemsOverviewProps> = (
  props,
) => {
  const { order } = props;

  const items = useMemo(() => {
    return order.items.filter((i) => i.__typename !== 'DiscountOrderItem');
  }, [order.items]);
  return (
    <Table
      compact
      columns={[
        'Anzahl',
        'Produkt',
        'Unternehmen',
        <th className="right">Preis</th>,
      ]}
      data={items}
      additionalRows={
        <>
          {order.discounts.map((d) => (
            <Row key={d.id} className={styles.withBorderBottom}>
              <td></td>
              <td>{d.reason}</td>
              <td></td>
              <td className="numeric">
                <DiscountLabel type={d.type} value={d.amount} />
              </td>
            </Row>
          ))}
          <Row>
            <td colSpan={2}></td>
            <td className="total">Gesamt</td>
            <td className="numeric total">
              <Formatted.Currency value={order.total} />
            </td>
          </Row>
        </>
      }
      render={(item) => {
        let enterpriseName = '';
        let booking;
        let courseUnitItem: CourseUnit | undefined = undefined;
        if (item.__typename === 'ProductOrderItem') {
          enterpriseName = item.product.enterprise.name;
        } else if (item.__typename === 'BookingOrderItem') {
          booking = item.booking;

          enterpriseName =
            booking?.courseUnit?.course.category.enterprise.name ||
            booking?.courseModule?.courseUnit?.course.category.enterprise.name;
          courseUnitItem = booking?.courseUnit || booking?.courseModule;
        } else if (item.__typename === 'PackageBookingOrderItem') {
          enterpriseName = item.package.enterprise.name;
        }

        const innerProduct = (
          <>
            {getProductName(item)}
            {courseUnitItem && (
              <div className={globalStyles.sub}>
                {getCourseUnitDates(courseUnitItem)}
              </div>
            )}
          </>
        );

        return (
          <>
            <Row
              className={
                (!item.discounts || item.discounts.length == 0) &&
                styles.withBorderBottom
              }
            >
              <td>{item.quantity}</td>
              <td>
                {courseUnitItem && (
                  <Link to={`/course-handling/bookings/${booking?.id}/view`}>
                    {innerProduct}
                  </Link>
                )}
                {!courseUnitItem && innerProduct}
              </td>
              <td>{enterpriseName}</td>
              <td className="numeric">
                <Formatted.Currency value={item.amount} />
              </td>
            </Row>
            {item.discounts?.length > 0 && (
              <>
                {item.discounts.map((d, i) => (
                  <Row
                    className={
                      i === item.discounts.length - 1 && styles.withBorderBottom
                    }
                    key={d.id}
                  >
                    <td>{item.quantity}</td>
                    <td>{d.reason}</td>
                    <td>{enterpriseName}</td>
                    <td className="numeric">
                      <DiscountLabel value={d.amount} type={d.type} />
                    </td>
                  </Row>
                ))}
              </>
            )}
          </>
        );
      }}
    />
  );
};
