import { Button } from '@/ui/button/button.component';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import classNames from 'classnames';
import React, { ComponentType, useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Translator } from 'shared/translator/translator.component';
import globalStyles from 'styles/global.scss';
import { Graph } from '../graph';
import styles from './graph-item.module.scss';
import { useGraphOptionsModal } from './options/graph-options.hooks';

interface GraphItemProps {
  graph: Graph<any>;
  onToggle: (value: boolean) => void;
  onRemove: () => void;
  onUpdateOptions: (data: any) => void;
}

export const GraphItem: ComponentType<GraphItemProps> = (props) => {
  const { graph, onToggle, onRemove, onUpdateOptions } = props;

  const { modal, open } = useGraphOptionsModal(graph, onUpdateOptions);
  const [colorPicker, setColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    graph.options?.color || '#000000',
  );
  const initialRef = useRef(true);

  const _ = Translator.useTranslator();

  useEffect(() => {
    if (!initialRef.current) {
      onUpdateOptions({ ...graph.options, color: selectedColor });
    }
    initialRef.current = false;
  }, [selectedColor]);
  return (
    <>
      <div
        className={classNames(styles.host, !graph.enabled && styles.disabled)}
      >
        {modal}
        <div className={globalStyles.linkLike} onClick={open}>
          {graph.name}
          <br />
          <span className={globalStyles.sub}>
            {_(`graph_type.${graph.type}`)}
          </span>
        </div>
        <div className={styles.actions}>
          <Checkbox
            small
            onChange={(v) => onToggle(v)}
            checked={graph.enabled}
          />
          <div
            className={styles.color}
            style={{ background: selectedColor }}
            onClick={() => setColorPicker((c) => !c)}
          ></div>
          <Button small round meaning="error" onClick={onRemove} label="-" />
        </div>
        {colorPicker && (
          <>
            <div className={styles.pickerModal}>
              <SketchPicker
                color={selectedColor}
                className={styles.picker}
                onChange={(color) => {
                  setSelectedColor(color.hex);
                }}
                // onChangeComplete={(color) => {
                //   setColorPicker(false);
                //   setSelectedColor(color.hex);
                // }}
              />
            </div>
            <div
              className={styles.pickerCover}
              onClick={() => setColorPicker(false)}
            ></div>
          </>
        )}
      </div>
    </>
  );
};
