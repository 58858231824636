import { ContactPropertyKey } from '@/graphql/generated/types';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import { Radio } from '@/ui/radio-button/radio-button.component';
import React, { ComponentType, useCallback, useMemo } from 'react';
import styles from '../duplicate-resolver.module.scss';
import { MergeCandidate, MergeState } from '../types';
import { getAttributesByType } from './attributes';
import { RelatedUserType } from './utils';

interface AttributesViewProps {
  candidates: MergeCandidate[];
  mergeState?: MergeState;
  activeRecordIds: string[];
  userType?: RelatedUserType;
  primaryRecordId?: string;
}

export const AttributesView: ComponentType<AttributesViewProps> = (props) => {
  const { candidates, mergeState, userType, activeRecordIds, primaryRecordId } =
    props;

  const attributes = useMemo(() => {
    return getAttributesByType(userType);
  }, [userType]);

  const onMarkPropertyAsChecked = useCallback(
    (
      candidateId: string,
      property: keyof MergeCandidate | ContactPropertyKey,
    ) => {
      // set the mergeState's relevant property to the actual candidateId
    },
    [],
  );

  const onToggleValue = useCallback(
    (
      candidateId: string,
      index: number,
      property: keyof MergeCandidate | ContactPropertyKey,
      checked: boolean,
    ) => {
      if (checked) {
        // push the candidateId[index] to mergeState[property]
      } else {
        // remove index from candidateId in mergeState[property]
      }
    },
    [],
  );

  return (
    <>
      {attributes.map((item, idx) => (
        <tr key={idx}>
          <td>{item.label}</td>
          {/* TODO: display the representation of mergeState in the below td */}
          <td></td>
          {candidates.map((record) => {
            return (
              <td
                key={Math.random()}
                className={
                  activeRecordIds.includes(record.id)
                    ? undefined
                    : styles.disabledCell
                }
              >
                {item.extractMultipleValues ? (
                  item
                    .extractMultipleValues(record)
                    .map(({ label, value }, idx) => (
                      <Checkbox
                        key={idx}
                        label={label}
                        onChange={(checked) =>
                          onToggleValue(record.id, idx, item.name, checked)
                        }
                        checked={false}
                        noSpacing
                      />
                    ))
                ) : (
                  <Radio
                    label={item.render ? item.render(record) : ''}
                    checked={false}
                    onChange={() =>
                      onMarkPropertyAsChecked(record.id, item.name)
                    }
                    disabled={!activeRecordIds.includes(record.id)}
                    value={''}
                    small
                  />
                )}
              </td>
            );
          })}
        </tr>
      ))}
    </>
  );
};
