import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DetailRoomDataFragment = { __typename?: 'Room', id: any, name: string, locationId: any, maxPeople?: number | null, pricePerHour?: number | null, location: { __typename?: 'Location', id: any, name: string }, equipments: Array<{ __typename?: 'Equipment', id: any, name: string }> };

export type RoomQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type RoomQuery = { __typename?: 'Query', adminRoom: { __typename?: 'Room', id: any, name: string, locationId: any, maxPeople?: number | null, pricePerHour?: number | null, location: { __typename?: 'Location', id: any, name: string }, equipments: Array<{ __typename?: 'Equipment', id: any, name: string }> } };

export type CreateRoomMutationVariables = Types.Exact<{
  input: Types.CreateRoomInput;
}>;


export type CreateRoomMutation = { __typename?: 'Mutation', adminCreateRoom: { __typename?: 'Room', id: any, name: string, locationId: any, maxPeople?: number | null, pricePerHour?: number | null, location: { __typename?: 'Location', id: any, name: string }, equipments: Array<{ __typename?: 'Equipment', id: any, name: string }> } };

export type EditRoomMutationVariables = Types.Exact<{
  input: Types.EditRoomInput;
  id: Types.Scalars['UUID'];
}>;


export type EditRoomMutation = { __typename?: 'Mutation', adminEditRoom: { __typename?: 'Room', id: any, name: string, locationId: any, maxPeople?: number | null, pricePerHour?: number | null, location: { __typename?: 'Location', id: any, name: string }, equipments: Array<{ __typename?: 'Equipment', id: any, name: string }> } };

export const DetailRoomDataFragmentDoc = gql`
    fragment DetailRoomData on Room {
  id
  name
  location {
    id
    name
  }
  locationId
  maxPeople
  pricePerHour
  equipments {
    id
    name
  }
}
    `;
export const RoomDocument = gql`
    query room($id: UUID!) {
  adminRoom(id: $id) {
    ...DetailRoomData
  }
}
    ${DetailRoomDataFragmentDoc}`;
export type RoomComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RoomQuery, RoomQueryVariables>, 'query'> & ({ variables: RoomQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RoomComponent = (props: RoomComponentProps) => (
      <ApolloReactComponents.Query<RoomQuery, RoomQueryVariables> query={RoomDocument} {...props} />
    );
    

/**
 * __useRoomQuery__
 *
 * To run a query within a React component, call `useRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoomQuery(baseOptions: Apollo.QueryHookOptions<RoomQuery, RoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoomQuery, RoomQueryVariables>(RoomDocument, options);
      }
export function useRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomQuery, RoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoomQuery, RoomQueryVariables>(RoomDocument, options);
        }
export type RoomQueryHookResult = ReturnType<typeof useRoomQuery>;
export type RoomLazyQueryHookResult = ReturnType<typeof useRoomLazyQuery>;
export type RoomQueryResult = Apollo.QueryResult<RoomQuery, RoomQueryVariables>;
export const CreateRoomDocument = gql`
    mutation createRoom($input: CreateRoomInput!) {
  adminCreateRoom(input: $input) {
    ...DetailRoomData
  }
}
    ${DetailRoomDataFragmentDoc}`;
export type CreateRoomMutationFn = Apollo.MutationFunction<CreateRoomMutation, CreateRoomMutationVariables>;
export type CreateRoomComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRoomMutation, CreateRoomMutationVariables>, 'mutation'>;

    export const CreateRoomComponent = (props: CreateRoomComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRoomMutation, CreateRoomMutationVariables> mutation={CreateRoomDocument} {...props} />
    );
    

/**
 * __useCreateRoomMutation__
 *
 * To run a mutation, you first call `useCreateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomMutation, { data, loading, error }] = useCreateRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoomMutation, CreateRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoomMutation, CreateRoomMutationVariables>(CreateRoomDocument, options);
      }
export type CreateRoomMutationHookResult = ReturnType<typeof useCreateRoomMutation>;
export type CreateRoomMutationResult = Apollo.MutationResult<CreateRoomMutation>;
export type CreateRoomMutationOptions = Apollo.BaseMutationOptions<CreateRoomMutation, CreateRoomMutationVariables>;
export const EditRoomDocument = gql`
    mutation editRoom($input: EditRoomInput!, $id: UUID!) {
  adminEditRoom(input: $input, id: $id) {
    ...DetailRoomData
  }
}
    ${DetailRoomDataFragmentDoc}`;
export type EditRoomMutationFn = Apollo.MutationFunction<EditRoomMutation, EditRoomMutationVariables>;
export type EditRoomComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRoomMutation, EditRoomMutationVariables>, 'mutation'>;

    export const EditRoomComponent = (props: EditRoomComponentProps) => (
      <ApolloReactComponents.Mutation<EditRoomMutation, EditRoomMutationVariables> mutation={EditRoomDocument} {...props} />
    );
    

/**
 * __useEditRoomMutation__
 *
 * To run a mutation, you first call `useEditRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoomMutation, { data, loading, error }] = useEditRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditRoomMutation(baseOptions?: Apollo.MutationHookOptions<EditRoomMutation, EditRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRoomMutation, EditRoomMutationVariables>(EditRoomDocument, options);
      }
export type EditRoomMutationHookResult = ReturnType<typeof useEditRoomMutation>;
export type EditRoomMutationResult = Apollo.MutationResult<EditRoomMutation>;
export type EditRoomMutationOptions = Apollo.BaseMutationOptions<EditRoomMutation, EditRoomMutationVariables>;