import {
  getPersonDescription,
  getPersonDetailLink,
} from '@/contact/person/utils';
import { DuplicatesCell } from '@/contact/person/view/duplicates-cell.component';
import { PersonEmail } from '@/contact/person/view/person-email.component';
import { UserStatusChip } from '@/contact/person/view/user-status-chip.component';
import { FilterPersonInput } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { DropdownMenu } from '@/ui/dropdown-menu/dropdown-menu.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { Frame } from '../../../../frame';
import { DebouncedInput } from '../../../../ui/debounced-input/debounced-input.component';
import { ListView } from '../../../../ui/list-view/list-view.component';
import { Row } from '../../../../ui/table/row.component';
import { usePersonActions } from '../../actions/actions.hooks';
import { PersonListDataFragment, usePersonsQuery } from '../list.generated';

export const CustomerList: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery<{ query: string }>();
  const { data, loading, refetch } = usePersonsQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      withDuplicates: true,
      filter: { query: query.query, userType: 'Customer' },
    },
    fetchPolicy: 'network-only',
  });

  const { modalAnonymize, modalDelete, askForAnonymize, askForDelete } =
    usePersonActions(() => refetch());

  const _ = Translator.useTranslator();

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="/contacts/customers/create" label="Neuer Kunde" />
      </Frame.ActionBar>
      <Frame.Content>
        {modalAnonymize}
        {modalDelete}
        <ListView<PersonListDataFragment, FilterPersonInput>
          loading={loading}
          data={data?.persons?.data || []}
          totalCount={data?.persons?.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'E-Mail',
              sortable: true,
            },
            {
              name: 'Benutzer',
              sortable: true,
            },
            {
              name: 'Unternehmen',
              sortable: true,
            },
            { name: 'Status', sortable: true },
            { name: 'Hinweise', sortable: false },
            { name: 'Aktionen', sortable: false },
          ]}
          renderFilter={({ value, onChange }) => (
            <DebouncedInput
              debounceRate={400}
              value={value?.query || ''}
              onChange={(value) => onChange('query', value)}
              placeholder={'Suche…'}
            />
          )}
          renderRow={(item) => {
            const detailLink = getPersonDetailLink(item);
            return (
              <Row key={item.id}>
                <td>
                  <Link to={detailLink}>{getPersonDescription(item)}</Link>
                </td>

                <td>
                  <Link to={detailLink}>
                    <PersonEmail person={item} />
                  </Link>
                </td>
                <td>
                  <Link to={detailLink}>{item.user?.username}</Link>
                </td>

                <td>
                  {item.enterprises
                    .map((enterprise) => enterprise.name)
                    .join(', ')}
                </td>
                <td>
                  <UserStatusChip user={item.user} />
                </td>
                <td>
                  {item.__typename === 'PersonWithDuplicates' &&
                    item.duplicates && (
                      <DuplicatesCell
                        duplicates={item.duplicates}
                        linkTo={`../../${item.id}/duplicates`}
                      />
                    )}
                </td>
                <td>
                  <DropdownMenu
                    actions={[
                      {
                        label: 'Löschen (anonym)',
                        meaning: 'error',
                        onClick: () => askForAnonymize(item),
                      },
                      {
                        label: 'Löschen (destruktiv)',
                        meaning: 'error',
                        onClick: () => askForDelete(item),
                      },
                    ]}
                  />
                </td>
              </Row>
            );
          }}
        />
      </Frame.Content>
    </>
  );
};
