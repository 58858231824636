import { DefaultToastOptions } from 'react-hot-toast';

export const toastOptions: DefaultToastOptions = {
  duration: 4000,
  position: 'top-right',
  // Styling
  style: {
    padding: '16px',
    borderRadius: '0',
  },
  className: '',
  // Custom Icon
  // icon: '👏',
  // Change colors of success/error/loading icon
  iconTheme: {
    primary: '#000',
    secondary: '#fff',
  },
  // Aria
  ariaProps: {
    role: 'status',
    'aria-live': 'polite',
  },
  success: {
    style: {
      background: '#1b801b',
      color: '#fff',
    },
  },
  error: {
    style: {
      background: '#b3404a',
      color: '#fff',
    },
  },
};
