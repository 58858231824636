import check from 'assets/image/icons/check.svg';
import classNames from 'classnames';
import React, { ComponentType, useCallback, useRef, useState } from 'react';
import { Icon } from '../icon/icon.component';
import styles from './copyable.module.scss';
interface Props {
  inline?: boolean;
  valueToCopy?: string;
  label?: string;
  copyOnClick?: boolean;
  disabled?: boolean;
}

export const Copyable: ComponentType<Props> = (props) => {
  const {
    disabled,
    inline,
    label = 'Kopieren',
    valueToCopy,
    copyOnClick,
  } = props;
  const [isCopied, setIsCopied] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { children } = props;
  const timerRef = useRef<any>(null);
  const valueRef = useRef<any>(null);

  const onMouseEnter = useCallback(() => {
    clearTimeout(timerRef.current);
    setHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setHovered(false);
    }, 300);
  }, []);

  const onClick = useCallback(
    (e) => {
      e.preventDefault();

      function listener(e) {
        e.clipboardData.setData(
          'text/html',
          valueToCopy || valueRef.current.innerText,
        );
        e.clipboardData.setData(
          'text/plain',
          valueToCopy || valueRef.current.innerText,
        );
        e.preventDefault();
      }

      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);
      setIsCopied(true);
    },
    [valueToCopy, valueRef],
  );
  return (
    <div
      onMouseEnter={!disabled ? onMouseEnter : undefined}
      onMouseLeave={!disabled ? onMouseLeave : undefined}
      onClick={copyOnClick && !disabled ? onClick : undefined}
      className={classNames(
        styles.host,
        inline && styles.inline,
        hovered && styles.hovered,
        disabled && styles.disabled,
      )}
    >
      <div className={styles.inline} ref={valueRef}>
        {children}
      </div>
      <div className={styles.popup} onClick={!disabled ? onClick : undefined}>
        {label}
        {isCopied && <Icon inline size={14} icon={check} />}
      </div>
    </div>
  );
};
