import { ActionBar } from './action-bar/action-bar.component';
import { Content } from './content/content.component';
import './frame.scss';
import { Menu } from './menu/menu.component';
import { SubMenu } from './sub-menu/sub-menu.component';
import { SubTitle, Title } from './title/title.component';
export const Frame = Object.assign(
  {},
  { Title, SubTitle, Menu, Content, SubMenu, ActionBar },
);
