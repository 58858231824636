import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FindProductsQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
}>;


export type FindProductsQuery = { __typename?: 'Query', adminProducts: { __typename?: 'PaginatedProductResult', data: Array<{ __typename?: 'LocationProduct', id: any, title: string, amount: number } | { __typename?: 'SimpleProduct', id: any, title: string, amount: number }> } };


export const FindProductsDocument = gql`
    query findProducts($query: String!) {
  adminProducts(pagination: {skip: 0, take: 999}, filter: {query: $query}) {
    data {
      ... on Product {
        id
        title
        amount
      }
    }
  }
}
    `;
export type FindProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FindProductsQuery, FindProductsQueryVariables>, 'query'> & ({ variables: FindProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FindProductsComponent = (props: FindProductsComponentProps) => (
      <ApolloReactComponents.Query<FindProductsQuery, FindProductsQueryVariables> query={FindProductsDocument} {...props} />
    );
    

/**
 * __useFindProductsQuery__
 *
 * To run a query within a React component, call `useFindProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFindProductsQuery(baseOptions: Apollo.QueryHookOptions<FindProductsQuery, FindProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindProductsQuery, FindProductsQueryVariables>(FindProductsDocument, options);
      }
export function useFindProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindProductsQuery, FindProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindProductsQuery, FindProductsQueryVariables>(FindProductsDocument, options);
        }
export type FindProductsQueryHookResult = ReturnType<typeof useFindProductsQuery>;
export type FindProductsLazyQueryHookResult = ReturnType<typeof useFindProductsLazyQuery>;
export type FindProductsQueryResult = Apollo.QueryResult<FindProductsQuery, FindProductsQueryVariables>;