import { EnhancedEventInterface } from '@/event/context/event.context';
import { addDays, addWeeks, set, startOfDay } from 'date-fns';

interface GenerateEventsInput {
  numWeeks: number;
  startTime: string;
  endTime: string;
  startDay: Date;
  weekDays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  isPreview: boolean;
  availableLocationIds?: string[];
  availableCourseIds?: string[];
  availableSubjects?: string[];
  consultantId?: string;
}

export function generateEvents<T = EnhancedEventInterface>(
  input: GenerateEventsInput,
) {
  const {
    numWeeks,
    startTime,
    endTime,
    startDay,
    weekDays,
    isPreview,
    availableCourseIds,
    availableLocationIds,
    availableSubjects,
    consultantId,
  } = input;
  const events: T[] = [];
  if (!numWeeks) {
    return events;
  }

  const [startHours, startMinutes] = startTime.split(':');
  const [endHours, endMinutes] = endTime.split(':');

  for (let i = 0; i < numWeeks; i++) {
    for (const weekDayOffset of weekDays) {
      const startDate = set(
        addWeeks(addDays(startOfDay(startDay), weekDayOffset), i),
        { hours: parseInt(startHours), minutes: parseInt(startMinutes) },
      );
      const endDate = set(startDate, {
        hours: parseInt(endHours),
        minutes: parseInt(endMinutes),
      });
      events.push({
        startDate,
        endDate,
        isPreview: isPreview || undefined,
        description: isPreview ? 'Beratung' : undefined,
        availableCourseIds,
        availableLocationIds,
        availableSubjects,
        consultantId,
      } as unknown as T);
    }
  }

  return events;
}
