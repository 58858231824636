import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { SubNav } from '../sub-nav.component';
import { Editor } from './editor/editor.component';
import { List } from './list/list.component';

type EnterpriseDetailParams = {
  id?: string;
};

interface Props {
  enterprise: { id: string; name: string };
}
export const TransactionMailTemplate: ComponentType<
  RouteComponentProps<EnterpriseDetailParams> & Props
> = (props) => {
  const { enterprise } = props;
  return (
    <>
      <SubNav current="transaction-mail-templates" />
      <Router className="router">
        <Editor path="create" />
        <Editor path=":templateId/edit" />
        <List path="list" enterprise={enterprise} />
        <Redirect from="/" to="list" />
      </Router>
    </>
  );
};
