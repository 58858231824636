import { Gender, Person, UserUnion } from '@/graphql/generated/types';
import { TranslatorFunction } from 'shared/translator/translator.component';
import { UserType } from './editor/base/base-editor.component';

export function getCreateMutationName(userType?: UserType) {
  if (userType === 'administrators') {
    return 'adminCreateAdministrator';
  }
  if (userType === 'customers') {
    return 'adminCreateCustomer';
  }
  if (userType === 'teachers') {
    return 'adminCreateTeacher';
  }
  return 'adminCreatePerson';
}

export function getPersonDescription(
  person: Pick<Person, 'firstName' | 'lastName' | 'gender' | 'title'>,
) {
  return `${person.title || ''} ${person.firstName} ${person.lastName}`
    .replace('null', '')
    .trim();
}

export function getGenderedUserLabel(
  person: Pick<Person, 'gender'>,
  userType: UserType | undefined,
  _: TranslatorFunction,
) {
  return _(
    `user-type.${userType}.singular.${
      person.gender === Gender.Female ? 'Female' : 'Male'
    }`,
  );
}

export function getPersonDetailLink(
  person: Pick<Person, 'id'> & { user?: Pick<UserUnion, '__typename'> | null },
  urlFragment?: string,
) {
  if (urlFragment) {
    return `/contacts/${urlFragment}/${person.id}/edit`;
  }
  if (person.user?.__typename === 'Administrator') {
    return `/contacts/administrators/${person.id}/edit`;
  }
  if (person.user?.__typename === 'Customer') {
    return `/contacts/customers/${person.id}/edit`;
  }
  if (person.user?.__typename === 'Teacher') {
    return `/contacts/teachers/${person.id}/edit`;
  }
  return `/contacts/persons/${person.id}/edit`;
}
