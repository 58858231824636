import { IfSuper } from '@/auth/role/if-role.component';
import { Profile } from '@/profile/profile';
import { LeadsWidget } from '@/statistics-v3/leads/widget/leads-widget.component';
import { Box } from '@/ui/box/box.component';
import { Grid } from '@/ui/grid/grid.component';
import { Loader } from '@/ui/loader/loader.component';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';

export const Dashboard: ComponentType<RouteComponentProps> = ({}) => {
  const profile = Profile.use();

  if (!profile) {
    return <Loader />;
  }

  return (
    <>
      <Frame.Title>Dashboard</Frame.Title>
      <Frame.Content>
        <Grid.Row>
          <Grid.Column>
            <Box
              fullHeight
              withMargin
              title={`Willkommen, ${profile.person?.firstName}!`}
            >
              <IfSuper>
                <p>Du bist Super Admin und hast alle Rechte.</p>
              </IfSuper>
              <p>Wende dich bei technischen Fragen oder Problemen bitte an</p>
              <p>
                Philip Mancarella
                <br />
                <a href="mailto:pma@voltadigitale.de">pma@voltadigitale.de</a>
                <br />
                <a href="tel:+492213360419">0221 33604 19</a>
              </p>
            </Box>
          </Grid.Column>
          <Grid.Column>
            <LeadsWidget />
            {/*  <Box title="Changelog - Entwicklung" collapsible>
              <ReactMarkdown className={styles.changelog}>
                {CHANGELOG}
              </ReactMarkdown>
            </Box> */}
          </Grid.Column>
        </Grid.Row>
      </Frame.Content>
    </>
  );
};
