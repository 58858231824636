import {
  StatisticsBoxContext,
  StatisticsBoxType,
} from '@/graphql/generated/types';
import { getGraphTypes } from '@/statistics-v3/shared/box/statistics-box-registry';
import {
  Graph,
  GraphOptions,
  GraphType,
} from '@/statistics-v3/shared/chart/graph/graph';
import { useCustomDialogue } from '@/ui/modal/modal.hooks';
import React, {
  ComponentType,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useChartOptions } from '../../../options/options.provider';
import styles from '../graph-item.module.scss';

export interface GraphItemOptionsProps {
  stateDispatch: [any, Dispatch<SetStateAction<any>>];
  graph: Graph<GraphType>;
}

function getChartOptionsComponent(
  type: StatisticsBoxType,
  context: StatisticsBoxContext,
  graphType: GraphType,
): [ComponentType<GraphItemOptionsProps> | null, string | null] {
  const graphTypes = getGraphTypes(type, context);

  const graph = graphTypes.find((t) => t.value === graphType);
  return [graph?.optionsComponent || null, graph?.optionsLabel || null];
}

export function useGraphOptionsModal<T extends GraphType>(
  graph: Graph<T>,
  onUpdate: (data: any) => void,
) {
  const stateDispatch = useState<GraphOptions<T>>(graph.options);
  const { type, context } = useChartOptions();

  const [Component, title] = useMemo(() => {
    return getChartOptionsComponent(type, context, graph.type);
  }, [graph]);

  const modal = useCustomDialogue(
    Component ? (
      <div className={styles.graphOptionsModal}>
        <Component graph={graph} stateDispatch={stateDispatch} />
      </div>
    ) : (
      <></>
    ),
    {
      title: title || '',
      actions: [
        {
          title: 'Abbrechen',
          handler: () => modal[1].close(),
        },
        {
          title: 'Anwenden',
          primary: true,
          handler: () => onUpdate(stateDispatch[0]),
        },
      ],
    },
  );

  return {
    modal: modal[0],
    open: modal[1].open,
  };
}
