import {
  add,
  differenceInMinutes,
  formatISO,
  parseISO,
  startOfDay,
} from 'date-fns';
import React, {
  ComponentType,
  createContext,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MINUTES_PER_DAY } from './room-event-manager/room-event-manager.component';
import {
  EventManagerRoomsQuery,
  EventsForTimeQuery,
  useEventManagerRoomsQuery,
  useEventsForTimeLazyQuery,
  useUpdateEventMutation,
} from './room-management.generated';

const RoomManagementContext = createContext<RoomManagementApi | null>(null);

interface UpdateEventData {
  startDate?: string;
  endDate?: string;
  roomId?: string;
}
interface RoomManagementApi {
  reload: () => void;
  rooms: EventManagerRoomsQuery['adminRooms']['data'];
  localEvents: EventsForTimeQuery['events'];
  updateLocalEvent: (
    event: EventsForTimeQuery['events'][number],
    data: UpdateEventData,
  ) => void;
  registerRoomDiv: (id: string, ref: RefObject<HTMLDivElement>) => void;
  roomDivs: Record<string, RefObject<HTMLDivElement>>;
  gridUnit: number;
  pixelsPerMinute: number;
  changeGridUnit: (direction: 'inc' | 'dec') => void;
  currentDate: Date;
  setCurrentDate: (date: Date) => void;
}

type GridUnitValues = 15 | 30 | 60;

export const RoomManagementProvider: ComponentType = ({ children }) => {
  const [localEvents, setLocalEvents] = useState<EventsForTimeQuery['events']>(
    [],
  );
  const [roomDivs, setRoomDivs] = useState<
    Record<string, RefObject<HTMLDivElement>>
  >({});

  const [gridUnit, setGridUnit] = useState<GridUnitValues>(30);
  const [pixelsPerMinute, setPixelsPerMinute] = useState<number>(1);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const roomsQuery = useEventManagerRoomsQuery();
  const [load, { data }] = useEventsForTimeLazyQuery();
  const [update] = useUpdateEventMutation();

  const updateLocalEvent = useCallback(
    (event: EventsForTimeQuery['events'][number], data: UpdateEventData) => {
      setLocalEvents((e) =>
        e.map((e) => (e.id === event.id ? { ...e, ...data } : e)),
      );
      update({
        variables: {
          id: event.id,
          input: {
            startDate: data.startDate,
            endDate: data.endDate,
            roomId: data.roomId,
          },
        },
      });
    },
    [],
  );

  /* const loadEvents = useCallback(async () => {
    const result = await load({
      variables: { input: { startDate: formatISO(currentDate) } },
    });
    setLocalEvents(result.data?.events || []);
  }, [currentDate]); */

  const registerRoomDiv = useCallback(
    (id: string, ref: RefObject<HTMLDivElement>) => {
      setRoomDivs((r) => ({ ...r, [id]: ref }));
    },
    [],
  );

  const changeGridUnit = useCallback(
    (direction: 'inc' | 'dec') => {
      if (direction === 'inc') {
        if (gridUnit === 30) {
          setGridUnit(60);
        }
        if (gridUnit === 15) {
          setGridUnit(30);
        }
      } else {
        if (gridUnit === 30) {
          setGridUnit(15);
        }
        if (gridUnit === 60) {
          setGridUnit(30);
        }
      }
    },
    [gridUnit],
  );

  const reload = useCallback(() => {
    load({
      variables: {
        input: {
          startDate: formatISO(currentDate),
          endDate: formatISO(currentDate),
        },
      },
    }).then((result) => {
      setLocalEvents(result.data?.events || []);
    });
  }, [currentDate]);

  useEffect(() => {
    reload();
  }, [reload]);

  const api: RoomManagementApi = {
    localEvents,
    rooms: roomsQuery.data?.adminRooms.data || [],
    updateLocalEvent,
    registerRoomDiv,
    reload,
    roomDivs,
    gridUnit,
    pixelsPerMinute,
    changeGridUnit,
    currentDate,
    setCurrentDate,
  };

  return (
    <RoomManagementContext.Provider value={api}>
      {children}
    </RoomManagementContext.Provider>
  );
};

export function useRoomManagement() {
  const context = useContext(RoomManagementContext);
  if (!context) {
    throw new Error('Context not ready');
  }

  return context;
}

export function useRoomManagementUi() {
  const { roomDivs, gridUnit, pixelsPerMinute, changeGridUnit } =
    useRoomManagement();

  const gridWidth = useMemo(() => {
    return gridUnit * pixelsPerMinute;
  }, [gridUnit]);

  const numSlots = useMemo(() => {
    return Math.floor(MINUTES_PER_DAY / gridUnit);
  }, [gridUnit]);

  const rowWidth = useMemo(() => {
    return numSlots * gridWidth;
  }, [gridWidth, numSlots]);

  const getX = useCallback((event: EventsForTimeQuery['events'][number]) => {
    const startDate = parseISO(event.startDate);
    return (
      differenceInMinutes(startDate, startOfDay(startDate)) * pixelsPerMinute
    );
  }, []);

  // returns minutes from pixel input
  const getMinutesFromPixels = useCallback((x: number) => {
    return Math.round(x / pixelsPerMinute);
  }, []);

  const translateEventDates = useCallback(
    (event: EventsForTimeQuery['events'][number], x: number) => {
      const duration = differenceInMinutes(
        parseISO(event.endDate),
        parseISO(event.startDate),
      );
      const startTime = getMinutesFromPixels(x);
      const startDate = add(startOfDay(parseISO(event.startDate)), {
        minutes: startTime,
      });

      const endDate = add(startDate, {
        minutes: duration,
      });

      return {
        startDate,
        endDate,
      };
    },
    [],
  );

  const getRoomIdByPosition = useCallback(
    (y: number) => {
      const pair = Object.entries(roomDivs).find(([_, ref]) => {
        if (!ref.current) {
          return false;
        }
        const clientRect = ref.current.getBoundingClientRect();
        if (clientRect.top <= y && clientRect.bottom >= y) {
          return true;
        }
        return false;
      });
      if (pair) {
        return pair[0];
      }
      return undefined;
    },
    [roomDivs],
  );

  const addRoomVerticalCoordinates = useCallback(
    (rooms: EventManagerRoomsQuery['adminRooms']['data']) => {
      const roomVerticalCoordinates = rooms.map((room) => {
        const top = document
          .getElementById(room.id)
          ?.getBoundingClientRect().top;
        const bottom = document
          .getElementById(room.id)
          ?.getBoundingClientRect().bottom;
        return { ...room, ...{ top, bottom } };
      });

      return roomVerticalCoordinates;
    },
    [],
  );

  return {
    rowWidth,
    gridWidth,
    numSlots,
    getX,
    getMinutesFromPixels,
    getRoomIdByPosition,
    addRoomVerticalCoordinates,
    gridUnit,
    changeGridUnit,
    pixelsPerMinute,
    translateEventDates,
  };
}
