import { Frame } from '@/frame';
import { TransactionMailAction } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Chip } from '@/ui/chip/chip.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { InfoBox } from '@/ui/info-box/info-box.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import axios from 'axios';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { API_URI } from 'shared/constants';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';
import { array, object, string } from 'yup';
import {
  useEditTransactionMailTemplateMutation,
  useTransactionMailTemplateQuery,
} from './editor.generated';

interface TemplateVarsProps {
  variables?: {
    name: string;
    description: string;
    hasHandler: boolean;
    templateString?: string | null;
  }[];
  helpTexts?: string[] | null;
}
function TemplateVarsAndHelp({ variables, helpTexts }: TemplateVarsProps) {
  if (!variables) {
    return null;
  }
  return (
    <>
      <label>Verfügbare Variablen und Macros</label>
      <div>
        {variables?.map((variable) => (
          <Copyable
            key={variable.name}
            copyOnClick
            inline
            valueToCopy={variable.templateString || `{{${variable.name}}}`}
            label={variable.description}
          >
            <Chip
              transparent
              className={variable.hasHandler ? 'bg-blue' : 'bg-green'}
            >
              {variable.name}
            </Chip>
          </Copyable>
        ))}
      </div>
      {helpTexts && (
        <InfoBox header="Hilfe">
          {helpTexts.map((t, i) => (
            <p key={i}>{t}</p>
          ))}
        </InfoBox>
      )}
    </>
  );
}

type Values = {
  name: string;
  subject: string;
  bodyText: string;
  bodyHtml: string | null;
  actions: TransactionMailAction[];
};

const schema = object({
  name: string().required('Der Name is ein Pflichtfeld.').min(1),
  subject: string().required('Der Betreff is ein Pflichtfeld.').min(1),
  bodyText: string().required('Der Text-Teil is ein Pflichtfeld.').min(1),
  bodyHtml: string().nullable(),
  actions: array(string()).required(),
});

type TransactionMailTemplateEditorParams = {
  id?: string;
  templateId?: string;
};

interface Props {
  enterprise?: { id: string; name: string };
}

export const Editor: ComponentType<
  RouteComponentProps<TransactionMailTemplateEditorParams> & Props
> = (props) => {
  const templateQuery = useTransactionMailTemplateQuery({
    variables: { id: props.templateId },
    skip: props.templateId === undefined,
  });

  const _ = Translator.useTranslator();

  const [save] = useEditTransactionMailTemplateMutation();

  const form = useForm<any>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const { reload } = useResources();

  const onSubmit = useMemo(() => {
    return form.handleSubmit(
      async (input) => {
        try {
          const saved = await save({
            variables: {
              id: props.templateId,
              input: { ...input, enterpriseId: props.id },
            },
          });
          reload();
          success(
            props.templateId
              ? 'Die Vorlage wurde gespeichert.'
              : 'Die Vorlage wurde erstellt.',
          );
          if (!props.templateId) {
            navigate(
              `./${saved.data?.adminEditTransactionMailTemplate.id}/edit`,
            );
          }
        } catch (e) {
          error('Beim Speichern der Vorlage ist ein Fehler aufgetreten.');
        }
      },
      (e) => {
        error('Ein Fehler ist aufgetreten.');
        console.error(e);
      },
    );
  }, [props.id, form.handleSubmit]);

  const onTestMailClick = useCallback(() => {
    if (templateQuery.data) {
      axios
        .post(
          `${API_URI}/transaction-mail-templates/${templateQuery.data.adminTransactionMailTemplate.id}/send-test`,
          null,
          {
            withCredentials: true,
          },
        )
        .then(() =>
          success(
            'Eine Test-Mail mit diesem Template wurde an deine E-Mail Adresse versandt.',
          ),
        )
        .catch(() =>
          error('Beim Versenden der Test-Mail ist ein Fehler aufgetreten.'),
        );
    }
  }, [templateQuery.data]);

  const onTestPreviewClick = useCallback(() => {
    if (templateQuery.data) {
      window.open(
        `${API_URI}/transaction-mail-templates/${templateQuery.data.adminTransactionMailTemplate.id}/preview`,
      );
    }
  }, [templateQuery.data]);

  const persisted =
    props.templateId !== undefined || templateQuery.data !== undefined;

  return (
    <>
      <Frame.Title>TX-Mail-Vorlagen</Frame.Title>

      <Frame.SubTitle>
        {persisted
          ? templateQuery.data?.adminTransactionMailTemplate.name
          : 'Neue Vorlage'}
      </Frame.SubTitle>
      <Frame.ActionBar>
        {templateQuery.data && (
          <>
            <Button label="Test versenden" warning onClick={onTestMailClick} />
            <Button
              label="Test Vorschau im Browser"
              warning
              onClick={onTestPreviewClick}
            />
          </>
        )}
        <Button
          label="Zurück"
          linkTo={`/enterprises/${props.id}/transaction-mail-templates/`}
        />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form
          form={form}
          values={templateQuery.data?.adminTransactionMailTemplate}
        >
          <Grid.Row>
            <Grid.Column>
              <Form.Input name="name" label="Name der Vorlage (intern)" />
              <Form.Input name="subject" label="Betreff" />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                label="Aktionen"
                multiple
                name="actions"
                choices={Object.keys(TransactionMailAction).map((t) => ({
                  label: _(`transaction-mail-action.${t}`),
                  value: t,
                }))}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Textarea name="bodyHtml" label="HTML-Teil" />
            </Grid.Column>
            <Grid.Column>
              <TemplateVarsAndHelp
                helpTexts={
                  templateQuery.data?.adminTransactionMailTemplate.helpTexts
                }
                variables={
                  templateQuery.data?.adminTransactionMailTemplate
                    .availableVariables
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Textarea name="bodyText" label="Text-Teil" />
            </Grid.Column>
            <Grid.Column>
              <TemplateVarsAndHelp
                helpTexts={
                  templateQuery.data?.adminTransactionMailTemplate.helpTexts
                }
                variables={
                  templateQuery.data?.adminTransactionMailTemplate
                    .availableVariables
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
