import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

interface ClipboardApi {
  copy: (event: { id: string }) => void;
  paste: () => string | undefined;
  state: string | undefined;
}
const ClipboardContext = createContext<ClipboardApi | null>(null);

export const ClipboardProvider: ComponentType = ({ children }) => {
  const [state, setState] = useState<string>();

  const copy = useCallback((event) => {
    setState(event.id);
  }, []);

  const paste = useCallback(() => {
    return state;
  }, [state]);

  return (
    <ClipboardContext.Provider value={{ copy, paste, state }}>
      {children}
    </ClipboardContext.Provider>
  );
};

export function useClipboard() {
  const context = useContext(ClipboardContext);
  if (!context) {
    throw new Error('Clipboard Context not ready');
  }
  return context;
}
