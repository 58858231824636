import { Frame } from '@/frame';
import {
  CourseCategory,
  CreateCourseCategoryInput,
  UpdateCourseCategoryInput,
} from '@/graphql/generated/types';
import { Profile, isSuperAdmin } from '@/profile/profile';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { error, success, warning } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { PropertySchemaRenderer } from 'shared/property-schema/property-schema.component';
import { useResources } from 'shared/resources/resources.provider';
import { object, string } from 'yup';
import {
  useCourseCategoryQuery,
  useCreateCourseCategoryMutation,
  useDeleteCourseCategoryMutation,
  useUpdateCourseCategoryMutation,
} from './editor.generated';

const schema = object({
  title: string().required().min(1),
  parentId: string().nullable(),
});
interface EditProps {
  mode: 'create' | 'update';
}

export const Editor: ComponentType<
  RouteComponentProps<{ id: string }> & EditProps
> = (props) => {
  const { id, mode } = props;

  const profile = Profile.use();
  const { availableEnterprises, availableCourseCategories, reload } =
    useResources();

  const courseCategoryQuery = useCourseCategoryQuery({
    variables: { id },
    skip: mode === 'create',
    fetchPolicy: 'network-only',
  });

  const [update] = useUpdateCourseCategoryMutation();
  const [create] = useCreateCourseCategoryMutation();
  const [remove] = useDeleteCourseCategoryMutation();

  const form = useForm<
    UpdateCourseCategoryInput & CreateCourseCategoryInput,
    CourseCategory
  >({
    shouldUnregister: true,
    resolver: yupResolver(schema),
    defaultValues: {
      enterpriseId: isSuperAdmin(profile)
        ? undefined
        : profile.selectedEnterprise?.id,
    },
  });

  const [confirmModal, confirmDelete] = useConfirm(async (id: string) => {
    try {
      await remove({ variables: { id } });
      await reload();
      navigate('../list');
      success('Kurs-Kategorie gelöscht.');
    } catch (e) {
      error(
        'Fehler',
        'Die Kategorie kann nicht gelöscht werden, da sie noch Subkategorien oder Kurse enthält.',
      );
    }
  });

  const selectedEnterpriseId = form.watch('enterpriseId');
  const selectedParentId = form.watch('parentId');

  const onSubmit = useMemo(() => {
    return form.handleSubmit(async (input) => {
      try {
        if (id) {
          await update({
            variables: {
              id,
              input,
            },
          });
          await reload();
          success('Kurs-Kategorie gespeichert.');
        } else {
          const created = await create({
            variables: {
              input: {
                ...input,
                parentId:
                  input.parentId === '0' ? null : input.parentId || undefined,
              },
            },
          });
          await reload();
          navigate(`./${created.data?.adminCreateCourseCategory.id}/edit`);
          success('Kurs-Kategorie erstellt.');
        }
      } catch (e: any) {
        error('Fehler', e.message);
      }
    });
  }, [id, form.handleSubmit]);

  const onDelete = useCallback(async () => {
    if (id) {
      confirmDelete('Soll diese Kurs-Kategorie wirklich gelöscht werden?', id);
    }
  }, [id]);

  useEffect(() => {
    if (availableEnterprises.length) {
      //form.setValue('enterpriseId', availableEnterprises[0].id);
    }
  }, [availableEnterprises]);

  useEffect(() => {
    if (selectedParentId) {
      const parentCategory = availableCourseCategories.find(
        (category) => category.id === selectedParentId,
      );
      const enterprise = availableEnterprises.find(
        (enterprise) => enterprise.id === selectedEnterpriseId,
      );
      if (parentCategory && enterprise) {
        if (parentCategory.enterprise.id !== enterprise.id) {
          form.setValue('parentId', null);

          warning(
            'Konflikt',
            'Die Mutter-Kategorie muss dem selben Unternehmen zugeordnet sein!',
          );
        }
      }
    }
  }, [selectedEnterpriseId, selectedParentId]);

  return (
    <>
      {confirmModal}
      <Frame.SubTitle>
        Kategorie: {courseCategoryQuery.data?.adminCourseCategory.title}
      </Frame.SubTitle>

      <Frame.ActionBar>
        {id && (
          <Button
            warning
            transparent
            label="Kategorie löschen"
            onClick={onDelete}
          />
        )}
        <Button label="Zurück" linkTo="/course-management/categories/list" />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form
          form={form}
          values={courseCategoryQuery.data?.adminCourseCategory}
        >
          <Section>
            <Form.Input name="title" label="Name" />
            <Form.Input
              label="Slug"
              name="slug"
              placeholder="Freilassen um automatisch generieren zu lassen"
            />

            <Form.Select
              name="parentId"
              nullable
              label="Mutter"
              choices={
                availableCourseCategories.map((category) => ({
                  value: category.id,
                  label: `${category.title} (${category.enterprise.name})`,
                })) || []
              }
            />
            {!id && (
              <Form.Select
                disabled={!isSuperAdmin(profile)}
                name="enterpriseId"
                label="Unternehmen"
                defaultValue={
                  availableEnterprises.length > 0
                    ? availableEnterprises[0].id
                    : undefined
                }
                choices={
                  availableEnterprises.map((enterprise) => ({
                    value: enterprise?.id,
                    label: enterprise?.name,
                  })) || []
                }
              />
            )}
            {id && (
              <p>
                Diese Kategorie ist dem Unternehmen{' '}
                <em>
                  {
                    courseCategoryQuery.data?.adminCourseCategory.enterprise
                      ?.name
                  }
                </em>{' '}
                zugewiesen.
              </p>
            )}
          </Section>
          {courseCategoryQuery.data && (
            <PropertySchemaRenderer
              enterpriseId={
                courseCategoryQuery.data?.adminCourseCategory.enterpriseId
              }
              context="course-category"
            />
          )}
        </Form>
      </Frame.Content>
    </>
  );
};
