import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

interface TabsApi {
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
  highlightedIndizes: number[];
  setHighlightedIndizes: Dispatch<SetStateAction<number[]>>;
  highlightTabsWithFields: (fields: string[]) => void;
  fieldMapRef: MutableRefObject<string[][]>;
}
const TabsContext = createContext<TabsApi | null>(null);

export function TabsProvider({ children }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [highlightedIndizes, setHighlightedIndizes] = useState<number[]>([]);
  const fieldMapRef = useRef<string[][]>([]);

  const highlightTabsWithFields = useCallback(
    (fieldsToBeHighlighted: string[]) => {
      const tabsToHighlight: number[] = [];
      for (let i = 0; i < fieldMapRef.current.length; i++) {
        for (const fieldToBeHighlighted of fieldsToBeHighlighted) {
          if (
            fieldMapRef.current[i].find((f) =>
              fieldToBeHighlighted.startsWith(f),
            )
          ) {
            if (!tabsToHighlight.includes(i)) {
              tabsToHighlight.push(i);
            }
          }
        }
      }
      setHighlightedIndizes(tabsToHighlight);
    },
    [fieldMapRef.current],
  );

  const api = useMemo(
    () => ({
      tabIndex,
      setTabIndex,
      highlightedIndizes,
      setHighlightedIndizes,
      highlightTabsWithFields,
      fieldMapRef,
    }),
    [tabIndex, highlightedIndizes, highlightTabsWithFields],
  );
  return <TabsContext.Provider value={api}>{children}</TabsContext.Provider>;
}

export function useTabs(fields?: string[][]) {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('Tabs Context not ready');
  }
  if (fields) {
    context.fieldMapRef.current = fields;
  }
  return context;
}
