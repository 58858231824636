import classNames from 'classnames';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import React, { ComponentType, useCallback, useMemo, useState } from 'react';
import { useResources } from 'shared/resources/resources.provider';
import {
  useRoomManagement,
  useRoomManagementUi,
} from '../room-management-context';
import {
  EventManagerRoomsQuery,
  RoomEventDataFragment,
  useUpdateEventMutation,
} from '../room-management.generated';
import { DatePicker } from './date-picker/date-picker.component';
import { Events } from './events/events.component';
import { MiniEditor } from './mini-editor/mini-editor.component';
import styles from './room-event-manager.module.scss';
import { Ruler } from './ruler/ruler.component';

export const MINUTES_PER_DAY = 24 * 60;

export const RoomEventManager: ComponentType = (props) => {
  const [currentEvent, setCurrentEvent] = useState<
    RoomEventDataFragment | undefined | true
  >();
  const { rowWidth, changeGridUnit, addRoomVerticalCoordinates } =
    useRoomManagementUi();
  const { rooms, currentDate } = useRoomManagement();
  const { availableLocations } = useResources();

  const roomsWithVerticalCoordinates = addRoomVerticalCoordinates(rooms);
  const [update] = useUpdateEventMutation();

  const onUpdateEvent = useCallback((event: RoomEventDataFragment) => {
    update({
      variables: {
        id: event.id,
        input: {
          startDate: event.startDate,
          endDate: event.endDate,
          roomId: event.roomId,
        },
      },
    });
  }, []);

  const roomsByLocation = useMemo(() => {
    const locationsWithRooms: {
      location: EventManagerRoomsQuery['adminRooms']['data'][number]['location'];
      rooms: EventManagerRoomsQuery['adminRooms']['data'];
    }[] = [];

    for (const room of roomsWithVerticalCoordinates) {
      let locationWithRoom = locationsWithRooms.find(
        (l) => l.location === room.locationId,
      );
      if (!locationWithRoom) {
        locationWithRoom = { location: room.location, rooms: [] };
        locationsWithRooms.push(locationWithRoom);
      }
      locationWithRoom.rooms.push(room);
    }

    return locationsWithRooms;
  }, [rooms, availableLocations]);

  return (
    <div className={classNames(styles.host, 'room-management-working-area')}>
      <MiniEditor
        event={currentEvent}
        onClose={() => setCurrentEvent(undefined)}
      />
      <div className={styles.topBar}>
        <DatePicker />
        <h2>{format(currentDate, 'EEEE, dd.MM.yyyy', { locale: de })}</h2>
      </div>
      <div
        className={classNames(
          styles.actionArea,
          'room-event-manager-action-area',
        )}
      >
        <div className={styles.legendRow} style={{ width: `${rowWidth}px` }}>
          <div className={styles.rulerWrap}>
            <Ruler withLegend />
          </div>
        </div>

        {roomsByLocation.map((roomsWithLocation) => (
          <div key={roomsWithLocation.location.id} className={styles.location}>
            {roomsWithLocation.location.name}
            {roomsWithLocation.rooms.map((room) => (
              <div key={room.id} className={styles.row}>
                <div className={styles.label}>{room.name}</div>
                <Events
                  room={room}
                  id={room.id}
                  onDoubleClick={(e) => setCurrentEvent(e)}
                />
              </div>
            ))}
          </div>
        ))}
        {/* 
      <button onClick={() => changeGridUnit('inc')}>Plus</button>
      <button onClick={() => changeGridUnit('dec')}>Minus</button>
       */}
      </div>
    </div>
  );
};
