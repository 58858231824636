import { Box } from '@/ui/box/box.component';
import { Grid } from '@/ui/grid/grid.component';
import { SelectField2 } from '@/ui/select-field-2/select-field-2.component';
import { omit } from 'lodash-es';
import React, { ComponentType } from 'react';
import { useUrlQuery } from 'shared/hooks/url-query';
import { useResources } from 'shared/resources/resources.provider';

export type CourseUnitFilterType = {
  locationIds: string[];
  seasonIds: string[];
  includeInactive?: string;
  isOnline?: string;
  isCorrespondenceCourse?: string;
  sortByStartDate?: string;
  omitPastUnits?: string;
};
export const CourseUnitListFilter: ComponentType = (props) => {
  const [query, setQuery] = useUrlQuery<CourseUnitFilterType>();
  const { availableLocations, availableSeasons } = useResources();

  const { locationIds, seasonIds } = query;

  return (
    <Box withMargin>
      <Grid.Row>
        <Grid.Column>
          <SelectField2
            multiple
            value={locationIds}
            onChange={(e) =>
              setQuery({
                ...query,
                locationIds: e as string[],
              })
            }
            choices={availableLocations.map((l) => ({
              label: l.name,
              value: l.id,
            }))}
            label="Standorte"
          />
        </Grid.Column>
        <Grid.Column>
          <SelectField2
            multiple
            value={seasonIds}
            onChange={(e) =>
              setQuery({
                ...query,
                seasonIds: e as string[],
              })
            }
            choices={availableSeasons.map((l) => ({
              label: l.title,
              value: l.id,
            }))}
            label="Saisons"
          />
        </Grid.Column>
        <Grid.Column>
          <SelectField2
            label="Inaktive Einheiten"
            choices={[
              { label: 'Zeigen', value: 'true' },
              { label: 'Verbergen', value: 'false' },
            ]}
            value={
              query.includeInactive === 'false' ||
              query.includeInactive === undefined
                ? 'false'
                : 'true'
            }
            onChange={(e) => {
              setQuery({
                ...query,
                includeInactive: e === 'true' ? 'true' : 'false',
              });
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <SelectField2
            label="Vergangene Einheiten"
            choices={[
              { label: 'Zeigen', value: 'false' },
              { label: 'Verbergen', value: 'true' },
            ]}
            value={
              query.omitPastUnits === 'false' ||
              query.omitPastUnits === undefined
                ? 'false'
                : 'true'
            }
            onChange={(e) => {
              setQuery({
                ...query,
                omitPastUnits: e === 'true' ? 'true' : 'false',
              });
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <SelectField2
            label="Online / Fernkurs"
            multiple
            choices={[
              { label: 'Online', value: 'isOnline' },
              { label: 'Fernkurs', value: 'isCorrespondenceCourse' },
            ]}
            value={
              query.isOnline && query.isCorrespondenceCourse
                ? ['isOnline', 'isCorrespondenceCourse']
                : query.isOnline
                ? ['isOnline']
                : query.isCorrespondenceCourse
                ? ['isCorrespondenceCourse']
                : []
            }
            onChange={(e) => {
              const values = e as string[];
              if (values.length === 1) {
                if (values.includes('isOnline')) {
                  setQuery({ ...query, isOnline: 'true' });
                } else {
                  setQuery({ ...query, isCorrespondenceCourse: 'true' });
                }
              } else if (values.length == 2) {
                setQuery({
                  ...query,
                  isCorrespondenceCourse: 'true',
                  isOnline: 'true',
                });
              } else {
                setQuery({
                  ...omit(query, 'isOnline', 'isCorrespondenceCourse'),
                });
              }
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <SelectField2
            choices={[
              { label: 'Start, aufsteigend', value: 'ASC' },
              { label: 'Start, absteigend', value: 'DESC' },
            ]}
            label="Sortierung"
            value={query.sortByStartDate === 'DESC' ? 'DESC' : 'ASC'}
            onChange={(e) =>
              setQuery({ ...query, sortByStartDate: e as string })
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Box>
  );
};
