import developerIcon from 'assets/image/icons/developer.svg';
import errorIcon from 'assets/image/icons/error.svg';
import infoIcon from 'assets/image/icons/info.svg';
import classNames from 'classnames';
import React, { ComponentType, MouseEvent, ReactNode } from 'react';
import { Icon } from '../icon/icon.component';
import styles from './info-box.module.scss';

export type InfoBoxProps = {
  header?: string;
  content?: ReactNode;
  icon?: string;
  className?: string;
  error?: boolean;
  warning?: boolean;
  success?: boolean;
  onClick?: (e: MouseEvent) => void;
};

const Basic: ComponentType<InfoBoxProps> = (props) => {
  const {
    header = '',
    content = '',
    icon,
    className,
    error,
    warning,

    success,
    onClick,

    children,
  } = props;

  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.host,
        className,
        error && styles.error,
        warning && styles.warning,
        success && styles.success,
      )}
    >
      {icon && !error && <Icon icon={icon} />}
      {!icon && !error && <Icon icon={infoIcon} />}
      {error && <Icon icon={errorIcon} />}
      <div className={styles.content}>
        <div className={styles.header}>{header}</div>
        {content && <p>{content}</p>}
        {children}
      </div>
    </div>
  );
};

const Todo: ComponentType<InfoBoxProps> = (props) => {
  const {
    header = 'Not implemented',
    content = 'Work in progress',
    icon = developerIcon,
    children,
  } = props;

  return (
    <Basic
      header={header}
      content={content}
      icon={icon}
      className={styles.todo}
    >
      {children}
    </Basic>
  );
};

const Empty: ComponentType<InfoBoxProps> = (props) => {
  const {
    header = 'Keine Ergebnisse',
    content = 'Es wurden keine Einträge gefunden.',
  } = props;

  return <Basic header={header} content={content} />;
};

export const InfoBox = Object.assign(Basic, { Empty, Todo });
