import {
  StatisticsBoxContext,
  StatisticsBoxType,
} from '@/graphql/generated/types';
import { useGraphActions } from '@/statistics-v3/shared/chart/graph/actions.hooks';
import { Graph } from '@/statistics-v3/shared/chart/graph/graph';
import { GraphItem } from '@/statistics-v3/shared/chart/graph/graph-item/graph-item.component';
import { useChartOptions } from '@/statistics-v3/shared/chart/options/options.provider';
import { Button } from '@/ui/button/button.component';
import { SelectField } from '@/ui/select-field/select-field.component';
import React, { ComponentType, useCallback } from 'react';
import styles from '../../box/statistics-box.module.scss';

export interface CommonChartOptionsProps {
  context: StatisticsBoxContext;
  type: StatisticsBoxType;
}

export const CommonChartOptions: ComponentType<CommonChartOptionsProps> = (
  props,
) => {
  const { context, type } = props;
  const { options, setOptions } = useChartOptions();
  const { chartType, graphs } = options;

  const onUpdateGraphs = useCallback(
    (graphs: Graph<any>[]) => {
      setOptions({ ...options, graphs });
    },
    [options],
  );

  const {
    askForDelete,
    modalDelete,
    toggleGraph,
    modalAdd,
    askAdd,
    updateGraphOptions,
  } = useGraphActions(context, type, graphs, onUpdateGraphs);

  return (
    <>
      {modalDelete}
      {modalAdd}
      <div className={styles.section}>
        <h4>Diagramm-Typ</h4>
        <SelectField
          small
          value={chartType}
          choices={[
            {
              value: 'line',
              label: 'Linien',
            },
            { value: 'bar', label: 'Balken' },
          ]}
          onChange={(value) =>
            setOptions({ ...options, chartType: value as 'line' | 'bar' })
          }
        />
      </div>
      <div className={styles.section}>
        <h4>Graphen</h4>
        {graphs?.map((graph, index) => (
          <GraphItem
            key={`${graph.name}-${index}`}
            graph={graph}
            onToggle={(v) => toggleGraph(index, v)}
            onRemove={() => askForDelete(index)}
            onUpdateOptions={(data) => updateGraphOptions(index, data)}
          />
        ))}
        <Button round label="+" primary small onClick={askAdd.open} />
      </div>
    </>
  );
};
