import { ContactProperty, ContactPropertyKey } from '@/graphql/generated/types';
import React, { ComponentType } from 'react';

interface PersonEmailProps {
  person: { contactProperties: Pick<ContactProperty, 'key' | 'value'>[] };
}

export const PersonEmail: ComponentType<PersonEmailProps> = (props) => {
  const { person } = props;
  const emailAddresses = person.contactProperties.filter(
    (c) => c.key === ContactPropertyKey.Email,
  );
  return (
    <>
      {emailAddresses.map((prop, index) => (
        <div key={index}>
          {prop.key === ContactPropertyKey.Email && prop.value}
          {index < emailAddresses.length - 1 && <>, </>}
        </div>
      ))}
    </>
  );
};
