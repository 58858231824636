import { object, string } from 'yup';

export const addressSchema = object({
  contactPerson: string().nullable(),
  company: string().nullable(),
  streetAndNumber: string().nullable(),
  postalCode: string().nullable(),
  city: string().nullable(),
  country: string().nullable(),
  //
});
