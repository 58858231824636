import { getPersonDetailLink } from '@/contact/person/utils';
import { Button } from '@/ui/button/button.component';
import { DropdownMenu } from '@/ui/dropdown-menu/dropdown-menu.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getCourseUnitLocationLabel } from 'shared/course/utils';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';

import { Frame } from '../../../frame';
import { ListView } from '../../../ui/list-view/list-view.component';
import { Row } from '../../../ui/table/row.component';
import { useBookingActions } from '../shared/actions.hooks';
import { BookingCourseUnitOrModule } from '../shared/booking-course-unit-or-module.component';
import { BookingStatusView } from '../shared/booking-status-view.component';
import { BookingCourseView } from '../shared/course-view.component';
import { useCreateBookingModal } from '../shared/create-booking/create-booking-modal.component';
import { BookingsQuery, useBookingsQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery();

  const { data, loading, refetch } = useBookingsQuery({
    variables: { pagination: getPaginationFromQuery(query) },
    fetchPolicy: 'network-only',
  });

  const _ = Translator.useTranslator();

  const { modalDelete, askForDelete } = useBookingActions(refetch, refetch);
  const { modal: createBookingModal, open: openBookingModal } =
    useCreateBookingModal(refetch);

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button onClick={openBookingModal} primary label="Neue Buchung" />
      </Frame.ActionBar>
      <Frame.Content>
        {createBookingModal}
        {modalDelete}
        <ListView<BookingsQuery['adminBookings']['data'][number]>
          loading={loading}
          data={data?.adminBookings?.data || []}
          totalCount={data?.adminBookings?.totalCount}
          columns={[
            {
              name: 'Gebucht am',
              sortable: true,
            },
            {
              name: 'Status',
              sortable: true,
            },
            {
              name: 'Kurs',
              sortable: false,
            },
            {
              name: 'Einheit / Modul',
              sortable: true,
            },

            {
              name: 'Standort',
              sortable: true,
            },
            {
              name: 'Kunde/Kundin',
              sortable: true,
            },

            { name: 'Aktionen', sortable: true },
          ]}
          /* renderFilter={({ value, onChange }) => (
            <DebouncedInput
              debounceRate={400}
              value={value?.query || ''}
              onChange={(value) => onChange('query', value)}
              placeholder={'Suche…'}
            />
          )} */
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/view`}>
                  <Formatted.Date withTime value={item.createdAt} />
                </Link>
              </td>
              <td>
                <BookingStatusView booking={item} />
              </td>
              <td>
                <BookingCourseView booking={item} />
              </td>
              <td>
                <BookingCourseUnitOrModule booking={item} />
              </td>
              <td>
                {getCourseUnitLocationLabel(
                  item.__typename === 'CourseModuleBooking'
                    ? item.courseModule
                    : (item as any).courseUnit,
                )}
              </td>
              <td>
                <Link
                  to={getPersonDetailLink(item.customer.person, 'customers')}
                >
                  {[
                    item.customer.person.title,
                    item.customer.person.firstName,
                    item.customer.person.lastName,
                  ].join(' ')}
                </Link>
              </td>

              <td>
                <DropdownMenu
                  actions={[
                    item.orderItem
                      ? {
                          label: 'Zur Bestellung',
                          href: `/orders/${item.orderItem.order.id}/view`,
                        }
                      : undefined,
                    {
                      label: 'Löschen',
                      meaning: 'error',
                      onClick: () => askForDelete(item),
                    },
                  ]}
                />
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
