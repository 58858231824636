import removeIcon from '@/assets/image/icons/bin.svg';
import addIcon from '@/assets/image/icons/plus.svg';
import { ContactPropertyKey } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { InputField } from '@/ui/input-field/input-field.component';
import { SelectField } from '@/ui/select-field/select-field.component';
import React, { ComponentType } from 'react';
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { Translator } from 'shared/translator/translator.component';

interface ContactPropertiesEditorProps {
  form: UseFormReturn<any>;
  contactPropertiesFieldArray: UseFieldArrayReturn<any, any>;
  prefix: string;
}

export const ContactPropertiesEditor: ComponentType<
  ContactPropertiesEditorProps
> = (props) => {
  const _ = Translator.useTranslator();
  const { contactPropertiesFieldArray, form, prefix } = props;
  return (
    <>
      {contactPropertiesFieldArray.fields.map((field, index) => (
        <Form.Pack
          alignBottom
          key={field.id} // important to include key with field's id
        >
          <Controller
            render={({ field }) => (
              <SelectField
                label="Art"
                choices={Object.keys(ContactPropertyKey).map((k) => ({
                  label: _(`contact-property.${k}`),
                  value: k,
                }))}
                {...field}
              />
            )}
            name={`${prefix}.${index}.key`}
            control={form.control}
          />
          <Controller
            render={({ field }) => <InputField {...field} label="Wert" />}
            name={`${prefix}.${index}.value`}
            control={form.control}
          />
          <Controller
            render={({ field }) => <input type="hidden" {...field} />}
            name={`${prefix}.${index}.id`}
            control={form.control}
          />
          <Button
            small
            round
            icon={removeIcon}
            onClick={() => contactPropertiesFieldArray.remove(index)}
          />
        </Form.Pack>
      ))}
      <Button.Row>
        <Button
          small
          round
          primary
          icon={addIcon}
          onClick={() => {
            contactPropertiesFieldArray.append({
              key: ContactPropertyKey.Email,
              value: '',
            });
          }}
        />
      </Button.Row>
    </>
  );
};
