import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type TransactionMailTemplateDataFragment = {
  __typename?: 'TransactionMailTemplate';
  id: any;
  name: string;
  actions: Array<string>;
  subject: string;
  bodyText: string;
  bodyHtml?: string | null;
  helpTexts?: Array<string> | null;
  availableVariables: Array<{
    __typename?: 'TemplateVariableDefinition';
    name: string;
    description: string;
    templateString?: string | null;
    hasHandler: boolean;
  }>;
};

export type TransactionMailTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type TransactionMailTemplateQuery = {
  __typename?: 'Query';
  adminTransactionMailTemplate: {
    __typename?: 'TransactionMailTemplate';
    id: any;
    name: string;
    actions: Array<string>;
    subject: string;
    bodyText: string;
    bodyHtml?: string | null;
    helpTexts?: Array<string> | null;
    availableVariables: Array<{
      __typename?: 'TemplateVariableDefinition';
      name: string;
      description: string;
      templateString?: string | null;
      hasHandler: boolean;
    }>;
  };
};

export type EditTransactionMailTemplateMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['UUID']>;
  input: Types.TransactionMailTemplateInput;
}>;

export type EditTransactionMailTemplateMutation = {
  __typename?: 'Mutation';
  adminEditTransactionMailTemplate: {
    __typename?: 'TransactionMailTemplate';
    id: any;
    name: string;
    actions: Array<string>;
    subject: string;
    bodyText: string;
    bodyHtml?: string | null;
    helpTexts?: Array<string> | null;
    availableVariables: Array<{
      __typename?: 'TemplateVariableDefinition';
      name: string;
      description: string;
      templateString?: string | null;
      hasHandler: boolean;
    }>;
  };
};

export const TransactionMailTemplateDataFragmentDoc = gql`
  fragment TransactionMailTemplateData on TransactionMailTemplate {
    id
    name
    actions
    subject
    bodyText
    bodyHtml
    availableVariables {
      name
      description
      templateString
      hasHandler
    }
    helpTexts
  }
`;
export const TransactionMailTemplateDocument = gql`
  query transactionMailTemplate($id: UUID!) {
    adminTransactionMailTemplate(id: $id) {
      ...TransactionMailTemplateData
    }
  }
  ${TransactionMailTemplateDataFragmentDoc}
`;
export type TransactionMailTemplateComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TransactionMailTemplateQuery,
    TransactionMailTemplateQueryVariables
  >,
  'query'
> &
  (
    | { variables: TransactionMailTemplateQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const TransactionMailTemplateComponent = (
  props: TransactionMailTemplateComponentProps,
) => (
  <ApolloReactComponents.Query<
    TransactionMailTemplateQuery,
    TransactionMailTemplateQueryVariables
  >
    query={TransactionMailTemplateDocument}
    {...props}
  />
);

/**
 * __useTransactionMailTemplateQuery__
 *
 * To run a query within a React component, call `useTransactionMailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionMailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionMailTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransactionMailTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionMailTemplateQuery,
    TransactionMailTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransactionMailTemplateQuery,
    TransactionMailTemplateQueryVariables
  >(TransactionMailTemplateDocument, options);
}
export function useTransactionMailTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionMailTemplateQuery,
    TransactionMailTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransactionMailTemplateQuery,
    TransactionMailTemplateQueryVariables
  >(TransactionMailTemplateDocument, options);
}
export type TransactionMailTemplateQueryHookResult = ReturnType<
  typeof useTransactionMailTemplateQuery
>;
export type TransactionMailTemplateLazyQueryHookResult = ReturnType<
  typeof useTransactionMailTemplateLazyQuery
>;
export type TransactionMailTemplateQueryResult = Apollo.QueryResult<
  TransactionMailTemplateQuery,
  TransactionMailTemplateQueryVariables
>;
export const EditTransactionMailTemplateDocument = gql`
  mutation editTransactionMailTemplate(
    $id: UUID
    $input: TransactionMailTemplateInput!
  ) {
    adminEditTransactionMailTemplate(id: $id, input: $input) {
      ...TransactionMailTemplateData
    }
  }
  ${TransactionMailTemplateDataFragmentDoc}
`;
export type EditTransactionMailTemplateMutationFn = Apollo.MutationFunction<
  EditTransactionMailTemplateMutation,
  EditTransactionMailTemplateMutationVariables
>;
export type EditTransactionMailTemplateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    EditTransactionMailTemplateMutation,
    EditTransactionMailTemplateMutationVariables
  >,
  'mutation'
>;

export const EditTransactionMailTemplateComponent = (
  props: EditTransactionMailTemplateComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    EditTransactionMailTemplateMutation,
    EditTransactionMailTemplateMutationVariables
  >
    mutation={EditTransactionMailTemplateDocument}
    {...props}
  />
);

/**
 * __useEditTransactionMailTemplateMutation__
 *
 * To run a mutation, you first call `useEditTransactionMailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTransactionMailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTransactionMailTemplateMutation, { data, loading, error }] = useEditTransactionMailTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTransactionMailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditTransactionMailTemplateMutation,
    EditTransactionMailTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditTransactionMailTemplateMutation,
    EditTransactionMailTemplateMutationVariables
  >(EditTransactionMailTemplateDocument, options);
}
export type EditTransactionMailTemplateMutationHookResult = ReturnType<
  typeof useEditTransactionMailTemplateMutation
>;
export type EditTransactionMailTemplateMutationResult =
  Apollo.MutationResult<EditTransactionMailTemplateMutation>;
export type EditTransactionMailTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    EditTransactionMailTemplateMutation,
    EditTransactionMailTemplateMutationVariables
  >;
