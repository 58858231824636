import { add, format, startOfDay } from 'date-fns';
import React, { ComponentType, useMemo } from 'react';
import { useRoomManagementUi } from '../../room-management-context';
import styles from '../room-event-manager.module.scss';

interface RulerProps {
  withLegend?: boolean;
}

export const Ruler: ComponentType<RulerProps> = ({ withLegend }) => {
  const { gridWidth, numSlots, gridUnit } = useRoomManagementUi();

  const lines = useMemo(() => {
    return Array.from(Array(numSlots).keys()).map((slot) => ({
      label: 'Blah',
      left: slot * gridWidth,
    }));
  }, [gridWidth]);

  return (
    <div className={styles.ruler}>
      {lines.map((line, index) => (
        <div
          key={index}
          className={withLegend ? styles.legend : styles.line}
          style={{ left: `${line.left}px` }}
        >
          {withLegend && (
            <>
              {format(
                add(startOfDay(new Date()), { minutes: index * gridUnit }),
                'HH:mm',
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
