import { Inquiry, InquiryStatus } from '@/graphql/generated/types';
import { Chip } from '@/ui/chip/chip.component';
import React, { ComponentType } from 'react';
import { Translator } from 'shared/translator/translator.component';
import globalStyles from 'styles/global.scss';
function meaningByInquiryStatus(status: InquiryStatus) {
  if (status === InquiryStatus.Open) {
    return 'error';
  }
  if (status === InquiryStatus.InProgress) {
    return 'warning';
  }
  if (status === InquiryStatus.Closed) {
    return 'success';
  }
  return undefined;
}

interface InquiryStatusProps {
  inquiry: Pick<Inquiry, 'status'> & { assignee?: { fullName: string } | null };
}

export const InquiryStatusView: ComponentType<InquiryStatusProps> = (props) => {
  const { inquiry } = props;
  const _ = Translator.useTranslator();
  return (
    <>
      <Chip meaning={meaningByInquiryStatus(inquiry.status)}>
        {_(`inquiry-status.${inquiry.status}`)}
      </Chip>

      {inquiry.assignee &&
        (inquiry.status === InquiryStatus.InProgress ||
          inquiry.status === InquiryStatus.Closed) && (
          <div className={globalStyles.small}>
            von {inquiry.assignee?.fullName}
          </div>
        )}
    </>
  );
};
