import classNames from 'classnames';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import propertyPath from 'property-path';
import React, { forwardRef, ReactNode, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import globalStyles from 'styles/global.scss';
import styles from './json-field.module.scss';

export interface JsonFieldProps {
  value?: string | any;
  name?: string;
  onChange?: (e: any) => void;
  error?: string;
  label?: ReactNode | null;
  placeholder?: string;
  readOnly?: boolean;
  formState?: FormState<any>;
  className?: string;
}

export const JsonField = forwardRef<any, JsonFieldProps>((props, ref) => {
  const { error, label, value, formState, className } = props;

  const errorMessage = useMemo(() => {
    if (props.name && formState?.errors) {
      return propertyPath.get(formState.errors, props.name)?.message;
    }
    return error;
  }, [error, formState, props.name]);

  return (
    <div className={classNames(styles.host, className)}>
      {label && <label>{label}</label>}
      <JsonEditor value={value} onChange={props.onChange} />
      {/*  <textarea
          ref={ref}
          {...rest}
          value={
            props.type === 'date'
              ? value
                ? formatISO(parseISO(value)).substr(0, 10)
                : ''
              : value || ''
          }
        /> */}
      {errorMessage && <p className={globalStyles.error}>{errorMessage}</p>}
    </div>
  );
});
