import { SelectField } from '@/ui/select-field/select-field.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType, useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useHideFrame } from 'shared/hooks/hide-frame';
import globalStyles from 'styles/global.scss';
import { Button } from '../../ui/button/button.component';
import { InputField } from '../../ui/input-field/input-field.component';
import { Loader } from '../../ui/loader/loader.component';
import { Logo } from '../../ui/logo/logo.component';
import { useLoginForm } from './login.form';

const claims = [
  /* '', 'Befrausche das Chaos.', 'Lege die Karten.', 'Spack ab!' */
];

export const Login: ComponentType<RouteComponentProps> = () => {
  const [step, setStep] = useState<'one' | 'two'>('one');

  const [enterprises, setEnterprises] = useState<
    { id: string; name: string }[]
  >([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<string | null>(
    null,
  );

  const onStep1Success = useCallback(
    (enterprises: { id: string; name: string }[]) => {
      if (enterprises.length > 0) {
        setEnterprises(enterprises);
        setSelectedEnterprise(enterprises[0].id);
        setStep('two');
      }
    },
    [],
  );

  const { loading, submitStep1, submitStep2, control, formState, serverError } =
    useLoginForm(onStep1Success);

  const { errors } = formState;

  const onLoginClick = useCallback(() => {
    if (selectedEnterprise) {
      submitStep2(selectedEnterprise);
    }
  }, [submitStep2, selectedEnterprise]);

  useHideFrame();
  const domNode = document.getElementById('frame');
  if (!domNode) {
    return null;
  }

  return (
    <div className="centered" style={{ width: 300, margin: '150px auto' }}>
      {loading && <Loader small />}
      <Logo />
      <p>
        {claims[Math.floor(Math.random() * claims.length)]}
        <br />
        &nbsp;
      </p>
      {step === 'one' && (
        <>
          <Controller
            name="username"
            render={({ field }) => (
              <InputField
                {...field}
                error={errors.username?.message}
                placeholder="Benutzername"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    submitStep1();
                  }
                }}
              />
            )}
            control={control}
            defaultValue=""
          />
          <Controller
            name="password"
            render={({ field }) => (
              <InputField
                {...field}
                placeholder="Passwort"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    submitStep1();
                  }
                }}
                type="password"
                error={errors.password?.message}
              />
            )}
            control={control}
            defaultValue=""
          />

          {serverError && <p className={globalStyles.error}>{serverError}</p>}
          <div>
            <Button fluid primary onClick={submitStep1} label="Login" />
            <p>
              <Link to="/request-password">Passwort vergessen?</Link>
            </p>
          </div>
        </>
      )}

      {step === 'two' && (
        <>
          <SelectField
            name="enterprise"
            label="Bitte wähle das Unternehmen"
            choices={enterprises.map((enterprise) => ({
              value: enterprise.id,
              label: enterprise.name,
            }))}
            onChange={(id) => setSelectedEnterprise(id as string)}
            value={selectedEnterprise || undefined}
            error={errors.username?.message}
          />

          {serverError && <p className={globalStyles.error}>{serverError}</p>}
          <div>
            <Button fluid onClick={onLoginClick} label="Login" primary />
          </div>
        </>
      )}
    </div>
  );
};
