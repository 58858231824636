import { format, parseISO } from 'date-fns';
import de from 'date-fns/locale/de';

export function formatDate(
  value?: string | Date | null,
  withTime?: boolean,
  formatString?: string,
) {
  if (!value) {
    return '';
  }

  const date = typeof value === 'string' ? parseISO(value) : value;
  if (formatString) {
    return format(date, formatString, { locale: de });
  }
  return format(date, withTime ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy', {
    locale: de,
  });
}

export function formatMoney(value: number, precision = 2) {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    minimumFractionDigits: precision,
    currency: 'EUR',
  }).format(value);
}

export function secondsToTime(value?: number | null) {
  if (value === undefined || value === null) {
    return 'unbekannt';
  }
  let hours: string | number = Math.floor(value / 3600);
  let minutes: string | number = Math.floor((value - hours * 3600) / 60);
  let seconds: string | number = value - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return `${hours}:${minutes}:${seconds}`;
}
