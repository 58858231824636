import { Inquiry, InquiryStatus } from '@/graphql/generated/types';
import { Action } from '@/ui/dropdown-menu/dropdown-menu.component';
import { error, success } from '@/ui/toaster/toast';
import { useCallback } from 'react';
import {
  useAssignMutation,
  useCloseMutation,
  useReopenMutation,
} from '../detail/editor/editor.generated';

export function useAssigneeActions(callback: () => void) {
  const [assign] = useAssignMutation();
  const [close] = useCloseMutation();
  const [reopen] = useReopenMutation();

  const assignInquiry = useCallback(
    async (inquiry: Pick<Inquiry, 'id' | 'status'> | undefined) => {
      if (inquiry) {
        try {
          await assign({ variables: { id: inquiry.id } });
          success('Diese Anfrage wurde dir zugewiesen.');
          callback();
        } catch (e) {
          error('Ein Fehler ist aufgetreten.');
        }
      }
    },
    [],
  );

  const closeInquiry = useCallback(
    async (inquiry: Pick<Inquiry, 'id' | 'status'> | undefined) => {
      if (inquiry) {
        try {
          await close({ variables: { id: inquiry.id } });
          success('Diese Anfrage wurde geschlossen.');
          callback();
        } catch (e) {
          error('Ein Fehler ist aufgetreten.');
        }
      }
    },
    [],
  );

  const reopenInquiry = useCallback(
    async (inquiry: Pick<Inquiry, 'id' | 'status'> | undefined) => {
      if (inquiry) {
        try {
          await reopen({ variables: { id: inquiry.id } });
          success('Diese Anfrage wurde zurückgestellt.');
          callback();
        } catch (e) {
          error('Ein Fehler ist aufgetreten.');
        }
      }
    },
    [],
  );

  const actionsForInquiry = useCallback(
    (inquiry: Pick<Inquiry, 'id' | 'status'> | undefined) => {
      const actions: Action[] = [];

      if (inquiry?.status === InquiryStatus.Open) {
        actions.push({
          label: 'Mir zuweisen',
          meaning: 'warning',
          onClick: () => assignInquiry(inquiry),
        });
      } else if (inquiry?.status === InquiryStatus.InProgress) {
        actions.push({
          label: 'Zurückstellen',
          meaning: 'error',
          onClick: () => reopenInquiry(inquiry),
        });
        actions.push({
          label: 'Abschließen',
          meaning: 'success',
          onClick: () => closeInquiry(inquiry),
        });
      } else if (inquiry?.status === InquiryStatus.Closed) {
        actions.push({
          label: 'Wieder öffnen',
          meaning: 'error',
          onClick: () => reopenInquiry(inquiry),
        });
      }
      return actions;
    },
    [],
  );

  return { reopenInquiry, closeInquiry, assignInquiry, actionsForInquiry };
}
