import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { Grid } from '@/ui/grid/grid.component';
import { LabeledItem } from '@/ui/labeled-item/labeled-item.component';
import React, { ComponentType, useMemo } from 'react';
import { useLeadStatsQuery } from './leads-widget.generated';
import styles from './leads-widget.module.scss';
interface LeadsWidgetProps {}

const today = new Date();

export const LeadsWidget: ComponentType<LeadsWidgetProps> = (props) => {
  const leadsQuery = useLeadStatsQuery({
    fetchPolicy: 'network-only',
    variables: { date: today },
  });

  const numOfEvents = useMemo(
    () => leadsQuery.data?.adminConsultingEventsForCalendar.length || 0,
    [leadsQuery.data],
  );

  const total = useMemo(() => {
    if (!leadsQuery.data) {
      return 0;
    }

    let numOrdersWithoutBooking = 0;
    for (const order of leadsQuery.data.adminOrders.data) {
      if (order.items.find((i) => i.__typename === 'BookingOrderItem')) {
        continue;
      }
      numOrdersWithoutBooking++;
    }

    return (
      leadsQuery.data.adminBookings.totalCount +
      numOrdersWithoutBooking +
      leadsQuery.data.adminInquiries.totalCount +
      numOfEvents
    );
  }, [leadsQuery.data, numOfEvents]);

  return (
    <Box
      stretch
      title={
        <>
          Leads heute{' '}
          <Button small primary linkTo="/leads" label="Alle Leads" />{' '}
        </>
      }
      withMargin
      className={styles.host}
      loading={leadsQuery.loading}
    >
      <LabeledItem label="Neue Interaktionen" big value={total} />
      <Grid.Row>
        <Grid.Column>
          <LabeledItem
            big
            label="Bestellungen"
            value={leadsQuery.data?.adminOrders.totalCount || 0}
          />
        </Grid.Column>
        <Grid.Column>
          <LabeledItem
            big
            label="Buchungen"
            value={leadsQuery.data?.adminBookings.totalCount || 0}
          />
        </Grid.Column>
        <Grid.Column>
          <LabeledItem label="Beratungstermine" big value={numOfEvents} />
        </Grid.Column>
        <Grid.Column>
          <LabeledItem
            big
            label="Anfragen"
            value={leadsQuery.data?.adminInquiries.totalCount || 0}
          />
        </Grid.Column>
      </Grid.Row>
    </Box>
  );
};
