import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { getCourseUnitDates } from 'shared/course/utils';
import globalStyles from 'styles/global.scss';
import { BookingsQuery } from '../list/list.generated';
import styles from './booking-course-unit-or-module.module.scss';

interface BookingCourseUnitOrModuleProps {
  booking: BookingsQuery['adminBookings']['data'][number];
}

export const BookingCourseUnitOrModule: ComponentType<
  BookingCourseUnitOrModuleProps
> = (props) => {
  const { booking } = props;

  if (booking.__typename === 'CourseModuleBooking') {
    return (
      <div className={styles.host}>
        {booking.courseModule.title && (
          <div className={styles.title}>{booking.courseModule.title}</div>
        )}
        <div className={styles.dates}>
          {getCourseUnitDates(booking.courseModule)}
        </div>
        <div className={classNames(globalStyles.sub, globalStyles.meta)}>
          {booking.courseModule.sku}
        </div>
      </div>
    );
  }
  if (booking.__typename === 'CourseUnitBooking') {
    return (
      <div className={styles.host}>
        {booking.courseUnit.title && (
          <div className={styles.title}>{booking.courseUnit.title}</div>
        )}
        <div className={styles.dates}>
          {getCourseUnitDates(booking.courseUnit)}
        </div>
        <div className={classNames(globalStyles.sub, globalStyles.meta)}>
          {booking.courseUnit.sku}
        </div>
      </div>
    );
  }

  return <>Unbekannt</>;
};
