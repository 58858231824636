import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { Category } from './category/category.component';
import { CourseUnit } from './course-unit/course-unit.component';
import { Course } from './course/course.component';
import { Package } from './package/package.component';
import { Season } from './season/season.component';

export const CourseManagementRoutes: ComponentType<RouteComponentProps> = (
  props,
) => {
  return (
    <>
      <Frame.Title>Kursverwaltung</Frame.Title>
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            {
              label: 'Kursplan',
              path: 'units',
            },
            {
              label: 'Saisons',
              path: 'seasons',
            },
            {
              label: 'Kurse',
              path: 'courses',
            },
            {
              label: 'Packages',
              path: 'packages',
            },
            {
              label: 'Kategorien',
              path: 'categories',
            },
          ]}
        />
      </Frame.SubMenu>
      <Router className="router">
        <CourseUnit path="units/*" />
        <Course path="courses/*" />
        <Category path="categories/*" />
        <Season path="seasons/*" />
        <Package path="packages/*" />
        <Redirect from="/" to="units" />
      </Router>
    </>
  );
};
