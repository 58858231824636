import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FileListDataFragment = { __typename?: 'FilesystemFile', id: any, name: string, url: string, humanUrl: string, isImage: boolean, isRasterizedImage: boolean, group: Types.FileGroup, createdAt: any, mimetype?: string | null, enterpriseId?: any | null, enterprise?: { __typename?: 'Enterprise', id: any, name: string } | null };

export type FilesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.FilterFileInput>;
}>;


export type FilesQuery = { __typename?: 'Query', adminFiles: Array<{ __typename?: 'FilesystemFile', id: any, name: string, url: string, humanUrl: string, isImage: boolean, isRasterizedImage: boolean, group: Types.FileGroup, createdAt: any, mimetype?: string | null, enterpriseId?: any | null, enterprise?: { __typename?: 'Enterprise', id: any, name: string } | null }> };

export type UploadFileMutationVariables = Types.Exact<{
  input: Types.FileUploadInput;
  file: Types.Scalars['Upload'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', adminUploadFile: { __typename?: 'FilesystemFile', id: any, name: string, url: string, group: Types.FileGroup, enterpriseId?: any | null, private: boolean } };

export const FileListDataFragmentDoc = gql`
    fragment FileListData on FilesystemFile {
  id
  name
  url
  humanUrl
  isImage
  isRasterizedImage
  group
  createdAt
  mimetype
  enterpriseId
  enterprise {
    id
    name
  }
}
    `;
export const FilesDocument = gql`
    query files($filter: FilterFileInput) {
  adminFiles(filter: $filter) {
    ...FileListData
  }
}
    ${FileListDataFragmentDoc}`;
export type FilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FilesQuery, FilesQueryVariables>, 'query'>;

    export const FilesComponent = (props: FilesComponentProps) => (
      <ApolloReactComponents.Query<FilesQuery, FilesQueryVariables> query={FilesDocument} {...props} />
    );
    

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilesQuery(baseOptions?: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
      }
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
        }
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($input: FileUploadInput!, $file: Upload!) {
  adminUploadFile(input: $input, file: $file) {
    id
    name
    url
    group
    enterpriseId
    private
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;
export type UploadFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadFileMutation, UploadFileMutationVariables>, 'mutation'>;

    export const UploadFileComponent = (props: UploadFileComponentProps) => (
      <ApolloReactComponents.Mutation<UploadFileMutation, UploadFileMutationVariables> mutation={UploadFileDocument} {...props} />
    );
    

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;