import { Frame } from '@/frame';
import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import React, { ComponentType } from 'react';

interface Props {
  current:
    | 'edit'
    | 'settings'
    | 'transaction-mail-templates'
    | 'property-schemas';
}
export const SubNav: ComponentType<Props> = ({ current }) => (
  <Frame.SubMenu>
    <NavMenu
      type="horizontal"
      items={[
        {
          label: 'Allgemein',
          path: current === 'edit' ? './' : '../edit',
        },
        {
          label: 'TX-Mail-Vorlagen',
          path:
            current === 'transaction-mail-templates'
              ? './'
              : '../transaction-mail-templates',
        },
        {
          label: 'Benutzerdefinierte Felder',
          path: current === 'property-schemas' ? './' : '../property-schemas',
        },
        {
          label: 'Einstellungen',
          path: current === 'settings' ? './' : '../settings',
        },
      ]}
    />
  </Frame.SubMenu>
);
