import { IfSuper } from '@/auth/role/if-role.component';
import { Frame } from '@/frame';
import {
  SaveSeasonInput,
  Season,
  SeasonStatus,
} from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDuplicateSeason } from 'shared/course/duplicator/season-duplicator.hook';
import { useResources } from 'shared/resources/resources.provider';
import { object, string } from 'yup';
import {
  useDeleteSeasonMutation,
  useSaveSeasonMutation,
  useSeasonQuery,
} from './editor.generated';

const schema = object({
  title: string().required(),
  status: string().required(),
});

interface EditProps {
  mode: 'create' | 'update';
}

export const Editor: ComponentType<
  RouteComponentProps<{ id: string }> & EditProps
> = (props) => {
  const { id, mode } = props;
  const profile = Profile.use();

  const { availableEnterprises, reload } = useResources();

  const { open: openDuplicationModal, modal: duplicationModal } =
    useDuplicateSeason(() => {
      reload();
    });

  const { loading, data } = useSeasonQuery({
    variables: { id },
    skip: mode === 'create',
    fetchPolicy: 'network-only',
  });

  const [save] = useSaveSeasonMutation();
  const [remove] = useDeleteSeasonMutation();

  const form = useForm<SaveSeasonInput>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
    defaultValues: {
      enterpriseId: profile?.selectedEnterprise?.id,
      status: SeasonStatus.Active,
    },
  });

  const [confirmModal, confirmDelete] = useConfirm(async (id: string) => {
    try {
      await remove({ variables: { id } });
      await reload();
      navigate('../list');
      success('Die Saison wurde gelöscht.');
    } catch (e) {
      error('Fehler', 'Die Saison kann nicht gelöscht werden.');
    }
  });

  const onSubmit = useMemo(() => {
    return form.handleSubmit(async (input) => {
      try {
        if (id) {
          await save({
            variables: {
              input: {
                ...input,
                id,
              },
            },
          });
          await reload();
          success('Die Saison wurde gespeichert.');
        } else {
          const created = await save({
            variables: {
              input,
            },
          });
          await reload();
          navigate(`./${created.data?.adminSaveSeason.id}/edit`);
          success('Die Saison wurde erstellt.');
        }
      } catch (e: any) {
        error('Fehler', e.message);
      }
    });
  }, [id, form.handleSubmit]);

  const onDelete = useCallback(async () => {
    if (id) {
      confirmDelete('Soll diese Saison wirklich gelöscht werden?', id);
    }
  }, [id]);

  return (
    <>
      {confirmModal}
      {duplicationModal}
      <Frame.SubTitle>
        {mode === 'create' ? (
          <>Neue Saison</>
        ) : (
          <>Saison: {data?.adminSeason.title}</>
        )}
      </Frame.SubTitle>
      <Frame.ActionBar>
        {id && (
          <>
            <Button
              warning
              transparent
              label="Saison löschen"
              onClick={onDelete}
            />
            <Button
              label="Duplizieren"
              onClick={() => openDuplicationModal(data?.adminSeason as Season)}
            />
          </>
        )}
        <Button label="Zurück" linkTo="/course-management/seasons/list" />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={data?.adminSeason}>
          <Section>
            <Form.Input name="title" label="Titel" />
            <Form.Select
              label="Status"
              name="status"
              choices={[
                { label: 'Aktiv', value: SeasonStatus.Active },
                { label: 'Inaktiv', value: SeasonStatus.Inactive },
              ]}
            />
            {!id && (
              <IfSuper
                otherwise={<Form.Input type="hidden" name="enterpriseId" />}
              >
                <Form.Select
                  name="enterpriseId"
                  label="Unternehmen"
                  defaultValue={
                    availableEnterprises.length > 0
                      ? availableEnterprises[0].id
                      : undefined
                  }
                  choices={
                    availableEnterprises.map((enterprise) => ({
                      value: enterprise?.id,
                      label: enterprise?.name,
                    })) || []
                  }
                />
              </IfSuper>
            )}

            {id && (
              <>
                <p>
                  Diese Saison ist dem Unternehmen{' '}
                  <em>{data?.adminSeason.enterprise?.name}</em> zugewiesen.
                </p>
                <Form.Input type="hidden" name="enterpriseId" />
              </>
            )}
          </Section>
        </Form>
      </Frame.Content>
    </>
  );
};
