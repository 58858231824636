import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DeleteDiscountRuleMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteDiscountRuleMutation = { __typename?: 'Mutation', deleteDiscountRule: boolean };


export const DeleteDiscountRuleDocument = gql`
    mutation deleteDiscountRule($id: UUID!) {
  deleteDiscountRule(id: $id)
}
    `;
export type DeleteDiscountRuleMutationFn = Apollo.MutationFunction<DeleteDiscountRuleMutation, DeleteDiscountRuleMutationVariables>;
export type DeleteDiscountRuleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDiscountRuleMutation, DeleteDiscountRuleMutationVariables>, 'mutation'>;

    export const DeleteDiscountRuleComponent = (props: DeleteDiscountRuleComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDiscountRuleMutation, DeleteDiscountRuleMutationVariables> mutation={DeleteDiscountRuleDocument} {...props} />
    );
    

/**
 * __useDeleteDiscountRuleMutation__
 *
 * To run a mutation, you first call `useDeleteDiscountRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscountRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscountRuleMutation, { data, loading, error }] = useDeleteDiscountRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDiscountRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiscountRuleMutation, DeleteDiscountRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiscountRuleMutation, DeleteDiscountRuleMutationVariables>(DeleteDiscountRuleDocument, options);
      }
export type DeleteDiscountRuleMutationHookResult = ReturnType<typeof useDeleteDiscountRuleMutation>;
export type DeleteDiscountRuleMutationResult = Apollo.MutationResult<DeleteDiscountRuleMutation>;
export type DeleteDiscountRuleMutationOptions = Apollo.BaseMutationOptions<DeleteDiscountRuleMutation, DeleteDiscountRuleMutationVariables>;