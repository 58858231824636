import { FileGroup, RoleType } from '@/graphql/generated/types';
import { Profile } from '@/profile/profile';
import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import { useModal } from '@/ui/modal/modal.hooks';
import { SelectField } from '@/ui/select-field/select-field.component';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { AvailableEnterpriseDataFragment } from 'shared/resources/resources.generated';
import { useResources } from 'shared/resources/resources.provider';
import globalStyles from 'styles/global.scss';
import { FileGroupSelectField } from '../file-group-select-field.component';

interface FileUploadModalOptions {
  onSubmit(
    file: File | File[] | undefined,
    enterprise: AvailableEnterpriseDataFragment | undefined,
    group: FileGroup,
    private_: boolean,
  ): void;
}
export function useFileUploadModal(
  options: FileUploadModalOptions,
): [
  ReactNode,
  { open: (file: File | File[] | undefined) => void; close: () => void },
] {
  const [file, setFile] = useState<File | File[] | undefined>();
  const [enterprise, setEnterprise] =
    useState<AvailableEnterpriseDataFragment>();
  const [group, setGroup] = useState<FileGroup>(FileGroup.Common);
  const [private_, setPrivate_] = useState<boolean>();
  const { availableEnterprises } = useResources();
  const profile = Profile.use();

  useEffect(() => {
    if (availableEnterprises.length > 0) {
      setEnterprise(availableEnterprises[0]);
    }
  }, [availableEnterprises]);

  const close = useCallback(() => {
    modal[1].close();
  }, []);

  const modal = useModal(
    <Box title="Datei hochladen">
      <h3>
        {file && Array.isArray(file) ? (
          <>{file.length} Datei(en)</>
        ) : (
          file?.name
        )}
      </h3>
      <p>
        Bitte gib an, wer die Besitzrechte an dieser Datei hat und in welche
        Kategorie die Datei einsortiert werden soll!
      </p>
      <FileGroupSelectField
        nullable={false}
        value={group}
        onChange={(g) => (g ? setGroup(g) : undefined)}
      />
      {profile?.roles.find((r) => r.name === RoleType.Super) && (
        <SelectField
          label="Unternehmen"
          value={enterprise?.id}
          onChange={(value) =>
            setEnterprise(availableEnterprises.find((c) => c.id === value))
          }
          choices={
            availableEnterprises.map((enterprise) => ({
              value: enterprise?.id,
              label: enterprise?.name,
            })) || []
          }
        />
      )}
      <Checkbox
        label="Diese Datei ist privat"
        noSpacing
        onChange={(value) => {
          setPrivate_(value);
        }}
      />
      <div className={globalStyles.flexAlignRight}>
        <Button label="Abbrechen" onClick={close} />
        <Button
          primary
          label="Hochladen"
          onClick={() =>
            options.onSubmit(file, enterprise, group, private_ || false)
          }
        />
      </div>
    </Box>,
    { closeOnDimmerClick: false },
  );

  const open = useCallback(
    (file: File | File[] | undefined) => {
      setFile(file);
      modal[1].open();
    },
    [modal[1].open],
  );

  return [modal[0], { ...modal[1], open }];
}
