import { getPersonDetailLink } from '@/contact/person/utils';
import { Button } from '@/ui/button/button.component';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import { DropdownMenu } from '@/ui/dropdown-menu/dropdown-menu.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Link, RouteComponentProps } from '@reach/router';
import { format } from 'date-fns';
import { pick } from 'lodash-es';
import { stringify } from 'query-string';
import React, { ComponentType } from 'react';
import { API_URI } from 'shared/constants';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { Frame } from '../../frame';
import { ListView } from '../../ui/list-view/list-view.component';
import { Row } from '../../ui/table/row.component';
import { useOrderActions } from '../shared/actions/actions.hooks';
import { useCreateOrderModal } from '../shared/create-order/create-order-modal.component';
import { MiniOrderItemsOverview } from '../shared/mini-order-items-overview/mini-order-items-overview.component';
import { OrdersQuery, useOrdersQuery } from './list.generated';

interface FilterType {
  startDate?: string;
  endDate?: string;
}

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery<FilterType>();
  const { data, loading, refetch, error } = useOrdersQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      filter: pick(query, ['startDate', 'endDate']),
    },
    fetchPolicy: 'network-only',
  });

  const { modalDelete, askForDelete } = useOrderActions(refetch);

  const { modal: createOrderModal, open: openCreateOrderModal } =
    useCreateOrderModal(refetch);

  const today = format(new Date(), 'y-MM-dd');

  const _ = Translator.useTranslator();

  return (
    <>
      {createOrderModal}
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button
          href={`${API_URI}/orders/export/orders?${stringify(query)}`}
          label="CSV Export (Bestellungen)"
        />
        <Button
          href={`${API_URI}/orders/export/items?${stringify(query)}`}
          label="CSV Export (Positionen)"
        />
        <Button
          primary
          onClick={openCreateOrderModal}
          label="Neue Bestellung"
        />
      </Frame.ActionBar>
      <Frame.Content>
        {modalDelete}
        <ListView<OrdersQuery['adminOrders']['data'][number], FilterType>
          loading={loading}
          data={data?.adminOrders?.data || []}
          totalCount={data?.adminOrders?.totalCount}
          columns={[
            {
              name: 'Bestellt am',
              sortable: true,
            },
            {
              name: 'Umsatz',
              sortable: false,
            },
            {
              name: 'Positionen',
              sortable: false,
            },
            {
              name: 'Kunde/Kundin',
              sortable: false,
            },
            { name: 'Aktionen', sortable: false },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              <DatetimeField
                label="Anfangs-Datum"
                value={value.startDate}
                withTime={false}
                onChange={(value) => {
                  onChange(
                    'startDate',
                    value ? format(value, 'Y-MM-dd') : null,
                  );
                }}
              />
              <DatetimeField
                label="End-Datum"
                value={value.endDate}
                withTime={false}
                onChange={(value) =>
                  onChange('endDate', value ? format(value, 'Y-MM-dd') : null)
                }
              />
              <Button
                small
                label="Heute"
                linkTo={`./?startDate=${today}&endDate=${today}`}
              />
            </>
          )}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/view`}>
                  <Formatted.Date withTime value={item.createdAt} />
                </Link>
              </td>
              <td>
                <Formatted.Currency value={item.total} />
              </td>
              <td>
                <MiniOrderItemsOverview order={item} />
              </td>
              <td>
                <Link
                  to={getPersonDetailLink(item.customer.person, 'customers')}
                >
                  {[
                    item.customer.person.title,
                    item.customer.person.firstName,
                    item.customer.person.lastName,
                  ].join(' ')}
                </Link>
              </td>
              <td>
                <DropdownMenu
                  actions={[
                    {
                      label: 'Löschen',
                      meaning: 'error',
                      onClick: () => askForDelete(item),
                    },
                  ]}
                />
              </td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
