import classNames from 'classnames';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { InfoBox } from '../info-box/info-box.component';
import { Loader } from '../loader/loader.component';
import styles from './table.module.scss';

export interface TableProps<DataType> {
  className?: string;
  loading?: boolean;
  data?: DataType[];
  columns: ReactNode[];
  render(object: DataType, index: number): ReactElement;
  compact?: boolean;
  tiny?: boolean;
  additionalRows?: ReactNode;
}

export function Table<DataType>(props: TableProps<DataType>) {
  const {
    className,
    columns,
    data = [],
    render,
    loading,
    compact,
    tiny,
    additionalRows,
  } = props;

  if (loading) {
    return <Loader />;
  }

  if (data.length === 0) {
    return <InfoBox.Empty />;
  }

  return (
    <table
      className={classNames(
        styles.host,
        className,
        compact && styles.compact,
        tiny && styles.tiny,
      )}
    >
      <thead>
        <tr>
          {columns.map((column, index) =>
            typeof column === 'string' ? (
              <th key={index}>{column}</th>
            ) : (
              <Fragment key={index}>{column}</Fragment>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {data.map(render)}
        {additionalRows}
      </tbody>
    </table>
  );
}
