import {
  Course,
  CourseModule,
  CourseUnit,
  Location,
  Product,
} from '@/graphql/generated/types';
import React, { ComponentType } from 'react';
import {
  getCourseUnitDates,
  getCourseUnitLocationLabel,
} from 'shared/course/utils';

interface DiscountRuleDependenciesProps {
  dependencies: {
    courses: Pick<Course, 'id' | 'title' | 'verboseTitle'>[];
    products: Pick<Product, 'id' | 'title'>[];
    courseUnits: (Pick<
      CourseUnit,
      | 'id'
      | 'title'
      | 'startDate'
      | 'endDate'
      | 'withTime'
      | 'dateUncertain'
      | 'correspondenceCourse'
      | 'online'
    > & {
      course: Pick<Course, 'id' | 'title'>;
      location?: Pick<Location, 'commonName'> | null;
      searchableLocation?: Pick<Location, 'commonName'> | null;
    })[];
    courseModules: (Pick<
      CourseModule,
      | 'id'
      | 'title'
      | 'startDate'
      | 'endDate'
      | 'withTime'
      | 'dateUncertain'
      | 'correspondenceCourse'
      | 'online'
    > & {
      courseUnit: { course: Pick<Course, 'id' | 'title'> };
      location?: Pick<Location, 'commonName'> | null;
      searchableLocation?: Pick<Location, 'commonName'> | null;
    })[];
  };
}

export const DiscountRuleDependenciesView: ComponentType<
  DiscountRuleDependenciesProps
> = (props) => {
  const { products, courseUnits, courseModules, courses } = props.dependencies;
  return (
    <>
      {[
        courses.map((c) => c.verboseTitle || c.title).join(', '),
        courseUnits
          .map(
            (c) =>
              `${c.title || c.course.title} (${getCourseUnitDates(
                c,
              )}, ${getCourseUnitLocationLabel(c)})`,
          )
          .join(', '),
        courseModules
          .map(
            (c) =>
              `${c.title || c.courseUnit.course.title} (${getCourseUnitDates(
                c,
              )}, ${getCourseUnitLocationLabel(c)})`,
          )
          .join(', '),
        products.map((p) => p.title).join(', '),
      ]
        .filter((i) => !!i)
        .join(', ')}
    </>
  );
};
