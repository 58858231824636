// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W_7X6Ke38bqQcEOMWbXl{display:flex;justify-content:flex-end;margin:1rem 0}", "",{"version":3,"sources":["webpack://./src/order/shared/create-order/create-order.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,wBAAA,CACA,aAAA","sourcesContent":[".addButton {\n  display: flex;\n  justify-content: flex-end;\n  margin: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "W_7X6Ke38bqQcEOMWbXl"
};
export default ___CSS_LOADER_EXPORT___;
