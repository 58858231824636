import { UserStatus } from '@/graphql/generated/types';
import { useMemo } from 'react';
import { Translator } from 'shared/translator/translator.component';

export function useUserStatusChoices() {
  const _ = Translator.useTranslator();
  return useMemo(
    () => [
      {
        value: UserStatus.Registered,
        label: _(`user-status.${UserStatus.Registered}`),
      },
      {
        value: UserStatus.Unregistered,
        label: _(`user-status.${UserStatus.Unregistered}`),
      },
    ],
    [],
  );
}
