import { getPersonDescription } from '@/contact/person/utils';
import { Inquiry, Person } from '@/graphql/generated/types';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { error, success } from '@/ui/toaster/toast';
import React, { useCallback } from 'react';
import { useDeleteInquiryMutation } from './actions.generated';

export function useInquiryActions(callback: () => void) {
  const [deleteInquiry] = useDeleteInquiryMutation();

  const [modalDelete, askDelete] = useConfirm(
    async (id: string) => {
      try {
        await deleteInquiry({
          variables: {
            id,
          },
        });
        success('Die Anfrage wurde gelöscht.');
        callback();
      } catch (e: any) {
        console.error(e);
        error('Beim Löschen ist ein Fehler aufgetreten.', e.message);
      }
    },
    { meaning: 'error', title: 'Anfrage löschen' },
  );

  const askForDelete = useCallback(
    (
      inquiry: Pick<Inquiry, 'id'> & {
        person: Pick<Person, 'firstName' | 'lastName' | 'gender' | 'title'>;
      },
    ) => {
      askDelete(
        <>
          <p>Diese Aktion lässt sich nicht rückgängig machen.</p>
          <p>
            Bist du sicher, dass du die Anfrage von{' '}
            <em>{getPersonDescription(inquiry.person)}</em> löschen möchtest?
          </p>
        </>,
        inquiry.id,
      );
    },
    [],
  );

  return {
    modalDelete,
    askForDelete,
  };
}
