import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { Calendar } from './calendar/calendar.component';
import { Detail } from './calendar/detail/detail.component';
import { ConsultingEventList } from './consulting/list/list.component';

export const EventRoutes: ComponentType<RouteComponentProps> = (props) => {
  return (
    <>
      <Frame.Title>Terminverwaltung</Frame.Title>
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            /* {
              label: 'Terminplan',
              path: 'events',
            }, */
            {
              label: 'Beratungstermine',
              path: 'consulting/booked',
            },
            {
              label: 'Kalender',
              path: 'calendar',
            },
          ]}
        />
      </Frame.SubMenu>
      <Router className="router">
        <Calendar path="calendar/*" />
        <ConsultingEventList path="consulting/booked" />
        <Detail path="events/:id/detail" />
        <Redirect from="/" to="consulting/booked" />
        {/*
        <Course path="courses/*" />
        <Category path="categories/*" />
         */}
      </Router>
    </>
  );
};
