import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { PersonListDataFragmentDoc } from '../list/list.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DeletePersonMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeletePersonMutation = { __typename?: 'Mutation', adminDeletePerson: boolean };

export type AnonymizePersonMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type AnonymizePersonMutation = { __typename?: 'Mutation', adminAnonymizePerson: { __typename?: 'Person', id: any, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, user?: { __typename: 'Administrator', roleIds?: Array<any> | null, isBot: boolean, id: any, username?: string | null, status: Types.UserStatus, roles: Array<{ __typename?: 'Role', id: any, name: Types.RoleType }> } | { __typename: 'Customer', id: any, username?: string | null, status: Types.UserStatus } | { __typename: 'Teacher', id: any, username?: string | null, status: Types.UserStatus } | null, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string }>, contactProperties: Array<{ __typename?: 'ContactProperty', key: Types.ContactPropertyKey, value: string }> } };


export const DeletePersonDocument = gql`
    mutation deletePerson($id: UUID!) {
  adminDeletePerson(id: $id)
}
    `;
export type DeletePersonMutationFn = Apollo.MutationFunction<DeletePersonMutation, DeletePersonMutationVariables>;
export type DeletePersonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePersonMutation, DeletePersonMutationVariables>, 'mutation'>;

    export const DeletePersonComponent = (props: DeletePersonComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePersonMutation, DeletePersonMutationVariables> mutation={DeletePersonDocument} {...props} />
    );
    

/**
 * __useDeletePersonMutation__
 *
 * To run a mutation, you first call `useDeletePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonMutation, { data, loading, error }] = useDeletePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonMutation(baseOptions?: Apollo.MutationHookOptions<DeletePersonMutation, DeletePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePersonMutation, DeletePersonMutationVariables>(DeletePersonDocument, options);
      }
export type DeletePersonMutationHookResult = ReturnType<typeof useDeletePersonMutation>;
export type DeletePersonMutationResult = Apollo.MutationResult<DeletePersonMutation>;
export type DeletePersonMutationOptions = Apollo.BaseMutationOptions<DeletePersonMutation, DeletePersonMutationVariables>;
export const AnonymizePersonDocument = gql`
    mutation anonymizePerson($id: UUID!) {
  adminAnonymizePerson(id: $id) {
    ...PersonListData
  }
}
    ${PersonListDataFragmentDoc}`;
export type AnonymizePersonMutationFn = Apollo.MutationFunction<AnonymizePersonMutation, AnonymizePersonMutationVariables>;
export type AnonymizePersonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AnonymizePersonMutation, AnonymizePersonMutationVariables>, 'mutation'>;

    export const AnonymizePersonComponent = (props: AnonymizePersonComponentProps) => (
      <ApolloReactComponents.Mutation<AnonymizePersonMutation, AnonymizePersonMutationVariables> mutation={AnonymizePersonDocument} {...props} />
    );
    

/**
 * __useAnonymizePersonMutation__
 *
 * To run a mutation, you first call `useAnonymizePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnonymizePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anonymizePersonMutation, { data, loading, error }] = useAnonymizePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnonymizePersonMutation(baseOptions?: Apollo.MutationHookOptions<AnonymizePersonMutation, AnonymizePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnonymizePersonMutation, AnonymizePersonMutationVariables>(AnonymizePersonDocument, options);
      }
export type AnonymizePersonMutationHookResult = ReturnType<typeof useAnonymizePersonMutation>;
export type AnonymizePersonMutationResult = Apollo.MutationResult<AnonymizePersonMutation>;
export type AnonymizePersonMutationOptions = Apollo.BaseMutationOptions<AnonymizePersonMutation, AnonymizePersonMutationVariables>;