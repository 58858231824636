import * as Types from '../../graphql/generated/types';

import { gql } from '@apollo/client';
import { EventDataFragmentDoc } from '../event.generated';
import { PersonSimpleDetailDataFragmentDoc } from '../../contact/person/editor/editor.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ConsultingEventDataFragment = { __typename?: 'ConsultingEvent', availableSubjects: Array<string>, selectedSubjects: Array<string>, id: any, description?: string | null, kind: string, startDate: any, endDate: any, attendee?: { __typename?: 'Person', id: any, createdAt: any, title?: string | null, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, inquiries: Array<{ __typename?: 'Inquiry', id: any, createdAt: any, status: Types.InquiryStatus, assignee?: { __typename?: 'Administrator', id: any, fullName: string } | null }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null } } | null, enterprise: { __typename?: 'Enterprise', id: any, name: string }, consultant?: { __typename?: 'Administrator', id: any, fullName: string, personId: any } | null, availableCourses: Array<{ __typename?: 'Course', id: any, title: string }>, selectedCourses: Array<{ __typename?: 'Course', id: any, title: string }>, availableLocations: Array<{ __typename?: 'Location', id: any, commonName: string }>, selectedLocations: Array<{ __typename?: 'Location', id: any, commonName: string }>, location?: { __typename?: 'Location', id: any, commonName: string } | null };

export type ConsultingEventsForCalendarQueryVariables = Types.Exact<{
  input: Types.FilterConsultingEventsForCalendar;
}>;


export type ConsultingEventsForCalendarQuery = { __typename?: 'Query', adminConsultingEventsForCalendar: Array<{ __typename?: 'ConsultingEvent', availableSubjects: Array<string>, selectedSubjects: Array<string>, id: any, description?: string | null, kind: string, startDate: any, endDate: any, attendee?: { __typename?: 'Person', id: any, createdAt: any, title?: string | null, gender?: Types.Gender | null, firstName?: string | null, lastName?: string | null, anonymizedAt?: any | null, contactProperties: Array<{ __typename?: 'ContactProperty', id: any, key: Types.ContactPropertyKey, value: string }>, inquiries: Array<{ __typename?: 'Inquiry', id: any, createdAt: any, status: Types.InquiryStatus, assignee?: { __typename?: 'Administrator', id: any, fullName: string } | null }>, address: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null }, invoiceAddress: { __typename?: 'Address', company?: string | null, contactPerson?: string | null, streetAndNumber?: string | null, postalCode?: string | null, city?: string | null, country?: string | null, other?: string | null } } | null, enterprise: { __typename?: 'Enterprise', id: any, name: string }, consultant?: { __typename?: 'Administrator', id: any, fullName: string, personId: any } | null, availableCourses: Array<{ __typename?: 'Course', id: any, title: string }>, selectedCourses: Array<{ __typename?: 'Course', id: any, title: string }>, availableLocations: Array<{ __typename?: 'Location', id: any, commonName: string }>, selectedLocations: Array<{ __typename?: 'Location', id: any, commonName: string }>, location?: { __typename?: 'Location', id: any, commonName: string } | null }> };

export type CopyConsultingEventMutationVariables = Types.Exact<{
  input: Types.CopyConsultingEventInput;
}>;


export type CopyConsultingEventMutation = { __typename?: 'Mutation', copyConsultingEvent: { __typename: 'ConsultingEvent', id: any } };

export type DeleteConsultingEventMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteConsultingEventMutation = { __typename?: 'Mutation', deleteConsultingEvent: boolean };

export const ConsultingEventDataFragmentDoc = gql`
    fragment ConsultingEventData on ConsultingEvent {
  ...EventData
  attendee {
    ...PersonSimpleDetailData
  }
  enterprise {
    id
    name
  }
  consultant {
    id
    fullName
    personId
  }
  availableCourses {
    id
    title
  }
  selectedCourses {
    id
    title
  }
  availableLocations {
    id
    commonName
  }
  selectedLocations {
    id
    commonName
  }
  availableSubjects
  selectedSubjects
}
    ${EventDataFragmentDoc}
${PersonSimpleDetailDataFragmentDoc}`;
export const ConsultingEventsForCalendarDocument = gql`
    query consultingEventsForCalendar($input: FilterConsultingEventsForCalendar!) {
  adminConsultingEventsForCalendar(input: $input) {
    ...ConsultingEventData
  }
}
    ${ConsultingEventDataFragmentDoc}`;
export type ConsultingEventsForCalendarComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConsultingEventsForCalendarQuery, ConsultingEventsForCalendarQueryVariables>, 'query'> & ({ variables: ConsultingEventsForCalendarQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConsultingEventsForCalendarComponent = (props: ConsultingEventsForCalendarComponentProps) => (
      <ApolloReactComponents.Query<ConsultingEventsForCalendarQuery, ConsultingEventsForCalendarQueryVariables> query={ConsultingEventsForCalendarDocument} {...props} />
    );
    

/**
 * __useConsultingEventsForCalendarQuery__
 *
 * To run a query within a React component, call `useConsultingEventsForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultingEventsForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultingEventsForCalendarQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsultingEventsForCalendarQuery(baseOptions: Apollo.QueryHookOptions<ConsultingEventsForCalendarQuery, ConsultingEventsForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultingEventsForCalendarQuery, ConsultingEventsForCalendarQueryVariables>(ConsultingEventsForCalendarDocument, options);
      }
export function useConsultingEventsForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultingEventsForCalendarQuery, ConsultingEventsForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultingEventsForCalendarQuery, ConsultingEventsForCalendarQueryVariables>(ConsultingEventsForCalendarDocument, options);
        }
export type ConsultingEventsForCalendarQueryHookResult = ReturnType<typeof useConsultingEventsForCalendarQuery>;
export type ConsultingEventsForCalendarLazyQueryHookResult = ReturnType<typeof useConsultingEventsForCalendarLazyQuery>;
export type ConsultingEventsForCalendarQueryResult = Apollo.QueryResult<ConsultingEventsForCalendarQuery, ConsultingEventsForCalendarQueryVariables>;
export const CopyConsultingEventDocument = gql`
    mutation copyConsultingEvent($input: CopyConsultingEventInput!) {
  copyConsultingEvent(input: $input) {
    __typename
    id
  }
}
    `;
export type CopyConsultingEventMutationFn = Apollo.MutationFunction<CopyConsultingEventMutation, CopyConsultingEventMutationVariables>;
export type CopyConsultingEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyConsultingEventMutation, CopyConsultingEventMutationVariables>, 'mutation'>;

    export const CopyConsultingEventComponent = (props: CopyConsultingEventComponentProps) => (
      <ApolloReactComponents.Mutation<CopyConsultingEventMutation, CopyConsultingEventMutationVariables> mutation={CopyConsultingEventDocument} {...props} />
    );
    

/**
 * __useCopyConsultingEventMutation__
 *
 * To run a mutation, you first call `useCopyConsultingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyConsultingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyConsultingEventMutation, { data, loading, error }] = useCopyConsultingEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyConsultingEventMutation(baseOptions?: Apollo.MutationHookOptions<CopyConsultingEventMutation, CopyConsultingEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyConsultingEventMutation, CopyConsultingEventMutationVariables>(CopyConsultingEventDocument, options);
      }
export type CopyConsultingEventMutationHookResult = ReturnType<typeof useCopyConsultingEventMutation>;
export type CopyConsultingEventMutationResult = Apollo.MutationResult<CopyConsultingEventMutation>;
export type CopyConsultingEventMutationOptions = Apollo.BaseMutationOptions<CopyConsultingEventMutation, CopyConsultingEventMutationVariables>;
export const DeleteConsultingEventDocument = gql`
    mutation deleteConsultingEvent($id: UUID!) {
  deleteConsultingEvent(id: $id)
}
    `;
export type DeleteConsultingEventMutationFn = Apollo.MutationFunction<DeleteConsultingEventMutation, DeleteConsultingEventMutationVariables>;
export type DeleteConsultingEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteConsultingEventMutation, DeleteConsultingEventMutationVariables>, 'mutation'>;

    export const DeleteConsultingEventComponent = (props: DeleteConsultingEventComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteConsultingEventMutation, DeleteConsultingEventMutationVariables> mutation={DeleteConsultingEventDocument} {...props} />
    );
    

/**
 * __useDeleteConsultingEventMutation__
 *
 * To run a mutation, you first call `useDeleteConsultingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsultingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsultingEventMutation, { data, loading, error }] = useDeleteConsultingEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConsultingEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsultingEventMutation, DeleteConsultingEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsultingEventMutation, DeleteConsultingEventMutationVariables>(DeleteConsultingEventDocument, options);
      }
export type DeleteConsultingEventMutationHookResult = ReturnType<typeof useDeleteConsultingEventMutation>;
export type DeleteConsultingEventMutationResult = Apollo.MutationResult<DeleteConsultingEventMutation>;
export type DeleteConsultingEventMutationOptions = Apollo.BaseMutationOptions<DeleteConsultingEventMutation, DeleteConsultingEventMutationVariables>;