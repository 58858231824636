import { NavMenu } from '@/ui/nav-menu/nav-menu.component';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { ComponentType, useMemo } from 'react';
import { Frame } from '../frame';
import './orders';
import {
  StatisticsBoardProvider,
  useStatisticsBoards,
} from './shared/board/board.provider';
import { IntroStatsBoard } from './shared/board/intro-stats-board.component';
import { StatsBoard } from './shared/board/stats-board.component';
import { useBoardModals } from './shared/box/modals.hook';

export const Inner: ComponentType = () => {
  const { settings } = useStatisticsBoards();

  const { askCreateBoard, askCreateBoardModal } = useBoardModals();

  const boardLinks = useMemo(() => {
    if (settings) {
      return (
        settings.boards?.map((board) => ({
          label: board.name,
          path: `board-${board.id}`,
        })) || []
      );
    }
    return [];
  }, [settings]);

  return (
    <>
      {askCreateBoardModal}
      <Frame.SubMenu>
        <NavMenu
          type="horizontal"
          items={[
            {
              label: 'Start',
              path: 'home',
            },
            ...boardLinks,
            {
              label: '+',
              onClick: askCreateBoard,
            },
          ]}
        />
      </Frame.SubMenu>
      <Frame.ActionBar></Frame.ActionBar>
      <Router className="router">
        <IntroStatsBoard key="home" path="home" />
        {settings?.boards?.map((board) => (
          <StatsBoard key={`board-${board.name}`} path={`board-${board.id}`} />
        ))}
        {/* <Sales path="sales" /> */}
        <Redirect to="home" from="/" />
      </Router>
    </>
  );
};

export const StatisticsRoutes: ComponentType<RouteComponentProps> = (props) => {
  return (
    <>
      <StatisticsBoardProvider>
        <Inner />
      </StatisticsBoardProvider>
    </>
  );
};
