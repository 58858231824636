import { Frame } from '@/frame';
import { Button } from '@/ui/button/button.component';
import { Checkbox } from '@/ui/checkbox/checkbox.component';
import { Loader } from '@/ui/loader/loader.component';
import { Radio } from '@/ui/radio-button/radio-button.component';
import { RouteComponentProps } from '@reach/router';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AttributesView } from './attributes-view/attributes-view.component';
import {
  PersonWithDuplicatesQuery,
  usePersonWithDuplicatesQuery,
} from './duplicate-resolver.generated';
import styles from './duplicate-resolver.module.scss';
import { MergeState, PersonId } from './types';
import { calculateMaster, extractCandidates } from './utils';

export type PersonDuplicateTypeBase = PersonWithDuplicatesQuery['person'];
export type CheckedStatesType = Record<string, Record<string, any>>;
export type MultipleValuesType = Record<string, Record<string, string[]>>;

export const DuplicateResolver: ComponentType<
  RouteComponentProps<{ id: string }>
> = (props) => {
  const { id } = props;

  const [primaryRecordId, setPrimaryRecordId] = useState<PersonId>();
  const [activeRecordIds, setActiveRecordIds] = useState<PersonId[]>([]);

  const [state, setState] = useState<MergeState>();

  const isInitial = useRef(true);

  const { data, error } = usePersonWithDuplicatesQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });

  const person = data?.person;

  // const createMergeCandidate = (person: MergeCandidate): MergeCandidate => {
  //   const mergeCandidate = {
  //     id: person.id,
  //     createdAt: person.createdAt,
  //     firstName: person.firstName,
  //     lastName: person.lastName,
  //     title: person.title,
  //     gender: person.gender,
  //     address: person.address,
  //     invoiceAddress: person.invoiceAddress,
  //     contactProperties: person.contactProperties,
  //     companies: person.companies,
  //     notes: person.notes,
  //     user: person.user,
  //   };
  //   return mergeCandidate;
  // };

  const candidates = useMemo(() => {
    return extractCandidates(person);
  }, [person]);

  const master = useMemo(() => {
    return calculateMaster(candidates, state);
  }, [candidates, state]);

  const mergePossible = useMemo(
    () => activeRecordIds.length > 1,
    [activeRecordIds],
  );

  const toggleActiveRecord = useCallback(
    (recordId: string, active: boolean) => {
      setActiveRecordIds((recordIds) =>
        active
          ? [...recordIds, recordId]
          : recordIds.filter((id) => id !== recordId),
      );
      if (candidates && !active && primaryRecordId === recordId) {
        setPrimaryRecordId(
          candidates.find(
            (d) => d.id !== recordId && activeRecordIds.includes(d.id),
          )?.id,
        );
      }
    },
    [candidates, primaryRecordId, activeRecordIds],
  );

  // activate all records on mount
  useEffect(() => {
    if (candidates.length > 0 && isInitial.current) {
      isInitial.current = false;
      setActiveRecordIds(candidates.map((c) => c.id));
    }
  }, [candidates]);

  useEffect(() => {
    if (person) {
      setPrimaryRecordId(person.id);
    }
  }, [person]);

  if (!person || !candidates || (candidates && candidates.length === 0)) {
    return <Loader />;
  }

  return (
    <div className={styles.host}>
      <Frame.SubTitle>Duplikat-Resolver</Frame.SubTitle>

      <Frame.ActionBar>
        <Button label={'Zusammenführen'} disabled={!mergePossible} primary />
      </Frame.ActionBar>

      <Frame.Content>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              {candidates.map((record, idx) => (
                <th className={styles.headerCell} key={idx}>
                  <Checkbox
                    label={'Kein Duplikat'}
                    onChange={(v) => toggleActiveRecord(record.id, !v)}
                    noSpacing
                  />
                </th>
              ))}
            </tr>
            <tr>
              <th></th>
              <th>Master Record</th>
              {candidates.map((record, idx) => (
                <th className={styles.headerCell} key={idx}>
                  <Radio
                    label={`Record ${idx + 1}`}
                    checked={
                      record.id === primaryRecordId &&
                      activeRecordIds.includes(record.id)
                    }
                    disabled={!activeRecordIds.includes(record.id)}
                    onChange={(e) => setPrimaryRecordId(e.target.value)}
                    value={record.id}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {person.user?.__typename ? (
              <AttributesView
                candidates={candidates}
                mergeState={state}
                activeRecordIds={activeRecordIds}
                userType={person.user?.__typename}
                primaryRecordId={primaryRecordId}
              />
            ) : (
              <AttributesView
                candidates={candidates}
                mergeState={state}
                activeRecordIds={activeRecordIds}
                primaryRecordId={primaryRecordId}
              />
            )}
          </tbody>
        </table>
      </Frame.Content>
    </div>
  );
};
