import { Button } from '@/ui/button/button.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Frame } from '../../frame';
import { ListView } from '../../ui/list-view/list-view.component';
import { Row } from '../../ui/table/row.component';
import { EnterpriseDataFragment, useEnterprisesQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = usePaginatedUrlQuery();
  const { data, loading } = useEnterprisesQuery({
    variables: { pagination: getPaginationFromQuery(query) },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.Title>Unternehmen</Frame.Title>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="/enterprises/create" label="Neues Unternehmen" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<EnterpriseDataFragment>
          loading={loading}
          data={data?.adminEnterprises?.data || []}
          totalCount={data?.adminEnterprises?.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'ID',
              sortable: false,
            },
            {
              name: 'Slug',
              sortable: false,
            },
            {
              name: 'Admin',
              sortable: false,
            },
          ]}
          renderRow={(item) => (
            <Row key={item.id}>
              <td>
                <Link to={`../${item.id}/edit`}>{item.name}</Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>
                  <Copyable>{item.id}</Copyable>
                </Link>
              </td>
              <td>
                <Link to={`../${item.id}/edit`}>{item.slug}</Link>
              </td>
              <td>{item.isAdmin ? <>Ja</> : <></>}</td>
            </Row>
          )}
        />
      </Frame.Content>
    </>
  );
};
