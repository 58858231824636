import * as Types from '../graphql/generated/types';

import { gql } from '@apollo/client';
import { StatisticsSettingsDataFragmentDoc } from '../statistics-v3/statistics.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ProfileDataFragment = { __typename?: 'Administrator', id: any, username?: string | null, primaryEmail?: string | null, fullName: string, roleIds?: Array<any> | null, uiSettings: { __typename?: 'UiSettings', statistics?: Array<{ __typename?: 'StatisticsSettings', enterpriseId: any, boards: Array<{ __typename?: 'StatisticsBoard', id: string, name: string, boxes: Array<{ __typename?: 'StatisticsBox', id: string, name: string, type: Types.StatisticsBoxType, context: Types.StatisticsBoxContext, options: any }> }> }> | null }, person: { __typename?: 'Person', id: any, firstName?: string | null, lastName?: string | null, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string, courseAttributeSets: Array<string>, logo?: { __typename?: 'FilesystemFile', url: string } | null }> }, selectedEnterprise?: { __typename?: 'Enterprise', id: any, name: string, logo?: { __typename?: 'FilesystemFile', url: string } | null } | null, roles: Array<{ __typename?: 'Role', id: any, name: Types.RoleType }> };

export type CurrentProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentProfileQuery = { __typename?: 'Query', profile: { __typename?: 'Administrator', id: any, username?: string | null, primaryEmail?: string | null, fullName: string, roleIds?: Array<any> | null, uiSettings: { __typename?: 'UiSettings', statistics?: Array<{ __typename?: 'StatisticsSettings', enterpriseId: any, boards: Array<{ __typename?: 'StatisticsBoard', id: string, name: string, boxes: Array<{ __typename?: 'StatisticsBox', id: string, name: string, type: Types.StatisticsBoxType, context: Types.StatisticsBoxContext, options: any }> }> }> | null }, person: { __typename?: 'Person', id: any, firstName?: string | null, lastName?: string | null, enterprises: Array<{ __typename?: 'Enterprise', id: any, name: string, courseAttributeSets: Array<string>, logo?: { __typename?: 'FilesystemFile', url: string } | null }> }, selectedEnterprise?: { __typename?: 'Enterprise', id: any, name: string, logo?: { __typename?: 'FilesystemFile', url: string } | null } | null, roles: Array<{ __typename?: 'Role', id: any, name: Types.RoleType }> } | { __typename?: 'Customer' } | { __typename?: 'Teacher' } };

export const ProfileDataFragmentDoc = gql`
    fragment ProfileData on Administrator {
  id
  username
  primaryEmail
  fullName
  uiSettings {
    statistics {
      ...StatisticsSettingsData
    }
  }
  person {
    id
    firstName
    lastName
    enterprises {
      id
      name
      logo {
        url
      }
      courseAttributeSets
    }
  }
  selectedEnterprise {
    id
    name
    logo {
      url
    }
  }
  roles {
    id
    name
  }
  roleIds
}
    ${StatisticsSettingsDataFragmentDoc}`;
export const CurrentProfileDocument = gql`
    query currentProfile {
  profile {
    ...ProfileData
  }
}
    ${ProfileDataFragmentDoc}`;
export type CurrentProfileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentProfileQuery, CurrentProfileQueryVariables>, 'query'>;

    export const CurrentProfileComponent = (props: CurrentProfileComponentProps) => (
      <ApolloReactComponents.Query<CurrentProfileQuery, CurrentProfileQueryVariables> query={CurrentProfileDocument} {...props} />
    );
    

/**
 * __useCurrentProfileQuery__
 *
 * To run a query within a React component, call `useCurrentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentProfileQuery(baseOptions?: Apollo.QueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, options);
      }
export function useCurrentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, options);
        }
export type CurrentProfileQueryHookResult = ReturnType<typeof useCurrentProfileQuery>;
export type CurrentProfileLazyQueryHookResult = ReturnType<typeof useCurrentProfileLazyQuery>;
export type CurrentProfileQueryResult = Apollo.QueryResult<CurrentProfileQuery, CurrentProfileQueryVariables>;