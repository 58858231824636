import * as Types from '../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CourseModuleDetailDataFragment = {
  __typename?: 'CourseModule';
  id: any;
  status: Types.CourseModuleStatus;
  title?: string | null;
  subTitle?: string | null;
  description?: string | null;
  startDate: any;
  endDate: any;
  dateUncertain: boolean;
  withTime: boolean;
  locationId?: any | null;
  searchableLocationId?: any | null;
  amount: number;
  discountedAmount?: number | null;
  earlyBirdDate?: any | null;
  earlyBirdNotice?: string | null;
  teacherId?: any | null;
  tagIds?: Array<any> | null;
  online: boolean;
  correspondenceCourse: boolean;
  location?: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    address?: { __typename?: 'Address'; city?: string | null } | null;
  } | null;
  searchableLocation?: {
    __typename?: 'Location';
    id: any;
    name: string;
    city?: string | null;
    address?: { __typename?: 'Address'; city?: string | null } | null;
  } | null;
  lateBookingOffset?: {
    __typename?: 'Duration';
    value?: number | null;
    factor?: number | null;
  } | null;
  teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
  tags?: Array<{
    __typename?: 'Tag';
    id: any;
    name: string;
    slug?: string | null;
    context?: string | null;
  }> | null;
  courseUnit: {
    __typename?: 'CourseUnit';
    id: any;
    title?: string | null;
    course: {
      __typename?: 'Course';
      id: any;
      title: string;
      earlyBirdDate?: any | null;
      earlyBirdNotice?: string | null;
      category: { __typename?: 'CourseCategory'; id: any; enterpriseId: any };
    };
  };
};

export type CourseModuleQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type CourseModuleQuery = {
  __typename?: 'Query';
  adminCourseModule: {
    __typename?: 'CourseModule';
    id: any;
    status: Types.CourseModuleStatus;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    startDate: any;
    endDate: any;
    dateUncertain: boolean;
    withTime: boolean;
    locationId?: any | null;
    searchableLocationId?: any | null;
    amount: number;
    discountedAmount?: number | null;
    earlyBirdDate?: any | null;
    earlyBirdNotice?: string | null;
    teacherId?: any | null;
    tagIds?: Array<any> | null;
    online: boolean;
    correspondenceCourse: boolean;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    lateBookingOffset?: {
      __typename?: 'Duration';
      value?: number | null;
      factor?: number | null;
    } | null;
    teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
    }> | null;
    courseUnit: {
      __typename?: 'CourseUnit';
      id: any;
      title?: string | null;
      course: {
        __typename?: 'Course';
        id: any;
        title: string;
        earlyBirdDate?: any | null;
        earlyBirdNotice?: string | null;
        category: { __typename?: 'CourseCategory'; id: any; enterpriseId: any };
      };
    };
  };
};

export type UpdateDetailedCourseModuleMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  input: Types.SaveCourseModuleInput;
}>;

export type UpdateDetailedCourseModuleMutation = {
  __typename?: 'Mutation';
  adminUpdateCourseModule: {
    __typename?: 'CourseModule';
    id: any;
    status: Types.CourseModuleStatus;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    startDate: any;
    endDate: any;
    dateUncertain: boolean;
    withTime: boolean;
    locationId?: any | null;
    searchableLocationId?: any | null;
    amount: number;
    discountedAmount?: number | null;
    earlyBirdDate?: any | null;
    earlyBirdNotice?: string | null;
    teacherId?: any | null;
    tagIds?: Array<any> | null;
    online: boolean;
    correspondenceCourse: boolean;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    lateBookingOffset?: {
      __typename?: 'Duration';
      value?: number | null;
      factor?: number | null;
    } | null;
    teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
    }> | null;
    courseUnit: {
      __typename?: 'CourseUnit';
      id: any;
      title?: string | null;
      course: {
        __typename?: 'Course';
        id: any;
        title: string;
        earlyBirdDate?: any | null;
        earlyBirdNotice?: string | null;
        category: { __typename?: 'CourseCategory'; id: any; enterpriseId: any };
      };
    };
  };
};

export type CreateCourseModuleMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.SaveCourseModuleInput>;
  moduleIdToDuplicate?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;

export type CreateCourseModuleMutation = {
  __typename?: 'Mutation';
  adminCreateCourseModule: {
    __typename?: 'CourseModule';
    id: any;
    status: Types.CourseModuleStatus;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    startDate: any;
    endDate: any;
    dateUncertain: boolean;
    withTime: boolean;
    locationId?: any | null;
    searchableLocationId?: any | null;
    amount: number;
    discountedAmount?: number | null;
    earlyBirdDate?: any | null;
    earlyBirdNotice?: string | null;
    teacherId?: any | null;
    tagIds?: Array<any> | null;
    online: boolean;
    correspondenceCourse: boolean;
    location?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    searchableLocation?: {
      __typename?: 'Location';
      id: any;
      name: string;
      city?: string | null;
      address?: { __typename?: 'Address'; city?: string | null } | null;
    } | null;
    lateBookingOffset?: {
      __typename?: 'Duration';
      value?: number | null;
      factor?: number | null;
    } | null;
    teacher?: { __typename?: 'Teacher'; id: any; fullName: string } | null;
    tags?: Array<{
      __typename?: 'Tag';
      id: any;
      name: string;
      slug?: string | null;
      context?: string | null;
    }> | null;
    courseUnit: {
      __typename?: 'CourseUnit';
      id: any;
      title?: string | null;
      course: {
        __typename?: 'Course';
        id: any;
        title: string;
        earlyBirdDate?: any | null;
        earlyBirdNotice?: string | null;
        category: { __typename?: 'CourseCategory'; id: any; enterpriseId: any };
      };
    };
  };
};

export type DeleteCourseModuleMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type DeleteCourseModuleMutation = {
  __typename?: 'Mutation';
  adminDeleteCourseModule: boolean;
};

export const CourseModuleDetailDataFragmentDoc = gql`
  fragment CourseModuleDetailData on CourseModule {
    id
    status
    title
    subTitle
    description
    startDate
    endDate
    dateUncertain
    withTime
    locationId
    location {
      id
      name
      city
      address {
        city
      }
    }
    searchableLocation {
      id
      name
      city
      address {
        city
      }
    }
    searchableLocationId
    amount
    discountedAmount
    earlyBirdDate
    earlyBirdNotice
    lateBookingOffset {
      value
      factor
    }
    teacher {
      id
      fullName
    }
    teacherId
    tags {
      id
      name
      slug
      context
    }
    tagIds
    online
    correspondenceCourse
    courseUnit {
      id
      title
      course {
        id
        title
        earlyBirdDate
        earlyBirdNotice
        category {
          id
          enterpriseId
        }
      }
    }
  }
`;
export const CourseModuleDocument = gql`
  query courseModule($id: UUID!) {
    adminCourseModule(id: $id) {
      ...CourseModuleDetailData
    }
  }
  ${CourseModuleDetailDataFragmentDoc}
`;
export type CourseModuleComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CourseModuleQuery,
    CourseModuleQueryVariables
  >,
  'query'
> &
  (
    | { variables: CourseModuleQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const CourseModuleComponent = (props: CourseModuleComponentProps) => (
  <ApolloReactComponents.Query<CourseModuleQuery, CourseModuleQueryVariables>
    query={CourseModuleDocument}
    {...props}
  />
);

/**
 * __useCourseModuleQuery__
 *
 * To run a query within a React component, call `useCourseModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseModuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseModuleQuery,
    CourseModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseModuleQuery, CourseModuleQueryVariables>(
    CourseModuleDocument,
    options,
  );
}
export function useCourseModuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseModuleQuery,
    CourseModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseModuleQuery, CourseModuleQueryVariables>(
    CourseModuleDocument,
    options,
  );
}
export type CourseModuleQueryHookResult = ReturnType<
  typeof useCourseModuleQuery
>;
export type CourseModuleLazyQueryHookResult = ReturnType<
  typeof useCourseModuleLazyQuery
>;
export type CourseModuleQueryResult = Apollo.QueryResult<
  CourseModuleQuery,
  CourseModuleQueryVariables
>;
export const UpdateDetailedCourseModuleDocument = gql`
  mutation updateDetailedCourseModule(
    $id: UUID!
    $input: SaveCourseModuleInput!
  ) {
    adminUpdateCourseModule(id: $id, input: $input) {
      ...CourseModuleDetailData
    }
  }
  ${CourseModuleDetailDataFragmentDoc}
`;
export type UpdateDetailedCourseModuleMutationFn = Apollo.MutationFunction<
  UpdateDetailedCourseModuleMutation,
  UpdateDetailedCourseModuleMutationVariables
>;
export type UpdateDetailedCourseModuleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateDetailedCourseModuleMutation,
    UpdateDetailedCourseModuleMutationVariables
  >,
  'mutation'
>;

export const UpdateDetailedCourseModuleComponent = (
  props: UpdateDetailedCourseModuleComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateDetailedCourseModuleMutation,
    UpdateDetailedCourseModuleMutationVariables
  >
    mutation={UpdateDetailedCourseModuleDocument}
    {...props}
  />
);

/**
 * __useUpdateDetailedCourseModuleMutation__
 *
 * To run a mutation, you first call `useUpdateDetailedCourseModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDetailedCourseModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDetailedCourseModuleMutation, { data, loading, error }] = useUpdateDetailedCourseModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDetailedCourseModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDetailedCourseModuleMutation,
    UpdateDetailedCourseModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDetailedCourseModuleMutation,
    UpdateDetailedCourseModuleMutationVariables
  >(UpdateDetailedCourseModuleDocument, options);
}
export type UpdateDetailedCourseModuleMutationHookResult = ReturnType<
  typeof useUpdateDetailedCourseModuleMutation
>;
export type UpdateDetailedCourseModuleMutationResult =
  Apollo.MutationResult<UpdateDetailedCourseModuleMutation>;
export type UpdateDetailedCourseModuleMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDetailedCourseModuleMutation,
    UpdateDetailedCourseModuleMutationVariables
  >;
export const CreateCourseModuleDocument = gql`
  mutation createCourseModule(
    $input: SaveCourseModuleInput
    $moduleIdToDuplicate: UUID
  ) {
    adminCreateCourseModule(
      input: $input
      moduleIdToDuplicate: $moduleIdToDuplicate
    ) {
      ...CourseModuleDetailData
    }
  }
  ${CourseModuleDetailDataFragmentDoc}
`;
export type CreateCourseModuleMutationFn = Apollo.MutationFunction<
  CreateCourseModuleMutation,
  CreateCourseModuleMutationVariables
>;
export type CreateCourseModuleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCourseModuleMutation,
    CreateCourseModuleMutationVariables
  >,
  'mutation'
>;

export const CreateCourseModuleComponent = (
  props: CreateCourseModuleComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateCourseModuleMutation,
    CreateCourseModuleMutationVariables
  >
    mutation={CreateCourseModuleDocument}
    {...props}
  />
);

/**
 * __useCreateCourseModuleMutation__
 *
 * To run a mutation, you first call `useCreateCourseModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseModuleMutation, { data, loading, error }] = useCreateCourseModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      moduleIdToDuplicate: // value for 'moduleIdToDuplicate'
 *   },
 * });
 */
export function useCreateCourseModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseModuleMutation,
    CreateCourseModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCourseModuleMutation,
    CreateCourseModuleMutationVariables
  >(CreateCourseModuleDocument, options);
}
export type CreateCourseModuleMutationHookResult = ReturnType<
  typeof useCreateCourseModuleMutation
>;
export type CreateCourseModuleMutationResult =
  Apollo.MutationResult<CreateCourseModuleMutation>;
export type CreateCourseModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseModuleMutation,
  CreateCourseModuleMutationVariables
>;
export const DeleteCourseModuleDocument = gql`
  mutation deleteCourseModule($id: UUID!) {
    adminDeleteCourseModule(id: $id)
  }
`;
export type DeleteCourseModuleMutationFn = Apollo.MutationFunction<
  DeleteCourseModuleMutation,
  DeleteCourseModuleMutationVariables
>;
export type DeleteCourseModuleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCourseModuleMutation,
    DeleteCourseModuleMutationVariables
  >,
  'mutation'
>;

export const DeleteCourseModuleComponent = (
  props: DeleteCourseModuleComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    DeleteCourseModuleMutation,
    DeleteCourseModuleMutationVariables
  >
    mutation={DeleteCourseModuleDocument}
    {...props}
  />
);

/**
 * __useDeleteCourseModuleMutation__
 *
 * To run a mutation, you first call `useDeleteCourseModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseModuleMutation, { data, loading, error }] = useDeleteCourseModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCourseModuleMutation,
    DeleteCourseModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCourseModuleMutation,
    DeleteCourseModuleMutationVariables
  >(DeleteCourseModuleDocument, options);
}
export type DeleteCourseModuleMutationHookResult = ReturnType<
  typeof useDeleteCourseModuleMutation
>;
export type DeleteCourseModuleMutationResult =
  Apollo.MutationResult<DeleteCourseModuleMutation>;
export type DeleteCourseModuleMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseModuleMutation,
  DeleteCourseModuleMutationVariables
>;
