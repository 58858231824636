import { Box } from '@/ui/box/box.component';
import { Button } from '@/ui/button/button.component';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { useRoomManagement } from '../../room-management-context';
import {
  RoomEventDataFragment,
  useUpdateEventMutation,
} from '../../room-management.generated';

interface MiniEditorProps {
  event?: RoomEventDataFragment | true;
  onClose: () => void;
}

export const MiniEditor: ComponentType<MiniEditorProps> = (props) => {
  const { event, onClose } = props;

  const { reload } = useRoomManagement();
  const [localEvent, setLocalEvent] = useState<Partial<RoomEventDataFragment>>(
    event instanceof Object ? event : {},
  );

  const [update] = useUpdateEventMutation();

  const onSubmit = useCallback(async () => {
    if (localEvent.id) {
      await update({
        variables: {
          id: localEvent.id,
          input: {
            startDate: localEvent.startDate,
            endDate: localEvent.endDate,
            roomId: localEvent.roomId,
          },
        },
      });
      reload();
    }
  }, [localEvent, reload]);

  useEffect(() => {
    if (event === true) {
      setLocalEvent({});
    } else if (event !== undefined) {
      setLocalEvent(event);
    }
  }, [event]);

  if (!event) {
    return null;
  }

  return (
    <Box.Floating
      onClose={onClose}
      title="Event"
      position={{ x: 200, y: 200 }}
      bounds=".room-management-working-area"
    >
      <DatetimeField
        withTime
        label="Start"
        value={localEvent.startDate}
        onChange={(date) =>
          setLocalEvent((event) => ({ ...event, startDate: date }))
        }
      />
      <DatetimeField
        withTime
        label="Start"
        value={localEvent.endDate}
        onChange={(date) =>
          setLocalEvent((event) => ({ ...event, endDate: date }))
        }
      />
      <Button primary label="Aktualisieren" onClick={onSubmit} />
    </Box.Floating>
  );
};
