import classNames from 'classnames';
import React, {
  ComponentType,
  CSSProperties,
  ReactNodeArray,
  useMemo,
} from 'react';
import styles from './grid.module.scss';

interface MasonryProps {
  className?: string;
  cells: ReactNodeArray;
  columns: number;
}
const Masonry: ComponentType<MasonryProps> = (props) => {
  const { className, cells, columns } = props;

  const cellsPerColumn = useMemo(() => {
    if (columns === 0) {
      return 0;
    }
    return cells.length / columns;
  }, [cells, columns]);

  const columnComponents = useMemo(() => {
    const columnComponents: JSX.Element[] = [];
    for (let i = 0; i < columns; i++) {
      const component = (
        <Grid.Column key={`column-${i}`}>
          {cells.filter(
            (_, index) =>
              index < (i + 1) * cellsPerColumn && index >= i * cellsPerColumn,
          )}
        </Grid.Column>
      );
      columnComponents.push(component);
    }

    return columnComponents;
  }, [cells, cellsPerColumn, columns]);

  return (
    <div className={classNames(styles.row, className, 'row')}>
      {columnComponents}
    </div>
  );
};

interface CellProps {
  className?: string;
}
const Cell: ComponentType<CellProps> = (props) => {
  const { children, className } = props;
  return (
    <div className={classNames(styles.cell, className, 'cell')}>{children}</div>
  );
};

interface RowProps {
  className?: string;
  fullHeight?: boolean;
}
const Row: ComponentType<RowProps> = (props) => {
  const { children, className, fullHeight } = props;
  return (
    <div
      className={classNames(
        styles.row,
        fullHeight && styles.fullHeight,
        className,
        'row',
      )}
    >
      {children}
    </div>
  );
};

interface ColumnProps {
  className?: string;
  bigger?: boolean;
  smaller?: boolean;
  style?: CSSProperties;
  oneFourth?: boolean;
  threeFourths?: boolean;
}

const Column: ComponentType<ColumnProps> = (props) => {
  const {
    children,
    bigger,
    smaller,
    className,
    style,
    oneFourth,
    threeFourths,
  } = props;
  return (
    <div
      className={classNames(
        'column',
        styles.column,
        bigger && styles.bigger,
        smaller && styles.smaller,
        oneFourth && styles.oneFourth,
        threeFourths && styles.threeFourths,
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export const Grid = { Row, Column, Cell, Masonry };
