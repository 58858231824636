import { Chip } from '@/ui/chip/chip.component';
import { ToolTip } from '@/ui/tool-tip/tool-tip.component';
import { Link } from '@reach/router';
import React, { ComponentType } from 'react';

interface DuplicatesCellProps {
  duplicates: any[];
  linkTo: string;
}

export const DuplicatesCell: ComponentType<DuplicatesCellProps> = (props) => {
  const { duplicates, linkTo } = props;
  if (duplicates.length > 0) {
    return (
      <>
        <ToolTip
          multiline
          title="Mögliche Duplikate gefunden"
          content={
            <>
              <ul>
                {duplicates[0].reasons.map((r, index) => (
                  <li key={index}>{r}</li>
                ))}
              </ul>
              <br />
              <i>Klicken, um Duplikate aufzulösen</i>
            </>
          }
        >
          <Link to={linkTo}>
            <Chip meaning={'warning'}>D</Chip>
          </Link>
        </ToolTip>
      </>
    );
  }
  return null;
};
