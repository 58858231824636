import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { DiscountRuleDataFragmentDoc } from '../../discount.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DiscountRuleQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type DiscountRuleQuery = {
  __typename?: 'Query';
  discountRule: {
    __typename?: 'DiscountRule';
    id: any;
    title?: string | null;
    description?: string | null;
    status: Types.DiscountRuleStatus;
    amount: number;
    type: Types.DiscountType;
    voucherCode?: string | null;
    useCheckbox: boolean;
    applyToWholeCart: boolean;
    dependencyRelation: Types.DiscountRuleDependencyRelation;
    enterpriseId: any;
    dependencies: {
      __typename?: 'DiscountRuleDependencies';
      courseIds?: Array<string> | null;
      courseUnitIds?: Array<string> | null;
      courseModuleIds?: Array<string> | null;
      productIds?: Array<string> | null;
      categoryIds?: Array<string> | null;
      products: Array<
        | { __typename?: 'LocationProduct'; id: any; title: string }
        | { __typename?: 'SimpleProduct'; id: any; title: string }
      >;
      courses: Array<{
        __typename?: 'Course';
        id: any;
        title: string;
        verboseTitle?: string | null;
      }>;
      courseUnits: Array<{
        __typename?: 'CourseUnit';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        withTime: boolean;
        dateUncertain: boolean;
        correspondenceCourse: boolean;
        online: boolean;
        location?: { __typename?: 'Location'; commonName: string } | null;
        searchableLocation?: {
          __typename?: 'Location';
          commonName: string;
        } | null;
        course: { __typename?: 'Course'; id: any; title: string };
      }>;
      courseModules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        withTime: boolean;
        dateUncertain: boolean;
        correspondenceCourse: boolean;
        online: boolean;
        location?: { __typename?: 'Location'; commonName: string } | null;
        searchableLocation?: {
          __typename?: 'Location';
          commonName: string;
        } | null;
        courseUnit: {
          __typename?: 'CourseUnit';
          id: any;
          course: { __typename?: 'Course'; id: any; title: string };
        };
      }>;
    };
    excludedItems: {
      __typename?: 'DiscountRuleDependencies';
      courseIds?: Array<string> | null;
      courseUnitIds?: Array<string> | null;
      courseModuleIds?: Array<string> | null;
      productIds?: Array<string> | null;
      categoryIds?: Array<string> | null;
      products: Array<
        | { __typename?: 'LocationProduct'; id: any; title: string }
        | { __typename?: 'SimpleProduct'; id: any; title: string }
      >;
      courses: Array<{
        __typename?: 'Course';
        id: any;
        title: string;
        verboseTitle?: string | null;
      }>;
      courseUnits: Array<{
        __typename?: 'CourseUnit';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        withTime: boolean;
        dateUncertain: boolean;
        correspondenceCourse: boolean;
        online: boolean;
        location?: { __typename?: 'Location'; commonName: string } | null;
        searchableLocation?: {
          __typename?: 'Location';
          commonName: string;
        } | null;
        course: { __typename?: 'Course'; id: any; title: string };
      }>;
      courseModules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        withTime: boolean;
        dateUncertain: boolean;
        correspondenceCourse: boolean;
        online: boolean;
        location?: { __typename?: 'Location'; commonName: string } | null;
        searchableLocation?: {
          __typename?: 'Location';
          commonName: string;
        } | null;
        courseUnit: {
          __typename?: 'CourseUnit';
          id: any;
          course: { __typename?: 'Course'; id: any; title: string };
        };
      }>;
    };
    enterprise: { __typename?: 'Enterprise'; id: any; name: string };
  };
};

export type AvailableCoursesForDiscountsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AvailableCoursesForDiscountsQuery = {
  __typename?: 'Query';
  adminCourses: {
    __typename?: 'PaginatedCourseResult';
    data: Array<{
      __typename?: 'Course';
      id: any;
      title: string;
      verboseTitle?: string | null;
    }>;
  };
};

export type AvailableCourseUnitsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AvailableCourseUnitsQuery = {
  __typename?: 'Query';
  adminCourseUnits: Array<{
    __typename?: 'CourseUnit';
    id: any;
    title?: string | null;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    correspondenceCourse: boolean;
    online: boolean;
    location?: { __typename?: 'Location'; commonName: string } | null;
    searchableLocation?: { __typename?: 'Location'; commonName: string } | null;
    course: { __typename?: 'Course'; id: any; title: string };
  }>;
};

export type AvailableCourseModulesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AvailableCourseModulesQuery = {
  __typename?: 'Query';
  adminCourseModules: Array<{
    __typename?: 'CourseModule';
    id: any;
    title?: string | null;
    startDate: any;
    endDate: any;
    withTime: boolean;
    dateUncertain: boolean;
    correspondenceCourse: boolean;
    online: boolean;
    location?: { __typename?: 'Location'; commonName: string } | null;
    searchableLocation?: { __typename?: 'Location'; commonName: string } | null;
    courseUnit: {
      __typename?: 'CourseUnit';
      id: any;
      course: { __typename?: 'Course'; id: any; title: string };
    };
  }>;
};

export type SaveDiscountRuleMutationVariables = Types.Exact<{
  input: Types.SaveDiscountRuleInput;
  id?: Types.InputMaybe<Types.Scalars['UUID']>;
  force?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type SaveDiscountRuleMutation = {
  __typename?: 'Mutation';
  saveDiscountRule: {
    __typename?: 'DiscountRule';
    id: any;
    title?: string | null;
    description?: string | null;
    status: Types.DiscountRuleStatus;
    amount: number;
    type: Types.DiscountType;
    voucherCode?: string | null;
    useCheckbox: boolean;
    applyToWholeCart: boolean;
    dependencyRelation: Types.DiscountRuleDependencyRelation;
    enterpriseId: any;
    dependencies: {
      __typename?: 'DiscountRuleDependencies';
      courseIds?: Array<string> | null;
      courseUnitIds?: Array<string> | null;
      courseModuleIds?: Array<string> | null;
      productIds?: Array<string> | null;
      categoryIds?: Array<string> | null;
      products: Array<
        | { __typename?: 'LocationProduct'; id: any; title: string }
        | { __typename?: 'SimpleProduct'; id: any; title: string }
      >;
      courses: Array<{
        __typename?: 'Course';
        id: any;
        title: string;
        verboseTitle?: string | null;
      }>;
      courseUnits: Array<{
        __typename?: 'CourseUnit';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        withTime: boolean;
        dateUncertain: boolean;
        correspondenceCourse: boolean;
        online: boolean;
        location?: { __typename?: 'Location'; commonName: string } | null;
        searchableLocation?: {
          __typename?: 'Location';
          commonName: string;
        } | null;
        course: { __typename?: 'Course'; id: any; title: string };
      }>;
      courseModules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        withTime: boolean;
        dateUncertain: boolean;
        correspondenceCourse: boolean;
        online: boolean;
        location?: { __typename?: 'Location'; commonName: string } | null;
        searchableLocation?: {
          __typename?: 'Location';
          commonName: string;
        } | null;
        courseUnit: {
          __typename?: 'CourseUnit';
          id: any;
          course: { __typename?: 'Course'; id: any; title: string };
        };
      }>;
    };
    excludedItems: {
      __typename?: 'DiscountRuleDependencies';
      courseIds?: Array<string> | null;
      courseUnitIds?: Array<string> | null;
      courseModuleIds?: Array<string> | null;
      productIds?: Array<string> | null;
      categoryIds?: Array<string> | null;
      products: Array<
        | { __typename?: 'LocationProduct'; id: any; title: string }
        | { __typename?: 'SimpleProduct'; id: any; title: string }
      >;
      courses: Array<{
        __typename?: 'Course';
        id: any;
        title: string;
        verboseTitle?: string | null;
      }>;
      courseUnits: Array<{
        __typename?: 'CourseUnit';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        withTime: boolean;
        dateUncertain: boolean;
        correspondenceCourse: boolean;
        online: boolean;
        location?: { __typename?: 'Location'; commonName: string } | null;
        searchableLocation?: {
          __typename?: 'Location';
          commonName: string;
        } | null;
        course: { __typename?: 'Course'; id: any; title: string };
      }>;
      courseModules: Array<{
        __typename?: 'CourseModule';
        id: any;
        title?: string | null;
        startDate: any;
        endDate: any;
        withTime: boolean;
        dateUncertain: boolean;
        correspondenceCourse: boolean;
        online: boolean;
        location?: { __typename?: 'Location'; commonName: string } | null;
        searchableLocation?: {
          __typename?: 'Location';
          commonName: string;
        } | null;
        courseUnit: {
          __typename?: 'CourseUnit';
          id: any;
          course: { __typename?: 'Course'; id: any; title: string };
        };
      }>;
    };
    enterprise: { __typename?: 'Enterprise'; id: any; name: string };
  };
};

export const DiscountRuleDocument = gql`
  query discountRule($id: UUID!) {
    discountRule(id: $id) {
      ...DiscountRuleData
    }
  }
  ${DiscountRuleDataFragmentDoc}
`;
export type DiscountRuleComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DiscountRuleQuery,
    DiscountRuleQueryVariables
  >,
  'query'
> &
  (
    | { variables: DiscountRuleQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const DiscountRuleComponent = (props: DiscountRuleComponentProps) => (
  <ApolloReactComponents.Query<DiscountRuleQuery, DiscountRuleQueryVariables>
    query={DiscountRuleDocument}
    {...props}
  />
);

/**
 * __useDiscountRuleQuery__
 *
 * To run a query within a React component, call `useDiscountRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountRuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscountRuleQuery,
    DiscountRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscountRuleQuery, DiscountRuleQueryVariables>(
    DiscountRuleDocument,
    options,
  );
}
export function useDiscountRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscountRuleQuery,
    DiscountRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscountRuleQuery, DiscountRuleQueryVariables>(
    DiscountRuleDocument,
    options,
  );
}
export type DiscountRuleQueryHookResult = ReturnType<
  typeof useDiscountRuleQuery
>;
export type DiscountRuleLazyQueryHookResult = ReturnType<
  typeof useDiscountRuleLazyQuery
>;
export type DiscountRuleQueryResult = Apollo.QueryResult<
  DiscountRuleQuery,
  DiscountRuleQueryVariables
>;
export const AvailableCoursesForDiscountsDocument = gql`
  query availableCoursesForDiscounts {
    adminCourses(pagination: { take: 999, skip: 0 }) {
      data {
        id
        title
        verboseTitle
      }
    }
  }
`;
export type AvailableCoursesForDiscountsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AvailableCoursesForDiscountsQuery,
    AvailableCoursesForDiscountsQueryVariables
  >,
  'query'
>;

export const AvailableCoursesForDiscountsComponent = (
  props: AvailableCoursesForDiscountsComponentProps,
) => (
  <ApolloReactComponents.Query<
    AvailableCoursesForDiscountsQuery,
    AvailableCoursesForDiscountsQueryVariables
  >
    query={AvailableCoursesForDiscountsDocument}
    {...props}
  />
);

/**
 * __useAvailableCoursesForDiscountsQuery__
 *
 * To run a query within a React component, call `useAvailableCoursesForDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoursesForDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoursesForDiscountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCoursesForDiscountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableCoursesForDiscountsQuery,
    AvailableCoursesForDiscountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableCoursesForDiscountsQuery,
    AvailableCoursesForDiscountsQueryVariables
  >(AvailableCoursesForDiscountsDocument, options);
}
export function useAvailableCoursesForDiscountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCoursesForDiscountsQuery,
    AvailableCoursesForDiscountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCoursesForDiscountsQuery,
    AvailableCoursesForDiscountsQueryVariables
  >(AvailableCoursesForDiscountsDocument, options);
}
export type AvailableCoursesForDiscountsQueryHookResult = ReturnType<
  typeof useAvailableCoursesForDiscountsQuery
>;
export type AvailableCoursesForDiscountsLazyQueryHookResult = ReturnType<
  typeof useAvailableCoursesForDiscountsLazyQuery
>;
export type AvailableCoursesForDiscountsQueryResult = Apollo.QueryResult<
  AvailableCoursesForDiscountsQuery,
  AvailableCoursesForDiscountsQueryVariables
>;
export const AvailableCourseUnitsDocument = gql`
  query availableCourseUnits {
    adminCourseUnits {
      id
      title
      startDate
      endDate
      withTime
      dateUncertain
      correspondenceCourse
      online
      location {
        commonName
      }
      searchableLocation {
        commonName
      }
      course {
        id
        title
      }
    }
  }
`;
export type AvailableCourseUnitsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AvailableCourseUnitsQuery,
    AvailableCourseUnitsQueryVariables
  >,
  'query'
>;

export const AvailableCourseUnitsComponent = (
  props: AvailableCourseUnitsComponentProps,
) => (
  <ApolloReactComponents.Query<
    AvailableCourseUnitsQuery,
    AvailableCourseUnitsQueryVariables
  >
    query={AvailableCourseUnitsDocument}
    {...props}
  />
);

/**
 * __useAvailableCourseUnitsQuery__
 *
 * To run a query within a React component, call `useAvailableCourseUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCourseUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCourseUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCourseUnitsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableCourseUnitsQuery,
    AvailableCourseUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableCourseUnitsQuery,
    AvailableCourseUnitsQueryVariables
  >(AvailableCourseUnitsDocument, options);
}
export function useAvailableCourseUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCourseUnitsQuery,
    AvailableCourseUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCourseUnitsQuery,
    AvailableCourseUnitsQueryVariables
  >(AvailableCourseUnitsDocument, options);
}
export type AvailableCourseUnitsQueryHookResult = ReturnType<
  typeof useAvailableCourseUnitsQuery
>;
export type AvailableCourseUnitsLazyQueryHookResult = ReturnType<
  typeof useAvailableCourseUnitsLazyQuery
>;
export type AvailableCourseUnitsQueryResult = Apollo.QueryResult<
  AvailableCourseUnitsQuery,
  AvailableCourseUnitsQueryVariables
>;
export const AvailableCourseModulesDocument = gql`
  query availableCourseModules {
    adminCourseModules {
      id
      title
      startDate
      endDate
      withTime
      dateUncertain
      correspondenceCourse
      online
      location {
        commonName
      }
      searchableLocation {
        commonName
      }
      courseUnit {
        id
        course {
          id
          title
        }
      }
    }
  }
`;
export type AvailableCourseModulesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AvailableCourseModulesQuery,
    AvailableCourseModulesQueryVariables
  >,
  'query'
>;

export const AvailableCourseModulesComponent = (
  props: AvailableCourseModulesComponentProps,
) => (
  <ApolloReactComponents.Query<
    AvailableCourseModulesQuery,
    AvailableCourseModulesQueryVariables
  >
    query={AvailableCourseModulesDocument}
    {...props}
  />
);

/**
 * __useAvailableCourseModulesQuery__
 *
 * To run a query within a React component, call `useAvailableCourseModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCourseModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCourseModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCourseModulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableCourseModulesQuery,
    AvailableCourseModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableCourseModulesQuery,
    AvailableCourseModulesQueryVariables
  >(AvailableCourseModulesDocument, options);
}
export function useAvailableCourseModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCourseModulesQuery,
    AvailableCourseModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCourseModulesQuery,
    AvailableCourseModulesQueryVariables
  >(AvailableCourseModulesDocument, options);
}
export type AvailableCourseModulesQueryHookResult = ReturnType<
  typeof useAvailableCourseModulesQuery
>;
export type AvailableCourseModulesLazyQueryHookResult = ReturnType<
  typeof useAvailableCourseModulesLazyQuery
>;
export type AvailableCourseModulesQueryResult = Apollo.QueryResult<
  AvailableCourseModulesQuery,
  AvailableCourseModulesQueryVariables
>;
export const SaveDiscountRuleDocument = gql`
  mutation saveDiscountRule(
    $input: SaveDiscountRuleInput!
    $id: UUID
    $force: Boolean
  ) {
    saveDiscountRule(input: $input, id: $id, force: $force) {
      ...DiscountRuleData
    }
  }
  ${DiscountRuleDataFragmentDoc}
`;
export type SaveDiscountRuleMutationFn = Apollo.MutationFunction<
  SaveDiscountRuleMutation,
  SaveDiscountRuleMutationVariables
>;
export type SaveDiscountRuleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SaveDiscountRuleMutation,
    SaveDiscountRuleMutationVariables
  >,
  'mutation'
>;

export const SaveDiscountRuleComponent = (
  props: SaveDiscountRuleComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    SaveDiscountRuleMutation,
    SaveDiscountRuleMutationVariables
  >
    mutation={SaveDiscountRuleDocument}
    {...props}
  />
);

/**
 * __useSaveDiscountRuleMutation__
 *
 * To run a mutation, you first call `useSaveDiscountRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDiscountRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDiscountRuleMutation, { data, loading, error }] = useSaveDiscountRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useSaveDiscountRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveDiscountRuleMutation,
    SaveDiscountRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveDiscountRuleMutation,
    SaveDiscountRuleMutationVariables
  >(SaveDiscountRuleDocument, options);
}
export type SaveDiscountRuleMutationHookResult = ReturnType<
  typeof useSaveDiscountRuleMutation
>;
export type SaveDiscountRuleMutationResult =
  Apollo.MutationResult<SaveDiscountRuleMutation>;
export type SaveDiscountRuleMutationOptions = Apollo.BaseMutationOptions<
  SaveDiscountRuleMutation,
  SaveDiscountRuleMutationVariables
>;
