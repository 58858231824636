import placeholder from '@/assets/image/icons/picture.svg';
import { useExplorer } from '@/file/explorer/explorer.context';
import { FileGroup } from '@/graphql/generated/types';
import globalStyles from '@/styles/global.scss';
import { default as classNames, default as classnames } from 'classnames';
import React, {
  ChangeEvent,
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Button } from '../button/button.component';
import { InfoBox } from '../info-box/info-box.component';
import { Loader } from '../loader/loader.component';
import styles from './image-chooser.module.scss';

interface Props {
  currentImageUrl?: string;
  loading?: boolean;
  onChange: (file?: File) => void;
  onSelect?: (
    selected: { id: string; url: string; name: string } | null,
  ) => void;
  error?: string;
  label?: string;
  disabled?: boolean;
  explorerFilter?: {
    group: FileGroup;
  };
}

export const ImageChooser: ComponentType<Props> = (props) => {
  const {
    explorerFilter,
    onChange,
    currentImageUrl,
    loading,
    error,
    label,
    disabled,
    onSelect,
  } = props;

  const onSelectCallback = useCallback(
    (selected: { id: string; name: string; url: string } | null) => {
      if (onSelect) {
        onSelect(selected);
        setCurrentImageUrlLocal(selected?.url);
      }
    },
    [onSelect],
  );

  const { open } = useExplorer();

  const [currentImageUrlLocal, setCurrentImageUrlLocal] = useState<string>();
  const [active, setActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const readerRef = useRef(new FileReader());
  const onMouseEnter = useCallback(() => {
    if (!disabled) {
      setActive(true);
    }
  }, [disabled]);
  const onMouseLeave = useCallback(() => setActive(false), []);
  const onDeleteClick = useCallback(() => {
    onSelectCallback(null);
  }, [onSelectCallback]);

  const onFileChange = useCallback(
    ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (files && files.length > 0) {
        onChange(files.item(0) as File);
      } else {
        onChange(undefined);
      }
    },
    [onChange],
  );

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (monitor) {
        const files = (monitor.getItem() as any).files;

        if (files.length > 0 && !disabled) {
          onChange(files.item(0) as File);
        }
      }
    },
    collect: (monitor) => ({
      isOver: !disabled && monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    setCurrentImageUrlLocal(currentImageUrl);
  }, [currentImageUrl]);

  const content = useMemo(() => {
    if (loading) {
      return <Loader header="Upload ..." />;
    }

    if (disabled) {
      return null;
    }

    if (isOver && canDrop) {
      return (
        <InfoBox
          header="Datei hochladen"
          content="Datei hier ablegen um hochzuladen"
        />
      );
    }

    return (
      <div className={styles.actions}>
        <Button
          disabled={currentImageUrlLocal === undefined}
          onClick={onDeleteClick}
          label="Entfernen"
          warning
        />
        <Button
          primary
          disabled={loading}
          onClick={() =>
            open({
              filter: { group: explorerFilter?.group || null },
              onSelect: onSelectCallback,
              withUpload: false,
            })
          }
          label="Auswählen"
        />
        <Button
          primary
          disabled={loading}
          label="Hochladen"
          onClick={() => inputRef.current?.click()}
        />
      </div>
    );
  }, [loading, currentImageUrlLocal, canDrop, isOver, disabled]);

  return (
    <div className={styles.host}>
      {label && <label>{label}</label>}
      <div
        className={styles.imageWrapper}
        ref={drop}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={onFileChange}
        />

        <img
          className={classNames(
            styles.currentImage,
            !currentImageUrlLocal && styles.placeholder,
          )}
          src={currentImageUrlLocal || placeholder}
        />
        <div
          className={classnames(
            styles.overlay,
            (active || loading || (canDrop && isOver)) && styles.active,
          )}
        >
          {content}
        </div>
      </div>
      {error && <div className={globalStyles.error}>{error}</div>}
    </div>
  );
};
