import { Chip } from '@/ui/chip/chip.component';
import React, { ComponentType } from 'react';
import { TagDataFragment } from '../list/list.generated';

interface TagChipProps {
  tag: TagDataFragment;
}

export const TagChip: ComponentType<TagChipProps> = (props) => {
  const { tag } = props;
  return (
    <Chip>
      {tag.context}&nbsp;<em>{tag.name}</em>
    </Chip>
  );
};
