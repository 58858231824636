import { Frame } from '@/frame';
import {
  CourseUnitStatus,
  PackageStatus,
  SavePackageInput,
} from '@/graphql/generated/types';
import globalStyles from '@/styles/global.scss';
import { useAdminTagsQuery } from '@/tag/list/list.generated';
import { Button } from '@/ui/button/button.component';
import { Chip } from '@/ui/chip/chip.component';
import { Copyable } from '@/ui/copyable/copyable.component';
import { Form, FormError } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { Row } from '@/ui/table/row.component';
import { Table } from '@/ui/table/table.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { getCourseUnitDates } from 'shared/course/utils';
import { useResources } from 'shared/resources/resources.provider';
import { array, number, object, string } from 'yup';
import { PackageFragment } from '../../list/list.generated';
import { CourseUnitPicker } from './course-unit-picker/course-unit-picker.component';
import {
  useCreatePackageMutation,
  useDeletePackageMutation,
  usePackageQuery,
  useUpdatePackageMutation,
} from './editor.generated';
import { TagsList } from './tags-list.component';

const schema = object({
  title: string().min(1),
  subTitle: string().nullable(),
  abbreviation: string().required(FormError.required),
  status: string().required(),
  description: string().nullable(),
  amount: number().nullable(),
  courseUnits: array()
    .of(object({ id: string().uuid() }))
    .default([]),
  tagIds: array().of(string()),
  taxRate: number().required(),
});

interface EditorProps {
  mode: 'create' | 'update';
}

export const Editor: ComponentType<
  EditorProps & RouteComponentProps<{ id: string }>
> = (props) => {
  const { id } = props;

  const navigate = useNavigate();

  const packageQuery = usePackageQuery({
    variables: { id },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });

  const tagsQuery = useAdminTagsQuery({
    variables: { pagination: { take: 999, skip: 0 } },
  });

  const { taxRates } = useResources();

  const [update] = useUpdatePackageMutation();
  const [create] = useCreatePackageMutation();
  const [remove] = useDeletePackageMutation();

  const form = useForm<PackageFragment>({
    resolver: yupResolver(schema),
    defaultValues: {
      status: PackageStatus.Active,
      amount: 0,
      courseUnits: [],
      description: '',
      subTitle: '',
      title: '',
      tagIds: [],
    },
  });

  const courseUnits = useFieldArray({
    control: form.control,
    name: 'courseUnits',
    keyName: 'key',
  });

  const [confirmModal, confirmDelete] = useConfirm(async (id: string) => {
    try {
      await remove({ variables: { id } });
      navigate('/course-managements/units');
      success('Kurs-Einheit gelöscht.');
    } catch (e) {
      console.log(e);
    }
  });

  const onSubmit = useMemo(() => {
    return form.handleSubmit(
      async ({
        courseUnits,
        abbreviation,
        amount,
        status,
        title,
        subTitle,
        tagIds,
        description,
        taxRate,
        id,
      }) => {
        try {
          const input: SavePackageInput = {
            amount,
            status,
            title,
            subTitle,
            abbreviation,
            tagIds,
            description,
            taxRate,
            courseUnits: courseUnits.map(({ id }) => id),
          };

          if (id) {
            await update({
              variables: {
                id,
                input,
              },
            });
          } else {
            const newPackage = await create({
              variables: {
                input,
              },
            });
            navigate(`./${newPackage.data?.adminCreatePackage.id}/edit`);
          }

          success('Das Package wurde gespeichert.');
        } catch (e: any) {
          error('Fehler', e.message);
        }
      },
    );
  }, [id, form.handleSubmit, packageQuery.data]);

  const onDelete = useCallback(async () => {
    if (id) {
      confirmDelete('Soll dieses Package wirklich gelöscht werden?', id);
    }
  }, [id]);

  useEffect(() => {
    if (packageQuery.data) {
      form.reset(packageQuery.data.adminPackage);
    }
  }, [packageQuery.data]);

  return (
    <>
      {confirmModal}
      <Frame.SubTitle>
        Package: {packageQuery.data?.adminPackage.title}
        {packageQuery.data &&
          packageQuery.data.adminPackage.status === PackageStatus.Inactive && (
            <>
              {' '}
              <span className={globalStyles.error}>[INAKTIV]</span>
            </>
          )}
      </Frame.SubTitle>

      <Frame.ActionBar>
        {id && (
          <>
            <Button
              warning
              transparent
              label="Package löschen"
              onClick={onDelete}
            />
          </>
        )}
        <Button label="Zurück" onClick={() => navigate('..')} />
        <Button primary label="Speichern" onClick={onSubmit} />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={packageQuery.data?.adminPackage}>
          <Grid.Row>
            <Grid.Column oneFourth>
              <Section title="Package-Eigenschaften">
                <Form.Input name="title" label="Titel" />
                <Form.Input name="subTitle" label="Untertitel" />
                <Form.Input name="abbreviation" label="Kürzel" />
                <Form.Select2
                  name="tagIds"
                  multiple
                  label="Tags"
                  choices={
                    tagsQuery.data?.adminTags.data.map((t) => ({
                      value: t.id,
                      label: t.name,
                    })) || []
                  }
                />
                <Form.Select
                  label="Status"
                  name="status"
                  choices={[
                    { label: 'Aktiv', value: CourseUnitStatus.Active },
                    { label: 'Inaktiv', value: CourseUnitStatus.Inactive },
                  ]}
                />

                <Form.Currency name="amount" label="Preis" />
                <Form.Select
                  label="Steuersatz"
                  name="taxRate"
                  choices={taxRates.map((rate) => ({
                    label: `${Math.round(rate * 100)} %`,
                    value: rate.toString(),
                  }))}
                />
                <Form.Html
                  name="description"
                  label="Beschreibung (wird z.B. im Detail-Popup/Overlay angezeigt)"
                />
              </Section>
            </Grid.Column>
            <Grid.Column threeFourths>
              <Section title="Enthaltene Kurs-Einheiten">
                <Table
                  tiny
                  columns={['', 'Zeitraum', 'Titel', 'Tags', 'Standort']}
                  data={courseUnits.fields}
                  render={(unit, index) => (
                    <Row
                      key={unit.id}
                      className={
                        unit.status === CourseUnitStatus.Inactive
                          ? globalStyles.disabled
                          : undefined
                      }
                    >
                      <td>
                        <Button
                          small
                          label="-"
                          onClick={() => courseUnits.remove(index)}
                        />
                      </td>
                      <td>
                        <Link to={`../../../units/${unit.id}/edit`}>
                          {getCourseUnitDates(unit)}
                        </Link>
                        {unit.status === CourseUnitStatus.Inactive && (
                          <>
                            {' '}
                            <span className={globalStyles.error}>
                              [INAKTIV]
                            </span>
                          </>
                        )}
                      </td>

                      <td>
                        <Link to={`../../../units/${unit.id}/edit`}>
                          <Copyable
                            valueToCopy={unit.course.id}
                            label="ID kopieren"
                            inline
                          >
                            {unit.course.abbreviation} {unit.course.title}
                            <br />
                            <span className={globalStyles.light}>
                              {unit.season?.title}
                            </span>
                          </Copyable>
                        </Link>
                      </td>
                      <td>
                        <TagsList unit={unit} />
                      </td>
                      <td>
                        {unit.location?.name ||
                          unit.location?.city ||
                          unit.location?.address?.city}
                        {unit.online && <Chip>Online</Chip>}
                        {unit.correspondenceCourse && <Chip>Fernkurs</Chip>}
                      </td>
                    </Row>
                  )}
                />
                <CourseUnitPicker
                  exclude={courseUnits.fields}
                  onAddClick={(item) => {
                    courseUnits.append(item);
                  }}
                />
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
