import { Formatted } from '@/ui/formatted/formatted.component';
import React, { ComponentType } from 'react';
import AsyncSelect from 'react-select/async';
import { Translator } from 'shared/translator/translator.component';
import {
  FindProductsQuery,
  useFindProductsQuery,
} from './product-finder.generated';

import styles from './product-finder.module.scss';

function getProductLabel(
  product: FindProductsQuery['adminProducts']['data'][number],
  _: any,
) {
  return (
    <>
      {product.title}
      <br />
      <Formatted.Currency value={product.amount} />
    </>
  );
}

export type ProductItemType = {
  label: string;
  value: string;
  amount: number;
};
interface ProductFinderProps {
  value?: ProductItemType | null;
  onChange: (item?: ProductItemType | null) => void;
}

export const ProductFinder: ComponentType<ProductFinderProps> = (props) => {
  const { value, onChange } = props;
  const _ = Translator.useTranslator();

  const { data, refetch } = useFindProductsQuery({ variables: { query: '' } });
  return (
    <div className={styles.host}>
      <label>Suche</label>
      <AsyncSelect<ProductItemType>
        onChange={onChange}
        value={value}
        defaultOptions={
          data?.adminProducts.data.map((product) => ({
            label: getProductLabel(product, _) as any,
            value: product.id,
            amount: product.amount,
          })) || []
        }
        loadOptions={async (inputValue: string, callback) => {
          try {
            const data = await refetch({
              query: inputValue,
            });
            callback(
              data.data?.adminProducts.data.map(
                (product) =>
                  ({
                    label: getProductLabel(product, _),
                    value: product.id,
                    amount: product.amount,
                  } as any),
              ),
            );
          } catch (e) {
            console.log(e);
          }
        }}
      />
    </div>
  );
};
