import {
  PersonListDataFragment,
  usePersonsQuery,
} from '@/contact/person/list/list.generated';
import {
  getPersonDescription,
  getPersonDetailLink,
} from '@/contact/person/utils';
import { PersonEmail } from '@/contact/person/view/person-email.component';
import { UserStatusChip } from '@/contact/person/view/user-status-chip.component';
import { Frame } from '@/frame';
import { Administrator, FilterPersonInput } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { Chip } from '@/ui/chip/chip.component';
import { DebouncedInput } from '@/ui/debounced-input/debounced-input.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';

export const AdministratorList: ComponentType<RouteComponentProps> = (
  props,
) => {
  const [query] = usePaginatedUrlQuery<{ query: string }>();
  const { data, loading } = usePersonsQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
      filter: { query: query.query, userType: 'Administrator' },
    },
    fetchPolicy: 'network-only',
  });
  const _ = Translator.useTranslator();

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <Frame.ActionBar>
        <Button linkTo="/contacts/administrators/create" label="Neuer Nutzer" />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<PersonListDataFragment, FilterPersonInput>
          loading={loading}
          data={data?.persons.data || []}
          totalCount={data?.persons.totalCount}
          columns={[
            {
              name: 'Name',
              sortable: true,
            },
            {
              name: 'Benutzer',
              sortable: true,
            },
            {
              name: 'Unternehmen',
              sortable: true,
            },
            {
              name: 'Rollen/Berechtigungen',
              sortable: false,
            },

            { name: 'Status', sortable: true },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              <DebouncedInput
                debounceRate={400}
                value={value?.query || ''}
                onChange={(value) => onChange('query', value)}
                placeholder={'Suche…'}
              />
            </>
          )}
          renderRow={(item) => {
            const detailLink = getPersonDetailLink(item);
            return (
              <Row key={item.id}>
                <td>
                  <Link to={detailLink}>{getPersonDescription(item)}</Link>
                </td>
                <td>
                  <Link to={detailLink}>
                    {item.user?.username}
                    <PersonEmail person={item} />
                    {(item.user as Administrator)?.isBot && (
                      <Chip
                        key={(item.user as Administrator)?.id}
                        noVerticalSpacing
                      >
                        Bot
                      </Chip>
                    )}
                  </Link>
                </td>

                <td>
                  {item.enterprises
                    .map((enterprise) => enterprise.name)
                    .join(', ')}
                </td>
                <td>
                  {item.user?.__typename === 'Administrator'
                    ? item.user.roles.map((role) => (
                        <Chip key={role.id}>{role.name}</Chip>
                      ))
                    : null}
                </td>
                <td>
                  <UserStatusChip user={item.user} />
                </td>
              </Row>
            );
          }}
        />
      </Frame.Content>
    </>
  );
};
