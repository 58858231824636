import React, { ComponentType, ReactNode } from 'react';
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';
import styles from './tabs.module.scss';
import { useTabs } from './tabs.provider';

interface TabInterface {
  title: ReactNode;
  content: ReactNode;
  fields?: string[];
}
interface Props {
  tabs: (TabInterface | undefined)[];
}

export const Tabs: ComponentType<Props> = (props) => {
  const tabs = props.tabs.filter((t) => t !== undefined) as TabInterface[];
  const { tabIndex, setTabIndex, highlightedIndizes } = useTabs(
    tabs.map((tab) => tab.fields || []),
  );

  return (
    <ReactTabs
      forceRenderTabPanel
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
      className={styles.host}
    >
      <TabList className={styles.tabsList}>
        {tabs.map((tab, index) => (
          <Tab
            className={
              highlightedIndizes.includes(index)
                ? styles.highlighted
                : undefined
            }
            key={index}
          >
            {tab.title}
          </Tab>
        ))}
      </TabList>
      {tabs.map((tab, index) => (
        <TabPanel key={index}>{tab.content}</TabPanel>
      ))}
    </ReactTabs>
  );
};
