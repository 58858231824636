import { Frame } from '@/frame';
import {
  AdminActionAuditLog,
  ContactPropertyKey,
} from '@/graphql/generated/types';
import { Formatted } from '@/ui/formatted/formatted.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { Row } from '@/ui/table/row.component';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { getPaginationFromQuery } from 'shared/helpers/pagination.helper';
import { usePaginatedUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { useAdminActionAuditLogsQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const _ = Translator.useTranslator();
  const [query] = usePaginatedUrlQuery();
  const { data, loading } = useAdminActionAuditLogsQuery({
    variables: {
      pagination: getPaginationFromQuery(query),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>
      <ListView<AdminActionAuditLog>
        loading={loading}
        data={(data?.adminAuditLogs.data as any) || []}
        totalCount={data?.adminAuditLogs.totalCount || 0}
        columns={[
          {
            name: 'Datum',
            sortable: true,
          },
          {
            name: 'User',
            sortable: true,
          },
          {
            name: 'Aktion',
            sortable: true,
          },
          {
            name: 'Detail',
            sortable: true,
          },
        ]}
        renderFilter={({ value, onChange }) => (
          <>
            {/* <Filter
              value={value || ''}
              onChange={onChange}
              placeholder={'Suche…'}
              loading={loading}
            /> */}
          </>
        )}
        renderRow={(item) => (
          <Row key={item.id}>
            <td>
              <Formatted.Date value={item.createdAt} withTime />
            </td>

            <td>
              {item.admin.fullName}
              <br />
              {
                item.person.contactProperties.find(
                  (c) => c.key === ContactPropertyKey.Email,
                )?.value
              }
            </td>
            <td>{item.adminAction}</td>
            <td>
              <pre>{item.adminActionDescription}</pre>
            </td>
          </Row>
        )}
      />
      <Frame.Content></Frame.Content>
    </>
  );
};
