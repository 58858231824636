import {
  CourseUnit,
  CourseUnitStatus,
  SeasonStatus,
} from '@/graphql/generated/types';
import globalStyles from '@/styles/global.scss';
import { Chip } from '@/ui/chip/chip.component';
import React, { ComponentType } from 'react';
import styles from './course-unit-node-title.module.scss';

interface CourseUnitNodeTitleProps {
  courseUnit: Pick<CourseUnit, 'title' | 'status'> & {
    season?: { title: string; status: SeasonStatus } | null;
    tags?: { name: string; id: string }[] | null;
  };
  //courseUnit: CourseUnit;
}

export const CourseUnitNodeTitle: ComponentType<CourseUnitNodeTitleProps> = (
  props,
) => {
  const { courseUnit } = props;
  if (
    !courseUnit.title &&
    courseUnit.status !== CourseUnitStatus.Inactive &&
    courseUnit.season?.status !== SeasonStatus.Inactive &&
    !courseUnit.season
  ) {
    return null;
  }
  return (
    <div className={styles.host}>
      {courseUnit.season?.title && (
        <Chip className={styles.season}>
          Saison&nbsp;<em>{courseUnit.season.title}</em>
        </Chip>
      )}
      {courseUnit.season?.status === SeasonStatus.Inactive && (
        <span className={globalStyles.error}>[Saison INAKTIV]</span>
      )}

      {(courseUnit.title ||
        courseUnit.status === CourseUnitStatus.Inactive) && (
        <div className={styles.title}>
          {courseUnit.title && <h4>{courseUnit.title}</h4>}
          {courseUnit.status === CourseUnitStatus.Inactive && (
            <span className={globalStyles.error}>[Einheit INAKTIV]</span>
          )}
        </div>
      )}
    </div>
  );
};
