import { getPersonDescription } from '@/contact/person/utils';
import { Order, Person } from '@/graphql/generated/types';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { error, success } from '@/ui/toaster/toast';
import React, { useCallback } from 'react';
import { useDeleteOrderMutation } from './actions.generated';

export function useOrderActions(callback: () => void) {
  const [deleteOrder] = useDeleteOrderMutation();

  const [modalDelete, askDelete] = useConfirm(
    async (id: string) => {
      try {
        await deleteOrder({
          variables: {
            id,
          },
        });
        success('Die Bestellung wurde gelöscht.');
        callback();
      } catch (e: any) {
        console.error(e);
        error('Beim Löschen ist ein Fehler aufgetreten.', e.message);
      }
    },
    { meaning: 'error', title: 'Bestellung löschen' },
  );

  const askForDelete = useCallback(
    (
      order: Pick<Order, 'id'> & {
        customer: {
          person: Pick<Person, 'firstName' | 'lastName' | 'gender' | 'title'>;
        };
      },
    ) => {
      askDelete(
        <>
          <p>Diese Aktion lässt sich nicht rückgängig machen.</p>
          <p>
            Falls in der Bestellung Kurs-Buchungen enthalten sind, werden diese
            ebenfalls gelöscht, und somit u.a. die Anzahl der Teilnahmen des
            betr. Kurses verringert.
          </p>
          <p>
            Bist du sicher, dass du die Bestellung von{' '}
            <em>{getPersonDescription(order.customer.person)}</em> löschen
            möchtest?
          </p>
        </>,
        order.id,
      );
    },
    [],
  );

  return {
    modalDelete,
    askForDelete,
  };
}
