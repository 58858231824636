import { FormError } from '@/ui/form/form.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHideFrame } from 'shared/hooks/hide-frame';
import globalStyles from 'styles/global.scss';
import { object, string } from 'yup';
import { Button } from '../../ui/button/button.component';
import { InputField } from '../../ui/input-field/input-field.component';
import { Loader } from '../../ui/loader/loader.component';
import { useRequestPasswordResetMutation } from './request-password.generated';

const schema = object({
  emailOrUsername: string().required(FormError.required),
});
export const RequestPasswordComponent: ComponentType<
  RouteComponentProps
> = () => {
  const [serverError, setServerError] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [request, { loading }] = useRequestPasswordResetMutation();

  const { formState, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit(
        async (values) => {
          setServerError(undefined);
          try {
            const result = await request({
              variables: { emailOrUsername: values.emailOrUsername },
            });
            if (result.data?.requestPasswordReset) {
              setSuccess(true);
            } else {
              setServerError(
                'Dieser User existiert nicht oder hat keine gültige E-Mail-Adresse.',
              );
            }
          } catch (e) {
            setServerError(
              'Dieser User existiert nicht oder hat keine gültige E-Mail-Adresse.',
            );
          }
        },
        () => {},
      ),
    [],
  );
  useHideFrame();
  const domNode = document.getElementById('frame');
  if (!domNode) {
    return null;
  }

  return (
    <div className="centered" style={{ width: 300, margin: '150px auto' }}>
      {loading && <Loader small />}
      {success && (
        <>
          <h1>OK.</h1>
          <p>
            Du hast einen Link per Mail erhalten, mit dem du dein Passwort
            zurücksetzen kannst.
          </p>
        </>
      )}
      {!success && (
        <>
          <p>
            Wenn du noch kein Passwort hast, es vergessen hast oder ein neues
            vergeben möchtest, gib deinen Benutzernamen an, um einen Link zur
            Vergabe eines neuen Passwort per Mail zu erhalten!
          </p>
          <Controller
            name="emailOrUsername"
            render={({ field }) => (
              <InputField
                {...field}
                placeholder="Benutzername"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
                error={formState.errors.emailOrUsername?.message?.toString()}
              />
            )}
            control={control}
            defaultValue=""
          />

          {serverError && <p className={globalStyles.error}>{serverError}</p>}
          <div>
            <Button fluid onClick={onSubmit} label="Absenden" primary />
          </div>
        </>
      )}
    </div>
  );
};
