import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import { PropertySchemaFragmentDoc } from '../list/list.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PersistPropertySchemaMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['UUID']>;
  enterpriseId: Types.Scalars['UUID'];
  name: Types.Scalars['String'];
  contexts: Array<Types.Scalars['String']> | Types.Scalars['String'];
  value: Types.Scalars['String'];
}>;

export type PersistPropertySchemaMutation = {
  __typename?: 'Mutation';
  adminPersistPropertySchema: {
    __typename?: 'PropertySchema';
    id: any;
    name: string;
    contexts: Array<string>;
    value: string;
  };
};

export const PersistPropertySchemaDocument = gql`
  mutation persistPropertySchema(
    $id: UUID
    $enterpriseId: UUID!
    $name: String!
    $contexts: [String!]!
    $value: String!
  ) {
    adminPersistPropertySchema(
      id: $id
      enterpriseId: $enterpriseId
      name: $name
      contexts: $contexts
      value: $value
    ) {
      ...PropertySchema
    }
  }
  ${PropertySchemaFragmentDoc}
`;
export type PersistPropertySchemaMutationFn = Apollo.MutationFunction<
  PersistPropertySchemaMutation,
  PersistPropertySchemaMutationVariables
>;
export type PersistPropertySchemaComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PersistPropertySchemaMutation,
    PersistPropertySchemaMutationVariables
  >,
  'mutation'
>;

export const PersistPropertySchemaComponent = (
  props: PersistPropertySchemaComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    PersistPropertySchemaMutation,
    PersistPropertySchemaMutationVariables
  >
    mutation={PersistPropertySchemaDocument}
    {...props}
  />
);

/**
 * __usePersistPropertySchemaMutation__
 *
 * To run a mutation, you first call `usePersistPropertySchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersistPropertySchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [persistPropertySchemaMutation, { data, loading, error }] = usePersistPropertySchemaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enterpriseId: // value for 'enterpriseId'
 *      name: // value for 'name'
 *      contexts: // value for 'contexts'
 *      value: // value for 'value'
 *   },
 * });
 */
export function usePersistPropertySchemaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersistPropertySchemaMutation,
    PersistPropertySchemaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PersistPropertySchemaMutation,
    PersistPropertySchemaMutationVariables
  >(PersistPropertySchemaDocument, options);
}
export type PersistPropertySchemaMutationHookResult = ReturnType<
  typeof usePersistPropertySchemaMutation
>;
export type PersistPropertySchemaMutationResult =
  Apollo.MutationResult<PersistPropertySchemaMutation>;
export type PersistPropertySchemaMutationOptions = Apollo.BaseMutationOptions<
  PersistPropertySchemaMutation,
  PersistPropertySchemaMutationVariables
>;
