import removeIcon from '@/assets/image/icons/bin.svg';
import addIcon from '@/assets/image/icons/plus.svg';
import { Button } from '@/ui/button/button.component';
import { Form } from '@/ui/form/form.component';
import { InputField } from '@/ui/input-field/input-field.component';
import { SelectField2 } from '@/ui/select-field-2/select-field-2.component';
import React, { ComponentType } from 'react';
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { Translator } from 'shared/translator/translator.component';

interface EmploymentsEditorProps {
  form: UseFormReturn<any>;
  employmentsFieldArray: UseFieldArrayReturn<any, any>;
}

export const EmploymentsEditor: ComponentType<EmploymentsEditorProps> = (
  props,
) => {
  const _ = Translator.useTranslator();
  const { availableCompanies } = useResources();
  const { employmentsFieldArray, form } = props;
  return (
    <>
      {employmentsFieldArray.fields.map((field, index) => (
        <Form.Pack
          alignBottom
          key={field.id} // important to include key with field's id
        >
          <Controller
            render={({ field }) => (
              <SelectField2
                label="Firma"
                choices={availableCompanies.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
                {...field}
              />
            )}
            name={`person.employments.${index}.companyId`}
            control={form.control}
          />
          <Controller
            render={({ field }) => (
              <InputField {...field} label="Tätigkeit / Beschreibung" />
            )}
            name={`person.employments.${index}.description`}
            control={form.control}
          />
          <Controller
            render={({ field }) => <input type="hidden" {...field} />}
            name={`person.employments.${index}.id`}
            control={form.control}
          />
          <Button
            small
            round
            icon={removeIcon}
            onClick={() => employmentsFieldArray.remove(index)}
          />
        </Form.Pack>
      ))}
      <Button.Row>
        <Button
          small
          round
          primary
          icon={addIcon}
          onClick={() => {
            employmentsFieldArray.append({});
          }}
        />
      </Button.Row>
    </>
  );
};
