import { Address } from '@/graphql/generated/types';
import { LabeledItem } from '@/ui/labeled-item/labeled-item.component';
import React, { ComponentType } from 'react';
import { useCountryNames } from 'shared/countries/iso-country-codes';

interface AddressViewProps {
  address: Address;
}

export const AddressView: ComponentType<AddressViewProps> = (props) => {
  const { address } = props;
  const getName = useCountryNames();
  return (
    <>
      <LabeledItem value={address.company} label="Firma" />
      <LabeledItem value={address.contactPerson} label="Kontaktperson/z.Hd." />
      <LabeledItem
        value={address.streetAndNumber}
        label="Straße und Hausnummer"
      />
      <LabeledItem value={address.postalCode} label="PLZ" />
      <LabeledItem value={address.city} label="Stadt" />
      <LabeledItem
        value={address.country ? getName(address.country) : ''}
        label="Land"
      />
      <LabeledItem value={address.other} label="Sonstige Angaben" />
    </>
  );
};
