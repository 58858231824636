import classNames from 'classnames';
import React, { ComponentType } from 'react';
import globalStyles from 'styles/global.scss';
import { BookingsQuery } from '../list/list.generated';

interface BookingCourseViewProps {
  booking: BookingsQuery['adminBookings']['data'][number];
}

export const BookingCourseView: ComponentType<BookingCourseViewProps> = (
  props,
) => {
  const { booking } = props;
  return (
    <>
      {booking.__typename === 'CourseModuleBooking' && (
        <>
          {booking.courseModule.courseUnit.course.abbreviation}
          <div className={classNames(globalStyles.sub, globalStyles.meta)}>
            {booking.courseModule.courseUnit.course.verboseTitle}
          </div>
        </>
      )}
      {booking.__typename === 'CourseUnitBooking' && (
        <>
          {booking.courseUnit.course.abbreviation}
          <div className={classNames(globalStyles.sub, globalStyles.meta)}>
            {booking.courseUnit.course.verboseTitle}
          </div>
        </>
      )}
    </>
  );
};
