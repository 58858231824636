export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** Represents a set of coordinates */
  Geometry: any;
  /** Represents a valid JSON as string */
  JSON: any;
  /** Represents a valid UUID as string */
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  streetAndNumber?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  streetAndNumber?: InputMaybe<Scalars['String']>;
};

export type AdminActionAuditLog = AuditLog & {
  __typename?: 'AdminActionAuditLog';
  admin: Administrator;
  adminAction: Scalars['String'];
  adminActionDescription?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  person: Person;
  personId: Scalars['UUID'];
};

export type Administrator = User & {
  __typename?: 'Administrator';
  anonymizedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enterprises: Array<Enterprise>;
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  image?: Maybe<FilesystemFile>;
  imageId?: Maybe<Scalars['UUID']>;
  isBot: Scalars['Boolean'];
  person: Person;
  personId: Scalars['UUID'];
  primaryEmail?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['UUID']>>;
  roles: Array<Role>;
  selectedEnterprise?: Maybe<Enterprise>;
  status: UserStatus;
  uiSettings: UiSettings;
  username?: Maybe<Scalars['String']>;
};

export type AdministratorInput = {
  description?: InputMaybe<Scalars['String']>;
  isBot: Scalars['Boolean'];
  password?: InputMaybe<Scalars['String']>;
  roleIds: Array<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']>;
};

export type Attribute = {
  id?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  valueProperty?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  view?: Maybe<Scalars['String']>;
};

export type AttributeInput = {
  decimalValue?: InputMaybe<Scalars['Float']>;
  intValue?: InputMaybe<Scalars['Int']>;
  jsonValue?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  stringValue?: InputMaybe<Scalars['String']>;
  textValue?: InputMaybe<Scalars['String']>;
};

export type AuditLog = {
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  person: Person;
  personId: Scalars['UUID'];
};

export type AuditLogUnion = AdminActionAuditLog | ConsentAuditLog;

export type BookConsultingEventInput = {
  attendeeComment?: InputMaybe<Scalars['String']>;
  onlineOfferSelected?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Scalars['JSON']>;
  selectedCourseIds?: InputMaybe<Array<Scalars['UUID']>>;
  selectedLocationIds?: InputMaybe<Array<Scalars['UUID']>>;
  selectedSubjects?: InputMaybe<Array<Scalars['String']>>;
};

export type Booking = {
  attributes: Array<Attribute>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: BookingKind;
  /** @deprecated Use custom booking attributes in the future */
  options?: Maybe<Scalars['JSON']>;
  orderItem?: Maybe<OrderItem>;
};

export enum BookingKind {
  CourseModuleBooking = 'CourseModuleBooking',
  CourseUnitBooking = 'CourseUnitBooking',
}

export type BookingOrderItem = OrderItem & {
  __typename?: 'BookingOrderItem';
  amount: Scalars['Float'];
  booking: Booking;
  bookingId: Scalars['UUID'];
  discount: DiscountEmbedded;
  discounts: Array<Discount>;
  id: Scalars['UUID'];
  order: Order;
  orderId: Scalars['UUID'];
  position: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type BookingUnion = CourseModuleBooking | CourseUnitBooking;

export type CalculatedBookingOrderItem = CalculatedOrderItem & {
  __typename?: 'CalculatedBookingOrderItem';
  amount?: Maybe<Scalars['Float']>;
  courseAbbreviation?: Maybe<Scalars['String']>;
  courseModule?: Maybe<CourseModule>;
  courseUnit?: Maybe<CourseUnit>;
  description?: Maybe<Scalars['String']>;
  description2?: Maybe<Scalars['String']>;
  discounts: Array<Discount>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  quantity: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  verboseTitle?: Maybe<Scalars['String']>;
};

export type CalculatedCustomOrderItem = CalculatedOrderItem & {
  __typename?: 'CalculatedCustomOrderItem';
  amount?: Maybe<Scalars['Float']>;
  courseAbbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description2?: Maybe<Scalars['String']>;
  discounts: Array<Discount>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  quantity: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  verboseTitle?: Maybe<Scalars['String']>;
};

export type CalculatedDiscountOrderItem = CalculatedOrderItem & {
  __typename?: 'CalculatedDiscountOrderItem';
  amount?: Maybe<Scalars['Float']>;
  courseAbbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description2?: Maybe<Scalars['String']>;
  discountRule?: Maybe<DiscountRule>;
  discounts: Array<Discount>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  quantity: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  verboseTitle?: Maybe<Scalars['String']>;
};

export type CalculatedOrderItem = {
  amount?: Maybe<Scalars['Float']>;
  courseAbbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description2?: Maybe<Scalars['String']>;
  discounts: Array<Discount>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  quantity: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  verboseTitle?: Maybe<Scalars['String']>;
};

export type CalculatedOrderItemUnion =
  | CalculatedBookingOrderItem
  | CalculatedCustomOrderItem
  | CalculatedDiscountOrderItem
  | CalculatedPackageBookingOrderItem
  | CalculatedProductOrderItem;

export type CalculatedOrderItemsWithTotal = {
  __typename?: 'CalculatedOrderItemsWithTotal';
  globalDiscounts: Array<Discount>;
  grossTotal?: Maybe<Scalars['Float']>;
  items: Array<CalculatedOrderItemUnion>;
  netTotal?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['UUID']>;
  taxes?: Maybe<Array<TaxInfo>>;
  total: Scalars['Float'];
};

export type CalculatedPackageBookingOrderItem = CalculatedOrderItem & {
  __typename?: 'CalculatedPackageBookingOrderItem';
  amount?: Maybe<Scalars['Float']>;
  courseAbbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description2?: Maybe<Scalars['String']>;
  discounts: Array<Discount>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  package: Package;
  quantity: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  verboseTitle?: Maybe<Scalars['String']>;
};

export type CalculatedProductOrderItem = CalculatedOrderItem & {
  __typename?: 'CalculatedProductOrderItem';
  amount?: Maybe<Scalars['Float']>;
  courseAbbreviation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description2?: Maybe<Scalars['String']>;
  discounts: Array<Discount>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  product: ProductUnion;
  productOptions?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  verboseTitle?: Maybe<Scalars['String']>;
};

export type CartItemInput = {
  amount?: InputMaybe<Scalars['Float']>;
  attributes?: InputMaybe<Array<AttributeInput>>;
  contacts?: InputMaybe<Array<ContactInput>>;
  /** Depends on the type argument. Either a course unit, course module or product id */
  id: Scalars['UUID'];
  options?: InputMaybe<Scalars['JSON']>;
  quantity: Scalars['Int'];
  /** Type of item. */
  type: CheckoutItemType;
};

export enum CheckoutItemType {
  CourseModule = 'CourseModule',
  CourseUnit = 'CourseUnit',
  Package = 'Package',
  Product = 'Product',
}

export type CommunicationSettings = Settings & {
  __typename?: 'CommunicationSettings';
  data?: Maybe<CommunicationSettingsData>;
  id: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

export type CommunicationSettingsData = {
  __typename?: 'CommunicationSettingsData';
  mailAddressCourseManagement?: Maybe<Scalars['String']>;
  mailAddressSupport?: Maybe<Scalars['String']>;
  mailBaseTemplateHtml?: Maybe<Scalars['String']>;
  mailBaseTemplateTxt?: Maybe<Scalars['String']>;
  mailFrom?: Maybe<Scalars['String']>;
  mailHost?: Maybe<Scalars['String']>;
  mailPass?: Maybe<Scalars['String']>;
  mailPort?: Maybe<Scalars['String']>;
  mailUser?: Maybe<Scalars['String']>;
  useCustomerEmailAsMailFrom?: Maybe<Scalars['Boolean']>;
};

export type CommunicationSettingsDataInput = {
  mailAddressCourseManagement?: InputMaybe<Scalars['String']>;
  mailAddressSupport?: InputMaybe<Scalars['String']>;
  mailBaseTemplateHtml?: InputMaybe<Scalars['String']>;
  mailBaseTemplateTxt?: InputMaybe<Scalars['String']>;
  mailFrom?: InputMaybe<Scalars['String']>;
  mailHost?: InputMaybe<Scalars['String']>;
  mailPass?: InputMaybe<Scalars['String']>;
  mailPort?: InputMaybe<Scalars['String']>;
  mailUser?: InputMaybe<Scalars['String']>;
  useCustomerEmailAsMailFrom?: InputMaybe<Scalars['Boolean']>;
};

export type CommunicationSettingsInput = {
  data: CommunicationSettingsDataInput;
};

export type Company = {
  __typename?: 'Company';
  address: Address;
  contactProperties: Array<ContactProperty>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  employments: Array<Employment>;
  enterpriseIds?: Maybe<Array<Scalars['UUID']>>;
  enterprises: Array<Enterprise>;
  id: Scalars['UUID'];
  internalNotes?: Maybe<Scalars['String']>;
  invoiceAddress: Address;
  name: Scalars['String'];
};

export type CompanyInput = {
  address?: InputMaybe<AddressInput>;
  contactProperties?: InputMaybe<Array<ContactPropertyInput>>;
  enterpriseIds?: InputMaybe<Array<Scalars['UUID']>>;
  internalNotes?: InputMaybe<Scalars['String']>;
  invoiceAddress?: InputMaybe<AddressInput>;
  name: Scalars['String'];
};

export type ConsentAuditLog = AuditLog & {
  __typename?: 'ConsentAuditLog';
  consent: Scalars['Boolean'];
  consentHandle: ConsentHandle;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  person: Person;
  personId: Scalars['UUID'];
  subject: Scalars['String'];
  verificationToken?: Maybe<Scalars['String']>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
};

export enum ConsentHandle {
  Ads = 'Ads',
  Gtc = 'Gtc',
  Privacy = 'Privacy',
}

export type ConsultingEvent = EventInterface & {
  __typename?: 'ConsultingEvent';
  attendee?: Maybe<Person>;
  attendeeComment?: Maybe<Scalars['String']>;
  attendeeId?: Maybe<Scalars['UUID']>;
  availableCourseIds: Array<Scalars['UUID']>;
  availableCourses: Array<Course>;
  availableLocationIds: Array<Scalars['UUID']>;
  availableLocations: Array<Location>;
  availableSubjects: Array<Scalars['String']>;
  consultant?: Maybe<Administrator>;
  consultantId?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  onlineOfferSelected: Scalars['Boolean'];
  room?: Maybe<Room>;
  roomId?: Maybe<Scalars['UUID']>;
  selectedCourseIds: Array<Scalars['UUID']>;
  selectedCourses: Array<Course>;
  selectedLocationIds: Array<Scalars['UUID']>;
  selectedLocations: Array<Location>;
  selectedSubjects: Array<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type ContactInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type ContactProperty = {
  __typename?: 'ContactProperty';
  id: Scalars['UUID'];
  key: ContactPropertyKey;
  value: Scalars['String'];
};

export type ContactPropertyInput = {
  id?: InputMaybe<Scalars['UUID']>;
  key: ContactPropertyKey;
  value: Scalars['String'];
};

export enum ContactPropertyKey {
  Email = 'Email',
  Mobile = 'Mobile',
  Phone = 'Phone',
  Website = 'Website',
}

export type CopyConsultingEventInput = {
  day: Scalars['String'];
  id: Scalars['UUID'];
};

export type Course = {
  __typename?: 'Course';
  abbreviation: Scalars['String'];
  approximateClassHours?: Maybe<Scalars['Float']>;
  approximateDuration?: Maybe<Duration>;
  attribute?: Maybe<Scalars['String']>;
  attributes: Array<Attribute>;
  category: CourseCategory;
  categoryId: Scalars['UUID'];
  courseUnits: Array<CourseUnit>;
  courseUnitsForAdmin: Array<CourseUnit>;
  description?: Maybe<Scalars['String']>;
  earlyBirdDate?: Maybe<Scalars['DateTime']>;
  earlyBirdNotice?: Maybe<Scalars['String']>;
  eligible: Scalars['Boolean'];
  id: Scalars['UUID'];
  image?: Maybe<FilesystemFile>;
  imageId?: Maybe<Scalars['UUID']>;
  infoCourse: Scalars['Boolean'];
  modulePriceMatrix?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  properties: Scalars['JSON'];
  purchasable: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  status: CourseStatus;
  subTitle?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['UUID']>>;
  tags?: Maybe<Array<Tag>>;
  taxRate: Scalars['Float'];
  teacherIds?: Maybe<Array<Scalars['UUID']>>;
  teachers: Array<Teacher>;
  title: Scalars['String'];
  verboseTitle?: Maybe<Scalars['String']>;
};

export type CourseAttributeArgs = {
  name: Scalars['String'];
};

export type CourseAttributesArgs = {
  set: Scalars['String'];
};

export type CourseCourseUnitsArgs = {
  options?: InputMaybe<CourseUnitSearchInput>;
};

export type CourseCourseUnitsForAdminArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
  options?: InputMaybe<CourseUnitSearchInput>;
};

export type CourseCategory = {
  __typename?: 'CourseCategory';
  ancestorIds: Array<Scalars['String']>;
  children?: Maybe<Array<CourseCategory>>;
  courses?: Maybe<Array<Course>>;
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  parent?: Maybe<CourseCategory>;
  parentId?: Maybe<Scalars['UUID']>;
  properties: Scalars['JSON'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  verboseTitle?: Maybe<Scalars['String']>;
};

export type CourseEvent = EventInterface & {
  __typename?: 'CourseEvent';
  courseModule?: Maybe<CourseModule>;
  courseUnit?: Maybe<CourseUnit>;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  room?: Maybe<Room>;
  roomId?: Maybe<Scalars['UUID']>;
  startDate: Scalars['DateTime'];
};

export type CourseInquiry = InquiryInterface & {
  __typename?: 'CourseInquiry';
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignee?: Maybe<Administrator>;
  assigneeId: Scalars['UUID'];
  closedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  origin?: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['UUID'];
  status: InquiryStatus;
  subject?: Maybe<Scalars['String']>;
};

export type CourseInquiryInput = {
  consent: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
  courseId: Scalars['UUID'];
  enterpriseId: Scalars['UUID'];
  notificationMailToId?: InputMaybe<Scalars['String']>;
  notificationMailToUsername?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  person: PersonInput;
  subject?: InputMaybe<Scalars['String']>;
};

export type CourseModule = {
  __typename?: 'CourseModule';
  amount: Scalars['Float'];
  correspondenceCourse: Scalars['Boolean'];
  courseUnit: CourseUnit;
  courseUnitId: Scalars['UUID'];
  dateUncertain: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  discountedAmount?: Maybe<Scalars['Float']>;
  earlyBirdDate?: Maybe<Scalars['DateTime']>;
  earlyBirdNotice?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['UUID'];
  isBookable: Scalars['Boolean'];
  lateBookingOffset?: Maybe<Duration>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  materials: Array<Material>;
  online: Scalars['Boolean'];
  possiblyDiscountedAmount: Scalars['Float'];
  searchRelevantLocation?: Maybe<Location>;
  searchableLocation?: Maybe<Location>;
  searchableLocationId?: Maybe<Scalars['UUID']>;
  sku: Scalars['String'];
  startDate: Scalars['DateTime'];
  status: CourseModuleStatus;
  subTitle?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['UUID']>>;
  tags?: Maybe<Array<Tag>>;
  teacher?: Maybe<Teacher>;
  teacherId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  withTime: Scalars['Boolean'];
};

export type CourseModuleBooking = Booking & {
  __typename?: 'CourseModuleBooking';
  attributes: Array<Attribute>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  courseModule: CourseModule;
  courseModuleId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: BookingKind;
  /** @deprecated Use custom booking attributes in the future */
  options?: Maybe<Scalars['JSON']>;
  orderItem?: Maybe<OrderItem>;
};

export type CourseModuleInquiry = InquiryInterface & {
  __typename?: 'CourseModuleInquiry';
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignee?: Maybe<Administrator>;
  assigneeId: Scalars['UUID'];
  closedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  courseModuleId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  origin?: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['UUID'];
  status: InquiryStatus;
  subject?: Maybe<Scalars['String']>;
};

export type CourseModuleSearchInput = {
  endAfter?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  startAfter?: InputMaybe<Scalars['String']>;
};

export enum CourseModuleStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum CourseStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type CourseUnit = {
  __typename?: 'CourseUnit';
  allTags?: Maybe<Array<Tag>>;
  amount: Scalars['Float'];
  bookingNotes?: Maybe<Scalars['String']>;
  bookings: Array<Booking>;
  commonTags?: Maybe<Array<Tag>>;
  correspondenceCourse: Scalars['Boolean'];
  course: Course;
  courseId: Scalars['UUID'];
  dateUncertain: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  discountedAmount?: Maybe<Scalars['Float']>;
  earlyBirdDate?: Maybe<Scalars['DateTime']>;
  earlyBirdNotice?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['UUID'];
  isBookable: Scalars['Boolean'];
  lateBookingOffset?: Maybe<Duration>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  materials: Array<Material>;
  modulePriceMatrix?: Maybe<Scalars['String']>;
  modules: Array<CourseModule>;
  online: Scalars['Boolean'];
  possiblyDiscountedAmount: Scalars['Float'];
  properties: Scalars['JSON'];
  searchRelevantLocation?: Maybe<Location>;
  searchableLocation?: Maybe<Location>;
  searchableLocationId?: Maybe<Scalars['UUID']>;
  season?: Maybe<Season>;
  seasonId?: Maybe<Scalars['UUID']>;
  sku: Scalars['String'];
  startDate: Scalars['DateTime'];
  status: CourseUnitStatus;
  subTitle?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['UUID']>>;
  tags?: Maybe<Array<Tag>>;
  teacher?: Maybe<Teacher>;
  teacherId?: Maybe<Scalars['UUID']>;
  teacherIds?: Maybe<Array<Scalars['UUID']>>;
  teachers?: Maybe<Array<Teacher>>;
  title?: Maybe<Scalars['String']>;
  withTime: Scalars['Boolean'];
};

export type CourseUnitBooking = Booking & {
  __typename?: 'CourseUnitBooking';
  attributes: Array<Attribute>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  courseUnit: CourseUnit;
  courseUnitId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: BookingKind;
  /** @deprecated Use custom booking attributes in the future */
  options?: Maybe<Scalars['JSON']>;
  orderItem?: Maybe<OrderItem>;
};

export type CourseUnitInquiry = InquiryInterface & {
  __typename?: 'CourseUnitInquiry';
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignee?: Maybe<Administrator>;
  assigneeId: Scalars['UUID'];
  closedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  courseUnitId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  origin?: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['UUID'];
  status: InquiryStatus;
  subject?: Maybe<Scalars['String']>;
};

export type CourseUnitSearchInput = {
  categoryIds?: InputMaybe<Array<Scalars['UUID']>>;
  courseIds?: InputMaybe<Array<Scalars['UUID']>>;
  endAfter?: InputMaybe<Scalars['String']>;
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  isCorrespondenceCourse?: InputMaybe<Scalars['Boolean']>;
  isOnline?: InputMaybe<Scalars['Boolean']>;
  locationIds?: InputMaybe<Array<Scalars['UUID']>>;
  omitPastUnits?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  seasonIds?: InputMaybe<Array<Scalars['UUID']>>;
  sortByStartDate?: InputMaybe<Scalars['String']>;
  startAfter?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<Scalars['String']>>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum CourseUnitStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type CreateConsultingEventInput = {
  availableCourseIds?: InputMaybe<Array<Scalars['UUID']>>;
  availableLocationIds?: InputMaybe<Array<Scalars['UUID']>>;
  availableSubjects?: InputMaybe<Array<Scalars['String']>>;
  consultantId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  enterpriseId: Scalars['UUID'];
  locationId?: InputMaybe<Scalars['UUID']>;
  roomId?: InputMaybe<Scalars['UUID']>;
  startDate: Scalars['DateTime'];
};

export type CreateCourseCategoryInput = {
  enterpriseId: Scalars['UUID'];
  parentId?: InputMaybe<Scalars['UUID']>;
  properties?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateCourseInput = {
  abbreviation: Scalars['String'];
  approximateClassHours?: InputMaybe<Scalars['Int']>;
  approximateDuration?: InputMaybe<DurationInput>;
  attributes: Array<AttributeInput>;
  categoryId: Scalars['UUID'];
  description?: InputMaybe<Scalars['String']>;
  earlyBirdDate?: InputMaybe<Scalars['DateTime']>;
  earlyBirdNotice?: InputMaybe<Scalars['String']>;
  eligible: Scalars['Boolean'];
  infoCourse: Scalars['Boolean'];
  modulePriceMatrix?: InputMaybe<Scalars['String']>;
  position: Scalars['Int'];
  properties?: InputMaybe<Scalars['JSON']>;
  purchasable: Scalars['Boolean'];
  slug?: InputMaybe<Scalars['String']>;
  status: CourseStatus;
  subTitle?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  taxRate: Scalars['Float'];
  teacherIds?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type CreateEquipmentInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CreateLocationInput = {
  address: AddressInput;
  administrativeEmail?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  enterpriseIds: Array<Scalars['String']>;
  geoLocation?: InputMaybe<Array<Scalars['Float']>>;
  geoLocationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['UUID']>;
  properties?: InputMaybe<Scalars['JSON']>;
  status: LocationStatus;
};

export type CreateProductInput = {
  amount?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  enterpriseId: Scalars['UUID'];
  kind: Scalars['String'];
  status: ProductStatus;
  title: Scalars['String'];
};

export type CreateRoomInput = {
  equipmentIds?: InputMaybe<Array<Scalars['UUID']>>;
  locationId: Scalars['UUID'];
  maxPeople?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  pricePerHour?: InputMaybe<Scalars['Float']>;
};

export type Customer = User & {
  __typename?: 'Customer';
  anonymizedAt?: Maybe<Scalars['DateTime']>;
  bookings: Array<Booking>;
  description?: Maybe<Scalars['String']>;
  enterprises: Array<Enterprise>;
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  image?: Maybe<FilesystemFile>;
  imageId?: Maybe<Scalars['UUID']>;
  orders: Array<Order>;
  person: Person;
  personId: Scalars['UUID'];
  primaryEmail?: Maybe<Scalars['String']>;
  selectedEnterprise?: Maybe<Enterprise>;
  status: UserStatus;
  username?: Maybe<Scalars['String']>;
};

export type CustomerContactInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type CustomerInput = {
  description?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']>;
};

export type DecimalAttribute = Attribute & {
  __typename?: 'DecimalAttribute';
  decimalValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  valueProperty?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  view?: Maybe<Scalars['String']>;
};

export type Discount = {
  __typename?: 'Discount';
  amount?: Maybe<Scalars['Float']>;
  globallyExcludedItems: DiscountRuleDependencies;
  id: Scalars['UUID'];
  order?: Maybe<Order>;
  orderItem?: Maybe<OrderItem>;
  reason?: Maybe<Scalars['String']>;
  type: DiscountType;
};

export type DiscountEmbedded = {
  __typename?: 'DiscountEmbedded';
  amount?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  type: DiscountType;
};

export type DiscountOrderItem = OrderItem & {
  __typename?: 'DiscountOrderItem';
  amount: Scalars['Float'];
  discount: DiscountEmbedded;
  discountRule?: Maybe<DiscountRule>;
  discountRuleId: Scalars['UUID'];
  discounts: Array<Discount>;
  id: Scalars['UUID'];
  order: Order;
  orderId: Scalars['UUID'];
  position: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type DiscountParamsInput = {
  discountCheckboxChecked?: InputMaybe<Scalars['Boolean']>;
  voucherCode?: InputMaybe<Scalars['String']>;
};

export type DiscountRule = {
  __typename?: 'DiscountRule';
  amount: Scalars['Float'];
  applyToWholeCart: Scalars['Boolean'];
  dependencies: DiscountRuleDependencies;
  dependencyRelation: DiscountRuleDependencyRelation;
  description?: Maybe<Scalars['String']>;
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  excludedItems: DiscountRuleDependencies;
  handle?: Maybe<DiscountRuleHandle>;
  id: Scalars['UUID'];
  status: DiscountRuleStatus;
  title?: Maybe<Scalars['String']>;
  type: DiscountType;
  useCheckbox: Scalars['Boolean'];
  voucherCode?: Maybe<Scalars['String']>;
};

export type DiscountRuleDependencies = {
  __typename?: 'DiscountRuleDependencies';
  categoryIds?: Maybe<Array<Scalars['String']>>;
  courseCategories: Array<CourseCategory>;
  courseIds?: Maybe<Array<Scalars['String']>>;
  courseModuleIds?: Maybe<Array<Scalars['String']>>;
  courseModules: Array<CourseModule>;
  courseUnitIds?: Maybe<Array<Scalars['String']>>;
  courseUnits: Array<CourseUnit>;
  courses: Array<Course>;
  productIds?: Maybe<Array<Scalars['String']>>;
  products: Array<ProductUnion>;
};

export type DiscountRuleDependenciesInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  courseIds?: InputMaybe<Array<Scalars['String']>>;
  courseModuleIds?: InputMaybe<Array<Scalars['String']>>;
  courseUnitIds?: InputMaybe<Array<Scalars['String']>>;
  productIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum DiscountRuleDependencyRelation {
  And = 'And',
  Or = 'Or',
}

export enum DiscountRuleHandle {
  ModulePriceMatrix = 'ModulePriceMatrix',
  MoreThanThree = 'MoreThanThree',
}

export enum DiscountRuleStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum DiscountType {
  Absolute = 'Absolute',
  Relative = 'Relative',
}

export type DownloadInquiry = InquiryInterface & {
  __typename?: 'DownloadInquiry';
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignee?: Maybe<Administrator>;
  assigneeId: Scalars['UUID'];
  closedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  location?: Maybe<Location>;
  locationId: Scalars['UUID'];
  origin?: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['UUID'];
  status: InquiryStatus;
  subject?: Maybe<Scalars['String']>;
};

export type DownloadInquiryInput = {
  consent: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
  emailSubject: Scalars['String'];
  emailTemplate: Scalars['String'];
  enterpriseId: Scalars['UUID'];
  fileIds: Array<Scalars['String']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  maxDownloads: Scalars['Int'];
  notificationMailToId?: InputMaybe<Scalars['String']>;
  notificationMailToUsername?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  person: PersonInput;
  subject?: InputMaybe<Scalars['String']>;
  urls?: InputMaybe<Array<Scalars['String']>>;
};

export type Duplicate = {
  __typename?: 'Duplicate';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  isNotDuplicate: Scalars['Boolean'];
  person1: Person;
  person2: Person;
  reasons: Array<Scalars['String']>;
  score: Scalars['Int'];
};

export type DuplicateCourseUnitInput = {
  seasonId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DuplicateSeasonInput = {
  title: Scalars['String'];
};

export type Duration = {
  __typename?: 'Duration';
  computed?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type DurationInput = {
  factor?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type EditEnterpriseInput = {
  communicationSettings?: InputMaybe<CommunicationSettingsInput>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  websiteSettings?: InputMaybe<WebsiteSettingsInput>;
};

export type EditEquipmentInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type EditLocationInput = {
  address: AddressInput;
  administrativeEmail?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  geoLocation?: InputMaybe<Array<Scalars['Float']>>;
  geoLocationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['UUID']>;
  properties?: InputMaybe<Scalars['JSON']>;
  status: LocationStatus;
};

export type EditRoomInput = {
  locationId: Scalars['String'];
  maxPeople?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  pricePerHour?: InputMaybe<Scalars['Float']>;
};

export type Employment = {
  __typename?: 'Employment';
  company: Company;
  companyId: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  person: Person;
  personId: Scalars['UUID'];
};

export type EmploymentInput = {
  companyId: Scalars['UUID'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type Enterprise = {
  __typename?: 'Enterprise';
  communicationSettings?: Maybe<CommunicationSettings>;
  courseAttributeSets: Array<Scalars['String']>;
  id: Scalars['UUID'];
  isAdmin: Scalars['Boolean'];
  logo?: Maybe<FilesystemFile>;
  logoId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  productAttributeSets: Array<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  websiteSettings?: Maybe<WebsiteSettings>;
};

export type EnterpriseInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
};

export type Equipment = {
  __typename?: 'Equipment';
  description: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type Event = EventInterface & {
  __typename?: 'Event';
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  room?: Maybe<Room>;
  roomId?: Maybe<Scalars['UUID']>;
  startDate: Scalars['DateTime'];
};

export type EventInterface = {
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  room?: Maybe<Room>;
  roomId?: Maybe<Scalars['UUID']>;
  startDate: Scalars['DateTime'];
};

export type EventUnion = ConsultingEvent | CourseEvent | Event;

export enum FileGroup {
  Administrator = 'Administrator',
  Common = 'Common',
  Course = 'Course',
  Enterprise = 'Enterprise',
  Teacher = 'Teacher',
}

export type FileUploadInput = {
  enterpriseId?: InputMaybe<Scalars['UUID']>;
  group: FileGroup;
  private?: InputMaybe<Scalars['Boolean']>;
};

export type FilesystemFile = {
  __typename?: 'FilesystemFile';
  createdAt: Scalars['DateTime'];
  enterprise?: Maybe<Enterprise>;
  enterpriseId?: Maybe<Scalars['UUID']>;
  group: FileGroup;
  humanImageUrl: Scalars['String'];
  humanUrl: Scalars['String'];
  id: Scalars['UUID'];
  imageUrl: Scalars['String'];
  isImage: Scalars['Boolean'];
  isRasterizedImage: Scalars['Boolean'];
  mimetype?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  private: Scalars['Boolean'];
  url: Scalars['String'];
};

export type FilesystemFileHumanImageUrlArgs = {
  properties?: InputMaybe<ImagePropertiesInput>;
};

export type FilesystemFileImageUrlArgs = {
  properties?: InputMaybe<ImagePropertiesInput>;
};

export type FilterAuditLogsInput = {
  includeAdminLogins?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Scalars['String']>;
};

export type FilterBookingsInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FilterCompaniesInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type FilterConsultingEvents = {
  consultantId?: InputMaybe<Scalars['UUID']>;
  mode: Scalars['String'];
};

export type FilterConsultingEventsForCalendar = {
  endConfirmedAt?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  onlyBooked?: InputMaybe<Scalars['Boolean']>;
  startConfirmedAt?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FilterCourseInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  categoryIds?: InputMaybe<Array<Scalars['UUID']>>;
  courseIds?: InputMaybe<Array<Scalars['UUID']>>;
  locationIds?: InputMaybe<Array<Scalars['UUID']>>;
  parentCategoryId?: InputMaybe<Scalars['UUID']>;
  query?: InputMaybe<Scalars['String']>;
};

export type FilterEventsInput = {
  endConfirmedAt?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  enterpriseIds?: InputMaybe<Array<Scalars['UUID']>>;
  kind?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  roomId?: InputMaybe<Scalars['UUID']>;
  startConfirmedAt?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type FilterFileInput = {
  group?: InputMaybe<FileGroup>;
  search?: InputMaybe<Scalars['String']>;
};

export type FilterInquiriesInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  excludeTestPersons?: InputMaybe<Scalars['Boolean']>;
  exclusiveStartDate?: InputMaybe<Scalars['DateTime']>;
  kind?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<InquiryStatus>;
};

export type FilterLeadsInput = {
  actions?: InputMaybe<Array<LeadAction>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FilterLocationsInput = {
  onlyRootLocations?: InputMaybe<Scalars['Boolean']>;
};

export type FilterOrdersInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  exclusiveStartDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FilterPackageInput = {
  courseIds?: InputMaybe<Array<Scalars['UUID']>>;
  locationIds?: InputMaybe<Array<Scalars['UUID']>>;
  query?: InputMaybe<Scalars['String']>;
};

export type FilterPersonInput = {
  excludeTestPersons?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
};

export type FilterProductsInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type FilterTagInput = {
  query?: InputMaybe<Scalars['String']>;
};

export enum Gender {
  Diverse = 'Diverse',
  Female = 'Female',
  Male = 'Male',
}

export type ImagePropertiesInput = {
  forceRegeneration: Scalars['Boolean'];
  size: Scalars['String'];
};

export type Inquiry = InquiryInterface & {
  __typename?: 'Inquiry';
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignee?: Maybe<Administrator>;
  assigneeId: Scalars['UUID'];
  closedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  origin?: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['UUID'];
  status: InquiryStatus;
  subject?: Maybe<Scalars['String']>;
};

export type InquiryInput = {
  consent: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
  enterpriseId: Scalars['UUID'];
  notificationMailToId?: InputMaybe<Scalars['String']>;
  notificationMailToUsername?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  person: PersonInput;
  subject?: InputMaybe<Scalars['String']>;
};

export type InquiryInterface = {
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignee?: Maybe<Administrator>;
  assigneeId: Scalars['UUID'];
  closedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  origin?: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['UUID'];
  status: InquiryStatus;
  subject?: Maybe<Scalars['String']>;
};

export type InquiryStatRecord = StatRecord & {
  __typename?: 'InquiryStatRecord';
  courseId?: Maybe<Scalars['String']>;
  courseModuleId?: Maybe<Scalars['UUID']>;
  courseModuleTitle?: Maybe<Scalars['String']>;
  courseUnitId?: Maybe<Scalars['UUID']>;
  courseUnitTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  locationId?: Maybe<Scalars['UUID']>;
  locationName?: Maybe<Scalars['String']>;
};

export enum InquiryStatus {
  Closed = 'Closed',
  InProgress = 'InProgress',
  Open = 'Open',
}

export type InquiryUnion =
  | CourseInquiry
  | CourseModuleInquiry
  | CourseUnitInquiry
  | DownloadInquiry
  | Inquiry
  | LocationInquiry;

export type IntAttribute = Attribute & {
  __typename?: 'IntAttribute';
  id?: Maybe<Scalars['String']>;
  intValue?: Maybe<Scalars['Int']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  valueProperty?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  view?: Maybe<Scalars['String']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  user: User;
};

export type JsonAttribute = Attribute & {
  __typename?: 'JsonAttribute';
  id?: Maybe<Scalars['String']>;
  jsonValue?: Maybe<Scalars['JSON']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  valueProperty?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  view?: Maybe<Scalars['String']>;
};

export type Lead = {
  __typename?: 'Lead';
  action: LeadAction;
  actionId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  person: Person;
};

export enum LeadAction {
  Booking = 'Booking',
  ConsultingEvent = 'ConsultingEvent',
  Inquiry = 'Inquiry',
  Order = 'Order',
}

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  administrativeEmail?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Location>>;
  city?: Maybe<Scalars['String']>;
  commonName: Scalars['String'];
  enterprises: Array<Enterprise>;
  geoLocation?: Maybe<Scalars['Geometry']>;
  geoLocationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  parent?: Maybe<Location>;
  parentId?: Maybe<Scalars['UUID']>;
  properties: Scalars['JSON'];
  rooms?: Maybe<Array<Room>>;
  status: LocationStatus;
};

export type LocationInquiry = InquiryInterface & {
  __typename?: 'LocationInquiry';
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignee?: Maybe<Administrator>;
  assigneeId: Scalars['UUID'];
  closedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  location?: Maybe<Location>;
  locationId: Scalars['UUID'];
  origin?: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['UUID'];
  status: InquiryStatus;
  subject?: Maybe<Scalars['String']>;
};

export type LocationInquiryInput = {
  consent: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
  enterpriseId: Scalars['UUID'];
  locationId?: InputMaybe<Scalars['UUID']>;
  notificationMailToId?: InputMaybe<Scalars['String']>;
  notificationMailToUsername?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  person: PersonInput;
  subject?: InputMaybe<Scalars['String']>;
};

export type LocationProduct = Product & {
  __typename?: 'LocationProduct';
  amount: Scalars['Float'];
  attribute?: Maybe<Scalars['String']>;
  attributes: Array<Attribute>;
  description?: Maybe<Scalars['String']>;
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  locationIds: Array<Scalars['UUID']>;
  locations: Array<Location>;
  nativeAttributes: Array<Attribute>;
  sku: Scalars['String'];
  status: ProductStatus;
  title: Scalars['String'];
};

export type LocationProductAttributeArgs = {
  name: Scalars['String'];
};

export type LocationProductAttributesArgs = {
  set: Scalars['String'];
};

export enum LocationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type Material = {
  __typename?: 'Material';
  id: Scalars['UUID'];
  title: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminAnonymizePerson: Person;
  adminCreateAdministrator: Person;
  adminCreateCompany: Company;
  adminCreateConsultingEvents: Scalars['Boolean'];
  adminCreateCourse: Course;
  adminCreateCourseCategory: CourseCategory;
  adminCreateCourseModule: CourseModule;
  adminCreateCourseUnit: CourseUnit;
  adminCreateCustomer: Person;
  adminCreateEnterprise: Enterprise;
  adminCreateEquipment: Equipment;
  adminCreateLocation: Location;
  adminCreatePackage: Package;
  adminCreatePerson: Person;
  adminCreateProduct: Product;
  adminCreateRoom: Room;
  adminCreateTeacher: Person;
  adminDeleteBooking: Scalars['Boolean'];
  adminDeleteCompany: Scalars['Boolean'];
  adminDeleteCourse: Scalars['Boolean'];
  adminDeleteCourseCategory: Scalars['Boolean'];
  adminDeleteCourseModule: Scalars['Boolean'];
  adminDeleteCourseUnit: Scalars['Boolean'];
  adminDeleteFile: Scalars['Boolean'];
  adminDeleteInquiry: Scalars['Boolean'];
  adminDeleteLocation: Scalars['Boolean'];
  adminDeleteOrder: Scalars['Boolean'];
  adminDeletePackage: Scalars['Boolean'];
  adminDeletePerson: Scalars['Boolean'];
  adminDeleteProduct: Product;
  adminDeleteSeason: Scalars['Boolean'];
  adminDeleteTeacher: Scalars['Boolean'];
  adminDuplicateCourseUnit: CourseUnit;
  adminDuplicateSeason: Season;
  adminEditAdministrator: Person;
  adminEditCompany: Company;
  adminEditCustomer: Person;
  adminEditEnterprise: Enterprise;
  adminEditEquipment: Equipment;
  adminEditLocation: Location;
  adminEditPerson: Person;
  adminEditProduct: Product;
  adminEditRoom: Room;
  adminEditTeacher: Person;
  adminEditTransactionMailTemplate: TransactionMailTemplate;
  adminEditUiSettings: Person;
  adminPersistPropertySchema: PropertySchema;
  adminSaveSeason: Season;
  adminSetBookingStatus: Scalars['Boolean'];
  adminShareStatisticsBoard: Scalars['Boolean'];
  adminUpdateConsultingEvent: ConsultingEvent;
  adminUpdateCourse: Course;
  adminUpdateCourseCategory: CourseCategory;
  adminUpdateCourseFiles: Scalars['Boolean'];
  adminUpdateCourseModule: CourseModule;
  adminUpdateCourseUnit: CourseUnit;
  adminUpdateEnterpriseFiles: Scalars['Boolean'];
  adminUpdateEvent: Scalars['Boolean'];
  adminUpdatePackage: Package;
  adminUpdateUserFiles: Scalars['Boolean'];
  adminUploadFile: FilesystemFile;
  assignInquiry: InquiryInterface;
  authenticate: Scalars['String'];
  bookConsultingEvent: Scalars['Boolean'];
  changeDiscountRuleStatus: Scalars['Boolean'];
  checkForDuplicates: Scalars['Boolean'];
  checkout: CalculatedOrderItemsWithTotal;
  closeInquiry: InquiryInterface;
  completeRegistration: Scalars['String'];
  copyConsultingEvent: ConsultingEvent;
  createBooking: Scalars['Boolean'];
  createCourseInquiry: CourseInquiry;
  createDownloadInquiry: DownloadInquiry;
  createInquiry: Inquiry;
  createLocationInquiry: LocationInquiry;
  createOrder: Order;
  createTag: Tag;
  deleteConsultingEvent: Scalars['Boolean'];
  deleteDiscountRule: Scalars['Boolean'];
  editTag: Tag;
  logout: Scalars['Boolean'];
  redeemInvitation: Scalars['Boolean'];
  registerAsCustomer: Scalars['Boolean'];
  removeTestInstances: Scalars['Boolean'];
  reopenInquiry: InquiryInterface;
  requestPasswordReset: Scalars['Boolean'];
  rewriteCourseAndCategoryNames: Scalars['Boolean'];
  saveDiscountRule: DiscountRule;
  submitBooking: Booking;
  submitOrder: Order;
  verifyConsent: Scalars['Boolean'];
  writeStatistics: Scalars['Boolean'];
};

export type MutationAdminAnonymizePersonArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminCreateAdministratorArgs = {
  personInput: PersonInput;
  userInput: AdministratorInput;
};

export type MutationAdminCreateCompanyArgs = {
  input: CompanyInput;
};

export type MutationAdminCreateConsultingEventsArgs = {
  input: Array<CreateConsultingEventInput>;
};

export type MutationAdminCreateCourseArgs = {
  input: CreateCourseInput;
};

export type MutationAdminCreateCourseCategoryArgs = {
  input: CreateCourseCategoryInput;
};

export type MutationAdminCreateCourseModuleArgs = {
  input?: InputMaybe<SaveCourseModuleInput>;
  moduleIdToDuplicate?: InputMaybe<Scalars['UUID']>;
};

export type MutationAdminCreateCourseUnitArgs = {
  input: SaveCourseUnitInput;
};

export type MutationAdminCreateCustomerArgs = {
  personInput: PersonInput;
  userInput: CustomerInput;
};

export type MutationAdminCreateEnterpriseArgs = {
  input: EnterpriseInput;
};

export type MutationAdminCreateEquipmentArgs = {
  input: CreateEquipmentInput;
};

export type MutationAdminCreateLocationArgs = {
  input: CreateLocationInput;
};

export type MutationAdminCreatePackageArgs = {
  input: SavePackageInput;
};

export type MutationAdminCreatePersonArgs = {
  personInput: PersonInput;
};

export type MutationAdminCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationAdminCreateRoomArgs = {
  input: CreateRoomInput;
};

export type MutationAdminCreateTeacherArgs = {
  personInput: PersonInput;
  userInput: TeacherInput;
};

export type MutationAdminDeleteBookingArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type MutationAdminDeleteCompanyArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteCourseArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteCourseCategoryArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteCourseModuleArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteCourseUnitArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteFileArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteInquiryArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type MutationAdminDeleteLocationArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteOrderArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type MutationAdminDeletePackageArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeletePersonArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteProductArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteSeasonArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDeleteTeacherArgs = {
  id: Scalars['UUID'];
};

export type MutationAdminDuplicateCourseUnitArgs = {
  input: DuplicateCourseUnitInput;
  unitIdToDuplicate: Scalars['UUID'];
};

export type MutationAdminDuplicateSeasonArgs = {
  input?: InputMaybe<DuplicateSeasonInput>;
  seasonIdToDuplicate: Scalars['UUID'];
};

export type MutationAdminEditAdministratorArgs = {
  id: Scalars['UUID'];
  personInput: PersonInput;
  userInput: AdministratorInput;
};

export type MutationAdminEditCompanyArgs = {
  id: Scalars['UUID'];
  input: CompanyInput;
};

export type MutationAdminEditCustomerArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  personInput: PersonInput;
  userInput: CustomerInput;
};

export type MutationAdminEditEnterpriseArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  input: EditEnterpriseInput;
};

export type MutationAdminEditEquipmentArgs = {
  id: Scalars['UUID'];
  input: EditEquipmentInput;
};

export type MutationAdminEditLocationArgs = {
  id: Scalars['UUID'];
  input: EditLocationInput;
};

export type MutationAdminEditPersonArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  personInput: PersonInput;
};

export type MutationAdminEditProductArgs = {
  id: Scalars['UUID'];
  input: UpdateProductInput;
};

export type MutationAdminEditRoomArgs = {
  id: Scalars['UUID'];
  input: EditRoomInput;
};

export type MutationAdminEditTeacherArgs = {
  id: Scalars['UUID'];
  personInput: PersonInput;
  userInput: TeacherInput;
};

export type MutationAdminEditTransactionMailTemplateArgs = {
  id?: InputMaybe<Scalars['UUID']>;
  input: TransactionMailTemplateInput;
};

export type MutationAdminEditUiSettingsArgs = {
  input: UiSettingsInput;
};

export type MutationAdminPersistPropertySchemaArgs = {
  contexts: Array<Scalars['String']>;
  enterpriseId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type MutationAdminSaveSeasonArgs = {
  input?: InputMaybe<SaveSeasonInput>;
};

export type MutationAdminSetBookingStatusArgs = {
  id: Scalars['UUID'];
  status: SetBookingStatus;
};

export type MutationAdminShareStatisticsBoardArgs = {
  input: ShareStatisticsBoardInput;
};

export type MutationAdminUpdateConsultingEventArgs = {
  id: Scalars['UUID'];
  input: UpdateConsultingEventInput;
};

export type MutationAdminUpdateCourseArgs = {
  id: Scalars['UUID'];
  input: UpdateCourseInput;
};

export type MutationAdminUpdateCourseCategoryArgs = {
  id: Scalars['UUID'];
  input: UpdateCourseCategoryInput;
};

export type MutationAdminUpdateCourseFilesArgs = {
  id: Scalars['UUID'];
  input: UpdateCourseFilesInput;
};

export type MutationAdminUpdateCourseModuleArgs = {
  id: Scalars['UUID'];
  input: SaveCourseModuleInput;
};

export type MutationAdminUpdateCourseUnitArgs = {
  id: Scalars['UUID'];
  input: SaveCourseUnitInput;
};

export type MutationAdminUpdateEnterpriseFilesArgs = {
  id: Scalars['UUID'];
  input: UpdateEnterpriseFilesInput;
};

export type MutationAdminUpdateEventArgs = {
  id: Scalars['UUID'];
  input: UpdateEventInput;
};

export type MutationAdminUpdatePackageArgs = {
  id: Scalars['UUID'];
  input: SavePackageInput;
};

export type MutationAdminUpdateUserFilesArgs = {
  id: Scalars['UUID'];
  input: UpdateUserFilesInput;
};

export type MutationAdminUploadFileArgs = {
  file?: InputMaybe<Scalars['Upload']>;
  id?: InputMaybe<Scalars['UUID']>;
  input: FileUploadInput;
};

export type MutationAssignInquiryArgs = {
  id: Scalars['UUID'];
};

export type MutationAuthenticateArgs = {
  enterpriseId?: InputMaybe<Scalars['UUID']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationBookConsultingEventArgs = {
  bookingInput: BookConsultingEventInput;
  enterpriseId: Scalars['UUID'];
  eventId: Scalars['UUID'];
  person: PersonInput;
};

export type MutationChangeDiscountRuleStatusArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  status: DiscountRuleStatus;
};

export type MutationCheckoutArgs = {
  additionalConsent?: InputMaybe<Scalars['String']>;
  address: AddressInput;
  comment?: InputMaybe<Scalars['String']>;
  consentAds?: InputMaybe<Scalars['String']>;
  consentGtc: Scalars['String'];
  contact: ContactInput;
  discountCheckboxChecked?: InputMaybe<Scalars['Boolean']>;
  discountParams?: InputMaybe<DiscountParamsInput>;
  enterpriseId: Scalars['UUID'];
  invoiceAddress?: InputMaybe<AddressInput>;
  items: Array<CartItemInput>;
  options?: InputMaybe<Scalars['JSON']>;
  voucherCode?: InputMaybe<Scalars['String']>;
};

export type MutationCloseInquiryArgs = {
  id: Scalars['UUID'];
};

export type MutationCompleteRegistrationArgs = {
  enterpriseId: Scalars['UUID'];
  token: Scalars['String'];
};

export type MutationCopyConsultingEventArgs = {
  input: CopyConsultingEventInput;
};

export type MutationCreateBookingArgs = {
  bookingType: Scalars['String'];
  customerId: Scalars['UUID'];
  id: Scalars['UUID'];
};

export type MutationCreateCourseInquiryArgs = {
  input: CourseInquiryInput;
};

export type MutationCreateDownloadInquiryArgs = {
  input: DownloadInquiryInput;
};

export type MutationCreateInquiryArgs = {
  input: InquiryInput;
};

export type MutationCreateLocationInquiryArgs = {
  input: LocationInquiryInput;
};

export type MutationCreateOrderArgs = {
  customerId: Scalars['UUID'];
  enterpriseId: Scalars['UUID'];
  order: OrderInput;
  sendNotificationMails: Scalars['Boolean'];
};

export type MutationCreateTagArgs = {
  input: TagInput;
};

export type MutationDeleteConsultingEventArgs = {
  id: Scalars['UUID'];
};

export type MutationDeleteDiscountRuleArgs = {
  id: Scalars['UUID'];
};

export type MutationEditTagArgs = {
  id: Scalars['UUID'];
  input: TagInput;
};

export type MutationRedeemInvitationArgs = {
  input: RedeemInvitationInput;
};

export type MutationRegisterAsCustomerArgs = {
  enterpriseId?: InputMaybe<Scalars['UUID']>;
  input: RegisterInput;
};

export type MutationReopenInquiryArgs = {
  id: Scalars['UUID'];
};

export type MutationRequestPasswordResetArgs = {
  emailOrUsername: Scalars['String'];
  enterpriseId?: InputMaybe<Scalars['UUID']>;
};

export type MutationSaveDiscountRuleArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  input: SaveDiscountRuleInput;
};

export type MutationSubmitBookingArgs = {
  booking: SubmitBookingInput;
  customerContact: CustomerContactInput;
  enterpriseId: Scalars['UUID'];
};

export type MutationSubmitOrderArgs = {
  customerContact?: InputMaybe<CustomerContactInput>;
  enterpriseId: Scalars['UUID'];
  order: OrderInput;
};

export type MutationVerifyConsentArgs = {
  token: Scalars['String'];
};

export type MyEnterprisesResult = {
  __typename?: 'MyEnterprisesResult';
  enterprises: Array<Enterprise>;
  isSuperAdmin: Scalars['Boolean'];
};

export type Order = {
  __typename?: 'Order';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['UUID'];
  discounts: Array<Discount>;
  enterprise?: Maybe<Enterprise>;
  enterpriseId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  items: Array<OrderItem>;
  options?: Maybe<Scalars['JSON']>;
  status: OrderStatus;
  total: Scalars['Float'];
  totalCalculated: Scalars['Float'];
  useInvoiceAddress: Scalars['Boolean'];
};

export type OrderInput = {
  additionalConsent?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressInput>;
  comment?: InputMaybe<Scalars['String']>;
  consentAds?: InputMaybe<Scalars['String']>;
  consentGtc: Scalars['String'];
  discountParams?: InputMaybe<DiscountParamsInput>;
  invoiceAddress?: InputMaybe<AddressInput>;
  items: Array<OrderItemInput>;
  orderOptions?: InputMaybe<Scalars['JSON']>;
};

export type OrderItem = {
  amount: Scalars['Float'];
  discount: DiscountEmbedded;
  discounts: Array<Discount>;
  id: Scalars['UUID'];
  order: Order;
  orderId: Scalars['UUID'];
  position: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type OrderItemInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bookingOptions?: InputMaybe<Scalars['JSON']>;
  courseModuleId?: InputMaybe<Scalars['UUID']>;
  courseUnitId?: InputMaybe<Scalars['UUID']>;
  productId?: InputMaybe<Scalars['UUID']>;
  productOptions?: InputMaybe<ProductOptionsInput>;
  quantity: Scalars['Int'];
};

export type OrderStatRecord = StatRecord & {
  __typename?: 'OrderStatRecord';
  amount: Scalars['Float'];
  bookingOptions?: Maybe<Scalars['JSON']>;
  courseCategoryIds?: Maybe<Array<Scalars['UUID']>>;
  courseCategoryTitle?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['UUID']>;
  courseModuleId?: Maybe<Scalars['UUID']>;
  courseModuleTitle?: Maybe<Scalars['String']>;
  courseTitle?: Maybe<Scalars['String']>;
  courseUnitId?: Maybe<Scalars['UUID']>;
  courseUnitTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discountRule?: Maybe<DiscountRule>;
  discountRuleId?: Maybe<Scalars['UUID']>;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  locationId?: Maybe<Scalars['UUID']>;
  locationName?: Maybe<Scalars['String']>;
  orderItemId?: Maybe<Scalars['UUID']>;
  orderItemType?: Maybe<StatRecordOrderItemType>;
  productAttributes?: Maybe<Array<Scalars['String']>>;
  productId?: Maybe<Scalars['UUID']>;
  productTitle?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export enum OrderStatus {
  Deleted = 'Deleted',
  Payed = 'Payed',
  Submitted = 'Submitted',
}

export type Package = {
  __typename?: 'Package';
  abbreviation: Scalars['String'];
  amount: Scalars['Float'];
  approximateClassHours?: Maybe<Scalars['Float']>;
  courseUnits: Array<CourseUnit>;
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Float'];
  endDate?: Maybe<Scalars['DateTime']>;
  enterprise: Enterprise;
  id: Scalars['String'];
  originalAmount: Scalars['Float'];
  purchasable: Scalars['Boolean'];
  seasons: Array<Season>;
  sku: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status: PackageStatus;
  subTitle?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['UUID']>>;
  tags?: Maybe<Array<Tag>>;
  taxRate: Scalars['Float'];
  title: Scalars['String'];
};

export type PackageBookingOrderItem = OrderItem & {
  __typename?: 'PackageBookingOrderItem';
  amount: Scalars['Float'];
  bookingIds: Array<Scalars['UUID']>;
  bookings: Array<Booking>;
  discount: DiscountEmbedded;
  discounts: Array<Discount>;
  id: Scalars['UUID'];
  order: Order;
  orderId: Scalars['UUID'];
  package: Package;
  position: Scalars['Int'];
  quantity: Scalars['Float'];
};

export enum PackageStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type PaginatedAuditLogResult = {
  __typename?: 'PaginatedAuditLogResult';
  data: Array<AuditLogUnion>;
  totalCount: Scalars['Int'];
};

export type PaginatedBookingResult = {
  __typename?: 'PaginatedBookingResult';
  data: Array<BookingUnion>;
  totalCount: Scalars['Int'];
};

export type PaginatedCompanyResult = {
  __typename?: 'PaginatedCompanyResult';
  data: Array<Company>;
  totalCount: Scalars['Int'];
};

export type PaginatedConsultingEventResult = {
  __typename?: 'PaginatedConsultingEventResult';
  data: Array<ConsultingEvent>;
  totalCount: Scalars['Int'];
};

export type PaginatedCourseCategoryResult = {
  __typename?: 'PaginatedCourseCategoryResult';
  data: Array<CourseCategory>;
  totalCount: Scalars['Int'];
};

export type PaginatedCourseResult = {
  __typename?: 'PaginatedCourseResult';
  data: Array<Course>;
  totalCount: Scalars['Int'];
};

export type PaginatedCourseUnitResult = {
  __typename?: 'PaginatedCourseUnitResult';
  data: Array<CourseUnit>;
  totalCount: Scalars['Int'];
};

export type PaginatedDiscountRuleResult = {
  __typename?: 'PaginatedDiscountRuleResult';
  data: Array<DiscountRule>;
  totalCount: Scalars['Int'];
};

export type PaginatedEnterpriseResult = {
  __typename?: 'PaginatedEnterpriseResult';
  data: Array<Enterprise>;
  totalCount: Scalars['Int'];
};

export type PaginatedEquipmentResult = {
  __typename?: 'PaginatedEquipmentResult';
  data: Array<Equipment>;
  totalCount: Scalars['Int'];
};

export type PaginatedFilesystemFileResult = {
  __typename?: 'PaginatedFilesystemFileResult';
  data: Array<FilesystemFile>;
  totalCount: Scalars['Int'];
};

export type PaginatedInquiryResult = {
  __typename?: 'PaginatedInquiryResult';
  data: Array<InquiryUnion>;
  totalCount: Scalars['Int'];
};

export type PaginatedLocationResult = {
  __typename?: 'PaginatedLocationResult';
  data: Array<Location>;
  totalCount: Scalars['Int'];
};

export type PaginatedOrderResult = {
  __typename?: 'PaginatedOrderResult';
  data: Array<Order>;
  totalCount: Scalars['Int'];
};

export type PaginatedPackageResult = {
  __typename?: 'PaginatedPackageResult';
  data: Array<Package>;
  totalCount: Scalars['Int'];
};

export type PaginatedPersonWithDuplicatesResult = {
  __typename?: 'PaginatedPersonWithDuplicatesResult';
  data: Array<PersonWithDuplicates>;
  totalCount: Scalars['Int'];
};

export type PaginatedProductResult = {
  __typename?: 'PaginatedProductResult';
  data: Array<ProductUnion>;
  totalCount: Scalars['Int'];
};

export type PaginatedPropertySchemaResult = {
  __typename?: 'PaginatedPropertySchemaResult';
  data: Array<PropertySchema>;
  totalCount: Scalars['Int'];
};

export type PaginatedRoomResult = {
  __typename?: 'PaginatedRoomResult';
  data: Array<Room>;
  totalCount: Scalars['Int'];
};

export type PaginatedSeasonResult = {
  __typename?: 'PaginatedSeasonResult';
  data: Array<Season>;
  totalCount: Scalars['Int'];
};

export type PaginatedTagResult = {
  __typename?: 'PaginatedTagResult';
  data: Array<Tag>;
  totalCount: Scalars['Int'];
};

export type PaginatedTransactionMailTemplateResult = {
  __typename?: 'PaginatedTransactionMailTemplateResult';
  data: Array<TransactionMailTemplate>;
  totalCount: Scalars['Int'];
};

export type PaginationInput = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type Person = PersonInterface & {
  __typename?: 'Person';
  address: Address;
  anonymizedAt?: Maybe<Scalars['DateTime']>;
  contactProperties: Array<ContactProperty>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  employments: Array<Employment>;
  enterpriseIds?: Maybe<Array<Scalars['UUID']>>;
  enterprises: Array<Enterprise>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id: Scalars['UUID'];
  inquiries: Array<Inquiry>;
  internalNotes?: Maybe<Scalars['String']>;
  invoiceAddress: Address;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type PersonInput = {
  address?: InputMaybe<AddressInput>;
  contactProperties?: InputMaybe<Array<ContactPropertyInput>>;
  employments?: InputMaybe<Array<EmploymentInput>>;
  enterpriseIds?: InputMaybe<Array<Scalars['UUID']>>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  internalNotes?: InputMaybe<Scalars['String']>;
  invoiceAddress?: InputMaybe<AddressInput>;
  lastName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PersonInterface = {
  address: Address;
  anonymizedAt?: Maybe<Scalars['DateTime']>;
  contactProperties: Array<ContactProperty>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  employments: Array<Employment>;
  enterpriseIds?: Maybe<Array<Scalars['UUID']>>;
  enterprises: Array<Enterprise>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id: Scalars['UUID'];
  inquiries: Array<Inquiry>;
  internalNotes?: Maybe<Scalars['String']>;
  invoiceAddress: Address;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type PersonUnion = Person | PersonWithDuplicates;

export type PersonWithDuplicates = PersonInterface & {
  __typename?: 'PersonWithDuplicates';
  address: Address;
  anonymizedAt?: Maybe<Scalars['DateTime']>;
  contactProperties: Array<ContactProperty>;
  createdAt: Scalars['DateTime'];
  duplicates?: Maybe<Array<Duplicate>>;
  email?: Maybe<Scalars['String']>;
  employments: Array<Employment>;
  enterpriseIds?: Maybe<Array<Scalars['UUID']>>;
  enterprises: Array<Enterprise>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id: Scalars['UUID'];
  inquiries: Array<Inquiry>;
  internalNotes?: Maybe<Scalars['String']>;
  invoiceAddress: Address;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Product = {
  amount: Scalars['Float'];
  attribute?: Maybe<Scalars['String']>;
  attributes: Array<Attribute>;
  description?: Maybe<Scalars['String']>;
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  nativeAttributes: Array<Attribute>;
  sku: Scalars['String'];
  status: ProductStatus;
  title: Scalars['String'];
};

export type ProductAttributeArgs = {
  name: Scalars['String'];
};

export type ProductAttributesArgs = {
  set: Scalars['String'];
};

export type ProductOptionsInput = {
  locationId?: InputMaybe<Scalars['UUID']>;
  onlineOffer?: InputMaybe<Scalars['Boolean']>;
};

export type ProductOrderItem = OrderItem & {
  __typename?: 'ProductOrderItem';
  amount: Scalars['Float'];
  discount: DiscountEmbedded;
  discounts: Array<Discount>;
  id: Scalars['UUID'];
  order: Order;
  orderId: Scalars['UUID'];
  position: Scalars['Int'];
  product: ProductUnion;
  productId: Scalars['UUID'];
  productOptions?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
};

export enum ProductStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Inactive = 'Inactive',
}

export type ProductUnion = LocationProduct | SimpleProduct;

export type PropertySchema = {
  __typename?: 'PropertySchema';
  contexts: Array<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  adminAuditLogs: PaginatedAuditLogResult;
  adminBooking: BookingUnion;
  adminBookings: PaginatedBookingResult;
  adminCompanies: PaginatedCompanyResult;
  adminCompany: Company;
  adminConsultingEvent: ConsultingEvent;
  adminConsultingEvents: PaginatedConsultingEventResult;
  adminConsultingEventsForCalendar: Array<ConsultingEvent>;
  adminCourse: Course;
  adminCourseCategories: PaginatedCourseCategoryResult;
  adminCourseCategory: CourseCategory;
  adminCourseModule: CourseModule;
  adminCourseModules: Array<CourseModule>;
  adminCourseUnit: CourseUnit;
  adminCourseUnits: Array<CourseUnit>;
  adminCourseUnitsForPackage: PaginatedCourseUnitResult;
  adminCourses: PaginatedCourseResult;
  adminEnterprise: Enterprise;
  adminEnterprises: PaginatedEnterpriseResult;
  adminEquipment: Equipment;
  adminEquipments: PaginatedEquipmentResult;
  adminEvents: Array<EventUnion>;
  adminFile: FilesystemFile;
  adminFiles: Array<FilesystemFile>;
  adminInquiries: PaginatedInquiryResult;
  adminInquiry: InquiryUnion;
  adminLocation: Location;
  adminLocations: PaginatedLocationResult;
  adminOrder: Order;
  adminOrders: PaginatedOrderResult;
  adminPackage: Package;
  adminPackages: PaginatedPackageResult;
  adminPaginatedFiles: PaginatedFilesystemFileResult;
  adminProduct: ProductUnion;
  adminProducts: PaginatedProductResult;
  adminPropertySchema: PropertySchema;
  adminPropertySchemaContext: Array<PropertySchema>;
  adminPropertySchemas: PaginatedPropertySchemaResult;
  adminRoom: Room;
  adminRooms: PaginatedRoomResult;
  adminSeason: Season;
  adminSeasons: PaginatedSeasonResult;
  adminTag: Tag;
  adminTags: PaginatedTagResult;
  adminTransactionMailTemplate: TransactionMailTemplate;
  adminTransactionMailTemplates: PaginatedTransactionMailTemplateResult;
  adminUnpaginatedOrders: Array<Order>;
  availableConsultingEvents: Array<ConsultingEvent>;
  calculatedOrderItems: CalculatedOrderItemsWithTotal;
  course: Course;
  courseBySlug: Course;
  courseCategories: Array<CourseCategory>;
  courseCategoriesWithCourses: Array<CourseCategory>;
  courseModules: Array<CourseModule>;
  courseUnit: CourseUnit;
  courseUnits: Array<CourseUnit>;
  courses: Array<Course>;
  discountRule: DiscountRule;
  discountRules: PaginatedDiscountRuleResult;
  enterprise: Enterprise;
  enterprises: Array<Enterprise>;
  events: Array<EventUnion>;
  invitation?: Maybe<Invitation>;
  leads: Array<Lead>;
  location: Location;
  locations: Array<Location>;
  locationsWithCourseUnits: Array<Location>;
  locationsWithCoursesOfCategories: Array<Location>;
  myEnterprises: MyEnterprisesResult;
  packages: Array<Package>;
  person: PersonUnion;
  persons: PaginatedPersonWithDuplicatesResult;
  previewCheckout: CalculatedOrderItemsWithTotal;
  product: ProductUnion;
  products: Array<ProductUnion>;
  profile: UserUnion;
  roles: Array<Role>;
  room: Room;
  rooms: Array<Room>;
  statRecords: Array<StatRecordUnion>;
  tags: Array<Tag>;
  teacher: Teacher;
  teacherBySlug: Teacher;
  teachers: Array<Teacher>;
};

export type QueryAdminAuditLogsArgs = {
  filter?: InputMaybe<FilterAuditLogsInput>;
  pagination: PaginationInput;
};

export type QueryAdminBookingArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminBookingsArgs = {
  input?: InputMaybe<FilterBookingsInput>;
  pagination: PaginationInput;
};

export type QueryAdminCompaniesArgs = {
  filter?: InputMaybe<FilterCompaniesInput>;
  pagination: PaginationInput;
};

export type QueryAdminCompanyArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminConsultingEventArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminConsultingEventsArgs = {
  input: FilterConsultingEvents;
  pagination: PaginationInput;
};

export type QueryAdminConsultingEventsForCalendarArgs = {
  input: FilterConsultingEventsForCalendar;
};

export type QueryAdminCourseArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminCourseCategoriesArgs = {
  enterpriseIds?: InputMaybe<Array<Scalars['UUID']>>;
  pagination: PaginationInput;
};

export type QueryAdminCourseCategoryArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminCourseModuleArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminCourseModulesArgs = {
  options?: InputMaybe<CourseModuleSearchInput>;
};

export type QueryAdminCourseUnitArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminCourseUnitsArgs = {
  courseId?: InputMaybe<Scalars['UUID']>;
  ids?: InputMaybe<Array<Scalars['UUID']>>;
  options?: InputMaybe<CourseUnitSearchInput>;
};

export type QueryAdminCourseUnitsForPackageArgs = {
  options?: InputMaybe<CourseUnitSearchInput>;
  pagination: PaginationInput;
};

export type QueryAdminCoursesArgs = {
  filter?: InputMaybe<FilterCourseInput>;
  pagination: PaginationInput;
};

export type QueryAdminEnterpriseArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminEnterprisesArgs = {
  pagination: PaginationInput;
};

export type QueryAdminEquipmentArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminEquipmentsArgs = {
  pagination: PaginationInput;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryAdminEventsArgs = {
  filter?: InputMaybe<FilterEventsInput>;
};

export type QueryAdminFileArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminFilesArgs = {
  filter?: InputMaybe<FilterFileInput>;
};

export type QueryAdminInquiriesArgs = {
  filter: FilterInquiriesInput;
  pagination: PaginationInput;
};

export type QueryAdminInquiryArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminLocationArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminLocationsArgs = {
  pagination: PaginationInput;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryAdminOrderArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminOrdersArgs = {
  filter?: InputMaybe<FilterOrdersInput>;
  pagination: PaginationInput;
};

export type QueryAdminPackageArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminPackagesArgs = {
  filter?: InputMaybe<FilterPackageInput>;
  pagination: PaginationInput;
};

export type QueryAdminPaginatedFilesArgs = {
  filter?: InputMaybe<FilterFileInput>;
  pagination: PaginationInput;
};

export type QueryAdminProductArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminProductsArgs = {
  filter?: InputMaybe<FilterProductsInput>;
  pagination: PaginationInput;
};

export type QueryAdminPropertySchemaArgs = {
  enterpriseId: Scalars['UUID'];
  propertySchemaId: Scalars['UUID'];
};

export type QueryAdminPropertySchemaContextArgs = {
  context: Scalars['String'];
  enterpriseId: Scalars['UUID'];
};

export type QueryAdminPropertySchemasArgs = {
  enterpriseId: Scalars['UUID'];
  pagination: PaginationInput;
};

export type QueryAdminRoomArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminRoomsArgs = {
  pagination: PaginationInput;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryAdminSeasonArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminSeasonsArgs = {
  pagination: PaginationInput;
};

export type QueryAdminTagArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminTagsArgs = {
  filter?: InputMaybe<FilterTagInput>;
  pagination: PaginationInput;
};

export type QueryAdminTransactionMailTemplateArgs = {
  id: Scalars['UUID'];
};

export type QueryAdminTransactionMailTemplatesArgs = {
  enterpriseId: Scalars['UUID'];
  pagination: PaginationInput;
};

export type QueryAdminUnpaginatedOrdersArgs = {
  filter?: InputMaybe<FilterOrdersInput>;
};

export type QueryAvailableConsultingEventsArgs = {
  enterpriseId: Scalars['UUID'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryCalculatedOrderItemsArgs = {
  discountParams?: InputMaybe<DiscountParamsInput>;
  enterpriseId: Scalars['UUID'];
  items: Array<OrderItemInput>;
};

export type QueryCourseArgs = {
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
};

export type QueryCourseBySlugArgs = {
  enterpriseId: Scalars['UUID'];
  slug: Scalars['String'];
};

export type QueryCourseCategoriesArgs = {
  enterpriseId: Scalars['UUID'];
};

export type QueryCourseCategoriesWithCoursesArgs = {
  enterpriseId: Scalars['UUID'];
  parentCategoryId?: InputMaybe<Scalars['UUID']>;
};

export type QueryCourseModulesArgs = {
  courseUnitId?: InputMaybe<Scalars['UUID']>;
  enterpriseId: Scalars['UUID'];
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};

export type QueryCourseUnitArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type QueryCourseUnitsArgs = {
  courseId?: InputMaybe<Scalars['UUID']>;
  enterpriseId: Scalars['UUID'];
  ids?: InputMaybe<Array<Scalars['UUID']>>;
  options?: InputMaybe<CourseUnitSearchInput>;
};

export type QueryCoursesArgs = {
  enterpriseId: Scalars['UUID'];
  filter?: InputMaybe<FilterCourseInput>;
};

export type QueryDiscountRuleArgs = {
  id: Scalars['UUID'];
};

export type QueryDiscountRulesArgs = {
  pagination: PaginationInput;
};

export type QueryEnterpriseArgs = {
  id: Scalars['UUID'];
};

export type QueryEventsArgs = {
  input?: InputMaybe<FilterEventsInput>;
};

export type QueryInvitationArgs = {
  code: Scalars['String'];
};

export type QueryLeadsArgs = {
  filter?: InputMaybe<FilterLeadsInput>;
};

export type QueryLocationArgs = {
  id: Scalars['UUID'];
};

export type QueryLocationsArgs = {
  enterpriseId?: InputMaybe<Scalars['UUID']>;
  enterpriseIds?: InputMaybe<Array<Scalars['UUID']>>;
  filter?: InputMaybe<FilterLocationsInput>;
};

export type QueryLocationsWithCourseUnitsArgs = {
  enterpriseId: Scalars['UUID'];
};

export type QueryLocationsWithCoursesOfCategoriesArgs = {
  categoryIds?: InputMaybe<Array<Scalars['UUID']>>;
  courseIds?: InputMaybe<Array<Scalars['UUID']>>;
  enterpriseId: Scalars['UUID'];
  filter?: InputMaybe<FilterLocationsInput>;
  locationIds?: InputMaybe<Array<Scalars['UUID']>>;
};

export type QueryMyEnterprisesArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type QueryPackagesArgs = {
  enterpriseId: Scalars['UUID'];
};

export type QueryPersonArgs = {
  id: Scalars['UUID'];
};

export type QueryPersonsArgs = {
  filter?: InputMaybe<FilterPersonInput>;
  pagination: PaginationInput;
  withDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPreviewCheckoutArgs = {
  address: AddressInput;
  comment?: InputMaybe<Scalars['String']>;
  contact: ContactInput;
  discountCheckboxChecked?: InputMaybe<Scalars['Boolean']>;
  discountParams?: InputMaybe<DiscountParamsInput>;
  enterpriseId: Scalars['UUID'];
  invoiceAddress?: InputMaybe<AddressInput>;
  items: Array<CartItemInput>;
  options?: InputMaybe<Scalars['JSON']>;
  voucherCode?: InputMaybe<Scalars['String']>;
};

export type QueryProductArgs = {
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
};

export type QueryProductsArgs = {
  enterpriseId: Scalars['UUID'];
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};

export type QueryRoomArgs = {
  id: Scalars['UUID'];
};

export type QueryStatRecordsArgs = {
  filter: StatRecordsFilter;
};

export type QueryTagsArgs = {
  context?: InputMaybe<Scalars['String']>;
  enterpriseId: Scalars['UUID'];
};

export type QueryTeacherArgs = {
  id: Scalars['UUID'];
};

export type QueryTeacherBySlugArgs = {
  enterpriseId: Scalars['UUID'];
  slug: Scalars['String'];
};

export type QueryTeachersArgs = {
  enterpriseId: Scalars['UUID'];
};

export type RedeemInvitationInput = {
  code: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterInput = {
  consentGtc: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender: Gender;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['UUID'];
  name: RoleType;
};

export enum RoleType {
  Admin = 'Admin',
  Analyst = 'Analyst',
  Consultant = 'Consultant',
  CourseManager = 'CourseManager',
  Employee = 'Employee',
  RoomManager = 'RoomManager',
  Super = 'Super',
}

export type Room = {
  __typename?: 'Room';
  equipments: Array<Equipment>;
  geoLocation?: Maybe<Scalars['Geometry']>;
  id: Scalars['UUID'];
  location: Location;
  locationId: Scalars['UUID'];
  maxPeople?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  pricePerHour?: Maybe<Scalars['Float']>;
};

export type SaveCourseModuleInput = {
  amount?: InputMaybe<Scalars['Float']>;
  correspondenceCourse?: InputMaybe<Scalars['Boolean']>;
  courseUnitId: Scalars['UUID'];
  dateUncertain?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  discountedAmount?: InputMaybe<Scalars['Float']>;
  earlyBirdDate?: InputMaybe<Scalars['DateTime']>;
  earlyBirdNotice?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  lateBookingOffset?: InputMaybe<DurationInput>;
  locationId?: InputMaybe<Scalars['UUID']>;
  online?: InputMaybe<Scalars['Boolean']>;
  searchableLocationId?: InputMaybe<Scalars['UUID']>;
  startDate: Scalars['DateTime'];
  status: CourseModuleStatus;
  subTitle?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  teacherId?: InputMaybe<Scalars['UUID']>;
  title?: InputMaybe<Scalars['String']>;
  withTime?: InputMaybe<Scalars['Boolean']>;
};

export type SaveCourseUnitInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bookingNotes?: InputMaybe<Scalars['String']>;
  correspondenceCourse?: InputMaybe<Scalars['Boolean']>;
  courseId: Scalars['UUID'];
  dateUncertain?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  discountedAmount?: InputMaybe<Scalars['Float']>;
  earlyBirdDate?: InputMaybe<Scalars['DateTime']>;
  earlyBirdNotice?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  lateBookingOffset?: InputMaybe<DurationInput>;
  locationId?: InputMaybe<Scalars['UUID']>;
  modulePriceMatrix?: InputMaybe<Scalars['String']>;
  online?: InputMaybe<Scalars['Boolean']>;
  properties?: InputMaybe<Scalars['JSON']>;
  searchableLocationId?: InputMaybe<Scalars['UUID']>;
  seasonId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: CourseUnitStatus;
  subTitle?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  teacherId?: InputMaybe<Scalars['UUID']>;
  teacherIds?: InputMaybe<Array<Scalars['UUID']>>;
  title?: InputMaybe<Scalars['String']>;
  withTime?: InputMaybe<Scalars['Boolean']>;
};

export type SaveDiscountRuleInput = {
  amount: Scalars['Float'];
  applyToWholeCart: Scalars['Boolean'];
  dependencies: DiscountRuleDependenciesInput;
  dependencyRelation: DiscountRuleDependencyRelation;
  description: Scalars['String'];
  enterpriseId: Scalars['UUID'];
  excludedItems: DiscountRuleDependenciesInput;
  status: DiscountRuleStatus;
  title: Scalars['String'];
  type: DiscountType;
  useCheckbox: Scalars['Boolean'];
  voucherCode?: InputMaybe<Scalars['String']>;
};

export type SavePackageInput = {
  abbreviation: Scalars['String'];
  amount?: InputMaybe<Scalars['Float']>;
  courseUnits: Array<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  status: PackageStatus;
  subTitle?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  taxRate: Scalars['Float'];
  title: Scalars['String'];
};

export type SaveSeasonInput = {
  enterpriseId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  status: SeasonStatus;
  title: Scalars['String'];
};

export type Season = {
  __typename?: 'Season';
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  status: SeasonStatus;
  title: Scalars['String'];
};

export enum SeasonStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum SetBookingStatus {
  Cancelled = 'Cancelled',
  Confirmed = 'Confirmed',
  Unconfirmed = 'Unconfirmed',
}

export type Settings = {
  id: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

export type ShareStatisticsBoardInput = {
  board: StatisticsBoardInput;
  userIds: Array<Scalars['UUID']>;
};

export type SimpleProduct = Product & {
  __typename?: 'SimpleProduct';
  amount: Scalars['Float'];
  attribute?: Maybe<Scalars['String']>;
  attributes: Array<Attribute>;
  description?: Maybe<Scalars['String']>;
  enterprise: Enterprise;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
  nativeAttributes: Array<Attribute>;
  sku: Scalars['String'];
  status: ProductStatus;
  title: Scalars['String'];
};

export type SimpleProductAttributeArgs = {
  name: Scalars['String'];
};

export type SimpleProductAttributesArgs = {
  set: Scalars['String'];
};

export type StatRecord = {
  createdAt: Scalars['DateTime'];
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  kind: Scalars['String'];
};

export enum StatRecordOrderItemType {
  Booking = 'Booking',
  Product = 'Product',
}

export type StatRecordUnion = InquiryStatRecord | OrderStatRecord;

export type StatRecordsFilter = {
  endDate?: InputMaybe<Scalars['String']>;
  kind: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type StatisticsBoard = {
  __typename?: 'StatisticsBoard';
  boxes: Array<StatisticsBox>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type StatisticsBoardInput = {
  boxes: Array<StatisticsBoxInput>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type StatisticsBox = {
  __typename?: 'StatisticsBox';
  context: StatisticsBoxContext;
  id: Scalars['String'];
  name: Scalars['String'];
  options: Scalars['JSON'];
  type: StatisticsBoxType;
};

export enum StatisticsBoxContext {
  Inquiries = 'Inquiries',
  Orders = 'Orders',
}

export type StatisticsBoxInput = {
  context: StatisticsBoxContext;
  id: Scalars['String'];
  name: Scalars['String'];
  options: Scalars['JSON'];
  type: StatisticsBoxType;
};

export enum StatisticsBoxType {
  Chart = 'Chart',
  Values = 'Values',
}

export type StatisticsSettings = {
  __typename?: 'StatisticsSettings';
  boards: Array<StatisticsBoard>;
  enterpriseId: Scalars['UUID'];
};

export type StatisticsSettingsInput = {
  boards: Array<StatisticsBoardInput>;
  enterpriseId: Scalars['UUID'];
};

export type StringAttribute = Attribute & {
  __typename?: 'StringAttribute';
  id?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  stringValue?: Maybe<Scalars['String']>;
  valueProperty?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  view?: Maybe<Scalars['String']>;
};

export type SubmitBookingInput = {
  additionalConsent?: InputMaybe<Scalars['String']>;
  address: AddressInput;
  attributes?: InputMaybe<Array<AttributeInput>>;
  comment?: InputMaybe<Scalars['String']>;
  consentAds?: InputMaybe<Scalars['String']>;
  consentGtc: Scalars['String'];
  id: Scalars['UUID'];
  invoiceAddress?: InputMaybe<AddressInput>;
  kind: BookingKind;
};

export type Tag = {
  __typename?: 'Tag';
  context?: Maybe<Scalars['String']>;
  enterprise?: Maybe<Enterprise>;
  enterpriseId: Scalars['UUID'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type TagInput = {
  context?: InputMaybe<Scalars['String']>;
  enterpriseId: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type TaxInfo = {
  __typename?: 'TaxInfo';
  amount: Scalars['Float'];
  taxRate: Scalars['Float'];
};

export type Teacher = User & {
  __typename?: 'Teacher';
  anonymizedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enterprises: Array<Enterprise>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  image?: Maybe<FilesystemFile>;
  imageId?: Maybe<Scalars['UUID']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  person: Person;
  personId: Scalars['UUID'];
  primaryEmail?: Maybe<Scalars['String']>;
  selectedEnterprise?: Maybe<Enterprise>;
  slug?: Maybe<Scalars['String']>;
  status: UserStatus;
  subjectArea?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type TeacherInput = {
  description?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  subjectArea?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type TemplateVariableDefinition = {
  __typename?: 'TemplateVariableDefinition';
  description: Scalars['String'];
  hasHandler: Scalars['Boolean'];
  name: Scalars['String'];
  templateString?: Maybe<Scalars['String']>;
};

export type TextAttribute = Attribute & {
  __typename?: 'TextAttribute';
  id?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  textValue?: Maybe<Scalars['String']>;
  valueProperty?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  view?: Maybe<Scalars['String']>;
};

export enum TransactionMailAction {
  CourseManagementNotificationNewBooking = 'CourseManagementNotificationNewBooking',
  CourseManagementNotificationNewOrder = 'CourseManagementNotificationNewOrder',
  CustomerBookingConfirmation = 'CustomerBookingConfirmation',
  CustomerOrderConfirmation = 'CustomerOrderConfirmation',
  CustomerOrderConfirmationWithInfoBooking = 'CustomerOrderConfirmationWithInfoBooking',
  PersonInquiryNotification = 'PersonInquiryNotification',
  PersonVerifyConsent = 'PersonVerifyConsent',
  RegistrationDoubleOptIn = 'RegistrationDoubleOptIn',
  RequestPasswordReset = 'RequestPasswordReset',
  SupportNotificationNewInquiry = 'SupportNotificationNewInquiry',
  SupportOrConsultantNotificationNewConsultingEventBooking = 'SupportOrConsultantNotificationNewConsultingEventBooking',
}

export type TransactionMailTemplate = TransactionTemplate & {
  __typename?: 'TransactionMailTemplate';
  actions: Array<Scalars['String']>;
  availableVariables: Array<TemplateVariableDefinition>;
  bodyHtml?: Maybe<Scalars['String']>;
  bodyText: Scalars['String'];
  enterpriseId: Scalars['UUID'];
  helpTexts?: Maybe<Array<Scalars['String']>>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  subject: Scalars['String'];
};

export type TransactionMailTemplateInput = {
  actions: Array<TransactionMailAction>;
  bodyHtml?: InputMaybe<Scalars['String']>;
  bodyText: Scalars['String'];
  enterpriseId: Scalars['UUID'];
  name: Scalars['String'];
  subject: Scalars['String'];
};

export type TransactionTemplate = {
  availableVariables: Array<TemplateVariableDefinition>;
  enterpriseId: Scalars['UUID'];
  helpTexts?: Maybe<Array<Scalars['String']>>;
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type UiSettings = {
  __typename?: 'UiSettings';
  statistics?: Maybe<Array<StatisticsSettings>>;
};

export type UiSettingsInput = {
  statistics?: InputMaybe<Array<StatisticsSettingsInput>>;
};

export type UpdateConsultingEventInput = {
  availableCourseIds?: InputMaybe<Array<Scalars['UUID']>>;
  availableLocationIds?: InputMaybe<Array<Scalars['UUID']>>;
  availableSubjects?: InputMaybe<Array<Scalars['String']>>;
  consultantId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['UUID']>;
  startDate: Scalars['DateTime'];
};

export type UpdateCourseCategoryInput = {
  parentId?: InputMaybe<Scalars['UUID']>;
  properties?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type UpdateCourseFilesInput = {
  imageId?: InputMaybe<Scalars['UUID']>;
};

export type UpdateCourseInput = {
  abbreviation: Scalars['String'];
  approximateClassHours?: InputMaybe<Scalars['Int']>;
  approximateDuration?: InputMaybe<DurationInput>;
  attributes?: InputMaybe<Array<AttributeInput>>;
  categoryId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  earlyBirdDate?: InputMaybe<Scalars['DateTime']>;
  earlyBirdNotice?: InputMaybe<Scalars['String']>;
  eligible: Scalars['Boolean'];
  infoCourse: Scalars['Boolean'];
  modulePriceMatrix?: InputMaybe<Scalars['String']>;
  position: Scalars['Int'];
  properties?: InputMaybe<Scalars['JSON']>;
  purchasable: Scalars['Boolean'];
  slug?: InputMaybe<Scalars['String']>;
  status: CourseStatus;
  subTitle?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  taxRate: Scalars['Float'];
  teacherIds?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type UpdateEnterpriseFilesInput = {
  logoId?: InputMaybe<Scalars['UUID']>;
};

export type UpdateEventInput = {
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  roomId?: InputMaybe<Scalars['UUID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateProductInput = {
  amount?: InputMaybe<Scalars['Float']>;
  attributes: Array<AttributeInput>;
  description?: InputMaybe<Scalars['String']>;
  enterpriseId?: InputMaybe<Scalars['UUID']>;
  locationIds?: InputMaybe<Array<Scalars['UUID']>>;
  status: ProductStatus;
  title: Scalars['String'];
};

export type UpdateUserFilesInput = {
  imageId?: InputMaybe<Scalars['UUID']>;
};

export type User = {
  anonymizedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enterprises: Array<Enterprise>;
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  image?: Maybe<FilesystemFile>;
  imageId?: Maybe<Scalars['UUID']>;
  person: Person;
  personId: Scalars['UUID'];
  primaryEmail?: Maybe<Scalars['String']>;
  selectedEnterprise?: Maybe<Enterprise>;
  status: UserStatus;
  username?: Maybe<Scalars['String']>;
};

export enum UserStatus {
  Registered = 'REGISTERED',
  Unregistered = 'UNREGISTERED',
}

export type UserUnion = Administrator | Customer | Teacher;

export type WebsiteSettings = Settings & {
  __typename?: 'WebsiteSettings';
  data?: Maybe<WebsiteSettingsData>;
  id: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
};

export type WebsiteSettingsData = {
  __typename?: 'WebsiteSettingsData';
  websiteBaseUri?: Maybe<Scalars['String']>;
};

export type WebsiteSettingsDataInput = {
  websiteBaseUri?: InputMaybe<Scalars['String']>;
};

export type WebsiteSettingsInput = {
  data: WebsiteSettingsDataInput;
};
