import { Order, OrderStatus } from '@/graphql/generated/types';
import { Chip } from '@/ui/chip/chip.component';
import React, { ComponentType } from 'react';
import { Translator } from 'shared/translator/translator.component';

interface OrderStatusChipProps {
  order: Pick<Order, 'status'>;
}

function meaningByStatus(
  meaning: Order['status'],
): 'warning' | 'success' | 'error' {
  if (meaning === OrderStatus.Payed) {
    return 'success';
  }
  return 'warning';
}
export const OrderStatusChip: ComponentType<OrderStatusChipProps> = (props) => {
  const { order } = props;
  const _ = Translator.useTranslator();
  return (
    <Chip meaning={meaningByStatus(order.status)}>
      {_(`order-status.${order.status}`)}
    </Chip>
  );
};
