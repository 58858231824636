import { addressSchema } from '@/address/address.schema';
import { AddressEditor } from '@/contact/shared/address/address-editor.component';
import { ContactPropertiesEditor } from '@/contact/shared/contact-property/contact-properties-editor.component';
import { Address } from '@/graphql/generated/types';
import { Profile, isSuperAdmin } from '@/profile/profile';
import { Form } from '@/ui/form/form.component';
import { Grid } from '@/ui/grid/grid.component';
import { HelpPopup } from '@/ui/help-popup/help-popup.component';
import { useConfirm } from '@/ui/modal/modal.hooks';
import { Section } from '@/ui/section/section.component';
import { error, success } from '@/ui/toaster/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, navigate } from '@reach/router';
import React, { ComponentType, useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useResources } from 'shared/resources/resources.provider';
import { array, number, object, string } from 'yup';
import { Frame } from '../../../../frame';
import { Button } from '../../../../ui/button/button.component';
import {
  useCompanyQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useEditCompanyMutation,
} from './editor.generated';

type Values = {
  name: string;
  internalNotes;
  address: Partial<Address> | null;
  invoiceAddress: Partial<Address> | null;
  enterpriseIds: string[];
};

const schema = object({
  name: string().min(3).required(),
  city: string(),
  address: addressSchema,
  administrativeEmail: string().email(),
  parentId: string().nullable(),
  geoLocation: array(number()).min(2).max(2).nullable(),
});

const superAdminSchema = schema.shape({
  enterpriseIds: array(string()).min(1),
});

export const Editor: ComponentType<RouteComponentProps<{ id: string }>> = (
  props,
) => {
  const companyQuery = useCompanyQuery({ variables: { id: props.id } });

  const [save] = useEditCompanyMutation();
  const [create] = useCreateCompanyMutation();
  const profile = Profile.use();

  const form = useForm<Values>({
    resolver: yupResolver(isSuperAdmin(profile) ? superAdminSchema : schema),
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      enterpriseIds: isSuperAdmin(profile)
        ? undefined
        : [profile.selectedEnterprise?.id],
    },
  });

  // useFormDirtyGuard(form);

  const contactProperties = useFieldArray<any>({
    control: form.control,
    name: 'contactProperties',
  });

  const { availableEnterprises, reload } = useResources();

  const onSubmit = useCallback(
    async (input) => {
      if (props.id) {
        try {
          await save({ variables: { id: props.id, input } });
          await reload();
          success('Der Firmenkontakt wurde aktualisiert.');
        } catch (e) {
          error(
            'Beim Erstellen des Firmenkontaktes ist ein Fehler aufgetreten.',
          );
        }
      } else {
        try {
          const company = await create({ variables: { input } });
          navigate(`./${company.data?.adminCreateCompany.id}/edit-company`);
          await reload();
          success('Der Firmenkontakt wurde erstellt.');
        } catch (e) {
          error(
            'Beim Erstellen des Firmenkontaktes ist ein Fehler aufgetreten.',
          );
        }
      }
    },
    [props.id],
  );

  const onSubmitError = useCallback((errors) => {
    error('Bitte überprüfe deine Eingabe.');
  }, []);

  const [remove] = useDeleteCompanyMutation();

  const [confirmModal, confirmDelete] = useConfirm(async (id: string) => {
    try {
      await remove({ variables: { id } });
      await reload();
      navigate('../list');
      success('Der Firmenkontakt wurde gelöscht.');
    } catch (e) {
      error('Fehler');
    }
  });

  const onDelete = useCallback(async () => {
    if (props.id) {
      confirmDelete(
        'Soll dieser Firmenkontakt wirklich gelöscht werden?',
        props.id,
      );
    }
  }, [props.id]);

  const address =
    companyQuery.data?.adminCompany.address || form.watch('address');

  const persisted = props.id !== undefined || companyQuery.data !== undefined;

  return (
    <>
      {confirmModal}
      <Frame.SubTitle>
        {persisted
          ? companyQuery.data?.adminCompany.name
          : 'Neuer Firmenkontakt'}
      </Frame.SubTitle>
      <Frame.ActionBar>
        <Button warning transparent label="Löschen" onClick={onDelete} />
        <Button label="Zurück" linkTo="/contacts/companies/list" />
        <Button
          primary
          label="Speichern"
          onClick={form.handleSubmit(onSubmit, onSubmitError)}
        />
      </Frame.ActionBar>
      <Frame.Content>
        <Form form={form} values={companyQuery.data?.adminCompany as any}>
          <Grid.Row>
            <Grid.Column>
              <Form.Input name="name" label="Name" />
              <Section title="Kontakt">
                <ContactPropertiesEditor
                  prefix="contactProperties"
                  contactPropertiesFieldArray={contactProperties}
                  form={form}
                />
              </Section>
              <Section title="Unternehmen">
                {!persisted && (
                  <>
                    <Form.Select
                      disabled
                      multiple
                      name="enterpriseIds"
                      label={
                        <>
                          Firmenzugehörigkeit
                          <HelpPopup hover="Halte die Strg- (Windows) oder Befehlstaste (Mac) gedrückt, um mehrere Optionen auszuwählen." />
                        </>
                      }
                      choices={availableEnterprises.map((enterprise) => ({
                        value: enterprise.id,
                        label: enterprise.name,
                      }))}
                    />
                  </>
                )}
                {persisted && (
                  <>
                    <label>Firmenzugehörigkeit</label>
                    <ul>
                      {companyQuery.data?.adminCompany.enterprises.map((c) => (
                        <li>{c.name}</li>
                      ))}
                    </ul>
                  </>
                )}
              </Section>
              <Section title="Sonstiges">
                <Form.Html label="Interne Notizen" name="internalNotes" />
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section title="Anschrift">
                <AddressEditor prefix="address" />
              </Section>
              <Section title="Rechnungsanschrift (falls abweichend)">
                <AddressEditor prefix="invoiceAddress" />
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Form>
      </Frame.Content>
    </>
  );
};
