import * as Types from '../../../../graphql/generated/types';

import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type EditCourseCategoryDataFragment = {
  __typename?: 'CourseCategory';
  id: any;
  title: string;
  slug?: string | null;
  enterpriseId: any;
  parentId?: any | null;
  properties: any;
  parent?: { __typename?: 'CourseCategory'; id: any; title: string } | null;
  enterprise: { __typename?: 'Enterprise'; id: any; name: string };
};

export type CourseCategoryQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type CourseCategoryQuery = {
  __typename?: 'Query';
  adminCourseCategory: {
    __typename?: 'CourseCategory';
    id: any;
    title: string;
    slug?: string | null;
    enterpriseId: any;
    parentId?: any | null;
    properties: any;
    parent?: { __typename?: 'CourseCategory'; id: any; title: string } | null;
    enterprise: { __typename?: 'Enterprise'; id: any; name: string };
  };
};

export type CreateCourseCategoryMutationVariables = Types.Exact<{
  input: Types.CreateCourseCategoryInput;
}>;

export type CreateCourseCategoryMutation = {
  __typename?: 'Mutation';
  adminCreateCourseCategory: {
    __typename?: 'CourseCategory';
    id: any;
    title: string;
    slug?: string | null;
    enterpriseId: any;
    parentId?: any | null;
    properties: any;
    parent?: { __typename?: 'CourseCategory'; id: any; title: string } | null;
    enterprise: { __typename?: 'Enterprise'; id: any; name: string };
  };
};

export type UpdateCourseCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateCourseCategoryInput;
  id: Types.Scalars['UUID'];
}>;

export type UpdateCourseCategoryMutation = {
  __typename?: 'Mutation';
  adminUpdateCourseCategory: {
    __typename?: 'CourseCategory';
    id: any;
    title: string;
    slug?: string | null;
    enterpriseId: any;
    parentId?: any | null;
    properties: any;
    parent?: { __typename?: 'CourseCategory'; id: any; title: string } | null;
    enterprise: { __typename?: 'Enterprise'; id: any; name: string };
  };
};

export type DeleteCourseCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type DeleteCourseCategoryMutation = {
  __typename?: 'Mutation';
  adminDeleteCourseCategory: boolean;
};

export const EditCourseCategoryDataFragmentDoc = gql`
  fragment EditCourseCategoryData on CourseCategory {
    id
    title
    slug
    parent {
      id
      title
    }
    enterprise {
      id
      name
    }
    enterpriseId
    parentId
    properties
  }
`;
export const CourseCategoryDocument = gql`
  query courseCategory($id: UUID!) {
    adminCourseCategory(id: $id) {
      ...EditCourseCategoryData
    }
  }
  ${EditCourseCategoryDataFragmentDoc}
`;
export type CourseCategoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CourseCategoryQuery,
    CourseCategoryQueryVariables
  >,
  'query'
> &
  (
    | { variables: CourseCategoryQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const CourseCategoryComponent = (
  props: CourseCategoryComponentProps,
) => (
  <ApolloReactComponents.Query<
    CourseCategoryQuery,
    CourseCategoryQueryVariables
  >
    query={CourseCategoryDocument}
    {...props}
  />
);

/**
 * __useCourseCategoryQuery__
 *
 * To run a query within a React component, call `useCourseCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseCategoryQuery,
    CourseCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseCategoryQuery, CourseCategoryQueryVariables>(
    CourseCategoryDocument,
    options,
  );
}
export function useCourseCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseCategoryQuery,
    CourseCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseCategoryQuery, CourseCategoryQueryVariables>(
    CourseCategoryDocument,
    options,
  );
}
export type CourseCategoryQueryHookResult = ReturnType<
  typeof useCourseCategoryQuery
>;
export type CourseCategoryLazyQueryHookResult = ReturnType<
  typeof useCourseCategoryLazyQuery
>;
export type CourseCategoryQueryResult = Apollo.QueryResult<
  CourseCategoryQuery,
  CourseCategoryQueryVariables
>;
export const CreateCourseCategoryDocument = gql`
  mutation createCourseCategory($input: CreateCourseCategoryInput!) {
    adminCreateCourseCategory(input: $input) {
      ...EditCourseCategoryData
    }
  }
  ${EditCourseCategoryDataFragmentDoc}
`;
export type CreateCourseCategoryMutationFn = Apollo.MutationFunction<
  CreateCourseCategoryMutation,
  CreateCourseCategoryMutationVariables
>;
export type CreateCourseCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCourseCategoryMutation,
    CreateCourseCategoryMutationVariables
  >,
  'mutation'
>;

export const CreateCourseCategoryComponent = (
  props: CreateCourseCategoryComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateCourseCategoryMutation,
    CreateCourseCategoryMutationVariables
  >
    mutation={CreateCourseCategoryDocument}
    {...props}
  />
);

/**
 * __useCreateCourseCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseCategoryMutation, { data, loading, error }] = useCreateCourseCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseCategoryMutation,
    CreateCourseCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCourseCategoryMutation,
    CreateCourseCategoryMutationVariables
  >(CreateCourseCategoryDocument, options);
}
export type CreateCourseCategoryMutationHookResult = ReturnType<
  typeof useCreateCourseCategoryMutation
>;
export type CreateCourseCategoryMutationResult =
  Apollo.MutationResult<CreateCourseCategoryMutation>;
export type CreateCourseCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseCategoryMutation,
  CreateCourseCategoryMutationVariables
>;
export const UpdateCourseCategoryDocument = gql`
  mutation updateCourseCategory(
    $input: UpdateCourseCategoryInput!
    $id: UUID!
  ) {
    adminUpdateCourseCategory(input: $input, id: $id) {
      ...EditCourseCategoryData
    }
  }
  ${EditCourseCategoryDataFragmentDoc}
`;
export type UpdateCourseCategoryMutationFn = Apollo.MutationFunction<
  UpdateCourseCategoryMutation,
  UpdateCourseCategoryMutationVariables
>;
export type UpdateCourseCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCourseCategoryMutation,
    UpdateCourseCategoryMutationVariables
  >,
  'mutation'
>;

export const UpdateCourseCategoryComponent = (
  props: UpdateCourseCategoryComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateCourseCategoryMutation,
    UpdateCourseCategoryMutationVariables
  >
    mutation={UpdateCourseCategoryDocument}
    {...props}
  />
);

/**
 * __useUpdateCourseCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCategoryMutation, { data, loading, error }] = useUpdateCourseCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCourseCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourseCategoryMutation,
    UpdateCourseCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCourseCategoryMutation,
    UpdateCourseCategoryMutationVariables
  >(UpdateCourseCategoryDocument, options);
}
export type UpdateCourseCategoryMutationHookResult = ReturnType<
  typeof useUpdateCourseCategoryMutation
>;
export type UpdateCourseCategoryMutationResult =
  Apollo.MutationResult<UpdateCourseCategoryMutation>;
export type UpdateCourseCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseCategoryMutation,
  UpdateCourseCategoryMutationVariables
>;
export const DeleteCourseCategoryDocument = gql`
  mutation deleteCourseCategory($id: UUID!) {
    adminDeleteCourseCategory(id: $id)
  }
`;
export type DeleteCourseCategoryMutationFn = Apollo.MutationFunction<
  DeleteCourseCategoryMutation,
  DeleteCourseCategoryMutationVariables
>;
export type DeleteCourseCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCourseCategoryMutation,
    DeleteCourseCategoryMutationVariables
  >,
  'mutation'
>;

export const DeleteCourseCategoryComponent = (
  props: DeleteCourseCategoryComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    DeleteCourseCategoryMutation,
    DeleteCourseCategoryMutationVariables
  >
    mutation={DeleteCourseCategoryDocument}
    {...props}
  />
);

/**
 * __useDeleteCourseCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseCategoryMutation, { data, loading, error }] = useDeleteCourseCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCourseCategoryMutation,
    DeleteCourseCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCourseCategoryMutation,
    DeleteCourseCategoryMutationVariables
  >(DeleteCourseCategoryDocument, options);
}
export type DeleteCourseCategoryMutationHookResult = ReturnType<
  typeof useDeleteCourseCategoryMutation
>;
export type DeleteCourseCategoryMutationResult =
  Apollo.MutationResult<DeleteCourseCategoryMutation>;
export type DeleteCourseCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseCategoryMutation,
  DeleteCourseCategoryMutationVariables
>;
